import axios from "./axios";

export async function generateText(payload) {
  return axios
    .post(`/ai/text/`, { payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}
