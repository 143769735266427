import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { ServiceAgreementSimpleForm } from "./simple-form";
import { fetchServiceAgreement } from "../../../../api/service/agreement";
import { PMDialog } from "../../../utils/PMDialog";

export default function ServiceAgreementModal(props) {
  const { t } = useTranslation();
  const { open, onClose, selected, customer } = props;

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    agreement: {},
  });

  const { agreement, isLoading } = itemState;

  const getServiceAgreement = async () => {
    await fetchServiceAgreement(selected.id)
      .then((res) => {
        if (res.data.id) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            agreement: res.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          report: {},
          error: true,
          errors: errors,
        }));
      });
  };

  useEffect(() => {
    if (open) {
      setItemState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      if (selected?.id) {
        getServiceAgreement();
      } else {
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
    } else {
      setItemState((prevState) => ({
        ...prevState,
        report: {},
      }));
    }
  }, [open]);

  return (
    <PMDialog
      isLoading={isLoading}
      open={open}
      onClose={onClose}
      title={
        agreement?.id
          ? t("Update Service Agreement")
          : t("Create Service Agreement")
      }
    >
      <ServiceAgreementSimpleForm
        item={agreement}
        onClose={onClose}
        customer={customer}
        {...props}
      />
    </PMDialog>
  );
}
