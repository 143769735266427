import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchDrivingDrivers,
  fetchDrivingDriver,
  updateDrivingDriver,
  createDrivingDriver,
  deleteDrivingDriver,
} from "../../api/driving/driver";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  totalCount: 0,
  currentCount: 0,
  synchedWithApiCount: 0,
  newCount: 0,
};

export const initialController = {
  filters: [],
  page: 0,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const deleteDrivingDriverAsync = createAsyncThunk(
  "drivingDrivers/deleteDrivingDriverAsync",
  async (id) => {
    let response = await deleteDrivingDriver(id);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createDrivingDriverAsync = createAsyncThunk(
  "drivingDrivers/createDrivingDriverAsync",
  async (payload) => {
    let response = await createDrivingDriver(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const updateDrivingDriverAsync = createAsyncThunk(
  "drivingDrivers/updateDrivingDriverAsync",
  async (payload) => {
    let response = await updateDrivingDriver(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);
export const getDrivingDriversAsync = createAsyncThunk(
  "drivingDrivers/getDrivingDriversAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchDrivingDrivers(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

export const getDrivingDriverAsync = createAsyncThunk(
  "drivingDrivers/getDrivingDriverAsync",
  async (id, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchDrivingDriver(id);

    // If errors, reject and return response
    if (response.error) return rejectWithValue(response);

    // If all good, return response.
    return response;
  }
);

const drivingDriverSlice = createSlice({
  name: "drivingDrivers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDrivingDriversAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDrivingDriversAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.currentCount = action.payload.currentCount;
        state.newestDrivingDriversCount = action.payload.newCount;
        state.synchedWithApiCount = action.payload.synchedWithApiCount;
      })
      .addCase(getDrivingDriversAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch drivingDrivers....is controller missing?",
        ];
      })
      .addCase(updateDrivingDriverAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateDrivingDriverAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data[index] = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(updateDrivingDriverAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update drivingDriver",
        ];
      })
      .addCase(createDrivingDriverAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createDrivingDriverAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
        });
      })
      .addCase(createDrivingDriverAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update drivingDriver",
        ];
      })
      .addCase(deleteDrivingDriverAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteDrivingDriverAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data.splice(index, 1);
          // draftState.data = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(deleteDrivingDriverAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update drivingDriver",
        ];
      })
      .addCase(getDrivingDriverAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDrivingDriverAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.drivingDriver = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(getDrivingDriverAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update product."];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = drivingDriverSlice;

export default drivingDriverSlice;
