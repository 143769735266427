import { DocumentCenter } from "../../../components/dashboard/documents/document-center";

export const ProductImages = (props) => {
  const { product } = props;

  return (
    <>
      <DocumentCenter
        title="Bilder"
        subtitle="Kun bilder er tillatt"
        module="product"
        allowedFileTypes="image/png, image/jpeg, image/jpg, image/gif, image/svg, image/svg+xml, image/webp"
        moduleParam={product?.id}
      />
    </>
  );
};
