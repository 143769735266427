import {
  ArrowRightCircleIcon,
  ClockIcon,
  ExclamationTriangleIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { TWclassNames } from "../../../utils/Div";
import { DeviationConsequenceDialog } from "./deviationConsequence";
import { useState } from "react";
import { SimpleHourDialog } from "../../hours/simple-hour-dialog";
import { PMDialog } from "../../../utils/PMDialog";
import { DeviationAssignUserDialog } from "./deviationAssignUser";

export const DeviationActions = ({ deviation, onItemChange }) => {
  const [consequenceDialogOpen, setConsequenceDialogOpen] = useState(false);
  const [hourDialogOpen, setHourDialogOpen] = useState(false);
  const [hoursSum, setHoursSum] = useState(0);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);

  const actions = [
    {
      name: "Assigned User",
      initials: "AU",
      icon: UserIcon,
      href: "#",
      action: () => setAssignDialogOpen(true),
      description: "Change or delegate",
      bgColor: "bg-blue-400",
    },
    {
      name: "Consequence",
      icon: ExclamationTriangleIcon,
      initials: "C",
      href: "#",
      description: "Add or change",
      action: () => setConsequenceDialogOpen(true),
      bgColor: "bg-purple-600",
    },
    {
      name: "Hour",
      initials: "H",
      icon: ClockIcon,
      href: "#",
      description:
        hoursSum > 0 ? hoursSum + " Registreringer" : "Work-hours & allowance",
      action: () => setHourDialogOpen(true),
      bgColor: "bg-yellow-500",
      borderColor: hoursSum > 0 ? "border-2 border-green-400" : "border-0",
    },
    {
      name: "Consumption",
      initials: "C",
      href: "#",
      description: "Item & material",
      bgColor: "bg-green-500",
    },
  ];

  return (
    <>
      <div>
        <ul
          role="list"
          className="grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
        >
          {actions.map((project) => (
            <li
              onClick={project?.action}
              key={project.name}
              className={TWclassNames(
                project.borderColor,

                "col-span-1 flex rounded-md shadow-sm"
              )}
            >
              <div
                className={TWclassNames(
                  project.bgColor,
                  "flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
                )}
              >
                {project?.icon ? (
                  <project.icon className="h-7 w-7" aria-hidden="true" />
                ) : (
                  project.initials
                )}
              </div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-2 text-sm">
                  <a
                    href={project.href}
                    className="font-medium text-gray-900 hover:text-gray-600"
                  >
                    {project.name}
                  </a>
                  <p className="text-gray-500">{project.description}</p>
                </div>
                <div className="flex-shrink-0 pr-2">
                  <button
                    type="button"
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">Open options</span>
                    <ArrowRightCircleIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <DeviationConsequenceDialog
        open={consequenceDialogOpen}
        onClose={() => setConsequenceDialogOpen(false)}
        onChange={onItemChange}
        deviation={deviation}
      />

      <SimpleHourDialog
        open={hourDialogOpen}
        onClose={() => setHourDialogOpen(false)}
        onHoursSum={(data) => setHoursSum(data)}
        module={"deviation"}
        deviation={deviation}
        moduleObject={deviation}
        deviationRequired={true}
        projectRequired={true}
        activityRequired={true}
      />

      <DeviationAssignUserDialog
        deviation={deviation}
        open={assignDialogOpen}
        onClose={() => setAssignDialogOpen(false)}
      />
    </>
  );
};
