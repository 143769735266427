import { useState, useEffect } from "react";
import { axiosWithToast } from "../../../../Utils";
import axios from "../../../../../api/axios";
import { ArrowRightIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { PMDialog } from "../../../../utils/PMDialog";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { toast } from "react-hot-toast";
import { PMField } from "../../../../utils/PMField";
import PMSwitch from "../../../../utils/PMSwitch";
import * as Yup from "yup";
import { PMImagePicker } from "../../../../utils/PMImagePicker";
import { documentSrc } from "../../../documents/utils";
import { PMPagination } from "../../../../utils/PMPagination";
import { ProductCategorySelector } from "../../../../utils/moduleSelectors/productCategorySelector";
import { ResourceLoading } from "../../../../utils/ResourceLoading";

const Category = ({ category, isChild, dialogOpen }) => {
  return (
    <li key={category.email} className=" py-2">
      <div
        onClick={() => dialogOpen(category)}
        className="flex hover:bg-blue-200 rounded-xl py-2 px-4 cursor-pointer"
      >
        {isChild && (
          <div className="py-1">
            <ArrowRightIcon className="w-5 h-5 mr-2 mt-1" />
          </div>
        )}

        <div className="h-10 w-10 flex-shrink-0 mr-2">
          <img
            className="h-10 w-10 rounded-full"
            src={documentSrc(category?.image)}
            alt={
              category?.image?.id ? category?.image?.title : "Image placeholder"
            }
          />
        </div>
        <span className="text-sm font-semibold leading-6 text-gray-900 py-2 px-2">
          {category?.title}
        </span>
      </div>

      <ul className="ml-5">
        {category?.children?.map((child) => (
          <Category
            key={child?.id}
            category={child}
            isChild={true}
            dialogOpen={() => dialogOpen(child)}
          />
        ))}
      </ul>
    </li>
  );
};

export const ProductCategoriesFormDialog = (props) => {
  const { onClose, open, selected, onUpdate } = props;
  const { t } = useTranslation();

  const [category, setCategory] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getCategory = () => {
    setIsLoading(true);
    axios
      .get(`/products/categories/` + selected?.id)
      .then((res) => {
        if (res.data.id) {
          setCategory(res.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (open && selected?.id) {
      getCategory();
    } else {
      setCategory({});
    }
  }, [open]);

  const updateOrCreate = (values) => {
    if (values?.id) {
      return axios.put(`/products/categories/` + values?.id, values);
    } else {
      return axios.post(`/products/categories/`, values);
    }
  };

  return (
    <PMDialog
      open={open}
      onClose={onClose}
      title={category?.id ? category.title : t("Categories")}
    >
      {isLoading ? (
        <ResourceLoading />
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            public: true,
            active: true,
            ...category,
            submit: null,
          }}
          validationSchema={Yup.object({
            title: Yup.string().max(255).required(t("title.required")),
            description: Yup.string().max(500),
          })}
          onSubmit={(values, helpers) => {
            try {
              axiosWithToast(
                updateOrCreate(values),
                (data) => onUpdate(data),
                onClose
              );
              helpers.setStatus({ success: true });
              helpers.setSubmitting(false);
            } catch (err) {
              toast.error("Something went wrong...");
              console.error(err);
              helpers.setStatus({ success: false });
              helpers.setErrors({ submit: err.message });
              helpers.setSubmitting(false);
            }
          }}
        >
          {(formik) => {
            return (
              <Form>
                <div className="grid grid-cols-6 gap-6 mt-10">
                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="title"
                      type="text"
                      label="Title"
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6"></div>

                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="description"
                      type="text"
                      label="Description"
                      multiline
                      rows={2}
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <ProductCategorySelector
                      name="parent_category"
                      label="Parent"
                      selected={formik?.values?.parent}
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <div className="py-2">
                      <PMSwitch name="active" label="Active" {...formik} />
                    </div>
                    <div className="py-2">
                      <PMSwitch name="public" label="Public" {...formik} />
                    </div>
                  </div>

                  <div className="col-span-3 sm:col-span-3">
                    <PMImagePicker
                      label="Image"
                      selected={formik?.values?.image}
                      name="image_id"
                      {...formik}
                      // {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2"></div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    onClick={onClose}
                  >
                    {t("Close")}{" "}
                  </button>

                  <button
                    type="button"
                    className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    onClick={formik.submitForm}
                  >
                    {formik.values?.id ? t("Save") : t("Create")}{" "}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </PMDialog>
  );
};

export const ProductCategoriesListV2 = (props) => {
  const [categories, setCategories] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentCount, setCurrentCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    getCategories();
  }, [page, perPage]);

  const getCategories = () => {
    setIsLoading(true);

    const fetchCategories = () => {
      return axios.get(
        `/products/categories/list?parents=true&page=${page}&per_page=${perPage}`
      );
    };

    const setData = (data) => {
      setCategories(data?.data);
      setTotalCount(data?.totalCount);
      setCurrentCount(data?.currentCount);
    };

    axiosWithToast(fetchCategories(), null, null, (data) => setData(data));
  };

  useEffect(() => {
    getCategories();
  }, []);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selected, setSelected] = useState();

  const handleDialogClose = () => {
    setDialogOpen(false);
    setTimeout(() => {
      setSelected(null);
    }, 250);
  };

  const handleDialogOpen = (category) => {
    setSelected(category);
    setDialogOpen(true);
  };

  const handleCategoriesUpdate = (data) => {
    if (data?.id) {
      if (data?.child) {
        const index = categories.findIndex((c) => c.id === data?.parent?.id);

        // find old child index
        const oldChildIdx = categories.findIndex((c) => c.id === data?.id);
        // remove from categories, since it's a child it will probably be moved or stay
        categories.splice(oldChildIdx, 1);

        if (index > -1) {
          categories[index].children.push(data);
        } else {
          categories.push(data);
        }
      } else if (!data?.parent_id) {
        // Check for children in each category and splice it if exists
        categories.forEach((c) => {
          if (c?.children?.length > 0) {
            const childIdx = c?.children?.findIndex(
              (child) => child?.id === data?.id
            );
            if (childIdx > -1) {
              c?.children?.splice(childIdx, 1);
            }
          }
        });

        const index = categories.findIndex((c) => c.id === data.id);
        if (index > -1) {
          categories[index] = data;
        } else {
          categories.push(data);
        }
      } else {
        categories.push(data);
      }
    }

    setCategories([...categories]);
  };

  return (
    <>
      <div className="flex text-white bg-blue-400 rounded-xl shadow px-4 py-2 mb-2 justify-between">
        <div className="py-1">
          <span className="font-bold">Categories</span>
        </div>
        <div
          className="flex font-bold cursor-pointer px-4 text-blue-400 hover:text-blue-200 bg-white rounded-xl"
          onClick={() => handleDialogOpen()}
        >
          <div className="py-2">
            <PlusCircleIcon className="h-5 w-5 mr-2" />
          </div>
          <span className="py-1 mt-0.5">Ny kategori</span>
        </div>
      </div>
      <div className="px-5 py-5 bg-white rounded-xl shadow ">
        <ul role="list" className="divide-y divide-gray-100">
          {categories &&
            categories?.map((category) => (
              <Category
                key={category?.id}
                category={category}
                dialogOpen={(category) => handleDialogOpen(category)}
              />
            ))}
        </ul>

        <PMPagination
          disabled={isLoading}
          onPageChange={handlePageChange}
          page={page}
          perPage={perPage}
          rowsCount={currentCount}
          totalCount={totalCount}
          currentCount={currentCount}
          showCount={true}
        />
      </div>

      <ProductCategoriesFormDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        selected={selected}
        onUpdate={(data) => handleCategoriesUpdate(data)}
      />
    </>
  );
};
