import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ProductsFilter } from "../../../../components/dashboard/products/products-filter";
import { ReportsTable } from "../../../../components/dashboard/service/reports/reports-table";
import { getReportsAsync } from "../../../../slices/service/reports";
import {
  reportStatusesOptions,
  reportViewsOptions,
} from "../../../../components/dashboard/service/reports/utils";
import { useSearchParams } from "react-router-dom";
import DashboardHeader from "../../../../components/dashboard/dashboardHeader";
import { BuildingLibraryIcon, PlusIcon } from "@heroicons/react/24/outline";
import ServiceReportEditModal from "../../../../components/dashboard/service/reports/service-report-edit-modal";
import {
  axiosWithToast,
  pageOptions,
  updateUrlWithException,
} from "../../../../components/Utils";
import { ReportActionEditBar } from "../../../../components/dashboard/service/reports/report-action-edit-bar";
import ReportMultiEditDialog from "../../../../components/dashboard/service/reports/report-multi-edit-dialog";
import { debounce } from "lodash";
import { fetchReportExcel } from "../../../../api/service/report";
import { ReportExportDialog } from "../../../../components/dashboard/service/reports/reports-export-dialog";

export const ReportList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [controller, setController] = useState({
    filters: [{ name: "status", value: JSON.stringify(["NEW", "ONGOING"]) }],
    page: searchParams.get("page") ? searchParams.get("page") : 1,
    per_page: searchParams.get("per_page") ? searchParams.get("per_page") : 50,
    query: searchParams.get("query") ? searchParams.get("query") : "",
    sort: searchParams.get("sort") ? searchParams.get("sort") : "asc",
    sortBy: searchParams.get("sortBy")
      ? searchParams.get("sortBy")
      : "created_at",
    view: searchParams.get("view") ? searchParams.get("view") : "all",
    date_from: searchParams.get("date_from")
      ? searchParams.get("date_from")
      : "",
    date_to: searchParams.get("date_to") ? searchParams.get("date_to") : "",
    fields: searchParams.get("fields")
      ? searchParams.get("fields")
      : "id,report_number,customer.id,customer.name,customer.customer_number,project,delivery_address,status,address,address,postal,place,appointment,report_type",
  });

  const productState = useSelector((store) => {
    return store.reports;
  });

  const [reportExportDialog, setReportExportDialog] = useState(false);

  // Update all params from controller except filters (dont't touch filters)
  useEffect(() => {
    updateUrlWithException(
      // These will be updated
      {
        date_from: controller.date_from,
        date_to: controller.date_to,
        page: controller.page,
        per_page: controller.per_page,
        query: controller.query,
        sort: controller.sort,
        sortBy: controller.sortBy,
        view: controller.view,
        // filters: controller.filters,
      },
      // This will be preserved
      "filters"
    );
  }, [controller]);

  const { isLoading } = productState;
  const [selectedItems, setSelectedItems] = useState([]);

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery,
      filters: [],
    });
  };

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handlePagePageChange = (option) => {
    setController({
      ...controller,
      per_page: option,
    });
  };

  const handleFilterChange = (selectedFilters) => {
    setController({
      ...controller,
      filters: selectedFilters?.map((filter) => {
        return {
          name: filter.id,
          value: JSON.stringify(filter.values),
        };
      }),
    });
  };

  const debouncedDispatchAction = debounce((controller) => {
    dispatch(getReportsAsync(controller));
  }, 1000);

  useEffect(() => {
    // Trigger the debounced dispatch action
    debouncedDispatchAction(controller);

    // Clean the debounced function on component unmount.
    return () => {
      debouncedDispatchAction.cancel();
    };
  }, [controller, dispatch]);

  const sortByOptions = [
    { name: "Seneste (Rapportnr.)", sort: "asc", sortBy: "report_number" },
    { name: "Eldste (Rapportnr.)", sort: "desc", sortBy: "report_number" },
  ];

  const initialFilters = [
    {
      id: "report_type",
      name: "report_type",
      optionsUrl: "/service/settings/report_types",
      optionsParams: {},
    },
    {
      id: "view",
      name: "view",
      options: reportViewsOptions,
    },
    {
      id: "status",
      name: "status",
      options: reportStatusesOptions,
    },
  ];

  const fetchExcel = async () => {
    const payload = {
      ...controller,
      format: "xlsx",
    };
    axiosWithToast(fetchReportExcel(payload));
  };

  const headerActions = [
    {
      component: null,
      label: "Export Excel",
      description: "",
      path: null,
      action: () => setReportExportDialog(true),
      icon: BuildingLibraryIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: true,
    },
  ];

  const [reportEditDialogOpen, setReportEditDialogOpen] = useState(false);
  const [multiEditDialogOpen, setMultiEditDialogOpen] = useState(false);

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={"Reports"}
        meta={[]}
        mainButton={{
          label: "New",
          action: () => setReportEditDialogOpen(true),
          path: null,
          color: "",
          icon: PlusIcon,
        }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            <ProductsFilter
              actionBar={
                <ReportActionEditBar
                  selectedItems={selectedItems}
                  setMultiEditDialogOpen={setMultiEditDialogOpen}
                />
              }
              className={"mt-10"}
              disabled={isLoading}
              onQueryChange={handleQueryChange}
              query={controller.query}
              selectedItems={selectedItems}
              sortByOptions={sortByOptions}
              onPerPageChange={handlePagePageChange}
              perPageOptions={[10, 25, 50, 100, 250]}
              pageOptions={pageOptions({
                perPage: controller.per_page,
                totalCount: productState.count,
              })}
              fromToDate={true}
              onFromToDateChange={(data) => {
                setController({
                  ...controller,
                  date_from: data?.date_from,
                  date_to: data?.date_to,
                });
              }}
              initialFilters={initialFilters}
              onFilterChange={handleFilterChange}
              defaultSelectedOptions={[
                { name: "status", value: "NEW" },
                { name: "status", value: "ONGOING" },
              ]}
            />

            <ReportsTable
              onItemsSelect={(items) => setSelectedItems(items)}
              itemState={productState}
              itemsCount={productState.count}
              currentCount={productState.currentCount}
              page={controller.page}
              onPageChange={handlePageChange}
              perPage={controller.per_page}
            />
          </div>
        </div>
      </DashboardHeader>

      <ReportMultiEditDialog
        selectedItems={selectedItems}
        open={multiEditDialogOpen}
        onClose={() => setMultiEditDialogOpen(false)}
      />

      <ServiceReportEditModal
        open={reportEditDialogOpen}
        onClose={() => setReportEditDialogOpen(false)}
        selected={null}
        // onRefresh={() => getReport()}
      />

      <ReportExportDialog
        open={reportExportDialog}
        isLoading={isLoading}
        controller={controller}
        // onLoading={(state) => setIsLoading(state)}
        onClose={() => setReportExportDialog(false)}
      />
    </>
  );
};
