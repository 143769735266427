import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { FormikSaveBar } from "../../utils/FormikSaveBar";
import { PMField } from "../../utils/PMField";
import { updateProductAsync } from "../../../slices/products";
import { useDispatch } from "react-redux";
import PMSwitch from "../../utils/PMSwitch";
import PMListboxFormik from "../../utils/PMListboxFormik";
import { updateCustomerAsync } from "../../../slices/customer";
import { updateVehicleAsync } from "../../../slices/vehicle";

export const VehicleInfo = (props) => {
  const { t } = useTranslation();
  const { item } = props;
  const dispatch = useDispatch();

  return (
    <>
      <Formik
        initialValues={{
          id: item?.id,
          number_plate: item?.number_plate,
          model: item?.model,
          make: item?.make,
          nickname: item?.nickname,
          model_year: item?.model_year,
          body_style: item?.body_style,
          description: item?.description,
          current_odometer_in_kilometers: item?.current_odometer_in_kilometers,
          vehicle_identification_number: item?.vehicle_identification_number,

          submit: null,
          active: item?.active || false,
        }}
        validationSchema={Yup.object({
          number_plate: Yup.string()
            .max(255)
            .required(t("Number Plate is required")),
          description: Yup.string(),
        })}
        onSubmit={(values, helpers) => {
          try {
            toast.promise(dispatch(updateVehicleAsync(values)), {
              loading: t("Saving...."),
              success: (data) => {
                if (data.status === 500) throw new Error("Server error");
                return t("Data was saved!");
              },
              error: t("Something went wrong. Could not save data!"),
            });
            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
          } catch (err) {
            toast.error("Nope");
            console.error(err);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                <div className="grid sm:grid-cols-8 gap-6">
                  <div className="sm:col-span-2">
                    <PMSwitch label="Active" name="active" {...formik} />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t("Contact information")}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500"></p>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <PMField
                      name="number_plate"
                      type="text"
                      label="Number Plate"
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <PMField
                      name="model"
                      type="text"
                      label="Model"
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <PMField
                      name="nickname"
                      type="text"
                      label="Nickname"
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <PMField name="make" type="text" label="Make" {...formik} />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <PMField
                      name="body_style"
                      type="text"
                      label="Body Style"
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <PMField
                      name="model_year"
                      type="text"
                      label="Model Year"
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <PMField
                      name="vehicle_identification_number"
                      type="text"
                      label="Vehicle Identification Number"
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <PMField
                      name="current_odometer_in_kilometers"
                      type="text"
                      label="Current Odometer In Kilometers"
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6">
                    <PMField
                      name="description"
                      type="text"
                      label="Description"
                      multiline
                      rows={2}
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2"></div>
                </div>
              </div>
            </div>

            {/* <PromptIfDirty {...formik}/> */}
            <FormikSaveBar {...formik} />
          </Form>
        )}
      </Formik>
    </>
  );
};
