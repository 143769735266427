/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          sky: colors.sky,
          teal: colors.teal,
          rose: colors.rose,
        },
      },
    },
  }
  ```
*/

import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { TWclassNames } from "./Div";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PMLinkCard(props) {
  const navigate = useNavigate();
  const { actions, cols } = props;

  const handleAction = (action) => {
    if (action.action) {
      action.action();
    } else if (action.path) {
      navigate(action.path);
    }
  };

  const isShowing = (action) => {
    if (action.show === undefined) {
      return true;
    } else if (action.show === true) {
      return true;
    } else return false;
  };

  function handleOptionChange(event) {
    handleAction(actions[event.target.value]);
  }

  return (
    <>
      <div className="sm:hidden  w-full">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          onChange={handleOptionChange}
        >
          <option key="-1">Actions</option>
          {actions?.map((tab, index) => (
            <option key={index + "-" + tab.id} value={index}>
              {tab.description}
            </option>
          ))}
        </select>
      </div>
      <div
        className={`hidden sm:grid grid-cols-${cols} gap-4 sm:grid-cols-${cols}`}
      >
        {!actions
          ? null
          : actions.map((action) => {
              if (isShowing(action)) {
                return (
                  <>
                    <div
                      key={action.label}
                      onClick={() => handleAction(action)}
                      className={TWclassNames(
                        action.completed ? "bg-green-200" : "bg-white",
                        "relative flex items-center grid-span-1 space-x-3 rounded-lg border border-gray-300 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                      )}
                    >
                      <div className="flex-shrink-0">
                        <span
                          className={classNames(
                            action.iconBackground,
                            action.iconForeground,
                            "rounded-lg inline-flex p-3 ring-4 ring-white"
                          )}
                        >
                          <action.icon className="h-6 w-6" aria-hidden="true" />
                        </span>
                      </div>
                      <div className="min-w-0 flex-1">
                        <a className="focus:outline-none cursor-pointer">
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          />
                          <p className="text-sm font-medium text-gray-900">
                            {action.label}
                          </p>
                          <p className="truncate text-sm text-gray-500">
                            {action.description}
                          </p>
                        </a>
                      </div>
                    </div>
                  </>
                );
              }
            })}
      </div>
    </>
  );
}

PMLinkCard.defaultProps = {
  cols: 1,
};
