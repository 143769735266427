import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { TWclassNames } from "../Div";

export const BigDatePicker = (props) => {
  function getCurrentMonth() {
    const today = new Date();
    return today.getMonth() + 1;
  }

  function getCurrentYear() {
    const today = new Date();
    return today.getFullYear();
  }

  const { onChange, selectedDate } = props;

  const [currentMonth, setCurrentMonth] = useState(getCurrentMonth());
  const [currentYear, setCurrentYear] = useState(getCurrentYear());

  const handleOnChange = (day) => {
    onChange(day.date);
  };

  const handleMonthChange = (newMonth) => {
    let newYear = currentYear;
    if (newMonth > 12) {
      newMonth = 1;
      newYear = currentYear + 1;
    }
    if (newMonth < 1) {
      newMonth = 12;
      newYear = currentYear - 1;
    }
    setCurrentMonth(newMonth);
    setCurrentYear(newYear);
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getDaysForCurrentMonth = () => {
    // 1. Create an empty array to hold the days
    const days = [];
    // 2. Get the current date and create a new date object for the first day of the month
    const today = new Date();
    const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1);
    // 3. Get the last day of the month
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      currentMonth,
      0
    ).getDate();
    // 4. Get the index of the first day of the month
    const firstDayOfMonthIndex = firstDayOfMonth.getDay();
    // 5. Get the index of the last day of the month
    const lastDayOfMonthIndex = new Date(
      today.getFullYear(),
      currentMonth - 1,
      lastDayOfMonth
    ).getDay();
    // 6. Get the number of days before the first day of the month
    const daysBefore = firstDayOfMonthIndex - 2;
    // 7. Get the number of days after the last day of the month
    const daysAfter = 42 - (daysBefore + lastDayOfMonth);

    // 8. Get the total number of days in the month
    const totalDays = daysBefore + lastDayOfMonth + daysAfter;
    // 9. Loop through the total number of days and create an object for each day
    for (let i = 1; i <= totalDays; i++) {
      // 10. Get the day of the month
      const day = i - daysBefore;
      // 11. Determine if the day is in the current month
      const isCurrentMonth = day > 0 && day <= lastDayOfMonth;
      // 12. Determine if the day is today
      const isToday =
        isCurrentMonth &&
        today.getDate() + 1 === day &&
        today.getMonth() + 1 === currentMonth;

      // 13. Create a new date object for the day
      const date = new Date(today.getFullYear(), currentMonth - 1, day)
        .toISOString()
        .substring(0, 10);

      // 14. Determine if the day is selected
      const isSelected = date === selectedDate;

      // 15. Add the day to the days array
      days.push({ date, isCurrentMonth, isToday, isSelected });
    }
    // 16. Return the days array
    return days;
  };

  const [currentDays, setCurrentDays] = useState(getDaysForCurrentMonth());

  const days = currentDays;

  useEffect(() => {
    setCurrentDays(getDaysForCurrentMonth());
  }, [currentMonth, currentYear, selectedDate]);

  return (
    <div className="text-center ">
      <div className="flex items-center text-gray-900">
        <button
          onClick={() => handleMonthChange(currentMonth - 1)}
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-sm font-semibold">
          {monthNames[currentMonth - 1] + " " + currentYear}
        </div>
        <button
          onClick={() => handleMonthChange(currentMonth + 1)}
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
        <div>S</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        {days.map((day, dayIdx) => (
          <button
            key={day.date}
            type="button"
            onClick={() => handleOnChange(day)}
            className={TWclassNames(
              "py-1.5 hover:bg-gray-100 focus:z-10",
              day.isCurrentMonth ? "bg-white" : "bg-gray-50",
              (day.isSelected || day.isToday) && "font-semibold",
              day.isSelected && "text-white",
              !day.isSelected &&
                day.isCurrentMonth &&
                !day.isToday &&
                "text-gray-900",
              !day.isSelected &&
                !day.isCurrentMonth &&
                !day.isToday &&
                "text-gray-400",
              day.isToday && !day.isSelected && "text-indigo-600",
              dayIdx === 0 && "rounded-tl-lg",
              dayIdx === 6 && "rounded-tr-lg",
              dayIdx === days.length - 7 && "rounded-bl-lg",
              dayIdx === days.length - 1 && "rounded-br-lg"
            )}
          >
            <time
              dateTime={day.date}
              className={TWclassNames(
                "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                day.isSelected && day.isToday && "bg-indigo-600",
                day.isSelected && !day.isToday && "bg-gray-900"
              )}
            >
              {day.date.split("-").pop().replace(/^0/, "")}
            </time>
          </button>
        ))}
      </div>
      {/* <button
        type="button"
        className="mt-8 w-full rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Add event
      </button> */}
    </div>
  );
};
