import axios from "./axios";

export async function fetchVehicles(controller) {
  return axios({
    method: "get",
    url: "/vehicles",
    params: controller,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchVehicle(id) {
  return axios.get(`/vehicles/` + id);
}

export async function createVehicle(payload) {
  return axios
    .post(`/vehicles`, { hour: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
export async function updateVehicle(payload) {
  return axios
    .put(`/vehicles/${payload.id}`, { hour: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function deleteVehicle(id) {
  return axios
    .delete(`/vehicles/${id}`)

    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
