import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchHours,
  fetchHour,
  updateHour,
  createHour,
  deleteHour,
} from "../api/hour";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  totalCount: 0,
  currentCount: 0,
  newCount: 0,
};

export const initialController = {
  filters: [],
  page: 0,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const deleteHourAsync = createAsyncThunk(
  "hours/deleteHourAsync",
  async (id) => {
    let response = await deleteHour(id);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createHourAsync = createAsyncThunk(
  "hours/createHourAsync",
  async (payload) => {
    let response = await createHour(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const updateHourAsync = createAsyncThunk(
  "hours/updateHourAsync",
  async (payload) => {
    let response = await updateHour(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);
export const getHoursAsync = createAsyncThunk(
  "hours/getHoursAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchHours(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

export const getHourAsync = createAsyncThunk(
  "hours/getHourAsync",
  async (id, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchHour(id);

    // If errors, reject and return response
    if (response.error) return rejectWithValue(response);

    // If all good, return response.
    return response;
  }
);

const hourSlice = createSlice({
  name: "hours",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHoursAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getHoursAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.currentCount = action.payload.currentCount;
        state.newestHoursCount = action.payload.newCount;
      })
      .addCase(getHoursAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch hours....is controller missing?",
        ];
      })
      .addCase(updateHourAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateHourAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data[index] = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(updateHourAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update hour"];
      })
      .addCase(createHourAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createHourAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
        });
      })
      .addCase(createHourAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update hour"];
      })
      .addCase(deleteHourAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteHourAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data.splice(index, 1);
          // draftState.data = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(deleteHourAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update hour"];
      })
      .addCase(getHourAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getHourAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.hour = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(getHourAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update product."];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = hourSlice;

export default hourSlice;
