import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  OperationCanFinish,
  dispatchWithToast,
  mapErrors,
} from "../../../Utils";
import PMSwitch from "../../../utils/PMSwitch";
import {
  createReportAsync,
  updateReportAsync,
} from "../../../../slices/service/reports";
import { fetchReport } from "../../../../api/service/report";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import OrderItems from "../../orders/order-items";
import PMSteps from "../../../utils/PMSteps";
import { ReportVariables } from "./report-variables";
import { ReportReportForm } from "./forms/report";
import { ReportHoursForm } from "./forms/hours";
import { PMField } from "../../../utils/PMField";
import { useUserHasAccess } from "../../../../routes/roleOnly";
import ChecklistItemLines from "../../quality/checklists/checklistItemLines";
import { PMNotice } from "../../../utils/notifications";
import { CustomerSystemForm } from "../../customers/systems/customer-system";

export default function ServiceReportFinishModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    report: {},
  });

  const adminRole = useUserHasAccess({ role: "admin" });

  const { open, onClose, selected, fields, project, customer, report_type } =
    props;

  const { report, isLoading, error, errors } = itemState;

  const getReport = async () => {
    await fetchReport(selected.id)
      .then((res) => {
        if (res.data.id) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            report: res.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err.data.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          report: {},
          error: true,
          errors: errors,
        }));
      });
  };

  const createOrUpdateDispatch = (data) => {
    if (report?.id) {
      const res = dispatch(updateReportAsync(data));
      return res;
    } else {
      const res = dispatch(createReportAsync(data));
      return res;
    }
  };

  const [stages, setStages] = useState([
    {
      id: 1,
      title: t("Rapport"),
      description: t("report.write.description"),
      current: true,
      completed: false,
      active: true,
    },
    {
      id: 2,
      title: t("System"),
      description: t("report.write.description"),
      active: true,
    },
    {
      id: 3,
      title: t("Items"),
      description: t("report.items.description"),
      current: false,
      completed: false,
      active: true,
    },
    {
      id: 4,
      title: t("Variables"),
      description: t("report.variables.description"),
      current: false,
      completed: false,
      active: true,
    },
    {
      id: 5,
      title: t("Checklist"),
      description: t("report.checklist.description"),
      current: false,
      completed: false,
      active: true,
    },
    {
      id: 6,
      title: t("Hours"),
      description: t("report.hours.description"),
      current: false,
      completed: false,
      active: true,
    },
    {
      id: 7,
      title: t("Finish"),
      description: t("report.finish.description"),
      current: false,
      completed: false,
      active: adminRole,
    },
  ]);

  const [stage, setStage] = useState();

  const activeStages = stages.filter((stage) => stage.active === true);

  useEffect(() => {
    if (open) {
      setRefresh(false);
      setCurrentStageIdx(0);
      setStage(activeStages[0]);
      setItemState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      if (selected?.id) {
        getReport();
      } else {
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
    } else {
      setItemState((prevState) => ({
        ...prevState,
        report: {},
      }));
    }
  }, [dispatch, open]);

  const handleChecklistItemUpdate = (data, formik) => {};

  function anyFinishErrors() {
    // Check if there is any errors with type "create_order" in report.operations
    if (report?.operations) {
      const errors = report.operations.filter(
        (operation) => operation.name === "finish_report"
      );
      return errors.length > 0;
    }
  }

  function FinishErrors() {
    // Return errors with type "create_order" in report.operations
    if (report?.operations) {
      const errors = report.operations.filter(
        (operation) => operation.name === "finish_report"
      );
      return (
        <div className="col-span-6 sm:col-span-6">
          <div className="text-red-500">
            {errors.map((error, index) => (
              <div key={index}>{error.message}</div>
            ))}
          </div>
        </div>
      );
    }
  }

  const stageOne = (report, formik) => {
    return <ReportReportForm formik={formik} report={report} />;
  };

  const stageSystem = (report, formik) => {
    return (
      <CustomerSystemForm
        selected={report?.system}
        formik={formik}
        customer={report?.customer}
        onAttachSystem={(data) => {
          console.log(data);
          formik.setFieldValue("system_id", data.target.value);
        }}
      />
    );
  };

  const stageTwo = (report, formik) => {
    return (
      <div className="grid grid-cols-6 gap-6 mt-10">
        <div className="col-span-6 sm:col-span-6">
          <OrderItems
            group={false}
            sales_price={true}
            purchase_price={false}
            discount={true}
            surcharge={false}
            drag={false}
            quantity_picked={false}
            quantity={true}
            {...formik}
          />
        </div>
      </div>
    );
  };

  const stageThree = (report, formik) => {
    return (
      <div className="grid grid-cols-6 gap-6 mt-10">
        <div className="col-span-6 sm:col-span-6">
          <ReportVariables {...formik} />
        </div>
      </div>
    );
  };

  const stageFour = (report, formik) => {
    return (
      <div className="grid grid-cols-6 gap-6 mt-10">
        <div className="col-span-6">
          {report?.checklist?.id ? (
            <ChecklistItemLines
              checklist={report?.checklist}
              items={report?.checklist?.items}
              editable={false}
              isLoading={isLoading}
              drag={false}
              addRow={false}
              deleteRow={false}
              deviation={false}
              disabled={
                report?.checklist?.is_items_editable !== undefined &&
                !report?.checklist?.is_items_editable
              }
              parentCallback={(data) =>
                formik.setFieldValue("checklist.items", data)
              }
            />
          ) : (
            <PMNotice
              title="Sjekkliste mangler"
              description="Rapporten er ikke knyttet til sjekkliste"
            />
          )}
        </div>
      </div>
    );
  };

  const stageFive = (report) => {
    return (
      <div className="grid grid-cols-6 gap-6 mt-10">
        <div className="col-span-6 sm:col-span-6">
          <ReportHoursForm report={report} />
        </div>
      </div>
    );
  };

  const stageSix = (report, formik) => {
    const canFinish = OperationCanFinish({
      operations: report?.operations,
      operationName: "finish_report",
    });

    return (
      <div className="grid grid-cols-6 gap-6 mt-10">
        {canFinish && (
          <div className="col-span-6 sm:col-span-6 border-2 border-red-200 rounded-xl p-5">
            <FinishErrors />
          </div>
        )}

        <div className="col-span-6 sm:col-span-6 border-2 border-green-200 rounded-xl p-5">
          <p className="font-semibold mb-2">Ferdigstill rapporten</p>
          <div className="py-5">
            <PMSwitch name="finished" label={t("Ferdigstill")} {...formik} />
          </div>
          <PMField
            name="finished_date"
            label={t("Finished Date")}
            type="date"
            {...formik}
          />
          {formik.values.finished && (
            <p>Rapporten ferdigstilles ved oppdatering.</p>
          )}
        </div>
      </div>
    );
  };

  const [currentStageIdx, setCurrentStageIdx] = useState(0);

  const [isLastStage, setIsLastStage] = useState(false);

  const activePageChecker = (stage) => {
    // Check if active page, else set next page
    if (stage.active) {
      const thisStageId = stage.id;
      const currentStageIdx = activeStages.findIndex(
        (stage) => stage.id === thisStageId
      );
      setStage((prevState) => stage);
      setCurrentStageIdx(currentStageIdx);
    } else {
      // create a loop that goes to next stage until it finds an active stage
      let nextStage = stage.id;
      while (nextStage < activeStages.length) {
        if (activeStages[nextStage].active) {
          setStage((prevState) => activeStages[nextStage - 1]);
          // idx of current "nextStage"
          const nextStageIdx = activeStages.findIndex(
            (stage) => stage.id === nextStage
          );
          setCurrentStageIdx(nextStage - 1);
          break;
        } else {
          nextStage++;
        }
      }
    }
  };

  const handleNextPage = (formik) => {
    if (currentStageIdx !== activeStages?.length - 1) {
      const nextStage = activeStages[currentStageIdx + 1];
      activePageChecker(nextStage);
    }
    formik.submitForm();
  };

  const handleStageChange = (stage) => {
    setItemState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    setTimeout(() => {
      setItemState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }, 250);

    activePageChecker(stage);
  };

  const [refresh, setRefresh] = useState(false);

  const handleFinish = (formik) => {
    formik.submitForm();
    onClose(true);
    setRefresh(true);
  };

  const handleOnRefresh = (data) => {
    setItemState((prevState) => ({
      ...prevState,
      report: data,
    }));

    if (props.onRefresh && refresh) {
      props.onRefresh(data || data?.data);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full sm:max-w-4xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    <div className="flex items-center py-5 w-full">
                      <div className="block w-[60%]">
                        <h2
                          id="applicant-information-title"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          {report?.id ? t("report.update") : t("report.create")}{" "}
                        </h2>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
                      </div>
                      <div className="relative right-0 text-right w-[40%]">
                        {report?.id &&
                          props.module !== "report" &&
                          props.module !== undefined && (
                            <button
                              onClick={() =>
                                navigate(
                                  `/dashboard/service/reports/` + report?.id
                                )
                              }
                              type="button"
                              className="inline-flex items-center rounded-md border border-transparent bg-blue-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              <ArrowRightIcon
                                className="-ml-0.5 mr-2 h-4 w-4"
                                aria-hidden="true"
                              />
                              Gå til rapport
                            </button>
                          )}
                      </div>
                    </div>
                  </Dialog.Title>

                  <div>
                    <PMSteps
                      steps={activeStages}
                      selected={stage}
                      onChange={handleStageChange}
                    />
                  </div>

                  {isLoading ? (
                    <ResourceLoading />
                  ) : (
                    <Formik
                      initialValues={{
                        id: report?.id
                          ? report?.id
                          : fields?.id
                          ? fields?.id
                          : null,
                        customer_id: report?.customer
                          ? report?.customer.id
                          : customer?.id
                          ? customer?.id
                          : null,
                        report_type: report?.report_type
                          ? report?.report_type.id
                          : report_type?.id
                          ? report_type?.id
                          : null,
                        project_id: report?.project
                          ? report?.project.id
                          : project?.id
                          ? project?.id
                          : null,
                        order_date: report?.order_date
                          ? report?.order_date
                          : new Date().toISOString().substring(0, 10),
                        description: report?.description
                          ? report?.description
                          : fields?.description
                          ? fields?.description
                          : null,
                        report: report?.report,
                        internal_report: report?.internal_report,
                        must_be_rectified: report?.must_be_rectified,
                        complaint: report?.complaint,
                        items: report?.items,
                        activities: report?.activities,
                        variables: report?.variables,
                        report_number: report?.report_number,
                        internal_description: report?.internal_description,
                        useCustomerAddress: report?.useCustomerAddress,
                        delivery_address_id: report?.delivery_address?.id,
                        // finished_date: new Date()
                        //   .toISOString()
                        //   .substring(0, 10),
                        // finished: report?.finished || false,
                        submit: null,
                      }}
                      validationSchema={Yup.object({
                        customer_id: Yup.string().required(
                          t("customer.required")
                        ),
                        report_type: Yup.string().required(
                          t("report.type.required")
                        ),
                        description: Yup.string(),
                      })}
                      onSubmit={(values, helpers) => {
                        try {
                          dispatchWithToast(
                            createOrUpdateDispatch(values),
                            handleOnRefresh
                          );

                          helpers.setStatus({ success: true });
                          helpers.setSubmitting(false);
                        } catch (err) {
                          toast.error("Something went wrong...");
                          console.error(err);
                          helpers.setStatus({ success: false });
                          helpers.setErrors({ submit: err.message });
                          helpers.setSubmitting(false);
                        }
                      }}
                    >
                      {(formik) => (
                        <Form>
                          {stage?.id === 1 && stageOne(report, formik)}
                          {stage?.id === 2 && stageSystem(report, formik)}
                          {stage?.id === 3 && stageTwo(report, formik)}
                          {stage?.id === 4 && stageThree(report, formik)}
                          {stage?.id === 5 && stageFour(report, formik)}
                          {stage?.id === 6 && stageFive(report)}
                          {stage?.id === 7 && stageSix(report, formik)}

                          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-3 sm:gap-3 justify-between">
                            <div className="w-full col-span-2">
                              <button
                                type="button"
                                className="justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                onClick={onClose}
                              >
                                {t("Close")}{" "}
                              </button>
                            </div>

                            <div className="flex w-full col-span-1 right align-right gap-4">
                              <button
                                disabled={
                                  currentStageIdx ===
                                    activeStages?.length - 1 &&
                                  !OperationCanFinish({
                                    operations: report?.operations,
                                    operationName: "finish_report",
                                  })
                                }
                                type="button"
                                className="disabled:bg-gray-200 right-0 rounded-md border border-transparent bg-yellow-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                onClick={() => handleFinish(formik)}
                              >
                                {report?.id ? t("Update") : t("Create")}
                                {" & Close"}
                              </button>
                              {currentStageIdx !== activeStages?.length - 1 && (
                                <button
                                  type="button"
                                  className="rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                  onClick={() => handleNextPage(formik)}
                                >
                                  Next
                                </button>
                              )}
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
