// Popup.js
import React from "react";
import "../../style/SimplePopup.css"; // Assuming you have basic styles defined in Popup.css

const SimplePopup = ({ content, textSize = "md", children }) => {
  return (
    <div className="popup-container">
      {children}
      <div className={`popup-content ${textSize}`}>{content}</div>
    </div>
  );
};

export default SimplePopup;
