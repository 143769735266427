import { Formik } from "formik";
import { PMDialog } from "./PMDialog";
import { PMButton } from "./PMButton";
import { PMField } from "./PMField";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export const DatePickerModal = ({ onClose, open, title, onChange }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [payload, setPayload] = useState({
    date_from: searchParams.get("date_from"),
    date_to: searchParams.get("date_to"),
  });

  const updatePayload = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  const nullPayload = () => {
    setPayload({ date_from: null, date_to: null });
    // update form
    onChange(payload);
    onClose();
  };

  const updateForm = (e) => {
    e.preventDefault();
    onChange(payload);
    onClose();
  };

  return (
    <PMDialog open={open} onClose={onClose} title={title}>
      <form onSubmit={updateForm}>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <PMField
              name="date_from"
              label={t("Date from")}
              type="date"
              value={payload.date_from}
              onChange={(e) => updatePayload(e)}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <PMField
              name="date_to"
              label={t("Date To")}
              type="date"
              value={payload.date_to}
              onChange={(e) => updatePayload(e)}
            />
          </div>

          <div className="col-span-6 sm:col-span-6">
            <PMButton type="submit">Oppdater</PMButton>
          </div>

          <div className="col-span-6 sm:col-span-6">
            <PMButton type="button" onClick={() => nullPayload()}>
              Nullstill
            </PMButton>
          </div>
        </div>
      </form>
    </PMDialog>
  );
};
