import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

export const ResourceError = (props) => {
  const { errors } = props;

  return (
    <div className="w-full flex items-center">
      <div className="px-5 py-5 bg-red-400 rounded-xl rounded-r-none shadow ">
        <ExclamationCircleIcon className="h-6 w-6 text-red-100 " />
      </div>
      <div className="w-full px-5 py-5 bg-red-600 text-red-100 rounded-xl rounded-l-none shadow ">
        <span>
          Error!{" "}
          {errors ? errors : "Noe gikk galt, kunne ikke laste inn ønsket data"}{" "}
        </span>
      </div>
    </div>
  );
};
