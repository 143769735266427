import { useState } from "react";
import { PMPagination } from "../../utils/PMPagination";
import ProConfirmation from "../../utils/ProConfirmation";
import { ResourceEmpty } from "../../utils/ResourceEmpty";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { useDispatch } from "react-redux";
import { deleteHourAsync } from "../../../slices/hour";
import { dispatchWithToast } from "../../Utils";
import { toast } from "react-hot-toast";

export const SimpleHourList = ({
  hours,
  loading,
  onPageChange,
  itemsCount,
  page,
  totalItemsCount,
  onRefresh,
  onItemSelect,
}) => {
  const [deletePromptOpen, setDeletePromptOpen] = useState(false);
  const [selectedHour, setSelectedHour] = useState(null);

  const handleDeletePrompt = (hour) => {
    setDeletePromptOpen(true);
    setSelectedHour(hour);
  };

  const dispatch = useDispatch();

  const handleDeleteItem = () => {
    dispatchWithToast(
      dispatch(deleteHourAsync(selectedHour?.id)),
      null,
      setDeletePromptOpen(false)
    );
  };

  const handleEditItem = (hour) => {
    onItemSelect(hour);
  };

  return (
    <>
      <div className="">
        <div className="flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Ansatt
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Aktivitet
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Dato
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Timer
                    </th>

                    <th
                      scope="col"
                      className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">Edit</span>
                      <span className="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {!loading ? (
                    hours?.length > 0 ? (
                      hours?.map((hour) => (
                        <tr key={hour?.id}>
                          <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                            {hour?.user?.displayTitle}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                            {hour?.activity?.displayTitle}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                            {hour?.date}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {hour?.time}
                          </td>

                          <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                            <a
                              onClick={() => handleEditItem(hour)}
                              className="text-blue-400 hover:text-blue-900 cursor-pointer"
                            >
                              Edit<span className="sr-only">, {hour?.id}</span>
                            </a>
                            <a
                              onClick={() => handleDeletePrompt(hour)}
                              className="text-red-600 hover:text-red-900 ml-5 cursor-pointer"
                            >
                              Delete
                              <span className="sr-only">, {hour?.id}</span>
                            </a>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4}>
                          <ResourceEmpty
                            text={"Fant ingen registrerte timer"}
                          />
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td colSpan={4}>
                        <ResourceLoading />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <PMPagination
                disabled={loading}
                onPageChange={onPageChange}
                page={page}
                totalCount={totalItemsCount}
                currentCount={hours && hours?.length}
              />
            </div>
          </div>
        </div>
      </div>

      <ProConfirmation
        severity="confirmation"
        open={deletePromptOpen}
        message={"Are you sure you want to delete this hour?"}
        action="delete"
        module="Hour"
        // item_id={confirmation.item_id}
        yesCallback={() => handleDeleteItem()}
        cancelCallback={() => setDeletePromptOpen(false)}
      />
    </>
  );
};
