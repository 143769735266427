import {
  CheckBadgeIcon,
  ExclamationTriangleIcon,
  PencilIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import ReportAgreementModal from "./report-agreement-modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReportAppointmentApprovedStatus } from "./utils";
import SMSDialog from "../../sms/dialog";
import { CopyToClipboard, Popup } from "../../../utils/Div";
export const ServiceReportContactSection = (props) => {
  const { report } = props;

  const [agreementModalOpen, setAgreementModalOpen] = useState(false);

  const [smsDialogOpen, setSMSDialogOpen] = useState(false);

  const { t } = useTranslation();

  function AgreementStatusInfo() {
    return (
      <>
        {report?.appointment?.has_agreement ? (
          <>
            {report?.appointment?.approved ? (
              <div className="flex items-center space-x-2">
                <CheckBadgeIcon className="inline w-5 h-5 text-green-700" />
                <span>Avtalen er bekreftet</span>
              </div>
            ) : (
              <div className="flex items-center space-x-2">
                <ExclamationTriangleIcon className="inline w-5 h-5 text-red-700" />
                <span>Avtalen er ikke bekreftet</span>
              </div>
            )}

            {report?.appointment?.text_sent ? (
              <>
                <div className="flex items-center space-x-2">
                  <CheckBadgeIcon className="inline w-5 h-5 text-green-700" />
                  <span>Melding er sendt</span>
                </div>
                {report?.appointment?.approved_via_text ? (
                  <div className="flex items-center space-x-2">
                    <CheckBadgeIcon className="inline w-5 h-5 text-green-700" />
                    <span>Melding er bekreftet via SMS</span>
                  </div>
                ) : null}
              </>
            ) : (
              <div className="flex items-center space-x-2">
                <ExclamationTriangleIcon className="inline w-5 h-5 text-red-700" />
                <span>Melding er ikke sendt</span>
              </div>
            )}

            {report?.appointment?.email_sent ? (
              <>
                <div className="flex items-center space-x-2">
                  <CheckBadgeIcon className="inline w-5 h-5 text-green-700" />
                  <span>E-post er sendt</span>
                </div>
              </>
            ) : (
              <div className="flex items-center space-x-2">
                <ExclamationTriangleIcon className="inline w-5 h-5 text-red-700" />
                <span>E-post er ikke sendt</span>
              </div>
            )}
          </>
        ) : (
          <div className="flex items-center space-x-2">
            <ExclamationTriangleIcon className="inline w-5 h-5 text-yellow-700" />
            <span>Ingen avtale</span>
          </div>
        )}
      </>
    );
  }
  return (
    <>
      {/* Description list*/}
      <section
        className="lg:max-w[7xl] h-full"
        aria-labelledby="applicant-information-title"
      >
        <div className="bg-white shadow sm:rounded-lg h-full">
          <div className="flex items-center justify-between px-4 py-3 sm:px-6 w-full">
            <div className="">
              <h2
                id="applicant-information-title"
                className="text-lg font-medium leading-6 text-gray-900 flex"
              >
                Kontaktperson
              </h2>
              <p className="mt-1 max-w-2xl text-xs text-gray-500">
                Informasjon om rapportens avtale
              </p>
              <span></span>
            </div>
          </div>
          <div className="border-t border-gray-200 px-4 py-3 sm:px-4">
            <dl className="grid grid-cols-3 gap-x-4 gap-y-4 sm:grid-cols-3">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Navn</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {report?.contact?.name}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Mobil</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {report?.contact?.mobile}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  E-postadresse
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {report?.contact.email}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </section>
    </>
  );
};

ServiceReportContactSection.defaultProps = {
  onRefresh: null,
};
