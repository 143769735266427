import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useState, useEffect } from "react";
import { TWclassNames } from "../../utils/Div";
import { toast } from "react-hot-toast";
import { fetchNotes } from "../../../api/notes";
import { NoteDialog } from "./dialog";

export const Notes = (props) => {
  const { module_param, module } = props;
  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getNotes = async () => {
    setIsLoading(true);
    const controller = {
      filters: [
        {
          name: props.module,
          value: props.module_param,
        },
      ],
      page: 1,
      per_page: 10,
      query: "",
      sort: "desc",
      sortBy: "updated_at",
      view: "all",
    };

    const response = await fetchNotes(controller)
      .then((res) => {
        var array = [];
        res.data.data.map((row, i) => {
          const rowParams = {
            ...row,
          };
          array.push(rowParams);
        });
        setNotes(array);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  function buttonColor() {
    if (!module_param || !module) {
      return "bg-red-400 border-red-600";
    } else {
      return "border-blue-400";
    }
  }

  // Check if module_param and module  set, else TOAST error message when trying to open.
  function handleOpenDialog() {
    if (!module_param || !module) {
      toast.error(
        "Module && Module Param  missing. Can't fetch or create notes."
      );
    } else {
      setOpen(!open);
    }
  }

  useEffect(() => {
    getNotes();
  }, []);

  function handleCreate(data) {
    // Add data response to notes
    setNotes((notes) => [...notes, data]);
  }

  return (
    <>
      <button
        onClick={() => handleOpenDialog(!open)}
        type="button"
        className={TWclassNames(
          buttonColor(),
          "group -m-2 flex items-center p-2 border-2 rounded-md"
        )}
      >
        <PencilSquareIcon
          className="h-4 w-4 flex-shrink-0 text-black group-hover:text-gray-500 "
          aria-hidden="true"
        />
        <span className="absolute ml-4 mb-7 text-sm font-medium text-gray-700 group-hover:text-gray-800 rounded-md bg-blue-400 p-1">
          {notes ? notes?.length : 0}
        </span>
        <span className="sr-only">Notes, view notes</span>
      </button>

      {/* Dialog START */}
      <NoteDialog
        notes={notes}
        open={open}
        isLoading={isLoading}
        onLoading={(state) => setIsLoading(state)}
        onCreate={(data) => handleCreate(data)}
        onClose={() => setOpen(!open)}
        {...props}
      />
      {/* Dialog END */}
    </>
  );
};

Notes.defaultProps = {
  module_param: "",
  module: "",
};
