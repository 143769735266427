import axios from "../axios";

export async function fetchChecklistTypes(controller) {
  return axios({
    method: "get",
    url: "/quality/checklists/types",
    params: controller,
  });
}

export async function fetchChecklistType(payload) {
  return axios({
    method: "get",
    url: "/quality/checklists/types/" + payload,
  });
}

export async function createChecklistType(payload) {
  return axios({
    method: "post",
    url: "/quality/checklists/types",
    data: payload,
  });
}

export async function updateChecklistType(payload) {
  return axios({
    method: "put",
    url: "/quality/checklists/types/" + payload.id,
    data: { type: payload },
  });
}

export async function deleteChecklistType(payload) {
  return axios({
    method: "delete",
    url: "/quality/checklists/types/" + payload,
  });
}
