import PropTypes from "prop-types";
import logo from "../../utils/logo.png";
import whiteLogo from "../../utils/logo-white.png";
import { TWclassNames } from "./Div";

export const Logo = (props: any) => {
  const { emblemOnly, variant, sizeClass, color } = props;

  if (color === "light") {
    return (
      <img
        src={whiteLogo}
        className={TWclassNames(sizeClass, "m-w-full mx-auto")}
      />
    );
  } else {
    return (
      <img src={logo} className={TWclassNames(sizeClass, "m-w-full mx-auto")} />
    );
  }
};

Logo.defaultProps = {
  emblemOnly: false,
  variant: "dark",
  sizeClass: "h-20",
  color: "dark",
};

Logo.propTypes = {
  emblemOnly: PropTypes.bool,
  color: PropTypes.oneOf(["light", "dark"]),
  sizeClass: PropTypes.string,
};
