import axios from "../axios";

export async function fetchCaseTypes(controller) {
  return axios({
    method: "get",
    url: "/cases/types",
    params: controller,
  });
}

export async function fetchCaseType(payload) {
  return axios({
    method: "get",
    url: `/cases/types/${payload.id}`,
    params: { ...payload },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function createCaseType(payload) {
  return axios({
    method: "post",
    url: "/cases/types",
    data: { case_type: payload },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function updateCaseType(payload) {
  return axios({
    method: "put",
    url: "/cases/types/" + payload.id,
    data: { case_type: payload },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function deleteCaseType(payload) {
  return axios({
    method: "delete",
    url: "/cases/types/" + payload,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
}
