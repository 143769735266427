import { Form, Formik } from "formik";
import { ActivitySelector } from "../../utils/moduleSelectors/activitySelector";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { createHourAsync, updateHourAsync } from "../../../slices/hour";
import { PMField } from "../../utils/PMField";
import { useSelector } from "react-redux";
import { EmployeeSelector } from "../../utils/moduleSelectors/employeeSelector";
import { useEffect, useState } from "react";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { fetchHour } from "../../../api/hour";
import CarAllowanceSelectionModal from "../driving_record/carAllowanceSelectionModal";
import PropTypes from "prop-types";
import { set } from "lodash";
import { axiosWithToast, dispatchWithToast } from "../../Utils";

export const SimpleHourForm = ({
  report,
  project,
  deviation,
  selected,
  onRefresh,
  projectRequired,
  deviationRequired,
  activityRequired,
  reportRequired,
}) => {
  const [loading, setLoading] = useState(true);

  const currentUser = useSelector((state) => state.session.currentUser);

  const [item, setItem] = useState({});

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const createOrUpdateDispatch = (data) => {
    if (item?.id) {
      setLoading(true);
      const res = dispatch(updateHourAsync(data));
      return res;
    } else {
      setLoading(true);
      const res = dispatch(createHourAsync(data));
      return res;
    }
  };

  const getItem = async (id) => {
    setLoading(true);
    fetchHour(id)
      .then((res) => {
        setItem(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selected) {
      // Fetch hour and update state
      getItem(selected.id);
    } else {
      setLoading(false);
    }
  }, [selected]);

  const handleEmptyForm = (formik) => {
    setLoading(true);
    formik.resetForm();
    setItem({});

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const [carAllowanceDialogOpen, setCarAllowanceDialogOpen] = useState(false);

  if (loading) return <ResourceLoading />;

  return (
    <Formik
      initialValues={{
        id: item?.id,
        user_id: item?.user?.id || currentUser?.id,
        project_id: item?.project?.id || project?.id,
        report_id: item?.report?.id || report?.id,
        activity_id: item?.activity?.id,
        deviation_id: item?.deviation?.id || deviation?.id,
        time: item?.time,
        date: item?.date,
        trips: item?.trips,
        submit: null,
      }}
      validationSchema={Yup.object({
        date: Yup.date().required("Date is required!"),
        time: Yup.string().required("Time is required!"),
        project_id: projectRequired
          ? Yup.string().required("Project is required!")
          : Yup.string(),
        report_id: reportRequired
          ? Yup.string().required("Report is required!")
          : Yup.string(),
        deviation_id: deviationRequired
          ? Yup.string().required("Deviation is required!")
          : Yup.string(),
        activity_id: activityRequired
          ? Yup.string().required("Activity is required!")
          : Yup.string(),
      })}
      onSubmit={(values, helpers) => {
        try {
          dispatchWithToast(
            createOrUpdateDispatch(values),
            (data) => {
              if (onRefresh) {
                onRefresh(data?.payload);
              }
              setLoading(false);
            },
            () => setLoading(false)
          );

          // toast.promise(createOrUpdateDispatch(values), {
          //   loading: t("Saving...."),
          //   success: (data) => {
          //     if (data?.payload?.id) {
          //       if (onRefresh) {
          //         onRefresh(data?.payload);
          //       }
          //       // onClose(true);
          //       setLoading(false);
          //       return t("Data was saved!");
          //     } else {
          //       throw new Error("Server error");
          //     }
          //   },
          //   error: (error) => {
          //     console.log(error);
          //     return t("Something went wrong. Could not save data!");
          //   },
          // });
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
        } catch (err) {
          setLoading(false);
          toast.error("Something went wrong...");
          console.error(err);
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }}
    >
      {(formik) => {
        if (loading) {
          return <ResourceLoading />;
        }

        return (
          <Form className="mb-20">
            {item?.id && (
              <button
                type="button"
                className="mt-6 inline-flex align-right justify-center rounded-md border border-transparent bg-blue-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={() => handleEmptyForm(formik)}
              >
                {t("New Hour")}
              </button>
            )}
            <div className="grid grid-cols-6 gap-6 mt-5">
              <div className="col-span-3 sm:col-span-3">
                <ActivitySelector
                  selected={item?.activity}
                  name="activity_id"
                  label="Aktivitet"
                  // onChange={(e) => onItemRowChange(e, item)}
                  // project={project}
                  report={report}
                  // disabled={item?.id ? true : false}
                  {...formik}
                />
              </div>

              <div className="col-span-3 sm:col-span-3">
                <EmployeeSelector
                  name="user_id"
                  selected={item?.user?.id ? item?.user : currentUser}
                  {...formik}
                />
              </div>
              <div className="col-span-3 sm:col-span-3">
                <PMField type="date" name="date" label="Date" {...formik} />
              </div>
              <div className="col-span-3 sm:col-span-3">
                <PMField name="time" label="Hours" {...formik} />
              </div>

              {formik.values?.user_id && formik.values?.date && (
                <>
                  <div className="col-span-3 sm:col-span-3">
                    <div className="flex">
                      <button
                        type="button"
                        className="inline-flex align-right justify-center rounded-md border border-transparent bg-blue-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                        onClick={() => setCarAllowanceDialogOpen(true)}
                      >
                        {t("Add Car Allowance")}
                      </button>

                      <div className="px-4 py-2">
                        {formik?.values?.trips
                          ? formik?.values?.trips?.length
                          : 0}{" "}
                        Turer valgt
                      </div>
                    </div>
                  </div>

                  <CarAllowanceSelectionModal
                    hour={formik.values}
                    open={carAllowanceDialogOpen}
                    onClose={() => setCarAllowanceDialogOpen(false)}
                    onSelect={(tripIds) =>
                      formik.setFieldValue("trips", tripIds)
                    }
                    selected={formik.values?.trips}
                  />
                </>
              )}

              <div className="col-span-3 sm:col-span-3">
                <button
                  type="button"
                  className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                  onClick={formik.submitForm}
                >
                  {item?.id ? t("Update") : t("Create")}{" "}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

SimpleHourForm.propTypes = {
  projectRequired: PropTypes.bool,
  reportRequired: PropTypes.bool,
  deviationRequired: PropTypes.bool,
  activityRequired: PropTypes.bool,
};
