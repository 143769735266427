import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { PMField } from "../../utils/PMField";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { mapErrors } from "../../Utils";
import {
  createProjectAsync,
  updateProjectAsync,
} from "../../../slices/project";
import { fetchCustomer, fetchProject } from "../../../api/project";
import {
  ArrowRightIcon,
  CheckBadgeIcon,
  CheckIcon,
  ExclamationCircleIcon,
  FolderIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CustomerSelector } from "../../utils/moduleSelectors/customerSelector";
import { DeliveryAddressSelector } from "../../utils/moduleSelectors/deliveryAddressSelector";
import { QuillEditor } from "../../utils/quill-editor";
import { DepartmentSelector } from "../../utils/moduleSelectors/departmentSelector";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { EmployeeSelector } from "../../utils/moduleSelectors/employeeSelector";
import { useSelector } from "react-redux";
import PMListbox from "../../utils/PMListbox";
import PMListboxFormik from "../../utils/PMListboxFormik";
import {
  accountCodeOptions,
  productTypeOptions,
  unitOptions,
  warehouseStatusOptions,
} from "./product-data";
import { getProductBrands } from "../../../slices/products/brands";
import PMSwitch from "../../utils/PMSwitch";
import { listUpdateProductAsync } from "../../../slices/products";
import ProductCategorySelector from "./Categories/selector/productCategorySelector";
import { PropertyList } from "../../utils/property-list";
import { PropertyListItem } from "../../utils/property-list-item";
export default function ProductMultiEditDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    project: {},
  });

  // Deconstructing props
  const { open, onClose, selected, onRefresh, selectedItems } = props;

  // Deconstruct itemState (Store)
  const { project, isLoading, error, errors } = itemState;

  const [readOnly, setReadOnly] = useState(props.readOnly);

  const [categoriesSelectorOpen, setCategoriesSelectorOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }, 500);
    } else {
      setTimeout(() => {
        setConfirmPrompt(false);
      }, 500);
    }
  }, [dispatch, open]);

  const createOrUpdateDispatch = (data) => {
    if (project?.id) {
      const res = dispatch(updateProjectAsync(data));
      return res;
    } else {
      const res = dispatch(createProjectAsync(data));
      return res;
    }
  };

  useEffect(() => {
    dispatch(getProductBrands());
  }, [dispatch]);

  const brandState = useSelector((store) => {
    return store.product_brands;
  });

  const productBrands = () => {
    const brands = [{ name: "brand", label: "Ikke valgt", value: 0 }];
    brandState?.data?.map((brand) => {
      brands.push({
        name: "brand",
        label: brand.title,
        value: brand.id,
      });
    });
    return brands;
  };

  const [optionalProjectNumber, setOptionalProjectNumber] = useState(true);

  const [confirmPrompt, setConfirmPrompt] = useState(false);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="flex items-center text-lg font-medium leading-6 text-gray-900"
                  >
                    Editing {selectedItems.length} products{" "}
                  </Dialog.Title>

                  {isLoading ? (
                    <ResourceLoading />
                  ) : (
                    <Formik
                      initialValues={{
                        ids: selectedItems.map((item) => item.id.toString()),
                        // brand_id: null,
                        // product_type: null,
                        // unit_id: null,
                        // account_coding_id: null,
                        // warehouse_status_type_id: null,
                        // active: true,
                        // warehouse: false,
                        // isSparePart: false,
                        // online_store: false,
                        submit: null,
                      }}
                      validationSchema={Yup.object({
                        project_number: Yup.string(),
                      })}
                      onSubmit={(values, helpers) => {
                        try {
                          toast.promise(
                            dispatch(listUpdateProductAsync(values)),
                            {
                              loading: t("Saving...."),
                              success: (data) => {
                                if (data?.meta?.requestStatus === "fulfilled") {
                                  onClose(true);
                                  onRefresh();
                                  return t("Data was saved!");
                                } else {
                                  throw new Error("Server error");
                                }
                              },
                              error: (error) => {
                                console.log(error);
                                return t(
                                  "Something went wrong. Could not save data!"
                                );
                              },
                            }
                          );
                          // helpers.setStatus({ success: true });
                        } catch (err) {
                          toast.error("Something went wrong...");
                          helpers.setStatus({ success: false });
                          helpers.setErrors({ submit: err.message });
                          // helpers.setSubmitting(false);
                        }
                      }}
                    >
                      {(formik) => (
                        <Form>
                          {!confirmPrompt ? (
                            <div className="grid grid-cols-6 gap-6 mt-10 mb-10">
                              <div className="col-span-6 sm:col-span-6">
                                <PMListboxFormik
                                  label={t("product.brand")}
                                  name="brand_id"
                                  options={productBrands()}
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-6">
                                <PMListboxFormik
                                  label={t("Product Type")}
                                  name="product_type"
                                  options={productTypeOptions}
                                  {...formik}
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-6">
                                <PMListboxFormik
                                  label={t("Unit")}
                                  name="unit_id"
                                  options={unitOptions}
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-6">
                                <PMListboxFormik
                                  label={t("Account Code")}
                                  name="account_coding_id"
                                  options={accountCodeOptions}
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-6">
                                <PMListboxFormik
                                  label={t("Warehouse Status Type")}
                                  name="warehouse_status_type_id"
                                  options={warehouseStatusOptions}
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-2">
                                <PMSwitch
                                  label={t("Active")}
                                  name="active"
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-2">
                                <PMSwitch
                                  label={t("Warehouse")}
                                  name="warehouse"
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-2">
                                <PMSwitch
                                  label={t("Sparepart")}
                                  name="isSparePart"
                                  {...formik}
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-2">
                                <PMSwitch
                                  label={t("Online Store")}
                                  name="online_store"
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-6">
                                <PropertyList cols={1}>
                                  <PropertyListItem
                                    label={t("Categories")}
                                    value={
                                      formik?.values.category_ids?.length
                                        ? formik?.values.category_ids?.length +
                                          " selected"
                                        : 0 + " selected"
                                    }
                                    icon={FolderIcon}
                                    onClick={() =>
                                      setCategoriesSelectorOpen(true)
                                    }
                                  />
                                </PropertyList>
                              </div>
                            </div>
                          ) : (
                            <div className="px-10 py-10">
                              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                                <ExclamationCircleIcon
                                  className="h-6 w-6 text-red-600"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="mt-3 text-center sm:mt-5">
                                <Dialog.Title
                                  as="h3"
                                  className="text-base font-semibold leading-6 text-gray-900"
                                >
                                  Er du sikker?
                                </Dialog.Title>
                                <div className="mt-2">
                                  <p className="text-sm text-gray-500">
                                    Du redigerer nå {selectedItems?.length}{" "}
                                    produkt
                                    {selectedItems?.length > 1 ? "er" : ""} og
                                    dette kan ikke ikke reverseres.
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                            <button
                              type="button"
                              className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                              onClick={onClose}
                            >
                              {t("Close")}{" "}
                            </button>

                            {!confirmPrompt ? (
                              <button
                                type="button"
                                className="bg-blue-400 rounded shadow text-white"
                                onClick={() => setConfirmPrompt(true)}
                              >
                                {t("Update")}
                              </button>
                            ) : (
                              <LoadingButton
                                loading={isLoading}
                                disabled={isLoading}
                                loadingPosition="start"
                                startIcon={<CheckBadgeIcon />}
                                type="button"
                                variant="contained"
                                color={"success"}
                                onClick={formik.submitForm}
                              >
                                {t("Confirm")}
                              </LoadingButton>
                            )}
                          </div>

                          <ProductCategorySelector
                            open={categoriesSelectorOpen}
                            selectorCloseCallback={() =>
                              setCategoriesSelectorOpen(false)
                            }
                            onSelect={(data) =>
                              formik.setFieldValue("category_ids", data)
                            }
                          />
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

ProductMultiEditDialog.defaultProps = {
  selected: null,
  // Read only is used when only wanting to display info. Update or creating will not work.
  readOnly: false,
  open: false,
  onRefresh: () => null,
  onClose: () => toast.error("Missing onClose function. Cannot close dialog"),
};
