import {
  ArrowLeftIcon,
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import { useEffect } from "react";
export const PMPagination = (props) => {
  const {
    currentCount,
    totalCount,
    page,
    onPageChange,
    perPage,
    showCount,
    showPages,
  } = props;
  // CurrentCount = How manny rows of items that is currentley showing
  // TotalCount = How manny rows of ACTIVE items that is in the database
  // page = What page is currentley showing
  // onPageChange = Callback function, Function that changes the page
  // pages = How manny pages that is needed to show all the items

  // How manny items there is per. page
  const itemsPerPage = perPage > 1 ? perPage : 200;

  const totalPages = Math.ceil(totalCount / itemsPerPage);

  let startPage, endPage;

  if (totalPages <= 10) {
    startPage = 1;
    endPage = totalPages;
  } else {
    if (page <= 6) {
      startPage = 1;
      endPage = 10;
    } else if (page + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
    } else {
      startPage = page - 5;
      endPage = page + 5;
    }
  }

  const pages = [];

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  const handleChangePage = (pageNumber) => {
    if (pageNumber < 1) return;
    if (pageNumber > totalPages) return;
    onPageChange?.(pageNumber);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const renderPages = (
    <div className="flex items-center">
      {pages.at(0) > 1 && (
        <div
          onClick={() => handleChangePage(page - 1)}
          href="#"
          className={classNames(
            "font-normal",
            "inline-flex cursor-pointer items-center border-b-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          )}
        >
          <ArrowLeftIcon className="h-5 w-5" />
        </div>
      )}
      <div className="p-2">
        {pages.map((p) => {
          return (
            <div
              onClick={() => handleChangePage(p)}
              key={p}
              className={classNames(
                p === parseInt(page, 10)
                  ? "font-bold border-b-2 border-blue-400 text-blue-400"
                  : "font-medium text-gray-500 border-transparent border-b-2 border-blue-400",
                "inline-flex cursor-pointer items-center px-4 pt-4 text-sm  hover:border-gray-300 hover:text-gray-700"
              )}
            >
              {p}
            </div>
          );
        })}
      </div>
      {pages.at(-1) < totalPages && (
        <div
          onClick={() => handleChangePage(page + 1)}
          href="#"
          className={classNames(
            "font-normal",
            "inline-flex cursor-pointer items-center border-b-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          )}
        >
          <ArrowRightIcon className="h-5 w-5" />
        </div>
      )}
    </div>
  );

  return (
    <>
      <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
        {props.showNextPrev && (
          <div className="-mt-px flex w-0 flex-1">
            <div
              onClick={() => handleChangePage(page - 1)}
              className="cursor-pointer inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >
              <ArrowLongLeftIcon
                className="mr-3 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              Previous
            </div>
          </div>
        )}
        <div className="hidden md:-mt-px md:flex">
          {
            // Display pages if showPages is true (default is true)
            showPages && renderPages
          }
        </div>
        {props.showNextPrev && (
          <div className="-mt-px flex w-0 flex-1 justify-end">
            <div
              onClick={() => handleChangePage(page + 1)}
              disabled="true"
              className="cursor-pointer inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >
              Next
              <ArrowLongRightIcon
                className="ml-3 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
        )}
      </nav>
      {showCount ? (
        <div className="flex  flex-1 text-gray-400">
          Showing {currentCount > 0 ? currentCount : 0} out of {totalCount}
        </div>
      ) : null}
    </>
  );
};

PMPagination.defaultProps = {
  showCount: true,
  showPages: true,
  showNextPrev: true,
  page: 1,
};
