import { useEffect, useRef, useState, useId } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../store";
import { loginUser, resetErrorState } from "../../slices/session";
import { Logo } from "../../components/utils/logo";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputField } from "../../components/utils/InputField";
import { PMButton } from "../../components/utils/PMButton";
import { PmFormHelperText } from "../../components/utils/PmFormHelperText";
import toast from "react-hot-toast";
import { ResourceLoading } from "../../components/utils/ResourceLoading";
import { StarField } from "../../components/utils/StarField";
import { PMField } from "../../components/utils/PMField";
import { TWclassNames } from "../../components/utils/Div";

function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const errorMessages = useSelector((state) => state.session.errorMessages);
  const sessionLoading = useSelector((state) => state.session.isLoading);
  const [errors, setErrors] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  // Set loading for 2 seconds, else listen to session.isLoading

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    emailRef?.current?.focus();
    if (errorMessages.length > 0) {
      setErrors(errorMessages);
      dispatch(resetErrorState());
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      password: Yup.string().max(255).required("Password is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const payload = {
          email: values.email,
          password: values.password,
        };

        const response = await dispatch(loginUser(payload));
        if (!response.error) {
          navigate("/dashboard");
        } else {
          if (response.error.message === "Rejected") {
            toast.error("Could not sign you in. Wrong password or email");
          } else if (response.error.message) {
            toast.error("Could not sign you in.");
          }
        }
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  function Form() {
    return (
      <form
        onSubmit={formik.handleSubmit}
        className="relative isolate mt-8 flex flex-col items-left pr-1"
      >
        <PMField
          ref={emailRef}
          className="peer w-0 flex-auto bg-transparent px-4 py-2.5 text-base text-white placeholder:text-gray-500 focus:outline-none sm:text-[0.8125rem]/6 rounded-lg bg-white/2.5 ring-1 ring-white/15 transition peer-focus:ring-sky-300"
          name="email"
          type="email"
          placeholder="E-post"
          helperText={formik.touched.email && formik.errors.email}
          value={formik.values.email}
          onChange={formik.handleChange}
        />

        <PMField
          ref={passwordRef}
          name="password"
          type="password"
          placeholder="Passord"
          className="peer w-0 flex-auto bg-transparent px-4 py-2.5 text-base text-white placeholder:text-gray-500 focus:outline-none sm:text-[0.8125rem]/6 rounded-lg bg-white/2.5 ring-1 ring-white/15 transition peer-focus:ring-sky-300"
          helperText={formik.touched.password && formik.errors.password}
          value={formik.values.password}
          onChange={formik.handleChange}
        />

        <PmFormHelperText>{formik.errors.submit}</PmFormHelperText>

        {/*
  <div className="flex items-center justify-between">
    <div className="flex items-center">
      <input
        id="remember-me"
        name="remember-me"
        type="checkbox"
        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
      />
      <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
        Remember me
      </label>
    </div>

    <div className="text-sm">
      <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
        Forgot your password?
      </a>
    </div>
  </div> */}

        <div className="mt-10">
          <PMButton
            disabled={formik.isSubmitting}
            size="large"
            type="submit"
            variant="outlined"
          >
            Logg Inn
          </PMButton>
        </div>
      </form>
    );
  }

  function Glow() {
    const id = useId();

    return (
      <div
        className={TWclassNames(
          "absolute inset-0 -z-10 overflow-hidden bg-gray-950 "
          // "lg:right-[calc(max(2rem,50%-38rem)+40rem)] lg:min-w-[32rem]"
        )}
      >
        <svg
          className="absolute -bottom-48 left-[-40%] h-[80rem] w-[180%] lg:-right-40 lg:bottom-auto lg:left-auto lg:top-[-40%] lg:h-[180%] lg:w-[80rem]"
          aria-hidden="true"
        >
          <defs>
            <radialGradient id={`${id}-desktop`} cx="100%">
              <stop offset="0%" stopColor="rgba(56, 189, 248, 0.3)" />
              <stop offset="53.95%" stopColor="rgba(0, 71, 255, 0.09)" />
              <stop offset="100%" stopColor="rgba(10, 14, 23, 0)" />
            </radialGradient>
            <radialGradient id={`${id}-mobile`} cy="100%">
              <stop offset="0%" stopColor="rgba(56, 189, 248, 0.3)" />
              <stop offset="53.95%" stopColor="rgba(0, 71, 255, 0.09)" />
              <stop offset="100%" stopColor="rgba(10, 14, 23, 0)" />
            </radialGradient>
          </defs>
          <rect
            width="100%"
            height="100%"
            fill={`url(#${id}-desktop)`}
            className="hidden lg:block"
          />
          <rect
            width="100%"
            height="100%"
            fill={`url(#${id}-mobile)`}
            className="lg:hidden"
          />
        </svg>
        <div className="absolute inset-x-0 bottom-0 right-0 h-px bg-white mix-blend-overlay lg:left-auto lg:top-0 lg:h-auto lg:w-px" />
      </div>
    );
  }

  function Footer() {
    return (
      <p className="flex items-baseline gap-x-2 text-[0.8125rem]/6 text-gray-500">
        Brought to you by Christoffer Skaar
        {/* <IconLink href="#" icon={TwitterIcon} compact large>
          Joe Davola
        </IconLink> */}
      </p>
    );
  }

  function LoginForm() {
    return (
      <>
        <div>
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <h1 className="mt-14 font-display text-4xl/tight font-light text-white">
          <span className="text-sky-300">Logg inn</span>
        </h1>
        <p className="mt-4 text-sm/6 text-gray-300"></p>
        {Form()}
        <div className="mt-8 flex flex-wrap justify-center gap-x-1 gap-y-3 sm:gap-x-2 lg:justify-start">
          {/* <IconLink href="#" icon={BookIcon} className="flex-none">
        Documentation
      </IconLink>
      <IconLink href="#" icon={GitHubIcon} className="flex-none">
        GitHub
      </IconLink>
      <IconLink href="/feed.xml" icon={FeedIcon} className="flex-none">
        RSS
      </IconLink> */}
        </div>
      </>
    );
  }

  if (isLoading) {
    return (
      <div className="relative flex-none overflow-hidden px-6 lg:pointer-events-none lg:fixed lg:inset-0 lg:z-40 lg:flex lg:px-0">
        <ResourceLoading size="base" />
      </div>
    );
  }

  return (
    <>
      <div className="relative flex-none overflow-hidden px-6 lg:pointer-events-none lg:fixed lg:inset-0 lg:z-40 lg:flex lg:px-0">
        <Glow />
        <div className="relative flex w-full lg:pointer-events-auto lg:mr-[calc(max(2rem,50%-38rem)+40rem)] lg:min-w-[32rem] lg:overflow-y-auto lg:overflow-x-hidden lg:pl-[max(4rem,calc(50%-38rem))]">
          <div className="mx-auto max-w-lg lg:mx-0 lg:flex lg:w-96 lg:max-w-none lg:flex-col lg:before:flex-1 lg:before:pt-6">
            <div className="pb-16 pt-20 sm:pb-20 sm:pt-32 lg:py-20">
              <div className="relative">
                <StarField className="-right-44 top-14" />
                {LoginForm()}
              </div>
            </div>
            <div className="flex flex-1 items-end justify-center pb-4 lg:justify-start lg:pb-6">
              <Footer />
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex-auto">
        {" "}
        <main className="space-y-20 py-20 sm:space-y-32 sm:py-32"></main>
      </div>
    </>
  );
}

export default Login;
