import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { PMField } from "../../../utils/PMField";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { mapErrors } from "../../../Utils";
import PMSwitch from "../../../utils/PMSwitch";
import {
  createCountAsync,
  updateCountAsync,
} from "../../../../slices/inventory/count";

export default function InventoryCountModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { open, onClose, itemId } = props;
  const storeState = useSelector((store) => {
    return store.inventory_counts;
  });
  const [itemState, setItemState] = useState(storeState);

  const { count, isLoading } = itemState;

  useEffect(() => {
    if (itemId) {
      // dispatch(getCountAsync(itemId));
      setItemState(storeState);
    } else {
      setItemState({ ...itemState, count: null });
    }
  }, [open]);

  const createOrUpdateDispatch = (data) => {
    if (count?.id) {
      const res = dispatch(updateCountAsync(data));
      return res;
    } else {
      const res = dispatch(createCountAsync(data));
      return res;
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {itemId ? t("storage.edit") : t("storage.add")}
                  </Dialog.Title>

                  {isLoading ? (
                    "Loading..."
                  ) : (
                    <Formik
                      initialValues={{
                        id: count?.id,
                        count_number: count?.count_number,
                        title: count?.title,
                        description: count?.description,
                        active: count?.active || true,
                        submit: null,
                      }}
                      validationSchema={Yup.object({
                        count_number: Yup.string().max(50),
                        title: Yup.string()
                          .max(255)
                          .required(t("title.required")),
                        description: Yup.string().max(500),
                      })}
                      onSubmit={(values, helpers) => {
                        toast("Saving");
                        try {
                          toast.promise(createOrUpdateDispatch(values), {
                            loading: t("Saving...."),
                            success: (data) => {
                              if (data?.status === 500)
                                throw new Error("Server error");
                              if (data?.payload.errors)
                                throw new Error(
                                  mapErrors(data?.payload.errors)
                                );
                              onClose(true);
                              return t("Data was saved!");
                            },
                            error: (error) => {
                              return t(
                                "Something went wrong. Could not save data!"
                              );
                            },
                          });
                          helpers.setStatus({ success: true });
                          helpers.setSubmitting(false);
                        } catch (err) {
                          toast.error("Something went wrong...");
                          console.error(err);
                          helpers.setStatus({ success: false });
                          helpers.setErrors({ submit: err.message });
                          helpers.setSubmitting(false);
                        }
                      }}
                    >
                      {(formik) => (
                        <Form>
                          <div className="grid grid-cols-6 gap-6 mt-10">
                            <div className="col-span-6 sm:col-span-3">
                              <PMField
                                name="count_number"
                                type="text"
                                label="Count Number"
                                disabled
                                {...formik}
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <PMField
                                name="title"
                                type="text"
                                label="Title"
                                {...formik}
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-6">
                              <PMField
                                name="description"
                                type="text"
                                label="Description"
                                multiline
                                rows={2}
                                {...formik}
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-6">
                              <PMField
                                name="date"
                                type="date"
                                label="Date"
                                {...formik}
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-6">
                              <PMSwitch
                                name="active"
                                label="Active"
                                {...formik}
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3 lg:col-span-2"></div>
                          </div>

                          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                            <button
                              type="button"
                              className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                              onClick={onClose}
                            >
                              {t("Close")}{" "}
                            </button>

                            <button
                              type="button"
                              className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                              onClick={formik.submitForm}
                            >
                              {count?.id ? t("Save") : t("Create")}{" "}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
