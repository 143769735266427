import { useTranslation } from "react-i18next";
import { PMSimpleSelectorFieldV2 } from "../PMSimpleSelector/PMSimpleSelectorFieldV2";
import axios from "../../../api/axios";
import { useState, useEffect } from "react";
import { mapErrors } from "../../Utils";
import { useDispatch, useSelector } from "react-redux";

import { getUnitsAsync } from "../../../slices/unit";
import { debounce } from "lodash";

export const UnitSelector = (props) => {
  const {
    setFieldValue,
    values,
    onChange,
    module,
    errors,
    module_param,
    fields,
  } = props;

  const name = props.name ? props.name : "unit_id";
  const label = props.label ? props.label : "";
  const itemDisplayLabel = "disaplayTitle";
  const numberField = null;
  const FETCH_URL = "/units/";
  const CREATE_URL = "/units/";
  const filters = [];

  const itemState = useSelector((store) => {
    return store.units;
  });

  const stateLoading = itemState.loading;

  const { t } = useTranslation();

  const [query, setQuery] = useState();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({
    id: 0,
    field1: "Ingen valgt",
    field2: null,
  });
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const labelAction = {
    // path: "/dashboard/projects/" + selected?.id,
    // action: () => toast("test"),
  };

  const handleChange = (data) => {
    if (data.selected.item.id) {
      getItem(data.selected.item.id);
    }
    setOpen(false);
    if (values) {
      setFieldValue(name, data.selected.item.id);
    } else {
      onChange({
        target: { name: name, value: data.selected.item.id },
        item: data.selected.item,
      });
    }
  };

  const getItem = (id) => {
    return axios({ method: "get", url: FETCH_URL + id })
      .then((res) => {
        if (res.data.id) {
          setLoading(false);
          setSelected({
            id: res.data?.id,
            field1: res.data.displayTitle,
          });
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err.data.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setLoading(false);
      });
  };

  const dispatch = useDispatch();

  const getItems = () => {
    setLoading(true);

    const controller = {
      query: query ? query : "",
      page: 1,
      per_page: 10,
      sortBy: "created_at",
      sort: "desc",
      filters: filters,
      view: null,
    };

    dispatch(getUnitsAsync(controller));
    setLoading(false);
  };

  useEffect(() => {
    // Format rowParams if items changes
    var array = [
      { id: null, value: null, primary: "Ingen valgt", secondary: null },
    ];
    itemState?.data?.map((row, i) => {
      const rowParams = {
        value: row.id,
        id: row.id,
        primary: row.displayTitle,
        secondary: null,
        object: row,
      };
      array.push(rowParams);
    });
    setItems(array);
    setLoading(false);
  }, [itemState, dispatch]);

  const debouncedAction = debounce((controller) => {
    getItems();
  }, 500);

  useEffect(() => {
    if (open) {
      debouncedAction();
    }

    // Clean the debounced function on component unmount.
    return () => {
      debouncedAction.cancel();
    };
  }, [query, open, dispatch]);

  useEffect(() => {
    if (props.selected) {
      setSelected({
        id: props.selected?.id,
        field1: props.selected.displayTitle,
      });
    }
  }, [props.selected]);

  return (
    <>
      <PMSimpleSelectorFieldV2
        errors={errors}
        name={name}
        label={t(label)}
        labelAction={labelAction}
        loading={loading}
        options={items}
        selected={selected}
        onChange={handleChange}
        onQueryChange={(query) => setQuery(query)}
        onClose={() => setOpen(!open)}
        open={open}
      />
    </>
  );
};
