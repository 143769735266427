import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { FormikSaveBar } from "../../../utils/FormikSaveBar";
import { PMField } from "../../../utils/PMField";
import { useDispatch } from "react-redux";

export const UserEmployment = (props) => {
  const { t } = useTranslation();
  const { item } = props;
  const dispatch = useDispatch();

  return (
    <>
      <Formik
        initialValues={{
          id: item?.id,
          submit: null,
          active: item?.active || false,
        }}
        validationSchema={Yup.object({
          name: Yup.string().max(255).required(t("customer.name.required")),
          description: Yup.string(),
        })}
        onSubmit={(values, helpers) => {
          try {
            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
          } catch (err) {
            toast.error("Nope");
            console.error(err);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {/* {t("Contact information")} */}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500"></p>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3 lg:col-span-2"></div>
                </div>
              </div>
            </div>

            <FormikSaveBar {...formik} />
          </Form>
        )}
      </Formik>
    </>
  );
};
