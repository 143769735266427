import { Fragment, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";
import { TWclassNames } from "./Div";
import Placeholder from "../../utils/img-placeholder.png";
import { useTranslation } from "react-i18next";

export default function PMCombobox(props) {
  const { t } = useTranslation();

  const [options, setOptions] = useState([
    { id: 0, title: "Ikke valgt", value: 0 },
  ]);
  const [selected, setSelected] = useState(options[0]);
  const [query, setQuery] = useState("");
  const { label, name, errors, touched, formik, avatar, values, disabled } =
    props;

  const findIndexByValue = () => {
    // Find option by value, then return index
    let index;
    if (props?.values) {
      index = options.findIndex(
        (option) => option.value === props.values[props.name]
      );
    } else {
      index = options.findIndex((option) => option.value === props.value);
    }
    return index;
  };

  useEffect(() => {
    if (props?.options) {
      // Add props.options to default options
      setOptions([options[0], ...props.options]);

      // After options is set, set current selected option
    }
  }, [props]);
  // add props.options to current options with default value

  if (values) {
    useEffect(() => {
      setTimeout(() => {
        const index = findIndexByValue();
        if (index !== -1) {
          setSelected(options[findIndexByValue()]);
        }
      }, 50);
    }, [options, values[props.name], values]);
  }

  function handleSelect(data) {
    setSelected(data);
    setComboboxOpen(false);
    if (props.setFieldValue) {
      props.setFieldValue(props.name, data.value);
    } else {
      props.onChange(data.value);
    }
  }

  let filteredOptions =
    query === ""
      ? options
      : options?.filter((option) =>
          option?.title
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  if (props.filters) {
    props.filters.map((filter) => {
      // Remove options that has filter.name && value true if filter.value is false
      if (!filter.value) {
        filteredOptions = filteredOptions.filter(
          (option) => option[filter.name] !== true
        );
      }
    });
  }

  const [comboboxOpen, setComboboxOpen] = useState(false);

  function handleAfterLeave() {
    setQuery("");
    setComboboxOpen(false);
  }

  return (
    <div className="top-16 z-10 w-[100%]">
      <label htmlFor="name" className="block text-sm font-medium text-gray-700">
        <label> {label} </label>
      </label>
      <Combobox value={selected} onChange={handleSelect}>
        <div className="relative mt-1">
          <div
            disabled
            className="relative w-full cursor-default overflow-hidden rounded-md bg-blue-500 text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm border-2 border-blue-400"
          >
            <Combobox.Input
              onClick={() => (disabled ? null : setComboboxOpen(!comboboxOpen))}
              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
              displayValue={(option) => t(option?.title)}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button
              key={"combobox-button" + selected?.id}
              onClick={() => (disabled ? null : setComboboxOpen(!comboboxOpen))}
              className="absolute inset-y-0 right-0 flex items-center pr-2"
            >
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            // show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => handleAfterLeave()}
          >
            <Combobox.Options className="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredOptions?.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredOptions?.map((option) => (
                  <Combobox.Option
                    key={option.id ? option.id : option?.displayTitle}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-blue-400 text-white" : "text-gray-900"
                      }`
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        {avatar ? (
                          <>
                            <div className="flex items-center">
                              <img
                                src={
                                  option.avatar?.file
                                    ? option.avatar?.file?.url
                                    : Placeholder
                                }
                                alt=""
                                className="relative left-0 h-5 w-5 flex-shrink-0 rounded-full"
                              />
                              <span
                                className={TWclassNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "ml-3 block truncate"
                                )}
                              >
                                {option.displaytitle}
                              </span>
                            </div>

                            {selected ? (
                              <span
                                className={TWclassNames(
                                  active ? "text-white" : "text-blue-400",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        ) : (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {t(option?.title)}
                            </span>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? "text-white" : "text-teal-600"
                                }`}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {touched && touched[name] && errors[name] && (
        <div className="flex mt-1 text-red-700 text-xs">
          <ExclamationCircleIcon className="w-4 text-xs" />
          <span className="ml-2">{errors[name]}</span>
        </div>
      )}
    </div>
  );
}

PMCombobox.defaultValues = {
  avtar: false,
  formik: true,
};
