import axios from "../../api/axios";

export async function fetchSystemTypes() {
  return axios.get("/customers/systems/types");
}

export async function fetchCustomerSystem(customerId, id) {
  return axios.get(`/customers/${customerId}/systems/` + id);
}

export async function fetchCustomerSystems(customerId) {
  return axios.get("/customers/" + customerId + "/systems");
}

export async function createSystem(payload) {
  return axios
    .post(`/customers/${payload.customer_id}/systems`, { system: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function updateSystem(payload) {
  return axios
    .put(`/customers/${payload.customer_id}/systems/` + payload.id, {
      system: payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // return error.response.data
      if (error.status !== 200) return error;
      else return error.response;
    });
}
