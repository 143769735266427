import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCustomers } from "../../../slices/customer";
import { CustomersStats } from "../../../components/dashboard/customers/customers-stats";
import { CustomersTable } from "../../../components/dashboard/customers/customers-table";
import { CustomersFilter } from "../../../components/dashboard/customers/customers-filter";
import {
  ArrowPathRoundedSquareIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import { toast } from "react-hot-toast";
import { mapErrors, updateUrlWithException } from "../../../components/Utils";
import { syncModule } from "../../../api/company";
import SimplePrompt from "../../../components/utils/simplePrompt";
import CustomerDialog from "../../../components/dashboard/customers/dialog";
import { useSearchParams } from "react-router-dom";

export const CustomerList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const itemState = useSelector((store) => {
    return store.customers;
  });

  const { isLoading } = itemState;
  const [selectedItems, setSelectedItems] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [controller, setController] = useState({
    filters: [],
    page: searchParams.get("page") ? searchParams.get("page") : 1,
    per_page: searchParams.get("per_page") ? searchParams.get("per_page") : 50,
    query: searchParams.get("query") ? searchParams.get("query") : "",
    sort: searchParams.get("sort") ? searchParams.get("sort") : "asc",
    sortBy: searchParams.get("sortBy")
      ? searchParams.get("sortBy")
      : "customer_number",
    view: searchParams.get("view") ? searchParams.get("view") : "all",
    fields: searchParams.get("fields")
      ? searchParams.get("fields")
      : "id,displayTitle",
  });

  // Update all params from controller except filters (dont't touch filters)
  useEffect(() => {
    updateUrlWithException(
      // These will be updated
      {
        page: controller.page,
        per_page: controller.per_page,
        query: controller.query,
        sort: controller.sort,
        sortBy: controller.sortBy,
        view: controller.view,
        // filters: controller.filters,
      },
      // This will be preserved
      "filters"
    );
  }, [controller]);

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery,
    });
  };

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  useEffect(() => {
    const payload = {
      filters: controller.filters,
      page: controller.page,
      per_page: controller.per_page,
      query: controller.query,
      sort: controller.sort,
      sortBy: controller.sortBy,
      view: controller.view,
      fields: "id,name,customer_number,mobile,email,postal_address,syncStatus",
    };
    dispatch(getCustomers(payload));
  }, [dispatch, controller]);

  const [syncState, setSyncState] = useState({
    isLoading: false,
    error: false,
    errors: [],
    updated: 0,
    created: 0,
    found: 0,
  });

  const runOrderSync = () => {
    return syncModule("customers")
      .then((res) => {
        setSyncState((prevState) => ({
          ...prevState,
          isLoading: false,
          updated: res?.data?.products_updated,
          created: res?.data?.products_created,
          found: res?.data?.products_found,
        }));
        toast.success(
          `${syncState.updated} was updated out of ${syncState.found} - ${syncState.created} was created`
        );
        return res;
      })
      .catch((err) => {
        // Updating ItemState
        setSyncState((prevState) => ({
          ...prevState,
          isLoading: false,
          updated: 0,
          created: 0,
          found: 0,
        }));
        return err;
      });
  };

  const SyncOrders = () => {
    setSyncState({
      isLoading: true,
      error: false,
      errors: [],
      products_updated: 0,
      products_created: 0,
      products_found: 0,
    });

    toast.promise(runOrderSync(), {
      loading: t("Syncing...."),
      success: (res) => {
        if (res.data.errors) throw new Error(mapErrors(res.data.errors));
        if (res.status === 500) throw new Error("Server error");
        return `Sync was successfull`;
      },
      error: t("Something went wrong. Could not save data!"),
    });
  };

  const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
  const [syncPromptState, setSyncPromptState] = useState(false);

  const headerActions = [
    {
      component: null,
      label: "Sync API",
      description: "",
      path: null,
      action: () => setSyncPromptState(true),
      icon: ArrowPathRoundedSquareIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: true,
    },
  ];

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={"Customers"}
        meta={[]}
        mainButton={{
          label: "New",
          action: () => setCustomerDialogOpen(true),
          path: null,
          color: "",
          icon: PlusIcon,
        }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            <CustomersFilter
              className={"mt-10"}
              disabled={isLoading}
              onQueryChange={handleQueryChange}
              query={controller.query}
              selectedItems={selectedItems}
            />

            <CustomersTable
              itemState={itemState}
              itemsCount={itemState?.count}
              currentCount={itemState?.data?.length}
              page={controller.page}
              onPageChange={handlePageChange}
              perPage={controller.per_page}
            />
          </div>
        </div>
      </DashboardHeader>

      <SimplePrompt
        title="Sync av ordre"
        description="Er du sikker på at du ønsker å synke ordrer nå?"
        onAccept={() => SyncOrders()}
        open={syncPromptState}
        onClose={() => setSyncPromptState(false)}
      />

      <CustomerDialog
        open={customerDialogOpen}
        onClose={() => setCustomerDialogOpen(false)}
        readOnly={false}
        redirect={true}
      />
    </>
  );
};
