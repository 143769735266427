import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  updateProductAsync,
  createProductAsync,
} from "../../../slices/products";
import { useNavigate } from "react-router-dom";
import { PMField } from "../../utils/PMField";
import { mapErrors } from "../../Utils";
import { ProductNumberCheck } from "./product-number-check";

export const ProductSimpleForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { product, onClose } = props;

  const createOrUpdateDispatch = (data) => {
    if (product?.id) {
      const res = dispatch(updateProductAsync(data));
      return res;
    } else {
      const res = dispatch(createProductAsync(data));
      // .then((res) => {
      //   toast.dismiss();
      //   onClose;
      //   navigate(`/dashboard/products/${res.payload.id}`)
      // })
      return res;
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          id: product?.id,
          item_number: product?.item_number,
          title: product?.title,
          short_description: product?.short_description,
          description: product?.description,
          sales_price: product?.sales_price,
          purchase_price: product?.purchase_price,
          sku: product?.sku || "",
          submit: null,
          tags: product?.tags || [],
          active: product?.active || false,
          isSparePart: product?.isSparePart || false,
          warehouse: product?.warehouse || false,
          online_store: product?.online_store || false,
        }}
        validationSchema={Yup.object({
          item_number: Yup.string()
            .max(50)
            .required(t("Item number is required")),
          title: Yup.string().max(255).required(t("title.required")),
          short_description: Yup.string()
            .max(500)
            .required("Short description is required"),
          description: Yup.string(),
          sales_price: Yup.string(),
          purchase_price: Yup.string(),
          tags: Yup.array(),
          sku: Yup.string(),
        })}
        onSubmit={(values, helpers) => {
          try {
            toast.promise(createOrUpdateDispatch(values), {
              loading: t("Saving...."),
              success: (data) => {
                if (data.status === 500) throw new Error("Server error");
                if (data.payload.errors)
                  throw new Error(mapErrors(data.payload.errors));
                if (data.payload.id)
                  navigate("/dashboard/products/" + data.payload.id);
                onClose(true);
                return t("Data was saved!");
              },
              error: (error) => {
                return t("Something went wrong. Could not save data!");
              },
            });
            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
          } catch (err) {
            toast.error("Something went wrong...");
            console.error(err);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <div className="grid grid-cols-6 gap-6 mt-10">
              <div className="col-span-6 sm:col-span-3">
                <PMField
                  name="item_number"
                  type="text"
                  label="Item Number"
                  {...formik}
                />
                {!formik.values.id && (
                  <ProductNumberCheck number={formik.values.item_number} />
                )}
              </div>

              <div className="col-span-6 sm:col-span-3">
                <PMField name="title" type="text" label="Title" {...formik} />
              </div>

              <div className="col-span-6">
                <PMField
                  name="short_description"
                  type="text"
                  label="Short Description"
                  multiline
                  rows={2}
                  {...formik}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <PMField
                  name="purchase_price"
                  type="number"
                  label={t("Purchase Price")}
                  {...formik}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <PMField
                  name="sales_price"
                  type="number"
                  label={t("Sales Price")}
                  {...formik}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2"></div>
            </div>

            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={onClose}
              >
                {t("Close")}{" "}
              </button>

              <button
                type="button"
                className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={formik.submitForm}
              >
                {t("Save")}{" "}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
