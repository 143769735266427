import { useTranslation } from "react-i18next";
import { PMSimpleSelectorField } from "../PMSimpleSelector/PMSimpleSelectorField";
import { PMSimpleSelectorFieldV2 } from "../PMSimpleSelector/PMSimpleSelectorFieldV2";
import axios from "../../../api/axios";
import { useState, useEffect } from "react";
import { mapErrors } from "../../Utils";
import { Form, Formik } from "formik";
import { ResourceLoading } from "../ResourceLoading";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { PMField } from "../PMField";
import { fetchSystemTypes } from "../../../api/customer/system";
import ItemSelector from "../Selector/ItemSelector";
import PMListbox from "../PMListbox";
import { debounce, set } from "lodash";

export const ProductCategorySelector = (props) => {
  const {
    setFieldValue,
    values,
    errors,
    onChange,
    module,
    module_param,
    fields,
  } = props;

  const disabled = props.disabled ? props.disabled : false;
  const name = props.name ? props.name : "parent_id";
  const label = props.label ? props.label : "";
  const FETCH_URL = "/products/categories/";
  const filters = [];

  const { t } = useTranslation();

  const [query, setQuery] = useState();
  const [loading, setLoading] = useState(false);

  const defaultSelected = {
    id: 0,
    field1: "Ingen valgt",
    field2: null,
  };
  const [selected, setSelected] = useState(defaultSelected);
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);

  const labelAction = {
    // path: "/dashboard/projects/" + selected?.id,
    // action: () => toast("test"),
  };

  const handleChange = (data) => {
    setLoading(false);

    setSelected({
      id: data?.selected?.item?.value,
      field1: data?.selected?.item?.primary,
    });

    setOpen(false);
    if (values) {
      setFieldValue(name, data.selected.item.id);
    } else {
      onChange({ target: { name: name, value: data.selected.item.id } });
    }
  };

  useEffect(() => {
    if (props.selected) {
      setSelected({
        id: props.selected?.id,
        field1: props.selected?.title,
        field2: null,
      });
    }
  }, [props.selected]);

  const getItems = () => {
    setLoading(true);
    const params = {
      query: query ? query : "",
      page: 1,
      sortBy: "created_at",
      sort: "desc",
      filters: filters,
      view: null,
    };

    return axios({ method: "get", url: FETCH_URL, params: params })
      .then((response) => {
        if (response.data.data) {
          var array = [
            { id: null, value: 0, primary: "Ingen valgt", secondary: null },
          ];
          if (!response.data.data) return array;
          response.data.data.map((row, i) => {
            const rowParams = {
              value: row.id,
              id: row.id,
              primary: row?.title,
              secondary: row?.parent?.title,
            };
            array.push(rowParams);
          });

          // add array with the existing first item

          setItems(array);

          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const debouncedAction = debounce((controller) => {
    getItems();
  }, 500);

  useEffect(() => {
    if (open) {
      debouncedAction();
    }

    // Clean the debounced function on component unmount.
    return () => {
      debouncedAction.cancel();
    };
  }, [query, open]);

  // useEffect(() => {
  //   if (values[name]) {
  //     getItem(values[name]);
  //   }
  // }, [items]);

  return (
    <>
      <PMSimpleSelectorFieldV2
        errors={errors}
        name={name}
        label={t(label)}
        loading={loading}
        labelAction={labelAction}
        options={items}
        selected={selected}
        onChange={handleChange}
        onQueryChange={(query) => setQuery(query)}
        onClose={() => setOpen(!open)}
        open={open}
      />
    </>
  );
};
