import { useTranslation } from "react-i18next"

export const ProductStock = () => {
    const { t } = useTranslation();

    return (
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">{ t("Product Stock") }</h3>
              <p className="mt-1 text-sm text-gray-500"></p>
            </div>

            <div className="grid grid-cols-6 gap-6">

              <div className="col-span-6 sm:col-span-3">
              </div>

              <div className="col-span-6 sm:col-span-3">
              </div>


              <div className="col-span-6">
              </div>



            </div>
          </div>
        </div>
    )
}