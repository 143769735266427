import CaseStats from "../../../components/dashboard/cases/case-stats";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";

export const CaseStatsPage = () => {
  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={[]}
        title={"Statestikk for saker"}
        meta={[]}
        // mainButton={{}}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            <CaseStats />
          </div>
        </div>
      </DashboardHeader>
    </>
  );
};
