import { Field, validateYupSchema } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  ArrowDownIcon,
  CheckBadgeIcon,
  CheckIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import PMSimpleSelectorModal from "./PMSimpleSelectorModal";
import { useState } from "react";
import toast from "react-hot-toast";
import axios from "../../../api/axios";
import { useEffect } from "react";
import { LABEL_NAME } from "./PMSimpleSelectorUtils";
import { fetchReport } from "../../../api/service/report";
import { StringLimit, mapErrors } from "../../Utils";
import PMSimpleSelectorModalV2 from "./PMSimpleSelectorModalV2";
import { Link } from "react-router-dom";
import { TWclassNames } from "../Div";

export const PMSimpleSelectorFieldV2 = (props) => {
  // Set selected as selected from input as default (reccived from props)

  const {
    type,
    name,
    label,
    multiline,
    touched,
    errors,
    error,
    onBlur,
    onChange,
    values,
    setFieldValue,
    formik,
    noLabel,
    options,
    selected,
    open,
    onClose,
    loading,
    labelAction,
    searchQuery,
    search,
    disabled,
    showField,
    ...other
  } = props;

  const { t } = useTranslation();

  const value = values ? values[name] : props.value;

  return (
    <>
      {showField && (
        <div>
          {noLabel ? (
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              {labelAction?.action ? (
                <button type="button" onClick={labelAction?.action}>
                  <label
                    className={TWclassNames(
                      labelAction?.path || labelAction?.action
                        ? "text-blue-400 cursor-pointer hover:text-blue-600"
                        : ""
                    )}
                  >
                    {t(label)}
                  </label>
                </button>
              ) : (
                <Link to={labelAction?.path} target="_blank">
                  <label
                    className={TWclassNames(
                      labelAction?.path || labelAction?.action
                        ? "text-blue-400 cursor-pointer hover:text-blue-600"
                        : ""
                    )}
                  >
                    {t(label)}
                  </label>
                </Link>
              )}
            </label>
          ) : null}
          <div className="mt-1 focus-within:border-indigo-600 ">
            <div
              onClick={() => onClose()}
              className={TWclassNames(
                disabled
                  ? "text-gray-400"
                  : "cursor-pointer border-2 border-blue-400 hover:border-l-indigo-500",
                "relative  py-1.5 px-1  bg-gray-50 shadow flex items-center rounded-md"
              )}
            >
              <span className="ml-2 flex">
                {selected?.syncStatus && (
                  <CheckBadgeIcon
                    className="h-6 w-6 mr-1 text-green-400"
                    aria-hidden="true"
                  />
                )}

                {selected?.syncStatus === false && (
                  <ExclamationCircleIcon
                    className="h-6 w-6 mr-1 text-red-400"
                    aria-hidden="true"
                  />
                )}

                {selected?.field1?.replace(/\s+/g, "").length > 0 ? (
                  <StringLimit text={selected?.field1} limit={25} />
                ) : (
                  t("Missing title")
                )}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                <ArrowDownIcon className="h-3 w-3" aria-hidden="true" />
              </span>
            </div>
          </div>
        </div>
      )}

      <div>
        {errors && errors[name] && (
          <div className="flex mt-1 text-red-700 text-xs">
            <ExclamationCircleIcon className="w-4 text-xs" />
            <span className="ml-2">{errors[name]}</span>
          </div>
        )}
      </div>

      <PMSimpleSelectorModalV2
        error={error}
        open={disabled ? false : open}
        name={name}
        label={label}
        selected={selected}
        items={options}
        loading={loading}
        onClose={onClose}
        callback={(data) => props.onChange(data)}
        query={(query) => props.onQueryChange(query)}
        createView={props.createView}
        searchQuery={searchQuery}
        searchEnabled={search}
      />
    </>
  );
};

PMSimpleSelectorFieldV2.defaultProps = {
  type: "text",
  formik: true,
  noLabel: true,
  options: [],
  search: true,
  showField: true,
};

PMSimpleSelectorFieldV2.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
};
