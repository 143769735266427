import { QuestionAnswer } from "@mui/icons-material";
import DashboardHeader from "../../../../components/dashboard/dashboardHeader";
import {
  TWTW,
  TWclassNames,
  TWclassNamesclassNames,
} from "../../../../components/utils/Div";
import {
  AcademicCapIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const actions = [
  {
    title: "Kunder",
    path: "/dashboard/resource-hub/faq/customer",
    icon: UsersIcon,
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
  },
  {
    title: "Ansatte",
    path: "/dashboard/resource-hub/faq/employee",
    icon: UserIcon,
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
  },
];

export const ResourceHubFaqFront = () => {
  const headerActions = [];

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={"FAQ"}
        meta={[{ label: "test", icon: QuestionAnswer }]}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
              {actions.map((action, actionIdx) => (
                <div
                  key={action.title}
                  className={TWclassNames(
                    actionIdx === 0
                      ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                      : "",
                    actionIdx === 1 ? "sm:rounded-tr-lg" : "",
                    actionIdx === actions.length - 2 ? "sm:rounded-bl-lg" : "",
                    actionIdx === actions.length - 1
                      ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
                      : "",
                    "group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
                  )}
                >
                  <div>
                    <span
                      className={TWclassNames(
                        action.iconBackground,
                        action.iconForeground,
                        "inline-flex rounded-lg p-3 ring-4 ring-white"
                      )}
                    >
                      <action.icon className="h-6 w-6" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="mt-8">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      <Link to={action.path} className="focus:outline-none">
                        {/* Extend touch target to entire panel */}
                        <span className="absolute inset-0" aria-hidden="true" />
                        {action.title}
                      </Link>
                    </h3>
                    <p className="mt-2 text-sm text-gray-500">
                      Doloribus dolores nostrum quia qui natus officia quod et
                      dolorem. Sit repellendus qui ut at blanditiis et quo et
                      molestiae.
                    </p>
                  </div>
                  <span
                    className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                    aria-hidden="true"
                  >
                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </DashboardHeader>
    </>
  );
};
