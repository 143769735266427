import React, { component } from "react";

import { mapErrors } from "../../../Utils";
import { useTranslation } from "react-i18next";
import { Form, Formik, useFormik, useFormikContext } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
// import { PromptIfDirty } from "../../utils/PromptIfDirty";
import { FormikSaveBar } from "../../../utils/FormikSaveBar";
import { PMField } from "../../../utils/PMField";
import { updateProductAsync } from "../../../../slices/products";
import { useDispatch } from "react-redux";
import PMSwitch from "../../../utils/PMSwitch";
import { updateCountItemsAsync } from "../../../../slices/inventory/count_item";
import {
  createCountAsync,
  updateCountAsync,
} from "../../../../slices/inventory/count";
export const InventoryCountInfo = (props) => {
  const { t } = useTranslation();
  const { item } = props;
  // Will be fetched form API in the future
  const dispatch = useDispatch();

  const createOrUpdateDispatch = (data) => {
    if (item?.id) {
      const res = dispatch(updateCountAsync(data));
      return res;
    } else {
      const res = dispatch(createCountAsync(data));
      return res;
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          id: item?.id,
          count_number: item?.count_number,
          title: item?.title,
          description: item?.description,
          submit: null,
          active: item?.active || false,
        }}
        validationSchema={Yup.object({
          count_number: Yup.string()
            .max(50)
            .required(t("Item number is required")),
          title: Yup.string().max(255),
        })}
        onSubmit={(values, helpers) => {
          try {
            toast.promise(createOrUpdateDispatch(values), {
              loading: t("Saving...."),
              success: (data) => {
                if (data?.status === 500) throw new Error("Server error");
                if (data?.payload?.errors)
                  throw new Error(mapErrors(data?.payload?.errors));
                return t("Data was saved!");
              },
              error: (error) => {
                return t("Something went wrong. Could not save data!");
              },
            });
            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
          } catch (err) {
            console.error(err);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                {/* <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">{ t("Product Description") }</h3>
                    <p className="mt-1 text-sm text-gray-500"></p>
                  </div> */}
                <div className="grid sm:grid-cols-8 gap-6">
                  <div className="sm:col-span-2">
                    <PMSwitch label="Active" name="active" {...formik} />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t("Contact information")}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500"></p>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <PMField
                      name="count_number"
                      type="text"
                      label="Count Number"
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <PMField
                      name="title"
                      type="text"
                      label="Title"
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6">
                    <PMField
                      name="description"
                      type="text"
                      label="Description"
                      multiline
                      rows={2}
                      {...formik}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <PromptIfDirty {...formik}/> */}
            <FormikSaveBar {...formik} />
          </Form>
        )}
      </Formik>
    </>
  );
};
