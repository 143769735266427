import { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { validateYupSchema } from "formik";
import { TWclassNames } from "../../../utils/Div";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// USED WITH FORMIK

export default function ChecklistSwitch(props) {
  const {
    label,
    name,
    values,
    onChange,
    setFieldValue,
    description,
    ...other
  } = props;

  const checked = values && values[name] ? values[name] : props.checked;

  const handleChange = (checked) => {
    if (onChange) {
      onChange({
        target: {
          name: name,
          checked: checked,
        },
      });
    }

    if (setFieldValue) {
      setFieldValue(name, !values[name]);
    }
  };

  return (
    <Switch.Group
      as="div"
      className={TWclassNames("flex items-center min-h-full h-full mx-auto")}
    >
      <Switch
        disabled={props.disabled}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={handleChange}
        className={classNames(
          props.disabled ? "border-red-700" : "",
          checked ? props?.bgClass : "bg-gray-50",
          props?.borderClass,
          "relative inline-flex h-full w-full flex-shrink-0 rounded-md cursor-pointer border-2 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-0 focus:ring-blue-500 focus:ring-offset-2",
          "hover:bg-blue-400"
        )}
      >
        {/* <span
          aria-hidden="true"
          className={classNames(
            checked ? "translate-x-14" : "translate-x-0",
            "pointer-events-none inline-block h-9 w-5 transform rounded-md bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        /> */}
        <div className="relative flex flex-col text-left bold text-black px-5 items-center">
          <div className="flex py-3 items-center">
            <props.icon className="h-6 w-6 mt-1 text-white-500" />
            <span className="ml-2 md:visible invisible py-2">{label}</span>
          </div>
        </div>
      </Switch>
    </Switch.Group>
  );
}
