import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Select,
  FormHelperText,
  FormControl,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { PropertyList } from "../../utils/property-list";
import { PropertyListItem } from "../../utils/property-list-item";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import toast from "react-hot-toast";
import ItemSelector from "../../utils/Selector/ItemSelector";
import {
  accountCodeOptions,
  productStatus,
  productTypeOptions,
  unitOptions,
  warehouseStatusOptions,
} from "./product-data";
import PMListbox from "../../utils/PMListbox";
import axios from "../../../api/axios";
import { useSelector, useDispatch } from "react-redux";
import { getProductBrands } from "../../../slices/products/brands";
import {
  FolderIcon,
  SquaresPlusIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import ProductCategorySelector from "./Categories/selector/productCategorySelector";

export const ProductSettings = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { product } = props;
  const [productState, setProductState] = useState(product);
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [accessoriesSelectorOpen, setAccessoriesSelectorOpen] = useState(false);
  const [sparePartsSelectorOpen, setSparePartsSelectorOpen] = useState(false);
  const [categoriesSelectorOpen, setCategoriesSelectorOpen] = useState(false);
  const [changed, setChanged] = useState(false);

  const handleOpenSelector = (selector) => {
    if (selector === "accessories") {
      setAccessoriesSelectorOpen(true);
      setSelectorOpen(true);
    } else if (selector === "spare_parts") {
      setSparePartsSelectorOpen(true);
      setSelectorOpen(true);
    } else if (selector === "categories") {
      setCategoriesSelectorOpen(true);
      setSelectorOpen(true);
    } else {
      toast.error("Could not open selector. Missing selector attribute");
      return false;
    }
  };

  function handleOpenSelectorCallback() {
    setSelectorOpen(false);
    setAccessoriesSelectorOpen(false);
    setSparePartsSelectorOpen(false);
    setCategoriesSelectorOpen(false);
  }

  const updateSettings = async () => {
    // Data to be sent to query
    const request = {
      product: {
        status: productState.status,
        warehouse_status_type_id: productState.warehouse_status_type_id,
        account_coding_id: productState.account_coding_id,
        product_type: productState.product_type,
        unit_id: productState.unit_id,
        brand_id: productState.brand,
      },
    };
    // Axios PUT request
    const response = axios.put(`/products/${productState.id}`, request);

    toast.promise(response, {
      loading: "Lagrer....",
      success: (data) => {
        if (data.status === 500) throw new Error("Server error");
        if (data.data?.id) {
          if (props?.onRefresh) props?.onRefresh(data?.data);
          return "Data was saved.";
        } else return "Something went wrong...could not save!";
      },
      error: (err) => {
        console.log(err);
        toast.error("Something went wrong...could not save!");
      },
    });
  };

  const handleListboxChange = (e) => {
    setChanged(true);
    setProductState((prevState) => ({ ...prevState, [e.name]: e.value }));
  };

  useEffect(() => {
    if (changed) {
      updateSettings();
    }
  }, [productState]);

  useEffect(() => {
    dispatch(getProductBrands());
  }, [dispatch]);

  const brandState = useSelector((store) => {
    return store.product_brands;
  });

  const productBrands = () => {
    const brands = [{ name: "brand", label: "Ikke valgt", value: 0 }];
    brandState?.data?.map((brand) => {
      brands.push({
        name: "brand",
        label: brand.title,
        value: brand.id,
      });
    });
    return brands;
  };

  return (
    <>
      <div className="shadow sm:overflow-hidden sm:rounded-md">
        <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("Product Settings")}
            </h3>
            <p className="mt-1 text-sm text-gray-500"></p>
          </div>
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-6">
              <PMListbox
                label={t("Status")}
                options={productStatus}
                selected={productState?.status}
                onChange={handleListboxChange}
              />
            </div>

            <div className="col-span-6 sm:col-span-6">
              <PMListbox
                label={t("product.brand")}
                options={productBrands()}
                selected={productState?.brand?.id || productState?.brand}
                onChange={handleListboxChange}
              />
            </div>

            <div className="col-span-6 sm:col-span-6">
              <PMListbox
                label={t("Product Type")}
                options={productTypeOptions}
                selected={productState.product_type}
                onChange={handleListboxChange}
              />
            </div>

            <div className="col-span-6 sm:col-span-6">
              <PMListbox
                label={t("Unit")}
                options={unitOptions}
                selected={productState?.unit_id}
                onChange={handleListboxChange}
              />
            </div>

            <div className="col-span-6 sm:col-span-6">
              <PMListbox
                label={t("Account Code")}
                options={accountCodeOptions}
                selected={productState.account_coding_id}
                onChange={handleListboxChange}
              />
            </div>

            <div className="col-span-6 sm:col-span-6">
              <PMListbox
                label={t("Warehouse Status Type")}
                options={warehouseStatusOptions}
                selected={productState.warehouse_status_type_id}
                onChange={handleListboxChange}
              />
            </div>

            <div className="col-span-6 sm:col-span-6">
              <PropertyList cols={1}>
                <PropertyListItem
                  label={t("Spare parts")}
                  value={product?.spare_parts?.length + " selected"}
                  icon={WrenchIcon}
                  onClick={() => handleOpenSelector("spare_parts")}
                />
                <PropertyListItem
                  label={t("Categories")}
                  value={product?.categories?.length + " selected"}
                  icon={FolderIcon}
                  onClick={() => handleOpenSelector("categories")}
                />
                <PropertyListItem
                  label={t("Accessories")}
                  value={product?.accessories?.length + " selected"}
                  icon={SquaresPlusIcon}
                  onClick={() => handleOpenSelector("accessories")}
                />
              </PropertyList>
            </div>

            <div className="col-span-6"></div>
          </div>
        </div>
      </div>
      <ItemSelector
        {...props}
        open={accessoriesSelectorOpen}
        selectorCloseCallback={handleOpenSelectorCallback}
        selectorType="accessories"
        module={"product"}
        module_param={product.id}
        selectedItems={product.accessories}
      />

      <ItemSelector
        {...props}
        open={sparePartsSelectorOpen}
        selectorCloseCallback={handleOpenSelectorCallback}
        selectorType="spareParts"
        module={"product"}
        module_param={product.id}
        selectedItems={product.spare_parts}
      />

      {/* <ItemSelector
        {...props}
        open={categoriesSelectorOpen}
        selectorCloseCallback={handleOpenSelectorCallback}
        selectorType="categories"
        module={"product"}
        module_param={product.id}
        selectedItems={product.category_ids}
      /> */}

      <ProductCategorySelector
        open={categoriesSelectorOpen}
        selectorCloseCallback={handleOpenSelectorCallback}
        selectedItems={product.category_ids}
        module={"product"}
        module_param={product.id}
      />
    </>
  );
};
