import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createNote, fetchNotes } from "../../../api/notes";
import {
  axiosWithToast,
  dateToString,
  mapErrors,
  NewLineFormatter,
} from "../../Utils";
import toast from "react-hot-toast";
import { Form, Formik } from "formik";
import NoteField from "./noteField";
import { ResourceLoading } from "../../utils/ResourceLoading";
import * as Yup from "yup";
import { set } from "lodash";

export const NotesSection = (props) => {
  const { module, module_param } = props;

  const { t } = useTranslation();

  const [notes, setNotes] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const postNote = async (values) => {
    return await createNote(values);
  };

  const getNotes = async () => {
    setIsLoading(true);
    const controller = {
      filters: [
        {
          name: props.module,
          value: props.module_param,
        },
      ],
      page: 1,
      per_page: 10,
      query: "",
      sort: "desc",
      sortBy: "updated_at",
      view: "all",
    };

    await fetchNotes(controller)
      .then((res) => {
        var array = [];
        res.data.data.map((row, i) => {
          const rowParams = {
            ...row,
          };
          array.push(rowParams);
        });
        setNotes(array);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getNotes();
  }, []);

  const initialValues = {
    id: null,
    note: null,
    module: module,
    module_param: module_param,
    submit: null,
  };

  return (
    <div className="bg-white rounded-md ">
      <div className="px-4 ">
        <div className="flex items-center py-5 w-full">
          <div className="block w-[60%]">
            <h2
              id="applicant-information-title"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              Notater
            </h2>
          </div>
        </div>

        <div className="mt-5">
          <div>
            <ul
              role="list"
              className="divide-y divide-gray-200 sm:max-h-[380px] max-h-[100px] overflow-y-scroll"
            >
              {notes.map((note) => (
                <li key={note.id} className="py-4 ">
                  <div className="space-x-3">
                    <div className="flex-1 space-y-1">
                      <div className="flex flex-col justify-between">
                        <h3 className="text-sm font-medium">
                          {note?.created_by?.name}
                        </h3>
                        <p className="text-sm text-gray-500">
                          {dateToString(note?.created_at)}
                        </p>
                      </div>
                      <p className="text-sm text-gray-500 overflow-hidden text-ellipsis whitespace-nowrap sm:max-w-md xl:max-w-2xl xs:max-w-sm ">
                        <NewLineFormatter value={note?.note} />
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {isLoading ? (
          <ResourceLoading />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              note: Yup.string().required("Note is required"),
            })}
            onSubmit={(values, helpers) => {
              try {
                axiosWithToast(postNote(values), null, null, (data) => {
                  console.log(data);
                  setNotes([...notes, data.data]);
                });
                helpers.setStatus({ success: true });
                helpers.setSubmitting(false);
                helpers.resetForm(initialValues);
              } catch (err) {
                toast.error("Something went wrong...");
                console.error(err);
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
              }
            }}
          >
            {(formik) => (
              <Form>
                <div className="grid grid-cols-6 gap-6 mt-10"></div>
                <div className="col-span-6 sm:col-span-3">
                  <NoteField {...formik} />
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};
