import axios from "./axios";

export async function fetchUnits(controller) {
  return axios({ method: "get", url: "/units/", params: controller })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}
