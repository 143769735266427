import { useTranslation } from "react-i18next";
import React, { useEffect, useState, useCallback, Fragment } from "react";
import { Card, CardContent, CardMedia, Box } from "@mui/material";
import { Dialog, Transition } from "@headlessui/react";
import ImageViewer from "react-simple-image-viewer";
import { current } from "@reduxjs/toolkit";
import Library from "../dashboard/Media/Library";
import { documentSrc } from "../dashboard/documents/utils";

export const PMImagePicker = (props) => {
  const { name, label, setFieldValue, module, module_param, onChange } = props;
  const { t } = useTranslation();

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = useState(false);
  const [document, setDocument] = useState();
  const [link, setLink] = useState();
  const [documentTitle, setDocumentTitle] = useState("Image placeholder");
  const [imageIsLoaded, setImageIsLoaded] = useState(false);
  const [selected, setSelected] = useState(props.selected);

  useEffect(() => {
    setDocument(selected);
  }, [props.selected]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDocumentPicker = (document) => {
    handleClose();
    setDocument(document);
    setSelected(document);
    if (onChange) {
      onChange({
        target: {
          name: name,
          value: document.id,
        },
      });
    }

    if (setFieldValue) {
      setFieldValue(name, document.id);
    }
  };

  const handleCardClick = () => {
    handleOpen();
  };

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const images = [documentSrc(document, "original")];

  const modal = (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="flex items-center text-lg font-medium leading-6 text-gray-900"
                  >
                    <div className="w-[60%]">{t("library")}</div>
                  </Dialog.Title>
                  <div className="grid grid-cols-6 gap-6 mt-10">
                    <div className="col-span-6 sm:col-span-6">
                      <Library
                        module={module}
                        moduleParam={module_param}
                        handleDocument={handleDocumentPicker}
                        link={link}
                        {...props}
                      />
                    </div>
                  </div>

                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                      onClick={handleClose}
                    >
                      {t("Close")}{" "}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );

  return (
    <>
      <div className="shadow sm:overflow-hidden sm:rounded-md">
        <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {label}
            </h3>
            <p className="mt-1 text-sm text-gray-500"></p>
          </div>
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-6">
              <Card
                onClick={() => openImageViewer(0)}
                sx={{ display: "flex", height: "200px" }}
              >
                <CardMedia
                  sx={{ width: "100%" }}
                  image={documentSrc(document, "thumb")}
                  title={document ? document.title : "Image placeholder"}
                />
              </Card>
            </div>
            <div className="col-span-6 sm-col-span-6 text-center ">
              <div
                className="block cursor-pointer bg-gray-50 px-4 py-4 text-center text-sm font-medium text-gray-500 hover:text-gray-700 sm:rounded-b-lg"
                onClick={handleCardClick}
              >
                {t("image.change")}
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal}
      {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal> */}
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
        />
      )}
    </>
  );
};

PMImagePicker.defaultProps = {
  module: null,
  module_param: null,
};
