import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { SimpleHourForm } from "./simple-hour-form";
import { SimpleHourList } from "./simple-hour-list";
import { getHoursAsync } from "../../../slices/hour";
import { PMDialog } from "../../utils/PMDialog";
import PropTypes from "prop-types";

export const SimpleHourDialog = (props) => {
  const {
    formik,
    module,
    moduleObject,
    open,
    onClose,
    onHoursSum,
    projectRequired,
    reportRequired,
    deviationRequired,
    activityRequired,
  } = props;

  if (!module) {
    throw new Error("module is required");
  }

  const filters = [{ name: module, value: moduleObject?.id }];

  const [loading, setLoading] = useState(false);

  const project = props?.project || moduleObject?.project;
  const report = props?.report || moduleObject?.report;
  const deviation = props?.deviation || moduleObject?.deviation;

  const [controller, setController] = useState({
    query: "",
    page: 1,
    per_page: 10,
    sortBy: "created_at",
    sort: "desc",
    filters: filters,
    view: null,
  });

  const itemState = useSelector((store) => {
    return store.hours;
  });

  // Update loading if itemState.isLoading is changing state
  useEffect(() => {
    if (itemState.isLoading !== loading) {
      setLoading(itemState?.isLoading);
    }
  }, [itemState.isLoading]);

  const dispatch = useDispatch();

  const getItems = () => {
    setLoading(true);
    dispatch(getHoursAsync(controller));
    setLoading(false);
  };

  useEffect(() => {
    if (itemState?.data?.length > 0) {
      onHoursSum(itemState?.data?.length);
    }
  }, [itemState]);

  useEffect(() => {
    if (module) {
      getItems();
    }
  }, [controller]);

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const { data } = itemState;

  const [selectedItem, setSelectedItem] = useState(null);

  const handleSelect = (item) => {
    setSelectedItem(item);
  };

  return (
    <PMDialog
      open={open}
      onClose={onClose}
      title="Hour Dialog"
      isLoading={itemState?.isLoading}
    >
      <div>
        <div>
          <SimpleHourList
            hours={data}
            loading={loading}
            onPageChange={handlePageChange}
            page={controller?.page}
            totalItemsCount={itemState?.totalCount}
            onRefresh={() => getItems()}
            onItemSelect={handleSelect}
          />
        </div>
        <div>
          <SimpleHourForm
            formik={formik}
            project={project}
            report={report}
            deviation={deviation}
            selected={selectedItem}
            projectRequired={projectRequired}
            reportRequired={reportRequired}
            deviationRequired={deviationRequired}
            activityRequired={activityRequired}
          />
        </div>
      </div>
    </PMDialog>
  );
};

SimpleHourDialog.defaultProps = {
  open: false,
  onClose: () => {},
  projectRequired: false,
  reportRequired: false,
  deviationRequired: false,
  activityRequired: false,
};

PMDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  projectRequired: PropTypes.bool,
  reportRequired: PropTypes.bool,
  deviationRequired: PropTypes.bool,
  activityRequired: PropTypes.bool,
};
