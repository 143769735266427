import { Form, Formik } from "formik";
import { PMDialog } from "../../../utils/PMDialog";
import { axiosWithToast, dispatchWithToast } from "../../../Utils";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { PMField } from "../../../utils/PMField";
import { useTranslation } from "react-i18next";
import { copyReport } from "../../../../api/service/report";
import PMSwitch from "../../../utils/PMSwitch";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

export const ReportCopyForm = (props) => {
  const { onClose, report } = props;

  const item = report || {};

  const { t } = useTranslation();

  const copyOptions = [
    { value: "order_date", label: "Order Date" },
    { value: "delivery_address", label: "Delivery Address" },
    { value: "system", label: "System" },
    { value: "executing_employee", label: "Executing Employee" },
    { value: "description", label: "Description" },
    { value: "internal_description", label: "Internal Description" },
  ];

  const [allSet, setAllSet] = useState(false);

  const handleSetAll = (formik) => {
    setAllSet(!allSet);
    copyOptions.forEach((option) => {
      formik.setFieldValue(option.value, !allSet);
    });
  };

  const navigate = useNavigate();

  const handleOnRefresh = (data) => {
    toast.success("Report copied successfully");
    navigate(`/dashboard/service/reports/${data?.id}`);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: item?.id || null,
        submit: null,
      }}
      validationSchema={Yup.object({
        // customer_id: Yup.string().required("Customer is required"),
      })}
      onSubmit={(values, helpers) => {
        try {
          axiosWithToast(
            copyReport(values),
            (data) => handleOnRefresh(data),
            onClose(true)
          );
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
        } catch (err) {
          console.log(err);
          toast.error("Something went wrong...");
          console.error(err);
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <Form>
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-6">
              <div className="mb-2">
                <PMSwitch
                  name="all"
                  label="All"
                  checked={copyOptions.every(
                    (option) => formik.values[option.value]
                  )}
                  onChange={() => handleSetAll(formik)}
                />
              </div>
              <span className="py-5 italic font-xs text-gray-400">
                Select all options
              </span>
            </div>

            {copyOptions &&
              copyOptions.map((option, idx) => (
                <div key={idx} className="col-span-6 sm:col-span-3">
                  <PMSwitch
                    name={option.value}
                    label={option.label}
                    {...formik}
                  />
                </div>
              ))}
          </div>

          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
              type="button"
              className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
              onClick={onClose}
            >
              {t("Close")}{" "}
            </button>

            <button
              type="button"
              className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
              onClick={formik.submitForm}
            >
              {formik?.values?.id ? t("Update") : t("Create")}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export const ReportCopyModal = (props) => {
  const { open, onClose, report } = props;

  return (
    <PMDialog open={open} onClose={onClose} title="Copy Report">
      <ReportCopyForm {...props} />
    </PMDialog>
  );
};
