import { useEffect, useState } from "react";
import ReportLayout from "../../../../components/dashboard/service/reports/ReportLayout";
import PMSteps from "../../../../components/utils/PMSteps";
import { useTranslation } from "react-i18next";
import { useUserHasAccess } from "../../../../routes/roleOnly";
import { ResourceLoading } from "../../../../components/utils/ResourceLoading";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {
  OperationCanFinish,
  dispatchWithToast,
} from "../../../../components/Utils";
import { useDispatch } from "react-redux";
import {
  createReportAsync,
  updateReportAsync,
} from "../../../../slices/service/reports";
import { ReportReportForm } from "../../../../components/dashboard/service/reports/forms/report";
import { CustomerSystemForm } from "../../../../components/dashboard/customers/systems/customer-system";
import OrderItems from "../../../../components/dashboard/orders/order-items";
import { ReportVariables } from "../../../../components/dashboard/service/reports/report-variables";
import ChecklistItemLines from "../../../../components/dashboard/quality/checklists/checklistItemLines";
import { PMNotice } from "../../../../components/utils/notifications";
import { ReportHoursForm } from "../../../../components/dashboard/service/reports/forms/hours";
import PMSwitch from "../../../../components/utils/PMSwitch";
import { PMField } from "../../../../components/utils/PMField";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TWclassNames } from "../../../../components/utils/Div";

export const ReportFinishPage = ({ child }) => {
  console.log("props", child);

  const Layout = (props) => {
    const { onRefresh } = props;

    const [report, setReport] = useState(props.report);

    // Update report state if props.report change
    useEffect(() => {
      setReport(props.report);
    }, [props.report]);

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();
    const adminRole = useUserHasAccess({ role: "admin" });
    const [currentStageIdx, setCurrentStageIdx] = useState(0);

    // set isLoading depending on props
    useEffect(() => {
      setIsLoading(props.isLoading);
    }, [props.isLoading]);

    const [stages, setStages] = useState([
      {
        id: 1,
        title: t("Rapport"),
        description: t("report.write.description"),
        current: true,
        completed: false,
        active: true,
      },
      {
        id: 2,
        title: t("System"),
        description: t("report.write.description"),
        active: report?.report_type?.system ? true : false,
      },
      {
        id: 3,
        title: t("Items"),
        description: t("report.items.description"),
        current: false,
        completed: false,
        active: report?.report_type?.items ? true : false,
      },
      {
        id: 4,
        title: t("Variables"),
        description: t("report.variables.description"),
        current: false,
        completed: false,
        active: report?.report_type?.variables ? true : false,
      },
      {
        id: 5,
        title: t("Checklist"),
        description: t("report.checklist.description"),
        current: false,
        completed: false,
        active: report?.report_type?.checklist ? true : false,
      },
      {
        id: 6,
        title: t("Hours"),
        description: t("report.hours.description"),
        current: false,
        completed: false,
        active: report?.report_type?.hours ? true : false,
      },
      {
        id: 7,
        title: t("Finish"),
        description: t("report.finish.description"),
        current: false,
        completed: false,
        active: adminRole,
      },
    ]);

    const activeStages = stages.filter((stage) => stage.active === true);

    const [stage, setStage] = useState(stages[0]);

    // get stage from url params

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
      const stage = searchParams.get("stage");
      if (stage) {
        setStage(stages[stage]);
        setCurrentStageIdx(stage);
      }
    }, [searchParams]);

    const activePageChecker = (stage) => {
      // Check if active page, else set next page
      if (stage.active) {
        const thisStageId = stage.id;
        const currentStageIdx = activeStages.findIndex(
          (stage) => stage.id === thisStageId
        );
        setStage((prevState) => stage);
        setCurrentStageIdx(currentStageIdx);
      } else {
        // create a loop that goes to next stage until it finds an active stage
        let nextStage = stage.id;
        while (nextStage < activeStages.length) {
          if (activeStages[nextStage].active) {
            setStage((prevState) => activeStages[nextStage - 1]);
            // idx of current "nextStage"
            const nextStageIdx = activeStages.findIndex(
              (stage) => stage.id === nextStage
            );
            setCurrentStageIdx(nextStage - 1);
            break;
          } else {
            nextStage++;
          }
        }
      }
    };

    const handleStageChange = (stage) => {
      setIsLoading(true);

      setTimeout(() => {
        setIsLoading(false);
      }, 250);

      activePageChecker(stage);
    };

    const handleNextPage = (formik) => {
      if (currentStageIdx !== activeStages?.length - 1) {
        const nextStage = activeStages[currentStageIdx + 1];
        activePageChecker(nextStage);
      }
      formik.submitForm();
    };

    const createOrUpdateDispatch = (data) => {
      if (report?.id) {
        const res = dispatch(updateReportAsync(data));
        return res;
      } else {
        const res = dispatch(createReportAsync(data));
        return res;
      }
    };

    const [refresh, setRefresh] = useState(false);

    const handleOnRefresh = (data) => {
      // setItemState((prevState) => ({
      //   ...prevState,
      //   report: data,
      // }));

      setReport(data);

      if (props.onRefresh && refresh) {
        onRefresh(data || data?.data);
      }
    };

    const navigate = useNavigate();

    const handleFinish = (formik) => {
      formik.submitForm();
      // onClose(true);
      setRefresh(true);
      onRefresh();

      setTimeout(() => {
        if (formik.values.id) {
          navigate(`/dashboard/service/reports/${formik.values.id}`);
        } else {
          navigate("/dashboard/service/reports/");
        }
      }, 25);
    };

    function FinishErrors() {
      // Return errors with type "create_order" in report.operations
      if (report?.operations) {
        const errors = report.operations.filter(
          (operation) => operation.name === "finish_report"
        );
        return (
          <div className="col-span-6 sm:col-span-6">
            <div className="text-red-500">
              {errors.map((error, index) => (
                <div key={index}>{error.message}</div>
              ))}
            </div>
          </div>
        );
      }
    }

    const stageOne = (report, formik) => {
      return <ReportReportForm formik={formik} report={report} />;
    };

    const stageSystem = (report, formik) => {
      return (
        <CustomerSystemForm
          selected={report?.system}
          formik={formik}
          customer={report?.customer}
          onAttachSystem={(data) => {
            console.log(data);
            formik.setFieldValue("system_id", data.target.value);
          }}
        />
      );
    };

    const stageTwo = (report, formik) => {
      return (
        <div className="grid grid-cols-6 gap-6 mt-10">
          <div className="col-span-6 sm:col-span-6">
            <OrderItems
              group={false}
              sales_price={true}
              purchase_price={false}
              discount={true}
              surcharge={false}
              drag={false}
              quantity_picked={false}
              quantity={true}
              {...formik}
            />
          </div>
        </div>
      );
    };

    const stageThree = (report, formik) => {
      return (
        <div className="grid grid-cols-6 gap-6 mt-10">
          <div className="col-span-6 sm:col-span-6">
            <ReportVariables {...formik} />
          </div>
        </div>
      );
    };

    const stageFour = (report, formik) => {
      return (
        <div className="grid grid-cols-6 gap-6 mt-10">
          <div className="col-span-6">
            {report?.checklist?.id ? (
              <ChecklistItemLines
                checklist={report?.checklist}
                items={report?.checklist?.items}
                editable={false}
                isLoading={isLoading}
                drag={false}
                addRow={false}
                deleteRow={false}
                deviation={false}
                disabled={
                  report?.checklist?.is_items_editable !== undefined &&
                  !report?.checklist?.is_items_editable
                }
                parentCallback={(data) =>
                  formik.setFieldValue("checklist.items", data)
                }
              />
            ) : (
              <PMNotice
                title="Sjekkliste mangler"
                description="Rapporten er ikke knyttet til sjekkliste"
              />
            )}
          </div>
        </div>
      );
    };

    const stageFive = (report) => {
      return (
        <div className="grid grid-cols-6 gap-6 mt-10">
          <div className="col-span-6 sm:col-span-6">
            <ReportHoursForm report={report} />
          </div>
        </div>
      );
    };

    const stageSix = (report, formik) => {
      const canFinish = OperationCanFinish({
        operations: report?.operations,
        operationName: "finish_report",
      });

      return (
        <div className="grid grid-cols-6 gap-6 mt-10">
          {canFinish && (
            <div className="col-span-6 sm:col-span-6 border-2 border-red-200 rounded-xl p-5">
              <FinishErrors />
            </div>
          )}

          <div className="col-span-6 sm:col-span-6 border-2 border-green-200 rounded-xl p-5">
            <p className="font-semibold mb-2">Ferdigstill rapporten</p>
            <div className="py-5">
              <PMSwitch name="finished" label={t("Ferdigstill")} {...formik} />
            </div>
            <PMField
              name="finished_date"
              label={t("Finished Date")}
              type="date"
              {...formik}
            />
            {formik.values.finished && (
              <p>Rapporten ferdigstilles ved oppdatering.</p>
            )}
          </div>
        </div>
      );
    };

    return (
      <div className="container bg-white px-4 py-4 rounded-xl">
        <div>
          <PMSteps
            steps={activeStages}
            selected={stage}
            onChange={handleStageChange}
          />
        </div>
        <div>
          {isLoading ? (
            <ResourceLoading />
          ) : (
            <Formik
              initialValues={{
                id: report?.id,
                report: report?.report,
                internal_report: report?.internal_report,
                must_be_rectified: report?.must_be_rectified,
                complaint: report?.complaint,
                items: report?.items,
                activities: report?.activities,
                variables: report?.variables,
                report_number: report?.report_number,
                internal_description: report?.internal_description,
                // finished_date: new Date()
                //   .toISOString()
                //   .substring(0, 10),
                // finished: report?.finished || false,
                submit: null,
              }}
              validationSchema={Yup.object({
                // description: Yup.string(),
              })}
              onSubmit={(values, helpers) => {
                try {
                  dispatchWithToast(
                    createOrUpdateDispatch(values),
                    handleOnRefresh
                  );

                  helpers.setStatus({ success: true });
                  helpers.setSubmitting(false);
                } catch (err) {
                  toast.error("Something went wrong...");
                  console.error(err);
                  helpers.setStatus({ success: false });
                  helpers.setErrors({ submit: err.message });
                  helpers.setSubmitting(false);
                }
              }}
            >
              {(formik) => (
                <Form>
                  {stage?.id === 1 && stageOne(report, formik)}
                  {stage?.id === 2 && stageSystem(report, formik)}
                  {stage?.id === 3 && stageTwo(report, formik)}
                  {stage?.id === 4 && stageThree(report, formik)}
                  {stage?.id === 5 && stageFour(report, formik)}
                  {stage?.id === 6 && stageFive(report)}
                  {stage?.id === 7 && stageSix(report, formik)}

                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-3 sm:gap-3 justify-between">
                    <div
                      className={TWclassNames(
                        "flex w-full col-span-3 right align-right gap-4",
                        currentStageIdx === activeStages?.length - 1
                          ? "justify-end"
                          : "justify-between"
                      )}
                    >
                      <button
                        // disabled={
                        //   currentStageIdx === activeStages?.length - 1 &&
                        //   !OperationCanFinish({
                        //     operations: report?.operations,
                        //     operationName: "finish_report",
                        //   })
                        // }
                        type="button"
                        className="disabled:bg-gray-200 right-0 rounded-md border border-transparent bg-yellow-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                        onClick={() => handleFinish(formik)}
                      >
                        {report?.id ? t("Update") : t("Create")}
                        {" & Back to Report"}
                      </button>
                      {currentStageIdx !== activeStages?.length - 1 && (
                        <button
                          type="button"
                          className="rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                          onClick={() => handleNextPage(formik)}
                        >
                          {t("Next")} &raquo;
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <ReportLayout>
        <Layout />
      </ReportLayout>
    </>
  );
};
