import axios from "axios";
import {
  getAccessToken,
  logoutUserWithoutToken,
  refreshAccessToken,
} from "../slices/session";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
    withCredentials: true,
  },
});

instance.interceptors.request.use((request) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    request.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return request;
});

export default instance;
