import { ClockIcon } from "@heroicons/react/24/outline";
import { useState, useEffect } from "react";
import { TWclassNames } from "../../utils/Div";
import { toast } from "react-hot-toast";
import { fetchHistories } from "../../../api/histories";
import { TextGeneratorDialog } from "./text-generator-dialog";
import UppercaseFirstLetter from "../../Utils";
import { useTranslation } from "react-i18next";

export const TextGeneratorButton = (props) => {
  const { module_param, module, text, onGenerate } = props;
  const [open, setOpen] = useState(false);
  const [histories, setHistories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  function buttonColor() {
    if (!module_param || !module) {
      return "bg-red-400 border-red-600";
    } else {
      return "border-blue-400";
    }
  }

  // Check if module_param and module  set, else TOAST error message when trying to open.
  function handleOpenDialog() {
    if (!module_param || !module) {
      toast.error(
        "Module && Module Param  missing. Can't fetch or create histories."
      );
    } else {
      setOpen(!open);
    }
  }

  return (
    <>
      <button
        onClick={() => handleOpenDialog(!open)}
        type="button"
        className={TWclassNames(
          buttonColor(),
          "group flex items-center p-2 border-2 rounded-md"
        )}
      >
        <UppercaseFirstLetter text={t(props.function + " text")} />
        {/* <ClockIcon
          className="h-4 w-4 flex-shrink-0 text-black group-hover:text-gray-500 "
          aria-hidden="true"
        /> */}
        <span className="sr-only">Generate Text</span>
      </button>

      {/* Dialog START */}
      <TextGeneratorDialog
        open={open}
        onClose={() => setOpen(!open)}
        isLoading={isLoading}
        onLoading={(state) => setIsLoading(state)}
        module_param={module_param}
        module={module}
        text={text}
        function={props?.function}
        onGenerate={(text) => {
          onGenerate(text);
          setOpen(!open);
        }}
      />
      {/* Dialog END */}
    </>
  );
};

TextGeneratorButton.defaultProps = {
  module_param: "",
  module: "",
  text: null, // text to be generated
  function: "generate",

  onGenerate: (text) => console.log("TextGeneratorButton", text),
};
