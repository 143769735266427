import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createUser, destroyUser, updateUser, fetchUsers } from "../api/user";
import produce from "immer";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  user: {},
  data: [],
  currentCount: 0,
  totalCount: 0,
  newCount: 0,
};

export const initialController = {
  filters: [],
  page: 0,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const updateUserAsync = createAsyncThunk(
  "users/updateUserAsync",
  async (payload) => {
    let response = await updateUser(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createUserAsync = createAsyncThunk(
  "users/createUserAsync",
  async (payload) => {
    let response = await createUser(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const getUsersAsync = createAsyncThunk(
  "cases/getUsersAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchUsers(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsersAsync.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getUsersAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.currentCount = action.payload.currentCount;
        state.newCount = action.payload.newCount;
      })

      .addCase(getUsersAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch Users....is controller missing?",
        ];
      })
      .addCase(updateUserAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data[index] = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(updateUserAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update product."];
      })
      .addCase(createUserAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createUserAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
        });
      })
      .addCase(createUserAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update product."];
      });
  },
});

export const { reducer } = userSlice;

export default userSlice;
