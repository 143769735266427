import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import { PencilIcon } from "@heroicons/react/20/solid";

export const warehouseStatusOptions = [
  { name: "warehouse_status_type_id", value: 0, label: "Ikke valgt" },
  { name: "warehouse_status_type_id", value: 1, label: "På lager" },
  { name: "warehouse_status_type_id", value: 2, label: "Vis varetelling" },
  { name: "warehouse_status_type_id", value: 3, label: "Bestillingsvare" },
  { name: "warehouse_status_type_id", value: 4, label: "Utgått" },
];

export const findWarehouseStatusById = (id) => {
  return warehouseStatusOptions.find((item) => item.value === id);
};

export const productTypeOptions = [
  { name: "product_type", value: 0, label: "Ikke valgt" },
  { name: "product_type", value: 1, label: "Standard" },
  { name: "product_type", value: 2, label: "Reservedel" },
  { name: "product_type", value: 3, label: "Tjeneste" },
  { name: "product_type", value: 4, label: "Nedlastbar" },
];

export const findProductTypeById = (id) => {
  return productTypeOptions.find((item) => item.value === id);
};

export const accountCodeOptions = [
  { name: "account_coding_id", value: 0, label: "Ikke valgt" },
  { name: "account_coding_id", value: 1, label: "Standard" },
];

export const unitOptions = [
  { name: "unit_id", value: 0, label: "Ikke valgt" },
  { name: "unit_id", value: 1, label: "Stykk (stk)" },
  { name: "unit_id", value: 2, label: "Løpemeter (lm)" },
  { name: "unit_id", value: 3, label: "Kilometer (km)" },
  { name: "unit_id", value: 4, label: "Dag (dag)" },
];

export const findUnitById = (id) => {
  return unitOptions.find((item) => item.value === id);
};

export const productStatus = [
  { name: "status", value: "none", label: "Ikke valgt" },
  { name: "status", value: "active", label: "Active", icon: CheckBadgeIcon },
  {
    name: "status",
    value: "unpublished",
    label: "Unpublished",
    icon: PencilIcon,
  },
];

export const findProductStatusByValue = (id) => {
  return productStatus.find((item) => item.value === id);
};
