import { PMField } from "../../utils/PMField";
import PMSwitch from "../../utils/PMSwitch";
import { PMImagePicker } from "../../utils/PMImagePicker";
import { PMDialog } from "../../utils/PMDialog";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { axiosWithToast, dispatchWithToast } from "../../Utils";
import toast from "react-hot-toast";
import { FaqCategorySelector } from "../../utils/moduleSelectors/faqCategorySelector";
import { useDispatch } from "react-redux";
import {
  createResourceHubFaqCategoryAsync,
  updateResourceHubFaqCategoryAsync,
} from "../../../slices/resource-hub/faq/category";
import PMCombobox from "../../utils/PMCombobox";

export const userTypeOptions = [
  {
    id: "customer",
    value: "customer",
    title: "Customer",
    class: "bg-green-100 text-green-800",
  },
  {
    id: "employee",
    value: "employee",
    title: "Employee",
    class: "bg-yellow-100 text-yellow-800",
  },
];

export const FaqCategoryDialog = (props) => {
  const { open, onClose, mainCategory, userType } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [category, setCategory] = useState(props.category);

  useEffect(() => {
    setCategory(props.category);
  }, [props.category]);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const createOrUpdateDispatch = (data) => {
    // onEditing(false);
    if (category?.id) {
      const res = dispatch(updateResourceHubFaqCategoryAsync(data));
      return res;
    } else {
      const res = dispatch(createResourceHubFaqCategoryAsync(data));
      return res;
    }
  };

  console.log("mainCategory", mainCategory);

  return (
    <PMDialog
      size="4xl"
      open={open}
      onClose={onClose}
      title={
        category?.id
          ? category.title
          : mainCategory
          ? t("Create main category")
          : t("Create sub category")
      }
    >
      {isLoading ? (
        <ResourceLoading />
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            id: category?.id,
            title: category?.title || "",
            description: category?.description || "",
            user_type: category?.user_type || userType,
            public: category?.public || false,
            parent_id: category?.parent?.id || null,
            mainCategory: mainCategory,
            submit: null,
          }}
          validationSchema={Yup.object({
            title: Yup.string().max(255).required(t("title.required")),
            description: Yup.string().max(500),
            // parent id must be selected if it is not a main category
            // if mainCategory is true then parent_id is not required
            parent_id: Yup.number()
              .nullable(true)
              .transform((value, originalValue) => {
                return isNaN(originalValue) ? null : value;
              })
              .when("mainCategory", {
                is: false,
                then: Yup.number().required(t("Category is required")),
                otherwise: Yup.number().nullable(true),
              }),
          })}
          onSubmit={(values, helpers) => {
            try {
              dispatchWithToast(
                createOrUpdateDispatch(values),
                (data) => {
                  // if (onRefresh) {
                  //   onRefresh();
                  // }
                  setCategory(data.data);
                },
                () => {
                  values?.id ? onClose() : null;
                  onClose();
                }
              );
              helpers.setStatus({ success: true });
              helpers.setSubmitting(false);
            } catch (err) {
              toast.error("Something went wrong...");
              console.error(err);
              helpers.setStatus({ success: false });
              helpers.setErrors({ submit: err.message });
              helpers.setSubmitting(false);
            }
          }}
        >
          {(formik) => {
            return (
              <Form>
                <div className="grid grid-cols-2 gap-4 mt-10">
                  <div className="col-span-1 grid">
                    <div className="col-span-6 sm:col-span-6">
                      <PMField
                        name="title"
                        type="text"
                        label="Title"
                        {...formik}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6">
                      <PMField
                        name="description"
                        type="text"
                        label="Description"
                        multiline
                        rows={4}
                        {...formik}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6">
                      <PMCombobox
                        name="user_type"
                        label={t("User Type")}
                        options={userTypeOptions}
                        {...formik}
                      />
                    </div>

                    {userType == "customer" && (
                      <div className="col-span-3 sm:col-span-3">
                        <div className="py-2">
                          <PMSwitch name="public" label="Public" {...formik} />
                        </div>
                      </div>
                    )}

                    {!mainCategory && (
                      <div className="col-span-6 sm:col-span-6">
                        <FaqCategorySelector
                          name="parent_id"
                          // label="Variable Type"
                          selected={category?.category}
                          onlyParent={true}
                          {...formik}
                        />
                      </div>
                    )}
                  </div>

                  <div className="col-span-1">
                    <PMImagePicker
                      label="Image"
                      selected={formik?.values?.image}
                      name="image_id"
                      {...formik}
                      // {...formik}
                    />
                  </div>
                </div>

                <div className="mt-10 sm:mt-15 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    onClick={onClose}
                  >
                    {t("Close")}{" "}
                  </button>

                  <button
                    type="button"
                    className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    onClick={formik.submitForm}
                  >
                    {formik.values?.id ? t("Save") : t("Create")}{" "}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </PMDialog>
  );
};
