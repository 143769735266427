import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ServiceReportModal from "../../../components/dashboard/service/reports/service-report-edit-modal";
import { getAgreementTypesAsync } from "../../../slices/service/agreement-types";
import { getReportTypes } from "../../../slices/service/report-types";
import { getVariableTypesAsync } from "../../../slices/service/variable-types";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import ChecklistTypesStacklist from "../../../components/dashboard/quality/settings/checklist-types-stacklist";
import { getChecklistTypeOptions } from "../../../components/dashboard/quality/checklists/utils";

export const QualitySettings = (props) => {
  const { ...other } = props;
  const [newServiceReportModalOpen, setNewServiceReportModalOpen] =
    useState(false);
  const dispatch = useDispatch();

  const [controller, setController] = useState({
    filters: [],
    page: 1,
    query: "",
    sort: "desc",
    sortBy: "updated_at",
    view: "all",
  });

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={[]}
        title={"Quality Settings"}
        meta={[]}
        // mainButton={{
        //   label: "New",
        //   action: null,
        //   path: "/dashboard/orders/new",
        //   color: "",
        //   icon: PlusIcon,
        // }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            <div className="grid grid-cols-1 gap-5">
              <div className="grid grid-cols-1 max-sm:hidden"></div>
              <div className="grid grid-cols-2 max-sm:grid-cols-1  gap-5">
                <div className="grid-cols-1">
                  <ChecklistTypesStacklist />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardHeader>
      <ServiceReportModal
        onClose={() => setNewServiceReportModalOpen(false)}
        open={newServiceReportModalOpen}
        itemId={0}
      />
    </>
  );
};
