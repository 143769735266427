import React from "react";
import { useSelector } from "react-redux";

const RoleOnly = ({ role, action, children, placeholder = null }) => {
  const currentUser = useSelector((state) => state.session.currentUser);

  if (!role) return children; // If no role is provided, render the children

  // Return placeholder if no roles
  if (!currentUser?.roles) {
    return placeholder;
  }

  // Admins can see everything
  const isAdmin = currentUser?.roles?.some((r) => r.name === "admin");
  if (isAdmin) {
    return children;
  }

  const userRole = currentUser?.roles.find((r) => r.name === role); // Find the role from the user's roles array

  // If action is not provided, only check for the role existence
  if (!action) {
    return userRole ? children : placeholder;
  }

  // If the user doesn't have the role or the role doesn't have the specific action, render the placeholder or null
  if (!userRole || !userRole.can.includes(action)) {
    return placeholder;
  }

  // If the user has the role and the action, render the children
  return children;
};

export default RoleOnly;

// A function that return a boolean value if role and action is confirmed like the component above
export const useUserHasAccess = ({ role, action }) => {
  const currentUser = useSelector((state) => state.session.currentUser);

  if (!role) return true; // If no role is provided, render the children

  // Return placeholder if no roles
  if (!currentUser?.roles) {
    return false;
  }

  // Admins can see everything
  const isAdmin = currentUser?.roles.some((r) => r.name === "admin");
  if (isAdmin) {
    return true;
  }

  const userRole = currentUser?.roles.find((r) => r.name === role); // Find the role from the user's roles array

  // If action is not provided, only check for the role existence
  if (!action) {
    return userRole && true;
  }

  // If the user doesn't have the role or the role doesn't have the specific action, render the placeholder or null
  if (!userRole || !userRole.can.includes(action)) {
    return false;
  }

  // If the user has the role and the action, render the children
  return true;
};
