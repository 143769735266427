import { useTranslation } from "react-i18next";
import { PMSimpleSelectorField } from "../PMSimpleSelector/PMSimpleSelectorField";
import { PMSimpleSelectorFieldV2 } from "../PMSimpleSelector/PMSimpleSelectorFieldV2";
import axios from "../../../api/axios";
import { useState, useEffect } from "react";
import UppercaseFirstLetter, { mapErrors, upperFirstLetter } from "../../Utils";
import { Form, Formik } from "formik";
import { ResourceLoading } from "../ResourceLoading";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { PMField } from "../PMField";
import { useDispatch, useSelector } from "react-redux";
import {
  createContactAsync,
  getContactsAsync,
} from "../../../slices/customer/contact";
import { getVariableTypesAsync } from "../../../slices/service/variable-types";
import { debounce } from "lodash";
import { getResourceHubFaqCategoriesAsync } from "../../../slices/resource-hub/faq/category";

export const FaqCategorySelector = (props) => {
  const {
    setFieldValue,
    values,
    onChange,
    module,
    errors,
    module_param,
    fields,
    onlyParent,
    onlyChildren,
    userType,
  } = props;

  const name = props.name ? props.name : "category_id";
  const label = props.label ? props.label : "FAQ Category";
  const filters = [];

  const itemState = useSelector((store) => {
    return store.resource_hub_faq_categories;
  });

  const stateLoading = itemState.loading;

  const { t } = useTranslation();

  const [query, setQuery] = useState();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({
    id: 0,
    field1: "Ingen valgt",
    field2: null,
  });
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const labelAction = {
    // path: "/dashboard/projects/" + selected?.id,
    // action: () => toast("test"),
  };

  const handleChange = (data) => {
    console.log("data", data);

    setSelected({
      id: data?.selected?.item?.id,
      field1: data?.selected?.item?.primary,
    });

    setOpen(false);

    if (values) {
      setFieldValue(name, data.selected.item.id);
    }
    if (onChange) {
      onChange({
        target: { name: name, value: data.selected.item.id },
        item: data.selected.item,
      });
    }
  };

  const handleSelectFromForm = (data) => {
    setSelected({
      id: data?.id,
      field1: data?.displayTitle,
    });
    if (values) {
      setFieldValue(name, data?.id);
    } else {
      onChange({ target: { name: name, value: data?.id } });
    }
  };

  const createView = (item) => {
    const createOrUpdate = (values) => {
      const data = {
        ...values,
        customer_id: module_param,
      };
      const res = dispatch(createContactAsync(data));
      return res;
    };

    return (
      <>
        {loading || stateLoading ? (
          <ResourceLoading />
        ) : (
          <Formik
            initialValues={{
              id: null,
              title: item?.title
                ? item?.title
                : fields?.title
                ? fields?.title
                : "",
              submit: null,
            }}
            validationSchema={Yup.object({
              name: Yup.string().required(t("Name is required")),
            })}
            onSubmit={(values, helpers) => {
              try {
                toast.promise(createOrUpdate(values), {
                  loading: t("Saving...."),
                  success: (data) => {
                    if (data.status === 500) throw new Error("Server error");
                    if (data.status === 400) throw new Error("Server error");
                    if (data.data.errors)
                      throw new Error(mapErrors(data.data.errors));
                    // props.onRefresh(data.payload);
                    getItems();

                    if (data?.data?.id) {
                      handleSelectFromForm(data?.data);

                      setOpen(false);
                    }

                    return t("Data was saved!");
                  },
                  error: (error) => {
                    return t("Something went wrong. Could not save data!");
                  },
                });
                helpers.setStatus({ success: true });
                helpers.setSubmitting(false);
              } catch (err) {
                toast.error("Something went wrong...");
                console.error(err);
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
              }
            }}
          >
            {(formik) => (
              <Form>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="title"
                      type="text"
                      label={t("Title")}
                      {...formik}
                    />
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    onClick={formik.submitForm}
                  >
                    {item?.id ? t("update") : t("create")}{" "}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </>
    );
  };

  const dispatch = useDispatch();

  const getItems = () => {
    setLoading(true);

    const controller = {
      query: query ? query : "",
      page: 1,
      per_page: 10,
      sortBy: "created_at",
      sort: "desc",
      filters: filters,
      parent: onlyParent,
      children: onlyChildren,
      userType: userType,
      view: null,
    };

    dispatch(getResourceHubFaqCategoriesAsync(controller)).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    // Format rowParams if items changes
    var array = [
      { id: null, value: null, primary: "Ingen valgt", secondary: null },
    ];
    itemState?.data?.map((row, i) => {
      const rowParams = {
        value: row.id,
        id: row.id,
        primary: row.displayTitle,
        secondary: "for " + upperFirstLetter(row?.user_type),
        object: row,
      };
      array.push(rowParams);
    });
    setItems(array);
    setLoading(false);
  }, [itemState, dispatch]);

  const debouncedAction = debounce((controller) => {
    getItems();
  }, 500);

  useEffect(() => {
    if (open) {
      debouncedAction();
    }

    // Clean the debounced function on component unmount.
    return () => {
      debouncedAction.cancel();
    };
  }, [query, open, dispatch]);

  useEffect(() => {
    if (props.selected) {
      setSelected({
        id: props.selected?.id,
        field1: props.selected.displayTitle,
      });
    }
  }, [props.selected]);

  return (
    <>
      <PMSimpleSelectorFieldV2
        errors={errors}
        name={name}
        label={t(label)}
        labelAction={labelAction}
        options={items}
        selected={selected}
        loading={loading}
        onChange={handleChange}
        onQueryChange={(query) => setQuery(query)}
        createView={createView()}
        onClose={() => setOpen(!open)}
        open={open}
        fields={[
          {
            name: "name",
            label: "Name",
            type: "text",
            required: true,
            disabled: true,
          },
        ]}
      />
    </>
  );
};

FaqCategorySelector.defaultProps = {
  onlyChildren: false,
  onlyParent: false,
};
