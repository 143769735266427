import { useState } from "react";
import { toast } from "react-hot-toast";
import { ModuleTag } from "./moduleTag";

export const ChecklistTypeTag = ({ checklist_type, onRefresh, label }) => {
  const [dialogOpen, setDialogOpen] = useState();

  const handleOpenDialog = () => {
    if (checklist_type?.id) {
      setDialogOpen(true);
    } else {
      toast.error("Du må velge avvik-type først");
    }
  };
  return (
    <>
      <ModuleTag
        label={label || "Deviation Type"}
        onClick={() => handleOpenDialog(true)}
      >
        {checklist_type?.displayTitle}
      </ModuleTag>
    </>
  );
};
