import { toast } from "react-hot-toast";

export const ServiceAgreeementActionEditBar = ({
  selectedItems,
  setMultiEditDialogOpen,
}) => {
  return (
    <div className="flex items-center justify-between">
      <div className="">
        <span>{selectedItems.length} selected</span>
      </div>
      <div className="px-2 py-2">
        <button
          onClick={() => setMultiEditDialogOpen(true)}
          type="button"
          className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
        >
          Edit
        </button>

        <button
          onClick={() => toast.error("Funksjonen kommer snart")}
          type="button"
          className="inline-flex items-center rounded ml-2 bg-red-400 px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-red-300 hover:bg-red-200 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
        >
          Slett
        </button>
      </div>
    </div>
  );
};
