import axios from "./axios";

export async function deleteOrder(payload) {
  return axios
    .delete(`/orders/` + payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function createOrder(payload) {
  return axios
    .post(`/orders/`, { order: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function updateOrder(payload) {
  return axios
    .put(`/orders/` + payload.id, { order: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // return error.response.data
      if (error.status !== 200) return error;
      else return error.response;
    });
}

export async function fetchOrder(payload) {
  // payload is only ID
  return axios
    .get(`/orders/` + payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchStripeSession(order_id) {
  // payload is only ID
  return axios
    .get(`/orders/` + order_id + "/stripe_session")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchOrders(controller) {
  return axios({ method: "get", url: "/orders/list", params: controller })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}
