import { useTranslation } from "react-i18next";
import { useState, Fragment, useEffect } from "react";
import { Formik, Form } from "formik";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { mapErrors } from "../../../Utils";
import { UploaderV2 } from "../../Media/Uploaderv2";
import { DocumentViewer } from "../../Media/documentViewer";

import { useDispatch, useSelector } from "react-redux";
import { getUsersAsync } from "../../../../slices/user";
import { createDeviationItem } from "../../../../api/quality/deviation";
import { PMField } from "../../../utils/PMField";
import PMCombobox from "../../../utils/PMCombobox";
import { deviationItemActionOptions } from "./utils";
import { TWclassNames } from "../../../utils/Div";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { DocumentUploadSectionV2 } from "../../Media/DocumentUploadSectionV2";
export function DeviationItemForm(props) {
  const { deviation } = props;
  const { t } = useTranslation();

  const initialUsers = [{ name: "Unassigned", value: null }];

  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState();
  const [users, setUsers] = useState(initialUsers);

  const currentUser = useSelector((state) => state.session.currentUser);

  const itemState = useSelector((store) => {
    return store.users;
  });

  const dispatch = useDispatch();

  const getUsers = () => {
    setLoading(true);
    const controller = {
      query: query ? query : "",
      page: 1,
      per_page: 10,
      sortBy: "id",
      sort: "desc",
      filters: [{ name: "EMPLOYEE", value: true }],
      view: null,
      fields: "id,displayTitle",
    };

    dispatch(getUsersAsync(controller));
    setLoading(false);
  };

  useEffect(() => {
    // Format rowParams if users changes
    var array = initialUsers;
    itemState?.data?.map((row, i) => {
      const rowParams = {
        value: row.id,
        name: row.displayTitle,
      };
      array.push(rowParams);
    });
    setUsers(array);
    setLoading(false);
  }, [itemState, dispatch]);

  useEffect(() => {
    if (open) {
      getUsers();
    }
  }, [query, open, dispatch]);

  const createItem = async (values) => {
    return await createDeviationItem(values);
  };

  const [documents, setDocuments] = useState([]);

  const UploaderCallback = (newDocuments, formik) => {
    // Add documents to document state
    const newArray = documents.concat(newDocuments);

    setDocuments(newArray);

    // Add documents to formik
    formik.setFieldValue("documents", newArray);

    setIsLoading(false);
  };

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: null,
        deviation_id: deviation?.id,
        date: new Date().toISOString().slice(0, 10),
        description: null,
        documents: null,
        action: null,
        submit: null,
      }}
      validationSchema={Yup.object({
        description: Yup.string().required(t("Description is required")),
      })}
      onSubmit={(values, helpers) => {
        try {
          toast.promise(createItem(values), {
            loading: t("Saving...."),
            success: (data) => {
              if (data?.status === 500) throw new Error("Server error");
              if (data?.status === 400) throw new Error("Server error");
              if (data?.status === 401)
                throw new Error("You are not authorized!");
              if (data?.data?.errors)
                throw new Error(mapErrors(data.payload.response.data.errors));
              props.onChange(data?.data);
              return t("Data was saved!");
            },
            error: (error) => {
              console.error(error);
              return t(
                "Something went wrong. Could not save data!" + " " + error
              );
              // Only for troubleshooting
              // return t(error);
            },
          });
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
          helpers.resetForm();
        } catch (err) {
          toast.error("Something went wrong...");
          console.error(err);
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <Form className="relative">
          {isLoading && (
            <ResourceLoading
              size="other"
              position="absolute"
              baseClass=""
              loaderClass="left-1/2 top-1/2"
              text={t("Laster...")}
            />
          )}
          <div
            className={TWclassNames(
              isLoading
                ? "bg-gray-400 opacity-25 cursor-not-allowed"
                : "bg-white",
              "overflow-hidden rounded-lg border  border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
            )}
          >
            <div className="px-5 py-5">
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
                <div className="col-span-1 sm:col-span-1">
                  <PMField
                    disabled={isLoading}
                    name="date"
                    label={t("Date")}
                    type="date"
                    {...formik}
                  />
                </div>
                <div className="col-span-3 sm:col-span-1">
                  <PMCombobox
                    disabled={isLoading}
                    name="action"
                    label={t("Type")}
                    options={deviationItemActionOptions}
                    filters={[
                      {
                        name: "responsibleUserOnly",
                        value:
                          deviation?.responsible_user &&
                          currentUser?.id === deviation?.responsible_user?.id
                            ? true
                            : false,
                      },
                    ]}
                    {...formik}
                  />
                </div>
              </div>
            </div>

            <label htmlFor="description" className="sr-only">
              Description
            </label>
            <textarea
              disabled={isLoading}
              wrap="off"
              rows={2}
              name="description"
              id="description"
              className="block w-full pt-2.5 resize-none border-0 px-5 py-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 disabled:bg-gray-400 "
              placeholder="Write a description..."
              value={formik.values.description}
              onChange={(e) =>
                formik.setFieldValue("description", e.target.value)
              }
              defaultValue={""}
            />

            {/* Spacer element to match the height of the toolbar */}
            <div aria-hidden="true">
              <div className="py-2">
                <div className="h-9" />
              </div>
              <div className="h-px" />
              <div className="py-2">
                <div className="py-px">
                  <div className="h-9" />
                </div>
              </div>
            </div>
          </div>

          <div className="absolute inset-x-px bottom-0">
            <div className="flex users-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
              <div className="flex">
                <div className="">
                  {/* <UploaderV2
                    disabled={isLoading}
                    module="deviation"
                    module_param={deviation?.id}
                    size="xs"
                    onLoading={() => setIsLoading(true)}
                    parentCallback={(data) => UploaderCallback(data, formik)}
                  /> */}

                  <DocumentUploadSectionV2
                    module="deviation"
                    module_param={deviation?.id}
                    parentCallback={(data) => UploaderCallback(data, formik)}
                  />
                </div>
              </div>
              <div className="flex-shrink-0">
                <button
                  onClick={formik.submitForm}
                  type="button"
                  className="disabled:bg-gray-200 inline-flex users-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  disabled={isLoading}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
          <div className="absolute mt-2 px-2 py-2  bg-white rounded-xl">
            <DocumentViewer
              pathType="quickview"
              documents={formik?.values?.documents}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
