import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

export const ResourceEmpty = (props) => {
  const { reload, text } = props;

  if (reload) {
    return <button> Reload Button </button>;
  }

  return (
    <div className="p-5 bg-white shadow rounded-md flex items-center mt-5">
      <ExclamationTriangleIcon className="w-5 h-5" />
      <div className="ml-2 font-medium text-lg text-grey">{text}</div>
    </div>
  );
};

ResourceEmpty.defaultProps = {
  text: "No item(s) found",
};
