import { dividerClasses } from "@mui/material";
import * as React from "react";
import { TableCell, TableRow, Checkbox, Button } from "@mui/material";

export default function itemTree(props) {
  const { items, options, columns } = props;

  const tree = (items) => {
    if (items && items.length > 0) {
      return items?.map((item) => {
        if (item.ancestry) {
          var ancestryCount = item.ancestry
            .split("/")
            .map((item) => item.trim()).length;
        } else {
          ancestryCount = 0;
        }

        return (
          // eslint-disable-next-line react/jsx-key
          <React.Fragment>
            <TableRow
              onClick={() => props.onClick(item)}
              hover={true}
              key={item.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {/* <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        onChange={(e) => handleSelectProduct(e.target.checked, item.id)}
                                        sx={{
                                            pl: `${ancestryCount ? ancestryCount * 5 + 10 : '10'}px`
                                        }}
                                    />
                                </TableCell> */}

              {/* {columns.id ?
                                    <TableCell>{item.id ? item.id : ''}</TableCell>
                                    : ''} */}

              <TableCell>
                <img
                  src={item?.image?.file?.thumb?.url}
                  className="h-10 w-10"
                />
              </TableCell>

              {columns.item_number ? (
                <TableCell>
                  {item.item_number ? item.item_number : ""}
                </TableCell>
              ) : (
                ""
              )}

              {columns.title ? (
                <TableCell component="th" scope="row">
                  {item.title ? item.title : ""}
                </TableCell>
              ) : (
                ""
              )}

              {columns.description ? (
                <TableCell component="th" scope="row">
                  {item.description ? item.description : ""}
                </TableCell>
              ) : (
                ""
              )}

              {columns.actions ? (
                <TableCell>
                  {/* {options.delete ?
                                            <Button color="error" onClick={() => this.props.handleDelete(category)}>Delete</Button>
                                            : ''
                                        } */}
                </TableCell>
              ) : (
                ""
              )}
            </TableRow>

            {/* Recursion  */}
            {/* Returning children's children of main category / descendants and so on...*/}
            {tree(item.children)}
          </React.Fragment>
        );
      });
    }
  };

  return (
    <React.Fragment>
      {items ? (
        tree(items)
      ) : (
        <TableRow style={{ padding: 10 }}>
          <TableCell>No items...</TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
