import axios from "../axios";

export async function deleteStorage(payload) {
  return axios
    .delete(`/inventory/storages/` + payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function createStorage(payload) {
  return axios
    .post(`/inventory/storages`, { inventory_storage: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function updateStorage(payload) {
  return axios
    .put(`/inventory/storages/` + payload.id, { inventory_storage: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchStorages() {
  return axios
    .get(`/inventory/storages`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.status === 500) return error;
      else return error.response;
    });
}

export async function fetchStorage(payload) {
  // payload is only ID
  return axios
    .get(`/inventory/storages/` + payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}
