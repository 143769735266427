import React from "react";
import {
  TextField,
  Grid,
  Paper,
  Button,
  Autocomplete,
  Box,
  Stack,
} from "@mui/material";
import axios from "../../../../api/axios";
import { element } from "prop-types";
import { StaticDateTimePicker } from "@mui/lab";
import { PMImagePicker } from "../../../utils/PMImagePicker";

class CategoriesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      id: "",
      title: "",
      description: "",
      parent_category: 0,
      image: {},
      image_id: null,
      parent_row: 0,
      category: [],
      categories: [],
      selectedOption: [],
      categoriesOptions: [
        {
          row_count: 0,
          value: "",
          label: "Ikke valgt",
        },
      ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate() {
    const { selectedCategory } = this.props;

    if (
      (selectedCategory && this.state.id === "") ||
      (selectedCategory &&
        selectedCategory.id != this.state.id &&
        this.state.id != "")
    ) {
      this.clearForm();
      this.setState({
        id: selectedCategory.id,
        title: selectedCategory.title,
        description: selectedCategory.description,
        parent_category: selectedCategory.parent_category || 0,
        image_id: selectedCategory?.image?.id,
        image: selectedCategory?.image,
      });

      setTimeout(() => {
        this.findAndSetOption();
      }, 100);
    }
  }

  findAndSetOption() {
    if (this.state.parent_category > 0) {
      const option = this.state.categoriesOptions.find((e) => {
        return e.value == this.state.parent_category;
      });
      this.setState({
        parent_row: option?.row_count || 0,
      });
    } else {
      console.log("Nope");
    }
  }

  getCategories = () => {
    this.setState({ IsLoading: true });
    const request = {
      parents: false,
    };
    axios
      .get(`/products/categories/list?parents=false`)
      .then((response) => {
        this.setState({
          isLoading: false,
          IsError: false,
          categories: response.data.data,
        });
        setTimeout(() => this.setOptions(), 100);
      })
      .catch((e) => {
        console.error(e);
        this.setState({ loading: false, error: true });
        this.props.callback({
          IsError: true,
          IsLoading: false,
          alertSeverity: "error",
          alertMessage: "Could not load categories for parent_category select",
          alertOpen: true,
        });
      });
  };

  clearForm = () => {
    this.setState({
      id: "",
      title: "",
      description: "",
      isLoading: false,
      parent_category: 0,
      parent_title: "",
      parent_row: 0,
      image: null,
    });
  };

  onCategoryChange = (data) => {
    this.setState({ parent_category: data.value, parent_row: data.row_count });
  };

  addCategoryHandler = async () => {
    const category = {
      title: this.state.title,
      description: this.state.description,
      parent_category: this.state.parent_category,
    };

    this.props.categoriesFormCallback(category);

    this.clearForm();

    setTimeout(() => {
      this.getCategories();
    }, 100);
  };

  updateCategory = async (id) => {
    const request = {
      category: {
        title: this.state.title,
        description: this.state.description,
        parent_category: this.state.parent_category,
        image_id: this.state.image_id,
      },
    };
    await axios
      .put(`/products/categories/${this.state.id}`, request)
      .then((response) => {
        this.props.callback({
          error: false,
          loading: false,
          alertSeverity: "success",
          alertMessage: "The category was saved successfully. ",
          alertOpen: true,
        });
        this.clearForm();
      })
      .catch((err) => {
        this.props.callback({
          error: true,
          loading: false,
          alertSeverity: "error",
          alertMessage:
            "Something went wrong. Could not save the category. " + err,
          alertOpen: true,
        });
      });
  };

  handleSubmitUpdate() {
    if (this.state.id && this.props.selectedCategory) {
      this.updateCategory();
    } else {
      this.addCategoryHandler(this.state);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.id === this.state.parent_category) {
      this.props.callback({
        error: true,
        loading: false,
        alertSeverity: "error",
        alertMessage: "",
        alertOpen: true,
        confirmation: {
          action: "update",
          severity: "info",
          message:
            "You have selected a parent_category that matches current category. You cannot choose the same category as parent.",
          open: true,
          item_id: null,
        },
      });
      return;
    }

    if (this.state.title === "") {
      alert("Title cannot be empty");
      return;
    }

    this.handleSubmitUpdate();
    return;
  };

  handleDelete = (e) => {};

  setOptions = () => {
    this.setState({
      categoriesOptions: [
        {
          row_count: 0,
          value: "",
          label: "Ikke valgt",
        },
      ],
    });
    if (this.state.categories) {
      var i = 0;
      this.state.categories.map((category) => {
        i += 1;
        const option = {
          name: "parentCategory",
          row_count: i,
          value: category.id,
          label: category.title,
          isSelected: this.state.id === category.id ? true : false,
          isDisabled: this.state.id === category.id ? true : false,
        };
        this.setState((prevState) => ({
          categoriesOptions: [...prevState.categoriesOptions, option],
        }));
      });
    } else {
      return;
    }
  };

  componentDidMount() {
    this.getCategories();
    if (!this.state.selectedOption) {
      setTimeout(() => {
        this.setState({ selectedOption: this.state.categoriesOptions[0] });
      }, 200);
    }
  }

  render() {
    return (
      <Box
        id="product-categories-form"
        onSubmit={this.handleSubmit}
        noValidate
        autoComplete="off"
        component="form"
        sx={{ width: "100%", ml: "20px", mr: "20px" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="category-title"
              name="title"
              label="Category Title"
              sx={{ width: "80%" }}
              value={this.state.title}
              onChange={(e) => this.setState({ title: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="category-description"
              name="description"
              label="Category Beskrivelse"
              sx={{ width: "80%" }}
              multiline
              rows={5}
              value={this.state.description}
              onChange={(e) => this.setState({ description: e.target.value })}
            />
          </Grid>
          <Grid item xs={10}>
            {this.state.categoriesOptions ? (
              <Autocomplete
                options={this.state.categoriesOptions}
                loading={this.state.isLoading}
                id="parentCategories"
                sx={{ width: "80%" }}
                // value={this.state.parent_title || 'Ikke valgt'}
                value={this.state.categoriesOptions[this.state.parent_row]}
                renderInput={(params) => (
                  <TextField {...params} key={params.value} label="" />
                )}
                onChange={(event, data) => this.onCategoryChange(data)}
              />
            ) : (
              // <ReactSelect
              //     sx={{ mt: "10px", width: '80%'}}
              //     options={this.state.categoriesOptions}
              //     isLoading={this.state.isLoading}
              //     isSearchable
              //     menuPlacement={"bottom"}
              //     menuPosition="fixed"
              //     aria-label="Velg enhet"
              //     defaultValue={this.state.categoriesOptions[this.state.parent_category]}
              //     onChange={(data) => this.onCategoryChange(data)} />
              //
              ""
            )}
          </Grid>

          <Grid item>
            {/* logo upload start */}
            <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6">
                    <PMImagePicker
                      label="Image"
                      selected={this.state.image}
                      name="image_id"
                      onChange={(e) => {
                        this.setState({ image_id: e.target.value });
                      }}
                      // {...formik}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* logo upload end */}
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={3} direction="row">
              {this.state.id != "" ? (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => this.props.handleDelete(this.state.id)}
                >
                  Delete
                </Button>
              ) : (
                ""
              )}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                value="submit"
              >
                Lagre
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default CategoriesForm;
