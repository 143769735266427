import produce from "immer";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchCountItems, updateCountItems, createCount, deleteCount } from '../../api/inventory/count_items'

const initialState = {
    isLoading: false,
    error: false,
    errors: null,
    data: [],
};


export const deleteCountAsync = createAsyncThunk(
    'inventory_counts/deleteCountAsync', 
    async (payload) => {


        let response = await deleteCount(payload);
        
        // If errors, reject and return response
        if (response.errors) return false

        // If all good, return response.
        return response;
    }
)


// Updates or creates new items
export const updateCountItemsAsync = createAsyncThunk(
    'inventory_count_items/updateCountItemsAsync', 
    async (payload) => {
        let response = await updateCountItems(payload);
        
        // If errors, reject and return response
        if (response.errors.length > 0) return false

        // If all good, return response.
        return response;
    }
)

export const getCountItemsAsync = createAsyncThunk(
    'inventory_count_items/getCountItemsAsync', 
    async (id, payload) => {

        // if (! controller) return false;
        let response = await fetchCountItems(id, payload);
        
        // If errors, reject and return response
        // if (response.errors) return false;

        // If all good, return response.
        return response;
})



const InventoryCountItemSlice = createSlice({
    name: 'inventory_count_items',
    initialState,
    reducers: {
    },
    extraReducers : (builder) => {
        builder.addCase(getCountItemsAsync.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getCountItemsAsync.fulfilled, (state, action ) => {
            state.isLoading = false;
            state.data = action.payload;
        })
        .addCase(getCountItemsAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errors = action.payload
        })
        .addCase(updateCountItemsAsync.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(updateCountItemsAsync.fulfilled, (state, action ) => {
            return produce(state, (draftState) => {
                // const index = draftState.data.findIndex(item => item.id === action.payload.id);
                draftState.data = action.payload.data;
                draftState.isLoading = false;
                draftState.errors = action.payload.errors;
            })
        })
        .addCase(updateCountItemsAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errors = action.payload.errors
        })
        .addCase(deleteCountAsync.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(deleteCountAsync.fulfilled, (state, action ) => {
            return produce(state, (draftState) => {
                draftState.data = action.payload;
                draftState.isLoading = false;
            })
        })
        .addCase(deleteCountAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errors = ['Something went wrong when trying to update report type']
        })
    }
})


// export const {} = customerSlice.actions;

export const { reducer } = InventoryCountItemSlice;

export default InventoryCountItemSlice

// export const selectInventoryCountById = (state, itemId) => state.inventory_counts.data.find(item => item.id == itemId)
