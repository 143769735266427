import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createResourceHubFaqPost,
  destroyResourceHubFaqPost,
  fetchResourceHubFaqPosts,
  updateResourceHubFaqPost,
} from "../../../api/resource-hub/faq/posts";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  case: {},
  totalCount: 0,
  currentCount: 0,
};

export const initialController = {
  filters: [],
  page: 1,
  per_page: 200,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const deleteResourceHubFaqPostAsync = createAsyncThunk(
  "resource_hub_faq_posts/deleteResourceHubFaqPostAsync",
  async (payload) => {
    let response = await destroyResourceHubFaqPost(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // include the ID in the response
    response.id = payload.id;
    // If all good, return response.
    return response;
  }
);

export const createResourceHubFaqPostAsync = createAsyncThunk(
  "resource_hub_faq_posts/createResourceHubFaqPostAsync",
  async (payload) => {
    let response = await createResourceHubFaqPost(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const updateResourceHubFaqPostAsync = createAsyncThunk(
  "resource_hub_faq_posts/updateResourceHubFaqPostAsync",
  async (payload, { rejectWithValue }) => {
    let response = await updateResourceHubFaqPost(payload);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/)) return rejectWithValue;

    // If all good, return response.
    return response;
  }
);
export const getResourceHubFaqPostsAsync = createAsyncThunk(
  "resource_hub_faq_posts/getResourceHubFaqPostsAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchResourceHubFaqPosts(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

const resourceHubFaqPostSlice = createSlice({
  name: "resource_hub_faq_posts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getResourceHubFaqPostsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getResourceHubFaqPostsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.currentCount = action.payload.currentCount;
      })
      .addCase(getResourceHubFaqPostsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch. Contact admin.",
        ];
      })
      .addCase(updateResourceHubFaqPostAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateResourceHubFaqPostAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data = draftState.data.map((item) => {
            if (item.id === action.payload.id) {
              return action.payload;
            } else {
              return item;
            }
          });

          // draftState.case = action.payload;
          draftState.isLoading = false;
          draftState.error = false;
          draftState.errors = null;
        });
      })
      .addCase(updateResourceHubFaqPostAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update ResourceHubFaqPost.",
        ];
      })
      .addCase(createResourceHubFaqPostAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createResourceHubFaqPostAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
          draftState.error = false;
          draftState.errors = null;
        });
      })
      .addCase(createResourceHubFaqPostAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to create ResourceHubFaqPost.",
        ];
      })
      .addCase(deleteResourceHubFaqPostAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteResourceHubFaqPostAsync.fulfilled, (state, action) => {
        console.log("action", action);
        return produce(state, (draftState) => {
          draftState.data = draftState.data.filter(
            (item) => item.id !== action.payload.id
          );
          draftState.isLoading = false;
          draftState.error = false;
          draftState.errors = null;
        });
      })
      .addCase(deleteResourceHubFaqPostAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to delete ResourceHubFaqPost.",
        ];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = resourceHubFaqPostSlice;

export default resourceHubFaqPostSlice;
