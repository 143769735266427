import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { PMField } from "../../../utils/PMField";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dispatchWithToast, mapErrors } from "../../../Utils";
import PMSwitch from "../../../utils/PMSwitch";
import {
  createReportTypeAsync,
  getReportTypeAsync,
  updateReportTypeAsync,
} from "../../../../slices/service/report-types";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { getChecklistType } from "../../../../api/quality/checklist";
import {
  createChecklistTypeAsync,
  updateChecklistTypeAsync,
} from "../../../../slices/quality/checklist-type";
import ChecklistTypeItemLines from "../checklists/checklistTypeItemLines";

export default function ChecklistTypeModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { open, onClose, itemId } = props;

  const [itemState, setItemState] = useState({
    checklist_type: {},
    isLoading: true,
  });

  const { checklist_type, isLoading } = itemState;

  useEffect(() => {
    const fetchChecklistType = async (itemId) => {
      const res = await getChecklistType(itemId);
      if (res.status === 200) {
        setItemState((prevState) => ({
          ...prevState,
          checklist_type: res?.data?.data,
          isLoading: false,
        }));
      }
    };

    if (itemId) {
      fetchChecklistType(itemId);
    } else {
      setItemState((prevState) => ({
        ...prevState,
        checklist_type: {},
        isLoading: false,
      }));
    }
  }, [open]);

  const createOrUpdateDispatch = (data) => {
    if (checklist_type?.id) {
      const res = dispatch(updateChecklistTypeAsync(data));
      return res;
    } else {
      const res = dispatch(createChecklistTypeAsync(data));
      return res;
    }
  };

  const [items, setItems] = useState([]);

  const addItemRow = (type) => {
    const newRow = {
      title: "",
      description: "",
      purchase_price: 0,
      quantity: 0,
      group_row: type == "group" ? true : false,
      row_order: items.length,
      // Generating a fake ID for draggable, so that we are able to move the row before saving.
      draggableId: "id" + items.length + 1,
    };
    setItems((prevState) => [...prevState, newRow]);
  };

  const onItemRowChange = (e, data) => {
    // setItems(prevState => ({ ...prevState, [1][description]: 'test' }))
    e.preventDefault();

    let prevItems = [...items];
    let item = {
      ...prevItems[data.row_order],
      [e.target.name]: e.target.value,
    };
    prevItems[data.row_order] = item;
    setItems(prevItems);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full sm:max-w-5xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {isLoading ? (
                  <ResourceLoading />
                ) : (
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {itemId
                        ? t("service.checklist_types.edit")
                        : t("service.checklist_types.create")}
                    </Dialog.Title>

                    {isLoading ? (
                      "Loading..."
                    ) : (
                      <Formik
                        enableReinitialize
                        initialValues={{
                          id: checklist_type?.id,
                          title: checklist_type?.title,
                          description: checklist_type?.description,
                          active: checklist_type?.active || true,
                          items: [],
                          submit: null,
                        }}
                        validationSchema={Yup.object({
                          title: Yup.string()
                            .max(255)
                            .required(t("title.required")),
                          description: Yup.string().max(500),
                        })}
                        onSubmit={(values, helpers) => {
                          try {
                            dispatchWithToast(
                              createOrUpdateDispatch(values),
                              (payload) => {
                                console.log(payload);
                                setItemState((prevState) => ({
                                  ...prevState,
                                  isLoading: false,
                                  checklist_type: payload?.data?.data,
                                })),
                                  props.callback(payload?.data?.data);
                              },
                              onClose(true)
                            );

                            helpers.setStatus({ success: true });
                            helpers.setSubmitting(false);
                          } catch (err) {
                            toast.error("Something went wrong...");
                            console.error(err);
                            helpers.setStatus({ success: false });
                            helpers.setErrors({ submit: err.message });
                            helpers.setSubmitting(false);
                          }
                        }}
                      >
                        {(formik) => (
                          <Form>
                            <div className="grid grid-cols-6 gap-6 mt-10">
                              <div className="col-span-6 sm:col-span-3">
                                <PMField
                                  name="title"
                                  type="text"
                                  label="Title"
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-3 sm:mt-8">
                                <PMSwitch
                                  name="active"
                                  label="Active"
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-6">
                                <PMField
                                  name="description"
                                  type="text"
                                  label="Description"
                                  multiline
                                  rows={2}
                                  {...formik}
                                />
                              </div>
                            </div>

                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                              <button
                                type="button"
                                className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                onClick={onClose}
                              >
                                {t("Close")}{" "}
                              </button>

                              <button
                                type="button"
                                className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                onClick={formik.submitForm}
                              >
                                {checklist_type?.id ? t("Save") : t("Create")}{" "}
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
