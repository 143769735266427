import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { mapErrors } from "../../../Utils";
import { createNote } from "../../../../api/notes";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import PMCombobox from "../../../utils/PMCombobox";
import { reportStatusesOptions } from "./utils";
import { updateReportAsync } from "../../../../slices/service/reports";
import { useDispatch } from "react-redux";
import SimplePrompt from "../../../utils/simplePrompt";
import PMSwitch from "../../../utils/PMSwitch";
import { PMField } from "../../../utils/PMField";

export const ReportStatusDialog = (props) => {
  const { onLoading, onClose, open, isLoading, report } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const statusOptions = [
    {
      id: 1,
      value: "UNDER_PROCESS",
      title: "Under process",
      label: "Under process",
    },
    { id: 2, value: "PROCESSED", title: "Processed", label: "Processed" },
    // { id: 3, value: "INVOICED", title: "Invoiced", label: "Invoiced" },
    { id: 4, value: "CLOSED", title: "Closed", label: "Closed" },
  ];

  const initialValues = {
    id: report?.id,
    status: report?.processStatus ? report?.processStatus.toUpperCase() : 0,
    finished: false,
    finished_date: null,
    delete: false,
    invoiced: report?.invoiced,
    submit: null,
  };

  const [deletePrompt, setDeletePrompt] = useState(false);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {t("Status")}
                    </Dialog.Title>
                    <p>
                      Dette verktøyet bør kun brukes av administratør for
                      behandling av rapport eller endring av status/
                      ferdigstilling eller sletting av rapport
                    </p>

                    {isLoading ? (
                      <ResourceLoading />
                    ) : !report?.id ? (
                      "Missing ID"
                    ) : (
                      <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={Yup.object({
                          finished_date: Yup.string()
                            .nullable()
                            .when("finished", (finishedValue) => {
                              if (finishedValue === false) {
                                return Yup.string().nullable();
                              } else {
                                return Yup.date().required("Date is required");
                              }
                            }),

                          status: Yup.string().when(
                            "finished",
                            (finishedValue) => {
                              if (finishedValue === true) {
                                return Yup.string();
                              } else {
                                return Yup.string().required(
                                  "You need to pick a status option"
                                );
                              }
                            }
                          ),
                        })}
                        onSubmit={(values, helpers) => {
                          try {
                            toast.promise(dispatch(updateReportAsync(values)), {
                              loading: t("Saving...."),
                              success: (data) => {
                                if (data.status === 500) {
                                  throw new Error("Server error");
                                }
                                if (!data.status === 200) {
                                  throw new Error(
                                    mapErrors(data.payload.errors)
                                  );
                                }
                                props.onClose();
                                props.onRefresh(data.payload);
                                return t("Data was saved!");
                              },
                              error: (error) => {
                                return t(
                                  "Something went wrong. Could not save data!"
                                );
                              },
                            });
                            helpers.setStatus({ success: true });
                            helpers.setSubmitting(false);
                            helpers.resetForm(initialValues);
                          } catch (err) {
                            toast.error("Something went wrong...");
                            console.error(err);
                            helpers.setStatus({ success: false });
                            helpers.setErrors({ submit: err.message });
                            helpers.setSubmitting(false);
                          }
                        }}
                      >
                        {(formik) => (
                          <Form>
                            <div className="grid grid-cols-6 gap-6 mt-10">
                              {!report?.finished && (
                                <div className="col-span-6 sm:col-span-6 border-2 border-green-200 rounded-xl p-5">
                                  <p className="font-semibold mb-2">
                                    Ferdigstill rapporten
                                  </p>
                                  <div className="py-5">
                                    <PMSwitch
                                      name="finished"
                                      label={t("Ferdigstill")}
                                      {...formik}
                                    />
                                  </div>
                                  <PMField
                                    name="finished_date"
                                    label={t("Finished Date")}
                                    type="date"
                                    {...formik}
                                  />
                                  {formik.values.finished && (
                                    <p>
                                      Rapporten ferdigstilles ved oppdatering.
                                    </p>
                                  )}
                                </div>
                              )}

                              {!formik.values.delete ? (
                                <div className="col-span-6 sm:col-span-6  border-2 border-blue-200 rounded-xl p-5">
                                  <PMCombobox
                                    name="status"
                                    label={t("Status")}
                                    options={statusOptions}
                                    {...formik}
                                  />
                                </div>
                              ) : (
                                <div className="col-span-6 sm:col-span-6">
                                  <p>Rapporten slettes når du oppdaterer</p>
                                </div>
                              )}

                              <div className="col-span-6 sm:col-span-6 border-2 rounded-xl p-5">
                                <PMSwitch
                                  name="invoiced"
                                  label={t("Invoiced")}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "invoiced",
                                      e.target.checked
                                    );
                                    if (formik.values.invoiced !== true) {
                                      formik.setFieldValue(
                                        "status",
                                        "PROCESSED"
                                      );
                                    } else {
                                      formik.setFieldValue("status", 0);
                                    }
                                  }}
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-6 border-2 border-red-200 rounded-xl p-5">
                                <PMSwitch
                                  name="delete"
                                  label={t("Delete report")}
                                  onChange={
                                    formik.values.delete
                                      ? () =>
                                          formik.setFieldValue("delete", false)
                                      : () => setDeletePrompt(true)
                                  }
                                  {...formik}
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-6"></div>
                              <div className="col-span-6 sm:col-span-6"></div>
                              <div className="col-span-6 sm:col-span-6"></div>
                            </div>
                            <div className="mt-20 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                              <button
                                type="button"
                                className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                onClick={onClose}
                              >
                                {t("Close")}{" "}
                              </button>

                              <button
                                type="button"
                                className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                onClick={formik.submitForm}
                              >
                                {t("update")}
                              </button>
                            </div>

                            <SimplePrompt
                              title="Slett rapport"
                              description="Er du sikker på at du ønsker å slette rapporten?"
                              onAccept={() =>
                                formik.setFieldValue("delete", true)
                              }
                              open={deletePrompt}
                              onClose={() => setDeletePrompt(false)}
                            />
                          </Form>
                        )}
                      </Formik>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
