import React, { useEffect, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  Grid,
  Typography,
  FormControl,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  DialogTitle,
} from "@mui/material";

import { FixedSizeList } from "react-window";

import axios from "../../../api/axios";
import { ResourceLoading } from "../ResourceLoading";
import { ResourceEmpty } from "../ResourceEmpty";
import { useTranslation } from "react-i18next";
import { ArrowLeftIcon, PlusIcon } from "@heroicons/react/24/solid";
import { SearchInput } from "../SearchInput";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { StringLimit } from "../../Utils";
import { ResourceError } from "../ResourceError";

export default function PMSimpleSelectorModalV2(props) {
  const [selectedItem, setSelectedItem] = useState();

  const [search, setSearch] = useState("");

  const {
    open,
    items,
    loading,
    name,
    label,
    createView,
    onClose,
    searchQuery,
    searchEnabled,
    error,
  } = props;

  const [inCreateView, setInCreateView] = useState(false);

  const { t } = useTranslation();

  const handleSelect = (item) => {
    setSelectedItem(item);
    props.callback({
      selected: { item },
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
    props.query(e.target.value);
  };

  const handleClose = () => {
    props.onClose();
  };

  useEffect(() => {
    if (inCreateView) {
      setInCreateView(false);
    }
  }, [items]);

  useEffect(() => {
    setSearch(searchQuery);
    props.query(searchQuery);
  }, [searchQuery]);

  const renderItems = ({ index, style }) => {
    return (
      <div>
        <ListItem
          key={items[index].id}
          style={style}
          className="hover:border-l-4 hover:border-indigo-500 hover:bg-indigo-100"
        >
          <ListItemButton onClick={() => handleSelect(items[index])}>
            <ListItemText
              primary={items[index].primary}
              secondary={items[index].secondary}
            />
          </ListItemButton>
        </ListItem>
      </div>
    );
  };

  const renderList = () => {
    return (
      <>
        <FormControl sx={{ ml: "10px", pl: "10px", width: "90%" }} fullWidth>
          <div className="flex items-center">
            <input
              type="text"
              name="search"
              className="w-full px-4 py-2 border border-gray-400 focus:outline-none focus:border-gray-500 mb-2 rounded-2xl"
              placeholder={"Søk etter " + label + "..."}
              onChange={(e) => handleSearch(e)}
              value={search}
            />
          </div>

          {/* <TextField
            name="search"
            id="search"
            label={"Søk etter " + label + "..."}
            value={search}
            onChange={(e) => handleSearch(e)}
            fullWidth={true}
            sx={{ width: "80%", marginBottom: "10px" }}
          /> */}
        </FormControl>
        {loading ? (
          <ResourceLoading />
        ) : items && items.length > 0 ? (
          <FixedSizeList
            height={400}
            width={"100%"}
            itemSize={40}
            itemCount={items.length}
            // overscanCount={5}
          >
            {renderItems}
          </FixedSizeList>
        ) : (
          <ResourceEmpty />
        )}
      </>
    );
  };

  const renderListV2 = () => {
    if (loading) return <ResourceLoading />;
    if (error) return <ResourceError errors={error} />;

    return (
      <>
        <div>
          {items?.length > 0 ? (
            <ul>
              {items?.map((item) => (
                <li
                  key={item.id}
                  className="hover:border-l-4 text-sm hover:border-indigo-500 hover:bg-indigo-100"
                >
                  <ListItemButton onClick={() => handleSelect(item)}>
                    <ListItemText
                      primary={<StringLimit text={item?.primary} limit={30} />}
                      secondary={
                        <StringLimit text={item?.secondary} limit={35} />
                      }
                    />
                  </ListItemButton>
                </li>
              ))}
            </ul>
          ) : (
            <div className="mt-10 flex justify-center">
              {createView ? (
                <button
                  onClick={() => setInCreateView(!inCreateView)}
                  type="button"
                  className="bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded-2xl"
                >
                  {t("Create")} {label}
                </button>
              ) : (
                <ResourceEmpty />
              )}
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <Transition appear show={props.open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-2 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 py-2 px-4 pt-2"
                    >
                      {t("Choose")} {label}
                    </Dialog.Title>

                    <div>
                      {createView && (
                        <button
                          onClick={() => setInCreateView(!inCreateView)}
                          type="button"
                          className="bg-transparent hover:bg-green-300 text-green-500 font-semibold hover:text-white py-2 px-4 border border-green-300 hover:border-transparent rounded"
                        >
                          {!inCreateView ? (
                            <PlusIcon className="h-5 w-5" />
                          ) : (
                            <ArrowLeftIcon className="h-5 w-5" />
                          )}
                        </button>
                      )}
                      <button
                        onClick={onClose}
                        type="button"
                        className="bg-transparent hover:bg-gray-300 text-gray-500 font-semibold hover:text-white py-2 px-4 rounded"
                      >
                        <XMarkIcon className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                  {searchEnabled && (
                    <div className="mt-5">
                      <input
                        type="text"
                        name="search"
                        className="w-full px-4 py-2 border border-gray-400 focus:outline-none focus:border-gray-500 mb-2 rounded-2xl"
                        placeholder={"Søk etter " + label + "..."}
                        onChange={(e) => handleSearch(e)}
                        value={search}
                      />
                    </div>
                  )}
                  <div className="h-[500px] overflow-auto">
                    {!inCreateView ? (
                      renderListV2()
                    ) : (
                      <div className="py-2 px-4">{createView}</div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
