import { useEffect, useState } from "react";
import { TemplateDialog } from "./template-dialog";
import SimplePrompt from "../../utils/simplePrompt";
import { PMButton } from "../../utils/PMButton";
import { set } from "lodash";

export const TemplateDialogButton = (props) => {
  const { setFieldValue, values, fieldName, className, styleType } = props;

  const [dialogOpen, setDialogOpen] = useState(false);

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [promptOpen, setPromptOpen] = useState(false);

  useEffect(() => {
    if (selectedTemplate) {
      if (setFieldValue) {
        if (values[fieldName] && values[fieldName].length > 1) {
          setPromptOpen(true);
        } else {
          setFieldValue(fieldName, selectedTemplate.content);
        }
      }

      if (props.onChange) {
        props.onChange({
          target: {
            name: "template",
            value: selectedTemplate.id,
            data: selectedTemplate,
          },
        });
      }
    }

    // reset template after setFieldValue/ onChange and close dialog
    setSelectedTemplate(null);
    setDialogOpen(false);
  }, [selectedTemplate]);

  // reset template when dialog closes
  // useEffect(() => {
  //   if (!dialogOpen) {
  //     // wait for dialog to close before resetting
  //     setTimeout(() => {
  //       setSelectedTemplate(null);
  //     }, 500);
  //   }
  // }, [dialogOpen]);

  return (
    <>
      <PMButton
        className={className}
        styleType={styleType}
        onClick={() => setDialogOpen(true)}
      >
        Velg mal
      </PMButton>
      <TemplateDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onChange={(template) => setSelectedTemplate(template)}
      />
      <SimplePrompt
        title="Dette overskriver teksten"
        description="Dette kan ikke reverseres"
        open={promptOpen}
        onClose={() => setPromptOpen(false)}
        onAccept={() => {
          setFieldValue(fieldName, selectedTemplate.content);
          setPromptOpen(false);
        }}
      />
    </>
  );
};

TemplateDialogButton.defaultProps = {
  styleType: "minimal",
};
