import { useState } from "react";
import { toast } from "react-hot-toast";
import { ModuleTag } from "./moduleTag";

export const EmployeeTag = ({ employee, onRefresh, label }) => {
  const [dialogOpen, setDialogOpen] = useState();

  const labelAction = {
    path: "/dashboard/company/employees/" + employee?.id,
    // action: () => toast("test"),
  };

  const handleOpenDialog = () => {
    if (employee?.id) {
      setDialogOpen(true);
    } else {
      toast.error("Du må velge ansatt først");
    }
  };
  return (
    <>
      <ModuleTag label={label || "Employee"} labelAction={labelAction}>
        {employee?.displayTitle}
      </ModuleTag>
    </>
  );
};
