import { WrenchIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import ProductCategories from "../../../components/dashboard/products/Categories/Categories";
import ProductsSettingsBrandsStacklist from "../../../components/dashboard/products/settings/brands-stacklist";
import { getProductBrands } from "../../../slices/products/brands";
import { getAgreementTypesAsync } from "../../../slices/service/agreement-types";
import { getReportTypes } from "../../../slices/service/report-types";
import { getVariableTypesAsync } from "../../../slices/service/variable-types";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import { ProductCategoriesListV2 } from "../../../components/dashboard/products/Categories/v2/categoriesListV2";

export const ProductsSettings = (props) => {
  const { ...other } = props;
  const [newServiceReportModalOpen, setNewServiceReportModalOpen] =
    useState(false);
  const dispatch = useDispatch();

  const [controller, setController] = useState({
    filters: [],
    page: 1,
    query: "",
    sort: "desc",
    sortBy: "updated_at",
    view: "all",
  });

  useEffect(() => {
    dispatch(getProductBrands(controller));
  }, [dispatch]);

  const rightSideActions = [
    {
      label: "New Report",
      description: "Create a new Report",
      path: "",
      action: () => setNewServiceReportModalOpen(true),
      icon: WrenchIcon,
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
    },
    {
      label: "New Inventory Count",
      description: "Create a new Inventory Count",
      path: "",
      action: () => toast.error("This function is not available"),
      icon: WrenchIcon,
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
    },
    {
      label: "Counter",
      description: "Count products with the Counter",
      path: "",
      action: () => toast.error("This function is not available"),
      icon: WrenchIcon,
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
    },
  ];

  return (
    <>
      <DashboardHeader
        subtitle=""
        // actions={headerActions}
        title={"Settings"}
        // meta={[{ label: "test", icon: Cog6ToothIcon }]}
        // mainButton={{
        //   label: "New",
        //   action: () => setCaseDialogOpen(true),
        //   path: null,
        //   color: "",
        //   icon: PlusIcon,
        // }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            <div className="grid grid-cols-2 max-sm:grid-cols-1  gap-5">
              <div className="col-span-1">
                <ProductsSettingsBrandsStacklist />
              </div>

              <div className="col-span-1">
                <ProductCategoriesListV2 />
              </div>
            </div>
          </div>
        </div>
      </DashboardHeader>
    </>
  );
};
