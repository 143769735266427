import { QuestionAnswer } from "@mui/icons-material";
import DashboardHeader from "../../../../components/dashboard/dashboardHeader";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";

import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { FormikSaveBar } from "../../../../components/utils/FormikSaveBar";
import { PMField } from "../../../../components/utils/PMField";
import PMSwitch from "../../../../components/utils/PMSwitch";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { FaqCategorySelector } from "../../../../components/utils/moduleSelectors/faqCategorySelector";
import { QuillEditor } from "../../../../components/utils/quill-editor";
import { ResourceLoading } from "../../../../components/utils/ResourceLoading";
import { dispatchWithToast } from "../../../../components/Utils";
import { useDispatch } from "react-redux";
import {
  createResourceHubFaqPostAsync,
  updateResourceHubFaqPostAsync,
} from "../../../../slices/resource-hub/faq/post";
import { fetchResourceHubFaqPost } from "../../../../api/resource-hub/faq/posts";

export const EditFaqPost = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const [post, setPost] = useState(null);

  useEffect(() => {
    // if ID is set, fetch the post
    setTimeout(() => {
      if (id) {
        setIsLoading(true);
        getFaqPost(id);
      } else {
        setIsLoading(false);
      }
    }, 500);
  }, [id]);

  const getFaqPost = (id) => {
    setIsLoading(true);
    try {
      fetchResourceHubFaqPost(id).then((data) => {
        setIsLoading(false);
        setPost(data?.data);
      });
    } catch {
      toast.error("Something went wrong when trying to load the report");

      setIsLoading(false);
      setError(true);
    }
  };

  const headerActions = [
    // {
    //   component: null,
    //   label: "Ny innlegg",
    //   description: "",
    //   path: null,
    //   action: () => toast.success("Hej Robin"),
    //   icon: PlusIcon,
    //   textColor: "text-teal-700",
    //   bgColor: "bg-white",
    //   hidden: false,
    //   menu: true,
    // },
  ];

  const dispatch = useDispatch();

  const createOrUpdateDispatch = (data) => {
    // onEditing(false);
    if (post?.id) {
      const res = dispatch(updateResourceHubFaqPostAsync(data));
      return res;
    } else {
      const res = dispatch(createResourceHubFaqPostAsync(data));
      return res;
    }
  };

  // get userType form searchParams
  const searchParams = new URLSearchParams(location.search);
  const userType = searchParams.get("userType") || "";

  function Content() {
    return (
      <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
          <div className="divide-y divide-gray-200 overflow-hidden rounded-lg sm:gap-px sm:divide-y-0">
            <Formik
              initialValues={{
                id: post?.id,
                title: post?.title || "",
                content: post?.content || "",
                category_id: post?.category?.id || null,
                public: post?.public || false,
                submit: null,
              }}
              validationSchema={Yup.object({
                title: Yup.string().max(255).required(t("Title is Required")),
                description: Yup.string(),
                category_id: Yup.number().required(t("Category is required")),
              })}
              onSubmit={(values, helpers) => {
                try {
                  dispatchWithToast(createOrUpdateDispatch(values), (data) => {
                    setPost(data);
                  });
                  helpers.setStatus({ success: true });
                  helpers.setSubmitting(false);
                } catch (err) {
                  toast.error("Nope");
                  console.error(err);
                  helpers.setStatus({ success: false });
                  helpers.setErrors({ submit: err.message });
                  helpers.setSubmitting(false);
                }
              }}
            >
              {(formik) => (
                <Form>
                  <div className="shadow sm:overflow-hidden sm:rounded-md">
                    <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                      <div className="grid sm:grid-cols-8 gap-6">
                        <div className="sm:col-span-6">
                          {userType !== "employee" && (
                            <PMSwitch
                              label="Public"
                              name="public"
                              {...formik}
                            />
                          )}
                        </div>
                        <div className="sm:col-span-2">
                          <FaqCategorySelector
                            name="category_id"
                            selected={post?.category}
                            userType={userType ? userType : null}
                            {...formik}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
                    <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                      <div className="grid sm:grid-cols-8 gap-6">
                        <div className="sm:col-span-8">
                          <PMField
                            name="title"
                            type="text"
                            label={t("Title")}
                            {...formik}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
                    <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3"></div>

                        <div className="col-span-6">
                          <QuillEditor name="content" rows={20} {...formik} />
                        </div>

                        <div className="col-span-6 sm:col-span-3 lg:col-span-2"></div>
                      </div>
                    </div>
                  </div>
                  <FormikSaveBar {...formik} />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={
          (!post?.id ? "New" : "Editing") +
          " FAQ Post" +
          (userType ? " for " : "") +
          userType.toUpperCase()
        }
      >
        {isLoading ? <ResourceLoading /> : <Content />}
      </DashboardHeader>
    </>
  );
};
