import { Query } from "../../utils/query"

export const CustomersFilter = (props) => {
    const { disabled, onQueryChange, query } = props;

    return (
        <div className="mt-5">
            <Query disabled={disabled} onChange={onQueryChange} value={query} />
        </div>
    )
}