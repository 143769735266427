import { useEffect, useState } from "react";
import ItemSelector from "./ItemSelector";
import { Link } from "react-router-dom";

export const ProductSelector = (props) => {
  const { onChange, setFieldValue } = props;

  const name = props.name || "product_id";
  const label = props.label || "Product";

  const [productSelectorOpen, setProductSelectorOpen] = useState(false);

  const [selected, setSelected] = useState({});

  const product = selected[0];

  const handleProductSelect = (selected) => {
    setSelected(selected);

    if (onChange) {
      onChange(selected[0]);
    }

    if (setFieldValue) {
      setFieldValue(name, selected[0].id);
    }

    setProductSelectorOpen(false);
  };

  useEffect(() => {
    if (props.selected) {
      // add selected to selected as an array
      setSelected([props.selected]);
    }
  }, [props.selected]);

  return (
    <>
      <div
        className="cursor-pointer"
        onClick={() => setProductSelectorOpen(true)}
      >
        {product?.id ? (
          <Link to={"/dashboard/products/" + product?.id}>
            <label
              htmlFor="comments"
              className="block text-sm font-medium text-blue-400 cursor-pointer hover:text-blue-600"
            >
              {label}
            </label>
          </Link>
        ) : (
          <label
            htmlFor="comments"
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
        )}

        {product?.id ? (
          <div className="font-bold p-2 border-b-4 border-indigo-300">
            {product?.displayTitle}
          </div>
        ) : (
          <div className="text-gray-400">Velg {label}</div>
        )}
      </div>

      <ItemSelector
        {...props}
        multiple={false}
        open={productSelectorOpen}
        selectorCloseCallback={() => setProductSelectorOpen(false)}
        selectorType="products"
        module={"product"}
        // module_param={product.id}
        selectedItems={selected}
        onSelect={(data) => handleProductSelect(data)}
        // selectedItemsCallback={handleSelectedItemsCallback}
      />
    </>
  );
};
