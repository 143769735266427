import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export function DashboardRoleRoute({ role, children }) {
  const currentUser = useSelector((state) => state.session.currentUser);
  const navigate = useNavigate();

  const userHasRole = () => {
    if (currentUser.roles.map((r) => r.name).includes("admin")) return true;
    return currentUser.roles.map((r) => r.name).includes(role);
  };

  useEffect(() => {
    if (role && !userHasRole()) {
      navigate("/dashboard");
    }
  }, [currentUser, navigate, role]);

  if (role && !userHasRole()) {
    return null;
  }

  return children;
}
