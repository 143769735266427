import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ProductsFilter } from "../../../../components/dashboard/products/products-filter";

import { useSearchParams } from "react-router-dom";
import DashboardHeader from "../../../../components/dashboard/dashboardHeader";
import {
  ArrowUpCircleIcon,
  CheckBadgeIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { updateUrlWithException } from "../../../../components/Utils";
import { getDeviationsAsync } from "../../../../slices/quality/deviation";
import DeviationNewDialog from "../../../../components/dashboard/quality/deviations/deviationNewDialog";
import { DeviationsTable } from "../../../../components/dashboard/quality/deviations/deviationsTable";
import { deviationStatusOptions } from "../../../../components/dashboard/quality/deviations/utils";
import { fetchUsers } from "../../../../api/user";
import { debounce } from "lodash";

export const DeviationsList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [controller, setController] = useState({
    filters: [{ name: "status", value: JSON.stringify(["1", "2"]) }],
    page: searchParams.get("page") ? searchParams.get("page") : 1,
    per_page: searchParams.get("per_page") ? searchParams.get("per_page") : 50,
    query: searchParams.get("query") ? searchParams.get("query") : "",
    sort: searchParams.get("sort") ? searchParams.get("sort") : "asc",
    sortBy: searchParams.get("sortBy")
      ? searchParams.get("sortBy")
      : "created_at",
    view: searchParams.get("view") ? searchParams.get("view") : "all",
    fields:
      "id,number,displayTitle,title,reference,project,status,deviation_type,priority,severity,folder,assigned_user",
  });

  const deviationState = useSelector((store) => {
    return store.deviations;
  });

  // Update all params from controller except filters (dont't touch filters)
  useEffect(() => {
    updateUrlWithException(
      // These will be updated
      {
        page: controller.page,
        per_page: controller.per_page,
        query: controller.query,
        sort: controller.sort,
        sortBy: controller.sortBy,
        view: controller.view,
      },
      // This will be preserved
      "filters"
    );
  }, [controller]);

  const { isLoading, totalCount, newCount } = deviationState;
  const [selectedItems, setSelectedItems] = useState([]);
  const [userOptions, setUserOptions] = useState({
    isLoading: true,
    error: false,
    errors: [],
    data: [],
  });

  async function fetchAndMapUsers() {
    setUserOptions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    // Fetch Case types from API?
    const controller = {
      filters: [{ name: "role", value: "deviation" }],
      page: 1,
      query: "",
      sort: "desc",
      sortBy: "id",
      view: "all",
    };

    await fetchUsers(controller)
      .then((res) => {
        if (res.data) {
          var array = [];
          if (!res.data) return array;
          res.data.map((row, i) => {
            const rowParams = {
              id: row.id,
              label: row.name,
              value: row.id,
              title: row.name,
              order: i,
            };
            array.push(rowParams);
          });
          setUserOptions((prevState) => ({
            ...prevState,
            isLoading: false,
            data: array,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        setUserOptions((prevState) => ({
          ...prevState,
          isLoading: false,
          error: true,
          errors: errors,
        }));
      });

    // Map into an array with ID and title?

    // Return the array
  }

  useEffect(() => {
    fetchAndMapUsers();
  }, []);

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery,
    });
  };

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleFilterChange = (selectedFilters) => {
    setController({
      ...controller,
      filters: selectedFilters?.map((filter) => {
        return {
          name: filter.id,
          value: JSON.stringify(filter.values),
        };
      }),
    });
  };

  const debouncedDispatchAction = debounce((controller) => {
    dispatch(getDeviationsAsync(controller));
  }, 250);

  useEffect(() => {
    // Trigger the debounced dispatch action
    debouncedDispatchAction(controller);

    // Clean the debounced function on component unmount.
    return () => {
      debouncedDispatchAction.cancel();
    };
  }, [controller, dispatch]);

  const sortByOptions = [
    // { name: "Seneste (Rapportnr.)", sort: "asc", sortBy: "report_number" },
    // { name: "Eldste (Rapportnr.)", sort: "desc", sortBy: "report_number" },
  ];

  const userController = {
    filters: [{ name: "role", value: "deviation" }],
    page: 1,
    query: "",
    sort: "desc",
    sortBy: "id",
    view: "all",
  };

  const initialFilters = [
    {
      id: "assigned_user",
      name: "Assigned user",
      optionsUrl: "/users",
      optionsParams: userController,
    },
    {
      id: "status",
      name: "Status",
      options: deviationStatusOptions,
    },
  ];

  const [deviationNewDialogOpen, setDeviationNewDialogOpen] = useState(false);

  const headerMeta = [
    {
      label: "Total: " + totalCount,
      description: "",
      path: null,
      action: null,
      icon: CheckBadgeIcon,
      textColor: "text-gray-400",
      iconColor: "text-gray-400",
      hidden: false,
    },

    {
      label: "New: " + newCount,
      description: "",
      path: null,
      action: null,
      icon: ArrowUpCircleIcon,
      textColor: "text-gray-400",
      iconColor: "text-green-500",
      hidden: false,
    },
  ];

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={[]}
        title={"Deviations"}
        meta={headerMeta}
        mainButton={{
          label: "New",
          action: () => setDeviationNewDialogOpen(true),
          path: null,
          color: "",
          icon: PlusIcon,
        }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            <ProductsFilter
              className={"mt-10"}
              disabled={isLoading}
              onQueryChange={handleQueryChange}
              query={controller.query}
              selectedItems={selectedItems}
              sortByOptions={sortByOptions}
              initialFilters={initialFilters}
              onFilterChange={handleFilterChange}
              defaultSelectedOptions={[
                { name: "status", value: "1" },
                { name: "status", value: "2" },
              ]}
            />

            <DeviationsTable
              itemState={deviationState}
              itemsCount={deviationState.count}
              currentCount={deviationState.currentCount}
              page={controller.page}
              onPageChange={handlePageChange}
              perPage={controller.per_page}
            />
          </div>
        </div>
      </DashboardHeader>

      <DeviationNewDialog
        open={deviationNewDialogOpen}
        onClose={() => setDeviationNewDialogOpen(false)}
        selected={null}
        // onRefresh={() => getReport()}
      />
    </>
  );
};
