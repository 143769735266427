import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { CustomersFilter } from "../../../components/dashboard/customers/customers-filter"
import { getCountsAsync } from "../../../slices/inventory/count"
import { InventoryCountsTable } from "../../../components/dashboard/inventory/counts/inventory-counts-table"
import { InventoryLayout } from "../../../components/dashboard/inventory/inventory-layout"

export const InventoryCounts = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const itemState = useSelector((store) => {
    return store.inventory_counts
  })

  const { isLoading } = itemState
  const [selectedItems, setSelectedItems] = useState([])
  const [controller, setController] = useState({
    filters: [],
    page: 1,
    query: '',
    sort: 'desc',
    sortBy: 'updated_at',
    view: 'all'
  });

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery
    });
  };

  useEffect(() => {
    const payload = {
      filters: controller.filters,
      page: controller.page,
      query: controller.query,
      sort: controller.sort,
      sortBy: controller.sortBy,
      view: controller.view
    }
    dispatch(getCountsAsync(payload));
  }, [dispatch, controller])


  return (
    <InventoryLayout name="inventory_counts_list">
      <CustomersFilter
        className={"mt-10"}
        disabled={isLoading}
        onQueryChange={handleQueryChange}
        query={controller.query}
        selectedItems={selectedItems}
      />

      <InventoryCountsTable itemState={itemState} />
    </InventoryLayout>
  )
}