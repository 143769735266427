import { Fragment, useEffect, useState } from "react";
import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { fetchStripeSession } from "../../../api/order";
import { useSettings } from "../../../contexts/settings-context";
import { StripePaymentMethod } from "./stripe-payment-method";
import UppercaseFirstLetter from "../../Utils";

export default function StripeDialog(props) {
  const { t } = useTranslation();

  // Deconstructing props
  const { open, onClose, selected, onRefresh } = props;
  const [data, setData] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  // Fetch the order from API
  const getStripeSession = async () => {
    try {
      setIsLoading(true);
      const response = await fetchStripeSession(selected?.id);
      if (response?.session?.id) {
        setIsLoading(false);
        setData(response);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      if (!selected) {
        toast.error(t("stripeDialog.missing.selected.id"));
      } else {
        getStripeSession();
      }
    }
  }, [open]);

  const StripeContent = () => {
    const { priceFormatter } = useSettings();

    return (
      // list of data/ information from stripe session
      <ul role="list" className="-my-5 divide-y divide-gray-200 mt-5">
        <li className="py-5">
          <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
            <h3 className="text-sm font-semibold leading-5 text-gray-800">
              {t("Payment Status")}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {data ? (
                <UppercaseFirstLetter text={t(data?.session?.payment_status)} />
              ) : (
                "Payment status not found"
              )}
            </p>
          </div>
        </li>
        <li className="py-5">
          <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
            <h3 className="text-sm font-semibold leading-5 text-gray-800">
              {t("Payment Method")}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              <StripePaymentMethod payment_method={data?.payment_method} />
            </p>
          </div>
        </li>
        <li className="py-5">
          <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
            <h3 className="text-sm font-semibold leading-5 text-gray-800">
              {data?.payment_status === "paid" ? "Betalt" : "Ordre Totalt"}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {priceFormatter.format(data?.session?.amount_total / 100)}
              {" inkl. mva."}
            </p>
          </div>
        </li>
      </ul>
    );
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="flex items-center text-lg font-medium leading-6 text-gray-900"
                  >
                    Stripe Session
                  </Dialog.Title>

                  {isLoading ? <ResourceLoading /> : <StripeContent />}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

StripeDialog.defaultProps = {
  selected: null,
  // Read only is used when only wanting to display info. Update or creating will not work.
  readOnly: false,
  open: false,
  onRefresh: () => null,
  onClose: () => toast.error("Missing onClose function. Cannot close dialog"),
};
