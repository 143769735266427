import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getCustomers } from "../../../slices/customer"
import { CustomersStats } from "../../../components/dashboard/customers/customers-stats"
import { CustomersTable } from "../../../components/dashboard/customers/customers-table"
import { CustomersFilter } from "../../../components/dashboard/customers/customers-filter"
import { InventoryStats } from "../../../components/dashboard/inventory/inventory-stats"
import PMStacklist from "../../../components/dashboard/inventory/inventory-storage-stacklist"
import { getStorages } from "../../../slices/inventory/storage"
import InventoryStorageStacklist from "../../../components/dashboard/inventory/inventory-storage-stacklist"
import PMLinkCard from "../../../components/utils/PMLinkCard"
import { toast } from "react-hot-toast"
import { Cog6ToothIcon, CalculatorIcon } from "@heroicons/react/24/outline"
import InventoryStorageModal from "../../../components/dashboard/inventory/InventoryStorageModal"
import { StandardLayout } from "../../../components/dashboard/standard-layout"
import { InventoryLayout } from "../../../components/dashboard/inventory/inventory-layout"

export const InventoryList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const itemState = useSelector((store) => {
    return store.inventory_storages
  })

  const { isLoading, error, errors, data } = itemState
  const [newStorageModalOpen, setNewStorageModalOpen] = useState(false);
  const rightSideActions = [
    {
      label: 'New Storage',
      description: 'Create a new Inventory Storage',
      path: '',
      action: () => setNewStorageModalOpen(true),
      icon: Cog6ToothIcon,
      iconForeground: 'text-teal-700',
      iconBackground: 'bg-teal-50',
    },
    {
      label: 'New Inventory Count',
      description: 'Create a new Inventory Count',
      path: '',
      action: () => toast.error('This function is not available'),
      icon: Cog6ToothIcon,
      iconForeground: 'text-teal-700',
      iconBackground: 'bg-teal-50',
    },
    {
      label: 'Counter',
      description: 'Count products with the Counter',
      path: '',
      action: () => toast.error('This function is not available'),
      icon: CalculatorIcon,
      iconForeground: 'text-teal-700',
      iconBackground: 'bg-teal-50',
    }
  ]

  useEffect(() => {
    dispatch(getStorages());
  }, [dispatch])


  return (
    <InventoryLayout name="inventory_overview">
      <div className="grid grid-cols-1 gap-5">
        <div className="grid grid-cols-1 max-sm:hidden">
          <InventoryStats />
        </div>
        <div className="grid grid-cols-2 max-sm:grid-cols-1  gap-5">
          <div className="grid-cols-1">
            <PMLinkCard actions={rightSideActions} />
          </div>
          <div className="grid-cols-1">
            <InventoryStorageStacklist
              label="Storages"
              isLoading={isLoading}
              error={error}
              errors={errors}
              items={data}
            />
          </div>
        </div>
      </div>

      <InventoryStorageModal onClose={() => setNewStorageModalOpen(false)} open={newStorageModalOpen} itemId={0} />
    </InventoryLayout>
  )
}
