import axios from "../../api/axios";
import { useDispatch, useSelector } from "react-redux";
import { logoutUserWithoutToken } from "../../slices/session";
import {
  getAccessToken,
  getRefreshToken,
  refreshAccessToken,
} from "../../slices/session";

const SessionExpiredModal = () => {
  const dispatch = useDispatch();
  const refreshToken = getRefreshToken();
  const accessToken = getAccessToken();

  const handleLogout = () => {
    if (accessToken) {
      dispatch(logoutUserWithoutToken());
    } else {
      dispatch(logoutUserWithoutToken());
    }
  };

  const handleOk = () => {
    handleLogout();
    window.location.href = "/authentication/login"; // Redirect to login page

    const modal = document.getElementById("session-expired-modal");
    modal.parentNode.removeChild(modal); // Remove modal from DOM
  };

  const handleCancel = () => {
    // Do nothing, keep user logged in
    // dispatch(refreshAccessToken(refreshToken));
    const modal = document.getElementById("session-expired-modal");
    modal.parentNode.removeChild(modal); // Remove modal from DOM
  };

  axios.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),
    (error) => {
      console.log(error);

      if (
        error.response.status === 401 &&
        window.location.pathname !== "/authentication/login"
      ) {
        const modal = document.createElement("div");
        modal.id = "session-expired-modal";
        modal.classList.add(
          "fixed",
          "inset-0",
          "z-50",
          "flex",
          "items-center",
          "justify-center"
        );
        modal.innerHTML = `
          <div class="fixed inset-0 bg-gray-900 opacity-75"></div>
          <div class="bg-white rounded-lg shadow-lg z-10">
            <div class="p-4 text-center">
              <h2 class="text-lg font-medium mb-2">Session Expired</h2>
              <p class="mb-4">Your session has expired. Please login again.</p>
              <div class="flex justify-center">
                <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2" id="logoutBtn">Login</button>
                <button class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded" id="cancelBtn">Cancel</button>
              </div>
            </div>
          </div>
        `;
        document.body.appendChild(modal);
        const logoutBtn = modal.querySelector("#logoutBtn");
        const cancelBtn = modal.querySelector("#cancelBtn");
        logoutBtn.addEventListener("click", handleOk);
        cancelBtn.addEventListener("click", handleCancel);
      }

      if (error?.response) {
        return new Promise((resolve, reject) => {
          resolve(error?.response);
        });
      }

      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    }
  );

  return null;
};

export default SessionExpiredModal;
