import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchActivityItems } from "../api/activity";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  totalCount: 0,
  currentCount: 0,
  newCount: 0,
};

export const initialController = {
  filters: [],
  page: 0,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const getActivityItemsAsync = createAsyncThunk(
  "activity_items/getActivityItemsAsync",
  async (controller = initialController, { rejectWithValue }) => {
    let response = await fetchActivityItems(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

const activityItemSlice = createSlice({
  name: "activity_items",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActivityItemsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getActivityItemsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.currentCount = action.payload.currentCount;
        state.newestActivitysCount = action.payload.newCount;
      })
      .addCase(getActivityItemsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch activity items....is controller missing?",
        ];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = activityItemSlice;

export default activityItemSlice;
