import { useTranslation } from "react-i18next";
import { PMSimpleSelectorField } from "../PMSimpleSelector/PMSimpleSelectorField";
import { PMSimpleSelectorFieldV2 } from "../PMSimpleSelector/PMSimpleSelectorFieldV2";
import axios from "../../../api/axios";
import { useState, useEffect } from "react";
import { mapErrors } from "../../Utils";
import { Form, Formik } from "formik";
import { ResourceLoading } from "../ResourceLoading";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { PMField } from "../PMField";
import { debounce } from "lodash";

export const PaymentTypeSelector = (props) => {
  const {
    setFieldValue,
    values,
    onChange,
    errors,
    module,
    module_param,
    fields,
  } = props;

  const name = props.name ? props.name : "payment_type_id";
  const label = props.label ? props.label : "";
  const FETCH_URL = "/company/settings/payment_types/";
  const FETCH_ON_SELECT = false;
  const CREATE_URL = "/company/settings/payment_types/";
  // const filters = [{ name: module, value: module_param }];
  const filters = [];

  const { t } = useTranslation();

  const [query, setQuery] = useState();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({
    id: 0,
    field1: "Ingen valgt",
    field2: null,
  });
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);

  const labelAction = {
    // action: () => toast("test"),
  };

  const handleChange = (data) => {
    if (FETCH_ON_SELECT) {
      getItem(data.selected.item.id);
    } else {
      setSelected({
        id: data.selected.item.id,
        field1: data.selected.item.primary,
      });
    }
    setOpen(false);
    if (values) {
      setFieldValue(name, data.selected.item.id);
    } else {
      onChange({ target: { name: name, value: data.selected.item.id } });
    }
  };

  const getItem = (id) => {
    return axios({ method: "get", url: FETCH_URL + id })
      .then((res) => {
        if (res.data.id) {
          setLoading(false);
          setSelected({
            id: res.data?.id,
            field1: res.data.displayTitle,
          });
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err.data.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setLoading(false);
      });
  };

  const getItems = () => {
    setLoading(true);
    const params = {
      query: query ? query : "",
      page: 1,
      sortBy: "created_at",
      sort: "desc",
      filters: filters,
      view: null,
    };

    return axios({ method: "get", url: FETCH_URL, params: params })
      .then((response) => {
        if (response.data.data) {
          var array = [
            { id: null, value: null, primary: "Ingen valgt", secondary: null },
          ];
          if (!response.data.data) return array;
          response.data.data.map((row, i) => {
            const rowParams = {
              value: row.id,
              id: row.id,
              primary: row.displayTitle,
            };
            array.push(rowParams);
          });
          setItems(array);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        return error.response.data;
      });
  };

  const debouncedAction = debounce((controller) => {
    getItems();
  }, 500);

  useEffect(() => {
    if (open) {
      debouncedAction();
    }

    // Clean the debounced function on component unmount.
    return () => {
      debouncedAction.cancel();
    };
  }, [query, open]);

  // useEffect(() => {
  //   if (values[name]) {
  //     getItem(values[name]);
  //   }
  // }, [items]);

  useEffect(() => {
    if (props.selected) {
      setSelected({
        id: props.selected?.id,
        field1: props.selected.displayTitle,
      });
    }
  }, [props.selected]);

  return (
    <>
      <PMSimpleSelectorFieldV2
        errors={errors}
        name={name}
        label={t(label)}
        labelAction={labelAction}
        loading={loading}
        options={items}
        selected={selected}
        onChange={handleChange}
        onQueryChange={(query) => setQuery(query)}
        onClose={() => setOpen(!open)}
        open={open}
        fields={[
          {
            name: "name",
            label: "Name",
            type: "text",
            required: true,
            disabled: true,
          },
        ]}
      />
    </>
  );
};
