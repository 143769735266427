import { useSelector } from "react-redux";
import { SimpleHourForm } from "../../../hours/simple-hour-form";
import { SimpleHourList } from "../../../hours/simple-hour-list";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getHourAsync, getHoursAsync } from "../../../../../slices/hour";
import { toast } from "react-hot-toast";

export const ReportHoursForm = ({ report, formik }) => {
  const filters = [{ name: "REPORT", value: report?.id }];

  const [loading, setLoading] = useState(false);

  const [controller, setController] = useState({
    query: "",
    page: 1,
    per_page: 10,
    sortBy: "created_at",
    sort: "desc",
    filters: filters,
    view: null,
  });

  const itemState = useSelector((store) => {
    return store.hours;
  });

  // Update loading if itemState.isLoading is changing state
  useEffect(() => {
    if (itemState.isLoading !== loading) {
      setLoading(itemState?.isLoading);
    }
  }, [itemState.isLoading]);

  const dispatch = useDispatch();

  const getItems = () => {
    setLoading(true);
    dispatch(getHoursAsync(controller));
    setLoading(false);
  };

  useEffect(() => {
    if (report) {
      getItems();
    }
  }, [controller]);

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const { data } = itemState;

  const [selectedItem, setSelectedItem] = useState(null);

  const handleSelect = (item) => {
    setSelectedItem(item);
  };

  return (
    <div>
      <div>
        <SimpleHourList
          hours={data}
          loading={loading}
          onPageChange={handlePageChange}
          page={controller?.page}
          totalItemsCount={itemState?.totalCount}
          onRefresh={() => getItems()}
          onItemSelect={handleSelect}
        />
      </div>
      <div>
        <SimpleHourForm
          formik={formik}
          project={report?.project}
          report={report}
          selected={selectedItem}
        />
      </div>
    </div>
  );
};
