import { TWclassNames } from "../../../utils/Div";

export function DriverStatus({ driver }) {
  const { user } = driver;

  return (
    <span
      className={TWclassNames(
        !user?.id ? "bg-red-200" : "bg-green-400 text-white",
        "inline-flex rounded-full px-2 text-xs font-semibold leading-5"
      )}
    >
      {user?.id ? "Aktiv" : "Bruker ikke tilknyttet sjåfør"}
    </span>
  );
}
