import axios from "../axios";

export async function fetchServiceAgreements(controller) {
  return axios({
    method: "get",
    url: "/service/agreements",
    params: controller,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchServiceAgreement(id) {
  return axios.get(`/service/agreements/` + id);
}

export async function createServiceAgreement(payload) {
  return axios
    .post(`/service/agreements`, { agreement: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
export async function updateServiceAgreement(payload) {
  return axios
    .put(`/service/agreements/${payload.id}`, { agreement: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function createReportServiceAgreement(payload) {
  return axios
    .post(`/service/agreements/${payload.id}/report`, { agreement: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function createOrderServiceAgreement(payload) {
  return axios
    .post(`/service/agreements/${payload.id}/order`, { agreement: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function getPdfServiceAgreement(payload) {
  return axios.get(`/service/agreements/${payload.id}/pdf`, {
    params: payload.params,
    // responseType: "blob",
    type: "application/pdf",
  });
}

export async function sendServiceAgreement(payload) {
  return axios.put(`/service/agreements/${payload.id}/send`, {
    agreement: payload,
  });
}

export async function deleteServiceAgreement(payload) {
  return axios
    .delete(`/service/agreements/${payload.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function reactivateServiceAgreement(payload) {
  return axios
    .put(`/service/agreements/${payload.id}/reactivate`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function listUpdateServiceAgreement(payload) {
  return axios
    .put(`/service/agreements/list`, { ids: payload.ids, agreement: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // return error.response.data
      if (error.status !== 200) return error;
      else return error.response;
    });
}
