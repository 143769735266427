import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { PMPagination } from "../../../utils/PMPagination";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { TWclassNames } from "../../../utils/Div";
import {
  CustomerTitle,
  EmployeeTitle,
  LoadingRows,
  ProjectTitle,
} from "../../../Utils";
import { DeviationPriority, DeviationSeverity, DeviationStatus } from "./utils";
import { ResourceEmpty } from "../../../utils/ResourceEmpty";

export const DeviationsTable = (props) => {
  const { itemState, onPageChange, itemsCount, currentCount, page, perPage } =
    props;
  const { data, isLoading, errors } = itemState;
  const deviations = data;
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedReports, setSelectedReports] = useState([]);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedReports.length > 0 && selectedReports.length < deviations.length;
    setChecked(selectedReports.length === deviations.length);
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedReports]);

  function toggleAll() {
    setSelectedReports(checked || indeterminate ? [] : deviations);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const navigate = useNavigate();

  const renderRows = () => {
    return deviations?.map((deviation) => (
      <tr
        key={deviation?.id}
        className={TWclassNames(
          selectedReports.includes(deviation) ? "bg-gray-50" : null,
          "hover:bg-gray-50"
        )}
      >
        <td className="relative w-12 px-6 sm:w-16 sm:px-8 hidden md:table-cell">
          {selectedReports.includes(deviation) && (
            <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
          )}
          <input
            type="checkbox"
            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
            value={data.id}
            checked={selectedReports.includes(deviation)}
            onChange={(e) =>
              setSelectedReports(
                e.target.checked
                  ? [...selectedReports, deviation]
                  : selectedReports.filter((p) => p !== deviation)
              )
            }
          />
        </td>
        <td
          onClick={() =>
            navigate("/dashboard/quality/deviations/" + deviation.id)
          }
          className={TWclassNames(
            "whitespace-nowrap py-4 pr-3 px-10 sm:px-0 text-sm font-medium",
            selectedReports.includes(deviation)
              ? "text-indigo-600"
              : "text-gray-900"
          )}
        >
          <div className="flex items-center">
            <div className="font-medium text-gray-900">
              <div className="flex flex-col">
                <div>{deviation?.number}</div>
                <div>{deviation?.deviation_type?.displayTitle}</div>
                <div className="text-gray-500">{deviation?.title}</div>
                <div className="visible md:invisible">
                  <ProjectTitle
                    project={deviation?.project}
                    folder={deviation?.folder}
                  />
                </div>
              </div>
            </div>
          </div>
        </td>
        <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <div className="flex flex-col">
            <div>
              <ProjectTitle
                project={deviation?.project}
                folder={deviation?.folder}
              />
            </div>
            <div>
              <CustomerTitle customer={deviation?.customer} />
            </div>
          </div>
        </td>

        <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <EmployeeTitle employee={deviation?.assigned_user} />
        </td>

        <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
          <div className="flex flex-col">
            <div className="mt-2">
              <DeviationSeverity severity={deviation?.severity} />
            </div>
            <div className="mt-2">
              <DeviationPriority priority={deviation?.priority} />
            </div>
            <div className="mt-2">
              <DeviationStatus deviation={deviation} />
            </div>
          </div>
        </td>
      </tr>
    ));
  };

  return (
    <div>
      <PMPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        perPage={perPage}
        rowsCount={itemsCount}
        totalCount={itemsCount}
        currentCount={currentCount}
        showCount={false}
        showPages={false}
      />
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="hidden md:table-cell">
                      <input
                        type="checkbox"
                        className="left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th
                      scope="col"
                      className=" px-10 sm:px-0 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex flex-col">
                        <div>Number</div>
                        <div>Type</div>
                      </div>
                    </th>

                    <th
                      scope="col"
                      className="hidden md:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex flex-col">
                        <div>Project</div>
                        <div>Customer</div>
                      </div>
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Utførende
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {isLoading ? (
                    <>
                      <LoadingRows cols={4} checkboxCell={true} />
                      <LoadingRows cols={4} checkboxCell={true} />
                      <LoadingRows cols={4} checkboxCell={true} />
                      <LoadingRows cols={4} checkboxCell={true} />
                      <LoadingRows cols={4} checkboxCell={true} />
                      <LoadingRows cols={4} checkboxCell={true} />
                      <LoadingRows cols={4} checkboxCell={true} />
                      <LoadingRows cols={4} checkboxCell={true} />
                      <LoadingRows cols={4} checkboxCell={true} />
                      <LoadingRows cols={4} checkboxCell={true} />
                    </>
                  ) : data.length < 1 ? (
                    <tr>
                      <td colSpan={5} className="py-2 px-2">
                        <ResourceEmpty />
                      </td>
                    </tr>
                  ) : (
                    renderRows()
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <PMPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        perPage={perPage}
        rowsCount={itemsCount}
        totalCount={itemsCount}
        currentCount={currentCount}
        showCount={true}
      />
    </div>
  );
};

DeviationsTable.defaultProps = {
  page: 1,
  itemState: [],
  itemsCount: 0,
  selectedItems: [],
  sort: "desc",
  sortBy: "createdAt",
};
