import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import { Link } from "react-router-dom";

export const InvoiceStatus = ({ order }) => {
  const { invoice } = order;
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      {invoice && invoice?.id ? (
        <Link
          onClick={() => setDialogOpen(true)}
          className="flex rounded-md py-2 cursor-pointer text-green-500"
        >
          <CheckBadgeIcon className="h-5 w-5" />
          <span className="px-1">{invoice.displayTitle}</span>
        </Link>
      ) : (
        <div className="flex rounded-md py-2">
          <ExclamationCircleIcon className="h-5 w-5" />
          <span className="px-1">Ikke bokført</span>
        </div>
      )}
    </>
  );
};
