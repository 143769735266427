export const Table = (props) => {
  const { children, actions, ...other } = props;

  const selectedItems = null;

  return (
    <div className="px-6 sm:px-6 lg:px-0">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {selectedItems && selectedItems?.length > 0 && (
                <div className="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16">
                  {actions &&
                    actions?.map((action, index) => (
                      <button
                        key={index}
                        type="button"
                        className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                      >
                        {action.label}
                      </button>
                    ))}
                </div>
              )}
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                {children}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
