import axios from "../../axios";

export async function fetchResourceHubFaqPost(id) {
  return axios.get(`/resource_hub/faq/posts/` + id);
}

export async function fetchResourceHubFaqPosts(controller) {
  return axios({
    method: "get",
    url: "/resource_hub/faq/posts/",
    params: controller,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function createResourceHubFaqPost(payload) {
  return axios
    .post(`/resource_hub/faq/posts/`, { post: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return {};
    });
}
export async function updateResourceHubFaqPost(payload) {
  return axios
    .put(`/resource_hub/faq/posts/${payload.id}`, { post: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function destroyResourceHubFaqPost(payload) {
  return axios
    .delete(`/resource_hub/faq/posts/${payload.id}`)
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
}
