import React, { component } from 'react'

export const NotFound = () => {
    return (
        <div>
            <h1>Page Not Found</h1>
            <p>
                The page you are looking for could not be found.
            </p>
        </div>
    )
}