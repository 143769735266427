import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { useSettings } from "../../../contexts/settings-context";
import { use } from "i18next";

export default function DashboardGreeting(props) {
  const { t } = useTranslation();

  const currentUser = useSelector((state) => state.session.currentUser);

  const { settings } = useSettings();

  const { language } = settings;

  const greetingMessages = [
    {
      message: "God dag",
    },
    {
      message: "God morgen",
    },
    {
      message: "God ettermiddag",
    },
    {
      message: "God kveld",
    },
  ];

  const greetingJokesNO = [
    "Der F5 faktisk gjør en forskjell!",
    "Vennligst la fornuften din ved døren.",
    "Oppgavene dine kan vente litt til.",
    "Pikslene er omorganisert for din visningsglede.",
    "Den virtuelle kaffen står på venstre side.",
    "Vi lover å ikke Rickrolle deg... kanskje.",
    "Nettstedet vårt er som en eske med sjokolade, du vet aldri hva du finner.",
    "Nettstedet vårt er 90 % JavaScript og 10 % interne vitser.",
    "Husk, kaken er ikke en løgn... vi spiste den.",
    "Vennligst unnskyld rotet, vi omorganiserer bare biter og byte.",
    "Den virkelige festen skjer i serverrommet.",
    "Nettstedet vårt er glutenfritt, melkefritt og helt feilfritt. Bare tuller.",
    "Vi bruker informasjonskapsler for å forbedre opplevelsen din og for å drive nattkodingen vår.",
    "Dette nettstedet er som et velg-din-egen-eventyrspill, men med flere feil.",
    "Vi lover at våre AI-overherrer er vennlige... for nå.",
    "Ingen behov for å banke, vi vet at du er her.",
    "Dette nettstedet blir bevoktet av virtuelle gummigriser. Fortsett forsiktig.",
    "Advarsel: Puns under arbeid. Trå varsomt.",
    "Spenn deg fast! Dette nettstedet er på en berg-og-dal-bane av enere og nuller.",
    "Ikke bekymre deg, nettstedet vårt vil ikke dømme nettleservalget ditt.",
    "Velkommen til kaosets høyborg!",
    "La oss kode som det er 1999!",
    "Hvem trenger søvn når du har kode som ikke vil samarbeide?",
    "Klapp deg selv på skulderen for å finne den skjulte lenken!",
    "Får du en 404-følelse? Velkommen til klubben!",
    "Dette nettstedet er sikrere enn å bruke 'password' som passord.",
    "Advarsel: Denne siden inneholder humor. Du er herved advart.",
    "Velkommen til det digitale villvestvesten!",
    "Vår kode er som et trylleshow – noen ganger fungerer det!",
    "Vi har reservert den beste feilen til deg!",
    "Du er nå offisielt en del av vår digitale sirkusfamilie!",
    "Bare snikksnakk mellom kodene, ingenting å se her!",
    "Velkommen til hovedkvarteret for nuller og ettall!",
    "Advarsel: Kreativ tenkning kan føre til innovasjon her!",
    "Glem virkeligheten og bli med på en reise gjennom kodelandet!",
    "Ingenting å frykte, vi har en nødutgang ... et sted.",
    "Ikke rist for mye på musepekeren, den blir svimmel.",
    "Ikke vær redd, nettstedet biter ikke... ofte.",
    "Dette nettstedet er som en boks med overraskelser, bare uten bånd.",
    "Nettleseren din er på rett sted, selv om du kanskje ikke er helt sikker.",
    "Vårt digitale fristed har brannslukningsapparater.",
    "Før du spør, nei, vi har ikke sett skrujernet ditt her.",
    "Våre 404-sider har faktisk ganske bra selvtillit.",
    "Vi kan ikke love null bugs, men vi prøver i hvert fall å få dem til å danse.",
    "Hvis du tror dette er et vanlig nettsted, tenk igjen!",
    "Hvis internett var en fornøyelsespark, ville dette være berg-og-dal-banen.",
    "Vår kode er som en tryllekunstners triks, du vet aldri hva som skjer.",
    "Vennligst la fornuften din ved døren, den har ingen nytte her.",
    "Dette nettstedet er som en boks med sjokolader, du vet aldri hva du får.",
    "Våre servere er som små hamstere, alltid i bevegelse.",
    "Ta en brikke av vår digitale festkake.",
    "Vårt bidrag til å gjøre internett litt rarere.",
    "Vårt digitale reir har kanskje rare egg.",
    "Våre kodene leker gjemsel, bare ikke si ifra dem hvor du er.",
    "Hvis du liker rare vitser og rare funksjoner, er du på rett sted.",
    "Hvis du føler deg tapt, ikke bekymre deg, vi har også ingen anelse.",
    "Hvis nettstedet vårt var en vits, ville den inneholdt minst ett 'ping'.",
    "Vårt virtuelle lekeområde, der kodene kan ha det gøy.",
    "Ventilasjonen vår suger... bokstavelig talt. Vi jobber med det.",
    "Hvis du tror du er i en Disney-film, kan vi bekrefte at du ikke er.",
    "Vi lover å ikke bruke dine personlige data til å sende memes.",
    "Serverne våre prøver å synge, men det høres bare ut som bråk.",
    "Hvis du vil logge ut, finner du 'exit' til venstre.",
    "Ikke bli overrasket hvis musepekeren vår også har en egen agenda.",
    "Ikke bli forvirret hvis serverne våre tar en siesta.",
    "Hvis du vil finne meningen med livet, anbefaler vi å søke på Google.",
    "Ikke bekymre deg, nettstedet vårt er allergivennlig, uten spor av nøtter.",
    "Hvis du tror vi har all svarene, har du tydeligvis ikke sett koden vår.",
    "Vårt digitale cirkus – hvor kodene prøver å jonglere med nuller og ettall.",
    "Hvis kaffen din er kald, er det sannsynligvis fordi serveren slurpet den i seg.",
    "Våre vitser kan få 'null pointer'-unntak, men vi gir ikke opp.",
    "Musepekeren vår har noen rare bevegelser, den danser til sin egen melodi.",
    "Hvis du vil finne meningen med koden, kan du lete i kildekoden.",
    "Ikke bekymre deg, det er bare data som flyter, ikke fisk.",
    "Hvis du har kommet hit for å finne meningen med livet, lykke til.",
    "Ikke bli overrasket hvis musepekeren vår også prøver å peke deg i riktig retning.",
    "Våre servere har brukt hele natten på å feire med kodeknall og fargesprak.",
    "Hvis nettleseren din blir trøtt, kan du alltids lese deg tilbake til søvn.",
    "Vår kode er som et trylleshow - noen ganger magisk, andre ganger kaotisk.",
    "Ikke bli forvirret hvis du ser en 404-feil – den bare tester selvtilliten din.",
    "Hvis du lurer på hva som er høna og hva som er egget, har du allerede feilet.",
    "Vår digitale jungel er full av rare skapninger og enda rarere koder.",
    "Hvis du føler deg som en firkant i en rund verden, er dette ditt hjem.",
    "Ikke bli overrasket hvis dataene dine forsvinner i et svart hull. Velkommen til nettet.",
    "Hvis du har kommet hit for å finne meningen med livet, kan du like gjerne ta en kopp te.",
    "Hvis du vil finne skattekartet, har vi et i Excel, men pass på formateringen.",
    "Ikke bli overrasket hvis tastaturet vårt også har en egen vilje.",
    "Vår digitale eventyrpark er åpen for alle som tør å klikke seg videre.",
    "Ikke forvent å finne svaret på livets spørsmål her. Du er fremdeles på nettet.",
    "Ikke bekymre deg, nettstedet vårt er glutenfritt, fordi vi ikke bruker ekte brød.",
    "Hvis du lurer på hva som er vårt beste triks, er det å få koden til å virke.",
    "Velkommen til den digitale jungelen! Vær oppmerksom på bannanfluer.",
    "Hvis du hører rare lyder, ikke bekymre deg, det er bare kodene som feirer.",
    "Velkommen til vår digitale lekeplass! Ikke bli overrasket hvis du finner noen rare egg.",
    "Livet er en dans på roser... for de som elsker å tråkke på torner.",
    "Det er utrolig imponerende hvordan du klarer å balansere mellom å ikke gjøre noe og å virkelig ikke gjøre noe.",
    "Å være lat er en kunstform, og du er virkelig en mester.",
    "Det er utrolig hvordan du alltid finner den perfekte balansen mellom arbeid og å unngå arbeid.",
    "Latskap er som en usynlig superkraft - og du er superhelten.",
    "De sier at de beste tingene i livet er gratis. De har tydeligvis aldri kjøpt kaffe på Starbucks.",
    "Å arbeide smart er overvurdert. Å ikke arbeide i det hele tatt er virkelig en prestasjon.",
    "Så du sier at du har lav arbeidsmoral? Gratulerer, du har funnet en direkte vei til middelmådighet!",
    "For hver minutt du ikke arbeider, blir noen andre minutter mer frustrert over å dekke for deg.",
    "Jeg er imponert over hvordan du konsekvent setter lave forventninger og likevel klarer å underprestere.",
    "Latskap er bare en avansert form for tidsstyring, ikke sant?",
    "Er det ikke herlig å se hvordan andre arbeider mens du utforsker de dypere spørsmålene i Instagram-feeden din?",
    "Å vente til siste øyeblikk viser virkelig din evne til å skape spenning i hverdagen.",
    "Har noen noen gang sagt at du er som en levende reklame for arbeidsinspirasjon? Nei? Ikke overraskende.",
    "Livet er som en buffet av muligheter, og du tar virkelig for deg - av sofaen.",
    "Du er som en solstråle på kontoret. Så sjelden at folk tror du bare er en myte.",
    "Jeg hørte at latskap kan være smittsomt. Takk for at du holder oss friske!",
    "Hvem trenger rikdom og suksess når man kan ha... null innsats?",
    "Ditt forhold til arbeid er virkelig inspirerende... for alle andre som heller ikke vil jobbe.",
    "Jeg skulle ønske jeg også kunne leve etter prinsippet 'Hvorfor gjøre i dag det som kan utsettes til i morgen?'",
    "De sier at tålmodighet er en dyd. Men har noen sagt at utålmodighet er en kunstform?",
    "Å være produktiv er overvurdert. Tenk på all energien du sparer ved å unngå fremskritt!",
    "Det er beundringsverdig hvordan du stadig kjemper for å oppnå det absolutt minste mulige.",
    "Jeg pleide å tenke at arbeid er meningsfylt, helt til jeg møtte deg og skjønte at det er helt valgfritt.",
    "Ditt arbeid er som en solnedgang - vakkert å se på, men forsvinner raskt.",
    "Hvis latskap var en olympisk gren, ville du vært vår stolteste gullmedaljevinner.",
    "Jeg har hørt at hjernen er som en muskel. Du må virkelig gi hjernen din mye hvile og avslapning.",
    "Det sies at geni er 1% inspirasjon og 99% sult. Du har virkelig mestret de prosentene.",
    "Er det ikke imponerende hvordan du balanserer på grensen mellom avslapning og koma?",
    "Å være lat krever en imponerende mengde mental gymnastikk. Du er som en olympisk gullmedaljevinner i det.",
    "De sier at man skal følge drømmene sine. Du følger definitivt søvnen som om du var en drømmefølger.",
    "Hva er meningen med livet? Ifølge deg, å minimere alt som ligner på arbeid.",
    "Å være ambisiøs er overvurdert. Å være likegyldig er den virkelige trenden.",
    "Det er de som går den ekstra milen. Så er det deg som ikke engang kommer deg ut av sengen.",
    "Jeg kan virkelig føle din tilstedeværelse... eller rettere sagt fraværet ditt på arbeidsplassen.",
    "Det er fantastisk hvordan du alltid følger prinsippet om å 'gjøre mindre' for å 'oppnå mer'.",
    "For hver arbeidsoppgave du unngår, vokser en enhjørning et sted i verden.",
    "Jeg pleide å tro at tid er penger, helt til jeg møtte deg og skjønte at tid bare er... tid.",
    "Å være treg om morgenen er en kunst som du behersker til perfeksjon.",
    "Din dedikasjon til å ikke anstrenge deg er virkelig inspirerende... hvis man ser det fra riktig perspektiv.",
    "Hvis verden var en sofa, ville du være dens mest hengivne beboer.",
    "For hver gang du unngår arbeid, får noen andre en uventet mulighet til å skinne.",
    "Er det ikke fantastisk hvordan du alltid finner tid til å unngå arbeid, uansett omstendighetene?",
    "Det er som om du har funnet den hemmelige formelen for å konstant leve livet i parkeringsmodus.",
    "Du er som en solstråle på en regnfull dag - veldig sjelden og mest sannsynlig bare en myte.",
    "Livet er som en sirkusforestilling, og du er definitivt mesteren i å balansere på tauet mellom inaktivitet og... ja, mer inaktivitet.",
    "Jeg er imponert over hvordan du kan nyte å ikke gjøre absolutt noe med så mye lidenskap og hengivenhet.",
    "Det sies at latekatter vil arve jorden. Du vil trolig være for opptatt med å sove for å merke det.",
    "Å være lat er som å være en pioner innenfor kunsten å ufrivillig hvile.",
    "Du er som en guru for å maksimere fritid og minimere prestasjon.",
    "Det er som om du er ekspert på å finne den enkleste måten å ikke gjøre noe på.",
    "For hver ide du har om å gjøre noe produktivt, er det noen som tenker på en bedre ide: å ikke gjøre det i det hele tatt.",
  ];

  const greetingJokesSE = [
    "Där F5 faktiskt gör en skillnad!",
    "Lämna din vettighet vid dörren, tack.",
    "Dina uppgifter kan vänta en stund till.",
    "Pixlarna har omorganiserats för din visningsglädje.",
    "Den virtuella kaffekoppen står till vänster.",
    "Vi lovar att inte Rickrolla dig... kanske.",
    "Vår webbplats är som en chokladask, du vet aldrig vad du hittar.",
    "Vår webbplats är 90 % JavaScript och 10 % interna skämt.",
    "Kom ihåg, tårtan är ingen lögn... vi åt upp den.",
    "Ursäkta röran, vi omorganiserar bara bitar och byte.",
    "Den riktiga festen pågår i serverrummet.",
    "Vår webbplats är glutenfri, mjölkfri och helt buggfri. Skämt åsido.",
    "Vi använder kakor för att förbättra din upplevelse och driva vår nattkodning.",
    "Den här webbplatsen är som ett välj-ditt-egen-äventyrsspel, fast med fler buggar.",
    "Vi lovar att våra AI-överherrar är vänliga... för nu.",
    "Ingen anledning att knacka, vi vet att du är här.",
    "Den här webbplatsen bevaktas av virtuella gummiankor. Fortsätt försiktigt.",
    "Varning: Puns pågår. Träd varsamt.",
    "Sätt på säkerhetsbältet! Den här webbplatsen åker berg-och-dal-bana med ettor och nollor.",
    "Oroa dig inte, vår webbplats kommer inte döma ditt webbläsarval.",
    "Välkommen till kaosets högborg!",
    "Låt oss koda som om det vore 1999!",
    "Vem behöver sömn när man har kod som inte vill samarbeta?",
    "Klappa dig själv på axeln för att hitta den dolda länken!",
    "Känner du dig 404? Välkommen till klubben!",
    "Den här webbplatsen är säkrare än att använda 'password' som lösenord.",
    "Varning: Denna sida innehåller humor. Du är härmed varnad.",
    "Välkommen till det digitala vilda västern!",
    "Vår kod är som en magishow – ibland fungerar den!",
    "Vi har sparat den bästa buggen åt dig!",
    "Du är nu officiellt en del av vår digitala cirkusfamilj!",
    "Bara småprat mellan koderna, inget att se här!",
    "Välkommen till huvudkontoret för ettor och nollor!",
    "Varning: Kreativt tänkande kan leda till innovation här!",
    "Glöm verkligheten och följ med på en resa genom kodlandet!",
    "Inget att frukta, vi har en nödutgång... någonstans.",
    "Skaka inte för mycket på muspekaren, den blir yr.",
    "Var inte rädd, webbplatsen biter inte... oftast.",
    "Denna webbplats är som en låda med överraskningar, bara utan band.",
    "Din webbläsare är på rätt plats, även om du kanske inte är helt säker.",
    "Vårt digitala fristad har brandsläckare.",
    "Innan du frågar, nej, vi har inte sett din skruv här.",
    "Våra 404-sidor har faktiskt ganska bra självförtroende.",
    "Vi kan inte lova noll buggar, men vi försöker åtminstone få dem att dansa.",
    "Om du tror att detta är en vanlig webbplats, tänk om!",
    "Om internet var en nöjespark skulle detta vara berg-och-dal-banan.",
    "Vår kod är som en trollkarls trick, du vet aldrig vad som händer.",
    "Vänligen lämna din vettighet vid dörren, den har ingen nytta här.",
    "Denna webbplats är som en låda med choklad, du vet aldrig vad du får.",
    "Våra servrar är som små hamstrar, alltid i rörelse.",
    "Ta en bit av vår digitala festkaka.",
    "Vårt bidrag för att göra internet lite märkligt.",
    "Vårt digitala bo har kanske några märkliga ägg.",
    "Våra koder leker gömmeleken, berätta inte var du är.",
    "Om du gillar märkliga skämt och märkliga funktioner är du på rätt plats.",
    "Om du känner dig vilse, oroa dig inte, vi har också ingen aning.",
    "Om vår webbplats var ett skämt skulle det minst innehålla ett 'ping'.",
    "Vårt virtuella lekområde, där koderna har roligt.",
    "Ventilationen suger... bokstavligt talat. Vi jobbar på det.",
    "Om du tror att du är i en Disney-film kan vi bekräfta att du inte är det.",
    "Vi lovar att inte använda dina personuppgifter för att skicka memes.",
    "Våra servrar försöker sjunga, men det låter bara som bråk.",
    "Om du vill logga ut hittar du 'exit' till vänster.",
    "Bli inte förvånad om muspekaren också har en egen agenda.",
    "Bli inte förvirrad om våra servrar tar en siesta.",
    "Om du vill hitta meningen med livet rekommenderar vi att du googlar det.",
    "Oroa dig inte, vår webbplats är allergivänlig, utan spår av nötter.",
    "Om du tror att vi har alla svaren har du uppenbarligen inte sett koden vår.",
    "Vårt digitala cirkus - där koderna försöker jonglera med nollor och ettor.",
    "Om din kaffe är kall är det troligtvis för att servrarna slurpat i sig den.",
    "Våra skämt kan få 'null pointer'-undantag, men vi ger inte upp.",
    "Muspekaren har några konstiga rörelser, den dansar till sin egen melodi.",
    "Om du vill hitta meningen med koden, kan du leta i källkoden.",
    "Bli inte förvånad om muspekaren också försöker peka dig i rätt riktning.",
    "Våra servrar har spenderat natten med att fira med knall och fall.",
    "Om din webbläsare blir trött kan du alltid läsa dig tillbaka till sömn.",
    "Vår kod är som en trollkarls show - ibland magisk, ibland kaotisk.",
    "Bli inte förvirrad om du ser en 404-fel - den testar bara ditt självförtroende.",
    "Om du undrar vad som kom först, hönan eller ägget, har du redan misslyckats.",
    "Vår digitala djungel är full av konstiga varelser och ännu konstigare koder.",
    "Om du känner dig som en fyrkant i en rund värld är detta ditt hem.",
    "Bli inte förvånad om dina data försvinner i ett svart hål. Välkommen till nätet.",
    "Om du har kommit hit för att hitta meningen med livet, kan du lika gärna ta en kopp te.",
    "Om du vill hitta skattekartan har vi en i Excel, men se till att formatet stämmer.",
    "Bli inte förvånad om ditt tangentbord också har en egen vilja.",
    "Vår digitala äventyrspark är öppen för alla som vågar klicka sig vidare.",
    "Förvänta dig inte att hitta svaret på livets frågor här. Du är fortfarande på nätet.",
    "Orora dig inte, vår webbplats är glutenfri, eftersom vi inte använder riktigt bröd.",
    "Om du undrar vad som är vårt bästa trick är det att få koden att fungera.",
    "Välkommen till den digitala djungeln! Håll utkik efter bananflugor.",
    "Om du hör konstiga ljud, oroa dig inte, det är bara koderna som firar.",
    "Välkommen till vår digitala lekplats! Bli inte förvånad om du hittar några konstiga ägg.",
    "Livet är en dans på rosor... för myror som älskar att trampa på taggar.",
    "Visst är det fantastiskt hur arbete kan ge oss en så stark längtan efter att bara ligga i soffan?",
    "Att vara lat är en konstform, och du är definitivt en mästare.",
    "Det är verkligen imponerande hur du alltid hittar den perfekta balansen mellan arbete och att undvika arbete.",
    "Lathet är som en osynlig kraft – och du är dess superhjälte.",
    "De säger att de bästa sakerna i livet är gratis. De har tydligen aldrig köpt kaffe på Starbucks.",
    "Att arbeta smart är överreklamerat. Att arbeta inte alls är en verklig prestation.",
    "Så du säger att du har en låg arbetsmoral? Grattis, du har hittat en direkt väg till mediokritet!",
    "För varje minut du inte arbetar, blir någon annan minut tröttare på att täcka för dig.",
    "Jag är imponerad av hur du konsekvent sätter låga förväntningar och ändå lyckas underprestera.",
    "Lathet är bara en avancerad form av tidsmanagement, eller hur?",
    "Visst är det underbart att se hur andra arbetar medan du utforskar de djupare frågorna i ditt Instagram-flöde?",
    "Att vänta till sista minuten visar verkligen din förmåga att skapa spänning i vardagen.",
    "Har någon någonsin sagt att du är som en levande reklam för arbetsinspiration? Nej? Inte förvånande.",
    "Livet är som en buffé av möjligheter, och du tar verkligen för dig – av soffan.",
    "Du är som en solstråle på kontoret. Så sällsynt att folk tror att du bara är en myt.",
    "Jag hörde att lathet kan vara smittsamt. Tack för att du håller oss friska!",
    "Vem behöver rikedom och framgång när man kan ha... noll ansträngning?",
    "Ditt förhållande till arbete är verkligen inspirerande... för alla andra som inte vill jobba heller.",
    "Jag önskar att jag också kunde leva efter principen 'Varför göra i dag det som kan skjutas upp till i morgon?'",
    "De säger att tålamod är en dygd. Men har någon sagt att otålighet är en konstart?",
    "Att vara produktiv är överskattat. Tänk på all den energi du sparar genom att undvika framsteg!",
    "Det är beundransvärt hur du ständigt kämpar för att uppnå det absolut minsta möjliga.",
    "Jag brukade tänka att arbete är meningsfullt tills jag träffade dig och insåg att det är fullständigt frivilligt.",
    "Ditt arbete är som en solnedgång – vacker att titta på, men försvinner snabbt.",
    "Om lathet var en olympisk gren, skulle du vara vår stoltaste guldmedaljör.",
    "Jag hörde att hjärnan är som en muskel. Du måste verkligen ge din hjärna mycket vila och återhämtning.",
    "Det sägs att geni är 1% inspiration och 99% svält. Du har verkligen låst upp de procenten.",
    "Visst är det imponerande hur du balanserar på gränsen mellan avslappning och koma?",
    "Att vara lat kräver en imponerande mängd mental gymnastik. Du är som en olympisk guldmedaljör i det.",
    "De säger att man ska följa sina drömmar. Du följer definitivt sömnen som om du var en drömföljare.",
    "Vad är meningen med livet? Enligt dig, att minimera allt som liknar arbete.",
    "Att vara ambitiös är överskattat. Att vara obrydd är den verkliga trenden.",
    "Det finns de som går den extra milen. Sen finns det du som inte ens går upp ur sängen.",
    "Jag kan verkligen känna din närvaro... eller snarare avsaknaden av den på arbetsplatsen.",
    "Det är fantastiskt att du alltid följer principen om att 'göra mindre' för att 'uppnå mer'.",
    "För varje arbetsuppgift du undviker, växer en enhörning någonstans i världen.",
    "Jag brukade tro att tid var pengar, tills jag träffade dig och insåg att tid bara är... tid.",
    "Att vara trögstartad på morgonen är en konst som du behärskar till perfektion.",
    "Ditt engagemang för att inte anstränga dig är verkligen inspirerande... om man ser på det från rätt perspektiv.",
    "Om världen var en soffa, skulle du vara dess mest hängivna beboare.",
    "För varje gång du undviker arbete, får någon annan en oväntad möjlighet att briljera.",
    "Visst är det fantastiskt hur du alltid hittar tiden att undvika arbete, oavsett omständigheterna?",
    "Det är som om du har hittat den hemliga formeln för att konstant leva livet i parkeringsläge.",
    "Du är som en solstråle på en regnig dag – väldigt sällsynt och mest troligt bara en myt.",
    "Livet är som en cirkus, och du är definitivt mästaren i att balansera på tråden mellan inaktivitet och... ja, mer inaktivitet.",
    "Jag är imponerad av hur du kan njuta av att göra absolut ingenting med sådan passion och hängivenhet.",
    "Det sägs att latmaskar kommer att ärva jorden. Du kommer troligen att vara för upptagen med att sova för att märka.",
    "Att vara lat är som att vara en pionjär inom den ofrivilliga vilaens konst.",
    "Du är som en guru för att maximera fritid och minimera prestation.",
    "Det är som om du är en expert på att hitta enklaste sättet att göra ingenting på.",
    "För varje idé du har om att göra något produktivt, finns det någon som tänker på en bättre idé: att inte göra det alls.",
  ];

  // Set greetingJoke depending on the language
  let greetingJokes = greetingJokesNO;

  useEffect(() => {
    if (language == "se") {
      greetingJokes = greetingJokesSE;
    }
  }, [language]);

  const randomGreetingJoke =
    greetingJokes[Math.floor(Math.random() * greetingJokes.length)];

  // Set greetingMessage depending on the time of day
  const date = new Date();
  const hour = date.getHours();
  let greetingMessage = greetingMessages[0].message;
  if (hour >= 5 && hour < 12) {
    greetingMessage = greetingMessages[1].message;
  } else if (hour >= 12 && hour < 18) {
    greetingMessage = greetingMessages[2].message;
  } else if (hour >= 18 && hour < 24) {
    greetingMessage = greetingMessages[3].message;
  }

  // Get just first name from currentUser.name
  const firstName = currentUser?.name?.split(" ")[0];

  // Generate a writing animation for the whole greeting message
  // https://stackoverflow.com/questions/30712615/how-to-make-a-typing-effect-in-javascript

  const [greetingMessageState, setGreetingMessageState] = useState("");
  const [greetingMessageStateFinished, setGreetingMessageStateFinished] =
    useState(false);

  let greetingMessageWithName = `${greetingMessage}`;
  if (firstName) {
    greetingMessageWithName += `, ${firstName}!`;
  }
  if (randomGreetingJoke && currentUser.greeting)
    greetingMessageWithName += `</br> ${randomGreetingJoke}`;
  else {
    greetingMessageWithName;
  }

  useEffect(() => {
    setGreetingMessageState("");
    const timer = setTimeout(() => {
      let i = 0;
      const interval = setInterval(() => {
        setGreetingMessageState((prev) => {
          if (i >= greetingMessageWithName.length) {
            clearInterval(interval);
            return prev;
          }
          return prev + greetingMessageWithName[i++];
        });
      }, 30);

      return () => {
        clearInterval(interval);
      };
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="bg-white px-5 py-5 rounded-2xl shadow ">
      <div
        className="text-xl"
        dangerouslySetInnerHTML={{ __html: greetingMessageState }}
      />
    </div>
  );
}
