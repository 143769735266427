import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchChecklists,
  fetchChecklist,
  updateChecklist,
  createChecklist,
  deleteChecklist,
} from "../../api/quality/checklist";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  totalCount: 0,
  currentCount: 0,
  newCount: 0,
};

export const initialController = {
  filters: [],
  page: 0,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const deleteChecklistAsync = createAsyncThunk(
  "checklists/deleteChecklistAsync",
  async (payload) => {
    let response = await deleteChecklist(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createChecklistAsync = createAsyncThunk(
  "checklists/createChecklistAsync",
  async (payload) => {
    let response = await createChecklist(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const updateChecklistAsync = createAsyncThunk(
  "checklists/updateChecklistAsync",
  async (payload) => {
    let response = await updateChecklist(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);
export const getChecklistsAsync = createAsyncThunk(
  "checklists/getChecklistsAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchChecklists(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

export const getChecklistAsync = createAsyncThunk(
  "checklists/getChecklistAsync",
  async (id, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchChecklist(id);

    // If errors, reject and return response
    if (response.error) return rejectWithValue(response);

    // If all good, return response.
    return response;
  }
);

const checklistSlice = createSlice({
  name: "checklists",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getChecklistsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getChecklistsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.currentCount = action.payload.currentCount;
        state.newestChecklistsCount = action.payload.newCount;
      })
      .addCase(getChecklistsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch variable types....is controller missing?",
        ];
      })
      .addCase(updateChecklistAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateChecklistAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data[index] = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(updateChecklistAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update checklist"];
      })
      .addCase(createChecklistAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createChecklistAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
        });
      })
      .addCase(createChecklistAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update checklist"];
      })
      .addCase(deleteChecklistAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteChecklistAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(deleteChecklistAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update checklist"];
      })
      .addCase(getChecklistAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getChecklistAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.checklist = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(getChecklistAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update checklist.",
        ];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = checklistSlice;

export default checklistSlice;
