import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { getVehiclesAsync } from "../../../slices/vehicle";
import { ResourceLoading } from "../../../components/utils/ResourceLoading";
import { TWclassNames } from "../../../components/utils/Div";
import { PMPagination } from "../../../components/utils/PMPagination";

export default function VehiclesListDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const itemState = useSelector((state) => state.vehicles);

  const { open, onClose, onSelect } = props;

  const { data, isLoading, error, errors, currentCount, totalCount } =
    itemState;

  const filters = [];

  const [controller, setController] = useState({
    query: "",
    page: 1,
    per_page: 10,
    sortBy: "created_at",
    sort: "desc",
    filters: filters,
    view: null,
  });

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  useEffect(() => {
    if (open) {
      dispatch(getVehiclesAsync(controller));
    }
  }, [dispatch, open, controller]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex justify-center p-4 text-center sm:data-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full sm:max-w-xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    <div className="flex data-center py-5 w-full">
                      <div className="block w-[60%]">
                        <h2
                          id="applicant-information-title"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          {t("Vehicles")}
                        </h2>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
                      </div>
                    </div>
                  </Dialog.Title>

                  {isLoading ? (
                    <ResourceLoading />
                  ) : (
                    <>
                      <ul role="list" className="divide-y divide-gray-100">
                        {data.map((item) => (
                          <li
                            onClick={() => onSelect(item?.id)}
                            key={item.email}
                            className={TWclassNames(
                              "bg-blue-50",
                              "cursor-pointer relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 rounded-xl mt-2 sm:px-6 lg:px-8"
                            )}
                          >
                            <div className="flex gap-x-4">
                              <div className="min-w-0 flex-auto">
                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                  <span className="absolute inset-x-0 -top-px bottom-0" />
                                  {item?.displayTitle}
                                </p>
                              </div>
                            </div>
                            <div className="flex data-center gap-x-4">
                              <div className="hidden sm:flex sm:flex-col sm:data-end">
                                <p className="text-sm leading-6 text-gray-900">
                                  {item.role}
                                </p>
                              </div>
                              <ChevronRightIcon
                                className="h-5 w-5 flex-none text-gray-400"
                                aria-hidden="true"
                              />
                            </div>
                          </li>
                        ))}
                      </ul>

                      <PMPagination
                        disabled={isLoading}
                        onPageChange={handlePageChange}
                        page={controller?.page}
                        perPage={controller?.per_page}
                        totalCount={totalCount}
                        currentCount={currentCount}
                      />
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
