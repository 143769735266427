import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { PMPagination } from "../../../utils/PMPagination";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { CopyToClipboard, TWclassNames } from "../../../utils/Div";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const UsersTable = (props) => {
  const {
    itemState,
    onPageChange,
    itemsCount,
    currentCount,
    page,
    perPage,
    onItemsSelect,
  } = props;
  const { data, isLoading, errors } = itemState;
  const users = data;
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedReports, setSelectedReports] = useState([]);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedReports.length > 0 && selectedReports.length < users.length;
    setChecked(selectedReports.length === users.length);
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
    onItemsSelect(selectedReports);
  }, [selectedReports]);

  function toggleAll() {
    setSelectedReports(checked || indeterminate ? [] : users);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const navigate = useNavigate();

  return (
    <div>
      <PMPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        perPage={perPage}
        rowsCount={itemsCount}
        totalCount={itemsCount}
        currentCount={currentCount}
        showCount={false}
        showPages={false}
      />
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="relative w-12 px-6 sm:w-16 sm:px-8"
                    >
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th
                      scope="col"
                      className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      User
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email / Mobile
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Address
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {users?.map((user) => (
                    <tr
                      key={user.id}
                      className={TWclassNames(
                        selectedReports.includes(user) ? "bg-gray-50" : null,
                        "hover:bg-gray-50"
                      )}
                    >
                      <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                        {selectedReports.includes(user) && (
                          <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                        )}
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                          value={data?.id}
                          checked={selectedReports.includes(user)}
                          onChange={(e) =>
                            setSelectedReports(
                              e.target.checked
                                ? [...selectedReports, user]
                                : selectedReports.filter((p) => p !== user)
                            )
                          }
                        />
                      </td>
                      <td
                        onClick={() => navigate("/dashboard/users/" + user.id)}
                        className={classNames(
                          "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                          selectedReports.includes(user)
                            ? "text-indigo-600"
                            : "text-gray-900"
                        )}
                      >
                        <span className="cursor-pointer text-blue-400">
                          {user?.name}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="flex items-center">
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">
                              <Link to={"mailto:" + user?.email}>
                                <span className="cursor-pointer text-blue-400">
                                  {user?.email}
                                </span>
                              </Link>
                            </div>
                            <div className="text-gray-500">
                              <CopyToClipboard
                                path={null}
                                content={user?.mobile}
                                type="popup"
                              />
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        {/* <a href="#" className="text-indigo-600 hover:text-indigo-900">
                          Edit<span className="sr-only">, {person.name}</span>
                        </a> */}
                      </td>

                      <td></td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <PMPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        perPage={perPage}
        rowsCount={itemsCount}
        totalCount={itemsCount}
        currentCount={currentCount}
        showCount={true}
      />
    </div>
  );
};

UsersTable.defaultProps = {
  page: 1,
  itemState: [],
  itemsCount: 0,
  selectedItems: [],
  sort: "desc",
  sortBy: "createdAt",
};
