import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { FormikSaveBar } from "../../utils/FormikSaveBar";
import { PMField } from "../../utils/PMField";
import { useDispatch } from "react-redux";
import PMSwitch from "../../utils/PMSwitch";
import { useUserHasAccess } from "../../../routes/roleOnly";
import { dispatchWithToast } from "../../Utils";
import { updateContactAsync } from "../../../slices/customer/contact";

export const ContactInfo = (props) => {
  const { t } = useTranslation();
  const { item, onRefresh, onLoading } = props;
  // Will be fetched form API in the future
  const dispatch = useDispatch();

  const isAdmin = useUserHasAccess({ role: "customer", action: "sync" });

  return (
    <>
      <Formik
        initialValues={{
          id: item?.id,
          name: item?.name,
          email: item?.email,
          mobile: item?.mobile,
          submit: null,
          active: item?.active || false,
        }}
        validationSchema={Yup.object({
          name: Yup.string().max(255).required(t("customer.name.required")),
          description: Yup.string(),
        })}
        onSubmit={(values, helpers) => {
          try {
            onLoading();
            dispatchWithToast(dispatch(updateContactAsync(values)), onRefresh);
            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
          } catch (err) {
            toast.error("Nope");
            console.error(err);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                {/* <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">{ t("Product Description") }</h3>
                    <p className="mt-1 text-sm text-gray-500"></p>
                  </div> */}
                <div className="grid sm:grid-cols-8 gap-6">
                  <div className="sm:col-span-2">
                    <PMSwitch label="Active" name="active" {...formik} />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t("Contact information")}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500"></p>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <PMField name="name" type="text" label="Name" {...formik} />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <PMField
                      name="mobile"
                      type="text"
                      label="Mobile"
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <PMField
                      name="email"
                      type="text"
                      label="Email"
                      {...formik}
                    />
                  </div>

                  {formik?.values?.customer_type?.includes(
                    "company",
                    "customer_company_supplier"
                  ) && (
                    <div className="col-span-6 sm:col-span-3">
                      <PMField
                        name="vat"
                        type="text"
                        label="Org. Nummer"
                        {...formik}
                      />
                    </div>
                  )}

                  <div className="col-span-6">
                    <PMField
                      name="short_description"
                      type="text"
                      label="Short Description"
                      multiline
                      rows={2}
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2"></div>
                </div>
              </div>
            </div>

            {/* <PromptIfDirty {...formik}/> */}
            <FormikSaveBar {...formik} />
          </Form>
        )}
      </Formik>
    </>
  );
};
