import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSettings } from "../../../contexts/settings-context";
import {
  ArrowLeftOnRectangleIcon,
  ArrowRightOnRectangleIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import { Popup, TWclassNames } from "../../../components/utils/Div";
import { fetchDrivingDriverByUser } from "../../../api/driving/driver";
import { ResourceLoading } from "../../../components/utils/ResourceLoading";
import { CountUp } from "../../../components/Utils";
import { driverVehicleAction } from "./drivers/utils";
import VehiclesListDialog from "../vehicles/list-dialog";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

export default function CurrentVehicleSection(props) {
  const { t } = useTranslation();

  const currentUser = useSelector((state) => state.session.currentUser);

  const { settings } = useSettings();

  const { language } = settings;

  const [driver, setDriver] = useState({});
  const [vehicle, setVehicle] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [vehiclesListDialogOpen, setVehiclesListDialogOpen] = useState(false);

  const navigate = useNavigate();

  // Fetch Driver
  useEffect(() => {
    const payload = {
      user_id: currentUser?.id,
      fields:
        "id,displayTitle,checked_into_vehicle.id,checked_into_vehicle.number_plate,checked_into_vehicle.distance_driven_today,checked_into_vehicle.distance_driven_this_week,checked_into_vehicle.distance_driven_this_month",
    };
    async function fetchDriver() {
      setIsLoading(true);
      await fetchDrivingDriverByUser(payload)
        .then((response) => {
          if (response?.data?.id) {
            setIsLoading(false);
            setDriver(response?.data);
            if (response?.data?.checked_into_vehicle) {
              setVehicle(response?.data?.checked_into_vehicle);
            }
          } else {
            setIsLoading(false);
            setDriver({});
            setVehicle({});
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
    if (currentUser) {
      fetchDriver();
    }
  }, [currentUser]);

  const handleVehicleAction = async () => {
    if (driver?.id) {
      setIsLoading(true);
      await driverVehicleAction({
        userId: currentUser?.id,
        vehicleId: vehicle?.id,
        action: "checkout",
        onDriverUpdate: (response) => {
          if (response?.id) {
            setDriver(response);
            setVehicle({});
          }
          setIsLoading(false);
        },
      });
    }
  };

  const handleVehicleCheckin = async (vehicleId) => {
    if (driver?.id) {
      setIsLoading(true);
      await driverVehicleAction({
        userId: currentUser?.id,
        vehicleId: vehicleId,
        action: "checkin",
        onDriverUpdate: (response) => {
          if (response?.id) {
            setDriver(response);
            setVehicle(response?.checked_into_vehicle);
          }
          setIsLoading(false);
        },
      });
    }
  };

  const Content = () => (
    <>
      <div className="flex flex-row">
        <div className="flex flex-col">
          <div
            className={TWclassNames(
              vehicle?.id ? "border-blue-400" : "border-red-400",
              "hidden lg:block font-bold border-2 rounded-full lg:px-5 lg:py-5 bg-blue-50 "
            )}
          >
            <TruckIcon className="h-10 w-10" />
          </div>
        </div>
        <div className="ml-0 lg:ml-5 flex flex-col">
          <div className="ml-2 text-sm text-gray-400">
            {t("Current Checked in Vehicle")}
          </div>
          <div className="ml-2 text-2xl font-bold">
            {vehicle?.id
              ? vehicle?.number_plate
              : driver?.id
              ? "Ingen bil"
              : "Du er ikke en sjåfør"}
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-5 mt-10">
        {vehicle?.id ? (
          <button
            onClick={() => handleVehicleAction()}
            type="button"
            className="mt-2 block cursor-pointer px-4 py-1 border-2 rounded-xl shadow-md bg-blue-50 border-blue-400 font-semibold hover:bg-blue-400"
          >
            <Popup content="Sjekk ut av bilen">
              <ArrowRightOnRectangleIcon className="h-5 w-5" />
            </Popup>
          </button>
        ) : (
          driver?.id && (
            <button
              onClick={() => setVehiclesListDialogOpen(true)}
              type="button"
              className="mt-2 block cursor-pointer px-4 py-1 border-2 rounded-xl shadow-md bg-blue-50 border-blue-400 font-semibold hover:bg-blue-400"
            >
              <Popup content="Sjekk inn i en bil">
                <ArrowLeftOnRectangleIcon className="h-5 w-5" />
              </Popup>
            </button>
          )
        )}
        {vehicle?.id && (
          <Link
            to={"/dashboard/driving/vehicles/" + vehicle?.id}
            type="button"
            className="mt-2 block cursor-pointer px-4 py-1 border-2 rounded-xl shadow-md bg-blue-50 border-blue-400 font-semibold hover:bg-blue-400"
          >
            Oversikt
          </Link>
        )}
      </div>

      {vehicle?.id && (
        <div className="flex flex-col md:flex-row mt-10 justify-between">
          <div className="flex flex-col">
            <div className="text-sm text-gray-400">{t("Driven today")}</div>
            <div className="text-2xl font-bold">
              <CountUp value={vehicle && vehicle?.distance_driven_today} />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-sm text-gray-400">{t("Driven this week")}</div>
            <div className="text-2xl font-bold">
              <CountUp value={vehicle?.distance_driven_this_week} />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-sm text-gray-400">
              {t("Driven this month")}
            </div>
            <div className="text-2xl font-bold">
              <CountUp value={vehicle?.distance_driven_this_month} />
            </div>
          </div>
        </div>
      )}

      <VehiclesListDialog
        open={vehiclesListDialogOpen}
        onClose={() => setVehiclesListDialogOpen(false)}
        onSelect={(vehicleId) => {
          toast.success("Vehicle selected");
          setVehiclesListDialogOpen(false);
          handleVehicleCheckin(vehicleId);
        }}
      />
    </>
  );

  return (
    <div className="bg-white px-5 py-5 rounded-2xl shadow hover:shadow-blue-400 md:min-h-[350px]">
      <h1 className="px-2 py-2 font-bold mb-5">Ditt kjøretøy</h1>
      {isLoading ? <ResourceLoading /> : <Content />}
    </div>
  );
}
