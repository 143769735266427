import axios from "../axios";

export async function getDeviationPdf(payload) {
  return axios.get(`/quality/deviations/${payload.id}/pdf`, {
    params: payload.params,
    type: "application/pdf",
  });
}

export async function fetchDeviations(controller) {
  return axios({
    method: "get",
    url: "/quality/deviations",
    params: controller,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchDeviation(id) {
  return axios.get(`/quality/deviations/` + id);
}

export async function createDeviation(payload) {
  return axios
    .post(`/quality/deviations`, { deviation: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function createDeviationItem(payload) {
  return axios
    .post(`/quality/deviations/${payload.deviation_id}/items`, {
      deviation_item: payload,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function deleteDeviationItem(payload) {
  return axios
    .delete(`/quality/deviations/${payload.deviation_id}/items/${payload.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function updateDeviation(payload) {
  return axios
    .put(`/quality/deviations/${payload.id}`, { deviation: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function printDeviation(payload) {
  return axios.put(`/quality/deviations/${payload.id}/print`, {
    deviation: payload,
  });
}

export async function sendDeviation(payload) {
  return axios.put(`/quality/deviations/${payload.id}/send`, {
    deviation: payload,
  });
}

export async function deleteDeviation(payload) {
  const deviation = payload.deviation;
  return axios
    .delete(`/quality/deviations/${deviation.deviation_id}`, {
      data: JSON.stringify({
        deviation,
      }),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
