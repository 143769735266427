import axios from "./axios";

export async function fetchActivities(controller) {
  return axios({
    method: "get",
    url: "/activities",
    params: controller,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchActivityItems(controller) {
  return axios({
    method: "get",
    url: "/activities/items",
    params: controller,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchActivity(id) {
  return axios.get(`/activities/` + id);
}

export async function createActivity(payload) {
  return axios
    .post(`/activities`, { activity: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
export async function updateActivity(payload) {
  return axios
    .put(`/activities/${payload.id}`, { activity: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function deleteActivity(payload) {
  const activity = payload.activity;
  return axios
    .delete(`/activities/${activity.activity_id}`, {
      data: JSON.stringify({
        activity,
      }),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
