import axios from "../../axios";

export async function fetchResourceHubFaqCategory(id) {
  return axios.get(`/resource_hub/faq/categories/` + id);
}

export async function fetchResourceHubFaqCategories(controller) {
  return axios({
    method: "get",
    url: "/resource_hub/faq/categories/",
    params: controller,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function createResourceHubFaqCategory(payload) {
  return axios
    .post(`/resource_hub/faq/categories/`, { category: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return {};
    });
}
export async function updateResourceHubFaqCategory(payload) {
  return axios
    .put(`/resource_hub/faq/categories/${payload.id}`, { category: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function destroyResourceHubFaqCategory(payload) {
  return axios
    .delete(`/resource_hub/faq/categories/${payload.id}`)
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
}
