import { useState } from "react";
import { toast } from "react-hot-toast";
import { ModuleTag } from "./moduleTag";

export const DeviationTypeTag = ({ deviation_type, onRefresh, label }) => {
  const [dialogOpen, setDialogOpen] = useState();

  const handleOpenDialog = () => {
    if (deviation_type?.id) {
      setDialogOpen(true);
    } else {
      toast.error("Du må velge avvik-type først");
    }
  };
    return (
      <>
        <ModuleTag
          label={label || "Deviation Type"}
          onClick={() => handleOpenDialog(true)}
        >
          {deviation_type?.displayTitle}
        </ModuleTag>
      </>
    );
};
