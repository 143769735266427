import { useTranslation } from "react-i18next";
import { fetchCaseTypes } from "../../../api/case/type";
import { status } from "../../Utils";
import { TWclassNames } from "../../utils/Div";
import { priorityOptions } from "../quality/deviations/utils";

export const caseStatuses = {
  NEW: { label: "New", class: "bg-green-100 text-green-800" },
  PROCESSING: { label: "Processing", class: "bg-yellow-200 text-green-800" },
  FINISHED: { label: "Finished", class: "bg-yellow-200 text-green-800" },
  CLOSED: { label: "Closed", class: "bg-red-600 text-white" },
  "": { label: "Unknown", class: "bg-gray-600 text-white" },
  null: { label: "Unknown", class: "bg-gray-600 text-white" },
};

export const caseStatusOptions = [
  { id: "NEW", value: "NEW", label: "New", title: "New" },
  {
    id: "PROCESSING",
    value: "PROCESSING",
    label: "Processing",
    title: "Processing",
  },
  { id: "FINISHED", value: "FINISHED", label: "Finished", title: "Finished" },
  { id: "CLOSED", value: "CLOSED", label: "Closed", title: "Closed" },
];

export const casesViewsOptions = [
  { id: "ONLY_OVERDUE", value: "ONLY_OVERDUE", label: "Kun forfalt" },
];

export async function getCaseTypeOptions() {
  try {
    const response = await fetchCaseTypes(); // Replace with your API endpoint
    // if (!response.ok) {
    //   throw new Error("Network response was not ok");
    // }
    const data = await response;

    return data?.data?.data?.map((type, idx) => ({
      value: type?.id, // Assuming the API has an 'id' field for each checklist type
      title: type?.displayTitle, // Assuming the API has a 'name' field for each checklist type
      order: idx, // Assuming the API has an 'order' field for each checklist type
      object: type,
    }));
  } catch (error) {
    console.log(error);
    console.error(
      "There was a problem with the fetch operation:",
      error.message
    );
  }
}

export const CaseStatus = ({ caseItem }) => {
  if (!caseItem?.status) return null;

  const itemStatus = caseItem?.status?.toLowerCase();

  if (!status[itemStatus]) return null;

  return (
    <span
      className={TWclassNames(
        status[itemStatus]?.class,
        "inline-flex rounded-full px-2 text-xs font-semibold leading-5"
      )}
    >
      {status[itemStatus].label}
    </span>
  );
};

export function CasePriorityStatus({ priority }) {
  const { t } = useTranslation();
  if (!priority) return null;
  const index = priority - 1;
  return (
    <span
      className={TWclassNames(
        priorityOptions[index].class,
        "inline-flex rounded-full px-2 text-xs font-semibold leading-5"
      )}
    >
      {priorityOptions[index].label} {t("Priority")}
    </span>
  );
}

export async function caseTypeOptions() {
  var array = [];
  // Fetch Case types from API?
  await fetchCaseTypes()
    .then((res) => {
      if (res.data) {
        if (!res?.data) return array;
        res?.data?.map((row, i) => {
          const rowParams = {
            value: row.id,
            title: row.title,
            order: i,
          };
          array.push(rowParams);
        });
      }
    })
    .catch((err) => {
      let errors = null;
    });

  return array;
}
