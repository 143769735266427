import { CameraIcon } from "@heroicons/react/20/solid";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ResourceLoading } from "../../../../components/utils/ResourceLoading";
import { ResourceError } from "../../../../components/utils/ResourceError";
import { ResourceEmpty } from "../../../../components/utils/ResourceEmpty";
import { Notes } from "../../../../components/dashboard/notes/notes";
import DashboardHeader from "../../../../components/dashboard/dashboardHeader";
import {
  ArrowPathRoundedSquareIcon,
  AtSymbolIcon,
  BuildingOfficeIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  deleteChecklistItem,
  fetchChecklist,
} from "../../../../api/quality/checklist";
import { CustomerTag } from "../../../../components/utils/moduleTags/customerTag";
import { ProjectTag } from "../../../../components/utils/moduleTags/projectTag";
import { EmployeeTag } from "../../../../components/utils/moduleTags/employeeTag";

import {
  customerHeaderAction,
  dateToString,
  dispatchWithToast,
} from "../../../../components/Utils";
import { HistoriesModalButton } from "../../../../components/dashboard/histories/modal-button";
import { DocumentViewer } from "../../../../components/dashboard/Media/documentViewer";
import { Popup } from "../../../../components/utils/Div";
import RoleOnly, { useUserHasAccess } from "../../../../routes/roleOnly";
import SimplePrompt from "../../../../components/utils/simplePrompt";
import { ProjectFolderTag } from "../../../../components/utils/moduleTags/projectFolderTag";
import ChecklistDialog from "../../../../components/dashboard/quality/checklists/checklistDialog";
import { ChecklistTypeTag } from "../../../../components/utils/moduleTags/checklistTypeTag";
import { Form, Formik } from "formik";
import { FormikSaveBar } from "../../../../components/utils/FormikSaveBar";
import ChecklistItemLines from "../../../../components/dashboard/quality/checklists/checklistItemLines";
import { updateChecklistAsync } from "../../../../slices/quality/checklist";
import {
  ChecklistApprovedStatus,
  ChecklistStatus,
} from "../../../../components/dashboard/quality/checklists/utils";
import { PMField } from "../../../../components/utils/PMField";
import PMSwitch from "../../../../components/utils/PMSwitch";
import { ChecklistPrintDialog } from "../../../../components/dashboard/quality/checklists/checklistPrint";
import { PMNotice } from "../../../../components/utils/notifications";

export const ChecklistView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    checklist: {},
  });

  const getChecklist = () => {
    setItemState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      fetchChecklist(id).then((data) => {
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          checklist: data?.data,
        }));
      });
    } catch {
      toast.error("Something went wrong when trying to load the checklist");
      setItemState((prevState) => ({
        ...prevState,
        isLoading: false,
        error: true,
      }));
    }
  };

  useEffect(() => {
    getChecklist();
  }, [dispatch, id, open]);

  const handleOnRefresh = (data) => {
    if (data?.id) {
      setItemState((prevState) => ({
        ...prevState,
        checklist: data,
      }));
    } else {
      getChecklist();
    }
  };

  function handleNewChecklistItems(data) {
    const newChecklistItem = {
      ...checklist,
      items: [...checklist.items, data],
    };

    setItemState((prevState) => ({
      ...prevState,
      checklist: newChecklistItem,
    }));
  }

  const [checklistItemDeletePromptOpen, setChecklistItemDeletePromptOpen] =
    useState(false);
  const [selectedChecklistItem, setSelectedChecklistItem] = useState(null);

  const promptDeleteChecklistItem = (item) => {
    setSelectedChecklistItem(item);
    setChecklistItemDeletePromptOpen(true);
  };

  const handleDeleteChecklistItem = async () => {
    const payload = {
      checklist_id: checklist?.id,
      id: selectedChecklistItem?.id,
    };
    const response = await deleteChecklistItem(payload);

    if (response?.status === 200) {
      setChecklistItemDeletePromptOpen(false);
      toast.success("Innlegget ble slettet");

      const newChecklistItems = checklist?.items.filter(
        (checklistItem) => checklistItem.id !== selectedChecklistItem?.id
      );

      const newChecklist = {
        ...checklist,
        items: newChecklistItems,
      };

      setItemState((prevState) => ({
        ...prevState,
        checklist: newChecklist,
      }));

      setChecklistItemDeletePromptOpen(false);
      setSelectedChecklistItem(null);
    }
  };

  const { checklist, isLoading, error, errors } = itemState;

  const [checklistDialogOpen, setChecklistDialogOpen] = useState(false);
  const [checklistSendDialogOpen, setReportSendDialogOpen] = useState(false);
  const [printDialogOpen, setPrintDialogOpen] = useState(false);

  const canApprove = useUserHasAccess({ role: "checklist", action: "approve" });

  const headerMeta = [
    // {
    //   component: <ChecklistSeverity severity={checklist?.severity} />,
    //   label: "Severity",
    //   // description: "",
    //   // path: null,
    //   // action: null,
    //   // icon: null,
    //   // textColor: "text-teal-700",
    //   hidden: checklist?.severity ? false : true,
    // },
    // {
    //   component: <ChecklistPriority priority={checklist?.priority} />,
    //   label: "Priority",
    //   // description: "",
    //   // path: null,
    //   // action: null,
    //   // icon: null,
    //   // textColor: "text-teal-700",
    //   hidden: checklist?.priority ? false : true,
    // },
    {
      component: <ChecklistStatus checklist={checklist} />,
      label: "Status",
      description: "",
      path: null,
      action: null,
      icon: null,
      textColor: "text-teal-700",
      hidden: false,
    },
    {
      component: <ChecklistApprovedStatus checklist={checklist} />,
      label: "Approved Status",
      description: "",
      path: null,
      action: null,
      icon: null,
      textColor: "text-teal-700",
      hidden: false,
    },
    customerHeaderAction(checklist?.customer),
    {
      label: checklist?.project?.displayTitle,
      description: "",
      path: "/dashboard/projects/" + checklist?.project?.id,
      action: null,
      icon: BuildingOfficeIcon,
      textColor: "text-teal-700",
      hidden: checklist?.project?.id ? false : true,
    },
  ];

  const headerActions = [
    {
      component: (
        <HistoriesModalButton
          module={"checklist"}
          module_param={checklist?.id}
        />
      ),
      label: "History",
      description: "",
      icon: ArrowPathRoundedSquareIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: false,
    },
    {
      component: (
        <div className="px-2 mt-2">
          <Notes module={"checklist"} module_param={checklist?.id} />
        </div>
      ),
      label: "Status",
      description: "",
      path: null,
      action: null,
      icon: CameraIcon,
      textColor: "text-teal-700",
      bgColor: "bg-red-200",
      hidden: false,
      menu: false,
    },
    // {
    //   component: null,
    //   label: "Copy",
    //   description: "",
    //   path: null,
    //   action: null,
    //   icon: ClipboardDocumentCheckIcon,
    //   textColor: "text-teal-700",
    //   bgColor: "bg-white",
    //   hidden: false,
    //   menu: true,
    // },
    {
      component: null,
      label: "Skriv ut",
      description: "",
      path: null,
      action: () => setPrintDialogOpen(true),
      icon: AtSymbolIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: true,
    },
    {
      component: null,
      label: "Slett",
      description: "",
      path: null,
      action: () => handleDeleteChecklist(),
      icon: TrashIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: true,
    },
  ];

  const handleChecklistItemUpdate = (data, formik) => {
    formik.setFieldValue("items", data);
  };

  const ChecklistItem = ({ item }) => {
    return (
      <>
        <section
          className="lg:max-w[7xl]"
          aria-labelledby="applicant-information-title"
        >
          <div className="bg-white shadow sm:rounded-lg">
            <div className="flex items-center px-4 py-5 sm:px-6 w-full">
              <div className="block w-full">
                <h2
                  id="applicant-information-title"
                  className="flex-col text-md font-medium leading-6 text-gray-900 "
                >
                  <div className="flex justify-between">
                    <span className="px-2">
                      Lagt til {dateToString(item?.created_at)} av{" "}
                      {item?.created_by?.displayTitle}
                    </span>
                    <RoleOnly role="checklists" action="destroy">
                      <div className="right-0 cursor-pointer">
                        <Popup content="Slett linje">
                          <button
                            type="button"
                            onClick={() => promptDeleteChecklistItem(item)}
                          >
                            <TrashIcon className="h-5 w-5 text-red-600" />
                          </button>
                        </Popup>
                      </div>
                    </RoleOnly>
                  </div>
                  <div className="mt-2">
                    {/* <ChecklistItemActionStatus item={item} /> */}
                  </div>
                </h2>
              </div>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  {item?.description}
                </p>
              </dl>
            </div>

            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <DocumentViewer
                pathType="quickview"
                documents={item?.documents}
              />
            </div>
          </div>
        </section>
      </>
    );
  };

  const handleRefresh = (data) => {
    if (data?.id) {
      setItemState((prevState) => ({
        ...prevState,
        checklist: data,
      }));
    }
  };

  const handleDeleteChecklist = () => {};

  const Content = () => {
    if (isLoading) {
      return <ResourceLoading size="base" />;
    }

    if (error) return <ResourceError errors={errors} />;

    if (!checklist?.id) {
      return <ResourceEmpty />;
    }

    return (
      <>
        <div className="space-y-6 lg:col-span-2 lg:col-start-1 sm:col-span-2 max-sm:mt-5 max bg-white">
          <section
            className="lg:max-w[7xl]"
            aria-labelledby="applicant-information-title"
          >
            <div className="bg-white shadow sm:rounded-lg">
              <div className="flex items-center px-4 py-5 sm:px-6 w-full">
                <div className="block w-[60%]">
                  <h2
                    id="applicant-information-title"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Detaljer
                  </h2>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Informasjon om avvik
                  </p>
                </div>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
                  <ProjectTag
                    project={checklist?.project}
                    folder={checklist?.folder}
                    onRefresh={handleOnRefresh}
                  />

                  <CustomerTag
                    customer={checklist?.customer}
                    onRefresh={handleOnRefresh}
                  />
                  <ProjectFolderTag
                    label="Folder"
                    folder={checklist?.folder}
                    onRefresh={handleOnRefresh}
                  />

                  <EmployeeTag
                    label="Ansarlig Utførende"
                    employee={checklist?.responsible_executive}
                    onRefresh={handleOnRefresh}
                  />

                  <ChecklistTypeTag
                    checklist_type={checklist?.checklist_type}
                    onRefresh={handleOnRefresh}
                  />

                  {/* <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Beskrivelse av avvik
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: checklist?.description,
                        }}
                      />
                    </dd>
                  </div> */}
                </dl>
              </div>
            </div>
          </section>
        </div>
        <div className="space-y-6 lg:col-span-2 lg:col-start-1 sm:col-span-2 max-sm:mt-5 max">
          {/* <ChecklistActions
            checklist={checklist}
            onItemChange={(data) => handleNewChecklistItems(data)}
          /> */}
        </div>

        <div className="space-y-6 lg:col-span-2 lg:col-start-1 sm:col-span-2 max-sm:mt-5 max">
          <Formik
            enableReinitialize
            initialValues={{
              id: checklist?.id || null,
              items: checklist?.items || [],
              finished: checklist?.finished || false,
              approved: checklist?.approved || false,
              submit: null,
            }}
            onSubmit={(values, helpers) => {
              try {
                dispatchWithToast(
                  dispatch(updateChecklistAsync(values)),
                  (data) => handleRefresh(data)
                  // onClose(true)
                );

                helpers.setStatus({ success: true });
                helpers.setSubmitting(false);
              } catch (err) {
                console.log(err);
                toast.error("Something went wrong...");
                console.error(err);
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
              }
            }}
          >
            {(formik) => (
              <Form>
                <div className="grid grid-cols-6 gap-6">
                  {checklist?.is_items_editable !== undefined &&
                    !checklist?.is_items_editable && (
                      <div className="col-span-6 sm:col-span-6">
                        <PMNotice
                          title="Sjekklisten er ferdigstilt"
                          description="Sjekklisten kan ikke endres"
                        />
                      </div>
                    )}

                  <div className="col-span-6 sm:col-span-6">
                    <ChecklistItemLines
                      checklist={checklist}
                      items={checklist?.items}
                      editable={false}
                      isLoading={isLoading}
                      drag={false}
                      addRow={true}
                      deleteRow={false}
                      disabled={
                        checklist?.is_items_editable !== undefined &&
                        !checklist?.is_items_editable
                      }
                      parentCallback={(data) =>
                        handleChecklistItemUpdate(data, formik)
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-6 mb-[200px] bg-white px-5 py-5 ">
                  <div className="col-span-2 sm:col-span-2">
                    <div className="mt-5">
                      <PMSwitch
                        name="finished"
                        label="Sjekkliste er ferdig"
                        {...formik}
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    {canApprove && (
                      <>
                        {!checklist?.can_be_approved ? (
                          <PMNotice
                            title="Sjekklisten kan ikke ferdistilles"
                            description="Samtlige
                          punkter må godkjennes først og ferdigstilles."
                          />
                        ) : (
                          <>
                            {formik.values.finished ? (
                              <PMSwitch
                                name="approved"
                                label="Sjekklisten er godkjent"
                                {...formik}
                              />
                            ) : (
                              <PMNotice
                                title="Sjekklisten må ferdigstilles!"
                                description="Før den kan godkjennes"
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <FormikSaveBar {...formik} />
              </Form>
            )}
          </Formik>
        </div>
      </>
    );
  };

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={checklist?.displayTitle || "Mangler title"}
        meta={headerMeta}
        mainButton={{
          label: "Edit",
          action: () => setChecklistDialogOpen(true),
          path: null,
          color: "",
          icon: PencilIcon,
        }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-1">
          <Content />
        </div>
      </DashboardHeader>

      <ChecklistDialog
        open={checklistDialogOpen}
        onClose={() => setChecklistDialogOpen(false)}
        selected={checklist}
        onRefresh={handleOnRefresh}
      />

      <SimplePrompt
        title="Slett inlegg"
        description="Er du sikker på at du ønsker å slette inlegget?"
        onAccept={() => handleDeleteChecklistItem()}
        open={checklistItemDeletePromptOpen}
        onClose={() => setChecklistItemDeletePromptOpen(false)}
      />

      <ChecklistPrintDialog
        checklist={checklist}
        open={printDialogOpen}
        onClose={() => setPrintDialogOpen(false)}
      />
    </>
  );
};
