import { useTranslation } from "react-i18next";
import { PMSimpleSelectorField } from "../PMSimpleSelector/PMSimpleSelectorField";
import { PMSimpleSelectorFieldV2 } from "../PMSimpleSelector/PMSimpleSelectorFieldV2";
import axios from "../../../api/axios";
import { useState, useEffect } from "react";
import { mapErrors } from "../../Utils";
import { Form, Formik } from "formik";
import { ResourceLoading } from "../ResourceLoading";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { PMField } from "../PMField";
import { fetchSystemTypes } from "../../../api/customer/system";
import ItemSelector from "../Selector/ItemSelector";
import PMListbox from "../PMListbox";
import { debounce } from "lodash";
import CustomerDialog from "../../dashboard/customers/dialog";

export const CustomerSelector = (props) => {
  const {
    setFieldValue,
    values,
    onChange,
    errors,
    module,
    module_param,
    fields,
    searchQuery,
    showField,
  } = props;

  const name = props?.name ? props.name : "customer_id";
  const label = props?.label ? props.label : "Customer";
  const FETCH_URL = `/customers/`;
  const CREATE_URL = `/customers/`;
  const filters = null;

  const { t } = useTranslation();

  const [customerDialogOpen, setCustomerDialogOpen] = useState(false);

  const [query, setQuery] = useState();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({
    id: 0,
    field1: "Ingen valgt",
    field2: null,
  });
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);

  const labelAction = {
    // path: selected?.id ? "/dashboard/customers/" + selected?.id : null,
    action: () => setCustomerDialogOpen(true),
  };

  useEffect(() => {
    if (props.open) {
      setOpen(true);
    }
  }, [props.open]);

  const handleChange = (data) => {
    console.log(data);

    if (data.selected.item.id !== null) {
      getItem(data.selected.item.id);
    } else {
      setSelected({
        id: data?.selected?.item?.id,
        field1: data?.selected?.item?.primary,
      });
    }

    setOpen(false);

    if (values) {
      setFieldValue(name, data.selected.item.id);
    }

    if (onChange) {
      onChange({
        target: {
          name: name,
          value: data.selected.item.id,
          data: data?.selected?.item,
        },
      });
    }
  };

  useEffect(() => {
    if (props?.selected?.id) {
      setSelected({
        id: props.selected?.id,
        field1: props.selected?.displayTitle,
        syncStatus: props.selected?.syncStatus,
      });
    }
  }, [props.selected]);

  const handleSelectFromForm = (data) => {
    setSelected({
      id: data?.id,
      field1: data?.displayTitle,
    });
    if (values) {
      setFieldValue(name, data?.id);
    } else {
      onChange({ target: { name: name, value: data?.id } });
    }
  };

  const createView = (item) => {
    const createOrUpdate = (values) => {
      const data = {
        customer: {
          ...values,
        },
        module: module,
        module_param: module_param,
      };
      return axios.post(CREATE_URL, data).then((res) => {
        return res;
      });
    };

    return (
      <>
        {loading ? (
          <ResourceLoading />
        ) : (
          <Formik
            initialValues={{
              id: null,
              name: item?.name
                ? item?.name
                : fields?.name
                ? fields?.name
                : null,
              email: item?.email
                ? item?.email
                : fields?.email
                ? fields?.email
                : null,
              mobile: item?.mobile
                ? item?.mobile
                : fields?.mobile
                ? fields?.mobile
                : null,

              postal_address: item?.postal_address?.id
                ? item?.postal_address
                : {
                    address: item?.address
                      ? item?.address
                      : fields?.address
                      ? fields?.address
                      : null,
                    address2: item?.address2
                      ? item?.address2
                      : fields?.address2
                      ? fields?.address2
                      : null,
                    postal: item?.postal
                      ? item?.postal
                      : fields?.postal
                      ? fields?.postal
                      : null,
                    place: item?.place
                      ? item?.place
                      : fields?.place
                      ? fields?.place
                      : null,
                  },

              submit: null,
            }}
            validationSchema={Yup.object({
              name: Yup.string().required("Name is required"),
              mobile: Yup.string().required("Mobile is required"),
              // email: Yup.string().email("Invalid email address"),
            })}
            onSubmit={(values, helpers) => {
              try {
                toast.promise(createOrUpdate(values), {
                  loading: t("Saving...."),
                  success: (data) => {
                    if (data.status === 500) throw new Error("Server error");
                    if (data.status === 400) throw new Error("Server error");
                    if (data.data.errors)
                      throw new Error(mapErrors(data.data.errors));
                    // props.onRefresh(data.payload);
                    getItems();
                    if (data?.data?.id) {
                      handleSelectFromForm(data?.data);
                      setOpen(false);
                    }
                    return t("Data was saved!");
                  },
                  error: (error) => {
                    return t("Something went wrong. Could not save data!");
                  },
                });
                helpers.setStatus({ success: true });
                helpers.setSubmitting(false);
              } catch (err) {
                toast.error("Something went wrong...");
                console.error(err);
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
              }
            }}
          >
            {(formik) => (
              <Form>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="name"
                      type="text"
                      label={t("Name")}
                      {...formik}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="email"
                      type="text"
                      label={t("Email")}
                      {...formik}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="mobile"
                      type="text"
                      label={t("Mobile")}
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <span> Postal address</span>
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="postal_address.address"
                      type="text"
                      label={t("Address")}
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="postal_address.address_two"
                      type="text"
                      label={t("Address")}
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="postal_address.postal"
                      type="text"
                      label={t("Postal")}
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="postal_address.place"
                      type="text"
                      label={t("Place")}
                      {...formik}
                    />
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    onClick={formik.submitForm}
                  >
                    {item?.id ? t("update") : t("create")}{" "}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </>
    );
  };

  const getItem = (id) => {
    return axios({ method: "get", url: FETCH_URL + id })
      .then((res) => {
        if (res.data.id) {
          setLoading(false);
          setSelected({
            id: res.data?.id,
            field1: res.data?.displayTitle,
            syncStatus: res.data?.syncStatus,
          });
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err.data.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setLoading(false);
      });
  };

  const [controller, setController] = useState({
    query: query ? query : "",
    page: 1,
    sortBy: "updated_at",
    sort: "asc",
    filters: filters,
    view: null,
  });

  useEffect(() => {
    setController((prev) => ({ ...prev, query: query }));
  }, [query]);

  const getItems = (controller) => {
    setLoading(true);
    return axios({ method: "get", url: FETCH_URL, params: controller })
      .then((response) => {
        if (response.data.data) {
          var array = [
            { id: null, value: null, primary: "Ingen valgt", secondary: null },
          ];
          if (!response.data.data) return array;
          response.data.data.map((row, i) => {
            const rowParams = {
              value: row.id,
              id: row.id,
              primary: row?.displayTitle,
              secondary: row?.address + " " + row.postal + " " + row.place,
              object: row,
            };
            array.push(rowParams);
          });
          setItems(array);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        return error.response.data;
      });
  };

  const debouncedDispatchAction = debounce((controller) => {
    getItems(controller);
  }, 500);

  useEffect(() => {
    // Trigger the debounced dispatch action
    if (open) {
      debouncedDispatchAction(controller);
    }

    // Clean the debounced function on component unmount.
    return () => {
      debouncedDispatchAction.cancel();
    };
  }, [controller, open]);

  // useEffect(() => {
  //   if (values[name]) {
  //     getItem(values[name]);
  //   }
  // }, [items]);

  return (
    <>
      <PMSimpleSelectorFieldV2
        showField={showField}
        errors={errors}
        name={name}
        loading={loading}
        label={t(label)}
        labelAction={labelAction}
        options={items}
        selected={selected}
        onChange={handleChange}
        onQueryChange={(query) => setQuery(query)}
        createView={createView()}
        onClose={() => setOpen(!open)}
        open={open}
        searchQuery={searchQuery}
        disabled={props.disabled}
      />
      <CustomerDialog
        open={customerDialogOpen}
        onClose={() => setCustomerDialogOpen(false)}
        readOnly={true}
        // selected={{ report.customer }}
        // Only for testing
        selected={{ id: selected?.id }}
        // onRefresh={() => getCase()}
      />
    </>
  );
};

CustomerSelector.defaultProps = {
  showField: true,
};
