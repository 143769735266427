import axios from "./axios";

export async function fetchProjects(controller) {
  return axios({
    method: "get",
    url: "/projects",
    params: controller,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchProject(id) {
  return axios.get(`/projects/` + id);
}

export async function createProject(payload) {
  return axios
    .post(`/projects`, { project: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
export async function updateProject(payload) {
  return axios
    .put(`/projects/${payload.id}`, { project: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function deleteProject(payload) {
  const project = payload.project;
  return axios
    .delete(`/projects/${project.project_id}`, {
      data: JSON.stringify({
        project,
      }),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
