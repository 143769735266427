import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createChecklistType,
  deleteChecklistType,
  fetchChecklistType,
  fetchChecklistTypes,
  updateChecklistType,
} from "../../api/quality/checklistType";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  checklist_type: {},
};

export const checklistTypesController = {
  filters: [],
  page: 0,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const deleteChecklistTypeAsync = createAsyncThunk(
  "checklist_types/deleteChecklistTypeAsync",
  async (payload) => {
    let response = await deleteChecklistType(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createChecklistTypeAsync = createAsyncThunk(
  "checklist_types/createChecklistTypeAsync",
  async (payload) => {
    let response = await createChecklistType(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const updateChecklistTypeAsync = createAsyncThunk(
  "checklist_types/updateChecklistTypeAsync",
  async (payload) => {
    let response = await updateChecklistType(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);
export const getChecklistTypes = createAsyncThunk(
  "checklist_types/getChecklistTypes",
  async (controller = checklistTypesController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchChecklistTypes(controller);

    // If errors, reject and return response
    if (response.error) return rejectWithValue(response);

    // If all good, return response.
    return response;
  }
);

const checklistTypeSlice = createSlice({
  name: "checklist_types",
  initialState,
  reducers: {
    resetChecklistType(state) {
      state.checklist_type = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChecklistTypes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getChecklistTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(getChecklistTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong....is controller missing?"];
      })
      .addCase(updateChecklistTypeAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateChecklistTypeAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data[index] = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(updateChecklistTypeAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update checklist type",
        ];
      })
      .addCase(createChecklistTypeAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createChecklistTypeAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
        });
      })
      .addCase(createChecklistTypeAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update checklist type",
        ];
      })
      .addCase(deleteChecklistTypeAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteChecklistTypeAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(deleteChecklistTypeAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update checklist type",
        ];
      });
  },
});

export const { reducer } = checklistTypeSlice;

export const { resetChecklistType } = checklistTypeSlice.actions;

export default checklistTypeSlice;
