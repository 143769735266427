import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { PMField } from "../../utils/PMField";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { mapErrors } from "../../Utils";
import {
  createProjectAsync,
  updateProjectAsync,
} from "../../../slices/project";
import { fetchCustomer, fetchProject } from "../../../api/project";
import {
  ArrowRightIcon,
  CheckBadgeIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CustomerSelector } from "../../utils/moduleSelectors/customerSelector";
import { DeliveryAddressSelector } from "../../utils/moduleSelectors/deliveryAddressSelector";
import { QuillEditor } from "../../utils/quill-editor";
import { DepartmentSelector } from "../../utils/moduleSelectors/departmentSelector";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { EmployeeSelector } from "../../utils/moduleSelectors/employeeSelector";
import { useSelector } from "react-redux";
export default function ProjectDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    project: {},
  });

  // Deconstructing props
  const { open, onClose, selected, onRefresh, customer } = props;

  // Deconstruct itemState (Store)
  const { project, isLoading, error, errors } = itemState;

  const [readOnly, setReadOnly] = useState(props.readOnly);

  const [selectedDepartment, setSelectedDepartment] = useState(
    project?.department?.id ? project?.department : null
  );

  const currentUser = useSelector((state) => state.session.currentUser);

  const getCustomer = async () => {
    return fetchProject(selected.id)
      .then((res) => {
        if (res.data.id) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            project: res.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err.data.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          project: {},
          error: true,
          errors: errors,
        }));
      });
  };

  useEffect(() => {
    if (open) {
      setItemState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      if (selected?.id) {
        // getReport();
      } else {
        setTimeout(() => {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
        }, 500);
      }
    } else {
      setItemState((prevState) => ({
        ...prevState,
        project: {},
      }));
    }
  }, [dispatch, open]);

  const createOrUpdateDispatch = (data) => {
    if (project?.id) {
      const res = dispatch(updateProjectAsync(data));
      return res;
    } else {
      const res = dispatch(createProjectAsync(data));
      return res;
    }
  };

  const [optionalProjectNumber, setOptionalProjectNumber] = useState(true);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="flex items-center text-lg font-medium leading-6 text-gray-900"
                  >
                    <div className="w-[60%]">
                      {readOnly
                        ? t("project")
                        : project?.id
                        ? t("projects.edit")
                        : t("projects.create")}
                    </div>
                    <div className="w-[40%] relative right-0 text-right">
                      {project?.id && props.module !== "project" && (
                        <button
                          onClick={() =>
                            navigate(`/dashboard/projects/` + project?.id)
                          }
                          type="button"
                          className="mr-2 inline-flex items-center rounded-md border border-transparent bg-blue-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <ArrowRightIcon
                            className="-ml-0.5 mr-2 h-4 w-4"
                            aria-hidden="true"
                          />
                          Gå til prosjekt
                        </button>
                      )}
                      <button
                        onClick={() => setReadOnly(!readOnly)}
                        type="button"
                        className="inline-flex items-center rounded-md border border-transparent bg-blue-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        {readOnly ? (
                          <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                        ) : (
                          <PencilIcon
                            className="h-4 w-4 color-"
                            aria-hidden="true"
                          />
                        )}
                        <span className="ml-2">
                          {readOnly ? t("edit") : t("read_only")}
                        </span>
                      </button>
                    </div>
                  </Dialog.Title>

                  {isLoading ? (
                    <ResourceLoading />
                  ) : (
                    <Formik
                      initialValues={{
                        id: project?.id,
                        project_number: project?.project_number,
                        project_manager_id: project?.project_manager?.id,
                        name: project?.name,
                        customer_id: project?.customer?.id || customer?.id,
                        delivery_address_id: project?.delivery_address?.id,
                        start_date: project?.start_date,
                        end_date: project?.end_date,
                        nextProjectNumber: project?.nextProjectNumber || true,
                        // active: project?.active || true,
                        submit: null,
                      }}
                      validationSchema={Yup.object({
                        project_number: Yup.string(),
                      })}
                      onSubmit={(values, helpers) => {
                        try {
                          toast.promise(createOrUpdateDispatch(values), {
                            loading: t("Saving...."),
                            success: (data) => {
                              if (data?.payload?.id) {
                                onClose(true);
                                onRefresh();
                                return t("Data was saved!");
                              } else {
                                throw new Error("Server error");
                              }
                            },
                            error: (error) => {
                              console.log(error);
                              return t(
                                "Something went wrong. Could not save data!"
                              );
                            },
                          });
                          // helpers.setStatus({ success: true });
                        } catch (err) {
                          toast.error("Something went wrong...");
                          helpers.setStatus({ success: false });
                          helpers.setErrors({ submit: err.message });
                          // helpers.setSubmitting(false);
                        }
                      }}
                    >
                      {(formik) => (
                        <Form>
                          <div className="grid grid-cols-6 gap-6 mt-10">
                            <div className="col-span-6 sm:col-span-2">
                              <div>
                                <div>
                                  {!formik?.values?.project_number ? (
                                    <PMField
                                      name="nextProjectNumber"
                                      type="checkbox"
                                      label={t("Next Number")}
                                      // onChange={() =>
                                      //   setOptionalProjectNumber(
                                      //     !optionalProjectNumber
                                      //   )
                                      // }
                                      {...formik}
                                    />
                                  ) : null}
                                </div>
                                <div className="mt-2">
                                  {formik?.values?.project_number ||
                                  !formik.values?.nextProjectNumber ? (
                                    <PMField
                                      name="project_number"
                                      type="text"
                                      label={t("project.number")}
                                      {...formik}
                                    />
                                  ) : null}
                                </div>
                              </div>
                            </div>

                            <div className="col-span-6 sm:col-span-6">
                              <PMField
                                name="name"
                                type="text"
                                label={t("project.name")}
                                readOnly={readOnly}
                                {...formik}
                              />
                            </div>

                            {!props?.customer && (
                              <div className="col-span-6 sm:col-span-3">
                                <CustomerSelector
                                  selected={
                                    project?.customer?.id
                                      ? project?.customer
                                      : customer
                                  }
                                  {...formik}
                                />
                              </div>
                            )}

                            <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                              <DepartmentSelector
                                selected={selectedDepartment}
                                {...formik}
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <DeliveryAddressSelector
                                module="CUSTOMER"
                                module_param={formik.values.customer_id}
                                // fields={fields}
                                selected={project?.delivery_address}
                                {...formik}
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <EmployeeSelector
                                name="project_manager_id"
                                selected={
                                  project?.project_manager?.id
                                    ? project?.project_manager
                                    : currentUser
                                }
                                {...formik}
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <PMField
                                name="start_date"
                                type="date"
                                label={t("project.start_date")}
                                readOnly={readOnly}
                                {...formik}
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <PMField
                                name="end_date"
                                type="date"
                                label={t("project.end_date")}
                                readOnly={readOnly}
                                {...formik}
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-6">
                              <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700 mb-2"
                              >
                                <label> {t("project.description")} </label>
                              </label>
                              <QuillEditor
                                name="description"
                                rows={4}
                                {...formik}
                              />
                            </div>
                          </div>

                          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                            <button
                              type="button"
                              className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                              onClick={onClose}
                            >
                              {t("Close")}{" "}
                            </button>

                            {readOnly ? null : (
                              <LoadingButton
                                loading={isLoading}
                                disabled={isLoading}
                                loadingPosition="start"
                                startIcon={<CheckBadgeIcon />}
                                type="button"
                                variant="contained"
                                color={"success"}
                                onClick={formik.submitForm}
                              >
                                {project.id ? t("Update") : t("Create")}
                              </LoadingButton>
                            )}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

ProjectDialog.defaultProps = {
  selected: null,
  // Read only is used when only wanting to display info. Update or creating will not work.
  readOnly: false,
  open: false,
  onRefresh: () => null,
  onClose: () => toast.error("Missing onClose function. Cannot close dialog"),
};
