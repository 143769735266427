import produce from "immer";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  fetchProductBrands,
  updateProductBrand,
  createProductBrand,
  deleteProductBrand
} from '../../api/product/brand'

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
};

export const defaultController = {
  filters: [],
  page: 0,
  per_page: 50,
  query: '',
  sort: 'desc',
  sortBy: 'created_at',
  view: 'all'
}

export const deleteProductBrandAsync = createAsyncThunk(
  'product_brands/deleteProductBrandAsync',
  async (payload) => {


    let response = await deleteProductBrand(payload);

    // If errors, reject and return response
    if (response.error) return false

    // If all good, return response.
    return response;
  }
)

export const createProductBrandAsync = createAsyncThunk(
  'product_brands/createProductBrandAsync',
  async (payload) => {

    let response = await createProductBrand(payload);

    // If errors, reject and return response
    if (response.error) return false

    // If all good, return response.
    return response;
  }
)

export const updateProductBrandAsync = createAsyncThunk(
  'product_brands/updateProductBrandAsync',
  async (payload) => {

    let response = await updateProductBrand(payload);

    // If errors, reject and return response
    if (response.error) return false

    // If all good, return response.
    return response;
  }
)
export const getProductBrands = createAsyncThunk(
  'product_brands/getProductBrands',
  async (controller = defaultController, { rejectWithValue }) => {

    // if (! controller) return false;
    let response = await fetchProductBrands(controller);

    // If errors, reject and return response
    if (response.error) return rejectWithValue(response);

    // If all good, return response.
    return response;
  })




const productBrandsSlice = createSlice({
  name: 'product_brands',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getProductBrands.pending, (state, action) => {
      state.isLoading = true;
    })
      .addCase(getProductBrands.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(getProductBrands.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ['Something went wrong....is controller missing?']
      })
      .addCase(updateProductBrandAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateProductBrandAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(type => type.id === action.payload.id);
          draftState.data[index] = action.payload;
          draftState.isLoading = false;
        })
      })
      .addCase(updateProductBrandAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ['Something went wrong when trying to update report type']
      })
      .addCase(createProductBrandAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createProductBrandAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload)
          draftState.isLoading = false;
        })
      })
      .addCase(createProductBrandAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ['Something went wrong when trying to update report type']
      })
      .addCase(deleteProductBrandAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteProductBrandAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data = action.payload;
          draftState.isLoading = false;
        })
      })
      .addCase(deleteProductBrandAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ['Something went wrong when trying to update report type']
      })
  }
})


// export const {} = customerSlice.actions;

export const { reducer } = productBrandsSlice;

// export const {  } = productBrandsSlice.actions

export default productBrandsSlice

