import React, { component, useEffect, useState } from "react";
import { ProductQR } from "../../../components/dashboard/products/product-qr";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ResourceLoading } from "../../../components/utils/ResourceLoading";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerAsync } from "../../../slices/vehicle";
import { ResourceError } from "../../../components/utils/ResourceError";
import { Grid } from "@mui/material";
import PMBigActionCard from "../../../components/utils/PMLinkCard";
import PMLinkCard from "../../../components/utils/PMLinkCard";
import {
  BuildingOffice2Icon,
  Cog6ToothIcon,
  UsersIcon,
  BanknotesIcon,
  PaperClipIcon,
  PlusIcon,
  ArrowPathRoundedSquareIcon,
} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import { ResourceEmpty } from "../../../components/utils/ResourceEmpty";
import { fetchCustomer } from "../../../api/vehicle";
import { mapErrors } from "../../../components/Utils";
import CaseDialog from "../../../components/dashboard/cases/case-dialog";
import { CasesStackList } from "../../../components/dashboard/cases/cases-stack-list";
import ServiceReportEditModal from "../../../components/dashboard/service/reports/service-report-edit-modal";
import { ReportsStackList } from "../../../components/dashboard/service/reports/reports-stack-list";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import { fetchVehicle } from "../../../api/vehicle";
import { VehicleInfo } from "../../../components/dashboard/vehicles/vehicle-info";
import { HistoriesModalButton } from "../../../components/dashboard/histories/modal-button";

export const Vehicle = (props) => {
  const [caseDialogOpen, setCaseDialogOpen] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    vehicle: {},
  });

  // Deconstruct itemState (Store)
  const { isLoading, error, errors, vehicle } = itemState;

  const item = vehicle;

  useEffect(() => {
    getCustomer();
  }, [dispatch]);

  const getCustomer = async () => {
    return fetchVehicle(id)
      .then((res) => {
        if (res.data.id) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            vehicle: res.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err.data.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          vehicle: {},
          error: true,
          errors: errors,
        }));
      });
  };

  const [selectedTab, setSelectedTab] = useState(0);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);

  const tabs = [
    { name: "Detaljer", href: "#", component: <VehicleInfo item={item} /> },
    { name: "Trips", href: "#", component: <VehicleInfo item={item} /> },
  ];

  const headerActions = [
    {
      component: null,
      label: "Sync with Automile",
      description: "",
      path: null,
      action: () => toast.success("Hej Robin"),
      icon: ArrowPathRoundedSquareIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: true,
    },
    {
      component: (
        <HistoriesModalButton module={"vehicle"} module_param={item?.id} />
      ),
      label: "History",
      description: "",
      path: null,
      action: () => toast.success("Hej Robin"),
      icon: ArrowPathRoundedSquareIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: false,
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function vehicleTabs() {
    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            onChange={(e) => setSelectedTab(e.target.value)}
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={tabs[selectedTab].name}
          >
            {tabs.map((tab, tabIdx) => (
              <option key={tab.name} value={tabIdx}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav
            className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
            aria-label="Tabs"
          >
            {tabs.map((tab, tabIdx) => (
              <a
                key={tab.name}
                onClick={() => setSelectedTab(tabIdx)}
                className={classNames(
                  tabIdx === selectedTab
                    ? "text-gray-900"
                    : "text-gray-500 hover:text-gray-700",
                  tabIdx === 0 ? "rounded-l-lg" : "",
                  tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                  "cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                )}
                aria-current={tabIdx === selectedTab ? "page" : undefined}
              >
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    tabIdx === selectedTab ? "bg-indigo-500" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </a>
            ))}
          </nav>
        </div>
      </div>
    );
  }

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="">
          <ResourceLoading />
        </div>
      );
    }
    if (error && errors) {
      return <ResourceError errors={errors} />;
    }

    if (!item?.id) {
      return <ResourceEmpty />;
    }

    return (
      <>
        <DashboardHeader
          subtitle=""
          actions={headerActions}
          title={vehicle?.number_plate + " - " + vehicle?.model}
          meta={[{ label: "test", icon: Cog6ToothIcon }]}
          mainButton={{
            label: "New",
            action: () => setCaseDialogOpen(true),
            path: null,
            color: "",
            icon: PlusIcon,
          }}
        >
          <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
              <Grid container spacing={3}>
                <Grid
                  container
                  item
                  lg={8}
                  spacing={3}
                  sx={{ height: "fit-content", order: { md: 2, xs: 1 } }}
                >
                  <Grid item xs={12}>
                    {vehicleTabs()}
                    {tabs[selectedTab].component}
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  lg={4}
                  spacing={3}
                  sx={{ height: "fit-content", order: { md: 2, xs: 1 } }}
                ></Grid>
              </Grid>
            </div>
          </div>
        </DashboardHeader>
      </>
    );
  };

  return <>{renderContent()}</>;
};
