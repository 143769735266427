import { useEffect, useState } from "react";
import { checkProductNumber } from "../../../api/product";
import { PMDialog } from "../../utils/PMDialog";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { productPath } from "../../Utils";
import { Link } from "react-router-dom";
import { PMNotice } from "../../utils/notifications";
import { debounce } from "lodash";

export const ProductNumberCheck = (props) => {
  const { number } = props;

  const [numberTaken, setNumberTaken] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [product, setProduct] = useState(null);

  const checkNumber = () => {
    checkProductNumber({ item_number: number, fields: "id,displayTitle" }).then(
      (response) => {
        if (response?.data?.error === true) {
          setNumberTaken(true);
          setProduct(response?.data?.product);
        } else {
          setNumberTaken(false);
        }
      }
    );
  };

  const debouncedAction = debounce((controller) => {
    checkNumber();
  }, 1000);

  useEffect(() => {
    if (number) debouncedAction();

    // Clean the debounced function on component unmount.
    return () => {
      debouncedAction.cancel();
    };
  }, [number]);

  useEffect(() => {
    if (numberTaken) setDialogOpen(true);
    return () => {};
  }, [numberTaken]);

  return (
    <>
      {numberTaken && (
        <span className="mt-2 flex italic text-red-400 text-xs">
          <ExclamationCircleIcon className="h-4 w-4 mr-1" />
          Nummer er opptatt!
        </span>
      )}

      <PMDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <div className="mt-2">
          <PMNotice
            type="error"
            title={"Nummer " + number + " er opptatt"}
            description="Velg et annet nummer eller benytt eksisterende produkt"
          />

          <div className="flex justify-between mt-2">
            <button
              type="button"
              onClick={() => setDialogOpen(false)}
              className="px-4 py-2 bg-gray-400 rounded-md text-white"
            >
              Forsett redigering
            </button>
            <Link
              to={productPath({ product: product })}
              className="px-4 py-2 bg-blue-400 rounded-md text-white"
            >
              Gå til produkt
            </Link>
          </div>
        </div>
      </PMDialog>
    </>
  );
};
