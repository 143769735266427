import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { ResourceError } from "../../utils/ResourceError";
import { ResourceEmpty } from "../../utils/ResourceEmpty";
import { toast } from "react-hot-toast";
import { fetchTextMessages } from "../../../api/sms";
import {
  CheckBadgeIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { CopyToClipboard } from "../../utils/Div";
import SmsHistoriesDialog from "./histories-dialog";

export default function SMSDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    report: {},
  });

  const { open, onClose, selected, onRefresh, module, module_param } = props;

  const { report, isLoading, error, errors } = itemState;

  const [controller, setController] = useState({
    filters: [{ name: "report", value: module_param }],
    page: 1,
    per_page: 10,
    query: "",
    sort: "desc",
    sortBy: "id",
    view: "all",
  });

  async function fetchSMS() {
    setItemState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    // Fetch Case types from API?

    await fetchTextMessages(controller)
      .then((res) => {
        if (res.data) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            error: false,
            data: res?.data?.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          error: true,
          errors: errors,
        }));
      });

    // Map into an array with ID and title?

    // Return the array
  }

  useEffect(() => {
    if (open) {
      if (module && module_param) {
        fetchSMS();
      } else {
        toast.error("Missing module && module_param");
        props.onClose();
      }
    }
  }, [open]);

  const [historiesOpen, setHistoriesOpen] = useState(false);
  const [selectedHistories, setSelectedHistories] = useState(null);

  function handleHistoriesOpen(histories) {
    setSelectedHistories(histories);
    setHistoriesOpen(true);
  }

  useEffect(() => {
    if (!historiesOpen) {
      setSelectedHistories(null);
    }
  }, [historiesOpen]);

  function MessageItem({ item }) {
    function formattedDate(date) {
      return new Date(date).toLocaleString();
    }

    const answers = item?.answers;

    // For testing purpose
    // const answers = [
    //   {
    //     id: 1,
    //     number: "1234567890",
    //     message: "Hello, this is a test message",
    //     created_at: "2021-09-01T12:00:00.000Z",
    //   },
    // ];

    return (
      <>
        <li
          key={item.id}
          className="overflow-hidden rounded-md bg-white px-6 py-4 shadow"
        >
          <div className="italic text-sm flex justify-between">
            <div className="text-gray-800">
              Message was sent to: <span className="">{item?.number}</span> at{" "}
              <time>{formattedDate(item?.created_at)}</time>
            </div>
            <div className="flex">
              <div className="mr-2">
                {/* See histories button start */}
                <button
                  type="button"
                  className="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700"
                  onClick={() => handleHistoriesOpen(item?.histories)}
                >
                  See histories
                </button>
                {/* See histories button end */}
              </div>
              <div>
                {item?.status === true ? (
                  <CheckCircleIcon
                    className="h-5 w-5 text-green-500"
                    aria-hidden="true"
                  />
                ) : (
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="mt-2">
            <span className="">{item?.message}</span>
          </div>

          {!answers.length > 0 && (
            <div className="mt-2">
              <span className="italic text-xs text-gray-400">
                Ingen svar fra mottaker
              </span>
            </div>
          )}
        </li>

        {answers && answers?.length > 0 && (
          <ul className="mt-2 space-y-3">
            {answers?.map((answer) => (
              <li
                key={answer.id}
                className="overflow-hidden rounded-md bg-white px-6 py-4 ml-5 shadow"
              >
                <div className="italic text-sm flex justify-between">
                  <div className="text-gray-800">
                    Message reccived from:{" "}
                    <span className="">{answer?.number}</span> at{" "}
                    <time>{formattedDate(answer.created_at)}</time>
                  </div>
                  <div className="flex">
                    <div>
                      {item?.status === true ? (
                        <CheckCircleIcon
                          className="h-5 w-5 text-green-500"
                          aria-hidden="true"
                        />
                      ) : (
                        <ExclamationCircleIcon
                          className="h-5 w-5 text-red-500"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <span className="">{answer?.message}</span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </>
    );
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      SMS
                    </Dialog.Title>

                    {isLoading ? (
                      <ResourceLoading />
                    ) : error ? (
                      <ResourceError errors={errors} />
                    ) : !itemState?.data ? (
                      <ResourceEmpty />
                    ) : (
                      <ul role="list" className="mt-4 space-y-3">
                        {itemState?.data?.map((item) => (
                          <MessageItem key={item?.id} item={item} />
                        ))}
                      </ul>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <SmsHistoriesDialog
        items={selectedHistories}
        open={historiesOpen}
        onClose={() => setHistoriesOpen(false)}
        onRefresh={() => props.onRefresh()}
      />
    </>
  );
}

SMSDialog.defaultProps = {
  onRefresh: null,
};
