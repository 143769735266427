import { PaperClipIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { MapIcon, PencilSquareIcon } from "@heroicons/react/24/solid";
import { fullDeliveryAddress } from "../../../Utils";

export const DeliveryAddressSection = (props) => {
  const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
  const [addressDialogOpen, setReportDialogOpen] = useState(false);

  const { address } = props;

  const FULL_ADDRESS =
    address?.address + ", " + address?.postal + " " + address?.place;
  const isAddress = () => {
    if (address?.address) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {/* Description list*/}
      <section
        className="lg:max-w[7xl]"
        aria-labelledby="applicant-information-title"
      >
        <div className="bg-white shadow sm:rounded-lg">
          <div className="flex items-center px-4 py-5 sm:px-6 w-full">
            <div className="block w-[50%]">
              <h2
                id="applicant-information-title"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Address
              </h2>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {address?.id ? fullDeliveryAddress(address) : "Ikke valgt"}
              </p>
            </div>
            <div className="relative right-0 text-right w-[50%]">
              {address?.id && (
                <a
                  aria-disabled={isAddress()}
                  href={
                    "https://www.google.com/maps?q=" +
                    fullDeliveryAddress(address)
                  }
                  rel="noreferrer"
                  target="_blank"
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-blue-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <MapIcon className="h-4 w-4" aria-hidden="true" />
                  <span className="ml-2">Google</span>
                </a>
              )}
              {/* 
              <button
                onClick={() => setReportDialogOpen(true)}
                type="button"
                className="ml-2 inline-flex items-center rounded-md border border-transparent bg-blue-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <PencilSquareIcon className="h-4 w-4" aria-hidden="true" />
              </button> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
