import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ProductsFilter } from "../../../components/dashboard/products/products-filter";
import { ProductsStats } from "../../../components/dashboard/products/products-stats";
import { ProductsTable } from "../../../components/dashboard/products/products-table";
import { getProductsAsync } from "../../../slices/products";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import {
  ArrowPathRoundedSquareIcon,
  ArrowUpIcon,
  CheckBadgeIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import ProductModal from "../../../components/dashboard/products/ProductModal";
import SimplePrompt from "../../../components/utils/simplePrompt";
import { mapErrors, pageOptions } from "../../../components/Utils";
import { toast } from "react-hot-toast";
import { syncModule } from "../../../api/company";
import { ProductActionEditBar } from "../../../components/dashboard/products/product-action-edit-bar";
import ProductMultiEditDialog from "../../../components/dashboard/products/product-multi-edit-dialog";
import ProductPriceChangeDialog from "../../../components/dashboard/products/product-price-change-dialog";
import { getVehiclesAsync } from "../../../slices/vehicle";
import { VehiclesTable } from "../../../components/dashboard/vehicles/vehicles-table";

export const VehiclesList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const vehiclesState = useSelector((store) => {
    return store.vehicles;
  });

  const { isLoading } = vehiclesState;
  const [selectedItems, setSelectedItems] = useState([]);
  const [controller, setController] = useState({
    filters: [],
    page: searchParams.get("page") ? searchParams.get("page") : 1,
    per_page: searchParams.get("per_page") ? searchParams.get("per_page") : 250,
    query: searchParams.get("query") ? searchParams.get("query") : "",
    sort: searchParams.get("sort") ? searchParams.get("sort") : "asc",
    sortBy: searchParams.get("sortBy")
      ? searchParams.get("sortBy")
      : "created_at",
    view: searchParams.get("view") ? searchParams.get("view") : "all",
  });

  const updateSearchParams = (controller) => {
    setSearchParams(controller);
  };

  useEffect(() => {
    updateSearchParams({
      page: controller.page,
      per_page: controller.per_page,
      query: controller.query,
      sort: controller.sort,
      sortBy: controller.sortBy,
      view: controller.view,
    });
  }, [controller]);

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery,
    });
  };

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handlePagePageChange = (option) => {
    setController({
      ...controller,
      per_page: option,
    });
  };

  useEffect(() => {
    const payload = {
      filters: controller.filters,
      page: controller.page,
      per_page: controller.per_page,
      query: controller.query,
      sort: controller.sort,
      sortBy: controller.sortBy,
      view: controller.view,
    };
    dispatch(getVehiclesAsync(payload));
  }, [dispatch, controller]);

  const [editModalOpen, setEditModalOpen] = useState(false);

  const headerActions = [];

  const headerMeta = [
    {
      label: vehiclesState.count + " Products",
      description: "wdw",
      path: null,
      action: null,
      icon: CheckBadgeIcon,
      textColor: "text-teal-700",
      hidden: false,
    },
    {
      label: vehiclesState.newestProductsCount + " New products",
      description: "wdw",
      path: null,
      action: null,
      icon: ArrowUpIcon,
      textColor: "text-teal-700",
      hidden: false,
    },
    {
      label: vehiclesState.synchedWithApiCount + " Synched with API",
      description: "wdw",
      path: null,
      action: null,
      icon: ArrowUpIcon,
      textColor: "text-teal-700",
      hidden: false,
    },
  ];

  const [multiEditDialogOpen, setMultiEditDialogOpen] = useState(false);
  const [priceChangeDialogOpen, setPriceChangeDialogOpen] = useState(false);

  const sortByOptions = [
    { name: "Seneste (Rapportnr.)", sort: "asc", sortBy: "report_number" },
    { name: "Eldste (Rapportnr.)", sort: "desc", sortBy: "report_number" },
  ];

  const initialFilters = [];

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={"Vehicles"}
        meta={headerMeta}
        mainButton={{
          label: "New",
          action: () => setEditModalOpen(true),
          path: null,
          color: "",
          icon: PlusIcon,
        }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            {/* <ProductsStats
              totalProductsCount={vehiclesState.count}
              newestProductsCount={vehiclesState.newestProductsCount}
            /> */}

            <ProductsFilter
              className={"mt-10"}
              disabled={isLoading}
              onQueryChange={handleQueryChange}
              query={controller.query}
              selectedItems={selectedItems}
              sortByOptions={sortByOptions}
              perPageOptions={[10, 25, 50, 100, 250]}
              onPerPageChange={handlePagePageChange}
              pageOptions={pageOptions({
                perPage: controller?.per_page,
                totalCount: vehiclesState?.totalCount,
              })}
              onPageChange={handlePageChange}
              initialFilters={initialFilters}
              actionBar={
                <ProductActionEditBar
                  selectedItems={selectedItems}
                  setMultiEditDialogOpen={() => setMultiEditDialogOpen(true)}
                  setPriceChangeDialogOpen={() =>
                    setPriceChangeDialogOpen(true)
                  }
                />
              }
            />

            <VehiclesTable
              onItemsSelect={(items) => setSelectedItems(items)}
              itemState={vehiclesState}
              itemsCount={vehiclesState.count}
              currentCount={vehiclesState.currentCount}
              page={controller.page}
              onPageChange={handlePageChange}
              perPage={controller.per_page}
            />
          </div>
        </div>
      </DashboardHeader>

      <ProductModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
      />

      <ProductMultiEditDialog
        selectedItems={selectedItems}
        open={multiEditDialogOpen}
        onClose={() => setMultiEditDialogOpen(false)}
      />

      <ProductPriceChangeDialog
        selectedItems={selectedItems}
        open={priceChangeDialogOpen}
        onClose={() => setPriceChangeDialogOpen(false)}
      />
    </>
  );
};
