import axios from "./axios";

export async function createDepartment(payload) {
  return axios
    .post(`/departments`, { department: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function updateDepartment(payload) {
  return axios
    .put(`/departments/` + payload.id, { department: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // return error.response.data
      if (error.status !== 200) return error;
      else return error.response;
    });
}

export async function fetchDepartments(controller) {
  return axios({ method: "get", url: "/departments", params: controller })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}
