import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { CalendarIcon, MapPinIcon, UsersIcon } from "@heroicons/react/20/solid";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { ResourceEmpty } from "../../utils/ResourceEmpty";
import { ResourceError } from "../../utils/ResourceError";
import { BuildingOffice2Icon, WrenchIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { getOrdersAsync } from "../../../slices/order";
import { OrderStatus } from "./utils";
import { ProjectTag } from "../../utils/moduleTags/projectTag";
import { CustomerTitle, DeliveryAddressTitle, ProjectTitle } from "../../Utils";

export const OrdersStacklist = (props) => {
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const itemState = useSelector((store) => {
    return store.orders;
  });

  const { isLoading, data, error, errors } = itemState;

  const [controller, setController] = useState({
    filters: [...props.filters],
    page: 1,
    query: "",
    sort: "desc",
    sortBy: "updated_at",
    view: "all",
  });

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery,
    });
  };

  useEffect(() => {
    const payload = {
      filters: controller.filters,
      page: controller.page,
      query: controller.query,
      sort: controller.sort,
      sortBy: controller.sortBy,
      view: controller.view,
    };
    dispatch(getOrdersAsync(payload));
  }, [dispatch, controller]);

  const navigate = useNavigate();

  if (isLoading)
    return (
      <div className="mt-10">
        <ResourceLoading />
      </div>
    );
  if (error) return <ResourceError errors={errors} />;
  if (!data) return <ResourceEmpty />;

  return (
    <>
      {data && (
        <div className="overflow-hidden bg-white shadow sm:rounded-md mt-5">
          <ul role="list" className="divide-y divide-gray-200">
            {data?.map((item) => (
              <li
                key={item?.id}
                onClick={() => navigate("/dashboard/orders/" + item?.id)}
              >
                <a href="#" className="block hover:bg-gray-50">
                  <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <div className="grid">
                        <p className="truncate text-sm font-medium text-blue-400">
                          {item?.displayTitle || "Missing??"}
                        </p>
                      </div>
                      <p className="truncate text-sm font-medium text-indigo-600"></p>

                      <div className="ml-2 flex flex-shrink-0"></div>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="sm:flex">
                        <p className="flex items-center text-sm text-gray-500">
                          <UsersIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <CustomerTitle customer={item?.customer} />
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                          <BuildingOffice2Icon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <ProjectTitle project={item?.project} />
                        </p>

                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                          <MapPinIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {DeliveryAddressTitle({
                            deliveryAddress: item?.delivery_address,
                          })}
                        </p>

                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                          <OrderStatus order={item} />
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

OrdersStacklist.defaultProps = {
  page: 1,
  itemState: [],
  itemsCount: 0,
  selectedItems: [],
  sort: "desc",
  sortBy: "createdAt",
};
