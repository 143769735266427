import { useTranslation } from "react-i18next";
import { PMSimpleSelectorField } from "../PMSimpleSelector/PMSimpleSelectorField";
import { PMSimpleSelectorFieldV2 } from "../PMSimpleSelector/PMSimpleSelectorFieldV2";
import axios from "../../../api/axios";
import { useState, useEffect } from "react";
import { mapErrors } from "../../Utils";
import { Form, Formik } from "formik";
import { ResourceLoading } from "../ResourceLoading";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { PMField } from "../PMField";
import { fetchSystemTypes } from "../../../api/customer/system";
import ItemSelector from "../Selector/ItemSelector";
import PMListbox from "../PMListbox";
import { debounce } from "lodash";

export const SystemSelector = (props) => {
  const {
    setFieldValue,
    values,
    onChange,
    errors,
    module,
    module_param,
    fields,
  } = props;

  const name = "system_id";
  const label = props.label ? props.label : "System";
  const FETCH_URL = `/customers/${module_param}/systems/`;
  const CREATE_URL = `/customers/${module_param}/systems/`;
  const filters = null;

  const { t } = useTranslation();

  const [query, setQuery] = useState();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({
    id: 0,
    field1: "Ingen valgt",
    field2: null,
  });
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);

  const labelAction = {
    // path: "/dashboard/projects/" + selected?.id,
    // action: () => toast("test"),
  };

  const handleChange = (data) => {
    // getItem(data.selected.item.id);

    setSelected({
      id: data?.selected?.item?.id,
      field1: data?.selected?.item?.primary,
    });

    setOpen(false);
    if (values) {
      setFieldValue(name, data.selected.item.id);
    } else {
      onChange({ target: { name: name, value: data.selected.item.id } });
    }
  };

  const handleSelectFromForm = (data) => {
    setSelected({
      id: data?.id,
      field1: data?.displayTitle,
    });
    if (values) {
      setFieldValue(name, data?.id);
    } else {
      onChange({ target: { name: name, value: data?.id } });
    }
  };

  const createView = (item) => {
    const [typesState, setTypesState] = useState({
      isLoading: true,
      systemTypes: [],
    });
    const { systemTypes } = typesState;

    const createOrUpdate = (values) => {
      const data = {
        ...values,
        module: module,
        module_param: module_param,
      };
      return axios.post(CREATE_URL, data).then((res) => {
        return res;
      });
    };

    const getSystemTypes = async () => {
      return fetchSystemTypes()
        .then((res) => {
          if (res.data) {
            setTypesState((prevState) => ({
              ...prevState,
              isLoading: false,
              systemTypes: res.data,
            }));
          }
        })
        .catch((err) => {
          let errors = null;
          if (err?.data?.errors) {
            errors = mapErrors(err.data.errors);
          } else {
            errors = ["Something went wrong"];
          }
          setTypesState((prevState) => ({
            ...prevState,
            isLoading: false,
            systems: {},
            error: true,
            errors: errors,
          }));
        });
    };

    const systemTypeOptions = () => {
      const types = [{ name: "system_type", label: "Ikke valgt", value: null }];
      systemTypes?.map((type) => {
        types.push({
          name: "system_type",
          label: type.title,
          value: type.id,
        });
      });
      return types;
    };

    const [productSelectorOpen, setProductSelectorOpen] = useState(false);

    const handleRowProductSelect = () => {
      setProductSelectorOpen(true);
    };

    const handleProductSelect = (data, formik) => {
      const { setFieldValue } = formik;
      const product = data[0];
      setProductSelectorOpen(false);
      if (product) {
        setFieldValue("product_id", product.id);
        setFieldValue("product", product);
      }
    };

    const handleSystemTypeChange = (data, formik) => {
      formik.setFieldValue("system_type", data);
      formik.setFieldValue("system_type_id", data.value);
    };

    useEffect(() => {
      getSystemTypes();
    }, [open]);

    useEffect(() => {
      if (props?.selected?.id) {
        setSelected({
          id: props.selected?.id,
          field1: props.selected?.displayTitle,
        });
      } else {
        setSelected({
          id: null,
          field1: "Ingen valgt",
          field2: null,
        });
      }
    }, [props.selected]);

    return (
      <>
        {loading ? (
          <ResourceLoading />
        ) : (
          <Formik
            initialValues={{
              id: null,

              product: item?.product
                ? item?.product
                : fields?.product
                ? fields?.product
                : null,
              system_m_name: item?.system_m_name
                ? item?.system_m_name
                : fields?.model_name
                ? fields?.model_name
                : null,
              product_id: item?.product_id
                ? item?.product_id
                : fields?.product_id
                ? fields?.product_id
                : null,
              serial_number: item?.serial_number
                ? item?.serial_number
                : fields?.serial_number
                ? fields?.serial_number
                : null,
              installation_year: item?.installation_year
                ? item?.installation_year
                : fields?.installation_year
                ? fields?.installation_year
                : null,
              production_year: item?.production_year
                ? item?.production_year
                : fields?.production_year
                ? fields?.production_year
                : null,
              system_type: item?.system_type,
              system_type_id: item?.type?.id,
              submit: null,
            }}
            validationSchema={Yup.object({
              system_type_id: Yup.number().required("Required"),
              production_year: Yup.string(),
              installation_year: Yup.string(),
              serial_number: Yup.string(),
            })}
            onSubmit={(values, helpers) => {
              try {
                toast.promise(createOrUpdate(values), {
                  loading: t("Saving...."),
                  success: (data) => {
                    if (data.status === 500) throw new Error("Server error");
                    if (data.status === 400) throw new Error("Server error");
                    if (data.data.errors)
                      throw new Error(mapErrors(data.data.errors));
                    // props.onRefresh(data.payload);
                    getItems();

                    if (data?.data?.id) {
                      handleSelectFromForm(data?.data);

                      setOpen(false);
                    }

                    return t("Data was saved!");
                  },
                  error: (error) => {
                    return t("Something went wrong. Could not save data!");
                  },
                });
                helpers.setStatus({ success: true });
                helpers.setSubmitting(false);
              } catch (err) {
                toast.error("Something went wrong...");
                console.error(err);
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
              }
            }}
          >
            {(formik) => (
              <Form>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6">
                    <PMListbox
                      label={t("System Type")}
                      options={systemTypeOptions()}
                      selected={formik?.values?.system_type?.id}
                      onChange={(data) => handleSystemTypeChange(data, formik)}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      <label> {t("Product")} </label>
                    </label>
                    <div
                      className="cursor-pointer mt-1 block w-full p-2 border-0 border-b border-transparent bg-blue-50 focus:border-blue-500 focus:ring-0 sm:text-sm"
                      onClick={() => handleRowProductSelect()}
                    >
                      {formik.values.product?.title ? (
                        <div className="font-bold p-2 border-b-4 border-indigo-300">
                          {formik.values?.product?.item_number + " - "}
                          {formik.values?.product?.title}
                        </div>
                      ) : (
                        <div className="text-gray-400">Velg produkt</div>
                      )}
                    </div>

                    <ItemSelector
                      {...props}
                      multiple={false}
                      open={productSelectorOpen}
                      selectorCloseCallback={() =>
                        setProductSelectorOpen(false)
                      }
                      selectorType="products"
                      module={"product"}
                      // module_param={product.id}
                      // selectedItems={[]}
                      onSelect={(data) => handleProductSelect(data, formik)}
                      // selectedItemsCallback={handleSelectedItemsCallback}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="system_m_name"
                      type="text"
                      label={t("Model Name")}
                      {...formik}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="serial_number"
                      type="text"
                      label={t("Serial Number")}
                      {...formik}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="placement"
                      type="text"
                      label={t("Placement")}
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="installation_year"
                      type="number"
                      label={t("Installation Year")}
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="production_year"
                      type="number"
                      label={t("Production Year")}
                      {...formik}
                    />
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    onClick={formik.submitForm}
                  >
                    {item?.id ? t("update") : t("create")}{" "}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </>
    );
  };

  const getItems = () => {
    setLoading(true);
    const params = {
      query: query ? query : "",
      page: 1,
      sortBy: "created_at",
      sort: "desc",
      filters: filters,
      view: null,
    };

    return axios({ method: "get", url: FETCH_URL, params: params })
      .then((response) => {
        if (response.data.data) {
          var array = [
            { id: null, value: null, primary: "Ingen valgt", secondary: null },
          ];
          if (!response.data.data) return array;
          response.data.data.map((row, i) => {
            const rowParams = {
              value: row.id,
              id: row.id,
              primary: row?.product?.displayTitle,
              secondary: row.serial_number,
            };
            array.push(rowParams);
          });
          setItems(array);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        return error.response.data;
      });
  };

  const debouncedAction = debounce((controller) => {
    getItems();
  }, 500);

  useEffect(() => {
    if (open) {
      debouncedAction();
    }

    // Clean the debounced function on component unmount.
    return () => {
      debouncedAction.cancel();
    };
  }, [query, open]);

  // useEffect(() => {
  //   if (values[name]) {
  //     getItem(values[name]);
  //   }
  // }, [items]);

  return (
    <>
      <PMSimpleSelectorFieldV2
        errors={errors}
        name={name}
        label={t(label)}
        labelAction={labelAction}
        options={items}
        selected={selected}
        onChange={handleChange}
        onQueryChange={(query) => setQuery(query)}
        loading={loading}
        createView={createView()}
        onClose={() => setOpen(!open)}
        open={open}
        fields={[
          {
            name: "name",
            label: "Name",
            type: "text",
            required: true,
            disabled: true,
          },
        ]}
      />
    </>
  );
};
