import { InventoryLayout } from "../../../components/dashboard/inventory/inventory-layout";
import { ResourceLoading } from "../../../components/utils/ResourceLoading";
import { ResourceError } from "../../../components/utils/ResourceError";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCountAsync } from "../../../slices/inventory/count";
import { ResourceEmpty } from "../../../components/utils/ResourceEmpty";
import { Grid } from "@mui/material";
import { InventoryCountInfo } from "../../../components/dashboard/inventory/counts/inventory-count-info";
import { InventoryCountItemsTable } from "../../../components/dashboard/inventory/counts/inventory-count-items-table";
import OrderItems from "../../../components/dashboard/orders/order-items";
import { Form, Formik } from "formik";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { mapErrors } from "../../../components/Utils";

export const InventoryCount = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const itemState = {
    ...useSelector((store) => {
      return store.inventory_counts;
    }),
  };
  const { isLoading, error, errors, count } = itemState;

  const item = count;
  const count_items = null;

  useEffect(() => {
    dispatch(getCountAsync(id));
  }, [dispatch]);

  const createOrUpdateDispatch = () => {};
  const renderContent = () => {
    if (isLoading) {
      return <ResourceLoading />;
    }
    if (error && errors) {
      return <ResourceError errors={errors} />;
    }

    if (!item?.id) {
      return <ResourceEmpty />;
    }

    return (
      <>
        <Grid container spacing={3}>
          <Grid
            container
            item
            lg={4}
            spacing={3}
            sx={{ height: "fit-content", order: { md: 1, xs: 1 } }}
          >
            <Grid item xs={12}>
              <InventoryCountInfo item={item} />
            </Grid>
          </Grid>

          <Grid
            container
            item
            lg={8}
            spacing={3}
            sx={{ height: "fit-content", order: { md: 2, xs: 1 } }}
          >
            <Grid item xs={12}>
              {/* <InventoryCountItemsTable items={count_items} /> */}
              <Formik
                initialValues={{
                  id: item?.id,
                  items: null,
                  submit: null,
                }}
                validationSchema={Yup.object({
                  report: Yup.string().max(500),
                })}
                onSubmit={(values, helpers) => {
                  try {
                    toast.promise(createOrUpdateDispatch(values), {
                      loading: t("Saving...."),
                      success: (data) => {
                        if (data.status === 500)
                          throw new Error("Server error");
                        if (data.payload.errors)
                          throw new Error(mapErrors(data.payload.errors));
                        // onClose(true);
                        return t("Data was saved!");
                      },
                      error: (error) => {
                        return t("Something went wrong. Could not save data!");
                      },
                    });
                    // props.onRefresh()
                    helpers.setStatus({ success: true });
                    helpers.setSubmitting(false);
                  } catch (err) {
                    toast.error("Something went wrong...");
                    console.error(err);
                    helpers.setStatus({ success: false });
                    helpers.setErrors({ submit: err.message });
                    helpers.setSubmitting(false);
                  }
                }}
              >
                {(formik) => (
                  <Form>
                    <OrderItems
                      group={false}
                      sales_price={false}
                      // purchase_price={true}
                      discount={false}
                      surcharge={false}
                      drag={false}
                      {...formik}
                    />
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <InventoryLayout name="inventory_count">{renderContent()}</InventoryLayout>
  );
};
