import axios from "./axios";

export async function deleteProduct(payload) {
  return axios
    .delete(`/products/` + payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function checkProductNumber(payload) {
  return axios.get(`/products/checkNumber`, {
    params: payload,
  });
}

export async function nextProductNumber() {
  return axios.get(`/products/nextNumber`);
}

export async function createProduct(payload) {
  return axios
    .post(`/products/`, { product: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function updateProduct(payload) {
  return axios
    .put(`/products/` + payload.id, { product: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // return error.response.data
      if (error.status !== 200) return error;
      else return error.response;
    });
}

export async function listUpdateProduct(payload) {
  return axios
    .put(`/products/list`, { ids: payload.ids, product: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // return error.response.data
      if (error.status !== 200) return error;
      else return error.response;
    });
}

export async function fetchProduct(payload) {
  // payload is only ID
  return axios
    .get(`/products/` + payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchProducts(controller) {
  // let { filters, sort, sortBy, page, query, view, per_page } = controller;

  // const params = {
  //   query:    query,
  //   page:     page,
  //     per_page: per_page,
  //   sortBy:   sortBy,
  //   sort:     sort,
  //   filters:  filters,
  //   view:     view,
  // }

  return axios({ method: "get", url: "/products/list", params: controller })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}
