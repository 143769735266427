import { PlusIcon, WrenchIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import ServiceReportModal from "../../../components/dashboard/service/reports/service-report-edit-modal";
import { ServiceLayout } from "../../../components/dashboard/service/service-layout";
import PMLinkCard from "../../../components/utils/PMLinkCard";
import ReportsCalendarSimpleView from "./reports/calendar/simpleView";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import { ReportsStackList } from "../../../components/dashboard/service/reports/reports-stack-list";
import { TWclassNames } from "../../../components/utils/Div";
import { updateUrlWithException } from "../../../components/Utils";
import { useSearchParams } from "react-router-dom";
import { PMNotice } from "../../../components/utils/notifications";
import { useSelector } from "react-redux";
import { EmployeeSelector } from "../../../components/utils/moduleSelectors/employeeSelector";

export const ServiceOverview = (props) => {
  const { t } = useTranslation();
  const { ...other } = props;

  const currentUserSession = useSelector((store) => {
    return store.session.currentUser;
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const [currentUser, setCurrentUser] = useState(currentUserSession);

  const [newServiceReportModalOpen, setNewServiceReportModalOpen] =
    useState(false);

  function handleUserChange(data) {
    setCurrentUser(data.target.object);
  }

  const rightSideActions = [
    {
      label: "New Report",
      description: "Create a new Report",
      path: "",
      action: () => setNewServiceReportModalOpen(true),
      icon: WrenchIcon,
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
    },
  ];

  const TabTwo = () => {
    return (
      <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
          <div className="grid grid-cols-1 gap-5">
            <div className="grid grid-cols-2 max-sm:grid-cols-1  gap-5">
              <div className="col-span-2">
                <div className="bg-white px-5 py-5 rounded-2xl shadow ">
                  <h2 className="text-base font-semibold leading-6 text-gray-900">
                    Rapporter
                    <div className="mt-5">
                      <ReportsStackList
                        // module_param={item.id}
                        module="project"
                        filters={[
                          {
                            name: "user",
                            // value: item.id,
                          },
                        ]}
                      />
                    </div>
                  </h2>
                </div>
              </div>
              <div className="grid-cols-1"></div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [selectedTab, setSelectedTab] = useState(
    parseInt(searchParams.get("tab")) || 0
  );

  useEffect(() => {
    updateUrlWithException(
      // These will be updated
      {
        tab: selectedTab,
      }
    );
  }, [selectedTab]);
  const tabs = [
    {
      name: "Kalender",
      component: <Calendar />,
    },
    {
      name: "Ikke bekreftet",
      component: (
        <div className="flex flex-col gap-5">
          <ReportsStackList
            sort="asc"
            sortBy="agreement_date"
            fields="id,displayTitle,executing_employee,status,appointment,description,report_type,customer.id,customer.displayTitle,delivery_address"
            filters={[
              {
                name: "view",
                value: JSON.stringify(["AGREEMENT_NOT_CONFIRMED"]),
              },
            ]}
          />
        </div>
      ),
    },
    {
      name: "Ikke ferdigstilt",
      component: currentUser ? (
        <div className="flex flex-col gap-5">
          <EmployeeSelector
            filters={[{ name: "role", value: "service" }]}
            selected={currentUser}
            onChange={(e) => handleUserChange(e)}
          />
          <ReportsStackList
            filters={[
              { name: "view", value: JSON.stringify(["NOT_FINISHED"]) },
              {
                name: "executing_employee",
                value: JSON.stringify(currentUser?.id),
              },
            ]}
          />
        </div>
      ) : (
        <PMNotice
          title="Mangler bruker"
          description="Du må velge bruker før rapporter lastes inn"
        />
      ),
    },
  ];

  function Calendar() {
    return <ReportsCalendarSimpleView />;
  }

  function agreementTabs() {
    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            onChange={(e) => setSelectedTab(e.target.value)}
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
            defaultValue={tabs[selectedTab].name}
          >
            {tabs.map((tab, tabIdx) => (
              <option key={tab.name} value={tabIdx}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav
            className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
            aria-label="Tabs"
          >
            {tabs.map((tab, tabIdx) => (
              <a
                key={tab.name}
                onClick={() => setSelectedTab(tabIdx)}
                className={TWclassNames(
                  tabIdx === selectedTab
                    ? "text-gray-900"
                    : "text-gray-500 hover:text-gray-700",
                  tabIdx === 0 ? "rounded-l-lg" : "",
                  tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                  "cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                )}
                aria-current={tabIdx === selectedTab ? "page" : undefined}
              >
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={TWclassNames(
                    tabIdx === selectedTab ? "bg-blue-500" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </a>
            ))}
          </nav>
        </div>
      </div>
    );
  }

  return (
    <DashboardHeader
      subtitle=""
      actions={[]}
      title={"Service Overview"}
      meta={[]}
      mainButton={{
        label: "New",
        action: () => setNewServiceReportModalOpen(true),
        path: null,
        color: "",
        icon: PlusIcon,
      }}
    >
      <div className="mx-auto mt-8 grid max-w-7xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6">
        <div className="space-y-6 lg:col-span-6 lg:col-start-1 sm:col-span-6">
          {agreementTabs()}
          {tabs[selectedTab].component}
        </div>
      </div>
      <ServiceReportModal
        onClose={() => setNewServiceReportModalOpen(false)}
        open={newServiceReportModalOpen}
        itemId={0}
      />
    </DashboardHeader>
  );
};
