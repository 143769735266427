import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { PMPagination } from "../../../utils/PMPagination";
import { Navigate, useNavigate } from "react-router-dom";
import { Table } from "../../../utils/PMTable/Table";
import { TableBody } from "../../../utils/PMTable/TableBody";
import { Checkbox } from "@mui/material";
const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  // More people...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const InventoryCountsTable = (props) => {
  const { itemState, onPageChange, itemsCount, page } = props;
  const { data, isLoading, errors } = itemState;

  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedPeople.length > 0 && selectedPeople.length < people.length;
    setChecked(selectedPeople.length === people.length);
    setIndeterminate(isIndeterminate);
    // checkbox.current.indeterminate = isIndeterminate
  }, [selectedPeople]);

  function toggleAll() {
    setSelectedPeople(checked || indeterminate ? [] : people);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const navigate = useNavigate();

  function renderRows() {
    return data?.map((item, index) => {
      return (
        <tr
          key={item?.id}
          onClick={() => navigate("/dashboard/inventory/counts/" + item?.id)}
        >
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <div className="">
              <div className="">
                <div className="font-medium text-gray-900">
                  {item?.count_number}
                </div>
                <div className="text-gray-500">{item?.title}</div>
              </div>
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <div className="text-gray-900">{item?.date}</div>
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <div className="text-gray-900">{item?.status} </div>
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <Checkbox disabled checked={item?.active} />
          </td>
          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
            <a href="#" className="text-indigo-600 hover:text-indigo-900">
              Edit<span className="sr-only">, {item?.title}</span>
            </a>
          </td>
        </tr>
      );
    });
  }

  return (
    <>
      <Table>
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Title
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Date
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Status
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Active
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <TableBody>{!isLoading && data ? renderRows() : ""}</TableBody>
      </Table>
      <PMPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={itemsCount}
      />
    </>
  );
};

InventoryCountsTable.defaultProps = {
  page: 1,
  itemState: [],
  itemsCount: 0,
  selectedItems: [],
  sort: "desc",
  sortBy: "createdAt",
};
