import { Field, validateYupSchema } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  ArrowDownIcon,
  CheckIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import PMSimpleSelectorModal from "./PMSimpleSelectorModal";
import { useState } from "react";
import toast from "react-hot-toast";
import axios from "../../../api/axios";
import { useEffect } from "react";
import { LABEL_NAME } from "./PMSimpleSelectorUtils";
import { fetchReport } from "../../../api/service/report";
import { mapErrors } from "../../Utils";

export const PMSimpleSelectorField = (props) => {
  const [items, setItems] = useState([]);
  const [itemState, setItemState] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [query, setQuery] = useState();
  const [loading, setLoading] = useState(false);
  // Set selected as selected from input as default (reccived from props)

  const [selector, setSelector] = useState({
    type: props.name,
    fetchLink: "",
    name: "",
    fields: "",
  });
  const {
    type,
    name,
    label,
    multiline,
    touched,
    errors,
    onBlur,
    onChange,
    values,
    setFieldValue,
    formik,
    noLabel,
    ...other
  } = props;

  const { t } = useTranslation();

  const [selected, setSelected] = useState();
  const value = values ? values[name] : props.value;

  const onCallback = (data) => {
    setModalOpen(false);
    getItem(data.selected.item.id);
    if (formik) {
      setFieldValue(name, data.selected.item.id);
    } else {
      onChange({ target: { name: name, value: data.selected.item.id } });
    }
  };

  useEffect(() => {
    setSelectorType(selector.type);
  }, []);

  useEffect(() => {
    if (selector.fetchLink) {
      setTimeout(() => getItems(), 50);
    }
  }, [selector.fetchLink, query]);

  useEffect(() => {
    if (value && items) {
      getItem(value);
    }
  }, [items]);

  const getItem = (id) => {
    return axios({ method: "get", url: selector.fetchLink + "/" + id })
      .then((res) => {
        if (res.data.id) {
          setLoading(false);
          setSelected({
            id: res.data?.id,
            label: res.data[LABEL_NAME[name]],
            number: res.data[selector.numberField],
          });
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err.data.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          report: {},
          error: true,
          errors: errors,
        }));
      });
  };

  const getItems = () => {
    setLoading(true);
    const params = {
      query: query ? query : "",
      page: 1,
      sortBy: "created_at",
      sort: "desc",
      filters: null,
      view: null,
      fields: selector.fields,
    };

    return axios({ method: "get", url: selector.fetchLink, params: params })
      .then((response) => {
        setItems(response.data);
        // setItems(prevState => [...prevState, response.data])
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        return error.response.data;
      });
  };

  const translateAndSetItems = () => {
    const translatedItems = items?.map((item) => {
      return {
        ...item,
        label: item[LABEL_NAME[name]],
        value: item[selector.numberField],
        number: "",
      };
    });
    setItems(translatedItems);
  };

  const setSelectorType = () => {
    const selectorName = {
      customer: "kunde",
      project: "prosjekt",
      department: "avdeling",
      report_type: "report type",
      variable_type: "variable type",
      unit_id: "unit",
      system_id: "system",
    };

    const fetchLink = {
      customer: "/customers",
      project: "/projects",
      department: "/departments",
      report_type: "/service/settings/report_types",
      variable_type: "/service/settings/variable_types",
      unit_id: "/units",
    };

    const fields = {
      customer: "id, name, customer_number",
      project: "id, title, project_number",
      department: "id, title, department_number",
      report_type: "id, title",
      variable_type: "id, title",
      unit_id: "id, title",
    };

    const numberField = {
      customer: "customer_number",
      project: "project_number",
      department: "department_number",
      report_type: "-",
      variable_type: "-",
      unit_id: "-",
    };

    setSelector((prevState) => ({
      ...prevState,
      name: selectorName[selector.type],
      fetchLink: fetchLink[selector.type],
      fields: fields[selector.type],
      numberField: numberField[selector.type],
    }));
  };

  // Get selected ID from field (is set when form is loaded)
  // const getItemById = (items, selected) => items?.find(item => item.id === selected)

  // const setSelectedItemById = (id) => {
  //   const item = items.find(item => item.id === id)
  //   setSelected({
  //     id: item?.id,
  //     label: item[LABEL_NAME[name]],
  //     number: item[selector.numberField]
  //   })
  // }

  // 2 fields MAX
  const displayFields = {
    customer: ["number", "label"],
    project: ["number", "label"],
    department: ["number", "label"],
    report_type: ["label"],
    variable_type: ["label"],
    unit_id: ["label"],
  };

  const selectedTitle = () => {
    if (!selected) return t("Select a") + " " + label;

    if (displayFields[name].length === 2) {
      return (
        selected[displayFields[name][0]] +
        " " +
        selected[displayFields[name][1]]
      );
    }

    if (displayFields[name].length === 1) {
      return selected[displayFields[name][0]];
    }
  };

  const renderErrors = () => {
    if (formik) {
      if (touched[name] && errors[name]) {
        return (
          <div className="flex mt-1 text-red-700 text-xs">
            <ExclamationCircleIcon className="w-4 text-xs" />
            <span className="ml-2">{errors[name]}</span>
          </div>
        );
      }
    }
  };

  return (
    <>
      <div>
        {noLabel ? (
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            <label>{t(label)}</label>
          </label>
        ) : null}
        <div className="mt-1 focus-within:border-blue-600 ">
          <div
            onClick={() => setModalOpen(true)}
            className="relative h-[37px] mt-1 bg-blue-50 
                shadow flex items-center  hover:bg-gray-50 rounded-xl border-2 border-blue-400 hover:border-2-blue-600 cursor-pointer"
          >
            <span className="ml-2">{selectedTitle()}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-4">
              <ArrowDownIcon className="h-3 w-3" aria-hidden="true" />
            </span>
          </div>
        </div>

        {renderErrors()}
      </div>

      <PMSimpleSelectorModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        name={name}
        label={label}
        selected={selected}
        callback={(data) => onCallback(data)}
        items={items}
        query={(query) => setQuery(query)}
        loading={loading}
      />
    </>
  );
};

PMSimpleSelectorField.defaultProps = {
  type: "text",
  formik: true,
  noLabel: true,
  options: [],
};

PMSimpleSelectorField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
};
