import { PMDialog } from "../../utils/PMDialog";
import { useState } from "react";
import { UploaderV2 } from "./Uploaderv2";
import { DocumentImage } from "../documents/utils";
import { ProjectFolderSelector } from "../../utils/moduleSelectors/projectFolderSelector";
import { PMField } from "../../utils/PMField";
import { axiosWithToast } from "../../Utils";
import { useDispatch } from "react-redux";

import { ResourceLoading } from "../../utils/ResourceLoading";
import { updateDocuments } from "../../../api/document";

export const DocumentUploadSectionV2 = (props) => {
  const { module, moduleParam, multiple, version, allowedFileTypes } = props;
  const [open, setOpen] = useState(false);
  const [documents, setDocuments] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleUploadStart = () => {
    setIsLoading(true);
    setOpen(true);
  };

  // Handle document
  const handleOnUpload = (data) => {
    // add document to list
    setDocuments((prevState) => [...prevState, data]);

    // setDocument(data);
    setIsLoading(false);
    // props.onUpload(data);

    if (props.parentCallback) {
      props.parentCallback(data);
    }
  };

  const dispatch = useDispatch();

  const onItemRowChange = (e, data) => {
    // setItems(prevState => ({ ...prevState, [1][description]: 'test' }))
    e.preventDefault();

    // // find item in list by id
    const index = documents.findIndex((x) => x.id === data?.id);

    let prevItems = [...documents];
    let item = {
      ...prevItems[index],
      [e.target.name]: e.target.value,
    };
    prevItems[index] = item;
    setDocuments(prevItems);
  };

  const updateDocumentAsync = () => {
    axiosWithToast(
      updateDocuments(documents),
      () => {},
      () => setOpen(false)
    );

    if (props.parentCallback) {
      props.parentCallback(documents);
    }

    // Reset
    setTimeout(() => {
      setDocuments([]);
    }, 500);
  };

  const handleDocumentChange = (document) => {
    // find existing document in list
    const index = documents.findIndex((x) => x.id === document.id);

    let prevItems = [...documents];
    prevItems[index] = document;
    setDocuments(prevItems);
  };

  function documentForm(document) {
    // form for each document
    return (
      <div key={document.id} className="flex flex-col md:flex-row gap-5 py-5">
        <div className="rounded-xl shadow border-2 border-blue-400 w-full md:max-w-[300px] md:min-w-[300px] min-w-full">
          <DocumentImage
            open={open}
            document={document}
            onChange={(document) => handleDocumentChange(document)}
          />
        </div>
        <div className="">
          <PMField
            key={document?.id}
            name="title"
            type="text"
            label="Tittel"
            value={document?.title}
            onChange={(e) => onItemRowChange(e, document)}
          />
        </div>
        <div className="col-span-3 sm:col-span-3 flex flex-col">
          <div className="mt-5">
            {module === "project" && moduleParam && (
              <ProjectFolderSelector
                projectId={module === "project" ? moduleParam : null}
                selected={document?.folder?.id ? document?.folder : null}
                onChange={(e) => onItemRowChange(e, document)}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <UploaderV2
        onLoading={() => {
          handleUploadStart();
        }}
        module={module}
        module_param={moduleParam}
        size=""
        version={version}
        multiple={true}
        parentCallback={(data) => handleOnUpload(data)}
        allowedFileTypes={allowedFileTypes}
      />
      <PMDialog
        open={open}
        onClose={() => setOpen(false)}
        title="Nye dokumenter"
      >
        {isLoading ? (
          <ResourceLoading />
        ) : (
          <div>
            <div className="mt-10">
              {documents?.map((document) => documentForm(document))}
            </div>

            <div className="flex justify-between">
              <button
                type="button"
                className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={() => setOpen(false)}
              >
                Steng
              </button>

              <button
                type="button"
                className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={() => {
                  updateDocumentAsync();
                }}
              >
                Lagre
              </button>
            </div>
          </div>
        )}
      </PMDialog>
    </>
  );
};

DocumentUploadSectionV2.defaultProps = {
  version: 3,
};
