import {
  ArrowPathRoundedSquareIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { FormikSaveBar } from "../../../components/utils/FormikSaveBar";
import { ResourceLoading } from "../../../components/utils/ResourceLoading";
import ActivitiesTable from "../../../components/dashboard/activities/activitiesTable";
import { fetchHourSettings, updateHourSettings } from "../../../api/hour";
import { axiosWithToast } from "../../../components/Utils";

export const HourSettings = () => {
  const [settings, setSettings] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const item = null;

  const getSettings = () => {
    setIsLoading(true);
    fetchHourSettings()
      .then((res) => {
        // Set settingsState
        setIsLoading(false);
        setSettings(res.data.data);
      })
      .catch((error) => {
        // Set settingsState (errors, error and isLoading)
        setError(true);
        setErrors(error.data.errors);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSettings();
  }, []);

  const onRefresh = (data) => {
    // Update settingsState
    setSettings(data);
  };

  const headerActions = [
    // {
    //   component: null,
    //   label: "Sync Tripletex with promang",
    //   description: "",
    //   path: null,
    //   action: () => setSyncDialogOpen(true),
    //   icon: ArrowPathRoundedSquareIcon,
    //   textColor: "text-teal-700",
    //   bgColor: "bg-white",
    //   hidden: false,
    //   menu: true,
    // },
  ];

  function Content() {
    return (
      <>
        <div className="col-span-3">
          <Formik
            initialValues={{
              project_activities: settings?.project_activities,
              internal_activities: settings?.internal_activities,
              submit: null,
            }}
            onSubmit={(values, helpers) => {
              try {
                axiosWithToast(updateHourSettings(values));
                // toast.promise(dispatch(updateCompanyAsync(values)), {
                //   loading: t("Saving...."),
                //   success: (data) => {
                //     if (data.status === 500) throw new Error("Server error");
                //     onRefresh(data?.payload?.data?.data);
                //     return t("Data was saved!");
                //   },
                //   error: t("Something went wrong. Could not save data!"),
                // });
                helpers.setStatus({ success: true });
                helpers.setSubmitting(false);
              } catch (err) {
                toast.error("Nope");
                console.error(err);
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
              }
            }}
          >
            {(formik) => (
              <Form className="grid-span-1 sm:grid-span-2 w-full">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-3">
                    <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
                      <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                        {/* <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">{ t("Product Description") }</h3>
                    <p className="mt-1 text-sm text-gray-500"></p>
                  </div> */}
                        <div className="grid sm:grid-cols-8 gap-6">
                          <div className="sm:col-span-2">
                            {/* <PMSwitch label="Active" name="active" {...formik} /> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
                      <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                        <div>
                          <h3 className="text-lg font-medium leading-6 text-gray-900">
                            {t("Aktiviter")}
                          </h3>
                          <p className="mt-1 text-sm text-gray-500"></p>
                        </div>

                        <div className="grid grid-cols-6 gap-6">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-2">
                              <ActivitiesTable
                                name="project_activities"
                                number={true}
                                invoiceable={true}
                                inactive={true}
                                drag={false}
                                // project={item}
                                title={false}
                                module={"settings"}
                                {...formik}
                              />
                            </div>
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <PromptIfDirty {...formik}/> */}
                <FormikSaveBar {...formik} />
              </Form>
            )}
          </Formik>
        </div>
      </>
    );
  }

  if (isLoading) {
    return <ResourceLoading />;
  }

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={"Settings"}
        meta={[]}
        // mainButton={{
        //   label: "Edit",
        //   action: () => setReportDialogOpen(true),
        //   path: null,
        //   color: "",
        //   icon: PencilIcon,
        // }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <Content />
        </div>
      </DashboardHeader>
    </>
  );
};
