import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../store";
import { refreshAccessToken } from "../../slices/session";
import { FullPageLoading } from "../utils/Loading";

function PersistLogin(props: any) {
  const error = useSelector((state: RootState) => state.session.error);
  const loading = useSelector((state: RootState) => state.session.loading);
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const refreshToken = useSelector(
    (state: RootState) => state.session.refreshToken
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    function verifyRefreshToken() {
      try {
        dispatch(refreshAccessToken(refreshToken));
      } catch (error) {
        console.log(error);
      }
    }
    if (!accessToken) {
      verifyRefreshToken();
    }
  }, [accessToken, refreshToken]);

  return <>{loading ? <FullPageLoading /> : <Outlet />}</>;
}

export default PersistLogin;
