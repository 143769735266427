import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { PMField } from "../../../utils/PMField";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dispatchWithToast, mapErrors } from "../../../Utils";
import PMSwitch from "../../../utils/PMSwitch";
import {
  createReportTypeAsync,
  getReportTypeAsync,
  updateReportTypeAsync,
} from "../../../../slices/service/report-types";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import {
  fetchAgreementType,
  fetchAgreementTypes,
  fetchReportType,
} from "../../../../api/service/settings";
import { QuillEditor } from "../../../utils/quill-editor";
import { ReportTypeSelector } from "../../../utils/moduleSelectors/reportTypeSelector";
import {
  createAgreementTypeAsync,
  updateAgreementTypeAsync,
} from "../../../../slices/service/agreement-types";
import { Popup } from "../../../utils/Div";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

export default function ServiceAgreementTypeDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { open, onClose, itemId } = props;

  const [itemState, setItemState] = useState({});

  const { agreement_type, isLoading } = itemState;

  const getItem = (itemId) => {
    setItemState({ ...itemState, isLoading: true });

    fetchAgreementType(itemId)
      .then((data) => {
        setItemState({ ...itemState, agreement_type: data, isLoading: false });
      })
      .catch((err) => {
        setItemState({ ...itemState, error: err, isLoading: false });
      });
  };

  useEffect(() => {
    if (itemId) {
      getItem(itemId);
    } else {
      setItemState({ ...itemState, agreement_type: null });
    }
  }, [open]);

  useEffect(() => {
    setItemState(itemState);
  }, [itemState]);

  const createOrUpdateDispatch = (data) => {
    if (agreement_type?.id) {
      const res = dispatch(updateAgreementTypeAsync(data));
      return res;
    } else {
      const res = dispatch(createAgreementTypeAsync(data));
      return res;
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {isLoading ? (
                  <ResourceLoading />
                ) : (
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {itemId
                        ? t("service.agreement_types.edit")
                        : t("service.agreement_types.create")}
                    </Dialog.Title>

                    {isLoading ? (
                      "Loading..."
                    ) : (
                      <Formik
                        initialValues={{
                          id: agreement_type?.id,
                          title: agreement_type?.title,
                          description: agreement_type?.description,
                          conditions: agreement_type?.conditions,
                          report_type_id: agreement_type?.report_type?.id,
                          mission: agreement_type?.mission,
                          submit: null,
                        }}
                        validationSchema={Yup.object({
                          title: Yup.string()
                            .max(255)
                            .required(t("title.required")),
                          description: Yup.string().max(500),
                        })}
                        onSubmit={(values, helpers) => {
                          try {
                            dispatchWithToast(
                              createOrUpdateDispatch(values),
                              null,
                              onClose
                            );
                            helpers.setStatus({ success: true });
                            helpers.setSubmitting(false);
                          } catch (err) {
                            toast.error("Something went wrong...");
                            console.error(err);
                            helpers.setStatus({ success: false });
                            helpers.setErrors({ submit: err.message });
                            helpers.setSubmitting(false);
                          }
                        }}
                      >
                        {(formik) => (
                          <Form>
                            <div className="grid grid-cols-6 gap-6 mt-10">
                              <div className="col-span-6 sm:col-span-3">
                                <PMField
                                  name="title"
                                  type="text"
                                  label="Title"
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-3"></div>

                              <div className="col-span-6 sm:col-span-6">
                                <PMField
                                  name="description"
                                  type="text"
                                  label="Description"
                                  multiline
                                  rows={2}
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-6">
                                <span className="text-sm">Vilkår</span>
                                <QuillEditor name="conditions" {...formik} />
                              </div>

                              <div className="col-span-6 sm:col-span-6">
                                <ReportTypeSelector
                                  name="report_type_id"
                                  selected={agreement_type?.report_type}
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-6">
                                <div className=" flex">
                                  <div>
                                    <PMSwitch
                                      name="mission"
                                      label="Mission"
                                      {...formik}
                                    />
                                  </div>
                                  <div className="ml-2">
                                    <Popup
                                      content={
                                        "Om denne er krysset i for denne typen, så vil systemet opprette rapporter ved utførelse av ny avtale. Ellers så blir kun ordre opprettet"
                                      }
                                    >
                                      <QuestionMarkCircleIcon className="h-5 w-5" />
                                    </Popup>
                                  </div>
                                </div>
                              </div>

                              <div className="col-span-6 sm:col-span-3 lg:col-span-2"></div>
                            </div>

                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                              <button
                                type="button"
                                className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                onClick={onClose}
                              >
                                {t("Close")}{" "}
                              </button>

                              <button
                                type="button"
                                className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                onClick={formik.submitForm}
                              >
                                {agreement_type?.id ? t("Save") : t("Create")}{" "}
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
