import { useTranslation } from "react-i18next";
import { PMSimpleSelectorFieldV2 } from "../PMSimpleSelector/PMSimpleSelectorFieldV2";
import axios from "../../../api/axios";
import { useState, useEffect } from "react";
import { dispatchWithToast, mapErrors } from "../../Utils";
import { Form, Formik } from "formik";
import { ResourceLoading } from "../ResourceLoading";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { PMField } from "../PMField";
import { fetchSystemTypes } from "../../../api/customer/system";
import { useDispatch } from "react-redux";
import {
  createProjectFolderAsync,
  getProjectFoldersAsync,
  resetProjectFolders,
} from "../../../slices/project/folder";
import { useSelector } from "react-redux";
import PMCombobox from "../PMCombobox";
import { debounce } from "lodash";

export const ProjectFolderSelector = (props) => {
  const {
    setFieldValue,
    values,
    errors,
    onChange,
    module,
    module_param,
    fields,
  } = props;

  const name = "folder_id";
  const label = props.label ? props.label : "Folder";
  const filters = null;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const folderState = useSelector((store) => {
    return store.projectFolders;
  });

  const [query, setQuery] = useState();
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState({
    id: 0,
    field1: "Ingen valgt",
    field2: null,
  });
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const [projectId, setProjectId] = useState(null);
  const [disabled, setDisabled] = useState(false);

  // useEffect(() => {
  //   if (props.disabled || !projectId) {
  //     setDisabled(true);
  //   } else {
  //     setDisabled(false);
  //   }
  // }, [props.disabled, projectId]);

  useEffect(() => {
    if (props.projectId) {
      setProjectId(props.projectId);
      setError();
    } else {
      setError("Project ID is required");
      setLoading(false);
    }
  }, [props]);

  const debouncedAction = debounce((controller) => {
    getItems();
  }, 500);

  useEffect(() => {
    if (projectId && open) {
      debouncedAction();
    }

    // Clean the debounced function on component unmount.
    return () => {
      debouncedAction.cancel();
    };
  }, [projectId, query, open]);

  const labelAction = {
    path:
      projectId &&
      selected?.id &&
      "/dashboard/projects/" + projectId + "/folders/" + selected?.id,
    // action: () => toast("test"),
  };

  const handleChange = (data) => {
    setLoading(false);

    setSelected({
      id: data?.selected?.item?.object?.id,
      field1: data?.selected?.item?.object?.displayTitle,
    });

    setOpen(false);
    if (values) {
      setFieldValue(name, data.selected.item.id);
    } else {
      onChange({ target: { name: name, value: data.selected.item.id } });
    }
  };

  const handleSelectFromForm = (data) => {
    setSelected({
      id: data?.id,
      field1: data?.displayTitle,
    });
    if (values) {
      setFieldValue(name, data?.id);
    } else {
      onChange({ target: { name: name, value: data?.id } });
    }
  };

  useEffect(() => {
    if (props.selected) {
      setSelected({
        id: props.selected?.id,
        field1: props.selected?.displayTitle,
      });
    }
  }, [props.selected]);

  const CreateView = (item) => {
    return (
      <>
        {loading ? (
          <ResourceLoading />
        ) : (
          <Formik
            initialValues={{
              id: null,
              project_id: projectId,
              submit: null,
            }}
            validationSchema={Yup.object({
              project_id: Yup.string().required(() =>
                toast.error("Project ID is required")
              ),
              title: Yup.string().required("Title is required"),
            })}
            onSubmit={(values, helpers) => {
              try {
                dispatchWithToast(
                  dispatch(createProjectFolderAsync(values)),
                  handleSelectFromForm,
                  setOpen(false)
                );

                helpers.setStatus({ success: true });
                helpers.setSubmitting(false);
              } catch (err) {
                toast.error("Something went wrong...");
                console.error(err);
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
              }
            }}
          >
            {(formik) => (
              <Form>
                <div className="grid grid-cols-6 gap-6">
                  {formik?.errors["project_id"] && (
                    <div className="col-span-6 sm:col-span-6">
                      <div className="text-red-500">{error}</div>
                    </div>
                  )}

                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="title"
                      type="text"
                      label={t("Title")}
                      {...formik}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="description"
                      type="text"
                      label={t("Description")}
                      multiline
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <PMCombobox
                      name="parent_id"
                      label={t("Parent")}
                      options={folderState?.data
                        ?.filter((item) => item?.parent === null)
                        .map((item, idx) => {
                          return { value: item.id, label: item.displayTitle };
                        })}
                      {...formik}
                    />
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    onClick={formik.submitForm}
                  >
                    {item?.id ? t("update") : t("create")}{" "}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </>
    );
  };

  const getItems = () => {
    setLoading(true);
    const controller = {
      project_id: projectId,
      query: query ? query : "",
      page: 1,
      sortBy: "created_at",
      sort: "desc",
      filters: filters,
      view: null,
    };

    dispatch(getProjectFoldersAsync(controller))
      .then((data) => {
        var array = [
          { id: null, value: null, primary: "Ingen valgt", secondary: null },
        ];
        if (!data) return array;
        folderState?.data
          ?.filter(
            (item) => !item?.parent?.id && item?.project?.id === projectId
          )
          .map((row, i) => {
            const rowParams = {
              value: row.id,
              id: row.id,
              primary: row?.displayTitle,
              children: folderState?.data?.filter(
                (item) => item?.parent?.id === row?.id
              ),
              object: row,
            };
            array.push(rowParams);
          });
        setItems(array);
      })
      .catch((error) => {
        console.error("There was an error fetching the folders:", error);
      })
      .finally(() => {
        setLoading(false); // Ensure setLoading is called no matter what
      });
  };

  return (
    <>
      <PMSimpleSelectorFieldV2
        errors={errors}
        error={error}
        name={name}
        label={t(label)}
        labelAction={labelAction}
        options={items}
        selected={selected}
        onChange={handleChange}
        onQueryChange={(query) => setQuery(query)}
        createView={projectId ? <CreateView /> : null}
        onClose={() => setOpen(!open)}
        open={open}
        loading={loading}
        disabled={disabled}
      />
    </>
  );
};
