import { useEffect, useState } from "react";
import { fetchDashboardCompanyStats } from "../../api/company";
import { Link } from "react-router-dom";

export const DashboardStatsSection = () => {
  const testStats = [{ id: 1, name: "Total Reports", value: 100 }];

  const [stats, setStats] = useState(testStats);

  const loadStats = async () => {
    await fetchDashboardCompanyStats()
      .then((res) => {
        setStats(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadStats();
  }, []);

  // return title and subtitle depending on NAME, for example: REPORTS_NOT_FINISHED

  function translateNameToTitle(name) {
    switch (name) {
      case "REPORTS_NOT_FINISHED":
        return "RAPPORTER";
      case "REPORTS_FINISHED":
        return "RAPPORTER";
      case "CASES_OPEN":
        return "SAKER";
      case "DEVIATIONS_OPEN_TOTAL":
        return "AVVIK";
      case "DEVIATIONS_OPEN_ASSIGNED":
        return "AVVIK";
      case "PROJECTS_OPEN":
        return "PROSJEKTER";
      case "TRIPS_DISTANCE_TOTAL_CURRENT_MONTH":
        return "KILOMETER";
      case "TRIPS_DISTANCE_USER_TOTAL_CURRENT_MONTH":
        return "KILOMETER";
      default:
        return name;
    }
  }

  function translateNameToSubtitle(name) {
    switch (name) {
      case "REPORTS_NOT_FINISHED":
        return "Som ikke er ferdigstilt";
      case "REPORTS_FINISHED":
        return "Som er ferdigstilt";
      case "CASES_OPEN":
        return "Under behandling";
      case "DEVIATIONS_OPEN_TOTAL":
        return "Venter behandling";
      case "DEVIATIONS_OPEN_ASSIGNED":
        return "Tildelt deg";
      case "PROJECTS_OPEN":
        return "Under utførelse";
      case "TRIPS_DISTANCE_TOTAL_CURRENT_MONTH":
        return "Denne måneden";
      case "TRIPS_DISTANCE_USER_TOTAL_CURRENT_MONTH":
        return "Denne måneden";

      default:
        return name;
    }
  }

  function nameToPath(name) {
    switch (name) {
      case "REPORTS_NOT_FINISHED":
        return "/dashboard/service/?tab=2";
      case "REPORTS_FINISHED":
        return "/dashboard/service/reports?filters=view:FINISHED&page=1&per_page=50&sort=asc&sortBy=created_at&view=all";
      case "CASES_OPEN":
        return "/dashboard/cases";
      case "DEVIATIONS_OPEN_TOTAL":
        return "/dashboard/quality/deviations";
      case "DEVIATIONS_OPEN_ASSIGNED":
        return "/dashboard/quality/deviations";
      case "PROJECTS_OPEN":
        return "/dashboard/projects";
      case "TRIPS_DISTANCE_TOTAL_CURRENT_MONTH":
        return "/dashboard/trips";
      case "TRIPS_DISTANCE_USER_TOTAL_CURRENT_MONTH":
      default:
        return "/dashboard";
    }
  }

  return (
    <div className="flex px-2 py-2 bg-white shadow-md rounded-md overflow-y-scroll">
      {stats?.map((stat) => (
        <Link to={nameToPath(stat.name)} key={stat.name}>
          <div className="flex flex-col bg-gray-400/5 p-8">
            <dt className="text-sm/6 font-semibold text-gray-600">
              {translateNameToTitle(stat.name)}
            </dt>
            <dt className="text-xs italic font-semibold text-gray-600">
              {translateNameToSubtitle(stat.name)}
            </dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
              {stat.count}
            </dd>
          </div>
        </Link>
      ))}
    </div>
  );
};
