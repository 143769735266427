import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Table } from "../../utils/PMTable/Table";
import { TableBody } from "../../utils/PMTable/TableBody";
import { PMPagination } from "../../utils/PMPagination";
import { useNavigate } from "react-router-dom";
import { DeliveryAddressTitle, LoadingRows, SyncStatus } from "../../Utils";
import { ResourceEmpty } from "../../utils/ResourceEmpty";

export const ContactsTable = (props) => {
  const { itemState, onPageChange, itemsCount, page, perPage, currentCount } =
    props;
  const { data, isLoading, errors } = itemState;

  const navigate = useNavigate();

  function renderRows() {
    return data?.map((item, index) => {
      return (
        <tr
          key={item.id}
          onClick={() => navigate("/dashboard/contacts/" + item.id)}
        >
          <td></td>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <div className="flex items-center">
              <div className="">
                <div className="font-medium text-gray-900">{item.name}</div>
                <div className="text-gray-500">{item.customer_number}</div>
              </div>
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <div className="text-gray-500">{item.mobile}</div>
          </td>

          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <div className="text-gray-500">{item.email}</div>
          </td>

          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
            <div className="ml-2">
              <SyncStatus item={item} />
            </div>
          </td>
        </tr>
      );
    });
  }

  return (
    <>
      <Table>
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            ></th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Navn
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Mobil
            </th>

            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              E-post
            </th>

            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <TableBody>
          {isLoading ? (
            <>
              <LoadingRows cols={4} checkboxCell={true} />
              <LoadingRows cols={4} checkboxCell={true} />
              <LoadingRows cols={4} checkboxCell={true} />
              <LoadingRows cols={4} checkboxCell={true} />
              <LoadingRows cols={4} checkboxCell={true} />
              <LoadingRows cols={4} checkboxCell={true} />
              <LoadingRows cols={4} checkboxCell={true} />
              <LoadingRows cols={4} checkboxCell={true} />
              <LoadingRows cols={4} checkboxCell={true} />
              <LoadingRows cols={4} checkboxCell={true} />
            </>
          ) : data?.length < 1 ? (
            <tr>
              <td colSpan={7} className="py-2 px-2">
                <ResourceEmpty />
              </td>
            </tr>
          ) : (
            renderRows()
          )}
        </TableBody>
      </Table>

      <PMPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        perPage={perPage}
        rowsCount={itemsCount}
        totalCount={itemsCount}
        currentCount={currentCount}
        showCount={true}
      />
    </>
  );
};

ContactsTable.defaultProps = {
  page: 1,
  itemState: [],
  itemsCount: 0,
  selectedItems: [],
  sort: "desc",
  sortBy: "createdAt",
};
