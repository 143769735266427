import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { PMField } from "../../../utils/PMField";
import { mapErrors } from "../../../Utils";
import {
  createCustomerAsync,
  updateCustomerAsync,
} from "../../../../slices/customer";
import { ResourceError } from "../../../utils/ResourceError";
import { fetchCustomer } from "../../../../api/customer";
import {
  ArrowUpOnSquareIcon,
  CheckBadgeIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import ItemSelector from "../../../utils/Selector/ItemSelector";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { PMSimpleSelectorField } from "../../../utils/PMSimpleSelector/PMSimpleSelectorField";
import PMListbox from "../../../utils/PMListbox";
import {
  fetchCustomerSystem,
  fetchCustomerSystems,
  fetchSystemTypes,
} from "../../../../api/customer/system";
import {
  createSystemAsync,
  updateSystemAsync,
} from "../../../../slices/customer/system";
import ProductSlideover from "../../products/product-slideover";
import PMCombobox from "../../../utils/PMCombobox";
export default function CustomerSystemDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [itemState, setItemState] = useState({
    isLoading: false,
    error: false,
    errors: [],
    system: {},
    systems: [],
    systemTypes: [],
  });

  // Deconstructing props
  const { open, selectedSystem, customer, onClose, selected, onAttachSystem } =
    props;

  // Deconstruct itemState (Store)
  const { system, systems, systemTypes, isLoading, error, errors } = itemState;

  const [readOnly, setReadOnly] = useState(props.readOnly);

  const getCustomerSystems = async (customerId) => {
    return fetchCustomerSystems(customerId)
      .then((res) => {
        if (res?.data) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            systems: res?.data?.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err?.data?.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          systems: {},
          error: true,
          errors: errors,
        }));
      });
  };

  const getSystemTypes = async () => {
    return fetchSystemTypes()
      .then((res) => {
        if (res?.data) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            systemTypes: res?.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err?.data?.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          systems: {},
          error: true,
          errors: errors,
        }));
      });
  };

  const getCustomerSystem = async () => {
    setItemState((prevState) => ({ ...prevState, isLoading: true }));

    return fetchCustomerSystem(customer.id, selectedSystem)
      .then((res) => {
        if (res?.data?.id) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            system: res.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err?.data?.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          system: {},
          error: true,
          errors: errors,
        }));
      });
  };

  useEffect(() => {
    if (open) {
      if (selectedSystem) {
        getCustomerSystem();
      }
      getCustomerSystems(customer.id);
      getSystemTypes();
    }
  }, [open]);

  const createOrUpdateDispatch = (data) => {
    let res = null;

    if (system?.id) {
      res = dispatch(updateSystemAsync(data));
    } else {
      res = dispatch(createSystemAsync(data));
    }

    return res;
  };

  const [productSelectorOpen, setProductSelectorOpen] = useState(false);

  const handleRowProductSelect = () => {
    setProductSelectorOpen(true);
  };

  const handleProductSelect = (data, formik) => {
    const { setFieldValue } = formik;
    const product = data[0];
    setProductSelectorOpen(false);
    if (product) {
      setFieldValue("product_id", product.id);
      setFieldValue("product", product);
    }
  };

  const systemOptions = () => {
    const label = (system) => {
      if (system?.product) {
        return system?.product?.title + " " + system.serial_number;
      } else {
        return system.serial_number;
      }
    };

    const array = [{ name: "system_id", label: "Ikke valgt", value: 0 }];
    systems?.map((system) => {
      array.push({
        name: "system_id",
        label: label(system),
        value: system.id,
      });
    });
    return array;
  };

  const systemTypeOptions = () => {
    const types = [];
    systemTypes?.map((type) => {
      types.push({
        name: "system_type_id",
        label: type.title,
        title: type.title,
        value: type.id,
      });
    });
    return types;
  };

  const handleSystemTypeChange = (data, formik) => {
    formik.setFieldValue("system_type", data);
    formik.setFieldValue("system_type_id", data?.value);
  };

  const [productSlideroverOpen, setProductSlideoverOpen] = useState(false);

  const systemForm = (formik) => (
    <div className="grid grid-cols-6 gap-6 mt-10">
      {system?.product ? (
        <>
          <div className="col-span-6 sm:col-span-6">
            <ul
              role="list"
              className="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500"
            >
              <li key={0} className="flex space-x-6 py-6">
                <img
                  // src={product.imageSrc}
                  // alt={product.imageAlt}
                  className="h-24 w-24 flex-none rounded-md bg-gray-100 object-cover object-center"
                />
                <div className="flex-auto space-y-1">
                  <h3 className="text-gray-900">{system?.product?.title}</h3>
                  {system?.product?.item_number}

                  <p>{system?.product?.short_description}</p>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    navigate("/dashboard/products/" + system?.product?.id)
                  }
                >
                  <p className="flex-none font-medium text-gray-900">
                    <ArrowUpOnSquareIcon className="w-5 h-5 m-2" />
                  </p>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => setProductSlideoverOpen(true)}
                >
                  <div className="h-15 inline-flex items-center rounded-md border border-transparent bg-yellow-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    {t("Spareparts")}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </>
      ) : null}

      <div className="col-span-6 sm:col-span-3">
        {/* <PMListbox
          label={t("System Type")}
          options={systemTypeOptions()}
          selected={formik?.values?.system_type?.id}
          onChange={(data) => handleSystemTypeChange(data, formik)}
        /> */}

        <PMCombobox
          name="system_type_id"
          label={t("System Type")}
          options={systemTypeOptions()}
          {...formik}
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700"
        >
          <label> {t("Product")} </label>
        </label>
        <div
          className="cursor-pointer mt-1 block w-full p-2 border-0 border-b border-transparent bg-blue-50 focus:border-blue-500 focus:ring-0 sm:text-sm"
          onClick={!readOnly ? () => handleRowProductSelect() : null}
        >
          {formik.values.product?.title ? (
            <div className="font-bold p-2 border-b-4 border-indigo-300">
              {formik.values?.product?.item_number + " - "}
              {formik.values?.product?.title}
            </div>
          ) : (
            <div className="text-gray-400">Velg produkt</div>
          )}
        </div>

        <ItemSelector
          {...props}
          multiple={false}
          open={productSelectorOpen}
          selectorCloseCallback={() => setProductSelectorOpen(false)}
          selectorType="products"
          module={"product"}
          // module_param={product.id}
          // selectedItems={[]}
          onSelect={(data) => handleProductSelect(data, formik)}
          // selectedItemsCallback={handleSelectedItemsCallback}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <PMField
          name="system_m_name"
          type="text"
          label={t("Model name")}
          readOnly={readOnly}
          {...formik}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <PMField
          name="serial_number"
          type="text"
          label={t("Serial Number")}
          readOnly={readOnly}
          {...formik}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <PMField
          name="placement"
          type="text"
          label={t("Placement")}
          readOnly={readOnly}
          {...formik}
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <PMField
          name="installation_year"
          type="number"
          label={t("Installation Year")}
          readOnly={readOnly}
          {...formik}
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <PMField
          name="production_year"
          type="number"
          label={t("Production Year")}
          readOnly={readOnly}
          {...formik}
        />
      </div>
    </div>
  );

  const displayForm = (formik) => {
    if (!selectedSystem) {
      if (systems?.length > 0) {
        if (readOnly) {
          return attachSystem();
        } else {
          return systemForm(formik);
        }
      } else {
        setReadOnly(false);
        return systemForm(formik);
      }
    } else {
      return systemForm(formik);
    }
  };
  const handleDetachSystem = (formik) => {
    onAttachSystem({ value: null });
  };

  // If a system is allready selected (selectedSystem), then displaySystem will be displayed.
  // You will be able to read and edit system
  const displaySystem = () => (
    <Formik
      enableReinitialize
      initialValues={{
        id: system?.id,
        customer_id: customer.id,
        product: system?.product,
        product_id: system?.product_id,
        system_m_name: system?.system_m_name,
        serial_number: system?.serial_number,
        installation_year: system?.installation_year,
        production_year: system?.production_year,
        system_type: system?.system_type,
        system_type_id: system?.type?.id,
        submit: null,
      }}
      validationSchema={Yup.object({
        serial_number: Yup.string().required(t("Serial Number is required")),
      })}
      onSubmit={(values, helpers) => {
        try {
          toast.promise(createOrUpdateDispatch(values), {
            loading: t("Saving...."),
            success: (data) => {
              if (data?.status === 500) throw new Error("Server error");
              if (data?.payload?.errors)
                throw new Error(mapErrors(data?.payload?.errors));
              onClose(true);
              // After save or update, send the systemID to parent component
              if (data?.payload) {
                onAttachSystem({ value: data?.payload?.id });
              }
              return t("Data was saved!");
            },
            error: (error) => {
              return t("Something went wrong. Could not save data!");
            },
          });
          // helpers.setStatus({ success: true });
        } catch (err) {
          toast.error("Something went wrong...");
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err?.message });
          // helpers.setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <Form>
          {displayForm(formik)}

          <div className="mt-10 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3">
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
              onClick={onClose}
            >
              {t("Close")}{" "}
            </button>

            {readOnly ? null : (
              <LoadingButton
                loading={formik.isSubmitting}
                disabled={isLoading}
                loadingPosition="start"
                startIcon={<CheckBadgeIcon />}
                type="button"
                variant="contained"
                color={"success"}
                onClick={formik.submitForm}
              >
                {system?.id ? t("Update") : t("Create")}
              </LoadingButton>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );

  const attachSystem = (formik) => (
    <div className="grid grid-cols-6 gap-6 mt-10">
      <div className="col-span-6 sm:col-span-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {t("Attach System")}
        </h3>
      </div>
      <div className="col-span-12 sm:col-span-12">
        <div className="flex items-center">
          <div className="w-full p-2">
            <PMListbox
              label={t("System")}
              options={systemOptions()}
              selected={null}
              onChange={onAttachSystem}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="w-full sm:max-w-2xl max-w-md h-[500px] transform overflow-auto rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="flex items-center justify-between w-full text-lg font-medium leading-6 text-gray-900"
                    >
                      <div className="relative">
                        {readOnly
                          ? t("Customer System")
                          : system?.id
                          ? t("Edit Customer System")
                          : t("Create Customer System")}
                      </div>

                      <div className="relative right-0 text-right">
                        {system?.id && (
                          <button
                            type="button"
                            className="mr-2 inline-flex items-center rounded-md border border-transparent bg-yellow-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => handleDetachSystem()}
                          >
                            <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                            <span className="ml-2">{t("Detach System")} </span>
                          </button>
                        )}
                        <button
                          onClick={() => setReadOnly(!readOnly)}
                          type="button"
                          className="inline-flex items-center rounded-md border border-transparent bg-blue-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          {readOnly ? (
                            <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                          ) : (
                            <PencilIcon
                              className="h-4 w-4 color-"
                              aria-hidden="true"
                            />
                          )}
                          <span className="ml-2">
                            {readOnly
                              ? system?.id
                                ? t("Edit")
                                : t("Create")
                              : t("read_only")}
                          </span>
                        </button>
                      </div>
                    </Dialog.Title>

                    {isLoading ? (
                      <ResourceLoading />
                    ) : readOnly && !customer?.id ? (
                      <ResourceError
                        errors={[
                          "Missing selected customer. Cannot load information",
                        ]}
                      />
                    ) : (
                      displaySystem()
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ProductSlideover
        open={productSlideroverOpen}
        onClose={() => setProductSlideoverOpen(false)}
        view="spareparts"
        productId={system?.product?.id}
      />
    </>
  );
}

CustomerSystemDialog.defaultProps = {
  selected: null,
  // Read only is used when only wanting to display info. Update or creating will not work.
  readOnly: false,
  open: false,
  onClose: () => toast.error("Missing onClose function. Cannot close dialog"),
};
