import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import Draggable from "react-draggable";
import { useParams } from "react-router-dom";
import { fetchDocument } from "../../api/document";
import { ResourceLoading } from "../../components/utils/ResourceLoading";

export default function PDFViewer() {
  // Fetch document from ID param
  const { id } = useParams();

  const [document, setDocument] = useState(null);
  const [blobUrl, setBlobUrl] = useState(null);
  const [loading, setLoading] = useState(true); // State for loading

  const getDocument = async () => {
    try {
      const response = await fetchDocument(id);
      setDocument(response.data);

      // Once the document is fetched, fetch the PDF file
      if (response.data?.file?.url) {
        const res = await fetch(response.data.file.url);
        const blob = await res.blob();
        const url = URL.createObjectURL(blob);
        setBlobUrl(url);
        setLoading(false); // PDF loading complete
      }
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  };

  useEffect(() => {
    if (id) {
      getDocument();
    }
  }, [id]);

  const [tags, setTags] = useState([]);

  const addTag = (x, y) => {
    const newTag = { id: Date.now(), x, y, checked: false };
    setTags([...tags, newTag]);
  };

  const handleDrag = (e, data, tagId) => {
    setTags(
      tags.map((tag) =>
        tag.id === tagId ? { ...tag, x: data.x, y: data.y } : tag
      )
    );
  };

  if (loading) {
    return <ResourceLoading />; // Show loading indicator while PDF is being fetched
  }

  return (
    <div className="px-4 py-4">
      <h1 className="text-2xl font-bold">{document?.file?.filename}</h1>
      <span>{document?.file?.url}</span>
      <Document file={blobUrl}>
        <Page pageNumber={1} scale={2.0} />
      </Document>
      {tags.map((tag) => (
        <Draggable
          key={tag.id}
          position={{ x: tag.x, y: tag.y }}
          onStop={(e, data) => handleDrag(e, data, tag.id)}
        >
          <div className="checklist-tag">
            <input
              type="checkbox"
              checked={tag.checked}
              // onChange={() => toggleTag(tag.id)}
            />
          </div>
        </Draggable>
      ))}
      <button
        onClick={() => addTag(100, 100)}
        className="fixed bg-blue-400 rounded-md shadow-md py-2 px-4 "
      >
        Add Checklist Tag
      </button>
    </div>
  );
}
