import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { PMButton } from "../../utils/PMButton";
import { DocumentImage } from "./utils";
import { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { PMPagination } from "../../utils/PMPagination";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { ResourceEmpty } from "../../utils/ResourceEmpty";
import { DocumentUploadSectionV2 } from "../Media/DocumentUploadSectionV2";
import { EditDocumentDialog } from "../Media/editDocumentDialog";
import SimplePrompt from "../../utils/simplePrompt";
import { deleteDocument } from "../../../api/document";
import { dateToString } from "../../Utils";
import { TWclassNames } from "../../utils/Div";

export const DocumentCenter = (props) => {
  const { module, moduleParam, allowedFileTypes, subtitle, title } = props;

  const [page, setPage] = useState(1);
  const per_page = 20;

  const [state, setState] = useState({
    totalCount: 0,
    documents: [],
    document_pages: 0,
    isLoading: false,
  });

  const { documents, isLoading, totalCount } = state;

  const getDocuments = async () => {
    // setState({ ...state, isLoading: true });

    const params = {
      module: module,
      module_param: moduleParam,
      page: page,
      per_page: per_page,
    };
    const response = await axios.get(`/documents`, { params });
    setState({
      totalCount: response.data.totalCount,
      documents: response.data.documents,
      document_pages: response.data.pages - 1,
      isLoading: false,
    });
    setTimeout(() => {
      // setState({ ...state, isLoading: false });
    }, 50);
  };

  useEffect(() => {
    getDocuments();
  }, []);

  const handleAddDocument = (data) => {
    // halt if data?.id is missing
    if (!data?.id) {
      return;
    }
    // add new document to state, bottom
    setState({
      ...state,
      documents: [...state.documents, data],
    });
  };

  const handleDocumentChange = (document) => {
    // find existing document in list
    const index = documents.findIndex((x) => x.id === document.id);

    let prevItems = [...documents];
    prevItems[index] = document;
    setState({
      ...state,
      documents: prevItems,
    });
  };

  const [selectedEditDocument, setSelectedEditDocument] = useState(null);

  const [editDocumentDialogOpen, setEditDocumentDialogOpen] = useState(false);

  const [selectedRemoveDocument, setSelectedRemoveDocument] = useState(null);

  const [submitRemoveDocumentPromptOpen, setSubmitRemoveDocumentOpen] =
    useState(false);

  const submitRemoveDocument = async (document) => {
    await deleteDocument(document?.id).then((res) => {
      // check if any of these statuses 200, 204 etc.
      if (res.status === 204 || res.status === 200) {
        const newDocuments = documents.filter(
          (item) => item.id !== document.id
        );
        setState({
          ...state,
          documents: newDocuments,
        });
      }
    });
  };

  console.log("selectedEditDocument", selectedEditDocument);

  if (isLoading) {
    return <ResourceLoading />;
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <h1 className="text-2xl font-bold">{title}</h1>
          <p className="text-sm text-gray-500"> {subtitle}</p>
        </div>
        <div className="flex items-center gap-2">
          <DocumentUploadSectionV2
            allowedFileTypes={allowedFileTypes}
            module={module}
            moduleParam={moduleParam}
            parentCallback={(data) =>
              // add to state >> documents
              {
                console.log(data);
                handleAddDocument(data);
              }
            }
          />
        </div>
      </div>

      {/* Documents Stacklist */}
      {documents?.length > 0 ? (
        <>
          <div className="flex flex-col gap-2 py-5">
            {documents.map((document) => (
              <div
                key={document.id}
                className="flex items-center justify-between w-full p-4 bg-white rounded-lg shadow-md"
              >
                <div className="flex">
                  <div className="h-30 w-30 flex-shrink-0">
                    <DocumentImage
                      // pathType={pathType}
                      document={document}
                      // onChange={(data) => {
                      //   handleDocumentChange(data);
                      // }}
                    />
                  </div>
                  <div className="flex left flex-col gap-1 px-4">
                    <h2 className="text-lg font-bold">
                      {document.title || "Missing title"}
                    </h2>

                    <p className="text-xs italic">{document?.file?.filename}</p>

                    <p className="text-xs italic">
                      created at: {dateToString(document?.created_at)}
                    </p>

                    <p
                      className={TWclassNames(
                        "text-md",
                        document?.public ? "text-green-500" : "text-red-500"
                      )}
                    >
                      {document?.public ? (
                        <span className="flex">
                          <CheckBadgeIcon className="h-5 w-5 mr-1" /> Public
                        </span>
                      ) : (
                        <span className="flex">
                          <ExclamationCircleIcon className="h-5 w-5 mr-1" />{" "}
                          Private
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="flex gap-2">
                  <PMButton
                    styleType="minimal"
                    className="text-md"
                    onClick={() => {
                      setSelectedEditDocument(document);
                      setEditDocumentDialogOpen(true);
                    }}
                  >
                    <PencilIcon className="h-5 w-5" />
                    <span className="px-1 py-0.5">Rediger</span>
                  </PMButton>
                  <PMButton
                    styleType="minimal"
                    className="text-md text-red-400"
                    onClick={() => {
                      setSelectedRemoveDocument(document);
                      setSubmitRemoveDocumentOpen(true);
                    }}
                  >
                    <TrashIcon className="h-5 w-5" />
                    <span className="px-1 py-0.5">Slett</span>
                  </PMButton>
                </div>
              </div>
            ))}
          </div>

          <div>
            <PMPagination
              perPage={per_page}
              onPageChange={(page) => setPage(page)}
              totalCount={totalCount}
              currentCount={documents.length}
            />
          </div>

          <SimplePrompt
            title="Sikker på at du vill slette dette dokumentet?"
            description="Dokumentet slettes permanent og kan ikke gjenopprettes."
            onAccept={() => submitRemoveDocument(selectedRemoveDocument)}
            open={submitRemoveDocumentPromptOpen}
            onClose={() => setSubmitRemoveDocumentOpen(false)}
          />

          <EditDocumentDialog
            document={selectedEditDocument}
            open={editDocumentDialogOpen}
            onClose={() => setEditDocumentDialogOpen(false)}
            onRefresh={(data) => {
              handleDocumentChange(data);
            }}
            editPublic={true}
          />
        </>
      ) : (
        <ResourceEmpty />
      )}
    </div>
  );
};

DocumentCenter.defaultProps = {
  title: "Dokumenter",
};
