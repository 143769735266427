import { useState } from "react";
import { toast } from "react-hot-toast";
import { ModuleTag } from "./moduleTag";

export const ValueTag = ({ value, label, onRefresh }) => {
    return (
      <>
        <ModuleTag label={label}>{value}</ModuleTag>
      </>
    );
};
