import axios from "../axios";

export async function getChecklistType(id) {
  return axios.get(`/quality/checklists/types/${id}`);
}

export async function getChecklistPdf(payload) {
  return axios.get(`/quality/checklists/${payload.id}/pdf`, {
    params: payload.params,
    type: "application/pdf",
  });
}

export async function fetchChecklists(controller) {
  return axios({
    method: "get",
    url: "/quality/checklists",
    params: controller,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchChecklist(id) {
  return axios.get(`/quality/checklists/` + id);
}

export async function createChecklist(payload) {
  return axios
    .post(`/quality/checklists`, { checklist: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function createChecklistItem(payload) {
  return axios
    .post(`/quality/checklists/${payload.checklist_id}/items`, {
      checklist_item: payload,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function deleteChecklistItem(payload) {
  return axios
    .delete(`/quality/checklists/${payload.checklist_id}/items/${payload.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function updateChecklist(payload) {
  return axios
    .put(`/quality/checklists/${payload.id}`, { checklist: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function printChecklist(payload) {
  return axios.put(`/quality/checklists/${payload.id}/print`, {
    checklist: payload,
  });
}

export async function deleteChecklist(payload) {
  const checklist = payload.checklist;
  return axios
    .delete(`/quality/checklists/${checklist.checklist_id}`, {
      data: JSON.stringify({
        checklist,
      }),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
