import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "./i18n";
import { DashboardLayout } from "./app/components/dashboard/dashboard-layout";
import { DashboardRoutes } from "./app/routes/dashboard";
import PrivateRoute from "./app/routes/privateRoute";
import PublicOnlyRoute from "./app/routes/publicOnlyRoute";
import PersistLogin from "./app/components/authentication/persistLogin";
import { AppRoutes } from "./app/routes/app";
import { HomePage } from "./app/pages/HomePage";
import { NotFound } from "./app/pages/NotFound";
import { StandardLayout } from "./app/components/dashboard/standard-layout";
import { SettingsProvider } from "./app/contexts/settings-context";
import AuthVerify from "./app/components/authentication/authVerify";
import SessionExpiredModal from "./app/components/authentication/sessionExpiredDialog";
import { DashboardRoleRoute } from "./app/routes/dashboardRoleRoute";
import { DashboardPageNotFound } from "./app/pages/dashboard/DashboardPageNotFound";
import { Logout } from "./app/pages/authentication/Logout";
import InternetConnectionStatus from "./app/components/authentication/internetConnectionStatus";

function renderLayout(props) {
  const { children, title } = props;
  return <StandardLayout title={title}>{children}</StandardLayout>;
}

function App() {
  useEffect(() => {
    document.body.classList.add("h-full", "bg-gray-100");
  });

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route element={<PersistLogin />}>
        <Route
          path="/dashboard"
          element={
            <>
              <InternetConnectionStatus />
              <SessionExpiredModal />
              <AuthVerify />
              <PrivateRoute>
                <SettingsProvider>
                  <DashboardLayout />
                </SettingsProvider>
              </PrivateRoute>
            </>
          }
        >
          <Route path="/dashboard/*" element={<DashboardPageNotFound />} />
          {DashboardRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                // path={route.path}
                element={<route.layout title={route.title} />}
              >
                {index === 0 && (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <DashboardRoleRoute role={route.role}>
                        <route.element />
                      </DashboardRoleRoute>
                    }
                  />

                  // <DashboardRoleRoute
                  //   key={index}
                  //   path={route.path}
                  //   role={route.role}
                  //   element={<route.element />}
                  // />
                )}

                {route.children
                  ? route.children.map((childRoute, childIndex) => (
                      <Route
                        key={childIndex}
                        path={childRoute.path}
                        element={
                          <DashboardRoleRoute role={childRoute.role}>
                            <childRoute.element />
                          </DashboardRoleRoute>
                        }
                      />
                    ))
                  : ""}
              </Route>
            );
          })}
        </Route>
        {/* Public routes */}
        <Route path="/" element={<HomePage />} />
        <Route path="/authentication/logout" element={<Logout />} />
        <Route path="/">
          {AppRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <PublicOnlyRoute>
                    <route.element />
                  </PublicOnlyRoute>
                }
              />
            );
          })}
        </Route>
        {/* Public routes END */}
      </Route>
    </Routes>
  );
}

export default App;
