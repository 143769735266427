import axios from "axios";

export async function fetchBringPostalNumber(pnr) {
  const url = "https://api.bring.com/shippingguide/api/postalCode.json";
  const params = {
    pnr: pnr,
    clientUrl: "https://api.promang.no",
  };
  return await axios.get(url, { params: params });
}
