import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createProjectFolder,
  deleteProjectFolder,
  fetchProjectFolders,
  updateProjectFolder,
} from "../../api/project/folder";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  totalCount: 0,
  currentCount: 0,
};

export const initialController = {
  filters: [],
  page: 1,
  per_page: 200,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const resetProjectFolders = createAsyncThunk(
  "projectFolders/resetProjectFolders",
  async () => {
    return null;
  }
);

export const deleteProjectFolderAsync = createAsyncThunk(
  "projectFolders/deleteProjectFolderAsync",
  async (payload) => {
    let response = await deleteProjectFolder(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response.data;
  }
);

export const createProjectFolderAsync = createAsyncThunk(
  "projectFolders/createProjectFolderAsync",
  async (payload) => {
    let response = await createProjectFolder(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response.data;
  }
);

export const updateProjectFolderAsync = createAsyncThunk(
  "projectFolders/updateProjectFolderAsync",
  async (payload, { rejectWithValue }) => {
    let response = await updateProjectFolder(payload);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/)) return rejectWithValue;

    // If all good, return response.
    return response.data;
  }
);
export const getProjectFoldersAsync = createAsyncThunk(
  "projectFolders/getProjectFoldersAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchProjectFolders(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response.data;
  }
);

const projectFolderSlice = createSlice({
  name: "projectFolders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProjectFoldersAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProjectFoldersAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.currentCount = action.payload.currentCount;
      })
      .addCase(getProjectFoldersAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch ProjectFolders....is controller missing?",
        ];
      })
      .addCase(updateProjectFolderAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateProjectFolderAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.isLoading = false;
          draftState.error = false;
          draftState.errors = null;
        });
      })
      .addCase(updateProjectFolderAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update ProjectFolder.",
        ];
      })
      .addCase(createProjectFolderAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createProjectFolderAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
          draftState.error = false;
          draftState.errors = null;
        });
      })
      .addCase(createProjectFolderAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to create ProjectFolder.",
        ];
      })
      .addCase(deleteProjectFolderAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteProjectFolderAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data = action.payload.data;
          draftState.isLoading = false;
        });
      })
      .addCase(deleteProjectFolderAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to delete ProjectFolder.",
        ];
      })
      .addCase(resetProjectFolders.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(resetProjectFolders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.totalCount = 0;
        state.currentCount = 0;
      })
      .addCase(resetProjectFolders.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = [action.payload.error];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = projectFolderSlice;

export default projectFolderSlice;
