import axios from '../axios'


export async function deleteCount(payload) {
    return axios.delete(`/inventory/counts/` + payload )
        .then((response) => {
        return response.data
    })
        .catch((error) => {
        return error.response.data
    });
}

export async function createCount(payload) {
    return axios.post(`/inventory/counts`, { inventory_count: payload } )
    .then((response) => {
        return response.data
    })
    .catch((error) => {
        return error.response.data
    });
}


export async function updateCount(payload) {
    return axios.put(`/inventory/counts/` + payload.id, { inventory_count: payload } )
    .then((response) => {
        return response.data
    })
    .catch((error) => {
        return error.response.data
    });
}

export async function fetchCounts() {
    return axios.get(`/inventory/counts` )
    .then((response) => {
        return response.data

    })
    .catch((error) => {
        return error.response.data
    });
}

export async function fetchCount(payload) {
    return axios.get(`/inventory/counts/` + payload)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return error.response.data
        });
}