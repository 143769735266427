import {
  CalendarDaysIcon,
  CheckIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

// Icons depending on action
export const historyActionTypes = {
  "create": { icon: PlusIcon, bgColorClass: "bg-green-400" },
  "update": { icon: CheckIcon, bgColorClass: "bg-blue-400" },
  "destroy": { icon: TrashIcon, bgColorClass: "bg-red-400" },
  "new_agreement": { icon: CalendarDaysIcon, bgColorClass: "bg-pink-400" },
};

export const historyControllers = {
  "user": "users",
  "report": "rapport",
  "order": "ordre",
};

export const historyActions = {
  "processed": "processed",
  "update": "updated",
  "create": "created",
  "destroy": "removed",
  "new_agreement": "updated date agreement for",
  "new_message": "sendt en SMS",
  "answer": "mottatt svar fra kunde",
  "update_product": "updated",
};
