import { useState } from "react";
import CustomerDialog from "../../dashboard/customers/dialog";
import { toast } from "react-hot-toast";
import { ModuleTag } from "./moduleTag";

export const CustomerTag = ({ customer, onRefresh }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const labelAction = {
    // path: "/dashboard/customers/" + customer?.id,
    action: true,
  };

  const handleOpenDialog = () => {
    if (customer?.id) {
      setDialogOpen(true);
    } else {
      toast.error("Du må velge kunde først");
    }
  };
  return (
    <>
      <ModuleTag
        label="Customer"
        labelAction={labelAction}
        onAction={() => {
          setDialogOpen(true);
        }}
      >
        {customer?.displayTitle}
      </ModuleTag>

      <CustomerDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        readOnly={true}
        selected={customer}
        onRefresh={onRefresh}
      />
    </>
  );
};
