import toast from "react-hot-toast";
import axios from "./axios";

export async function checkCustomerNumber(payload) {
  return axios.get(`/customers/checkNumber`, {
    params: payload,
  });
}

export async function checkCustomerEmail(payload) {
  return axios.get(`/customers/checkEmail`, {
    params: payload,
  });
}

export async function getCustomerAgreements(customerId) {
  return axios
    .get(`/customers/${customerId}/agreements`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchCustomers(controller) {
  return axios({ method: "get", url: "/customers", params: controller })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchCustomer(id) {
  return axios.get(`/customers/` + id);
}

export async function createCustomer(payload) {
  return axios
    .post(`/customers/`, { customer: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return {};
    });
}
export async function updateCustomer(payload) {
  return axios
    .put(`/customers/${payload.id}`, { customer: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function destroyCustomer(payload) {
  return axios
    .delete(`/customers/${payload.id}`, {
      data: JSON.stringify({
        payload,
      }),
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function fetchCustomerContacts(customerId) {}

export async function fetchCustomerContact(customerId, contactId) {}

export async function createCustomerContact(payload) {}
