import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { OrdersTable } from "../../../components/dashboard/orders/orders-table";
import { ProductsFilter } from "../../../components/dashboard/products/products-filter";
import { getOrdersAsync } from "../../../slices/order";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import {
  ArrowPathRoundedSquareIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import { mapErrors, updateUrlWithException } from "../../../components/Utils";
import { syncModule } from "../../../api/company";
import SimplePrompt from "../../../components/utils/simplePrompt";
import { debounce } from "lodash";
import {
  orderStatusOptions,
  ordersViewsOptions,
} from "../../../components/dashboard/orders/utils";
import { useSearchParams } from "react-router-dom";

export const OffersList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [controller, setController] = useState({
    filters: searchParams.get("filters") ? searchParams.get("filters") : null,
    page: searchParams.get("page") ? searchParams.get("page") : 1,
    per_page: searchParams.get("per_page") ? searchParams.get("per_page") : 50,
    query: searchParams.get("query") ? searchParams.get("query") : "",
    sort: searchParams.get("sort") ? searchParams.get("sort") : "DESC",
    sortBy: searchParams.get("sortBy")
      ? searchParams.get("sortBy")
      : "order_number",
    view: searchParams.get("view") ? searchParams.get("view") : "all",
    fields: "id,order_number,order_date,customer,project,status,total_amount",
  });

  const itemState = useSelector((store) => {
    return store.orders;
  });

  // Update all params from controller except filters (dont't touch filters)
  useEffect(() => {
    updateUrlWithException(
      // These will be updated
      {
        page: controller.page,
        per_page: controller.per_page,
        query: controller.query,
        sort: controller.sort,
        sortBy: controller.sortBy,
        view: controller.view,
      },
      // This will be preserved
      "filters"
    );
  }, [controller]);

  const { isLoading, error, errors } = itemState;
  const [selectedItems, setSelectedItems] = useState([]);

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery,
    });
  };

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleFilterChange = (selectedFilters) => {
    setController({
      ...controller,
      filters: selectedFilters?.map((filter) => {
        return {
          name: filter.id,
          value: JSON.stringify(filter.values),
        };
      }),
    });
  };

  const debouncedDispatchAction = debounce((controller) => {
    dispatch(getOrdersAsync(controller));
  }, 250);

  useEffect(() => {
    // Trigger the debounced dispatch action
    debouncedDispatchAction(controller);

    // Clean the debounced function on component unmount.
    return () => {
      debouncedDispatchAction.cancel();
    };
  }, [controller, dispatch]);

  const [syncState, setSyncState] = useState({
    isLoading: false,
    error: false,
    errors: [],
    updated: 0,
    created: 0,
    found: 0,
  });

  const runOrderSync = () => {
    return syncModule("orders")
      .then((res) => {
        setSyncState((prevState) => ({
          ...prevState,
          isLoading: false,
          updated: res.data.products_updated,
          created: res.data.products_created,
          found: res.data.products_found,
        }));
        toast.success(
          `${syncState.updated} was updated out of ${syncState.found} - ${syncState.created} was created`
        );
        return res;
      })
      .catch((err) => {
        // Updating ItemState
        setSyncState((prevState) => ({
          ...prevState,
          isLoading: false,
          updated: 0,
          created: 0,
          found: 0,
        }));
        return err;
      });
  };

  const SyncOrders = () => {
    setSyncState({
      isLoading: true,
      error: false,
      errors: [],
      products_updated: 0,
      products_created: 0,
      products_found: 0,
    });

    toast.promise(runOrderSync(), {
      loading: t("Syncing...."),
      success: (res) => {
        if (res.data.errors) throw new Error(mapErrors(res.data.errors));
        if (res.status === 500) throw new Error("Server error");
        return `Sync was successfull`;
      },
      error: t("Something went wrong. Could not save data!"),
    });
  };

  const [syncPromptState, setSyncPromptState] = useState(false);

  const headerActions = [
    {
      component: null,
      label: "Sync API",
      description: "",
      path: null,
      action: () => setSyncPromptState(true),
      icon: ArrowPathRoundedSquareIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: true,
    },
  ];

  const initialFilters = [
    {
      id: "view",
      name: "View",
      options: ordersViewsOptions,
    },
    {
      id: "status",
      name: "Status",
      options: orderStatusOptions,
    },
  ];

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={"Orders"}
        meta={[]}
        mainButton={{
          label: "New",
          action: null,
          path: "/dashboard/orders/new",
          color: "",
          icon: PlusIcon,
        }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            <ProductsFilter
              className={"mt-10"}
              disabled={isLoading}
              onQueryChange={handleQueryChange}
              query={controller.query}
              selectedItems={selectedItems}
              initialFilters={initialFilters}
              onFilterChange={handleFilterChange}
              defaultSelectedOptions={[
                // { name: "status", value: "new" },
                { name: "view", value: "active" },
              ]}
            />

            <OrdersTable
              itemState={itemState}
              itemsCount={itemState.totalCount}
              currentCount={itemState.currentCount}
              page={controller.page}
              onPageChange={handlePageChange}
              perPage={controller.per_page}
            />
          </div>
        </div>
      </DashboardHeader>
      <SimplePrompt
        title="Sync av ordre"
        description="Er du sikker på at du ønsker å synke ordrer nå?"
        onAccept={() => SyncOrders()}
        open={syncPromptState}
        onClose={() => setSyncPromptState(false)}
      />
    </>
  );
};
