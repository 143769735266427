import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { FullPageLoading } from "../components/utils/Loading";
import { RootState } from "../store";

function PrivateRoute({ children }: any) {
  const loading = useSelector((state: RootState) => state.session.loading);
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const location = useLocation();
  const fromLocation = (location.state as any)?.from;
  const previousLocation = fromLocation
    ? fromLocation
    : { pathname: "/authentication/login" };
  const dispatch = useDispatch();
  // Adds nessecary authorization headers to API requests.

  if (accessToken && currentUser) {
    return children;
  } else if (loading) {
    return <FullPageLoading />;
  } else if (!accessToken && !loading) {
    return (
      <Navigate to={previousLocation} state={{ from: location }} replace />
    );
  } else {
    return <p>Something went wrong</p>;
  }
}

export default PrivateRoute;
