import axios from "../axios";

export async function fetchModuleTagMentions(data) {
  return axios({
    method: "get",
    url: `/module_tags/mentions/${data.module}/${data.id}`,
    params: data.controller,
  });
}

export async function fetchModuleTagMentionStatus(data) {
  return axios({
    method: "get",
    url: `/module_tags/mentions/${data.module}/${data.id}/status`,
  });
}
