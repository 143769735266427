import { DocumentCenter } from "../../../../components/dashboard/documents/document-center";

export const ServiceAgreementDocuments = (props) => {
  const { moduleParam } = props;

  return (
    <>
      <DocumentCenter
        subtitle="Kun PDF filer er tillatt"
        module="service_agreement_documents"
        allowedFileTypes="application/pdf"
        moduleParam={moduleParam}
      />
    </>
  );
};
