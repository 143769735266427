import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchOrders,
  updateOrder,
  createOrder,
  deleteOrder,
  fetchOrder,
} from "../api/order";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  order: {},
  totalCount: 0,
  currentCount: 0,
  newestOrdersCount: 0,
};

export const initialController = {
  filters: [],
  page: 1,
  per_page: 200,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const deleteOrderAsync = createAsyncThunk(
  "orders/deleteOrderAsync",
  async (payload) => {
    let response = await deleteOrder(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createOrderAsync = createAsyncThunk(
  "orders/createOrderAsync",
  async (payload, { rejectWithValue }) => {
    let response = await createOrder(payload);

    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/)) return rejectWithValue;

    // If all good, return response.
    return response;
  }
);

export const updateOrderAsync = createAsyncThunk(
  "orders/updateOrderAsync",
  async (payload, { rejectWithValue }) => {
    let response = await updateOrder(payload);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/)) return rejectWithValue;

    // If all good, return response.
    return response;
  }
);
export const getOrdersAsync = createAsyncThunk(
  "orders/getOrdersAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchOrders(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

export const getOrderAsync = createAsyncThunk(
  "orders/getOrderAsync",
  async (id, { rejectWithValue }) => {
    let response = await fetchOrder(id);

    if (response.error) return false;

    return response;
  }
);

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrdersAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getOrdersAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.currentCount = action.payload.currentCount;
        state.newestOrdersCount = action.payload.newestOrdersCount;
      })
      .addCase(getOrdersAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch Orders....is controller missing?",
        ];
      })
      .addCase(updateOrderAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateOrderAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.order = action.payload;
          draftState.isLoading = false;
          draftState.error = false;
          draftState.errors = null;
        });
      })
      .addCase(updateOrderAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update Order."];
      })
      .addCase(createOrderAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createOrderAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
          draftState.error = false;
          draftState.errors = null;
        });
      })
      .addCase(createOrderAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update Order."];
      })
      .addCase(deleteOrderAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteOrderAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data = action.payload.data;
          draftState.isLoading = false;
        });
      })
      .addCase(deleteOrderAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update Order."];
      })
      .addCase(getOrderAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getOrderAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.order = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(getOrderAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update Order."];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = orderSlice;

export default orderSlice;

export const selectOrderById = (state, orderId) =>
  state.orders.data.find((order) => order.id == orderId);
