import { useTranslation } from "react-i18next";
import PMListboxFormik from "../../../../utils/PMListboxFormik";
import { QuillEditor } from "../../../../utils/quill-editor";
import { TextGeneratorButton } from "../../../ai/text-generator-button";
import { TemplateDialogButton } from "../../../templates/template-dialog-button";

export const ReportReportForm = ({ formik, report }) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-6 gap-6 mt-10">
      <div className="col-span-6 sm:col-span-6">
        <div className="flex justify-between">
          <label
            htmlFor="name"
            className="relative text-sm font-bold text-gray-700 b-0 mb-0 pb-0"
          >
            {t("Report")}
          </label>
          <div className="flex mb-2 ml-2">
            <div className="px-2 py-1">
              <TemplateDialogButton
                className="rounded-md bg-blue-400"
                styleType="primary"
                fieldName="report"
                {...formik}
              />
            </div>

            <TextGeneratorButton
              module={"report"}
              module_param={formik?.values?.id}
              text={formik?.values?.report}
              onGenerate={(data) => {
                formik.setFieldValue("report", data?.text);
              }}
            />
          </div>
        </div>
        <QuillEditor name="report" rows={4} {...formik} />
      </div>

      <div className="col-span-6 sm:col-span-6">
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700"
        >
          <label> {t("Internal Report")} </label>
        </label>
        <QuillEditor name="internal_report" rows={4} {...formik} />
      </div>

      {!report?.checklist?.id && (
        <div className="col-span-6 sm:col-span-3 mb-20">
          <PMListboxFormik
            label={t("Must be Rectified")}
            name="must_be_rectified"
            options={[
              {
                name: "must_be_rectified",
                value: true,
                label: t("Yes"),
              },
              {
                name: "must_be_rectified",
                value: false,
                label: t("No"),
              },
            ]}
            {...formik}
          />
        </div>
      )}

      <div className="col-span-6 sm:col-span-3 mb-20">
        <PMListboxFormik
          label={t("Complaint")}
          name="complaint"
          options={[
            {
              name: "complaint",
              value: true,
              label: t("Yes"),
            },
            {
              name: "complaint",
              value: false,
              label: t("No"),
            },
          ]}
          {...formik}
        />
      </div>
    </div>
  );
};
