import { toast } from "react-hot-toast";
import { PMDialog } from "../../../utils/PMDialog";
import { PMField } from "../../../utils/PMField";
import { Form, Formik, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { dispatchWithToast } from "../../../Utils";
import { useDispatch } from "react-redux";
import {
  createDeviationAsync,
  updateDeviationAsync,
} from "../../../../slices/quality/deviation";
import { useTranslation } from "react-i18next";
import { UploaderV2 } from "../../Media/Uploaderv2";
import { DocumentViewer } from "../../Media/documentViewer";
import PMCombobox from "../../../utils/PMCombobox";
import {
  getDeviationTypeOptions,
  priorityOptions,
  severityOptions,
} from "../deviations/utils";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DocumentUploadSectionV2 } from "../../Media/DocumentUploadSectionV2";

export const ChecklistDeviationDialog = (props) => {
  const { open, onClose, checklistItem, checklist } = props;

  const { customer, project, folder } = checklist || {};

  const [deviation, setDeviation] = useState(checklistItem?.deviation);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    setDeviation(checklistItem?.deviation);
  }, [checklistItem]);

  const [isNewDev, setIsNewDev] = useState(false);

  // Set isNewDev to true if deviation is null
  useEffect(() => {
    if (deviation?.id) {
      setIsNewDev(false);
    } else {
      setIsNewDev(true);
    }
  }, [deviation]);

  const createOrUpdateDispatch = (data) => {
    if (deviation?.id) {
      const res = dispatch(updateDeviationAsync(data));
      return res;
    } else {
      const res = dispatch(createDeviationAsync(data));
      return res;
    }
  };

  const [uploadedDocs, setUploadedDocs] = useState([]);

  const [documents, setDocuments] = useState([]);

  // const UploaderCallback = (data, formik) => {
  //   setIsLoading(true);

  //   formik.setFieldValue("documents", [...formik.values.documents, data]);

  //   setIsLoading(false);
  // };

  const UploaderCallback = (newDocuments, formik) => {
    // Add documents to document state
    const newArray = documents.concat(newDocuments);

    setDocuments(newArray);

    // Add documents to formik
    formik.setFieldValue("documents", newArray);

    setIsLoading(false);
  };

  const [isLoading, setIsLoading] = useState(false);

  const [deviationTypeOptions, setDeviationTypeOptions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const fetchedOptions = await getDeviationTypeOptions();
      setDeviationTypeOptions(fetchedOptions);
    }
    if (open) {
      fetchData();
    }
  }, [open]);

  const currentUser = useSelector((store) => {
    return store.session.currentUser;
  });

  const handleDescriptionItemChange = (e, formik) => {
    const { name, value } = e.target;
    const items = [...formik.values.items];
    items[0].description = value;
    formik.setFieldValue("items", items);
  };

  const NewDeviationForm = () => {
    return (
      <Formik
        initialValues={{
          id: null,
          title: null,
          severity: null,
          priority: null,
          customer_id: customer?.id,
          project_id: project?.id,
          deviation_type_id: null,
          deviation_date: new Date().toISOString().substring(0, 10),
          description: "",
          responsible_user_id: currentUser?.id,
          assigned_user_id: currentUser?.id,
          folder_id: folder?.id,
          documents: deviation?.documents || [],
          items: [
            {
              date: new Date().toISOString().slice(0, 10),
              description: null,
              action: "MEASURES_REQUIRED",
            },
          ],
          checklist_item_id: checklistItem?.id,
          submit: null,
        }}
        validationSchema={Yup.object({
          // customer_id: Yup.string().required("Customer is required"),
          // project_id: Yup.string().required("Customer is required"),
        })}
        onSubmit={(values, helpers) => {
          try {
            dispatchWithToast(
              dispatch(createDeviationAsync(values)),
              (data) => props.onRefresh(data, checklistItem),
              onClose(true)
            );

            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
          } catch (err) {
            console.log(err);
            toast.error("Something went wrong...");
            console.error(err);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <div className="grid grid-cols-6 gap-6 py-10">
              <div className="col-span-6 sm:col-span-6">
                <PMField name="title" label={t("Title")} {...formik} />
              </div>

              <div className="col-span-6 sm:col-span-6">
                <label htmlFor="description" className="font-sm text-gray-700">
                  {t("Tiltak")}
                </label>
                <textarea
                  // disabled={isLoading}
                  wrap="off"
                  rows={4}
                  name="description"
                  id="description"
                  className="mt-2 block w-full pt-2.5 resize-none border-2 py-0 text-gray-900 border-blue-150 rounded-md shadow-md placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 disabled:bg-gray-400 "
                  placeholder="Write a description..."
                  value={formik.values.items[0].description}
                  onChange={(e) => handleDescriptionItemChange(e, formik)}
                  defaultValue={""}
                />
              </div>

              <div className="col-span-6 sm:col-span-6">
                <PMCombobox
                  name="deviation_type_id"
                  label={t("Deviation Type")}
                  options={deviationTypeOptions}
                  {...formik}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <PMCombobox
                  name="severity"
                  label={t("Severity")}
                  options={severityOptions}
                  {...formik}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <PMCombobox
                  name="priority"
                  label={t("Priority")}
                  options={priorityOptions}
                  {...formik}
                />
              </div>
            </div>

            <div className="flex flex-col users-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
              {/* <UploaderV2
                key={checklistItem?.id}
                disabled={isLoading}
                module="checklist_item"
                module_param={checklistItem?.id}
                size="xs"
                version={1}
                parentCallback={(data) => UploaderCallback(data, formik)}
                // onLoading={() => setIsLoading(true)}
              /> */}

              <DocumentUploadSectionV2
                key={checklistItem?.id}
                disabled={isLoading}
                module="checklist_item"
                module_param={checklistItem?.id}
                parentCallback={(data) => UploaderCallback(data, formik)}
              />
              <div className="mt-5 mb-10">
                <DocumentViewer
                  size="small"
                  pathType="quickview"
                  documents={formik?.values?.documents}
                />
              </div>
            </div>

            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={onClose}
              >
                {t("Close")}{" "}
              </button>

              <button
                type="button"
                className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={formik.submitForm}
              >
                {formik?.values?.id ? t("Update") : t("Create")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  const Deviation = () => {
    return (
      <div className="relative border-2 border-blue-400 w-full px-5 py-10 rounded-md shadow-md hover:bg-blue-300 cursor-pointer">
        <Link to={"/dashboard/quality/deviations/" + deviation?.id}>
          <span>{deviation?.displayTitle}</span>
        </Link>
      </div>
    );
  };

  return (
    <PMDialog open={open} onClose={onClose} title="Opprett avvik">
      {isNewDev ? (
        <NewDeviationForm />
      ) : (
        <div>
          <Deviation />
        </div>
      )}
    </PMDialog>
  );
};

ChecklistDeviationDialog.defaultProps = {
  open: false,
  onClose: () => toast.error("Missing onClose function"),
};
