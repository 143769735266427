import { PlusIcon, WrenchIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import ServiceReportModal from "../../../components/dashboard/service/reports/service-report-edit-modal";
import { ServiceLayout } from "../../../components/dashboard/service/service-layout";
import ServiceAgreementTypesStacklist from "../../../components/dashboard/service/settings/service-agreement-types-stacklist";
import ServiceReportTypesStacklist from "../../../components/dashboard/service/settings/service-report-types-stacklist";
import ServiceVariableTypesStacklist from "../../../components/dashboard/service/settings/service-variable-types-stacklist";
import PMLinkCard from "../../../components/utils/PMLinkCard";
import { getAgreementTypesAsync } from "../../../slices/service/agreement-types";
import { getReportTypes } from "../../../slices/service/report-types";
import { getVariableTypesAsync } from "../../../slices/service/variable-types";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import { TWclassNames } from "../../../components/utils/Div";
import { Form, Formik } from "formik";
import { PMField } from "../../../components/utils/PMField";
import * as Yup from "yup";
import { fetchCompany } from "../../../api/company";
import { useTranslation } from "react-i18next";
import { updateCompanyAsync } from "../../../slices/company";
import { FormikSaveBar } from "../../../components/utils/FormikSaveBar";
import { debounce } from "lodash";
import { QuillEditor } from "../../../components/utils/quill-editor";

export const ServiceSettings = (props) => {
  const { ...other } = props;
  const [newServiceReportModalOpen, setNewServiceReportModalOpen] =
    useState(false);
  const dispatch = useDispatch();

  const [controller, setController] = useState({
    filters: [],
    page: 1,
    query: "",
    sort: "desc",
    sortBy: "updated_at",
    view: "all",
  });

  useEffect(() => {
    dispatch(getReportTypes(controller));
    dispatch(getAgreementTypesAsync(controller));
    dispatch(getVariableTypesAsync(controller));
  }, [dispatch]);

  const [selectedTab, setSelectedTab] = useState(0);

  const [company, setCompany] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState([]);

  const getCompany = () => {
    setIsLoading(true);
    fetchCompany({
      fields: "report_finished_email,service_conditions",
    })
      .then((res) => {
        // Set companyState
        setIsLoading(false);
        setCompany(res.data);
      })
      .catch((error) => {
        // Set companyState (errors, error and isLoading)
        setError(true);
        setErrors(error.data.errors);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCompany();
  }, []);

  const ServiceTypes = () => (
    <div className="grid grid-cols-2 max-sm:grid-cols-1  gap-5">
      <div className="grid-cols-1">
        <ServiceAgreementTypesStacklist />
      </div>
      <div className="grid-cols-1">
        <ServiceReportTypesStacklist />
      </div>
      <div className="grid-cols-1">
        <ServiceVariableTypesStacklist />
      </div>
    </div>
  );

  const { t } = useTranslation();

  const debouncedSave = useCallback(
    debounce((values, submitForm) => {
      submitForm();
    }, 2000), // 2000ms = 2 seconds delay
    []
  );
  const OtherForm = () => (
    <div className="">
      <Formik
        initialValues={{
          report_finished_email: company?.report_finished_email,
          service_conditions: company?.service_conditions,
          submit: null,
        }}
        // validationSchema={Yup.object({
        //   report_fininshed_email: Yup.string()
        //     .max(255)
        //     .required(t("customer.name.required")),
        // })}
        onSubmit={(values, helpers) => {
          try {
            toast.promise(
              dispatch(
                updateCompanyAsync(values, {
                  fields: "report_finished_email,service_conditions",
                })
              ),
              {
                loading: t("Saving...."),
                success: (data) => {
                  if (data.status === 500) throw new Error("Server error");
                  setCompany(data?.payload?.data?.data);
                  return t("Data was saved!");
                },
                error: t("Something went wrong. Could not save data!"),
              }
            );

            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
          } catch (err) {
            toast.error("Nope");
            console.error(err);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form className=" w-full">
            <div className="grid grid-cols-3 gap-6 mt-5">
              <div className="col-span-3 sm:col-span-3">
                <div className="shadow sm:overflow-hidden sm:rounded-md">
                  <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                    <div className="col-span-6 sm:col-span-6">
                      <PMField
                        name="report_finished_email"
                        type="text"
                        label="Varsel om ferdigstilt rapport sendes til (e-post)"
                        {...formik}
                        onChange={(e) => {
                          console.log(e);
                          formik.handleChange(e);
                          debouncedSave(formik.values, formik.submitForm);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-3 sm:col-span-3">
                <div className="shadow sm:overflow-hidden sm:rounded-md space-y-6 bg-white py-6 px-4 sm:p-6">
                  <QuillEditor
                    name="service_conditions"
                    type="text"
                    label="Betingelser"
                    subtitle="Sendes også med e-post ved bekreftelse av avtale"
                    {...formik}
                    onChange={(e) => {
                      formik.handleChange({
                        target: { name: "service_conditions", value: e },
                      });
                      debouncedSave(formik.values, formik.submitForm);
                    }}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );

  const tabs = [
    { name: "Typer", href: "#", component: <ServiceTypes /> },

    {
      name: "Annet",
      href: "#",
      component: <OtherForm />,
    },
  ];

  function userTabs() {
    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            onChange={(e) => setSelectedTab(e.target.value)}
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={tabs[selectedTab].name}
          >
            {tabs.map((tab, tabIdx) => (
              <option key={tab.name} value={tabIdx}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav
            className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
            aria-label="Tabs"
          >
            {tabs.map((tab, tabIdx) => (
              <a
                key={tab.name}
                onClick={() => setSelectedTab(tabIdx)}
                className={TWclassNames(
                  tabIdx === selectedTab
                    ? "text-gray-900"
                    : "text-gray-500 hover:text-gray-700",
                  tabIdx === 0 ? "rounded-l-lg" : "",
                  tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                  "cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                )}
                aria-current={tabIdx === selectedTab ? "page" : undefined}
              >
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={TWclassNames(
                    tabIdx === selectedTab ? "bg-indigo-500" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </a>
            ))}
          </nav>
        </div>
      </div>
    );
  }

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={[]}
        title={"Service Settings"}
        meta={[]}
        // mainButton={{
        //   label: "New",
        //   action: null,
        //   path: "/dashboard/orders/new",
        //   color: "",
        //   icon: PlusIcon,
        // }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            <div className="grid grid-cols-1 gap-5">
              <div className="grid grid-cols-1 max-sm:hidden">
                {userTabs()}
                {tabs[selectedTab].component}
              </div>
            </div>
          </div>
        </div>
      </DashboardHeader>
      <ServiceReportModal
        onClose={() => setNewServiceReportModalOpen(false)}
        open={newServiceReportModalOpen}
        itemId={0}
      />
    </>
  );
};
