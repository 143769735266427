import { toast } from "react-hot-toast";
import SimplePrompt from "../../utils/simplePrompt";
import { useState } from "react";

export const ProductActionEditBar = ({
  selectedItems,
  setMultiEditDialogOpen,
  setPriceChangeDialogOpen,
  onDelete,
}) => {
  const [deletePrompt, setDeletePrompt] = useState(false);

  return (
    <div className="flex items-center justify-between">
      <div className="">
        <span>{selectedItems.length} selected</span>
      </div>
      <div className="px-2 py-2">
        {setMultiEditDialogOpen && (
          <button
            onClick={() => setMultiEditDialogOpen(true)}
            type="button"
            className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
          >
            Edit
          </button>
        )}
        {setPriceChangeDialogOpen && (
          <button
            onClick={() => setPriceChangeDialogOpen(true)}
            type="button"
            className="inline-flex items-center rounded ml-2 bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
          >
            Prisjustering
          </button>
        )}
        {onDelete && (
          <button
            onClick={() => setDeletePrompt(true)}
            type="button"
            className="inline-flex items-center rounded ml-2 bg-red-400 px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-red-300 hover:bg-red-200 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
          >
            Slett
          </button>
        )}
      </div>

      <SimplePrompt
        title="Slett rapport"
        description="Er du sikker på at du ønsker å slette rapporten?"
        onAccept={() => onDelete()}
        open={deletePrompt}
        onClose={() => setDeletePrompt(false)}
      />
    </div>
  );
};
