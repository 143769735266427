import axios from "./axios";

export async function fetchUsers(controller) {
  let { filters, sort, sortBy, page, query, view, per_page } = controller;

  const params = {
    query: query,
    page: page,
    per_page: per_page,
    sortBy: sortBy,
    sort: sort,
    filters: filters,
    view: view,
  };

  return axios({ method: "get", url: "/users/", params: params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchUser(id, params = {}) {
  return axios({ method: "get", url: `/users/${id}`, params: params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchRoles() {
  return axios({ method: "get", url: `/users/roles` })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
