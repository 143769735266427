import { CameraIcon } from "@heroicons/react/20/solid";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { fetchReport } from "../../../../api/service/report";
import { ResourceLoading } from "../../../../components/utils/ResourceLoading";
import { ResourceError } from "../../../../components/utils/ResourceError";
import { ResourceEmpty } from "../../../../components/utils/ResourceEmpty";
import {
  FullReportAddress,
  FullReportTitle,
  ReportAppointmentApprovedStatus,
  ReportInvoiceStatus,
  ReportMustBeRectified,
  ReportStatus,
} from "../../../../components/dashboard/service/reports/utils";
import { Notes } from "../../../../components/dashboard/notes/notes";
import { ReportStatusButton } from "../../../../components/dashboard/service/reports/report-status-button";
import DashboardHeader from "../../../../components/dashboard/dashboardHeader";
import {
  ArrowPathRoundedSquareIcon,
  AtSymbolIcon,
  BuildingOfficeIcon,
  CheckBadgeIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon,
  MapPinIcon,
  PencilIcon,
  PrinterIcon,
  UsersIcon,
  WrenchIcon,
  PhoneIcon,
  WrenchScrewdriverIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import ServiceReportEditModal from "../../../../components/dashboard/service/reports/service-report-edit-modal";
import { ReportResetDialog } from "../../../../components/dashboard/service/reports/report-reset-dialog";
import { ReportOrderDialog } from "../../../../components/dashboard/service/reports/report-order-dialog";
import { ReportRectifyButton } from "../../../../components/dashboard/service/reports/report-rectify-button";
import { ReportPrintDialog } from "../../../../components/dashboard/service/reports/report-print-dialog";
import { ReportSendDialog } from "../../../../components/dashboard/service/reports/report-send-dialog";
import { CopyToClipboard } from "../../../../components/utils/Div";
import { HistoriesModalButton } from "../../../../components/dashboard/histories/modal-button";
import { useUserHasAccess } from "../../../../routes/roleOnly";
import { ReportCopyModal } from "../../../../components/dashboard/service/reports/report-copy-moda";
import { serviceAgreementPath } from "../../../../components/Utils";
import { ReportExecutiveDialog } from "../../../../components/dashboard/service/reports/report-executive";

export default function ReportLayout(props) {
  const { children } = props;
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    report: {},
  });

  const getReport = () => {
    setItemState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      fetchReport(id).then((data) => {
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          report: data?.data,
        }));
      });
    } catch {
      toast.error("Something went wrong when trying to load the report");
      setItemState((prevState) => ({
        ...prevState,
        isLoading: false,
        error: true,
      }));
    }
  };

  useEffect(() => {
    getReport();
  }, [dispatch, id]);

  const handleOnRefresh = (data) => {
    if (data?.id) {
      setItemState((prevState) => ({
        ...prevState,
        report: data,
      }));
    } else {
      getReport();
    }
  };

  const hasAdminAccess = useUserHasAccess({ role: "service", action: "reset" });

  const { report, isLoading, error, errors } = itemState;

  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [reportResetDialogOpen, setReportResetDialogOpen] = useState(false);
  const [reportExecutiveDialogOpen, setReportExecutiveDialogOpen] =
    useState(false);
  const [reportOrderDialogOpen, setReportOrderDialogOpen] = useState(false);
  const [reportPrintDialogOpen, setReportPrintDialogOpen] = useState(false);
  const [reportSendDialogOpen, setReportSendDialogOpen] = useState(false);
  const [reportCopyDialogOpen, setReportCopyDialogOpen] = useState(false);

  const headerMeta = [
    {
      component: <ReportStatus report={report} />,
      label: "Status",
      description: "",
      path: null,
      action: null,
      icon: null,
      textColor: "text-teal-700",
      hidden: false,
    },

    {
      component: <ReportAppointmentApprovedStatus report={report} />,
      label: "AppointmentStatus",
      description: "",
      path: null,
      action: null,
      icon: null,
      textColor: "text-teal-700",
      hidden: ["new", "ongoing"].includes(report.status) ? false : true,
      copy: false,
    },

    {
      component: <ReportMustBeRectified rectify={report?.must_be_rectified} />,
      label: "AppointmentStatus",
      description: "",
      path: null,
      action: null,
      icon: null,
      textColor: "text-teal-700",
      hidden: ["accomplished"].includes(report.status) ? false : true,
      copy: false,
    },

    {
      label: "Rapport sendt",
      description: "",
      action: null,
      icon: CheckBadgeIcon,
      textColor: "text-green-500",
      iconColor: "text-green-500",
      hidden: report?.reportIsSent ? false : true,
      copy: false,
    },
    {
      component: <ReportInvoiceStatus report={report} />,
      label: "Invoice Status",
      description: "",
      path: null,
      action: null,
      icon: null,
      textColor: "text-teal-700",
      hidden: report?.invoiced ? false : true,
    },
    {
      label: "Hovedrapport " + report?.sequelMainReport?.report_number,
      description: "",
      path: "/dashboard/service/reports/" + report?.sequelMainReport?.id,
      action: null,
      icon: WrenchScrewdriverIcon,
      textColor: "text-teal-700",
      hidden: report?.sequelMainReport?.id ? false : true,
      copy: false,
    },
    {
      label: report?.executing_employee?.name,
      description: "",
      path: "/dashboard/customers/" + report?.customer?.id,
      action: null,
      icon: WrenchIcon,
      textColor: "text-teal-700",
      hidden: report?.executing_employee?.id ? false : true,
      copy: false,
    },
    {
      label: report?.customer?.customer_number + " " + report?.customer?.name,
      description: "",
      path: "/dashboard/customers/" + report?.customer?.id,
      action: null,
      icon: UsersIcon,
      textColor: "text-teal-700",
      hidden: report?.customer?.id ? false : true,
      copy: false,
    },
    {
      label: report?.project?.displayTitle,
      description: "",
      path: "/dashboard/projects/" + report?.project?.id,
      action: null,
      icon: BuildingOfficeIcon,
      textColor: "text-teal-700",
      hidden: report?.project?.id ? false : true,
      copy: false,
    },
    {
      label: (
        <CopyToClipboard
          path={"https://www.google.com/maps?q=" + FullReportAddress(report)}
          content={FullReportAddress(report)}
          type="popup"
        />
      ),
      description: "",
      path: null,
      action: null,
      icon: MapPinIcon,
      textColor: "text-teal-700",
      hidden: report?.delivery_address || report.address ? false : true,
      copy: true,
    },
    {
      label: (
        <CopyToClipboard
          path={"tel:" + report?.customer?.mobile}
          content={report?.customer?.mobile}
          type="popup"
        />
      ),
      description: "",
      path: null,
      action: null,
      icon: PhoneIcon,
      textColor: "text-teal-700",
      hidden: report?.customer?.mobile ? false : true,
      copy: true,
    },
    {
      label: "Serviceavtale " + report?.agreement?.agreement_number,
      description: "",
      path: serviceAgreementPath({ serviceAgreement: report?.agreement }),
      action: null,
      icon: CheckBadgeIcon,
      textColor: "text-teal-700",
      hidden: report?.agreement?.id ? false : true,
      copy: true,
    },
  ];

  const headerActions = [
    {
      component: (
        <div className="px-2 mt-2">
          <ReportStatusButton
            report={report}
            onRefresh={(data) => {
              handleOnRefresh(data);
            }}
          />
        </div>
      ),
      label: "AppointmentStatus",
      description: "",
      path: null,
      action: null,
      icon: CameraIcon,
      textColor: "text-teal-700",
      hidden: hasAdminAccess ? false : true,
      menu: false,
    },
    {
      component: (
        <div className="px-2 mt-2">
          <ReportRectifyButton
            report={report}
            onRefresh={(data) => {
              handleOnRefresh(data);
            }}
          />
        </div>
      ),
      label: "Rectify",
      description: "",
      path: null,
      action: null,
      icon: CameraIcon,
      textColor: "text-teal-700",
      hidden: false,
      menu: false,
    },
    {
      component: (
        <div className="px-2 mt-2">
          <Notes module={"report"} module_param={report.id} />
        </div>
      ),
      label: "Status",
      description: "",
      path: null,
      action: null,
      icon: CameraIcon,
      textColor: "text-teal-700",
      bgColor: "bg-red-200",
      hidden: false,
      menu: false,
    },
    {
      component: null,
      label: "Utførende",
      description: "",
      path: null,
      action: () => setReportExecutiveDialogOpen(true),
      icon: UserIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: hasAdminAccess ? false : true,
      menu: true,
    },
    {
      component: null,
      label: "Reset",
      description: "",
      path: null,
      action: () => setReportResetDialogOpen(true),
      icon: ArrowPathRoundedSquareIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: hasAdminAccess ? false : true,
      menu: true,
    },
    {
      component: null,
      label: "Order",
      description: "",
      path: null,
      action: () => setReportOrderDialogOpen(true),
      icon: ClipboardDocumentListIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: report?.finished ? false : true,
      menu: true,
    },
    {
      component: null,
      label: "Copy",
      description: "",
      path: null,
      action: () => setReportCopyDialogOpen(true),
      icon: ClipboardDocumentCheckIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: true,
    },
    {
      component: null,
      label: "Skriv ut",
      description: "",
      path: null,
      action: () => setReportPrintDialogOpen(true),
      icon: PrinterIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: true,
    },
    {
      component: null,
      label: "Send",
      description: "",
      path: null,
      action: () => setReportSendDialogOpen(true),
      icon: AtSymbolIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: true,
    },
    {
      component: (
        <HistoriesModalButton module={"report"} module_param={report?.id} />
      ),
      label: "History",
      description: "",
      path: null,
      action: () => toast.success("Hej Robin"),
      icon: ArrowPathRoundedSquareIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: false,
    },
  ];

  if (isLoading) {
    return <ResourceLoading size="base" />;
  }

  if (error) {
    return <ResourceError errors={errors} />;
  }

  if (!report.id) {
    return <ResourceEmpty />;
  }

  const Content = () => {
    return React.Children.map(children, (child) =>
      React.cloneElement(child, {
        report: report,
        isLoading: isLoading,
        error: error,
        errors: errors,
        onRefresh: handleOnRefresh,
      })
    );
  };

  return (
    <>
      <DashboardHeader
        actions={headerActions}
        title={FullReportTitle(report)}
        meta={headerMeta}
        mainButton={{
          label: t("Edit"),
          action: () => setReportDialogOpen(true),
          path: null,
          color: "",
          icon: PencilIcon,
        }}
      >
        <div className="mx-auto flex flex-col lg:grid grid-rows-1 lg:grid-rows-2 col-span-1 lg:col-span-4 sm:gap-6 sm:px-5 px-2 sm:py-5 py-2 ">
          <Content />
        </div>
      </DashboardHeader>

      <ReportSendDialog
        report={report}
        open={reportSendDialogOpen}
        isLoading={isLoading}
        // onLoading={(state) => setIsLoading(state)}
        onClose={() => setReportSendDialogOpen(false)}
        onRefresh={handleOnRefresh}
      />

      <ReportPrintDialog
        report={report}
        open={reportPrintDialogOpen}
        isLoading={isLoading}
        // onLoading={(state) => setIsLoading(state)}
        onClose={() => setReportPrintDialogOpen(false)}
        onRefresh={handleOnRefresh}
      />

      <ReportResetDialog
        report={report}
        open={reportResetDialogOpen}
        isLoading={isLoading}
        // onLoading={(state) => setIsLoading(state)}
        onClose={() => setReportResetDialogOpen(false)}
        onRefresh={handleOnRefresh}
      />

      <ReportExecutiveDialog
        report={report}
        open={reportExecutiveDialogOpen}
        isLoading={isLoading}
        onClose={() => setReportExecutiveDialogOpen(false)}
        onRefresh={handleOnRefresh}
      />

      <ReportOrderDialog
        report={report}
        open={reportOrderDialogOpen}
        isLoading={isLoading}
        // onLoading={(state) => setIsLoading(state)}
        onClose={() => setReportOrderDialogOpen(false)}
        // onRefresh={() => props.onRefresh()}
        onRefresh={handleOnRefresh}
      />

      <ServiceReportEditModal
        open={reportDialogOpen}
        onClose={() => setReportDialogOpen(false)}
        selected={report}
        onRefresh={handleOnRefresh}
      />

      <ReportCopyModal
        open={reportCopyDialogOpen}
        onClose={() => setReportCopyDialogOpen(false)}
        report={report}
      />
    </>
  );
}
