import { useTranslation } from "react-i18next";
import { TWclassNames } from "../../utils/Div";
import { status } from "../../Utils";

export const orderStatusOptions = [
  {
    id: "none",
    value: "none",
    title: "None",
    label: "None",
  },
  {
    id: "new",
    value: "new",
    title: "New",
    label: "New",
  },
  {
    id: "order_confirmation_sent",
    value: "order_confirmation_sent",
    title: "Order Confrimation Sent",
    label: "Order Confrimation Sent",
  },
  {
    id: "ready_to_be_picked",
    value: "ready_to_be_picked",
    title: "Ready to be picked",
    label: "Ready to be picked",
  },
  { id: "picked", value: "picked", title: "Picked", label: "Picked" },
  { id: "packed", value: "packed", title: "Packed", label: "Packed" },
  {
    id: "ready_to_be_shipped",
    value: "ready_to_be_shipped",
    title: "Ready to be shipped",
    label: "Ready to be shipped",
  },
  {
    id: "ready_to_be_invoiced",
    value: "ready_to_be_invoiced",
    title: "Ready to be invoiced",
    label: "Ready to be invoiced",
  },
  { id: "invoiced", value: "invoiced", title: "Invoiced", label: "Invoiced" },
  {
    id: "cancelled",
    value: "cancelled",
    title: "Cancelled",
    label: "Cancelled",
  },
];

export const OrderStatus = ({ order }) => {
  const { t } = useTranslation();

  return (
    <span
      className={TWclassNames(
        order?.status && !order?.is_invoiced ? status[order?.status].class : "",
        order?.is_invoiced && "bg-green-100 text-green-800",
        "inline-flex rounded-full px-2 text-xs font-semibold leading-5"
      )}
    >
      {order?.is_invoiced ? (
        <span>Fakturert</span>
      ) : (
        <>
          {order.status ? (
            <span> {t(status[order?.status].label)}</span>
          ) : (
            <span className="bg-gray-100 inline-flex rounded-full px-2 text-xs font-semibold leading-5">
              {t("None")}
            </span>
          )}
        </>
      )}
    </span>
  );
};

export const ordersViewsOptions = [
  { id: "active", value: "ACTIVE", label: "Aktive" },
];
