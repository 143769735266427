import React, { component } from "react";
import { TWclassNames } from "./Div";

export const ResourceLoading = ({ size, loaderClass, baseClass, position }) => {
  if (size === "base") {
    return (
      <div className="mt-10 mb-10 top-0 left-0 right-0 bottom-0 w-full z-50 overflow-hidden opacity-75 flex flex-col items-center justify-center">
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        <h2 className="text-center text-black text-l font-semibold">
          Loading...
        </h2>
        {/* <p className="w-1/3 text-center text-black text-xs">This may take a few seconds, please don&apos;t close this page.</p> */}
      </div>
    );
  }

  return (
    <div className={baseClass}>
      <div
        className={TWclassNames(
          "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-10 w-10",
          loaderClass,
          position
        )}
      ></div>
    </div>
  );
};

ResourceLoading.defaultProps = {
  size: "base",
  position: "relative",
  loaderClass: "",
  baseClass:
    "top-0 left-0 right-0 bottom-0 w-full z-50 overflow-hidden opacity-75 bg-gray-50 flex flex-col items-center justify-center",
};
