import { useEffect, useState } from "react";
import { HtmlTextFormatter } from "../../../Utils";
import { PMDialog } from "../../../utils/PMDialog";
import { fetchReportType } from "../../../../api/service/settings";
import toast from "react-hot-toast";

export const ServiceReportTypeConditionsDialog = (props) => {
  const { open } = props;
  const [reportType, setReportType] = useState({});

  // fetch report type
  const getReportType = async () => {
    const response = await fetchReportType(props?.reportType?.id, {
      fields: "id,conditions",
    });
    if (response.error) {
      toast.error("Failed to fetch report type");
      return;
    }
    setReportType(response);
  };

  useEffect(() => {
    if (open && props?.reportType?.id > 0) getReportType();
  }, [open]);

  const classes = {
    p: "text-sm text-gray-600",
    h1: "text-lg font-semibold",
    h2: "text-md font-semibold",
    ul: "list-disc list-inside indent-4",
    li: "text-sm text-gray-600",
    a: "text-blue-500 underline",
  };

  return (
    <PMDialog
      open={open}
      onClose={props.onClose}
      title="Betingelser for type/ tjeneste"
    >
      <hr className="my-4 border-blue-400" />
      <div>
        <HtmlTextFormatter string={reportType?.conditions} classes={classes} />
      </div>
    </PMDialog>
  );
};
