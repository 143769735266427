import { Fragment, useEffect, useState } from "react";
import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { PMField } from "../../utils/PMField";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { mapErrors } from "../../Utils";
import {
  createProjectAsync,
  updateProjectAsync,
} from "../../../slices/project";
import { fetchCustomer, fetchProject } from "../../../api/project";
import {
  ArrowRightIcon,
  CheckBadgeIcon,
  CheckIcon,
  ExclamationCircleIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CustomerSelector } from "../../utils/moduleSelectors/customerSelector";
import { DeliveryAddressSelector } from "../../utils/moduleSelectors/deliveryAddressSelector";
import { QuillEditor } from "../../utils/quill-editor";
import { DepartmentSelector } from "../../utils/moduleSelectors/departmentSelector";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { EmployeeSelector } from "../../utils/moduleSelectors/employeeSelector";
import { useSelector } from "react-redux";
import PMListbox from "../../utils/PMListbox";
import PMListboxFormik from "../../utils/PMListboxFormik";
import {
  accountCodeOptions,
  productTypeOptions,
  unitOptions,
  warehouseStatusOptions,
} from "./product-data";
import { getProductBrands } from "../../../slices/products/brands";
import PMSwitch from "../../utils/PMSwitch";
import { listUpdateProductAsync } from "../../../slices/products";
import { TWclassNames } from "../../utils/Div";
export default function ProductPriceChangeDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    project: {},
  });

  // Deconstructing props
  const { open, onClose, selected, onRefresh, selectedItems } = props;

  // Deconstruct itemState (Store)
  const { project, isLoading, error, errors } = itemState;

  const [readOnly, setReadOnly] = useState(props.readOnly);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }, 500);
    } else {
      setTimeout(() => {
        setConfirmPrompt(false);
      }, 500);
    }
  }, [dispatch, open]);

  const createOrUpdateDispatch = (data) => {
    if (project?.id) {
      const res = dispatch(updateProjectAsync(data));
      return res;
    } else {
      const res = dispatch(createProjectAsync(data));
      return res;
    }
  };

  useEffect(() => {
    dispatch(getProductBrands());
  }, [dispatch]);

  const brandState = useSelector((store) => {
    return store.product_brands;
  });

  const productBrands = () => {
    const brands = [{ name: "brand", label: "Ikke valgt", value: 0 }];
    brandState?.data?.map((brand) => {
      brands.push({
        name: "brand",
        label: brand.title,
        value: brand.id,
      });
    });
    return brands;
  };

  const [optionalProjectNumber, setOptionalProjectNumber] = useState(true);

  const [confirmPrompt, setConfirmPrompt] = useState(false);

  const tabs = [
    {
      id: 1,
      name: "Innkjøpspris",
      description: "Vitae sed mi luctus laoreet.",
    },
    {
      id: 2,
      name: "Veil. pris",
      description: "Cursus semper viverra.",
    },
  ];

  const priceSettings = [
    {
      name: "Prisendring i verdi",
      fieldName: "salesPriceIncreaseValue",
      settingsTab: 2,
    },
    {
      name: "Prisendring i %",
      fieldName: "salesPriceIncreasePercentage",
      settingsTab: 2,
    },
    {
      name: "Prisendring i %",
      fieldName: "purchasePriceIncreasePercentage",
      settingsTab: 1,
    },
    {
      name: "Prisendring i verdi",
      fieldName: "purchasePriceIncreaseValue",
      settingsTab: 1,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const [selectedPriceSetting, setSelectedPriceSetting] = useState(
    priceSettings[0]
  );

  function Tabs() {
    return (
      <div className="lg:border-b lg:border-t lg:border-gray-200">
        <nav
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          aria-label="Progress"
        >
          <ol
            role="list"
            className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
          >
            {tabs.map((step, stepIdx) => (
              <li
                key={step.id}
                onClick={() => setSelectedTab(tabs[stepIdx])}
                className="relative overflow-hidden lg:flex-1"
              >
                <div
                  className={TWclassNames(
                    stepIdx === 0 ? "rounded-t-md border-b-0" : "",
                    stepIdx === tabs.length - 1
                      ? "rounded-b-md border-t-0"
                      : "",
                    "overflow-hidden border border-gray-200 lg:border-0 "
                  )}
                >
                  <a
                    onClick={() => setSelectedTab(tabs[stepIdx])}
                    aria-current="step"
                  >
                    <span
                      className={TWclassNames(
                        selectedTab.id === step.id ? "bg-indigo-600" : "",
                        "absolute left-0 top-0 h-full w-1 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={TWclassNames(
                        stepIdx !== 0 ? "lg:pl-9" : "",
                        "flex items-start px-6 py-5 text-sm font-medium cursor-pointer"
                      )}
                    >
                      {/* <span className="flex-shrink-0">
                        <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-indigo-600"></span>
                      </span> */}
                      <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                        <span className="text-sm font-medium text-indigo-600">
                          {step.name}
                        </span>
                      </span>
                    </span>
                  </a>

                  {stepIdx !== 0 ? (
                    <>
                      {/* Separator */}
                      <div
                        className="absolute inset-0 left-0 top-0 hidden w-3 lg:block"
                        aria-hidden="true"
                      >
                        <svg
                          className="h-full w-full text-gray-300"
                          viewBox="0 0 12 82"
                          fill="none"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M0.5 0V31L10.5 41L0.5 51V82"
                            stroke="currentcolor"
                            vectorEffect="non-scaling-stroke"
                          />
                        </svg>
                      </div>
                    </>
                  ) : null}
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
    );
  }

  function SettingsRadioGroup({ formik }) {
    const [selected, setSelected] = useState(priceSettings[0]);

    useEffect(() => {
      priceSettings.map((item) => {
        formik.setFieldValue(item.fieldName, null);
      });
    }, [selected]);

    return (
      <RadioGroup value={selected} onChange={setSelected}>
        <RadioGroup.Label className="sr-only">Pricing plans</RadioGroup.Label>
        <div className="relative -space-y-px rounded-md bg-white">
          {priceSettings
            .filter((item) => item.settingsTab === selectedTab.id)
            .map((plan, planIdx) => (
              <RadioGroup.Option
                key={plan.name}
                value={plan}
                className={({ checked }) =>
                  TWclassNames(
                    planIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                    planIdx === priceSettings.length - 1
                      ? "rounded-bl-md rounded-br-md"
                      : "",
                    checked
                      ? "z-10 border-indigo-200 bg-indigo-50"
                      : "border-gray-200",
                    "relative flex cursor-pointer flex-col border p-4 focus:outline-none md:grid md:grid-cols-2 md:pl-4 md:pr-6"
                  )
                }
              >
                {({ active, checked }) => (
                  <>
                    <span className="flex items-center text-sm w-full">
                      <span
                        className={TWclassNames(
                          checked
                            ? "bg-indigo-600 border-transparent"
                            : "bg-white border-gray-300",
                          active ? "ring-2 ring-offset-2 ring-indigo-600" : "",
                          "h-4 w-4 rounded-full border flex items-center"
                        )}
                        aria-hidden="true"
                      >
                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                      </span>
                      <RadioGroup.Label
                        as="span"
                        className={TWclassNames(
                          checked ? "text-indigo-900" : "text-gray-900",
                          "ml-3 font-medium"
                        )}
                      >
                        {plan.name}
                      </RadioGroup.Label>
                    </span>
                    <RadioGroup.Description
                      as="span"
                      className={TWclassNames(
                        checked ? "text-indigo-700" : "text-gray-500",
                        "ml-2 pl-1 text-sm md:ml-0 md:pl-0 md:text-right"
                      )}
                    >
                      {plan.fieldName && (
                        <PMField
                          name={plan?.fieldName}
                          disabled={!checked}
                          {...formik}
                        />
                      )}
                    </RadioGroup.Description>
                  </>
                )}
              </RadioGroup.Option>
            ))}
        </div>
      </RadioGroup>
    );
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full sm:max-w-1xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="flex items-center text-lg font-medium leading-6 text-gray-900"
                  >
                    Editing {selectedItems.length} products{" "}
                  </Dialog.Title>

                  {isLoading ? (
                    <ResourceLoading />
                  ) : (
                    <Formik
                      initialValues={{
                        ids: selectedItems.map((item) => item.id.toString()),
                        // brand_id: null,
                        // product_type: null,
                        // unit_id: null,
                        // account_coding_id: null,
                        // warehouse_status_type_id: null,
                        // active: true,
                        // warehouse: false,
                        // isSparePart: false,
                        // online_store: false,
                        submit: null,
                      }}
                      validationSchema={Yup.object({
                        project_number: Yup.string(),
                      })}
                      onSubmit={(values, helpers) => {
                        try {
                          toast.promise(
                            dispatch(listUpdateProductAsync(values)),
                            {
                              loading: t("Saving...."),
                              success: (data) => {
                                if (data?.meta?.requestStatus === "fulfilled") {
                                  onClose(true);
                                  onRefresh();
                                  return t("Data was saved!");
                                } else {
                                  throw new Error("Server error");
                                }
                              },
                              error: (error) => {
                                console.log(error);
                                return t(
                                  "Something went wrong. Could not save data!"
                                );
                              },
                            }
                          );
                          // helpers.setStatus({ success: true });
                        } catch (err) {
                          toast.error("Something went wrong...");
                          helpers.setStatus({ success: false });
                          helpers.setErrors({ submit: err.message });
                          // helpers.setSubmitting(false);
                        }
                      }}
                    >
                      {(formik) => (
                        <Form>
                          <div className="mt-10">
                            <Tabs />
                          </div>
                          {!confirmPrompt ? (
                            <div className="grid grid-cols-6 gap-6 mt-10 mb-10">
                              <div className="col-span-6 sm:col-span-6">
                                <SettingsRadioGroup formik={formik} />
                              </div>
                            </div>
                          ) : (
                            <div className="px-10 py-10">
                              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                                <ExclamationCircleIcon
                                  className="h-6 w-6 text-red-600"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="mt-3 text-center sm:mt-5">
                                <Dialog.Title
                                  as="h3"
                                  className="text-base font-semibold leading-6 text-gray-900"
                                >
                                  Er du sikker?
                                </Dialog.Title>
                                <div className="mt-2">
                                  <p className="text-sm text-gray-500">
                                    Du redigerer nå {selectedItems?.length}{" "}
                                    produkt
                                    {selectedItems?.length > 1 ? "er" : ""} og
                                    dette kan ikke ikke reverseres.
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                            <button
                              type="button"
                              className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                              onClick={onClose}
                            >
                              {t("Close")}{" "}
                            </button>

                            {!confirmPrompt ? (
                              <button
                                type="button"
                                className="bg-blue-400 rounded shadow text-white"
                                onClick={() => setConfirmPrompt(true)}
                              >
                                {t("Update")}
                              </button>
                            ) : (
                              <LoadingButton
                                loading={isLoading}
                                disabled={isLoading}
                                loadingPosition="start"
                                startIcon={<CheckBadgeIcon />}
                                type="button"
                                variant="contained"
                                color={"success"}
                                onClick={formik.submitForm}
                              >
                                {t("Confirm")}
                              </LoadingButton>
                            )}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

ProductPriceChangeDialog.defaultProps = {
  selected: null,
  // Read only is used when only wanting to display info. Update or creating will not work.
  readOnly: false,
  open: false,
  onRefresh: () => null,
  onClose: () => toast.error("Missing onClose function. Cannot close dialog"),
};
