import React, { useEffect, useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import {
  Grid,
  Typography,
  FormControl,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  DialogTitle,
} from '@mui/material';

import { FixedSizeList } from 'react-window';

import axios from '../../../api/axios';
import { ResourceLoading } from '../ResourceLoading';
import { ResourceEmpty } from '../ResourceEmpty';
import { useTranslation } from 'react-i18next';

export default function PMSimpleSelectorModal(props) {

  const [selectedItem, setSelectedItem] = useState();
  const [search, setSearch] = useState('');
  const [selector, setSelector] = useState({
    type: props.name,
    fetchLink: '',
    name: '',
    fields: ''

  });

  const { open, items, loading, name } = props;

  const { t } = useTranslation();

  const handleSelect = (item) => {
    setSelectedItem(item)
    props.callback({
      selected: { item },
      selector: selector
    })
  }

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
    props.query(e.target.value)
  }


  const handleClose = () => {
    props.onClose()
  }



  const renderItems = ({ index, style }) => {

    const title = {
      "customer": `${items[index].customer_number} ${items[index].name}`,
      "project": `${items[index].project_number} ${items[index].name}`,
      "department": `${items[index].department_number} ${items[index].name}`,
      "report_type": `${items[index].title}`,
      "variable_type": `${items[index].title}`,
      "unit_id": `${items[index].title}`,
    }

    const secondaryTitle = {
      "customer": ``,
      "project": `${items[index].customer_title}`,
      "department": ``,
      "report_type": ``,
      "variable_type": ``,
      "unit_id": ``,
    }


    return (
      <div >
        <ListItem key={items[index].id} style={style} className="hover:border-l-4 hover:border-indigo-500 hover:bg-indigo-100">
        <ListItemButton onClick={() => handleSelect(items[index])}>
          <ListItemText
            primary={title[selector.type]}
            secondary={secondaryTitle[selector.type]}
          />
        </ListItemButton>
      </ListItem>
      </div>
    )
  }



  return (


    <>

      <Transition appear show={props.open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {t("Choose")} {name}
                  </Dialog.Title>
                  <div className="mt-5">
                    <FormControl sx={{ ml: '10px', pl: '10px', width: '90%' }} fullWidth >
                      <TextField
                        name="search" id="search"
                        label={"Søk etter " + selector.name + "..."}
                        value={search} onChange={(e) => handleSearch(e)}
                        fullWidth={true}
                        sx={{ width: '80%', marginBottom: '10px' }} />
                    </FormControl>
                    {loading ?
                      <ResourceLoading /> : items && items.length > 0 ?
                        <FixedSizeList
                          height={400}
                          width={'100%'}
                          itemSize={40}
                          itemCount={items.length}
                        // overscanCount={5}
                        >
                          {renderItems}

                        </FixedSizeList>
                        : <ResourceEmpty />}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )

}