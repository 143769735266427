import axios from '../axios'

export async function fetchBrand(id) {
  return axios.get(`/products/brands/` + id)
}

export async function deleteProductBrand(payload) {
  return axios.delete(`/products/brands/` + payload)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error.response.data
    });
}

export async function createProductBrand(payload) {
  return axios.post(`/products/brands/`, { product_brand: payload })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error.response.data
    });
}


export async function updateProductBrand(payload) {
  return axios.put(`/products/brands/` + payload.id, { product_brand: payload })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error.response.data
    });
}

export async function fetchProductBrands(controller) {
  let { filters, sort, sortBy, page, query, view } = controller;

  const params = {
    query: query,
    page: page,
    sortBy: sortBy,
    sort: sort,
    filters: filters,
    view: view,
  }

  return axios.get(`/products/brands`, { params })
    .then((response) => {
      return response.data

    })
    .catch((error) => {
      return error.response.data
    });
}
