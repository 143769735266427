import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ResourceLoading } from "../../utils/ResourceLoading";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { PMPagination } from "../../utils/PMPagination";
import { TWclassNames } from "../../utils/Div";
import { getDrivingTripsAsync } from "../../../slices/driving/trip";
import { dateToString } from "../../Utils";

export default function CarAllowanceSelectionModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const itemState = useSelector((state) => state.drivingTrips);

  const {
    open,
    onClose,
    onSelect,
    selected,
    fields,
    project,
    customer,
    item_type,
    hour,
  } = props;

  const { data, isLoading, error, errors, currentCount, totalCount } =
    itemState;

  const filters = [];

  // Update filters if user/ date is changing
  useEffect(() => {
    if (hour?.user_id && hour?.date) {
      setController((prevState) => ({
        ...prevState,
        filters: [
          { name: "USER", value: hour?.user_id },
          { name: "DATE", value: hour?.date },
          { name: "HOUR_CAR_ALLOWANCE", value: hour?.id },
        ],
      }));
    }
  }, [hour]);

  const [controller, setController] = useState({
    query: "",
    page: 1,
    per_page: 10,
    sortBy: "created_at",
    sort: "desc",
    filters: filters,
    view: null,
  });

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  useEffect(() => {
    if (open) {
      dispatch(getDrivingTripsAsync(controller));
    }
  }, [dispatch, open, controller]);

  const [selectedItems, setSelectedItems] = useState(selected ? selected : []);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const checkbox = useRef();

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedItems?.length > 0 && selectedItems?.length < data?.length;
    setChecked(selectedItems?.length === data?.length);
    setIndeterminate(isIndeterminate);
  }, [selectedItems]);

  const handleSelectAll = (e) => {
    setSelectedItems(e.target.checked ? data : []);
  };

  const handleSelectOne = (item) => {
    if (!isSelected(item.id)) {
      setSelectedItems((prevSelected) => [...prevSelected, item]);
    } else {
      setSelectedItems((prevSelected) =>
        // Find by matching ID and remove it from array
        prevSelected.filter((selected) => selected.id !== item.id)
      );
    }
  };

  const handleSave = () => {
    if (selectedItems?.length > 0) {
      // Format selectedItems to only array with ids
      const selectedItemsIds = selectedItems?.map((item) => item.id);
      onSelect(selectedItemsIds);
      onClose();
    }
  };

  // After data load, check if any of the selected data has current hour_id and set it as selected
  useEffect(() => {
    // Set selected items from hour?.trips

    if (hour?.trips) {
      setSelectedItems(selected);

      // setSelectedItems((prevSelected) => {
      //   const mergedItems = [...prevSelected];
      //   hour.trips.forEach((trip) => {
      //     if (!mergedItems.includes(trip)) {
      //       mergedItems.push(trip);
      //     }
      //   });
      //   return mergedItems;
      // });
    }
  }, [hour]);

  // Check if selected with matching the ids
  const isSelected = (id) => selectedItems?.some((item) => item.id === id);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:data-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    <div className="flex data-center py-5 w-full">
                      <div className="block w-[60%]">
                        <h2
                          id="applicant-information-title"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          {t("Car allowance")}
                          {" for "}
                          {hour?.date}
                          {selectedItems?.length > 0 && (
                            <span className="ml-2 text-sm text-gray-500">
                              ({selectedItems?.length}) {t("selected")}
                            </span>
                          )}
                        </h2>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
                      </div>
                    </div>
                  </Dialog.Title>

                  {isLoading ? (
                    <ResourceLoading />
                  ) : (
                    <>
                      <ul role="list" className="divide-y divide-gray-100">
                        {data?.map((item) => (
                          <li
                            onClick={() => handleSelectOne(item)}
                            key={item.email}
                            className={TWclassNames(
                              isSelected(item.id)
                                ? "bg-gray-200"
                                : "bg-blue-50",
                              "cursor-pointer relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 rounded-xl mt-2 sm:px-6 lg:px-8"
                            )}
                          >
                            <div className="flex gap-x-4">
                              <div className="min-w-0 flex-auto">
                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                  <span className="absolute inset-x-0 -top-px bottom-0" />
                                  {item?.vehicle?.displayTitle}
                                </p>
                                <p className="mt-1 grid text-xs leading-5 text-gray-500">
                                  <span className="truncate">
                                    {dateToString(item?.start_date) + " - "}
                                    {dateToString(item?.end_date)}
                                  </span>
                                </p>
                                <p className="mt-1 grid text-xs leading-5 text-gray-500">
                                  <span className="truncate">
                                    {item?.start_address}
                                  </span>
                                  <span className="truncate">
                                    {item?.end_address}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="flex data-center gap-x-4">
                              <div className="hidden sm:flex sm:flex-col sm:data-end">
                                <p className="text-sm leading-6 text-gray-900">
                                  {item.role}
                                </p>
                                {item.lastSeen ? (
                                  <p className="mt-1 text-xs leading-5 text-gray-500">
                                    Last seen{" "}
                                    <time dateTime={item.lastSeenDateTime}>
                                      {item.lastSeen}
                                    </time>
                                  </p>
                                ) : (
                                  <div className="mt-1 flex data-center gap-x-1.5">
                                    <p className="text-xs leading-5 text-gray-500">
                                      {item?.distance_kilometers} km
                                    </p>
                                  </div>
                                )}
                              </div>
                              <ChevronRightIcon
                                className="h-5 w-5 flex-none text-gray-400"
                                aria-hidden="true"
                              />
                            </div>
                          </li>
                        ))}
                      </ul>

                      <PMPagination
                        disabled={isLoading}
                        onPageChange={handlePageChange}
                        page={controller?.page}
                        perPage={controller?.per_page}
                        totalCount={totalCount}
                        currentCount={currentCount}
                      />
                    </>
                  )}
                </div>

                <div className="mt-5">
                  <button
                    type="button"
                    className="w-full inline-flex align-right justify-center rounded-md border border-transparent bg-blue-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    onClick={() => handleSave()}
                  >
                    {t("Save")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
