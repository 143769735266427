import axios from "../axios";

export async function createContact(payload, controller = {}) {
  return axios
    .post(`/contacts`, { contact: payload, params: controller })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function updateContact(payload, controller = {}) {
  return axios
    .put(`/contacts/` + payload.id, { contact: payload, params: controller })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // return error.response.data
      if (error.status !== 200) return error;
      else return error.response;
    });
}

export async function fetchContacts(controller) {
  return axios({ method: "get", url: "/contacts/", params: controller })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchContact(id) {
  return axios.get(`/contacts/` + id);
}

export async function destroyContact(payload) {
  return axios
    .delete(`/contacts/${payload.id}`, {
      data: JSON.stringify({
        payload,
      }),
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}
