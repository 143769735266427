import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Table } from "../../utils/PMTable/Table";
import { TableBody } from "../../utils/PMTable/TableBody";
import { PMPagination } from "../../utils/PMPagination";
import { Popup, TWclassNames } from "../../utils/Div";
import CaseDialog from "./case-dialog";
import { toast } from "react-hot-toast";
import { CasePriorityStatus, CaseStatus, caseStatuses } from "./utils";
import { ResourceLoading } from "../../utils/ResourceLoading";
import {
  InitialsTag,
  LoadingRows,
  OrderTitle,
  ProjectTitle,
  ReportTitle,
  ServiceAgreementTitle,
  StringLimit,
  dateToString,
  searchFieldsByName,
  status,
} from "../../Utils";
import { ResourceEmpty } from "../../utils/ResourceEmpty";
import {
  CalendarIcon,
  ExclamationCircleIcon,
  MapPinIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { FullReportTitle } from "../service/reports/utils";
import { useSearchParams } from "react-router-dom";

export const CasesTableV2 = (props) => {
  const {
    itemState,
    onPageChange,
    itemsCount,
    totalCount,
    currentCount,
    page,
    perPage,
  } = props;
  const { data, isLoading, errors } = itemState;

  const [selectedCase, setSelectedCase] = useState(null);

  const [isEditing, setIsEditing] = useState(false);

  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const caseId = searchParams.get("caseId");

  useEffect(() => {
    if (caseId && caseId !== "null" && caseId !== "undefined") {
      handleOpenCase({ id: caseId });
    }
  }, [caseId]);

  function handleOpenCase(object) {
    // set caseId to url
    setSearchParams({ caseId: object?.id });

    setSelectedCase(object);

    setTimeout(() => {
      setCaseDialogOpen(true);
    }, 50);
  }
  function handleDialogClose() {
    // props.onRefresh();
    if (!isEditing) {
      setCaseDialogOpen(false);
      setSelectedCase(null);
      // remove caseId param from url
      setSearchParams({ caseId: null });
    } else {
      toast.error("Please save your changes first.");
    }
  }

  const [caseDialogOpen, setCaseDialogOpen] = useState(false);

  const hasMorePages = () => {
    return page * perPage < totalCount;
  };

  const lastItemWithDescription = (item) => {
    // Search items inside "item" that matches module === "description"
    if (item?.items?.length > 0) {
      const descriptionItems = item?.items?.filter(
        (item) => item?.description?.length > 0
      );
      return descriptionItems[0];
    } else if (item?.last_item) {
      return item?.last_item;
    }
  };

  const FieldOrCustomerAddress = ({ item }) => {
    if (
      item?.customer?.postal_address &&
      item?.customer?.postal_address?.address !== "null"
    ) {
      return item?.customer?.postal_address?.displayTitle;
    }
    if (item?.fields) {
      const address = searchFieldsByName(item, "address")?.value;

      if (address) {
        const address2 = searchFieldsByName(item, "address2")?.value;
        const postal = searchFieldsByName(item, "postal")?.value;
        const place = searchFieldsByName(item, "place")?.value;

        // find fields

        return `${address} ${
          address2 ? ", " + address2 : ""
        }, ${postal} ${place}`;
      }
    }

    return "Mangler";
  };

  return (
    <>
      <PMPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        perPage={perPage}
        rowsCount={itemsCount}
        totalCount={totalCount}
        currentCount={currentCount}
        showCount={true}
        showPages={true}
        showNextPrev={true}
      />

      {data && (
        <div className="overflow-hidden bg-white shadow sm:rounded-md">
          <ul role="list" className="divide-y divide-gray-200">
            {data?.map((caseItem) => (
              <li key={caseItem.id} onClick={() => handleOpenCase(caseItem)}>
                <a href="#" className="block hover:bg-gray-50">
                  <div className="px-4 py-4 sm:px-6">
                    <div className="flex flex-col sm:grid grid-cols-5 items-left sm:items-center justify-between">
                      <p className="flex flex-col truncate text-sm font-medium">
                        <span className="text-blue-400">
                          {caseItem?.case_number}
                        </span>
                        <span>
                          {caseItem?.customer?.id
                            ? caseItem?.customer?.displayTitle
                            : caseItem?.fields
                            ? searchFieldsByName(caseItem, "name")?.value
                            : "--"}
                        </span>
                      </p>

                      <div className="mt-5 flex flex-col ml-2 col-span-2 col-start-2 flex-shrink-0   text-left">
                        {lastItemWithDescription(caseItem)?.id && (
                          <p className="px-2 py-2 bg-blue-200 rounded-md">
                            <StringLimit
                              text={
                                lastItemWithDescription(caseItem)?.description
                              }
                              limit={50}
                            />
                          </p>
                        )}

                        {/* <div className="mt-5">
                          <ProjectTitle project={caseItem?.project} />
                          <ReportTitle report={caseItem?.report} />
                          <ServiceAgreementTitle
                            serviceAgreement={caseItem?.service_agreement}
                          />
                          <OrderTitle order={caseItem?.order} />
                        </div> */}
                      </div>

                      <div
                        id="dateAndStatus"
                        className="flex flex-col-reverse sm:flex-row col-span-2 w-full sm:items-center justify-end"
                      >
                        <div className="flex flex-col  mt-2 sm:mt-0">
                          <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                            <CalendarIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <p>
                              Opprettet{": "}
                              <time dateTime={caseItem.created_at}>
                                {dateToString(caseItem?.created_at)}
                              </time>
                            </p>
                          </div>

                          <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                            <CalendarIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <p>
                              Oppdatert{": "}
                              <time dateTime={caseItem.updated_at}>
                                {dateToString(caseItem?.updated_at)}
                              </time>
                            </p>
                          </div>
                        </div>
                        <div className="ml-2 flex flex-shrink-0 right-0 mt-5 sm:mt-0">
                          <p className="flex flex-col gap-3">
                            <CaseStatus caseItem={caseItem} />
                            <CasePriorityStatus priority={caseItem?.priority} />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="sm:flex">
                        <p className="flex items-center text-sm text-gray-500 sm:mr-6">
                          <Popup content={caseItem?.note} textSize="md">
                            <InitialsTag name={caseItem?.user_assigned?.name} />
                          </Popup>
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                          <WrenchIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {caseItem.case_type?.title}
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-2">
                          <MapPinIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <FieldOrCustomerAddress item={caseItem} />{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      <PMPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        perPage={perPage}
        rowsCount={itemsCount}
        totalCount={totalCount}
        currentCount={currentCount}
        showCount={true}
        showPages={true}
        showNextPrev={true}
      />

      <CaseDialog
        // module_param={item.id}
        // module="customer"
        open={caseDialogOpen}
        onClose={() => handleDialogClose()}
        selected={selectedCase}
        onRefresh={() => props.onRefresh()}
        // onEditing={(boolean) => setIsEditing(boolean)}
      />
    </>
  );
};

CasesTableV2.defaultProps = {
  page: 1,
  itemState: [],
  itemsCount: 0,
  selectedItems: [],
  sort: "desc",
  sortBy: "createdAt",
};
