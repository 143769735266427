import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { PMField } from "../../../utils/PMField";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { dispatchWithToast, mapErrors } from "../../../Utils";
import { ProjectSelector } from "../../../utils/moduleSelectors/projectSelector";
import { CustomerSelector } from "../../../utils/moduleSelectors/customerSelector";
import PMCombobox from "../../../utils/PMCombobox";
import { fetchUsers } from "../../../../api/users";

import { ResourceLoading } from "../../../utils/ResourceLoading";
import { ProjectFolderSelector } from "../../../utils/moduleSelectors/projectFolderSelector";
import { getChecklistTypeOptions } from "./utils";
import {
  createChecklistAsync,
  updateChecklistAsync,
} from "../../../../slices/quality/checklist";
import { fetchChecklist } from "../../../../api/quality/checklist";

export default function ChecklistDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    checklist: {},
  });

  const { open, onClose, selected, fields, project, customer } = props;

  const { isLoading, checklist, error, errors } = itemState;

  const [checklistTypeOptions, setChecklistTypeOptions] = useState([]);

  const [userOptions, setUserOptions] = useState({
    isLoading: true,
    error: false,
    errors: [],
    data: [],
  });

  async function fetchAndMapUsers() {
    setUserOptions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const controller = {
      filters: [{ name: "role", value: "checklists" }],
      page: 1,
      query: "",
      sort: "desc",
      sortBy: "id",
      view: "all",
    };

    await fetchUsers(controller)
      .then((res) => {
        if (res.data) {
          var array = [];
          if (!res.data) return array;
          res.data.map((row, i) => {
            const rowParams = {
              value: row.id,
              title: row.name,
              order: i,
            };
            array.push(rowParams);
          });
          setUserOptions((prevState) => ({
            ...prevState,
            isLoading: false,
            data: array,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        setUserOptions((prevState) => ({
          ...prevState,
          isLoading: false,
          error: true,
          errors: errors,
        }));
      });

    // Map into an array with ID and title?

    // Return the array
  }

  const getDeviation = async () => {
    await fetchChecklist(selected.id)
      .then((res) => {
        if (res.data.id) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            checklist: res.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err.data.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          checklist: {},
          error: true,
          errors: errors,
        }));
      });
  };

  useEffect(() => {
    async function fetchTypeOptions() {
      const fetchedOptions = await getChecklistTypeOptions();
      setChecklistTypeOptions(fetchedOptions);
    }

    if (open) {
      setItemState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      if (selected?.id) {
        getDeviation(selected?.id);
      } else {
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
      setTimeout(() => {
        fetchAndMapUsers();
        fetchTypeOptions();
      });
    } else {
      setItemState((prevState) => ({
        ...prevState,
        checklist: {},
      }));
    }
  }, [open]);

  const createOrUpdateDispatch = (data) => {
    if (checklist?.id) {
      const res = dispatch(updateChecklistAsync(data));
      return res;
    } else {
      const res = dispatch(createChecklistAsync(data));
      return res;
    }
  };

  const handleOnRefresh = (data) => {
    props.onRefresh;

    if (data?.id) {
      navigate(`/dashboard/quality/checklists/${data.id}`);
    }
  };

  const ChecklistForm = () => (
    <Formik
      enableReinitialize
      initialValues={{
        id: checklist?.id || null,
        title: checklist?.title || null,
        customer_id: checklist?.customer?.id || customer?.id,
        project_id: checklist?.project?.id || project?.id,
        checklist_type_id: checklist?.checklist_type?.id || null,
        date: checklist?.date || new Date().toISOString().substring(0, 10),
        responsible_executive_id: checklist?.responsible_executive?.id || null,
        folder_id: checklist?.folder?.id || null,
        submit: null,
      }}
      validationSchema={Yup.object({
        // customer_id: Yup.string().required(
        //   t("customer.required")
        // ),
        // customer_id: Yup.string().required("Customer is required"),
        // project_id: Yup.string().required("Customer is required"),
        checklist_type_id: Yup.string().required("Checklist Type is required"),
      })}
      onSubmit={(values, helpers) => {
        try {
          dispatchWithToast(
            createOrUpdateDispatch(values),
            (data) => handleOnRefresh(data),
            onClose(true)
          );

          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
        } catch (err) {
          console.log(err);
          toast.error("Something went wrong...");
          console.error(err);
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <Form>
          <div className="grid grid-cols-6 gap-6 mb-[200px]">
            <div className="col-span-6 sm:col-span-6">
              <PMField
                name="title"
                type="text"
                label={t("Title")}
                {...formik}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <PMField name="date" type="date" label={t("Date")} {...formik} />
            </div>

            {!props?.customer?.id && (
              <div className="col-span-6 sm:col-span-3">
                <CustomerSelector
                  selected={
                    checklist?.customer?.id ? checklist?.customer : customer
                  }
                  {...formik}
                />
              </div>
            )}

            {!props?.project?.id && (
              <div className="col-span-6 sm:col-span-3">
                <ProjectSelector
                  selected={
                    checklist?.project?.id ? checklist?.project : project
                  }
                  {...formik}
                />
              </div>
            )}

            <div className="col-span-6 sm:col-span-3">
              <ProjectFolderSelector
                projectId={formik.values?.project_id || project?.id}
                selected={checklist?.folder?.id ? checklist?.folder : null}
                {...formik}
              />
            </div>

            {/* <div className="col-span-6 sm:col-span-3">
              <PMField
                name="reference"
                type="text"
                label={t("Reference")}
                {...formik}
              />
            </div> */}

            <div className="col-span-6 sm:col-span-3">
              <PMCombobox
                name="checklist_type_id"
                label={t("Checklist Type")}
                options={checklistTypeOptions}
                {...formik}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <PMCombobox
                name="responsible_executive_id"
                label={t("Responsible Executive")}
                options={userOptions.data}
                {...formik}
              />
            </div>
          </div>

          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
              type="button"
              className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
              onClick={onClose}
            >
              {t("Close")}{" "}
            </button>

            <button
              type="button"
              className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
              onClick={formik.submitForm}
            >
              {formik?.values?.id ? t("Update") : t("Create")}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    <div className="flex items-center py-5 w-full">
                      <div className="block">
                        <h2
                          id="applicant-information-title"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          {t("Create Checklist")}
                        </h2>
                      </div>
                    </div>
                  </Dialog.Title>
                  {isLoading ? <ResourceLoading /> : <ChecklistForm />}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
