import {
  ArrowPathRoundedSquareIcon,
  Cog6ToothIcon,
  CurrencyBangladeshiIcon,
  CurrencyDollarIcon,
  MapIcon,
} from "@heroicons/react/24/outline";
import PMLinkCard from "../../../components/utils/PMLinkCard";
import { toast } from "react-hot-toast";
import { CompanyIntegrationDialog } from "../../../components/dashboard/company/integrations/dialog";
import { useState } from "react";
import {
  deleteTripletexSubscriptions,
  fetchCompany,
  syncModule,
} from "../../../api/company";
import { useEffect } from "react";
import SimplePrompt from "../../../components/utils/simplePrompt";
import { mapErrors } from "../../../components/Utils";
import { useTranslation } from "react-i18next";
import SyncDialog from "../../../components/dashboard/company/integrations/sync-dialog";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";

export const CompanyIntegrations = () => {
  const [integrationDialogOpen, setIntegrationDialogOpen] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [company, setCompany] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const handleIntegrationDialogClose = () => {
    setIntegrationDialogOpen(false);
    setSelectedIntegration(null);
  };

  const handleIntegrationDialogOpen = (id) => {
    setIntegrationDialogOpen(true);
    setSelectedIntegration(id);
  };

  const getCompany = () => {
    setIsLoading(true);
    fetchCompany()
      .then((res) => {
        // Set companyState
        setIsLoading(false);
        setCompany(res.data);
      })
      .catch((error) => {
        // Set companyState (errors, error and isLoading)
        setError(true);
        setErrors(error.data.errors);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCompany();
  }, []);

  const onRefresh = (data) => {
    // Update companyState
    setCompany(data);
  };

  const [syncPromptState, setSyncPromptState] = useState(false);
  const [deleteSubscriptionPromptOpen, setDeleteSubscriptionPromptOpen] =
    useState(false);

  const [syncState, setSyncState] = useState({
    isLoading: false,
    error: false,
    errors: [],
    updated: 0,
    created: 0,
    found: 0,
  });

  const [syncDialogOpen, setSyncDialogOpen] = useState(false);

  const [syncModules, setSyncModules] = useState({});

  const runOrderSync = () => {
    const params = {
      syncUsers: true,
      syncCustomers: true,
      syncDeliveryAddresses: true,
      syncDepartments: true,
      syncContacts: true,
      syncProjects: true,
      syncProducts: false,
      force: true,
    };
    return syncModule(params)
      .then((res) => {
        setSyncState((prevState) => ({
          ...prevState,
          isLoading: false,
          updated: res?.data?.updated,
          created: res?.data?.created,
          found: res?.data?.found,
        }));
        toast.success(
          `${syncState.updated} was updated out of ${syncState.found} - ${syncState.created} was created`
        );
        return res;
      })
      .catch((err) => {
        // Updating ItemState
        setSyncState((prevState) => ({
          ...prevState,
          isLoading: false,
          updated: 0,
          created: 0,
          found: 0,
        }));
        return err;
      });
  };

  const SyncOrders = () => {
    setSyncState({
      isLoading: true,
      error: false,
      errors: [],
      updated: 0,
      created: 0,
      found: 0,
    });

    toast.promise(runOrderSync(), {
      loading: t("Syncing...."),
      success: (res) => {
        if (res.data.errors) throw new Error(mapErrors(res.data.errors));
        if (res.status === 500) throw new Error("Server error");
        return `Sync was successfull`;
      },
      error: t("Something went wrong. Could not save data!"),
    });
  };

  const handleDeleteTripletexSubscriptions = () => {
    toast.promise(deleteTripletexSubscriptions(), {
      loading: t("Syncing...."),
      success: (res) => {
        if (res.data.errors) throw new Error(mapErrors(res.data.errors));
        if (res.status === 500) throw new Error("Server error");
        return `Sync was successfull`;
      },
      error: t("Something went wrong. Could not save data!"),
    });
  };

  const headerActions = [
    {
      component: null,
      label: "Sync Tripletex with promang",
      description: "",
      path: null,
      action: () => setSyncDialogOpen(true),
      icon: ArrowPathRoundedSquareIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: true,
    },
    {
      component: null,
      label: "Delete Tripletex subscriptions",
      description: "",
      path: null,
      action: () => setDeleteSubscriptionPromptOpen(true),
      icon: ArrowPathRoundedSquareIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: true,
    },
  ];

  const integrations = [
    {
      id: 0,
      label: "Tripletex API",
      name: "tripletex",
      description: "Integrate Promang with Tripletex",
      path: "",
      action: () => handleIntegrationDialogOpen(0),
      icon: Cog6ToothIcon,
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
      initialFormValues: [
        {
          name: "tripletex_consumer_token",
          value: company?.tripletex_consumer_token,
          label: "Tripletex Consumer Token",
        },
        {
          name: "tripletex_employee_token",
          value: company?.tripletex_employee_token,
          label: "Tripletex Employee Token",
        },
      ],
    },
    {
      id: 2,
      label: "Bring API",
      name: "bring",
      description: "Integrate Promang with Bring API",
      path: "",
      action: () => handleIntegrationDialogOpen(1),
      icon: Cog6ToothIcon,
      iconForeground: "text-sky-700",
      iconBackground: "bg-sky-50",
      initialFormValues: [
        {
          name: "bring_api_uid",
          value: company?.bring_api_uid,
          label: "Bring API UID",
        },
        {
          name: "bring_api_key",
          value: company?.bring_api_key,
          label: "Bring API Key",
        },
        {
          name: "bring_main_customer_number",
          value: company?.bring_main_customer_number,
          label: "Bring Main Customer Number",
        },
      ],
    },
    {
      id: 3,
      label: "Stripe API",
      name: "stripe",
      description: "Integrate Promang with Stripe API",
      path: "",
      action: () => handleIntegrationDialogOpen(2),
      icon: Cog6ToothIcon,
      iconForeground: "text-sky-700",
      iconBackground: "bg-sky-50",
      initialFormValues: [
        {
          name: "stripe_webhook_secret",
          value: company?.stripe_webhook_secret,
          label: "Stripe Webhook Secret",
        },
        {
          name: "stripe_webhook_url",
          value: company?.stripe_webhook_url,
          label: "Stripe Webhook URL",
          disabled: true,
        },
        {
          name: "stripe_secret_key",
          value: company?.stripe_secret_key,
          label: "Stripe Secret Key",
        },
      ],
    },
    {
      id: 4,
      label: "Automile API",
      name: "automile",
      description: "Integrate Promang with Automile API",
      path: "",
      action: () => handleIntegrationDialogOpen(3),
      icon: Cog6ToothIcon,
      iconForeground: "text-sky-700",
      iconBackground: "bg-sky-50",
      initialFormValues: [
        {
          name: "automile_client_id",
          value: company?.automile_client_id,
          label: "Automile Client ID",
        },
        {
          name: "automile_client_secret",
          value: company?.automile_client_secret,
          label: "Automile Client Secret",
          disabled: false,
        },
        {
          name: "automile_username",
          value: company?.automile_username,
          label: "Automile Username",
        },
        {
          name: "automile_password",
          value: company?.automile_password,
          label: "Automile Password",
          type: "password",
        },
      ],
    },

    {
      id: 5,
      label: "Google Maps API",
      name: "google_maps",
      description: "Integrate Promang with Google Maps API",
      path: "",
      action: () => handleIntegrationDialogOpen(4),
      icon: MapIcon,
      iconForeground: "text-sky-700",
      iconBackground: "bg-sky-50",
      initialFormValues: [
        {
          name: "google_maps_api_key",
          value: company?.google_maps_api_key,
          label: "Google Maps API ID",
        },
      ],
    },
    {
      id: 6,
      label: "Webshop",
      name: "webshop",
      description: "Integrate Promang with Webshop",
      path: "",
      action: () => handleIntegrationDialogOpen(5),
      icon: CurrencyDollarIcon,
      iconForeground: "text-sky-700",
      iconBackground: "bg-sky-50",
      initialFormValues: [
        {
          name: "store_checkout_active",
          type: "switch",
          value: company?.store_checkout_active,
          label: "Webshop Checkout Enabled",
        },
        {
          name: "webshop_products_url",
          value: company?.webshop_products_url,
          label: "Webshop Products URL",
        },
        {
          name: "webshop_order_cancel_url",
          value: company?.webshop_order_cancel_url,
          label: "Webshop Order Cancel URL",
        },
        {
          name: "webshop_order_success_url",
          value: company?.webshop_order_success_url,
          label: "Webshop Order Success URL",
        },
      ],
    },
  ];

  function Content() {
    return (
      <>
        <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
          <div className="">
            <PMLinkCard actions={integrations} cols={integrations.length} />
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={"Integrations"}
        meta={[]}
        // mainButton={{
        //   label: "Edit",
        //   action: () => setReportDialogOpen(true),
        //   path: null,
        //   color: "",
        //   icon: PencilIcon,
        // }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <Content />
        </div>
      </DashboardHeader>
      {selectedIntegration !== null && (
        <CompanyIntegrationDialog
          company={company}
          integration={integrations[selectedIntegration]}
          open={integrationDialogOpen}
          isLoading={isLoading}
          onLoading={(state) => setIsLoading(state)}
          onClose={() => handleIntegrationDialogClose()}
          onRefresh={onRefresh}
        />
      )}
      <SimplePrompt
        title="Sync av produkter"
        description="Er du sikker på at du ønsker å synke produkter nå?"
        onAccept={() => SyncOrders()}
        open={syncPromptState}
        onClose={() => setSyncPromptState(false)}
      />

      <SimplePrompt
        title="Slett alle Tripletex Subscription Events"
        description="Er du sikker på at du ønsker å slette alle nå? Dette kan ikke angres!"
        onAccept={() => handleDeleteTripletexSubscriptions()}
        open={deleteSubscriptionPromptOpen}
        onClose={() => setDeleteSubscriptionPromptOpen(false)}
      />

      <SyncDialog
        open={syncDialogOpen}
        onClose={() => setSyncDialogOpen(false)}
      />
    </>
  );
};
