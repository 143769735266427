import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { DeviationStacklist } from "./deviationStacklist";
import { Link } from "react-router-dom";
import { Popup } from "../../../utils/Div";
import { useSelector } from "react-redux";

export const DeviationReportView = () => {
  const currentUser = useSelector((store) => {
    return store.session.currentUser;
  });

  return (
    <>
      <div className="bg-white px-5 py-5 rounded-2xl shadow md:min-h-[350px] max-h-[350px]">
        <div className="flex justify-between">
          <h1 className="px-2 py-2 font-bold">Avvik tildelt deg</h1>
          <Popup content="Se alle avvik">
            <Link
              className="px-2 py-3 cursor-pointer "
              to="/dashboard/quality/deviations"
            >
              <ArrowRightIcon className="h-5 w-5 text-gray-400 hover:text-blue-400" />
            </Link>
          </Popup>
        </div>
        <div className="max-h-[250px]  overflow-auto">
          <DeviationStacklist
            filters={[
              { name: "status", value: JSON.stringify(["1", "2"]) },
              { name: "assigned_user", value: JSON.stringify(currentUser?.id) },
            ]}
          />
        </div>
      </div>
    </>
  );
};
