import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ProductsFilter } from "../../../../components/dashboard/products/products-filter";
import { ProductsTable } from "../../../../components/dashboard/products/products-table";
import DashboardHeader from "../../../../components/dashboard/dashboardHeader";
import {
  ArrowUpIcon,
  CheckBadgeIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import ProductModal from "../../../../components/dashboard/products/ProductModal";
import {
  mapErrors,
  pageOptions,
  updateUrlWithException,
} from "../../../../components/Utils";
import { ServiceAgreeementActionEditBar } from "../../../../components/dashboard/service/agreements/action-edit-bar";
import ServiceAgreementMultiEditDialog from "../../../../components/dashboard/service/agreements/multi-edit-dialog";
import { getServiceAgreementsAsync } from "../../../../slices/service/agreements";
import ServiceAgreementModal from "../../../../components/dashboard/service/agreements/modal";
import { ServiceAgreementsTable } from "../../../../components/dashboard/service/agreements/table";
import { reportViewsOptions } from "../../../../components/dashboard/service/reports/utils";
import { ServiceAgreementViewOptions } from "../../../../components/dashboard/service/agreements/utils";
import { debounce } from "lodash";

export const ServiceAgreementList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const agreementState = useSelector((store) => {
    return store.service_agreements;
  });

  const { isLoading } = agreementState;
  const [selectedItems, setSelectedItems] = useState([]);
  const [controller, setController] = useState({
    filters: [{ name: "view", value: JSON.stringify(["ACTIVE"]) }],
    page: searchParams.get("page") ? searchParams.get("page") : 1,
    per_page: searchParams.get("per_page") ? searchParams.get("per_page") : 25,
    query: searchParams.get("query") ? searchParams.get("query") : "",
    sort: searchParams.get("sort") ? searchParams.get("sort") : "asc",
    sortBy: searchParams.get("sortBy")
      ? searchParams.get("sortBy")
      : "next_service_date",
    view: searchParams.get("view") ? searchParams.get("view") : "all",
  });

  // Update all params from controller except filters (dont't touch filters)
  useEffect(() => {
    updateUrlWithException(
      // These will be updated
      {
        page: controller.page,
        per_page: controller.per_page,
        query: controller.query,
        sort: controller.sort,
        sortBy: controller.sortBy,
        view: controller.view,
      },
      // This will be preserved
      "filters"
    );
  }, [controller]);

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery,
    });
  };

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handlePagePageChange = (option) => {
    setController({
      ...controller,
      per_page: option,
    });
  };

  const handleFilterChange = (selectedFilters) => {
    setController({
      ...controller,
      filters: selectedFilters?.map((filter) => {
        return {
          name: filter.id,
          value: JSON.stringify(filter.values),
        };
      }),
    });
  };

  const debouncedDispatchAction = debounce((controller) => {
    dispatch(getServiceAgreementsAsync(controller));
  }, 1000);

  useEffect(() => {
    // Trigger the debounced dispatch action
    debouncedDispatchAction(controller);

    // Clean the debounced function on component unmount.
    return () => {
      debouncedDispatchAction.cancel();
    };
  }, [controller, dispatch]);

  const [editModalOpen, setEditModalOpen] = useState(false);

  const headerActions = [];

  const headerMeta = [
    {
      label: agreementState.totalCount + " Products",
      description: "wdw",
      path: null,
      action: null,
      icon: CheckBadgeIcon,
      textColor: "text-teal-700",
      hidden: false,
    },
    {
      label: agreementState.newCount + " New agreements",
      description: "wdw",
      path: null,
      action: null,
      icon: ArrowUpIcon,
      textColor: "text-teal-700",
      hidden: false,
    },
  ];

  const [multiEditDialogOpen, setMultiEditDialogOpen] = useState(false);
  const [priceChangeDialogOpen, setPriceChangeDialogOpen] = useState(false);

  const sortByOptions = [
    { name: "Seneste (Rapportnr.)", sort: "asc", sortBy: "report_number" },
    { name: "Eldste (Rapportnr.)", sort: "desc", sortBy: "report_number" },
  ];

  const initialFilters = [
    // {
    //   id: "category",
    //   name: t("Category"),
    //   optionsUrl: "/agreements/categories/",
    //   optionsParams: {},
    // },
    {
      id: "view",
      name: "View",
      options: ServiceAgreementViewOptions,
    },
    // {
    //   id: "status",
    //   name: "Status",
    //   // options: reportStatusesOptions,
    // },
  ];

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={"Agreements"}
        meta={headerMeta}
        mainButton={{
          label: "New",
          action: () => setEditModalOpen(true),
          path: null,
          color: "",
          icon: PlusIcon,
        }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            {/* <ProductsStats
              totalProductsCount={agreementState.count}
              newestProductsCount={agreementState.newestProductsCount}
            /> */}

            <ProductsFilter
              className={"mt-10"}
              disabled={isLoading}
              onQueryChange={handleQueryChange}
              query={controller.query}
              selectedItems={selectedItems}
              sortByOptions={sortByOptions}
              perPageOptions={[10, 25, 50, 100, 250]}
              onPerPageChange={handlePagePageChange}
              pageOptions={pageOptions({
                perPage: controller.per_page,
                totalCount: agreementState.count,
              })}
              onPageChange={handlePageChange}
              initialFilters={initialFilters}
              onFilterChange={handleFilterChange}
              defaultSelectedOptions={[{ name: "view", value: "ACTIVE" }]}
              actionBar={
                <ServiceAgreeementActionEditBar
                  selectedItems={selectedItems}
                  setMultiEditDialogOpen={() => setMultiEditDialogOpen(true)}
                  setPriceChangeDialogOpen={() =>
                    setPriceChangeDialogOpen(true)
                  }
                />
              }
            />

            <ServiceAgreementsTable
              onItemsSelect={(items) => setSelectedItems(items)}
              itemState={agreementState}
              itemsCount={agreementState.totalCount}
              currentCount={agreementState.currentCount}
              page={controller.page}
              onPageChange={handlePageChange}
              perPage={controller.per_page}
            />
          </div>
        </div>
      </DashboardHeader>

      <ServiceAgreementModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
      />

      <ServiceAgreementMultiEditDialog
        selectedItems={selectedItems}
        open={multiEditDialogOpen}
        onClose={() => setMultiEditDialogOpen(false)}
      />
    </>
  );
};
