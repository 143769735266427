import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { TableBody } from "../../../utils/PMTable/TableBody";
import { PMPagination } from "../../../utils/PMPagination";
import { Link } from "react-router-dom";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { TWclassNames } from "../../../utils/Div";
import {
  ContactTitle,
  CustomerTitle,
  DeliveryAddressTitle,
  LoadingRows,
  ProjectTitle,
  ServiceAgreementTitle,
} from "../../../Utils";
import {
  ServiceAgreementIntervalById,
  ServiceAgreementNextServiceStatus,
  ServiceAgreementStatus,
} from "./utils";
import { ResourceEmpty } from "../../../utils/ResourceEmpty";

export const ServiceAgreementsTable = (props) => {
  const {
    itemState,
    onPageChange,
    itemsCount,
    currentCount,
    page,
    perPage,
    onItemsSelect,
  } = props;
  const { data, isLoading, errors } = itemState;

  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedItems.length > 0 && selectedItems.length < data.length;
    setChecked(selectedItems.length === data.length);
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
    onItemsSelect(selectedItems);
  }, [selectedItems]);

  function toggleAll() {
    setSelectedItems(checked || indeterminate ? [] : data);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const statuses = {
    "none": { label: "None", class: "bg-gray-100 text-black-800" },
    "active": { label: "Active", class: "bg-green-100 text-green-800" },
    "unpublished": { label: "Unpublished", class: "bg-red-600 text-white" },
  };

  function renderRows() {
    return data?.map((item, index) => {
      return (
        <tr key={item.id}>
          <td className="relative px-7 sm:w-12 sm:px-6">
            {selectedItems.includes(item) && (
              <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-400" />
            )}
            <input
              type="checkbox"
              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-400 focus:ring-blue-400"
              value={item.id}
              checked={selectedItems.includes(item)}
              onChange={(e) =>
                setSelectedItems(
                  e.target.checked
                    ? [...selectedItems, item]
                    : selectedItems.filter((p) => p !== item)
                )
              }
            />
          </td>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <div className="flex flex-col">
              <ServiceAgreementTitle target="" serviceAgreement={item} />
              {item?.type && item?.type?.displayTitle}
            </div>
          </td>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <div className="">
              {item?.customer && <CustomerTitle customer={item?.customer} />}
              {item?.project && <ProjectTitle project={item?.project} />}
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <DeliveryAddressTitle deliveryAddress={item?.delivery_address} />
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <div className="flex flex-col">
              <span>{item?.next_service_date}</span>
              <span>
                {ServiceAgreementIntervalById(item?.interval?.id).label}
              </span>
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <ServiceAgreementStatus serviceAgreement={item} />
          </td>

          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <ServiceAgreementNextServiceStatus serviceAgreement={item} />
          </td>
        </tr>
      );
    });
  }

  if (isLoading) return <ResourceLoading />;

  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="relative">
            <table className="min-w-full table-fixed divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                    <input
                      type="checkbox"
                      className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-400 focus:ring-blue-400"
                      ref={checkbox}
                      checked={checked}
                      onChange={toggleAll}
                    />
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    <div className="flex flex-col">
                      <span>Title</span>
                      <span>Type</span>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    <div className="flex flex-col">
                      <span>Project</span>
                      <span>Customer</span>
                    </div>
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Delivery Address
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    <div className="flex flex-col">
                      <span>Next Service Date</span>
                      <span>Interval</span>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Days til next service
                  </th>
                </tr>
              </thead>
              <TableBody>
                {isLoading ? (
                  <>
                    <LoadingRows cols={6} checkboxCell={true} />
                    <LoadingRows cols={6} checkboxCell={true} />
                    <LoadingRows cols={6} checkboxCell={true} />
                    <LoadingRows cols={6} checkboxCell={true} />
                    <LoadingRows cols={6} checkboxCell={true} />
                    <LoadingRows cols={6} checkboxCell={true} />
                    <LoadingRows cols={6} checkboxCell={true} />
                    <LoadingRows cols={6} checkboxCell={true} />
                    <LoadingRows cols={6} checkboxCell={true} />
                    <LoadingRows cols={6} checkboxCell={true} />
                    <LoadingRows cols={6} checkboxCell={true} />
                    <LoadingRows cols={6} checkboxCell={true} />
                    <LoadingRows cols={6} checkboxCell={true} />
                  </>
                ) : data.length < 1 ? (
                  <tr>
                    <td colSpan={7} className="py-2 px-2">
                      <ResourceEmpty />
                    </td>
                  </tr>
                ) : (
                  renderRows()
                )}
              </TableBody>
            </table>

            <PMPagination
              disabled={isLoading}
              onPageChange={onPageChange}
              page={page}
              perPage={perPage}
              rowsCount={itemsCount}
              totalCount={itemsCount}
              currentCount={currentCount}
              showCount={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ServiceAgreementsTable.defaultProps = {
  page: 1,
  itemState: [],
  itemsCount: 0,
  selectedItems: [],
  sort: "desc",
  sortBy: "createdAt",
};
