import { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { store } from "../store";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const initialSettings = {
  direction: "ltr",
  language: "no",
  pinSidebar: true,
  theme: "dark",
};

const localeTranslationFromRails = {
  "en": "en",
  "nn": "no",
  "sv": "se",
};

const localeCurrency = {
  "en": "USD",
  "nn": "NOK",
};

export const restoreSettings = async (props) => {
  const user = await store.getState().session.currentUser;

  let settings = null;

  try {
    const storedData = window.localStorage.getItem("settings");

    if (storedData) {
      settings = JSON.parse(storedData);
    } else {
      settings = {
        direction: "ltr",
        language: user?.settings?.locale
          ? localeTranslationFromRails[user.settings?.locale]
          : "en",
        pinSidebar: true,
        theme: window.matchMedia("(prefers-color-scheme: dark)").matches
          ? "dark"
          : "light",
      };
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings;
};

export const storeSettings = (settings) => {
  window.localStorage.setItem("settings", JSON.stringify(settings));
};

function setLanguage(language) {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
}

export const SettingsContext = createContext({
  settings: initialSettings,
  saveSettings: () => {},
});

export const SettingsProvider = (props) => {
  const { children } = props;
  const [settings, setSettings] = useState(initialSettings);
  const { i18n } = useTranslation();
  useEffect(() => {
    const restoredSettings = restoreSettings();
    if (restoredSettings) {
      setSettings(restoredSettings);
      restoredSettings
        .then((s) => {
          i18n.changeLanguage(s.language);
        })
        .catch((err) => {
          console.error("Could not change language");
        });
    }
  }, []);

  const saveSettings = (updatedSettings) => {
    setSettings(updatedSettings);
    storeSettings(updatedSettings);
  };

  const priceFormatter = new Intl.NumberFormat("nb-NO", {
    style: "currency",
    currency: "NOK",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return (
    <SettingsContext.Provider
      value={{
        settings,
        saveSettings,
        priceFormatter,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SettingsConsumer = SettingsContext.Consumer;

export const useSettings = () => useContext(SettingsContext);
