import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { CalendarIcon, MapPinIcon, UsersIcon } from "@heroicons/react/20/solid";
import { getCasesAsync } from "../../../../slices/case";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { ResourceEmpty } from "../../../utils/ResourceEmpty";
import { ResourceError } from "../../../utils/ResourceError";
import { toast } from "react-hot-toast";
import { getReportsAsync } from "../../../../slices/service/reports";
import { TWclassNames } from "../../../utils/Div";
import {
  FullReportAddress,
  ReportAppointmentApprovedStatus,
  ReportStatus,
  reportStatuses,
} from "./utils";
import {
  DocumentArrowUpIcon,
  PencilIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import {
  CustomerTitle,
  StringLimit,
  dateToString,
  formattedDate,
  removeHtml,
  reportPath,
} from "../../../Utils";
import ReportFinishDialog from "./report-finish-dialog";
import ServiceReportEditModal from "./service-report-edit-modal";

export const ReportsStackList = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const itemState = useSelector((store) => {
    return store.reports;
  });

  const { isLoading, data, error, errors } = itemState;

  const { sort, sortBy, fields } = props;

  const [controller, setController] = useState({
    filters: [...props.filters],
    page: 1,
    query: "",
    sort: sort.length > 1 ? sort : "desc",
    sortBy: sortBy.length > 1 ? sortBy : "updated_at",
    view: "all",
    fields: fields,
  });

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery,
    });
  };

  useEffect(() => {
    const handleFilterChange = () => {
      setController({
        ...controller,
        page: 1,
        filters: [...props.filters],
      });
    };

    if (props.filters) {
      handleFilterChange();
    }
  }, [props.filters]);

  useEffect(() => {
    const payload = {
      filters: controller.filters,
      page: controller.page,
      query: controller.query,
      sort: controller.sort,
      sortBy: controller.sortBy,
      view: controller.view,
      fields: controller.fields,
    };
    dispatch(getReportsAsync(payload));
  }, [dispatch, controller]);

  const [selectedCase, setSelectedCase] = useState(null);

  function handleOpenCase(id) {
    setSelectedCase(id);
  }

  const navigate = useNavigate();

  const [finishedReportDialogOpen, setFinishedReportDialogOpen] =
    useState(false);

  const [editReportDialogOpen, setEditReportDialogOpen] = useState(false);

  const [selectedReport, setSelectedReport] = useState(null);

  const handleOpenDialog = (type, item) => {
    if (type === "report") {
      setSelectedReport(item);
      setFinishedReportDialogOpen(true);
    }

    if (type === "edit") {
      setSelectedReport(item);
      setEditReportDialogOpen(true);
    }
  };

  if (isLoading)
    return (
      <div className="mt-10">
        <ResourceLoading />
      </div>
    );
  if (error) return <ResourceError errors={errors} />;
  if (data.length === 0) return <ResourceEmpty />;

  // Checks if agreement_date is before today. Return false if it is.

  const isAgreementDateBeforeToday = (agreement_date) => {
    const today = new Date();
    const agreementDate = new Date(agreement_date);
    return agreementDate > today;
  };

  return (
    <>
      {data && (
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
          <ul role="list" className="divide-y divide-gray-200">
            {data?.map((item) => (
              <li key={item?.id} className="flex justify-between items-center">
                <Link
                  className="px-6 py-6 sm:px-8 flex-grow hover:bg-gray-50"
                  to={reportPath({ report: item })}
                >
                  <div className="flex justify-between">
                    <div className="flex flex-col justify-between">
                      <div className="flex items-center">
                        <div className="grid">
                          <div className="mb-2">
                            <ReportStatus report={item} />
                            {isAgreementDateBeforeToday(
                              item?.appointment?.date
                            ) && (
                              <ReportAppointmentApprovedStatus
                                type="short"
                                report={item}
                                className="ml-2"
                              />
                            )}
                          </div>
                          <p className="truncate text-sm font-medium text-blue-600">
                            {item?.displayTitle}
                          </p>
                          <p className="text-xs">{item?.report_type?.title}</p>
                          <p>
                            {item?.description && (
                              <StringLimit
                                limit={30}
                                text={removeHtml(item?.description)}
                              />
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="mt-2 sm:flex sm:justify-between">
                        <div className="sm:flex">
                          <p className="flex items-center text-sm text-gray-600">
                            <UsersIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500"
                              aria-hidden="true"
                            />
                            <CustomerTitle customer={item?.customer} />
                          </p>
                          {item?.delivery_address?.id && (
                            <p className="sm:ml-2 flex items-center text-sm text-gray-600">
                              <MapPinIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500"
                                aria-hidden="true"
                              />
                              <StringLimit
                                limit={30}
                                text={FullReportAddress(item)}
                              />
                            </p>
                          )}
                          {item?.executing_employee?.id && (
                            <p className="sm:ml-2 flex items-center text-sm text-gray-600">
                              <WrenchIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500"
                                aria-hidden="true"
                              />
                              {item?.executing_employee?.name}
                            </p>
                          )}
                        </div>
                        <div className="sm:ml-2 flex items-center text-sm text-gray-600">
                          <CalendarIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500"
                            aria-hidden="true"
                          />
                          <p>
                            Utførelse dato:{" "}
                            <time dateTime={item?.appointment?.date}>
                              {/* {item?.appointment?.date || "Ikke avtalt"} */}
                              {dateToString(item?.appointment?.date)}
                            </time>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
                <div className="flex flex-col h-full">
                  <button
                    onClick={() => handleOpenDialog("edit", item)}
                    className="flex bg-gray-50 px-4 py-6 text-black hover:bg-gray-300"
                  >
                    <PencilIcon
                      className="mt-1 h-5 w-5 mr-2"
                      aria-hidden="true"
                    />
                    <span>{t("Edit")}</span>
                  </button>
                  <button
                    disabled={item?.finished !== true}
                    onClick={() => handleOpenDialog("report", item)}
                    className={TWclassNames(
                      item?.finished !== true
                        ? "text-gray-200 cursor-not-allowed"
                        : "text-green-400",
                      "flex bg-gray-50 px-4 py-6  hover:bg-gray-300"
                    )}
                  >
                    <DocumentArrowUpIcon
                      className="mt-1 h-5 w-5 mr-2"
                      aria-hidden="true"
                    />
                    <span>{t("Report")}</span>
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      <ReportFinishDialog
        open={finishedReportDialogOpen}
        onClose={() => setFinishedReportDialogOpen(false)}
        selected={selectedReport}
        onRefresh={props.onRefresh}
        isLoading={isLoading}
      />

      <ServiceReportEditModal
        open={editReportDialogOpen}
        onClose={() => setEditReportDialogOpen(false)}
        selected={selectedReport}
        redirect={false}
        // onRefresh={handleOnRefresh}
      />
    </>
  );
};

ReportsStackList.defaultProps = {
  page: 1,
  itemState: [],
  itemsCount: 0,
  selectedItems: [],
  sort: "desc",
  sortBy: "created_at",
};
