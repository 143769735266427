import axios from '../axios'


export async function deleteCount(payload) {
    return axios.delete(`/inventory/counts/items/` + payload )
        .then((response) => {
        return response.data
    })
        .catch((error) => {
        return error.response.data
    });
}



// Updates multiple items at the same time. 
export async function updateCountItems(payload) {
    const id = payload.countId
    const data = payload.data 
    return axios.put(`/inventory/counts/${id}/items/list`, { data } )
    .then((response) => {
        // Should return all items, just like the fetchCountItems() method. Not only updated items
        return response.data
    })
    .catch((error) => {
        return error.response.data
    });
}


// Fetches all items for selected COUNT
export async function fetchCountItems(id) {
    return axios.get(`/inventory/counts/${id}/items` )
    .then((response) => {
        return response.data

    })
    .catch((error) => {
        return error.response.data
    });
}

