import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PMListbox(props) {
  const {
    label,
    options,
    selected,
    name,
    onChange,
    setFieldValue,
    errors,
    touched,
  } = props;

  const findSelectedById = (id) => {
    return options.find((option) => option.value === id);
  };

  const [currentSelected, setCurrentSelected] = useState();

  const handleOnChange = (data) => {
    setCurrentSelected(data);

    if (onChange) {
      onChange(data);
    }

    if (setFieldValue) {
      setFieldValue(name, data.value);
    }
  };

  useEffect(() => {
    setCurrentSelected(findSelectedById(selected));
  }, [selected, options]);

  return (
    <>
      <Listbox name={name} value={currentSelected} onChange={handleOnChange}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium text-gray-700">
              {label}
            </Listbox.Label>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full cursor-default rounded-md border border-blue-400 bg-blue-50 py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm">
                <span className="flex items-center">
                  {/* <img src={selected.avatar} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" /> */}
                  {currentSelected?.icon ? (
                    <currentSelected.icon className="w-5" />
                  ) : (
                    ""
                  )}
                  <span className="ml-3 block truncate">
                    {currentSelected?.label || "None"}
                  </span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-50 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.id}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-blue-400" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            {option?.avatar ? (
                              <img
                                src={option.avatar}
                                alt=""
                                className="h-6 w-6 flex-shrink-0 rounded-full"
                              />
                            ) : option?.icon ? (
                              <option.icon className="w-5" />
                            ) : (
                              ""
                            )}
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {option.label}
                            </span>
                          </div>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-blue-400",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {touched && touched[name] && errors && errors[name] && (
        <div className="flex mt-1 text-red-700 text-xs">
          <ExclamationCircleIcon className="w-4 text-xs" />
          <span className="ml-2">{errors[name]}</span>
        </div>
      )}
    </>
  );
}
