import { Link } from "react-router-dom";
import { TWclassNames } from "../Div";
import { toast } from "react-hot-toast";

export const ModuleTag = ({ children, label, labelAction, onAction }) => {
  const Label = () => (
    <label
      className={TWclassNames(
        labelAction?.path || labelAction?.action
          ? "text-blue-400 cursor-pointer hover:text-blue-600"
          : ""
      )}
    >
      {label}
    </label>
  );
  return (
    <div className="sm:col-span-1">
      <dt htmlFor="name" className="block text-sm font-medium text-gray-700">
        {labelAction?.action ? (
          <button className="" onClick={labelAction?.action ? onAction : null}>
            <Label />
          </button>
        ) : (
          <Link to={labelAction ? labelAction?.path : null}>
            <Label />
          </Link>
        )}
      </dt>
      <dd className="block mt-1 text-sm text-gray-900 rounded-lg relative ">
        {children ? children : "Not selected"}
      </dd>
    </div>
  );
};
