import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { ResourceError } from "../../utils/ResourceError";
import { ResourceEmpty } from "../../utils/ResourceEmpty";
import { toast } from "react-hot-toast";
import { fetchTextMessages } from "../../../api/sms";
import {
  CheckBadgeIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { CopyToClipboard } from "../../utils/Div";

export default function SmsHistoriesDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [itemState, setItemState] = useState({
    isLoading: false,
    error: false,
    errors: [],
    report: {},
  });

  const { open, onClose, items } = props;

  const { isLoading, error, errors } = itemState;

  function HistoriesItem({ item }) {
    const formattedDate = new Date(item.created_at).toLocaleString();

    return (
      <li
        key={item.id}
        className="overflow-hidden rounded-md bg-white px-6 py-4 shadow"
      >
        <div className="italic text-xs flex justify-between">
          <div className="text-gray-800">
            Created at <time>{formattedDate}</time>
          </div>
          <div className="flex">
            <div>
              {item?.status === true ? (
                <CheckCircleIcon
                  className="h-5 w-5 text-green-500"
                  aria-hidden="true"
                />
              ) : (
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              )}
            </div>
          </div>
        </div>
        <div className="mt-2">
          <span className="">{item?.description}</span>
          <span className="">{item?.error_description}</span>
        </div>
      </li>
    );
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full sm:max-w-xs max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    SMS
                  </Dialog.Title>

                  {isLoading ? (
                    <ResourceLoading />
                  ) : error ? (
                    <ResourceError errors={errors} />
                  ) : !items ? (
                    <ResourceEmpty />
                  ) : (
                    <ul role="list" className="mt-4 space-y-3">
                      {items?.map((item) => (
                        <HistoriesItem key={item?.id} item={item} />
                      ))}
                    </ul>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

SmsHistoriesDialog.defaultProps = {
  onRefresh: null,
};
