import { useTranslation } from "react-i18next";
import { useSettings } from "../../../contexts/settings-context";
import { dateToString } from "../../Utils";
import { TWclassNames } from "../../utils/Div";
import { priceChangePercentage } from "./utils";
import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
} from "@heroicons/react/24/outline";

const PriceHistoryTable = ({ product }) => {
  const { priceFormatter } = useSettings();
  const items = product?.price_histories;
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    Fra
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    Innkjøpspris
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    Salgspris
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    Til
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {items?.map((item) => {
                  const percentageChangePurchase = priceChangePercentage(
                    item?.purchase_price,
                    product.purchase_price
                  );

                  const percentageChangeSales = priceChangePercentage(
                    item?.sales_price,
                    product.sales_price
                  );

                  return (
                    <tr
                      key={item.id}
                      className={TWclassNames(
                        item.valid_to ? "bg-gray-100" : "bg-green-50",
                        "hover:bg-gray-200 cursor-pointer"
                      )}
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {dateToString(item.valid_from)}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {priceFormatter.format(item?.purchase_price)}
                          <span
                            className={TWclassNames(
                              percentageChangePurchase > 0
                                ? "text-green-500"
                                : "text-red-500",
                              "italic flex"
                            )}
                          >
                            {percentageChangePurchase > 0 ? (
                              <ArrowUpCircleIcon className="h-5 w-5" />
                            ) : (
                              <ArrowDownCircleIcon className="h-5 w-5" />
                            )}
                            <span className="ml-2">
                              {percentageChangePurchase + "%"}
                            </span>
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {priceFormatter.format(item?.sales_price)}
                          <span
                            className={TWclassNames(
                              percentageChangeSales > 0
                                ? "text-green-500"
                                : "text-red-500",
                              "italic flex"
                            )}
                          >
                            {percentageChangeSales > 0 ? (
                              <ArrowUpCircleIcon className="h-5 w-5" />
                            ) : (
                              <ArrowDownCircleIcon className="h-5 w-5" />
                            )}
                            <span className="ml-2">
                              {percentageChangeSales + "%"}
                            </span>
                          </span>
                        </div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {item?.valid_to
                            ? dateToString(item?.valid_to)
                            : "Gjeldende"}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProductPriceHistorySection = ({ product }) => {
  const { t } = useTranslation();

  return (
    <div className="shadow sm:overflow-hidden sm:rounded-md">
      <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {t("Pris Historikk")}
          </h3>
          <p className="mt-1 text-sm text-gray-500"></p>
        </div>

        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-6">
            <PriceHistoryTable product={product} />
          </div>
        </div>
      </div>
    </div>
  );
};
