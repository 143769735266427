import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { FormikSaveBar } from "../../../utils/FormikSaveBar";
import { PMField } from "../../../utils/PMField";
import { useDispatch } from "react-redux";
import PMSwitch from "../../../utils/PMSwitch";
import PMListboxFormik from "../../../utils/PMListboxFormik";
import { updateCustomerAsync } from "../../../../slices/customer";
import { DepartmentSelector } from "../../../utils/moduleSelectors/departmentSelector";

export const UserInfo = (props) => {
  const { t } = useTranslation();
  const { item } = props;
  const dispatch = useDispatch();

  return (
    <>
      <Formik
        initialValues={{
          id: item?.id,
          name: item?.name,
          email: item?.email,
          mobile: item?.mobile,
          postal_address: item?.postal_address?.id
            ? item?.postal_address
            : {
                address: item?.address,
                address2: item?.address_two,
                postal: item?.postal,
                place: item?.place,
              },
          physical_address: item?.physical_address?.id
            ? item?.physical_address
            : "",
          nextCustomerNumber: item?.nextCustomerNumber || false,
          submit: null,
          active: item?.active || false,
        }}
        validationSchema={Yup.object({
          name: Yup.string().max(255).required(t("customer.name.required")),
          description: Yup.string(),
        })}
        onSubmit={(values, helpers) => {
          try {
            toast.promise(dispatch(updateCustomerAsync(values)), {
              loading: t("Saving...."),
              success: (data) => {
                if (data?.status === 500) throw new Error("Server error");
                return t("Data was saved!");
              },
              error: t("Something went wrong. Could not save data!"),
            });
            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
          } catch (err) {
            toast.error("Nope");
            console.error(err);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                {/* <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">{ t("Product Description") }</h3>
                    <p className="mt-1 text-sm text-gray-500"></p>
                  </div> */}
                <div className="grid sm:grid-cols-8 gap-6">
                  <div className="sm:col-span-2">
                    <PMSwitch label="Active" name="active" {...formik} />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t("Contact information")}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500"></p>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <PMField name="name" type="text" label="Name" {...formik} />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <PMField
                      name="email"
                      type="text"
                      label="Email"
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <PMField
                      name="mobile"
                      type="text"
                      label="Mobile"
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6">
                    <PMField
                      name="short_description"
                      type="text"
                      label="Short Description"
                      multiline
                      rows={2}
                      {...formik}
                    />
                  </div>

                  <div className="col-span-3">
                    <DepartmentSelector
                      selected={item?.department}
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2"></div>
                </div>
              </div>
            </div>

            <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
              <div className="grid sm:grid-cols-2 gap-6">
                <div className="grid grid-cols-3 sm:grid-cols-3 bg-white sm:p-6 gap-6">
                  <div className="col-span-3">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      {t("Postal Address")}
                    </h3>
                    <p className="mt-1 text-sm text-gray-500"></p>
                  </div>
                  <div className="col-span-3 sm:col-span-3">
                    <PMField
                      name="postal_address.address"
                      type="text"
                      label="Address"
                      {...formik}
                    />
                  </div>
                  <div className="col-span-3 sm:col-span-3">
                    <PMField
                      name="postal_address.address2"
                      type="text"
                      label="Address 2"
                      {...formik}
                    />
                  </div>
                  <div className="col-span-1 sm:col-span-1">
                    <PMField
                      name="postal_address.postal"
                      type="text"
                      label="Postal"
                      placeName="postal_address.place"
                      {...formik}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-2">
                    <PMField
                      name="postal_address.place"
                      type="text"
                      label="Place"
                      {...formik}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 sm:grid-cols-3 bg-white sm:p-6 gap-6">
                  <div className="col-span-3">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      {t("Physical Address")}
                    </h3>
                    <p className="mt-1 text-sm text-gray-500"></p>
                  </div>
                  <div className="col-span-3 sm:col-span-3">
                    <PMField
                      name="physical_address.address"
                      type="text"
                      label="Address"
                      {...formik}
                    />
                  </div>
                  <div className="col-span-3 sm:col-span-3">
                    <PMField
                      name="physical_address.address2"
                      type="text"
                      label="Address 2"
                      {...formik}
                    />
                  </div>
                  <div className="col-span-1 sm:col-span-1">
                    <PMField
                      name="physical_address.postal"
                      type="text"
                      label="Postal"
                      {...formik}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-2">
                    <PMField
                      name="physical_address.place"
                      type="text"
                      label="Place"
                      {...formik}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <PromptIfDirty {...formik}/> */}
            <FormikSaveBar {...formik} />
          </Form>
        )}
      </Formik>
    </>
  );
};
