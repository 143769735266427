import { useTranslation } from "react-i18next";
import { PMSimpleSelectorField } from "../PMSimpleSelector/PMSimpleSelectorField";
import { PMSimpleSelectorFieldV2 } from "../PMSimpleSelector/PMSimpleSelectorFieldV2";
import axios from "../../../api/axios";
import { useState, useEffect } from "react";
import { mapErrors } from "../../Utils";
import { Form, Formik } from "formik";
import { ResourceLoading } from "../ResourceLoading";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { PMField } from "../PMField";
import { useDispatch, useSelector } from "react-redux";
import {
  createContactAsync,
  getContactsAsync,
} from "../../../slices/customer/contact";
import { getVariableTypesAsync } from "../../../slices/service/variable-types";
import { getActivitiesAsync, getActivityAsync } from "../../../slices/activity";
import { getActivityItemsAsync } from "../../../slices/activity_item";
import { debounce } from "lodash";

export const ActivitySelector = (props) => {
  const {
    setFieldValue,
    values,
    onChange,
    module,
    errors,
    module_param,
    fields,
    project,
    report,
    projectActivity,
  } = props;

  const disabled = props.disabled ? props.disabled : false;
  const name = props.name ? props.name : "activity_id";
  const label = props.label ? props.label : "";
  const FETCH_URL = !project?.id ? "/activities/items/" : "/activities/";
  const filters = [];
  const [query, setQuery] = useState();

  const [controller, setController] = useState({
    query: query ? query : "",
    page: 1,
    per_page: 10,
    sortBy: "created_at",
    sort: "desc",
    filters: filters,
    view: null,
  });

  // Update filters depending on project.id // report.id
  useEffect(() => {
    if (project?.id) {
      filters.push({
        name: "PROJECT",
        value: project?.id,
      });
    }
    if (report?.id) {
      filters.push({
        name: "REPORT",
        value: report?.id,
      });
    }

    // Update filters in controller
    setController({
      ...controller,
      filters: filters,
    });
  }, [project, report]);

  const itemState = useSelector((store) => {
    if (project?.id && props.module === "project") {
      return store.activities;
    } else {
      return store.activity_items;
    }
  });

  const stateLoading = itemState?.loading;

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({
    id: 0,
    field1: "Ingen valgt",
    field2: null,
  });
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);

  let labelAction = null;
  if (project?.id) {
    labelAction = {
      path: "/dashboard/projects/" + project?.id,
    };
  }

  const handleChange = (data) => {
    setSelected({
      id: data?.selected?.item?.id,
      field1: data?.selected?.item?.primary,
    });

    setOpen(false);

    if (values) {
      if (setFieldValue) setFieldValue(name, data.selected.item.id);
    }
    if (onChange) {
      onChange({
        target: {
          name: name,
          value: data.selected.item.id,
          object: data?.selected?.item?.object,
        },
      });
    }
  };

  const dispatch = useDispatch();

  const getItems = () => {
    setLoading(true);

    if (project?.id && props.module === "project") {
      // dispatch Activity instead of ActivityItems
      dispatch(getActivitiesAsync(controller));
    } else {
      dispatch(getActivityItemsAsync(controller));
    }
    setLoading(false);
  };

  useEffect(() => {
    // Format rowParams if items changes
    var array = [
      { id: null, value: null, primary: "Ingen valgt", secondary: null },
    ];
    itemState?.data?.map((row, i) => {
      const rowParams = {
        value: row.id,
        id: row.id,
        primary: row.displayTitle,
        secondary: null,
        object: row,
      };
      array.push(rowParams);
    });
    setItems(array);
    setLoading(false);
  }, [itemState, dispatch]);

  const debouncedAction = debounce((controller) => {
    getItems();
  }, 500);

  useEffect(() => {
    if (open) {
      debouncedAction();
    }

    // Clean the debounced function on component unmount.
    return () => {
      debouncedAction.cancel();
    };
  }, [query, open, dispatch, controller]);

  useEffect(() => {
    if (props.selected?.id) {
      setSelected({
        id: props.selected?.id,
        field1: props.selected?.displayTitle,
      });
    }
  }, [props.selected]);

  return (
    <>
      <PMSimpleSelectorFieldV2
        disabled={disabled}
        errors={errors}
        loading={loading}
        name={name}
        label={t(label)}
        labelAction={labelAction}
        options={items}
        search={false}
        selected={selected}
        onChange={handleChange}
        onQueryChange={(query) => setQuery(query)}
        onClose={() => setOpen(!open)}
        open={open}
        fields={[
          {
            name: "name",
            label: "Name",
            type: "text",
            required: true,
            disabled: true,
          },
        ]}
      />
    </>
  );
};
