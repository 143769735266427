import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";

import { TextField, TableFooter, Button } from "@mui/material";
import { ClassNames } from "@emotion/react";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { PMField } from "../../utils/PMField";
import { PMSimpleSelectorField } from "../../utils/PMSimpleSelector/PMSimpleSelectorField";
import ItemSelector from "../../utils/Selector/ItemSelector";
import { toast } from "react-hot-toast";
import { TrashIcon } from "@heroicons/react/24/outline";
import ProConfirmation from "../../utils/ProConfirmation";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 0,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgrey" : null,

  // styles we need to apply on draggables
  ...draggableStyle,
});

export default function OrderItems(props) {
  const { setFieldValue, values, isLoading, name, drag } = props;

  const rows = values[name];

  const columns = [
    { id: "drag", label: "", minWidth: 10, maxWidth: 5, active: drag },
    { id: "product", label: "Produkt", minWidth: 300, active: true },
    {
      id: "quantity",
      label: "Antall",
      width: "25px !important",
      minWidth: "25px",
      maxWidth: 50,
      active: props.quantity ? true : false,
    },
    {
      id: "quantity_picked",
      label: "Plukket",
      width: "25px !important",
      minWidth: "25px",
      maxWidth: 50,
      active: props.quantity_picked ? true : false,
    },
    {
      id: "unit",
      label: "Enhet",
      width: "25px !important",
      maxWidth: "50px",
      active: true,
    },
    {
      id: "purchase_price",
      label: "Innkjøpspris",
      minWidth: 50,
      align: "left",
      active: props.purchase_price ? true : false,
    },
    {
      id: "purchase_price_total",
      label: "Beløp innkjøp",
      minWidth: 50,
      align: "left",
      active: props.purchase_price ? true : false,
    },
    {
      id: "sales_price_ex_vat",
      label: "Enhetspris ekskl. mva",
      minWidth: 50,
      align: "left",
      active: props.sales_price ? true : false,
    },
    {
      id: "surcharge",
      label: "Påslag (%)",
      minWidth: 50,
      align: "left",
      active: props.surcharge ? true : false,
    },
    {
      id: "discount",
      label: "Rabatt (%)",
      minWidth: 50,
      align: "left",
      active: props.discount ? true : false,
    },

    {
      id: "total_price",
      label: "Beløp eks. mva",
      minWidth: 50,
      align: "left",
      active: props.sales_price ? true : false,
    },
  ];

  // Get the count of active columns
  const activeColumns = columns.filter((column) => column.active == true);

  var formatter = new Intl.NumberFormat("nb-NO", {
    style: "currency",
    currency: "NOK",
  });

  const [productSelectorOpen, setProductSelectorOpen] = useState(false);

  const orderItems = (rows) => {
    var array = [];

    if (!rows) return;

    rows.map((row, i) => {
      const rowParams = {
        ...row,
        row_order: i,
        draggableId: "id" + row.id,
      };
      array.push(rowParams);
    });
    return array;
  };

  // Title column is only used when group_row is true
  const defaultRows = [
    {
      id: null,
      row_order: null,
      description: "",
      purchase_price: 0,
      sales_price: 0,
      quantity: 0,
      discount: 0,
      surcharge: 0,
      group_row: false,
      unitTitle: "Stk",
    },
  ];

  const [items, setItems] = useState(
    orderItems(rows?.length > 0 ? rows : defaultRows)
  );

  useEffect(() => {
    setFieldValue(name, items);
  }, [items]);

  const addItemRow = (type) => {
    const newRow = {
      title: "",
      description: "",
      purchase_price: 0,
      quantity: 0,
      group_row: type == "group" ? true : false,
      row_order: items.length,
      // Generating a fake ID for draggable, so that we are able to move the row before saving.
      draggableId: "id" + items.length + 1,
    };
    setItems((prevState) => [...prevState, newRow]);
  };

  const onItemRowChange = (e, data) => {
    // setItems(prevState => ({ ...prevState, [1][description]: 'test' }))
    e.preventDefault();

    let prevItems = [...items];
    let item = {
      ...prevItems[data.row_order],
      [e.target.name]: e.target.value,
    };
    prevItems[data.row_order] = item;
    setItems(prevItems);
  };

  const calculateRowTotalPrice = (item) => {
    const salesPriceDivided = item.sales_price / 100;
    const surcharge = item.surcharge ? salesPriceDivided * item.surcharge : 0;
    const discount = item.discount ? salesPriceDivided * item.discount : 0;
    var total = 0;
    if (item && item.sales_price && item.quantity) {
      total =
        (parseInt(item.sales_price) +
          parseInt(surcharge) -
          parseInt(discount)) *
        item.quantity;
    }

    return total;
  };

  const calculateTotalPrice = (items) => {
    var total = 0;
    if (items.length > 0) {
      items?.map((item) => {
        if (item.group_row == false) {
          total += calculateRowTotalPrice(item);
        }
      });
    }

    return total;
  };

  const calculateRowTotalPurchasePrice = (item) => {
    var total = 0.0;
    if (item && item.purchase_price && item.quantity) {
      total = item.purchase_price * item.quantity;
    }

    return total;
  };

  const calculateTotalPurchasePrice = (items) => {
    var total = 0;
    if (items?.length > 0) {
      items?.map((item) => {
        if (item.group_row == false) {
          total += calculateRowTotalPurchasePrice(item);
        }
      });
    }

    return total;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(orderItems(newItems));
  };

  const mapSelectedProductsFromItems = () => {
    var array = [];
    items?.map((item) => {
      if (item.product) {
        array.push(item.product);
      }
    });
    return array;
  };

  const [selectedItemRow, setSelectedItemRow] = useState();

  const handleRowProductSelect = (row) => {
    setSelectedItemRow(row);
    setProductSelectorOpen(true);
  };

  const handleProductSelect = (data) => {
    setProductSelectorOpen(false);
    if (selectedItemRow) {
      let prevItems = [...items];
      let item = {
        ...prevItems[selectedItemRow.row_order],
        product: data[0],
        product_id: data[0].id,
        unit_id: data[0].unit?.id,
        unit: data[0].unit,
        sales_price: data[0].sales_price,
        purchase_price: data[0].purchase_price,
        quantity: "1.0",
      };
      prevItems[selectedItemRow.row_order] = item;
      setItems(prevItems);
    }
  };

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const handleDeleteRow = () => {
    setDeleteConfirmationOpen(false);
    // Delete selectedItem row from array
    if (selectedItemRow) {
      const newArray = items.filter((item) => item.id !== selectedItemRow);
      setItems(orderItems(newArray));
    }
  };

  const deleteRow = (id) => {
    setSelectedItemRow(id);
    setDeleteConfirmationOpen(true);
    toast(id);
  };

  const groupRow = (item, provided, snapshot) => {
    return (
      <TableRow
        sx={{
          backgroundColor: "#bec0c2",
        }}
        hover
        key={item.id}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
      >
        <TableCell>
          <DragHandleIcon />
        </TableCell>
        <TableCell align="left" colSpan={3}>
          <TextField
            label="Title"
            variant="standard"
            name="title"
            sx={{ width: "50%" }}
            value={item.title}
            onChange={(e) => onItemRowChange(e, item)}
          ></TextField>
        </TableCell>
        <TableCell align="left" colSpan={6}>
          <TextField
            label="Description"
            variant="standard"
            name="description"
            fullWidth
            multiline
            rows={2}
            value={item.description}
            onChange={(e) => onItemRowChange(e, item)}
          ></TextField>
        </TableCell>

        <TableCell align="right">
          <TrashIcon
            className="cursor-pointer h-5 h-5 text-red-400"
            onClick={() => deleteRow(item.id)}
          />
        </TableCell>
      </TableRow>
    );
  };

  const itemRow = (item, provided, snapshot) => {
    return (
      <TableRow
        hover
        key={item.id}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
      >
        {drag ? (
          <TableCell>
            <DragHandleIcon />
          </TableCell>
        ) : null}

        <TableCell align="left">
          <div
            className="cursor-pointer"
            // onClick={() => setProductSelectorOpen(true)}
            onClick={() => handleRowProductSelect(item)}
          >
            {item?.product?.id ? (
              <div className="font-bold p-2 border-b-4 border-indigo-300">
                {item?.product?.displayTitle}
              </div>
            ) : (
              <div className="text-gray-400">Velg produkt</div>
            )}
          </div>

          <TextField
            label="Beskrivelse"
            variant="standard"
            sx={{ mt: "10px" }}
            value={item?.description}
            fullWidth
            name="description"
            multiline
            onChange={(e) => onItemRowChange(e, item)}
          />
        </TableCell>

        {props.quantity && (
          <TableCell align="left" sx={{ maxWidth: "25px" }}>
            <TextField
              variant="standard"
              value={item?.quantity}
              name="quantity"
              onChange={(e) => onItemRowChange(e, item)}
            />
          </TableCell>
        )}

        {props.quantity_picked && (
          <TableCell align="left" sx={{ maxWidth: "25px" }}>
            <TextField
              variant="standard"
              value={item?.quantity_picked}
              name="quantity_picked"
              onChange={(e) => onItemRowChange(e, item)}
            />
          </TableCell>
        )}

        <TableCell align="left">
          <TextField
            variant="standard"
            value={item?.unit?.title}
            name="unitTitle"
            disabled
          />
        </TableCell>

        {props.purchase_price ? (
          <TableCell align="left">
            <TextField
              variant="standard"
              value={item?.purchase_price}
              fullWidth
              name="purchase_price"
              onChange={(e) => onItemRowChange(e, item)}
            />
          </TableCell>
        ) : null}

        {props.purchase_price ? (
          <TableCell align="left">
            {formatter.format(calculateRowTotalPurchasePrice(item))}
          </TableCell>
        ) : null}

        {/* sales_price  */}
        {props.sales_price ? (
          <TableCell align="left">
            <TextField
              variant="standard"
              fullWidth
              name="sales_price"
              value={item.sales_price}
              onChange={(e) => onItemRowChange(e, item)}
            />
          </TableCell>
        ) : null}

        {/* Påslag // Surcharge  */}
        {props.surcharge ? (
          <TableCell align="left">
            <TextField
              variant="standard"
              fullWidth
              name="surcharge"
              value={item.surcharge}
              onChange={(e) => onItemRowChange(e, item)}
            />
          </TableCell>
        ) : null}

        {/* Rabatt // Discount */}
        {props.discount ? (
          <TableCell align="left">
            <TextField
              variant="standard"
              fullWidth
              name="discount"
              value={item.discount}
              onChange={(e) => onItemRowChange(e, item)}
            />
          </TableCell>
        ) : null}

        {props.sales_price ? (
          <TableCell align="left">
            {formatter.format(calculateRowTotalPrice(item))}
          </TableCell>
        ) : null}

        <TableCell align="right">
          {item?.id && (
            <TrashIcon
              className="cursor-pointer h-5 h-5 text-red-400"
              onClick={() => deleteRow(item.id)}
            />
          )}
        </TableCell>
      </TableRow>
    );
  };

  if (isLoading) {
    return <ResourceLoading />;
  }

  return (
    <React.Fragment>
      <Table sx={{ width: "100%" }}>
        <TableHead>
          {/* <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center" colSpan={2}>Product</TableCell>
                        { props.purchase_price ? 
                            <TableCell align="center" colSpan={3}>Innkjøpspris</TableCell>
                        : null }

                        { props.sales_price ? 
                            <TableCell align="center" colSpan={4}>Salgspris</TableCell>
                        : null }
                    </TableRow> */}
          <TableRow>
            {columns.map((column) =>
              column.active ? (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    top: 57,
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                    width: column.width,
                  }}
                >
                  {column.label}
                </TableCell>
              ) : null
            )}
          </TableRow>
        </TableHead>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                {items?.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.draggableId}
                    index={index}
                  >
                    {(provided, snapshot) =>
                      item.group_row == true
                        ? props.group
                          ? groupRow(item, provided, snapshot)
                          : null
                        : itemRow(item, provided, snapshot)
                    }
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>

        <TableFooter>
          <TableRow>
            <TableCell align="left" colSpan={2}>
              <Button onClick={() => addItemRow()}>Ny rad</Button>
            </TableCell>
            {props.group ? (
              <TableCell align="left" colSpan={3}>
                <Button onClick={() => addItemRow("group")}>
                  Ny gruppering
                </Button>
              </TableCell>
            ) : null}
            {rows && props.purchase_price ? (
              <TableCell laft="left" colSpan={1}>
                {formatter.format(calculateTotalPurchasePrice(items))}
              </TableCell>
            ) : null}
            <TableCell laft="left" colSpan={3}></TableCell>
            {rows && props.sales_price ? (
              <TableCell laft="left" colSpan={1}>
                {" "}
                {formatter.format(calculateTotalPrice(items))}
              </TableCell>
            ) : null}{" "}
          </TableRow>
        </TableFooter>
      </Table>
      <ItemSelector
        {...props}
        multiple={false}
        open={productSelectorOpen}
        selectorCloseCallback={() => setProductSelectorOpen(false)}
        selectorType="products"
        module={"product"}
        // module_param={product.id}
        selectedItems={mapSelectedProductsFromItems()}
        onSelect={(data) => handleProductSelect(data)}
        // selectedItemsCallback={handleSelectedItemsCallback}
      />

      <ProConfirmation
        severity="confirmation"
        open={deleteConfirmationOpen}
        message={"Are you sure you want to delete this row?"}
        action="delete"
        module="order_item"
        // item_id={confirmation.item_id}
        yesCallback={() => handleDeleteRow(selectedItemRow)}
        cancelCallback={() => setDeleteConfirmationOpen(false)}
      />
    </React.Fragment>
  );
}

OrderItems.defaultProps = {
  group: false,
  purchase_price: false,
  sales_price: false,
  name: "items",
  drag: true,
  quantity_picked: false,
  quantity: true,
};
