import React, { useRef } from "react";
import axios from "axios";

import { Grid } from "@mui/material";
import UploadService from "./UploadService";
import { LoadingButton } from "@mui/lab";
import { CameraIcon, CheckBadgeIcon } from "@heroicons/react/24/outline";
import { Avatar, Box, Button, Typography } from "@mui/material";

import imgPlaceholder from "../../../utils/img-placeholder.png";
class MediaUpload extends React.Component {
  constructor(props) {
    super(props);
    this.upload = this.upload.bind(this);
    this.selectFiles = this.selectFiles.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.state = {
      isLoading: false,
      selectedFile: {},
      selectedFiles: undefined,
      documents: [],
      document: [],
      progressInfos: [],
      fileInfos: [],
      selectedDocument: false,
    };
  }

  selectFiles(event) {
    this.setState(
      {
        progressInfos: [],
        selectedFiles: event.target.files,
      },
      this.uploadFiles
    );
  }

  clearForm() {
    this.setState({
      selectedFiles: "",
    });

    // fileInput.current.value = "";
  }

  upload(idx, file) {
    let _progressInfos = [...this.state.progressInfos];

    UploadService.upload(
      file,
      this.props.module,
      this.props.module_param,
      (event) => {
        _progressInfos[idx].percentage = Math.round(
          (100 * event.loaded) / event.total
        );
        this.setState({
          _progressInfos,
        });
      }
    )
      .then((response) => {
        this.props.parentCallback(response.data);

        // this.setState([...documents, document]);
        // this.state.documents.push(response.data);

        this.setState((prev) => {
          let nextMessage = [
            ...prev.message,
            "Uploaded the file successfully: " + file.name,
          ];
          return {
            message: nextMessage,
          };
        });
      })
      .then((files) => {
        this.setState({
          fileInfos: files.data,
        });
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;
        this.setState((prev) => {
          let nextMessage = [
            ...prev.message,
            "Could not upload the file: " + file.name,
          ];
          return {
            progressInfos: _progressInfos,
            message: nextMessage,
          };
        });
      });
  }

  uploadFiles() {
    this.setState({
      isLoading: true,
    });

    const selectedFiles = this.state.selectedFiles;

    let _progressInfos = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
    }

    this.setState(
      {
        progressInfos: _progressInfos,
        message: [],
        isLoading: false,
      },
      () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          this.upload(i, selectedFiles[i]);
        }
      }
    );
  }

  // onRefresh = () => {
  //   this.updateDocuments();
  // }

  uploader() {
    return (
      <div className="mt-6 flex-grow lg:mt-0 lg:flex-shrink-0 lg:flex-grow-0">
        {/* <p className="text-sm font-medium text-gray-700" aria-hidden="true">
          Photo
        </p> */}
        <div className="mt-1 lg:hidden">
          <div className="flex items-center">
            <div
              className="inline-block h-12 w-12 flex-shrink-0 overflow-hidden rounded-sm"
              aria-hidden="true"
            >
              <img
                className="h-10 w-10 rounded-sm"
                src={imgPlaceholder}
                alt=""
              />
            </div>
            <div className="ml-5 rounded-md shadow-sm">
              <div className="group relative flex items-center justify-center rounded-md border border-gray-300 py-2 px-3 focus-within:ring-2 focus-within:ring-sky-500 focus-within:ring-offset-2 hover:bg-gray-50">
                <label
                  htmlFor="mobile-user-photo"
                  className="pointer-events-none relative text-sm font-medium leading-4 text-gray-700"
                >
                  <span>Change</span>
                  <span className="sr-only"> user photo</span>
                </label>
                <input
                  id="mobile-user-photo"
                  name="user-photo"
                  type="file"
                  className="absolute h-5 w-5 cursor-pointer rounded-md border-gray-300 opacity-0"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="relative hidden overflow-hidden rounded-sm lg:block h-20 w-full bg-gray-200">
          {/* <img className="relative h-10 w-10 rounded-xs" src={imgPlaceholder} alt="" /> */}
          <CameraIcon className="absolute inset-0 flex h-full w-20 ml-5" />
          <div className="absolute inset-0 flex h-full w-full items-center justify-center">
            Last opp bilde
          </div>
          <label
            htmlFor="desktop-user-photo"
            className="absolute inset-0 flex h-full w-full items-center justify-center bg-black bg-opacity-75 text-sm font-medium text-white opacity-0 focus-within:opacity-100 hover:opacity-100"
          >
            <span>Click to upload</span>
            <span className="sr-only"> user photo</span>

            <input
              className="absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
              type="file"
              onChange={this.selectFiles}
              multiple
              ref={this.fileInput}
            />
          </label>
        </div>
      </div>
    );
  }

  render() {
    const { isLoading } = this.state;

    return (
      <>
        <div>{this.uploader()}</div>
      </>
    );
  }
}

export default MediaUpload;
