import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";

import { TextField, TableFooter, Button } from "@mui/material";
import { toast } from "react-hot-toast";
import {
  CheckBadgeIcon,
  ExclamationTriangleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import ProConfirmation from "../../../utils/ProConfirmation";
import { generateRandomString } from "../../../Utils";
import PMSwitch from "../../../utils/PMSwitch";
import { UploaderV2 } from "../../Media/Uploaderv2";
import { DocumentViewer } from "../../Media/documentViewer";
import ChecklistSwitch from "./ChecklistSwitch";
import { ChecklistDeviationDialog } from "./checklistDeviation";
import { debounce, set } from "lodash";
import { TWclassNames } from "../../../utils/Div";
import { DocumentUploadSectionV2 } from "../../Media/DocumentUploadSectionV2";
import ReportDocumentsDialog from "../../service/reports/report-documents-dialog";
import DocumentsDialog from "../../documents/documents-dialog";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 20,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgrey" : null,

  // styles we need to apply on draggables
  ...draggableStyle,
});

export default function ChecklistItemLines(props) {
  const {
    setFieldValue,
    values,
    isLoading,
    name,
    drag,
    editable,
    type,
    checklist,
    disabled,
    deviation,
  } = props;

  const rows = values?.items || props?.items;

  // Reordered items by updating their row_order
  const orderItems = (rows) => {
    const array = [];

    if (!rows) return;

    rows.map((row, i) => {
      // Ensure that the id exists before using it

      // Generate a random string (10 characters)
      const random = generateRandomString();

      const rowParams = {
        ...row,
        row_order: i,
        draggableId: random,
      };

      array.push(rowParams);
    });

    return array;
  };

  // Title column is only used when group_row is true
  const defaultRows = [
    {
      id: null,
      row_order: null,
      description: "",
    },
  ];

  const [items, setItems] = useState(orderItems(rows ? rows : defaultRows));

  useEffect(() => {
    if (setFieldValue) {
      setFieldValue(name, items);
    }
  }, [items]);

  const addItemRow = (type) => {
    const newRow = {
      title: "",
      description: "",
      avvik: false,
      row_order: items.length,
      documents: [],
    };
    const updatedItems = [...items, newRow];
    setItems(orderItems(updatedItems));
    // setItems((prevState) => [...prevState, newRow]);
  };

  const onItemRowChange = (e, data) => {
    // setItems(prevState => ({ ...prevState, [1][description]: 'test' }))
    if (typeof e === "function") {
      e.preventDefault();
    }

    let prevItems = [...items];
    let item = {
      ...prevItems[data.row_order],
      [e.target.name]: e.target.value || e.target.checked,
    };
    prevItems[data.row_order] = item;
    setItems(prevItems);
  };

  // Only approved or deviation can be true, not both
  const onApprovedChange = (e, data) => {
    let prevItems = [...items];
    let item = {
      ...prevItems[data.row_order],
      isApproved: e.target.checked,
      isDeviation: false,
    };
    prevItems[data.row_order] = item;
    setItems(prevItems);
  };

  const onDeviationChange = (e, data) => {
    let prevItems = [...items];
    let item = {
      ...prevItems[data.row_order],
      isDeviation: e.target.checked,
      isApproved: false,
    };
    prevItems[data.row_order] = item;
    setItems(prevItems);
  };

  const [createDeviationDialogOpen, setCreateDeviationDialogOpen] =
    useState(false);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(orderItems(newItems));
  };

  const [selectedItemRow, setSelectedItemRow] = useState();

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const handleDeleteRow = () => {
    setDeleteConfirmationOpen(false);
    // Delete selectedItem row from array
    if (selectedItemRow) {
      const newArray = items.filter((item) => item.id !== selectedItemRow);
      setItems(orderItems(newArray));
    }
  };

  const deleteRow = (id) => {
    setSelectedItemRow(id);
    setDeleteConfirmationOpen(true);
    toast(id);
  };

  // const UploaderCallback = (data, row) => {
  //   // Documents handler
  //   let prevItems = [...items];

  //   let item = {
  //     ...prevItems[row.row_order],
  //     documents: [...prevItems[row.row_order].documents, data],
  //   };

  //   prevItems[row.row_order] = item;

  //   setItems(prevItems);
  // };

  const UploaderCallback = (newDocuments, row) => {
    // Documents handler
    let prevItems = [...items];

    let item = {
      ...prevItems[row.row_order],
      documents: [...prevItems[row.row_order].documents, ...newDocuments],
    };

    prevItems[row.row_order] = item;

    setItems(prevItems);
  };

  // const UploaderCallback = (newDocuments, formik) => {
  //   // Add documents to document state
  //   const newArray = documents.concat(newDocuments);

  //   setDocuments(newArray);

  //   // Add documents to formik
  //   formik.setFieldValue("documents", newArray);

  //   setIsLoading(false);
  // };

  const delayedCallback = debounce(props.parentCallback, 300);

  useEffect(() => {
    // props.parentCallback(items);
    delayedCallback(items);
  }, [items]);

  const [selectedChecklistItem, setSelectedChecklistItem] = useState(null);

  const handleDeviation = (row) => {
    if (!deviation) {
      let item = {};
      // find item by id or row_order if id is null
      if (!row.id) {
        item = items.find((item) => item.id === row.id);
      } else {
        item = items.find((item) => item.row_order === row.row_order);
      }
      onDeviationChange({ target: { checked: true } }, item);
    } else {
      setCreateDeviationDialogOpen(true);
      setSelectedChecklistItem(row);
    }
  };

  const handleDeviationCreate = (data, checklistItem) => {
    // Find item by id
    const item = items.find((item) => item.id === checklistItem.id);

    // Data === Deviation
    // Add the deviation to the item and update items
    let prevItems = [...items];

    let newItem = {
      ...prevItems[item.row_order],
      deviation: data,
    };

    prevItems[item.row_order] = newItem;

    setItems(prevItems);

    // Set isDeviation
    onDeviationChange({ target: { checked: true } }, item);
  };

  useEffect(() => {
    if (!createDeviationDialogOpen) {
      setTimeout(() => {
        setSelectedChecklistItem(null);
      }, 250);
    }
  }, [createDeviationDialogOpen]);

  const canUploadImage = (item) => {
    if (type === "checklist") {
      if (deviation) {
        if (item?.isApproved) {
          // Item is approved and deviation is true
          return true;
        } else {
          // Item is not approved and deviation is true
          return false;
        }
      } else {
        // Deviation is not true
        return true;
      }
    } else {
      return false;
    }
  };

  const [documentsDialogOpen, setDocumentsDialogOpen] = useState(false);

  const [selectedChecklistItemDocumentId, setSelectedChecklistItemDocumentId] =
    useState(null);

  // Add a row if not rows available in the items array
  useEffect(() => {
    if (items.length === 0) {
      addItemRow();
    }
  }, []);

  const itemRow = (item, provided, snapshot) => {
    return (
      <>
        <div
          key={"div" + item.id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          // style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          className="grid grid-cols-4 py-2 bg-white shadow-md rounded-md  h-full mb-2"
        >
          {drag && (
            <div>
              <DragHandleIcon />
            </div>
          )}

          <div
            className={TWclassNames(
              type === "checklist"
                ? "col-span-3 row-span-1"
                : "col-span-4 row-span-1"
            )}
          >
            <div className="px-5 py-5">
              {!editable && item?.id ? (
                <span>{item?.title}</span>
              ) : (
                <TextField
                  disabled={false}
                  label="Title"
                  variant="standard"
                  sx={{ mt: "10px" }}
                  value={item?.title}
                  fullWidth
                  name="title"
                  onChange={(e) => onItemRowChange(e, item)}
                />
              )}
              {type === "checklist" && (
                <>
                  <TextField
                    disabled={disabled}
                    label="Beskrivelse"
                    required={item?.isDeviation}
                    variant="standard"
                    sx={{ mt: "5px" }}
                    value={item?.description}
                    fullWidth
                    name="description"
                    multiline
                    rows={2}
                    onChange={(e) => onItemRowChange(e, item)}
                  />
                  {item?.isDeviation && (
                    <span className="text-xs text-red-400 italic">
                      Vennligst fyll i forklaring til avviket.
                    </span>
                  )}
                </>
              )}
            </div>
          </div>

          {canUploadImage(item) && (
            <div
              className={TWclassNames(
                "grid grid-cols-1 sm:grid-cols-4",
                type === "CHECKLIST"
                  ? "col-span-1 sm:col-span-4"
                  : "col-span-4",
                "px-5 py-2 justify-between"
              )}
            >
              {type === "checklist" && (
                <>
                  <div className="">
                    <ChecklistSwitch
                      disabled={disabled}
                      label="Godkjent"
                      name="isApproved"
                      description="Godkjent"
                      bgClass="bg-green-400"
                      borderClass="border-green-400"
                      checked={item?.isApproved}
                      onChange={(e) => {
                        onApprovedChange(e, item);
                      }}
                      icon={CheckBadgeIcon}
                    />
                  </div>
                  <div className="mb-0">
                    <ChecklistSwitch
                      disabled={disabled}
                      label="Avvik"
                      name="isDeviation"
                      bgClass="bg-red-300"
                      borderClass="border-red-300"
                      checked={item?.isDeviation}
                      description="Opprett avvik"
                      // onChange={(e) => onDeviationChange(e, item)}
                      onChange={() => handleDeviation(item)}
                      icon={ExclamationTriangleIcon}
                    />
                  </div>
                </>
              )}

              {!disabled && (
                <DocumentUploadSectionV2
                  key={item.id}
                  disabled={isLoading}
                  module="checklist_item"
                  moduleParam={item?.id}
                  parentCallback={(data) => UploaderCallback(data, item)}
                />
              )}

              <button
                type="button"
                onClick={() => {
                  setSelectedChecklistItemDocumentId(item.id);
                  setDocumentsDialogOpen(true);
                }}
                className="py-4 px-4 text-center items-center border border-transparent bg-blue-500 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Se dokument & bilder
                <span className="ml-2 text-sm italic">
                  ({item?.documents?.length ? item?.documents?.length : 0})
                </span>
              </button>

              {/* <div className="col-span-3 h-full min-h-[100%]">
                <div className="ml-2">
                  <DocumentViewer
                    size="small"
                    pathType="quickview"
                    documents={item?.documents}
                  />
                </div>
              </div> */}
            </div>
          )}

          {props.deleteRow && (
            <div className="absolute right-12">
              <TrashIcon
                className="cursor-pointer h-5 h-5 text-red-400"
                onClick={() => deleteRow(item.id)}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  if (isLoading) {
    return <ResourceLoading />;
  }

  return (
    <React.Fragment>
      <div className="flex flex-col ">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {items?.map((item, index) => (
                  <Draggable
                    isDragDisabled={!drag}
                    key={"drag" + item.id}
                    draggableId={item.draggableId}
                    index={index}
                  >
                    {(provided, snapshot) => itemRow(item, provided, snapshot)}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {props.addRow && !disabled && (
        <Table>
          <TableFooter>
            <div>
              <div colSpan={2} className="mb-2">
                <button
                  disabled={disabled}
                  className={TWclassNames(
                    "disabled:text-gray-500 border-gray-500",
                    "uppercase font-bold text-blue-400 border-b-4 border-blue-400 py-2 "
                  )}
                  type="button"
                  onClick={() => addItemRow()}
                >
                  Ny rad
                </button>
              </div>
            </div>
          </TableFooter>
        </Table>
      )}

      <ProConfirmation
        severity="confirmation"
        open={deleteConfirmationOpen}
        message={"Are you sure you want to delete this row?"}
        action="delete"
        module="order_item"
        // item_id={confirmation.item_id}
        yesCallback={() => handleDeleteRow(selectedItemRow)}
        cancelCallback={() => setDeleteConfirmationOpen(false)}
      />

      <ChecklistDeviationDialog
        open={createDeviationDialogOpen}
        onClose={() => setCreateDeviationDialogOpen(false)}
        checklistItem={selectedChecklistItem}
        checklist={checklist}
        onRefresh={(data, checklistItem) =>
          handleDeviationCreate(data, checklistItem)
        }
      />

      <DocumentsDialog
        open={documentsDialogOpen}
        onClose={() => setDocumentsDialogOpen(false)}
        module="checklist_item"
        moduleParam={selectedChecklistItemDocumentId}
        // selected={report}
        onRefresh={props.onRefresh}
      />
    </React.Fragment>
  );
}

ChecklistItemLines.defaultProps = {
  name: "items",
  drag: true,
  delete: true,
  editable: true,
  type: "checklist",
  disabled: false,
  deviation: true,
};
