import { useState } from "react";
import { toast } from "react-hot-toast";
import { ModuleTag } from "./moduleTag";

export const ProjectFolderTag = ({ folder, label, onRefresh }) => {
  const labelAction = {
    path:
      "/dashboard/projects/" + folder?.project?.id + "/folders/" + folder?.id,
    // action: () => toast("test"),
  };

  return (
    <>
      <ModuleTag label={label} labelAction={labelAction}>
        {folder?.displayTitle}
      </ModuleTag>
    </>
  );
};
