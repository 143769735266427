import { CameraIcon } from "@heroicons/react/20/solid";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ResourceLoading } from "../../../../components/utils/ResourceLoading";
import { ResourceError } from "../../../../components/utils/ResourceError";
import { ResourceEmpty } from "../../../../components/utils/ResourceEmpty";
import { Notes } from "../../../../components/dashboard/notes/notes";
import DashboardHeader from "../../../../components/dashboard/dashboardHeader";
import {
  ArrowPathRoundedSquareIcon,
  AtSymbolIcon,
  BuildingOfficeIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";

import {
  customerHeaderAction,
  dispatchWithToast,
  mapErrors,
} from "../../../../components/Utils";
import { HistoriesModalButton } from "../../../../components/dashboard/histories/modal-button";

import { PMField } from "../../../../components/utils/PMField";
import PMSwitch from "../../../../components/utils/PMSwitch";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ChecklistItemLines from "../../../../components/dashboard/quality/checklists/checklistTypeItemLines";
import {
  createChecklistTypeAsync,
  updateChecklistTypeAsync,
} from "../../../../slices/quality/checklist-type";
import { getChecklistType } from "../../../../api/quality/checklist";
import { FormikSaveBar } from "../../../../components/utils/FormikSaveBar";

export const ChecklistTypeView = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  const { open, onClose } = props;

  const [itemState, setItemState] = useState({
    checklist_type: {},
    error: "",
    errors: [],
    isLoading: true,
  });

  const { checklist_type, isLoading, error, errors } = itemState;

  useEffect(() => {
    const fetchChecklistType = async (id) => {
      const res = await getChecklistType(id);
      if (res?.status === 200) {
        setItemState((prevState) => ({
          ...prevState,
          checklist_type: res?.data?.data,
          isLoading: false,
        }));
      }
    };

    if (id) {
      fetchChecklistType(id);
    } else {
      setItemState((prevState) => ({
        ...prevState,
        checklist_type: {},
        isLoading: false,
      }));
    }
  }, [open]);

  const createOrUpdateDispatch = (data) => {
    if (checklist_type?.id) {
      const res = dispatch(updateChecklistTypeAsync(data));
      return res;
    } else {
      const res = dispatch(createChecklistTypeAsync(data));
      return res;
    }
  };

  const [items, setItems] = useState([]);

  const addItemRow = (type) => {
    const newRow = {
      title: "",
      description: "",
      row_order: items.length,
      // Generating a fake ID for draggable, so that we are able to move the row before saving.
      draggableId: "id" + items.length + 1,
    };
    setItems((prevState) => [...prevState, newRow]);
  };

  const onItemRowChange = (e, data) => {
    // setItems(prevState => ({ ...prevState, [1][description]: 'test' }))
    e.preventDefault();

    let prevItems = [...items];
    let item = {
      ...prevItems[data.row_order],
      [e.target.name]: e.target.value,
    };
    prevItems[data.row_order] = item;
    setItems(prevItems);
  };

  const headerMeta = [
    customerHeaderAction(checklist_type?.customer),
    {
      label: checklist_type?.project?.displayTitle,
      description: "",
      path: "/dashboard/projects/" + checklist_type?.project?.id,
      action: null,
      icon: BuildingOfficeIcon,
      textColor: "text-teal-700",
      hidden: checklist_type?.project?.id ? false : true,
    },
  ];

  const headerActions = [
    {
      component: (
        <HistoriesModalButton
          module={"checklist_type"}
          module_param={checklist_type?.id}
        />
      ),
      label: "History",
      description: "",
      icon: ArrowPathRoundedSquareIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: false,
    },
  ];

  const Content = () => {
    if (isLoading) {
      return <ResourceLoading size="base" />;
    }

    if (error) return <ResourceError errors={errors} />;

    if (!checklist_type?.id) {
      return <ResourceEmpty />;
    }

    return (
      <>
        <Formik
          enableReinitialize
          initialValues={{
            id: checklist_type?.id,
            title: checklist_type?.title,
            description: checklist_type?.description,
            active: checklist_type?.active || true,
            items: checklist_type?.items || [],
            submit: null,
          }}
          validationSchema={Yup.object({
            title: Yup.string().max(255).required(t("title.required")),
            description: Yup.string().max(500),
          })}
          onSubmit={(values, helpers) => {
            try {
              dispatchWithToast(createOrUpdateDispatch(values), (payload) =>
                setItemState((prevState) => ({
                  ...prevState,
                  isLoading: false,
                  checklist_type: payload?.data?.data,
                }))
              );

              helpers.setStatus({ success: true });
              helpers.setSubmitting(false);
            } catch (err) {
              toast.error("Something went wrong...");
              console.error(err);
              helpers.setStatus({ success: false });
              helpers.setErrors({ submit: err.message });
              helpers.setSubmitting(false);
            }
          }}
        >
          {(formik) => (
            <Form>
              <div className="grid grid-cols-6 gap-6 mt-10 bg-white px-5 py-5 rounded-md shadow">
                <div className="col-span-6 sm:col-span-3">
                  <PMField name="title" type="text" label="Title" {...formik} />
                </div>

                <div className="col-span-6 sm:col-span-3 sm:mt-8">
                  <PMSwitch name="active" label="Active" {...formik} />
                </div>

                <div className="col-span-6 sm:col-span-6">
                  <PMField
                    name="description"
                    type="text"
                    label="Description"
                    multiline
                    rows={2}
                    {...formik}
                  />
                </div>
              </div>

              <div className=" bg-white px-5 py-5 rounded-md shadow mt-5">
                <ChecklistItemLines
                  editable={true}
                  drag={true}
                  disabled={false}
                  {...formik}
                />
              </div>

              <FormikSaveBar {...formik} />
            </Form>
          )}
        </Formik>
      </>
    );
  };

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={checklist_type?.displayTitle || "Mangler title"}
        meta={headerMeta}
        mainButton={{
          label: "Edit",
          //   action: () => setChecklistDialogOpen(true),
          path: null,
          color: "",
          icon: PencilIcon,
        }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-1">
          <Content />
        </div>
      </DashboardHeader>
    </>
  );
};
