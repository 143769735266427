import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { logoutUserWithoutToken, setCurrentUser } from "../../slices/session";
import { getCurrentUser } from "../../api/session";
import { useSelector } from "react-redux";
const AuthVerify = (props) => {
  let location = useLocation();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.session.accessToken);
  const loading = useSelector((state) => state.session.loading);
  const [user, setUser] = useState();

  async function checkAccessToken() {
    try {
      const response = await getCurrentUser(accessToken);
      if (!response && !response?.data?.id) {
        dispatch(logoutUserWithoutToken());
      }
    } catch (err) {
      // dispatch(logoutUserWithoutToken());
      console.error("Error", err);
    }
  }

  useEffect(() => {
    if (!loading) {
      checkAccessToken();
    }
  }, [location.pathname]);
};

export default AuthVerify;
