import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ProductsFilter } from "../../../components/dashboard/products/products-filter";
import { ProductsTable } from "../../../components/dashboard/products/products-table";
import { getProductsAsync } from "../../../slices/products";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import {
  ArrowUpIcon,
  CheckBadgeIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import ProductModal from "../../../components/dashboard/products/ProductModal";
import { pageOptions, updateUrlWithException } from "../../../components/Utils";
import { ProductActionEditBar } from "../../../components/dashboard/products/product-action-edit-bar";
import ProductMultiEditDialog from "../../../components/dashboard/products/product-multi-edit-dialog";
import ProductPriceChangeDialog from "../../../components/dashboard/products/product-price-change-dialog";
import { productViewsOptions } from "../../../components/dashboard/products/utils";
import { debounce } from "lodash";

export const ProductsList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const productState = useSelector((store) => {
    return store.products;
  });

  const { isLoading } = productState;
  const [selectedItems, setSelectedItems] = useState([]);
  const [controller, setController] = useState({
    filters: [{ name: "view", value: JSON.stringify(["ACTIVE"]) }],
    page: searchParams.get("page") ? searchParams.get("page") : 1,
    per_page: searchParams.get("per_page") ? searchParams.get("per_page") : 250,
    query: searchParams.get("query") ? searchParams.get("query") : "",
    sort: searchParams.get("sort") ? searchParams.get("sort") : "asc",
    sortBy: searchParams.get("sortBy")
      ? searchParams.get("sortBy")
      : "created_at",
    view: searchParams.get("view") ? searchParams.get("view") : "all",

    fields:
      "id,displayTitle,title,item_number,main_picture,brand,status,warehouse_status_type_id,purchase_price,sales_price",
  });

  const updateSearchParams = (controller) => {
    setSearchParams(controller);
  };

  // Update all params from controller except filters (dont't touch filters)
  useEffect(() => {
    updateUrlWithException(
      // These will be updated
      {
        page: controller.page,
        per_page: controller.per_page,
        query: controller.query,
        sort: controller.sort,
        sortBy: controller.sortBy,
        view: controller.view,
      },
      // This will be preserved
      "filters"
    );
  }, [controller]);

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery,
    });
  };

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handlePagePageChange = (option) => {
    setController({
      ...controller,
      per_page: option,
    });
  };

  const handleFilterChange = (selectedFilters) => {
    setController({
      ...controller,
      filters: selectedFilters?.map((filter) => {
        return {
          name: filter.id,
          value: JSON.stringify(filter.values),
        };
      }),
    });
  };

  const [editModalOpen, setEditModalOpen] = useState(false);

  const [multiEditDialogOpen, setMultiEditDialogOpen] = useState(false);
  const [priceChangeDialogOpen, setPriceChangeDialogOpen] = useState(false);
  const [resetSelected, setResetSelected] = useState(false);

  const debouncedDispatchAction = debounce((controller) => {
    dispatch(getProductsAsync(controller));
  }, 1000);

  const handleResetSelected = () => {
    setResetSelected(true);
    setSelectedItems([]);

    setTimeout(() => {
      setResetSelected(false);
    }, 250);
  };

  useEffect(() => {
    if (multiEditDialogOpen) return;
    handleResetSelected();

    // Trigger the debounced dispatch action
    debouncedDispatchAction(controller);

    // Clean the debounced function on component unmount.
    return () => {
      debouncedDispatchAction.cancel();
    };
  }, [controller, dispatch, multiEditDialogOpen]);

  const headerActions = [];

  const headerMeta = [
    {
      label: productState.count + " Products",
      description: "wdw",
      path: null,
      action: null,
      icon: CheckBadgeIcon,
      textColor: "text-teal-700",
      hidden: false,
    },
    {
      label: productState.newestProductsCount + " New products",
      description: "wdw",
      path: null,
      action: null,
      icon: ArrowUpIcon,
      textColor: "text-teal-700",
      hidden: false,
    },
    {
      label: productState.synchedWithApiCount + " Synched with API",
      description: "wdw",
      path: null,
      action: null,
      icon: ArrowUpIcon,
      textColor: "text-teal-700",
      hidden: false,
    },
  ];

  const sortByOptions = [
    { name: "Seneste (Rapportnr.)", sort: "asc", sortBy: "report_number" },
    { name: "Eldste (Rapportnr.)", sort: "desc", sortBy: "report_number" },
  ];

  const initialFilters = [
    {
      id: "category",
      name: t("Category"),
      optionsUrl: "/products/categories/",
      optionsParams: {},
    },
    {
      id: "view",
      name: "View",
      options: productViewsOptions,
    },
    {
      id: "status",
      name: "Status",
      // options: reportStatusesOptions,
    },
  ];

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={"Products"}
        meta={headerMeta}
        mainButton={{
          label: "New",
          action: () => setEditModalOpen(true),
          path: null,
          color: "",
          icon: PlusIcon,
        }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            {/* <ProductsStats
              totalProductsCount={productState.count}
              newestProductsCount={productState.newestProductsCount}
            /> */}

            <ProductsFilter
              className={"mt-10"}
              disabled={isLoading}
              onQueryChange={handleQueryChange}
              query={controller.query}
              selectedItems={selectedItems}
              sortByOptions={sortByOptions}
              perPageOptions={[10, 25, 50, 100, 250]}
              onPerPageChange={handlePagePageChange}
              pageOptions={pageOptions({
                perPage: controller.per_page,
                totalCount: productState.count,
              })}
              onPageChange={handlePageChange}
              initialFilters={initialFilters}
              onFilterChange={handleFilterChange}
              actionBar={
                <ProductActionEditBar
                  selectedItems={selectedItems}
                  setMultiEditDialogOpen={() => setMultiEditDialogOpen(true)}
                  setPriceChangeDialogOpen={() =>
                    setPriceChangeDialogOpen(true)
                  }
                />
              }
              defaultSelectedOptions={[{ name: "view", value: "ACTIVE" }]}
            />

            <ProductsTable
              onItemsSelect={(items) => setSelectedItems(items)}
              itemState={productState}
              itemsCount={productState.count}
              currentCount={productState.currentCount}
              page={controller.page}
              onPageChange={handlePageChange}
              perPage={controller.per_page}
              resetSelected={resetSelected}
            />
          </div>
        </div>
      </DashboardHeader>

      <ProductModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
      />

      <ProductMultiEditDialog
        selectedItems={selectedItems}
        open={multiEditDialogOpen}
        onClose={() => setMultiEditDialogOpen(false)}
      />

      <ProductPriceChangeDialog
        selectedItems={selectedItems}
        open={priceChangeDialogOpen}
        onClose={() => setPriceChangeDialogOpen(false)}
      />
    </>
  );
};
