import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchDrivingTrips,
  fetchDrivingTrip,
  updateDrivingTrip,
  createDrivingTrip,
  deleteDrivingTrip,
} from "../../api/driving/trip";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  totalCount: 0,
  currentCount: 0,
  synchedWithApiCount: 0,
  newCount: 0,
};

export const initialController = {
  filters: [],
  page: 0,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const deleteDrivingTripAsync = createAsyncThunk(
  "drivingTrips/deleteDrivingTripAsync",
  async (id) => {
    let response = await deleteDrivingTrip(id);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createDrivingTripAsync = createAsyncThunk(
  "drivingTrips/createDrivingTripAsync",
  async (payload) => {
    let response = await createDrivingTrip(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const updateDrivingTripAsync = createAsyncThunk(
  "drivingTrips/updateDrivingTripAsync",
  async (payload) => {
    let response = await updateDrivingTrip(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);
export const getDrivingTripsAsync = createAsyncThunk(
  "drivingTrips/getDrivingTripsAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchDrivingTrips(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

export const getDrivingTripAsync = createAsyncThunk(
  "drivingTrips/getDrivingTripAsync",
  async (id, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchDrivingTrip(id);

    // If errors, reject and return response
    if (response.error) return rejectWithValue(response);

    // If all good, return response.
    return response;
  }
);

const drivingTripSlice = createSlice({
  name: "drivingTrips",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDrivingTripsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDrivingTripsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.currentCount = action.payload.currentCount;
        state.newestDrivingTripsCount = action.payload.newCount;
        state.synchedWithApiCount = action.payload.synchedWithApiCount;
      })
      .addCase(getDrivingTripsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch drivingTrips....is controller missing?",
        ];
      })
      .addCase(updateDrivingTripAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateDrivingTripAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data[index] = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(updateDrivingTripAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update drivingTrip",
        ];
      })
      .addCase(createDrivingTripAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createDrivingTripAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
        });
      })
      .addCase(createDrivingTripAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update drivingTrip",
        ];
      })
      .addCase(deleteDrivingTripAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteDrivingTripAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data.splice(index, 1);
          // draftState.data = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(deleteDrivingTripAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update drivingTrip",
        ];
      })
      .addCase(getDrivingTripAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDrivingTripAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.drivingTrip = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(getDrivingTripAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update product."];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = drivingTripSlice;

export default drivingTripSlice;
