import { Transition } from "@headlessui/react";
import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { TWclassNames } from "./Div";

export const InfoListPopup = (props) => {
  const { t } = useTranslation();
  const { children, info } = props;
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const [popupPosition, setPopupPosition] = useState({ left: 0, top: 0 });
  const containerRef = useRef(null);

  const handleMouseMove = (e) => {
    if (isHovering) {
      // Calculate the position of the popup based on the cursor's position
      const left = e.clientX + 50; // Adjust this value as needed
      const top = e.clientY - 350; // Adjust this value as needed
      setPopupPosition({ left, top });
    }
  };

  // Map over each info (array) - at make a list out of it
  const infoList = info?.map((item, index) => {
    return (
      <li key={index} className="flex flex-col mb-2">
        <span className="font-semibold">{item?.title}</span>
        <span>{item?.value}</span>
      </li>
    );
  });

  return (
    <>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        ref={containerRef}
        // style={{ position: "relative" }}
      >
        <span>{children}</span>
        {info?.length > 0 && (
          <Transition
            show={isHovering}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={TWclassNames(
                "z-10 absolute cursor-pointer shadow-md bg-white rounded-md p-2 border-2 border-blue-400"
              )}
              style={{
                left: `${popupPosition.left}px`,
                top: `${popupPosition.top}px`,
              }}
            >
              <div className="flex w-[300px]">
                <ul>
                  <li className="font-semibold text-lg mb-2">{t("Info")}</li>
                  {infoList}
                </ul>
              </div>
            </div>
          </Transition>
        )}
      </div>
    </>
  );
};
