export const cancelReasons = [
  { name: "cancelReason", value: 0, label: "cancel.reason.not_chosen" },
  { name: "cancelReason", value: 1, label: "cancel.reason.not_suited" },
  { name: "cancelReason", value: 2, label: "cancel.reason.employee_sickness" },
  { name: "cancelReason", value: 3, label: "cancel.reason.customer_sickness" },
  { name: "cancelReason", value: 4, label: "cancel.reason.not_confirmed" },
];

export const confirmationTypes = [
  { name: "manualConfirmationType", value: 0, label: "Confirmed by email" },
  { name: "manualConfirmationType", value: 1, label: "Confirmed by phone" },
  { name: "manualConfirmationType", value: 2, label: "Confirmed other" },
];
