import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createContact,
  destroyContact,
  updateContact,
  fetchContacts,
} from "../../api/customer/contact";
import produce from "immer";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  system: {},
  data: [],
  currentCount: 0,
  totalCount: 0,
  newCount: 0,
};

export const initialController = {
  filters: [],
  page: 0,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const updateContactAsync = createAsyncThunk(
  "contacts/updateContactAsync",
  async (payload) => {
    let response = await updateContact(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createContactAsync = createAsyncThunk(
  "contacts/createContactAsync",
  async (payload) => {
    let response = await createContact(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const getContactsAsync = createAsyncThunk(
  "cases/getContactsAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchContacts(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

const systemSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContactsAsync.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getContactsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.currentCount = action.payload.currentCount;
        state.newCount = action.payload.newCount;
      })

      .addCase(getContactsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch Contacts....is controller missing?",
        ];
      })
      .addCase(updateContactAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateContactAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data[index] = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(updateContactAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update product."];
      })
      .addCase(createContactAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createContactAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
        });
      })
      .addCase(createContactAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update product."];
      });
  },
});

export const { reducer } = systemSlice;

export default systemSlice;
