import axios from "../axios";

export const fetchShippingTypes = async (controller) => {
  return axios({ method: "get", url: "/shipping_types/", params: controller })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const fetchShippingType = async (payload) => {
  return axios({
    method: "get",
    url: `/shipping_types/${payload.id}`,
    params: { ...payload },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const updateShippingType = async (data) => {
  return axios({
    method: "put",
    url: `/shipping_types/${data.id}`,
    data: data,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const createShippingType = async (data) => {
  return axios({
    method: "post",
    url: `/shipping_types/`,
    data: data,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
};
