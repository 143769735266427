import axios from "./axios";

export async function deleteDocument(payload) {
  return axios.delete(`/documents/` + payload);
}

export async function createDocument(payload) {
  return axios
    .post(`/documents/`, { document: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function updateDocument(payload) {
  return axios
    .put(`/documents/` + payload.id, { document: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // return error.response.data
      if (error.status !== 200) return error;
      else return error.response;
    });
}

export async function updateDocuments(payload) {
  return axios
    .put(`/documents/list`, { documents: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.status !== 200) return error;
      else return error.response;
    });
}

export async function fetchDocumentStatus(id) {
  if (!id) return;
  return axios
    .get(`/documents/${id}/status/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchDocument(payload) {
  // payload is only ID
  return axios
    .get(`/documents/` + payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function fetchDocuments(controller) {
  let { filters, sort, sortBy, page, query, view, per_page } = controller;

  const params = {
    query: query,
    page: page,
    per_page: per_page,
    sortBy: sortBy,
    sort: sort,
    filters: filters,
    view: view,
  };

  return axios({ method: "get", url: "/documents/", params: params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}
