import { useEffect, useState } from "react";
import { checkProductNumber } from "../../../api/product";
import { PMDialog } from "../../utils/PMDialog";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { customerPath, productPath } from "../../Utils";
import { Link } from "react-router-dom";
import { PMNotice } from "../../utils/notifications";
import { debounce } from "lodash";
import { checkCustomerNumber } from "../../../api/customer";

export const CustomerNumberCheck = (props) => {
  const { number, customer } = props;

  const [numberTaken, setNumberTaken] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [foundCustomer, setFoundCustomer] = useState(null);

  const checkNumber = () => {
    checkCustomerNumber({
      customer_number: number,
      fields: "id,displayTitle",
    }).then((response) => {
      setFoundCustomer(response?.data?.customer);
    });
  };

  useEffect(() => {
    if (foundCustomer && foundCustomer.id !== customer?.id) {
      setDialogOpen(true);
      setNumberTaken(true);
    } else {
      setDialogOpen(false);
      setNumberTaken(false);
    }
  }, [foundCustomer]);

  const debouncedAction = debounce((controller) => {
    checkNumber();
  }, 1000);

  useEffect(() => {
    if (number) debouncedAction();

    // Clean the debounced function on component unmount.
    return () => {
      debouncedAction.cancel();
    };
  }, [number]);

  useEffect(() => {
    if (numberTaken) setDialogOpen(true);
    return () => {};
  }, [numberTaken]);

  return (
    <>
      {numberTaken && (
        <span className="mt-2 flex italic text-red-400 text-xs">
          <ExclamationCircleIcon className="h-4 w-4 mr-1" />
          Nummer er opptatt!
        </span>
      )}

      <PMDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <div className="mt-2">
          <PMNotice
            type="error"
            title={"Nummer " + number + " er opptatt"}
            description="Velg et annet nummer eller benytt eksisterende kunde."
          />

          <div className="flex justify-between mt-2">
            <button
              type="button"
              onClick={() => setDialogOpen(false)}
              className="px-4 py-2 bg-gray-400 rounded-md text-white"
            >
              Forsett redigering
            </button>
            <Link
              reloadDocument
              to={customerPath({ customer: foundCustomer })}
              className="px-4 py-2 bg-blue-400 rounded-md text-white"
            >
              Gå til kunde
            </Link>
          </div>
        </div>
      </PMDialog>
    </>
  );
};
