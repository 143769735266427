import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchAgreementTypes,
  updateAgreementType,
  createAgreementType,
  deleteAgreementType,
} from "../../api/service/settings";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
};

export const agreementTypesController = {
  filters: [],
  page: 0,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const deleteAgreementTypeAsync = createAsyncThunk(
  "agreement_types/deleteAgreementTypeAsync",
  async (payload) => {
    let response = await deleteAgreementType(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createAgreementTypeAsync = createAsyncThunk(
  "agreement_types/createAgreementTypeAsync",
  async (payload) => {
    let response = await createAgreementType(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const updateAgreementTypeAsync = createAsyncThunk(
  "agreement_types/updateReportTypeAsync",
  async (payload) => {
    let response = await updateAgreementType(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);
export const getAgreementTypesAsync = createAsyncThunk(
  "agreement_types/getAgreementTypesAsync",
  async (controller = agreementTypesController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchAgreementTypes(controller);

    // If errors, reject and return response
    if (response.error) return rejectWithValue(response);

    // If all good, return response.
    return response;
  }
);

const agreementTypeSlice = createSlice({
  name: "agreement_types",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAgreementTypesAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAgreementTypesAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(getAgreementTypesAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch agreement types....is controller missing?",
        ];
      })
      .addCase(updateAgreementTypeAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateAgreementTypeAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data[index] = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(updateAgreementTypeAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update agreement type",
        ];
      })
      .addCase(createAgreementTypeAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createAgreementTypeAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
        });
      })
      .addCase(createAgreementTypeAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update agreement type",
        ];
      })
      .addCase(deleteAgreementTypeAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteAgreementTypeAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(deleteAgreementTypeAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update agreement type",
        ];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = agreementTypeSlice;

export default agreementTypeSlice;

export const selectAgreementTypeById = (state, agreementTypeId) =>
  state.agreement_types.data.find((type) => type.id == agreementTypeId);
