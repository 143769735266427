import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";

import { TableFooter, Button } from "@mui/material";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { PMField } from "../../utils/PMField";
import { TrashIcon } from "@heroicons/react/24/outline";
import ProConfirmation from "../../utils/ProConfirmation";
import { EmployeeSelector } from "../../utils/moduleSelectors/employeeSelector";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 0,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgrey" : null,

  // styles we need to apply on draggables
  ...draggableStyle,
});

export default function ParticipantsSection(props) {
  const { setFieldValue, values, isLoading, drag, project, title, name } =
    props;

  const rows = values[name];

  const columns = [
    { id: "drag", label: "", minWidth: 10, maxWidth: 5, active: drag },
    { id: "user", label: "Bruker", minWidth: 300, active: true },
    {
      id: "admin",
      label: "Admin",
      minWidth: 20,
      align: "left",
      active: true,
    },
    {
      id: "actions",
      label: "",
      minWidth: 50,
      align: "left",
      active: true,
    },
  ];

  const orderItems = (rows) => {
    var array = [];

    if (!rows) return;

    rows.map((row, i) => {
      const rowParams = {
        ...row,
        row_order: i,
        draggableId: "id" + row.id,
      };
      array.push(rowParams);
    });
    return array;
  };

  // Title column is only used when group_row is true
  const defaultRows = [
    {
      id: null,
      activity_id: null,
      row_order: null,
      title: "",
    },
  ];

  const [items, setItems] = useState(orderItems(rows ? rows : defaultRows));

  useEffect(() => {
    setFieldValue(name, items);
  }, [items]);

  const addItemRow = (type) => {
    const newRow = {
      // Generate a random ID for the new row until one is made in the API
      id: Math.floor(Math.random() * 1000000000),
      title: "",
      row_order: items.length,
      // Generating a fake ID for draggable, so that we are able to move the row before saving.
      draggableId: "id" + items.length + 1,
    };
    setItems((prevState) => [...prevState, newRow]);
  };

  const onItemRowChange = (e, data) => {
    if (typeof e === "function") {
      e.preventDefault();
    }

    let prevItems = [...items];
    let item = {
      ...prevItems[data.row_order],
      [e.target.name]: e.target.value,
    };
    prevItems[data.row_order] = item;
    setItems(prevItems);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(orderItems(newItems));
  };

  const [selectedItemRow, setSelectedItemRow] = useState();

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const handleDeleteRow = () => {
    setDeleteConfirmationOpen(false);
    // Delete selectedItem row from array
    if (selectedItemRow) {
      const newArray = items.filter((item) => item.id !== selectedItemRow);
      setItems(orderItems(newArray));
    }
  };

  const deleteRow = (id) => {
    setSelectedItemRow(id);
    setDeleteConfirmationOpen(true);
  };

  const itemRow = (item, provided, snapshot) => {
    return (
      <TableRow
        hover
        key={item.id}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
      >
        {drag ? (
          <TableCell>
            <DragHandleIcon />
          </TableCell>
        ) : null}

        <TableCell align="left">
          <div className="">
            <EmployeeSelector
              selected={item?.user}
              name="user_id"
              label=""
              onChange={(e) => onItemRowChange(e, item)}
            />
          </div>
        </TableCell>

        <TableCell align="left">
          <div className="px-5 pb-2">
            <PMField
              type="checkbox"
              fullWidth
              name="admin"
              checked={item.admin}
              onChange={(e) =>
                onItemRowChange(
                  {
                    target: {
                      name: e.target.name,
                      value: e.target.checked,
                    },
                  },
                  item
                )
              }
            />
          </div>
        </TableCell>

        {props.actions && (
          <TableCell align="right">
            <TrashIcon
              className="cursor-pointer h-5 h-5 text-red-400"
              onClick={() => deleteRow(item.id)}
            />
          </TableCell>
        )}
      </TableRow>
    );
  };

  if (isLoading) {
    return <ResourceLoading />;
  }

  return (
    <React.Fragment>
      <table className="min-w-full w-full">
        <TableHead>
          {/* <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center" colSpan={2}>Product</TableCell>
                        { props.purchase_price ? 
                            <TableCell align="center" colSpan={3}>Innkjøpspris</TableCell>
                        : null }

                        { props.sales_price ? 
                            <TableCell align="center" colSpan={4}>Salgspris</TableCell>
                        : null }
                    </TableRow> */}
          <TableRow>
            {columns.map((column) =>
              column.active ? (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    top: 57,
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                    width: column.width,
                  }}
                >
                  {column.label}
                </TableCell>
              ) : null
            )}
          </TableRow>
        </TableHead>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                {items?.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.draggableId}
                    index={index}
                  >
                    {(provided, snapshot) => itemRow(item, provided, snapshot)}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>

        <TableFooter>
          <TableRow>
            <TableCell align="left" colSpan={2}>
              <Button onClick={() => addItemRow()}>Ny rad</Button>
            </TableCell>

            <TableCell laft="left" colSpan={3}></TableCell>
          </TableRow>
        </TableFooter>
      </table>

      <ProConfirmation
        severity="confirmation"
        open={deleteConfirmationOpen}
        message={"Are you sure you want to delete this row?"}
        action="delete"
        module="order_item"
        // item_id={confirmation.item_id}
        yesCallback={handleDeleteRow}
        cancelCallback={() => setDeleteConfirmationOpen(false)}
      />
    </React.Fragment>
  );
}

ParticipantsSection.defaultProps = {
  budget_hours: false,
  budget_fee_rate: false,
  budget_fee: false,
  number: false,
  invoiceable: false,
  inactive: false,
  actions: true,
  name: "participants",
  drag: true,
  title: true,
  module: null,
};
