import { useTranslation } from "react-i18next";
import { PMSimpleSelectorFieldV2 } from "../PMSimpleSelector/PMSimpleSelectorFieldV2";
import axios from "../../../api/axios";
import { useState, useEffect } from "react";
import { debounce } from "lodash";

export const ReportSelector = (props) => {
  const { setFieldValue, values, onChange, errors, searchQuery, showField } =
    props;

  const name = props?.name ? props.name : "report_id";
  const label = props?.label ? props.label : "Report";
  const FETCH_URL = `/service/reports/`;
  const CREATE_URL = `/service/reports/`;
  const filters = null;

  const { t } = useTranslation();

  const [query, setQuery] = useState();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({
    id: 0,
    field1: "Ingen valgt",
    field2: null,
  });
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);

  const labelAction = {
    // path: selected?.id ? "/dashboard/customers/" + selected?.id : null,
    action: () => {},
  };

  useEffect(() => {
    if (props.open) {
      setOpen(true);
    }
  }, [props.open]);

  const handleChange = (data) => {
    setSelected({
      id: data?.selected?.item?.id,
      field1: data?.selected?.item?.primary,
    });

    setOpen(false);

    if (values) {
      setFieldValue(name, data.selected.item.id);
    }
    if (onChange) {
      onChange({
        target: {
          name: name,
          value: data.selected.item.id,
          data: data?.selected?.item,
        },
      });
    }
  };

  useEffect(() => {
    if (props?.selected?.id) {
      setSelected({
        id: props.selected?.id,
        field1: props.selected?.displayTitle,
      });
    }
  }, [props.selected]);

  const handleSelectFromForm = (data) => {
    setSelected({
      id: data?.id,
      field1: data?.displayTitle,
    });
    if (values) {
      setFieldValue(name, data?.id);
    } else {
      onChange({ target: { name: name, value: data?.id } });
    }
  };

  const [controller, setController] = useState({
    query: query ? query : "",
    page: 1,
    sortBy: "updated_at",
    sort: "asc",
    filters: filters,
    fields: "id,displayTitle,customer,delivery_address",
    view: null,
  });

  useEffect(() => {
    setController((prev) => ({ ...prev, query: query }));
  }, [query]);

  const getItems = (controller) => {
    setLoading(true);
    return axios({ method: "get", url: FETCH_URL, params: controller })
      .then((response) => {
        if (response.data.data) {
          var array = [
            { id: null, value: null, primary: "Ingen valgt", secondary: null },
          ];
          if (!response.data.data) return array;
          response.data.data.map((row, i) => {
            const rowParams = {
              value: row.id,
              id: row.id,
              primary: row?.displayTitle + " - " + row?.customer?.displayTitle,
              secondary: row?.delivery_address?.displayTitle,
              object: row,
            };
            array.push(rowParams);
          });
          setItems(array);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        return error.response.data;
      });
  };

  const debouncedDispatchAction = debounce((controller) => {
    getItems(controller);
  }, 500);

  useEffect(() => {
    // Trigger the debounced dispatch action
    if (open) {
      debouncedDispatchAction(controller);
    }

    // Clean the debounced function on component unmount.
    return () => {
      debouncedDispatchAction.cancel();
    };
  }, [controller, open]);

  // useEffect(() => {
  //   if (values[name]) {
  //     getItem(values[name]);
  //   }
  // }, [items]);

  return (
    <>
      <PMSimpleSelectorFieldV2
        showField={showField}
        errors={errors}
        name={name}
        loading={loading}
        label={t(label)}
        labelAction={labelAction}
        options={items}
        selected={selected}
        onChange={handleChange}
        onQueryChange={(query) => setQuery(query)}
        onClose={() => setOpen(!open)}
        open={open}
        searchQuery={searchQuery}
      />
    </>
  );
};

ReportSelector.defaultProps = {
  showField: true,
};
