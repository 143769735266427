import produce from "immer";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchVariableTypes, updateVariableType, createVariableType, deleteVariableType } from '../../api/service/settings'

const initialState = {
    isLoading: false,
    error: false,
    errors: null,
    data: [],
};

export const variableTypesController = {
    filters: [],
    page: 0,
    query: '',
    sort: 'desc',
    sortBy: 'created_at',
    view: 'all'
}

export const deleteVariableTypeAsync = createAsyncThunk(
    'agreement_types/deleteVariableTypeAsync', 
    async (payload) => {
        
        let response = await deleteVariableType(payload);
        
        // If errors, reject and return response
        if (response.error) return false

        // If all good, return response.
        return response;
    }
)

export const createVariableTypeAsync = createAsyncThunk(
    'agreement_types/createVariableTypeAsync', 
    async (payload) => {
        
        let response = await createVariableType(payload);
        
        // If errors, reject and return response
        if (response.error) return false

        // If all good, return response.
        return response;
    }
)

export const updateVariableTypeAsync = createAsyncThunk(
    'agreement_types/updateVariableTypeAsync', 
    async (payload) => {
        
        let response = await updateVariableType(payload);
        
        // If errors, reject and return response
        if (response.error) return false

        // If all good, return response.
        return response;
    }
)
export const getVariableTypesAsync = createAsyncThunk(
    'agreement_types/getVariableTypesAsync', 
    async (controller = variableTypesController , { rejectWithValue } ) => {

        // if (! controller) return false;
        let response = await fetchVariableTypes(controller);
        
        // If errors, reject and return response
        if (response.error) return rejectWithValue(response);

        // If all good, return response.
        return response;
})



const variableTypeSlice = createSlice({
    name: 'variable_types',
    initialState,
    reducers: {
    },
    extraReducers : (builder) => {
        builder.addCase(getVariableTypesAsync.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getVariableTypesAsync.fulfilled, (state, action ) => {
            state.isLoading = false;
            state.data = action.payload.data;
        })
        .addCase(getVariableTypesAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errors = ['Something went wrong when trying to fetch variable types....is controller missing?']
        })
        .addCase(updateVariableTypeAsync.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(updateVariableTypeAsync.fulfilled, (state, action ) => {
            return produce(state, (draftState) => {
                const index = draftState.data.findIndex(type => type.id === action.payload.id);
                draftState.data[index] = action.payload;
                draftState.isLoading = false;
            })
        })
        .addCase(updateVariableTypeAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errors = ['Something went wrong when trying to update variable type']
        })
        .addCase(createVariableTypeAsync.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(createVariableTypeAsync.fulfilled, (state, action ) => {
            return produce(state, (draftState) => {
                draftState.data.push(action.payload)
                draftState.isLoading = false;
            })
        })
        .addCase(createVariableTypeAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errors = ['Something went wrong when trying to update variable type']
        })
            .addCase(deleteVariableTypeAsync.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(deleteVariableTypeAsync.fulfilled, (state, action ) => {
            return produce(state, (draftState) => {
                draftState.data = action.payload;
                draftState.isLoading = false;
            })
        })
        .addCase(deleteVariableTypeAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errors = ['Something went wrong when trying to update variable type']
        })
    }
})


// export const {} = customerSlice.actions;

export const { reducer } = variableTypeSlice;

export default variableTypeSlice

export const selectVariableTypeById = (state, TypeId) => state.variable_types.data.find(type => type.id == TypeId)
