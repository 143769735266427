import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { PMField } from "../../../utils/PMField";
import { axiosWithToast, dispatchWithToast, mapErrors } from "../../../Utils";
import PMSwitch from "../../../utils/PMSwitch";

import { PMDialog } from "../../../utils/PMDialog";
import {
  createCaseType,
  fetchCaseType,
  getCaseType,
  updateCaseType,
} from "../../../../api/case/type";

export default function CaseTypeDialog(props) {
  const { t } = useTranslation();
  const { open, onClose, item } = props;

  const [itemState, setItemState] = useState({
    case_type: {},
    isLoading: true,
  });

  const { case_type, isLoading } = itemState;

  useEffect(() => {
    const getCaseType = async () => {
      const res = await fetchCaseType({
        id: item?.object?.id,
      });
      if (res.status === 200) {
        console.log(res?.data);
        setItemState((prevState) => ({
          ...prevState,
          case_type: res?.data?.data,
          isLoading: false,
        }));
      }
    };
    console.log(item);
    if (item?.object?.id) {
      getCaseType();
    } else {
      setItemState((prevState) => ({
        ...prevState,
        case_type: {},
        isLoading: false,
      }));
    }
  }, [open]);

  const createOrUpdate = (data) => {
    if (case_type?.id) {
      const res = updateCaseType(data);
      return res;
    } else {
      const res = createCaseType(data);
      return res;
    }
  };

  console.log("type", case_type);

  return (
    <PMDialog open={open} onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={{
          id: case_type?.id,
          title: case_type?.title,
          description: case_type?.description,
          public: case_type?.public || false,
          submit: null,
        }}
        validationSchema={Yup.object({
          title: Yup.string().max(255).required(t("title.required")),
          description: Yup.string().max(500),
        })}
        onSubmit={(values, helpers) => {
          try {
            axiosWithToast(
              createOrUpdate(values),
              (payload) => {
                setItemState((prevState) => ({
                  ...prevState,
                  isLoading: false,
                  case_type: payload,
                })),
                  props.callback(payload);
              },
              onClose(true)
            );

            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
          } catch (err) {
            toast.error("Something went wrong...");
            console.error(err);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <div className="grid grid-cols-9 gap-6 mt-10">
              <div className="col-span-9 sm:col-span-9">
                <PMField name="title" type="text" label="Title" {...formik} />
              </div>

              <div className="col-span-9 sm:col-span-9">
                <PMField
                  name="description"
                  type="text"
                  label="Description"
                  multiline
                  rows={2}
                  {...formik}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 sm:mt-8">
                <PMSwitch name="public" label="Public" {...formik} />
              </div>
            </div>

            <div className="mt-10 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={onClose}
              >
                {t("Close")}{" "}
              </button>

              <button
                type="button"
                className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={formik.submitForm}
              >
                {case_type?.id ? t("Save") : t("Create")}{" "}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </PMDialog>
  );
}
