import { ArchiveBoxIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ArrowPathIcon, DocumentDuplicateIcon, PencilSquareIcon } from "@heroicons/react/24/solid";
import PrecisionManufacturingOutlined from "@mui/icons-material/PrecisionManufacturingOutlined";
import { render } from "@testing-library/react"
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import PMDropdown from "../../utils/PMDropdown";

export const OrdersLayout = (props) => {
  const { id } = useParams()
  const order = useSelector((state) => state.orders.order)

  const { children } = props;
  const { t } = useTranslation();

  const orderDropdownActions = [
    {
      label: 'edit',
      children: [
        { label: 'Duplicate', path: '/path', icon: DocumentDuplicateIcon, action: () => toast.error('This function is not available yet.') }
      ]
    },
    {
      label: 'Sync',
      children: [
        { label: 'Sync with Tripletex', path: '', icon: ArrowPathIcon, action: () => toast.error('This function is not available yet.') }
      ]
    },
    {
      label: 'archive',
      children: [
        { label: 'Archive', path: '/path', icon: ArchiveBoxIcon, action: () => toast.error('This function is not available yet.') }
      ]
    },
    {
      label: 'delete',
      children: [
        { label: 'Delete', path: '/path', icon: TrashIcon, action: () => toast.error('This function is not available yet.') }
      ]
    }
  ]


  const printActions = [
    {
      label: 'print',
      children: [
        { label: 'Følgeseddel', path: '/path', icon: DocumentDuplicateIcon, action: () => toast.error('This function is not available yet.') },
        { label: 'Pakkseddel', path: '/path', icon: DocumentDuplicateIcon, action: () => toast.error('This function is not available yet.') },
        { label: 'Ordrebekreftelse', path: '/path', icon: DocumentDuplicateIcon, action: () => toast.error('This function is not available yet.') },
        { label: 'Shipping Label', path: '/path', icon: DocumentDuplicateIcon, action: () => toast.error('This function is not available yet.') },
      ]
    },
  ]

  return (
    <div className="py-6">

      {/* Page header */}
      <div className="mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:px-8 max-w-8xl px-4 sm:px-6 md:px-8">
        <div className="flex items-center space-x-5">
          <div className="flex-shrink-0">
            <div className="relative">
              <PrecisionManufacturingOutlined className="w-20" />
              <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
            </div>
          </div>
          <div>
            <h1 className="text-2xl font-bold text-gray-900">{t("Order")} {id ? order?.order_number : t('Products')}</h1>
            <p className="text-sm font-medium text-gray-500">
              {id ? order?.customer?.customer_number + " " + order?.customer?.name : ''}
            </p>
          </div>
        </div>

        <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
          <PMDropdown label="Print" options={printActions} />
          <PMDropdown label="Actions" options={orderDropdownActions} />
        </div>

      </div>
      <div className="mx-auto max-w-8xl px-2 sm:px-6 md:px-9 mt-10 bg-gray-100 rounded-md">
        {children}
      </div>

    </div>

  )


}