import axios from "./axios";

export async function fetchHours(controller) {
  return axios({
    method: "get",
    url: "/hours",
    params: controller,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchHour(id) {
  return axios.get(`/hours/` + id);
}

export async function fetchHourSettings() {
  return axios.get(`/hours/settings`);
}

export async function updateHourSettings(payload) {
  return axios
    .put(`/hours/settings/`, { settings: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function createHour(payload) {
  return axios
    .post(`/hours`, { hour: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
export async function updateHour(payload) {
  return axios
    .put(`/hours/${payload.id}`, { hour: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function deleteHour(id) {
  return axios
    .delete(`/hours/${id}`)

    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
