import { useTranslation } from "react-i18next";
import { TWclassNames } from "../../../utils/Div";
import { status } from "../../../Utils";
import { fetchDeviationTypes } from "../../../../api/quality/deviationType";
import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";

export const deviationItemActionOptions = [
  {
    id: "MEASURES_TAKEN",
    value: "MEASURES_TAKEN",
    label: "Measures taken",
    title: "Measures taken",
    class: "bg-yellow-100 text-yellow-800",
    icon: PlusCircleIcon,
    responsibleUserOnly: false,
  },
  {
    id: "MEASURES_REQUIRED",
    value: "MEASURES_REQUIRED",
    label: "Measures required",
    title: "Measures required",
    class: "bg-red-100 text-red-800",
    icon: ExclamationCircleIcon,
    responsibleUserOnly: false,
  },
  {
    id: "MEASURES_APPROVED",
    value: "MEASURES_APPROVED",
    label: "Measures approved",
    title: "Measures approved",
    class: "bg-green-100 text-green-800",
    icon: CheckBadgeIcon,

    responsibleUserOnly: true,
  },
];

export const severityOptions = [
  {
    id: "1",
    value: "1",
    label: "Low",
    title: "Low",
    class: "bg-green-100 text-green-800",
  },
  {
    id: "2",
    value: "2",
    label: "Medium",
    title: "Medium",
    class: "bg-yellow-100 text-yellow-800",
  },
  {
    id: "3",
    value: "3",
    label: "High",
    title: "High",
    class: "bg-orange-100 text-orange-800",
  },
  {
    id: "4",
    value: "4",
    label: "Critical",
    title: "Critical",
    class: "bg-red-100 text-red-800",
  },
];

export const priorityOptions = [
  {
    id: "1",
    value: "1",
    label: "Low",
    title: "Low",
    class: "bg-green-100 text-green-800",
  },
  {
    id: "2",
    value: "2",
    label: "Medium",
    title: "Medium",
    class: "bg-yellow-100 text-yellow-800",
  },
  {
    id: "3",
    value: "3",
    label: "High",
    title: "High",
    class: "bg-orange-100 text-orange-800",
  },
  {
    id: "4",
    value: "4",
    label: "Critical",
    title: "Critical",
    class: "bg-red-100 text-red-800",
  },
];

export const deviationStatusOptions = [
  {
    id: "1",
    value: "1",
    label: "Open",
    title: "Open",
    class: "bg-red-100 text-red-800",
  },
  {
    id: "2",
    value: "2",
    label: "In Progress",
    title: "in Progress",
    class: "bg-yellow-100 text-yellow-800",
  },
  {
    id: "3",
    value: "3",
    label: "Closed",
    title: "Closed",
    class: "bg-green-100 text-green-800",
  },
];

export const deviationConsequenceOptions = [
  {
    id: 1,
    value: 1,
    label: "Economic",
    title: "Economic",
    class: "bg-orange-100 text-orange-800",
  },
  {
    id: 2,
    value: 2,
    label: "Progress",
    title: "Progress",
    class: "bg-yellow-100 text-yellow-800",
  },
  {
    id: 3,
    value: 3,
    label: "Safety",
    title: "Safety",
    class: "bg-red-100 text-red-800",
  },
  {
    id: 4,
    value: 4,
    label: "Environment",
    title: "Environment",
    class: "bg-green-100 text-green-800",
  },
  {
    id: 5,
    value: 5,
    label: "Quality",
    title: "Quality",
    class: "bg-blue-100 text-blue-800",
  },
  {
    id: 6,
    value: 6,
    label: "Authority",
    title: "Authority",
    class: "bg-gray-100 text-gray-800",
  },
  {
    id: 7,
    value: 7,
    label: "Other",
    title: "Other",
    class: "bg-gray-100 text-gray-800",
  },
];

// export const deviationTypeOptions = async () => {
//   const array = [];

//   try {
//     const res = await fetchDeviationTypes();

//     if (res && res.data) {
//       res.data.map((row, i) => {
//         const rowParams = {
//           value: row.id,
//           title: row.name,
//           order: i,
//         };
//         array.push(rowParams);
//       });
//     }
//   } catch (error) {
//     console.error("Error fetching deviation types:", error);
//   }

//   return array;
// };

export async function getDeviationTypeOptions() {
  try {
    const response = await fetchDeviationTypes(); // Replace with your API endpoint
    // if (!response.ok) {
    //   throw new Error("Network response was not ok");
    // }
    const data = await response;

    return data?.data?.data.map((deviation, idx) => ({
      value: deviation?.id, // Assuming the API has an 'id' field for each deviation type
      title: deviation?.displayTitle, // Assuming the API has a 'name' field for each deviation type
      order: idx, // Assuming the API has an 'order' field for each deviation type
    }));
  } catch (error) {
    console.log(error);
    console.error(
      "There was a problem with the fetch operation:",
      error.message
    );
  }
}

export function DeviationSeverity({ severity }) {
  const { t } = useTranslation();

  const index = severity - 1;

  if (!severity || severity == 0) return null;

  return (
    <span
      className={TWclassNames(
        severityOptions[index].class,
        "inline-flex rounded-full px-2 text-xs font-semibold leading-5"
      )}
    >
      {severityOptions[index].label} {t("Severity")}
    </span>
  );
}

export function DeviationPriority({ priority }) {
  const { t } = useTranslation();
  if (!priority) return null;
  const index = priority - 1;
  return (
    <span
      className={TWclassNames(
        priorityOptions[index].class,
        "inline-flex rounded-full px-2 text-xs font-semibold leading-5"
      )}
    >
      {priorityOptions[index].label} {t("Priority")}
    </span>
  );
}

export function DeviationStatus({ deviation }) {
  const { t } = useTranslation();
  if (!deviation) return null;
  const index = deviation?.status - 1;
  return (
    <span
      className={TWclassNames(
        deviation?.status ? deviationStatusOptions[index].class : "bg-gray-200",
        "inline-flex rounded-full px-2 text-xs font-semibold leading-5"
      )}
    >
      {deviation?.status ? (
        <span>{t(deviationStatusOptions[index].label)}</span>
      ) : (
        "None"
      )}
    </span>
  );
}

export function DeviationItemActionStatus({ item }) {
  const { t } = useTranslation();
  if (!item) return null;

  let status = {};

  const findItemActionByValue = (value) => {
    return deviationItemActionOptions.find((item) => item.value === value);
  };

  if (item?.consequence) {
    status = deviationConsequenceOptions[item?.consequence - 1];
  }

  if (item?.measures_taken) {
    status = findItemActionByValue("MEASURES_TAKEN");
  }

  if (item?.measures_required) {
    status = findItemActionByValue("MEASURES_REQUIRED");
  }

  if (item?.measures_approved) {
    status = findItemActionByValue("MEASURES_APPROVED");
  }

  if (!status) return null;

  return (
    <div
      className={TWclassNames(
        status ? status.class : "bg-gray-200",
        "rounded-full px-4 text-xs py-2 font-semibold leading-5 flex"
      )}
    >
      {status?.icon && <status.icon className="h-5 w-5" />}
      <span className="ml-2">
        {status ? <span>{t(status.label)}</span> : "Missing action!"}
      </span>
    </div>
  );
}
