import { useTranslation } from "react-i18next";
import { PMDialog } from "../../../utils/PMDialog";
import { Form, Formik } from "formik";
import { t } from "i18next";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { PMField } from "../../../utils/PMField";
import PMCombobox from "../../../utils/PMCombobox";
import { deviationConsequenceOptions } from "./utils";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { createDeviationItem } from "../../../../api/quality/deviation";
import { mapErrors } from "../../../Utils";

export const DeviationConsequenceForm = (props) => {
  const { onClose, deviation } = props;

  const createItem = async (values) => {
    return await createDeviationItem(values);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: null,
        deviation_id: deviation?.id,
        description: "",
        consequence: "",
        submit: null,
      }}
      validationSchema={Yup.object({
        consequence: Yup.string().required(t("Consequence is required")),
        description: Yup.string().required(t("Description is required")),
      })}
      onSubmit={(values, helpers) => {
        try {
          toast.promise(createItem(values), {
            loading: t("Saving...."),
            success: (data) => {
              if (data?.status === 500) throw new Error("500 Server error");
              if (data?.status === 400) throw new Error("400 Server error");
              if (data?.status === 401)
                throw new Error("You are not authorized!");
              if (data?.data?.errors)
                throw new Error(mapErrors(data?.data?.errors));
              props.onChange(data?.data);
              if (data?.data?.id) {
                props.onClose();
              }
              return t("Data was saved!");
            },
            error: (error) => {
              console.error(error);
              return t(
                "Something went wrong. Could not save data!" + " " + error
              );
            },
          });
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
        } catch (err) {
          toast.error("Something went wrong...");
          console.error(err);
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <Form>
          <div className="grid grid-cols-6 gap-6 ">
            <div className="col-span-6 sm:col-span-6">
              <PMCombobox
                name="consequence"
                label={t("Consequence")}
                options={deviationConsequenceOptions}
                {...formik}
              />
            </div>
            <div className="col-span-6 sm:col-span-6">
              <textarea
                wrap="off"
                rows={5}
                name="description"
                id="description"
                className="block w-full pt-2.5 resize-none border-2 rounded-xl border-blue-100 px-5 py-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="Write a description..."
                value={formik.values.description}
                onChange={(e) =>
                  formik.setFieldValue("description", e.target.value)
                }
                defaultValue={""}
              />
              {formik?.touched["description"] &&
                formik?.errors["description"] && (
                  <div className="flex mt-1 text-red-700 text-xs">
                    <ExclamationCircleIcon className="w-4 text-xs" />
                    <span className="ml-2">
                      {formik?.errors["description"]}
                    </span>
                  </div>
                )}
            </div>
          </div>

          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
              type="button"
              className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
              onClick={onClose}
            >
              {t("Close")}{" "}
            </button>

            <button
              type="button"
              className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
              onClick={() => formik.submitForm()}
            >
              {t("Add")}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export const DeviationConsequenceDialog = (props) => {
  const { open, onClose, isLoading, deviation } = props;

  const { t } = useTranslation();

  return (
    <PMDialog
      open={open}
      onClose={onClose}
      title={t("Add Consequence")}
      isLoading={isLoading}
    >
      <DeviationConsequenceForm
        onClose={onClose}
        deviation={deviation}
        {...props}
      />
    </PMDialog>
  );
};
