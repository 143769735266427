import { Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import { TWclassNames } from "./Div";

export default function ProConfirmation(props) {
  const { message, action, open, severity, module, item_id, data } = props;

  const { t } = useTranslation();

  const handleClose = () => {
    props.cancelCallback(false);
  };

  const handleYes = () => {
    props.yesCallback({
      data: {
        data: data,
        item_id: item_id,
        action: "delete",
      },
    });
  };

  const actionLabel = {
    "delete": t("delete"),
  };

  const cancelButtonRef = useRef(null);

  const icons = {
    "confirmation": (
      <ExclamationTriangleIcon
        className="h-6 w-6 text-red-500"
        aria-hidden="true"
      />
    ),
    "success": (
      <CheckCircleIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
    ),
    "error": <XCircleIcon className="h-6 w-6 text-red-500" />,
  };

  const severityBgColor = {
    "confirmation": "bg-red-50",
    "success": "bg-green-50",
    "error": "bg-red-50",
  };

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={handleClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div
                        className={TWclassNames(
                          severityBgColor[severity],
                          "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10"
                        )}
                      >
                        {icons[severity]}
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          {severity === "confirmation"
                            ? `${t("confirmation.sure")} ${
                                actionLabel[action]
                              }?`
                            : `Info`}
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            {message}
                            <br />
                            {/* Gjelder: {module}: {item_id} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {severity === "confirmation" ? (
                      <button
                        type="button"
                        className="cursor-pointer mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset hover:bg-red-500 ring-gray-300  sm:mt-0 sm:w-auto"
                        onClick={handleYes}
                        ref={cancelButtonRef}
                      >
                        Yes
                      </button>
                    ) : (
                      ""
                    )}

                    <button
                      type="button"
                      className="cursor-pointer inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50 sm:ml-3 sm:w-auto"
                      onClick={handleClose}
                    >
                      {severity === "confirmation" ? "Cancel" : "OK"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
