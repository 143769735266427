import { Form, Formik } from "formik";
import { PMDialog } from "../../../utils/PMDialog";
import { dispatchWithToast } from "../../../Utils";
import { updateReportAsync } from "../../../../slices/service/reports";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { EmployeeSelector } from "../../../utils/moduleSelectors/employeeSelector";
import { useDispatch } from "react-redux";

export const ReportExecutiveForm = ({ report, onRefresh, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        id: report?.id,
        executing_employee: report?.executing_employee?.id,
        submit: null,
      }}
      onSubmit={(values, helpers) => {
        try {
          dispatchWithToast(
            dispatch(updateReportAsync(values)),
            onRefresh,
            onClose
          );

          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
        } catch (err) {
          toast.error("Something went wrong...");
          console.error(err);
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <Form>
          <div className="grid grid-cols-6 gap-6 mt-10">
            <div className="col-span-6 sm:col-span-6">
              <EmployeeSelector
                name="executing_employee"
                label="Utførende"
                selected={report?.executing_employee}
                {...formik}
              />
            </div>
          </div>
          <div className="flex justify-between mt-10 mb-10">
            <div className="w-full col-span-2">
              <button
                type="button"
                className="justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={onClose}
              >
                Steng
              </button>
            </div>

            <div className="">
              <button
                type="button"
                className="disabled:bg-gray-200 right-0 rounded-md border border-transparent bg-yellow-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={() => formik.submitForm()}
              >
                Update
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export const ReportExecutiveDialog = (props) => {
  const { open, onClose, report, onRefresh } = props;

  return (
    <PMDialog open={open} onClose={onClose} title="Utførende">
      <ReportExecutiveForm
        report={report}
        onClose={onClose}
        onRefresh={onRefresh}
      />
    </PMDialog>
  );
};
