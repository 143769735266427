import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchProducts,
  updateProduct,
  createProduct,
  deleteProduct,
  fetchProduct,
  listUpdateProduct,
} from "../api/product";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  product: {},
  count: 0,
  currentCount: 0,
  newestProductsCount: 0,
  synchedWithApiCount: 0,
};

export const initialController = {
  filters: [],
  page: 1,
  per_page: 200,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const deleteProductAsync = createAsyncThunk(
  "products/deleteProductAsync",
  async (payload) => {
    let response = await deleteProduct(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createProductAsync = createAsyncThunk(
  "products/createProductAsync",
  async (payload) => {
    let response = await createProduct(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const updateProductAsync = createAsyncThunk(
  "products/updateProductAsync",
  async (payload, { rejectWithValue }) => {
    let response = await updateProduct(payload);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/)) return rejectWithValue;

    // If all good, return response.
    return response;
  }
);

export const listUpdateProductAsync = createAsyncThunk(
  "products/listUpdateProductAsync",
  async (payload, { rejectWithValue }) => {
    let response = await listUpdateProduct(payload);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/)) return rejectWithValue;

    // If all good, return response.
    return response;
  }
);

export const getProductsAsync = createAsyncThunk(
  "products/getProductsAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchProducts(controller);

    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

export const getProductAsync = createAsyncThunk(
  "products/getProductAsync",
  async (id, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchProduct(id);

    // If errors, reject and return response
    // if (response.error) return rejectWithValue(response);
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProductsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.count = action.payload.count;
        state.currentCount = action.payload.currentCount;
        state.newestProductsCount = action.payload.newestProductsCount;
        state.synchedWithApiCount = action.payload.synchedWithApiCount;
      })
      .addCase(getProductsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch products....is controller missing?",
        ];
      })
      .addCase(updateProductAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateProductAsync.fulfilled, (state, action) => {
        state.product = action.payload;
        state.isLoading = false;
        // return produce(state, (draftState) => {
        //     // const index = draftState.data.findIndex(type => type.id === action.payload.id);
        //     draftState.product = action.payload
        //     draftState.isLoading = false;
        // })
      })

      .addCase(listUpdateProductAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(listUpdateProductAsync.fulfilled, (state, action) => {
        state.product = action.payload;
        state.isLoading = false;
        // return produce(state, (draftState) => {
        //     // const index = draftState.data.findIndex(type => type.id === action.payload.id);
        //     draftState.product = action.payload
        //     draftState.isLoading = false;
        // })
      })

      .addCase(listUpdateProductAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update product."];
      })
      .addCase(createProductAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createProductAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
        });
      })
      .addCase(createProductAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update product."];
      })
      .addCase(deleteProductAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteProductAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data = action.payload.data;
          draftState.isLoading = false;
        });
      })
      .addCase(deleteProductAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update product."];
      })
      .addCase(getProductAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProductAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.product = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(getProductAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update product."];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = productSlice;

export default productSlice;

export const selectProductById = (state, productId) =>
  state.products.data.find((product) => product.id == productId);
