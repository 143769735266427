import { useTranslation } from "react-i18next";
import { PMDialog } from "../../../utils/PMDialog";
import { Form, Formik } from "formik";
import { t } from "i18next";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { PMField } from "../../../utils/PMField";
import PMCombobox from "../../../utils/PMCombobox";
import { deviationConsequenceOptions } from "./utils";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { createDeviationItem } from "../../../../api/quality/deviation";
import { dispatchWithToast, mapErrors } from "../../../Utils";
import { fetchUsers } from "../../../../api/user";
import { useEffect, useState } from "react";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { updateDeviationAsync } from "../../../../slices/quality/deviation";
import { useDispatch } from "react-redux";

export const DeviationAssignUserForm = (props) => {
  const { onClose, deviation, onRefresh } = props;

  const dispatch = useDispatch();

  const [userOptions, setUserOptions] = useState({
    isLoading: true,
    error: false,
    errors: [],
    data: [],
  });

  async function fetchAndMapUsers() {
    setUserOptions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const controller = {
      filters: [{ name: "role", value: "deviations" }],
      page: 1,
      query: "",
      sort: "desc",
      sortBy: "id",
      view: "all",
    };

    await fetchUsers(controller)
      .then((res) => {
        if (res.data) {
          var array = [];
          if (!res.data) return array;
          res.data.map((row, i) => {
            const rowParams = {
              value: row.id,
              title: row.name,
              order: i,
            };
            array.push(rowParams);
          });
          setUserOptions((prevState) => ({
            ...prevState,
            isLoading: false,
            data: array,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        setUserOptions((prevState) => ({
          ...prevState,
          isLoading: false,
          error: true,
          errors: errors,
        }));
      });

    // Map into an array with ID and title?

    // Return the array
  }

  useEffect(() => {
    fetchAndMapUsers();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: deviation?.id,
        deviation_id: deviation?.id,
        assigned_user_id: deviation?.assigned_user?.id,
        submit: null,
      }}
      validationSchema={Yup.object({
        id: Yup.string().required(() =>
          toast.error(t("Deviation ID is required"))
        ),
        assigned_user_id: Yup.string().required(t("User is required")),
        deviation_id: Yup.string().required(() =>
          toast.error(t("Deviation ID is required"))
        ),
      })}
      onSubmit={(values, helpers) => {
        try {
          dispatchWithToast(
            dispatch(updateDeviationAsync(values)),
            onRefresh,
            onClose
          );

          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
        } catch (err) {
          toast.error("Something went wrong...");
          console.error(err);
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <Form>
          <div className="grid grid-cols-6 gap-6 ">
            <div className="col-span-6 sm:col-span-6">
              <PMCombobox
                name="assigned_user_id"
                label={t("Assigned User")}
                options={userOptions.data}
                {...formik}
              />
            </div>
            <div className="col-span-6 sm:col-span-6"></div>
            <div className="col-span-6 sm:col-span-6"></div>
            <div className="col-span-6 sm:col-span-6"></div>
            <div className="col-span-6 sm:col-span-6"></div>
            <div className="col-span-6 sm:col-span-6"></div>
            <div className="col-span-6 sm:col-span-6"></div>
            <div className="col-span-6 sm:col-span-6"></div>
          </div>

          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
              type="button"
              className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
              onClick={onClose}
            >
              {t("Close")}{" "}
            </button>

            <button
              type="button"
              className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
              onClick={() => formik.submitForm()}
            >
              {t("Assign")}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export const DeviationAssignUserDialog = (props) => {
  const { open, onClose, isLoading, deviation } = props;

  const { t } = useTranslation();

  return (
    <PMDialog
      open={open}
      onClose={onClose}
      title={t("Assign User")}
      isLoading={isLoading}
    >
      <div className="min-h-[300px]">
        <DeviationAssignUserForm
          onClose={onClose}
          deviation={deviation}
          {...props}
        />
      </div>
    </PMDialog>
  );
};
