import { defaultProps } from "react-quill";
import { Popup, TWclassNames } from "./Div";
import { style } from "motion";
import { Link } from "react-router-dom";

export const PMButton = (props: any) => {
  const { children, onClick, type, className, tooltip, styleType, to, target } =
    props;

  function styleTypeClass() {
    switch (styleType) {
      case "primary":
        return "bg-blue-400 hover:bg-indigo-700 rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2";
      case "minimal":
        return "bg-transparent hover:text-blue-400 text-xs";
    }
  }

  if (type === "button") {
    return (
      <Popup content={tooltip} textSize="md">
        <button
          onClick={onClick}
          type="button"
          className={TWclassNames(
            "flex w-full justify-center cursor-pointer",
            className,
            styleTypeClass()
          )}
        >
          {children}
        </button>
      </Popup>
    );
  } else if (type === "submit") {
    return (
      <Popup content={tooltip} textSize="md">
        <button
          // type="button"
          className={TWclassNames(
            "flex w-full justify-center  cursor-pointer",
            className,
            styleTypeClass()
          )}
        >
          {children}
        </button>
      </Popup>
    );
  } else if (to) {
    return (
      <Popup content={tooltip} textSize="md">
        <Link
          target={target}
          to={to}
          className={TWclassNames(
            "flex w-full justify-center  cursor-pointer",
            styleTypeClass(),
            className
          )}
        >
          {children}
        </Link>
      </Popup>
    );
  } else {
    return (
      <Popup content={tooltip} textSize="md">
        <div
          onClick={onClick}
          className={TWclassNames(
            styleTypeClass(),
            "flex w-full justify-center cursor-pointer",
            className
          )}
        >
          {children}
        </div>
      </Popup>
    );
  }
};

PMButton.defaultProps = {
  styleType: "primary",
};
