import { useEffect, useState } from "react"
import { Transition } from '@headlessui/react'
import { Fragment } from "react"
import { useTranslation } from "react-i18next"

export const FormikSaveBar = (props) => { // const formik = useFormikContext()
    const [open, setOpen] = useState(false)
    const { submitForm, touched, values } = props
    const { t } = useTranslation()

    useEffect(() => {
        if (touched !== {}) {
            setOpen(true)
        }
    })

    const handleSaveForm = () => {
        submitForm
    }

    return (
        <Transition.Root show={open}
            as={Fragment}>
            <Transition.Child as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-x-0 bottom-0 pb-2 sm:pb-5 z-10 drop-shadow-xl">
                    <div className="mx-auto max-w-5xl px-2 sm:px-6 lg:px-8">
                        <div className="rounded-lg bg-white p-2 shadow-lg sm:p-3">
                            <div className="flex flex-wrap items-center justify-between">
                                <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
                                    <button onClick={
                                        () => handleSaveForm()
                                    }
                                        className="flex items-center justify-center rounded-md border border-transparent bg-emerald-500 px-10 py-2 text-sm font-medium text-black-600 shadow-sm hover:bg-indigo-50"
                                        type="submit">
                                        {
                                            values.id ? t("Update") : t("Create")
                                        } </button>
                                </div>
                                <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                                    <button type="button" className="-mr-1 flex rounded-md p-2 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-white">
                                        <span className="sr-only">Dismiss</span>
                                        {/* <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" /> */} </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition.Child>
        </Transition.Root>
    )
}

