import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { ResourceLoading } from "../../../components/utils/ResourceLoading";
import { useDispatch, useSelector } from "react-redux";
import { getProductAsync } from "../../../slices/products";
import { ResourceError } from "../../../components/utils/ResourceError";
import { Grid } from "@mui/material";
import { ProductInfo } from "../../../components/dashboard/products/product-info";
import { ProductStock } from "../../../components/dashboard/products/product-stock";
import { ProductSettings } from "../../../components/dashboard/products/product-settings";
import { ProductMainImage } from "../../../components/dashboard/products/product-main-image";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import {
  ProductLastUpdatedStatus,
  ProductWarehouseStatus,
} from "../../../components/dashboard/products/utils";
import { ProductPriceHistorySection } from "../../../components/dashboard/products/product-price-history";
import { Notes } from "../../../components/dashboard/notes/notes";
import { HistoriesModalButton } from "../../../components/dashboard/histories/modal-button";
import { ProductsForButton } from "../../../components/dashboard/products/product-stacklist-viewer";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { PMTabs } from "../../../components/utils/PMTabs";
import { ProductDocuments } from "./documents";
import { ProductImages } from "./images";

export const Product = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const itemState = {
    ...useSelector((store) => {
      return store.products;
    }),
  };
  const { isLoading, error, errors, product } = itemState;

  const item = product;

  useEffect(() => {
    dispatch(getProductAsync(id));
  }, [dispatch]);

  const headerMeta = [
    {
      component: <ProductWarehouseStatus report={item} />,
      label: "Warehouse Status",
      // description: "",
      // path: null,
      // action: null,
      // icon: null,
      // textColor: "text-teal-700",
      hidden: false,
    },
    {
      component: <ProductLastUpdatedStatus product={item} />,
      label: "Last updated",
      // description: "",
      // path: null,
      // action: null,
      // icon: null,
      // textColor: "text-teal-700",
      hidden: false,
    },
  ];

  const headerActions = [
    {
      component: (
        <HistoriesModalButton module={"product"} module_param={product?.id} />
      ),
      label: "History",
      description: "",
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: false,
    },
    {
      component: (
        <div className="px-2 mt-2">
          <Notes module={"product"} module_param={product?.id} />
        </div>
      ),
      label: "Status",
      description: "",
      path: null,
      action: null,
      textColor: "text-teal-700",
      bgColor: "bg-red-200",
      hidden: false,
      menu: false,
    },
  ];

  const renderContent = () => {
    if (isLoading) {
      return <ResourceLoading />;
    }
    if (error && errors) {
      return <ResourceError errors={errors} />;
    }

    return (
      <>
        <Grid container spacing={3}>
          <Grid
            container
            item
            lg={8}
            spacing={3}
            sx={{ height: "fit-content", order: { md: 2, xs: 1 } }}
          >
            <Grid item xs={12}>
              <div className="flex justify-between gap-2">
                {item?.accessory_for?.length > 0 && (
                  <ProductsForButton
                    label={"Tilbehør"}
                    products={item?.accessory_for}
                  />
                )}

                {item?.spare_part_for?.length > 0 && (
                  <ProductsForButton
                    label={"Reservedel"}
                    products={item?.spare_part_for}
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <ProductInfo product={item} />
            </Grid>

            <Grid item xs={12}>
              <ProductPriceHistorySection product={item} />
            </Grid>

            <Grid item xs={12}>
              <ProductStock product={item} />
            </Grid>
          </Grid>

          <Grid
            container
            item
            lg={4}
            spacing={3}
            sx={{ height: "fit-content", order: { md: 2, xs: 1 } }}
          >
            <Grid item xs={12}>
              {item?.webshop_url && (
                <>
                  <Link
                    to={item?.webshop_url}
                    className="w-full flex bg-blue-300 shadow px-5 py-5 rounded-xl cursor-pointer hover:bg-blue-400"
                  >
                    <ArrowRightCircleIcon className="h-10 w-10" />
                    <span className="ml-2 mt-2">Se produkt i nettbutikk</span>
                  </Link>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <ProductMainImage product={item} />
            </Grid>
            <Grid item xs={12}>
              <ProductSettings product={item} />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState(
    parseInt(searchParams.get("tab")) || 0
  );

  const tabs = [
    {
      name: "Detaljer",
      component: renderContent(),
    },
    {
      name: "Dokumenter",
      component: <ProductDocuments product={product} />,
    },
    {
      name: "Bilder",
      component: <ProductImages product={product} />,
    },
  ];

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={product?.id ? product.displayTitle : "New Product"}
        meta={headerMeta}

        // mainButton={{
        //   label: "New",
        //   action: null,
        //   path: null,
        //   color: "",
        //   icon: PlusIcon,
        // }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-4 lg:col-start-1 sm:col-span-4">
            <PMTabs
              tabs={tabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            {tabs[selectedTab].component}
          </div>
        </div>
      </DashboardHeader>
    </>
  );
};
