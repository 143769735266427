import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ResourceLoading } from "../../../components/utils/ResourceLoading";
import { useDispatch } from "react-redux";
import { ResourceError } from "../../../components/utils/ResourceError";
import { Grid } from "@mui/material";
import PMLinkCard from "../../../components/utils/PMLinkCard";
import {
  BuildingOffice2Icon,
  Cog6ToothIcon,
  UsersIcon,
  PaperClipIcon,
  PlusIcon,
  TrashIcon,
  CheckBadgeIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import { ResourceEmpty } from "../../../components/utils/ResourceEmpty";
import { axiosWithToast, mapErrors } from "../../../components/Utils";
import CaseDialog from "../../../components/dashboard/cases/case-dialog";
import { CasesStackList } from "../../../components/dashboard/cases/cases-stack-list";
import ServiceReportEditModal from "../../../components/dashboard/service/reports/service-report-edit-modal";
import { ReportsStackList } from "../../../components/dashboard/service/reports/reports-stack-list";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import { ServiceAgreementsStackList } from "../../../components/dashboard/service/agreements/agreements-stack-list";
import { ProjectsStackList } from "../../../components/dashboard/projects/projects-stack-list";
import ServiceAgreementModal from "../../../components/dashboard/service/agreements/modal";
import ProjectDialog from "../../../components/dashboard/projects/dialog";
import { OrdersStacklist } from "../../../components/dashboard/orders/orders-stack-list";
import SimplePrompt from "../../../components/utils/simplePrompt";
import { destroyContact, fetchContact } from "../../../api/customer/contact";
import { ContactInfo } from "../../../components/dashboard/contacts/ContactInfo";

export const ContactPage = (props) => {
  const rightSideActions = [
    {
      label: "New Case",
      description: "Create a new case",
      path: "",
      action: () => setCaseDialogOpen(true),
      icon: PaperClipIcon,
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
    },
    {
      label: "Service Report",
      description: "Create a service report",
      path: "",
      action: () => setReportDialogOpen(true),
      icon: Cog6ToothIcon,
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
    },
    {
      label: "Service Agreement",
      description: "Create a service agreement",
      path: "",
      action: () => setServiceAgreementDialogOpen(true),
      icon: UsersIcon,
      iconForeground: "text-purple-700",
      iconBackground: "bg-purple-50",
    },
    {
      label: "Project",
      description: "Create a project",
      path: "",
      action: () => setProjectDialogOpen(true),
      icon: BuildingOffice2Icon,
      iconForeground: "text-sky-700",
      iconBackground: "bg-sky-50",
    },
  ];

  const [caseDialogOpen, setCaseDialogOpen] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    contact: {},
  });

  // Deconstruct itemState (Store)
  const { isLoading, error, errors, contact } = itemState;
  const [projectDialogOpen, setProjectDialogOpen] = useState(false);

  const item = contact;

  useEffect(() => {
    getContact();
  }, [dispatch, id]);

  const getContact = async () => {
    return fetchContact(id)
      .then((res) => {
        if (res.data.id) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            contact: res.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err.data.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          contact: {},
          error: true,
          errors: errors,
        }));
      });
  };

  const handleRefresh = (data) => {
    setItemState((prevState) => ({
      ...prevState,
      contact: data,
      isLoading: false,
    }));
  };

  const handleLoading = () => {
    setItemState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
  };

  const [selectedTab, setSelectedTab] = useState(0);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [serviceAgreementDialogOpen, setServiceAgreementDialogOpen] =
    useState(false);

  const tabs = [
    {
      name: "Detaljer",
      component: (
        <ContactInfo
          item={item}
          onRefresh={(data) => handleRefresh(data)}
          onLoading={() => handleLoading()}
        />
      ),
    },
    {
      name: "Cases",
      component: (
        <CasesStackList
          module_param={item.id}
          module="contact"
          filters={[
            {
              name: "contact",
              value: item.id,
            },
          ]}
        />
      ),
    },
    {
      name: "Ordrer",
      component: (
        <OrdersStacklist
          module_param={item.id}
          module="contact"
          filters={[
            {
              name: "contact",
              value: item.id,
            },
          ]}
        />
      ),
    },
    {
      name: "Reports",
      component: (
        <ReportsStackList
          module_param={item.id}
          module="contact"
          filters={[
            {
              name: "contact",
              value: item.id,
            },
          ]}
        />
      ),
    },
    {
      name: "Agreements",
      component: (
        <ServiceAgreementsStackList
          module_param={item.id}
          module="contact"
          filters={[
            {
              name: "contact",
              value: item.id,
            },
          ]}
        />
      ),
    },
    {
      name: "Projects",
      href: "#",
      component: (
        <ProjectsStackList
          module_param={item.id}
          module="contact"
          filters={[
            {
              name: "contact",
              value: item.id,
            },
          ]}
        />
      ),
    },
  ];

  const [deletePromptOpen, setDeletePromptOpen] = useState(false);

  const navigate = useNavigate();

  const removeCustomer = async (contact) => {
    axiosWithToast(
      destroyContact(contact),
      () => {},
      () => navigate("/dashboard/contacts")
    );
  };

  const headerActions = [
    {
      component: null,
      label: "Slett",
      description: "",
      path: null,
      action: () => setDeletePromptOpen(true),
      icon: TrashIcon,
      textColor: "text-red-700",
      bgColor: "bg-white",
      hidden: false,
      menu: true,
    },
  ];

  const headerMeta = [
    {
      label: contact?.syncStatus ? "Synkronisert" : "Ikke synkronisert",
      description: "",
      path: null,
      action: null,
      icon: contact?.syncStatus ? CheckBadgeIcon : ExclamationCircleIcon,
      textColor: contact?.syncStatus ? "text-teal-700" : "text-red-700",
      hidden: false,
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function contactTabs() {
    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            onChange={(e) => setSelectedTab(e.target.value)}
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={tabs[selectedTab].name}
          >
            {tabs.map((tab, tabIdx) => (
              <option key={tab.name} value={tabIdx}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav
            className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
            aria-label="Tabs"
          >
            {tabs.map((tab, tabIdx) => (
              <a
                key={tab.name}
                onClick={() => setSelectedTab(tabIdx)}
                className={classNames(
                  tabIdx === selectedTab
                    ? "text-gray-900"
                    : "text-gray-500 hover:text-gray-700",
                  tabIdx === 0 ? "rounded-l-lg" : "",
                  tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                  "cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                )}
                aria-current={tabIdx === selectedTab ? "page" : undefined}
              >
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    tabIdx === selectedTab ? "bg-indigo-500" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </a>
            ))}
          </nav>
        </div>
      </div>
    );
  }

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="">
          <ResourceLoading />
        </div>
      );
    }
    if (error && errors) {
      return <ResourceError errors={errors} />;
    }

    if (!item?.id) {
      return <ResourceEmpty />;
    }

    return (
      <>
        <DashboardHeader
          subtitle=""
          actions={headerActions}
          title={contact?.displayTitle}
          meta={headerMeta}
          mainButton={{
            label: "New",
            action: () => setCaseDialogOpen(true),
            path: null,
            color: "",
            icon: PlusIcon,
          }}
        >
          <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
              <Grid container spacing={3}>
                <Grid
                  container
                  item
                  lg={8}
                  spacing={3}
                  sx={{ height: "fit-content", order: { md: 2, xs: 1 } }}
                >
                  <Grid item xs={12}>
                    {contactTabs()}
                    <div className="py-5">{tabs[selectedTab].component}</div>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  lg={4}
                  spacing={3}
                  sx={{ height: "fit-content", order: { md: 2, xs: 1 } }}
                >
                  <Grid item xs={12}>
                    <PMLinkCard actions={rightSideActions} />
                  </Grid>
                </Grid>
              </Grid>

              <ServiceReportEditModal
                contact={item}
                // fields={caseItemFields}
                open={reportDialogOpen}
                onClose={() => setReportDialogOpen(false)}
                selected={null}
                onRefresh={() => null}
              />

              <CaseDialog
                contact={item}
                module_param={item.id}
                module="contact"
                open={caseDialogOpen}
                onClose={() => setCaseDialogOpen(false)}
                //   selected={report}
                onRefresh={() => props.onRefresh()}
              />

              <ServiceAgreementModal
                open={serviceAgreementDialogOpen}
                onClose={() => setServiceAgreementDialogOpen(false)}
                contact={item}
              />

              <ProjectDialog
                open={projectDialogOpen}
                onClose={() => setProjectDialogOpen(false)}
                readOnly={false}
                contact={item}
                // selected={{ report.contact }}
                // Only for testing
                // selected={item?.contact}
                // onRefresh={() => getCase()}
              />
            </div>
          </div>
        </DashboardHeader>

        <SimplePrompt
          title="Sikker på at du vill lukke saken?"
          description="Kunden slettes permanent"
          onAccept={() => removeCustomer(item)}
          open={deletePromptOpen}
          onClose={() => setDeletePromptOpen(false)}
        />
      </>
    );
  };

  return <>{renderContent()}</>;
};
