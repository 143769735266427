import React, { component, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ResourceLoading } from "../../../components/utils/ResourceLoading";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { OrderInfo } from "../../../components/dashboard/orders/order-info";
import { deleteOrderAsync, getOrderAsync } from "../../../slices/order";
import { toast } from "react-hot-toast";
import { OrdersLayout } from "../../../components/dashboard/orders/orders-layout";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import {
  CheckCircleIcon,
  FolderIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import StripeDialog from "../../../components/dashboard/stripe/stripe-dialog";
import { StripePaymentStatus } from "../../../components/dashboard/stripe/stripe-payment-status";
import { InvoiceStatus } from "../../../components/dashboard/invoice/invoice-status";
import { Notes } from "../../../components/dashboard/notes/notes";
import { dispatchWithToast } from "../../../components/Utils";
import ProConfirmation from "../../../components/utils/ProConfirmation";
import { OrderStatus } from "../../../components/dashboard/orders/utils";
import { ResourceError } from "../../../components/utils/ResourceError";

export const Order = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const itemState = {
    ...useSelector((store) => {
      return store.orders;
    }),
  };
  const { isLoading, error, errors, order } = itemState;
  const [stripeDialogOpen, setStripeDialogOpen] = useState(false);

  const [deletePromptOpen, setDeletePromptOpen] = useState(false);

  useEffect(() => {
    dispatch(getOrderAsync(id));
  }, [dispatch]);

  const handleDeletePrompt = (hour) => {
    setDeletePromptOpen(true);
  };

  const navigate = useNavigate();

  const handleAfterDispatch = () => {
    setDeletePromptOpen(false);
    // Redirect to orders page
    navigate("/dashboard/orders");
  };

  const handleDeleteItem = () => {
    dispatchWithToast(dispatch(deleteOrderAsync(order?.id)), null, () =>
      handleAfterDispatch(false)
    );
  };

  const headerMeta = [
    {
      component: <OrderStatus order={order} />,
      label: "Status",
      description: "",
      path: null,
      action: null,
      icon: null,
      hidden: false,
    },
    {
      component: <StripePaymentStatus order={order} />,
      label: "Betalt med Stripe",
      description: "",
      path: null,
      action: null,
      icon: null,
      hidden: false,
    },
    {
      component: <InvoiceStatus order={order} />,
      label: "Faktura status",
      description: "",
      path: null,
      action: null,
      icon: null,
      hidden: false,
    },
  ];

  const headerActions = [
    {
      component: (
        <div className="px-2 mt-2">
          <Notes module={"order"} module_param={order?.id} />
        </div>
      ),
      label: "Status",
      description: "",
      path: null,
      action: null,
      icon: null,
      textColor: "text-teal-700",
      bgColor: "bg-red-200",
      hidden: false,
      menu: false,
    },
    {
      component: null,
      label: "Kopier",
      description: "",
      path: null,
      // action: () => handleDeletePrompt(order),
      icon: FolderIcon,
      textColor: "text-teal-700",
      bgColor: "",
      hidden: isLoading,
      menu: true,
    },
    {
      component: null,
      label: "Delete",
      description: "",
      path: null,
      action: () => handleDeletePrompt(order),
      icon: TrashIcon,
      textColor: "text-teal-700",
      bgColor: "",
      hidden: order?.invoice?.id || isLoading,
      menu: true,
    },
  ];

  const renderContent = () => {
    if (isLoading) {
      return <ResourceLoading />;
    }
    if (error && errors) {
      <ResourceError errors={errors} />;
    }

    return (
      <Grid container spacing={3}>
        <Grid
          container
          item
          lg={12}
          spacing={3}
          sx={{ height: "fit-content", order: { md: 2, xs: 1 } }}
        >
          <Grid item xs={12}>
            <OrderInfo order={order} />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={order?.id ? order?.displayTitle : "New Order"}
        meta={headerMeta}
        // mainButton={{
        //   label: "New",
        //   action: () => setCaseDialogOpen(true),
        //   path: null,
        //   color: "",
        //   icon: PlusIcon,
        // }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            {renderContent()}
          </div>
        </div>
      </DashboardHeader>

      <ProConfirmation
        severity="confirmation"
        open={deletePromptOpen}
        message={
          "Denne ordren vil da bli fjernet for godt og kan ikke reverseres"
        }
        action="delete"
        module="order"
        // item_id={confirmation.item_id}
        yesCallback={() => handleDeleteItem()}
        cancelCallback={() => setDeletePromptOpen(false)}
      />
    </>
  );
};
