import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchDocuments,
  updateDocument,
  createDocument,
  deleteDocument,
} from "../api/document";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  order: {},
  totalCount: 0,
  currentCount: 0,
  newestDocumentsCount: 0,
};

export const initialController = {
  filters: [],
  page: 1,
  per_page: 200,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const deleteDocumentAsync = createAsyncThunk(
  "documents/deleteDocumentAsync",
  async (payload) => {
    let response = await deleteDocument(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createDocumentAsync = createAsyncThunk(
  "documents/createDocumentAsync",
  async (payload) => {
    let response = await createDocument(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const updateDocumentAsync = createAsyncThunk(
  "documents/updateDocumentAsync",
  async (payload, { rejectWithValue }) => {
    let response = await updateDocument(payload);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/)) return rejectWithValue;

    // If all good, return response.
    return response;
  }
);
export const getDocumentsAsync = createAsyncThunk(
  "documents/getDocumentsAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchDocuments(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

export const loadMoreDocumentsAsync = createAsyncThunk(
  "documents/loadMoreDocumentsAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchDocuments(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);
    // If all good, return response.
    return response;
  }
);

const documentSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDocumentsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDocumentsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.documents;
        state.totalCount = action.payload.totalCount;
        state.currentCount = action.payload.currentCount;
        state.newestDocumentsCount = action.payload.newestDocumentsCount;
      })
      .addCase(getDocumentsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch Documents....is controller missing?",
        ];
      })
      .addCase(loadMoreDocumentsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(loadMoreDocumentsAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data = [...draftState.data, ...action.payload.documents];
          draftState.totalCount = action.payload.totalCount;
          draftState.currentCount =
            draftState.data.length + action.payload.currentCount;
          draftState.isLoading = false;
          draftState.error = false;
          draftState.errors = null;
        });
      })
      .addCase(loadMoreDocumentsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch Documents....is controller missing?",
        ];
      })
      .addCase(updateDocumentAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateDocumentAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.order = action.payload;
          draftState.isLoading = false;
          draftState.error = false;
          draftState.errors = null;
        });
      })
      .addCase(updateDocumentAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update Document."];
      })
      .addCase(createDocumentAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createDocumentAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
          draftState.error = false;
          draftState.errors = null;
        });
      })
      .addCase(createDocumentAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to create Document."];
      })
      .addCase(deleteDocumentAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteDocumentAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data = action.payload.data;
          draftState.isLoading = false;
        });
      })
      .addCase(deleteDocumentAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to delete Document."];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = documentSlice;

export default documentSlice;
