import React from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {
  Pagination,
  IconButton,
  ImageListItemBar,
  Grid,
  Paper,
  TextField,
  Button,
  CircularProgress,
  Backdrop,
  ImageList,
  ImageListItem,
  Box,
} from "@mui/material";
import axios from "../../../api/axios";
import EditModal from "./editModal";
import Uploader from "./Uploader";
import { ResourceEmpty } from "../../utils/ResourceEmpty";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { ResourceError } from "../../utils/ResourceError";
import ProConfirmation from "../../utils/ProConfirmation";
import {
  ArrowPathIcon,
  ArrowRightCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { PMField } from "../../utils/PMField";
import { documentSrc } from "../documents/utils";

class Library extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      isLoading: true,
      documents: [],
      document: [],
      id: "",
      module_param: this.props.module_param,
      title: "",
      alt: "",
      src: "",
      description: "",
      document_pages: "",
      current_document_page: 1,
      deleteConfirmationOpen: false,
    };
  }

  getDocuments = async () => {
    const params = {
      company_id: this.props.company_id,
      module: this.props.module ? this.props.module : null,
      module_param: this.props.module_param,
      page: this.state.current_document_page,
      per_page: 24,
    };
    const response = await axios.get(`/documents`, { params });
    this.setState({
      documents: response.data.documents,
      document_pages: response.data.pages - 1,
      isLoading: false,
    });
  };

  getDocument = async () => {
    const id = this.state.id;

    const response = await axios.get(`/documents/${id}`);
    this.setState({
      document: response.data,
    });
    this.setState({
      id: response.data.id,
      title: response.data.title,
      description: response.data.description,
      src: response.data.file.thumb?.url
        ? response.data.file.thumb.url
        : response.data.file.url,
      isLoading: false,
    });
  };

  openDocumentModal = (id) => {
    this.setState({ id: id, modalOpen: true }, this.getDocument);
  };

  clearModalState = () => {
    this.setState({
      id: "",
      title: "",
      description: "",
      src: "",
      modalOpen: false,
      isLoading: false,
    });
  };

  handleModalClose = () => {
    this.clearModalState();
  };

  updateDocument = async (document) => {
    this.setState({ isLoading: true });
    const request = {
      document: document,
    };

    const response = axios.put(`/documents/${this.state.id}`, request);
    this.setState({ modalOpen: false, isLoading: false });
  };

  updateDocumentHandler = (e) => {
    e.preventDefault();
    this.updateDocument(this.state);
    this.getDocuments();
  };

  deleteDocumentHandler = async (id) => {
    await axios.delete(`/documents/${id}`);
    this.getDocuments();
    this.clearModalState();
    this.setState({ deleteConfirmationOpen: false });
  };

  componentDidMount() {
    this.getDocuments();
  }

  pickDocument = (id) => {
    this.props.handleDocument(this.state.document);

    axios.put(this.props.link, {
      document: this.state.document,
    });

    this.clearModalState();
  };

  handlePageChange = (event, value) => {
    // Set state to loading
    // Setting new current document page to new value
    this.setState(
      {
        isLoading: true,
        current_document_page: value,
      },
      () => {
        // This will be run when the state is sucessfully changed.
        // Documents will be updated
        this.getDocuments();
      }
    );
  };

  handleChildCallback = (data) => {
    // this.setState(prevState => ({ documents: [...prevState.documents, data] }))
    this.setState((prevState) => {
      // Make a copy of the documents array
      if (!prevState.documents) {
        return false;
      }
      const documents = [...prevState.documents];
      // Remove the last element
      documents.pop();
      // Add the new element to the beginning of the array
      documents.unshift(data);
      // Return the updated state
      return { documents };
    });
  };

  renderEventsBody = () => {
    const { t } = useTranslation();

    return (
      // <div>SRC: {this.state.documentSrc}</div>
      <>
        <Grid container>
          <Grid item xs={12}>
            <h2>{this.state.title || "Missing title"}</h2>

            <Paper>
              <img
                src={documentSrc(this.state.document, "thumb")}
                style={{ width: 200 }}
              ></img>
            </Paper>

            <form
              className="mt-10"
              onSubmit={this.updateDocumentHandler}
              id="document-form"
              noValidate
              autoComplete="off"
            >
              <PMField
                type="text"
                id="document-title"
                name="title"
                label="Title"
                value={this.state?.title ? this.state?.title : ""}
                onChange={(e) => this.setState({ title: e.target.value })}
              />

              {/* <TextField
                id="document-description"
                name="description"
                style={{
                  width: "100%",
                  marginTop: "10px",
                }}
                label="Description"
                value={this.state.description}
                onChange={(e) => this.setState({ description: e.target.value })}
              /> */}

              <PMField
                id="document-alt"
                onChange={(e) => this.setState({ alt: e.target.value })}
                value={this.state?.alt}
                name="alt"
                label="Alt"
                type="text"
              />

              <div className="flex gap-2 mt-10">
                <button
                  onClick={() =>
                    this.setState({ deleteConfirmationOpen: true })
                  }
                  type="button"
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {t("Delete")}
                  <TrashIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                </button>

                <button
                  type="submit"
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {t("Save")}
                  <ArrowPathIcon
                    className="-mr-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                </button>

                <button
                  onClick={() => this.pickDocument(this.state.id)}
                  type="button"
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {t("Choose Picture")}
                  <ArrowRightCircleIcon
                    className="-mr-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </form>
          </Grid>
        </Grid>

        <ProConfirmation
          severity="confirmation"
          open={this.state.deleteConfirmationOpen}
          message={"Are you sure you want to delete this document?"}
          action="delete"
          module="Document"
          // item_id={confirmation.item_id}
          yesCallback={() => this.deleteDocumentHandler(this.state.id)}
          cancelCallback={() =>
            this.setState({ deleteConfirmationOpen: false })
          }
        />
      </>
    );
  };

  renderEvents = () => {
    return this.state.documents.map((document) => {
      return (
        <ImageListItem
          key={document.id}
          cols={document.cols || 1}
          onClick={() => this.openDocumentModal(document.id)}
          sx={{ width: "100px", height: "148px", border: "2px solid gray" }}
        >
          <img
            src={
              document.file?.thumb?.url
                ? document.file?.thumb?.url
                : document.file?.url
            }
            alt={document.alt || document.tile}
            loading="lazy"
            style={{ height: "148px" }}
          />
          <ImageListItemBar
            title={document.title}
            // actionIcon={
            //     <IconButton aria-label={`star ${document.title}`}>
            //         <StarBorderIcon className="" />
            //     </IconButton>
            // }
          />
        </ImageListItem>
      );
    }, this);
  };

  render() {
    const { isLoading, error } = this.state;
    if (isLoading) return <ResourceLoading />;
    else if (error) return <ResourceError />;
    return (
      <React.Fragment>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper>
              <Uploader
                {...this.props}
                parentCallback={this.handleChildCallback}
              />
            </Paper>

            <Paper sx={{ height: "630px" }} className="mt-10">
              <ImageList cols={6}>
                {this.state.documents && this.state.documents.length > 0 ? (
                  this.renderEvents()
                ) : (
                  <ResourceEmpty />
                )}
              </ImageList>
            </Paper>
            <Paper>
              <Pagination
                count={this.state.document_pages}
                page={this.state.current_document_page}
                onChange={this.handlePageChange}
              />
            </Paper>
          </Grid>
          <EditModal
            open={this.state.modalOpen}
            onClose={() => this.handleModalClose()}
            body={this.renderEventsBody}
          />
        </Grid>
      </React.Fragment>
    );
  }
}

export default Library;
