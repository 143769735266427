import PropTypes from 'prop-types';
import { List } from '@mui/material';

export const PropertyList = (props) => {
    const { children, cols } = props;
    
    return (
        <div className={`grid grid-cols-1 gap-4 sm:grid-cols-${cols}`}>
            { children }
        </div>
);
};

PropertyList.propTypes = {
    children: PropTypes.node
};
