import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Table } from "../../utils/PMTable/Table";
import { TableBody } from "../../utils/PMTable/TableBody";
import { PMPagination } from "../../utils/PMPagination";
import { Link, useNavigate } from "react-router-dom";
import { LoadingRows } from "../../Utils";
import { ResourceEmpty } from "../../utils/ResourceEmpty";

export const TemplatesTable = (props) => {
  const { itemState, onPageChange, itemsCount, page, perPage, currentCount } =
    props;
  const { data, isLoading, errors } = itemState;

  function renderRows() {
    return (
      data &&
      data?.map((item, index) => {
        return (
          <tr key={item.id}>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
              <Link
                to={"/dashboard/templates/" + item?.id}
                className="text-blue-400"
              >
                {item?.title}
              </Link>
            </td>

            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
              <a href="#" className="text-indigo-600 hover:text-indigo-900">
                Edit<span className="sr-only">, {item.title}</span>
              </a>
            </td>
          </tr>
        );
      })
    );
  }

  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="relative">
            <table className="min-w-full table-fixed divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Title
                  </th>

                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <TableBody>
                {" "}
                {isLoading ? (
                  <>
                    <LoadingRows cols={2} checkboxCell={true} />
                    <LoadingRows cols={2} checkboxCell={true} />
                    <LoadingRows cols={2} checkboxCell={true} />
                    <LoadingRows cols={2} checkboxCell={true} />
                    <LoadingRows cols={2} checkboxCell={true} />
                    <LoadingRows cols={2} checkboxCell={true} />
                    <LoadingRows cols={2} checkboxCell={true} />
                    <LoadingRows cols={2} checkboxCell={true} />
                    <LoadingRows cols={2} checkboxCell={true} />
                    <LoadingRows cols={2} checkboxCell={true} />
                    <LoadingRows cols={2} checkboxCell={true} />
                    <LoadingRows cols={2} checkboxCell={true} />
                    <LoadingRows cols={2} checkboxCell={true} />
                    <LoadingRows cols={2} checkboxCell={true} />
                    <LoadingRows cols={2} checkboxCell={true} />
                    <LoadingRows cols={2} checkboxCell={true} />
                  </>
                ) : data?.length < 1 ? (
                  <tr>
                    <td colSpan={6} className="py-2 px-2">
                      <ResourceEmpty />
                    </td>
                  </tr>
                ) : (
                  renderRows()
                )}
              </TableBody>
            </table>

            <PMPagination
              disabled={isLoading}
              onPageChange={onPageChange}
              page={page}
              perPage={perPage}
              rowsCount={itemsCount}
              totalCount={itemsCount}
              currentCount={currentCount}
              showCount={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

TemplatesTable.defaultProps = {
  page: 1,
  itemState: [],
  itemsCount: 0,
  selectedItems: [],
  sort: "desc",
  sortBy: "createdAt",
};
