import produce from "immer";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchCounts, updateCount, createCount, deleteCount, fetchCount } from '../../api/inventory/count'

const initialState = {
    isLoading: false,
    error: false,
    errors: null,
    data: [],
    count: {}
};


export const deleteCountAsync = createAsyncThunk(
    'inventory_counts/deleteCountAsync', 
    async (payload) => {


        let response = await deleteCount(payload);
        
        // If errors, reject and return response
        if (response.errors) return false

        // If all good, return response.
        return response;
    }
)

export const createCountAsync = createAsyncThunk(
    'inventory_counts/createCountAsync', 
    async (payload) => {

        let response = await createCount(payload);
        
        // If errors, reject and return response
        if (response.errors) return false
        // If all good, return response.
        return response;
    }
)

export const updateCountAsync = createAsyncThunk(
    'inventory_counts/updateCountAsync', 
    async (payload) => {
        
        let response = await updateCount(payload);
        
        // If errors, reject and return response
        if (response.errors) return false

        // If all good, return response.
        return response;
    }
)
export const getCountsAsync = createAsyncThunk(
    'inventory_counts/getCountsAsync', 
    async () => {


        // if (! controller) return false;
        let response = await fetchCounts();
        
        // If errors, reject and return response
        // if (response.errors) return false;

        // If all good, return response.
        return response;
})

export const getCountAsync = createAsyncThunk(
    'inventory_counts/getCountAsync',
    async (id, { rejectWithValue }) => {

        // if (! controller) return false;
        let response = await fetchCount(id);

        // If errors, reject and return response
        if (response.error) return rejectWithValue(response);

        // If all good, return response.
        return response;
    })




const InventoryCountSlice = createSlice({
    name: 'inventory_counts',
    initialState,
    reducers: {
    },
    extraReducers : (builder) => {
        builder.addCase(getCountsAsync.pending, (state,     ) => {
            state.isLoading = true;
        })
        .addCase(getCountsAsync.fulfilled, (state, action ) => {
            state.isLoading = false;
            state.data = action.payload;
        })
        .addCase(getCountsAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errors = action.payload
        })
        .addCase(updateCountAsync.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(updateCountAsync.fulfilled, (state, action ) => {
            return produce(state, (draftState) => {
                draftState.count = action.payload;
                draftState.isLoading = false;
            })
        })
        .addCase(updateCountAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errors = ['Something went wrong when trying to update report type']
        })
        .addCase(createCountAsync.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(createCountAsync.fulfilled, (state, action ) => {
            return produce(state, (draftState) => {
                draftState.data.push(action.payload)
                draftState.isLoading = false;
            })
        })
        .addCase(createCountAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errors = action.payload
        })
        .addCase(deleteCountAsync.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(deleteCountAsync.fulfilled, (state, action ) => {
            return produce(state, (draftState) => {
                draftState.data = action.payload;
                draftState.isLoading = false;
            })
        })
        .addCase(deleteCountAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errors = ['Something went wrong when trying to update report type']
        })
            .addCase(getCountAsync.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getCountAsync.fulfilled, (state, action) => {
                return produce(state, (draftState) => {
                    draftState.count = action.payload;
                    draftState.isLoading = false;
                })
            })
            .addCase(getCountAsync.rejected, (state, action) => {
                state.isLoading = false;
                state.error = true;
                state.errors = ['Something went wrong when trying to update product.']
            })
    }
})


// export const {} = customerSlice.actions;

export const { reducer } = InventoryCountSlice;

export default InventoryCountSlice

export const selectInventoryCountById = (state, itemId) => state.inventory_counts.data.find(item => item.id == itemId)
