import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { FormikSaveBar } from "../../utils/FormikSaveBar";
import { PMField } from "../../utils/PMField";
import { useDispatch } from "react-redux";
import PMSwitch from "../../utils/PMSwitch";
import { CustomerSelector } from "../../utils/moduleSelectors/customerSelector";
import { DepartmentSelector } from "../../utils/moduleSelectors/departmentSelector";
import { DeliveryAddressSelector } from "../../utils/moduleSelectors/deliveryAddressSelector";
import { QuillEditor } from "../../utils/quill-editor";
import { useState } from "react";
import { updateProjectAsync } from "../../../slices/project";
import { EmployeeSelector } from "../../utils/moduleSelectors/employeeSelector";
import { useSelector } from "react-redux";
import ActivitiesTable from "../activities/activitiesTable";
import ParticipantsSection from "./participants";
import { ContactsSelector } from "../../utils/moduleSelectors/contactSelector";
import { axiosWithToast, dispatchWithToast } from "../../Utils";

export const ProjectInfo = (props) => {
  const { t } = useTranslation();
  const { item, onRefresh } = props;
  const dispatch = useDispatch();

  const [selectedDepartment, setSelectedDepartment] = useState(
    item?.department?.id ? item?.department : null
  );

  const currentUser = useSelector((state) => state.session.currentUser);

  return (
    <>
      <Formik
        initialValues={{
          id: item?.id,
          name: item?.name,
          description: item?.description,
          project_number: item?.project_number,
          customer_id: item?.customer?.id,
          delivery_address_id: item?.delivery_address?.id,
          project_manager_id: item?.project_manager?.id,
          contact_id: item?.contact?.id,
          start_date: item?.start_date,
          end_date: item?.end_date,
          participants: item?.participants,
          submit: null,
          active: item?.active || false,
          activity_items: item?.activity_items || [],
          finished: item?.finished || false,
        }}
        validationSchema={Yup.object({
          project_number: Yup.string()
            .max(50)
            .required(t("Item number is required")),
          name: Yup.string().max(255).required(t("project.name.required")),
          project_manager_id: Yup.string().required(
            t("project.manager.required")
          ),
          description: Yup.string(),
        })}
        onSubmit={(values, helpers) => {
          try {
            dispatchWithToast(dispatch(updateProjectAsync(values)), (payload) =>
              onRefresh(payload)
            );

            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
          } catch (err) {
            toast.error("Nope");
            console.error(err);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                {/* <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">{ t("Product Description") }</h3>
                    <p className="mt-1 text-sm text-gray-500"></p>
                  </div> */}
                <div className="grid sm:grid-cols-8 gap-6">
                  <div className="sm:col-span-2">
                    <PMSwitch label="Active" name="active" {...formik} />
                  </div>
                  <div className="sm:col-span-2">
                    <PMSwitch label="Finished" name="finished" {...formik} />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t("Project Details")}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500"></p>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-2">
                    <PMField
                      name="project_number"
                      type="text"
                      label={t("project.number")}
                      {...formik}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-4">
                    <PMField
                      name="name"
                      type="text"
                      label={t("project.name")}
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <CustomerSelector selected={item?.customer} {...formik} />
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                    <DepartmentSelector
                      selected={selectedDepartment}
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <DeliveryAddressSelector
                      module="CUSTOMER"
                      module_param={formik.values.customer_id}
                      // fields={fields}
                      selected={item?.delivery_address}
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <EmployeeSelector
                      label="Project Manager"
                      name="project_manager_id"
                      selected={item?.project_manager}
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <ContactsSelector
                      label="Contact"
                      name="contact_id"
                      selected={item?.contact}
                      module_param={formik?.values?.customer_id}
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <PMField
                      name="start_date"
                      type="date"
                      label={t("project.start_date")}
                      // readOnly={readOnly}
                      {...formik}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <PMField
                      name="end_date"
                      type="date"
                      label={t("project.end_date")}
                      // readOnly={readOnly}
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      <label> {t("project.description")} </label>
                    </label>
                    <QuillEditor name="description" rows={4} {...formik} />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t("Aktiviter")}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500"></p>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-2">
                    <ActivitiesTable
                      name="activity_items"
                      number={true}
                      invoiceable={true}
                      inactive={true}
                      drag={false}
                      project={item}
                      title={false}
                      budget_fee={true}
                      budget_fee_rate={true}
                      module={"project"}
                      {...formik}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t("Deltagere")}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500"></p>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6">
                    <ParticipantsSection drag={false} {...formik} />
                  </div>
                </div>
              </div>
            </div>

            {/* <PromptIfDirty {...formik}/> */}
            <FormikSaveBar {...formik} />
          </Form>
        )}
      </Formik>
    </>
  );
};
