import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import { HourSheetTable } from "../../../components/dashboard/hours/sheet/table";

export const Content = () => {
  return (
    <div className="flex flex-col max-w-5xl mx-auto border-1 border-blue-400 px-4 py-4">
      <HourSheetTable />
    </div>
  );
};

export const HourSheet = () => {
  return (
    <DashboardHeader
      title={"Timeliste"}
      // actions={headerActions}
      // meta={headerMeta}
      // mainButton={{
      //   label: t("Edit"),
      //   action: () => setReportDialogOpen(true),
      //   path: null,
      //   color: "",
      //   icon: PencilIcon,
      // }}
    >
      <div className="mx-auto flex flex-col lg:grid grid-rows-1 lg:grid-rows-2 col-span-1 lg:col-span-4 sm:gap-6 sm:px-5 px-2 sm:py-5 py-2 ">
        <Content />
      </div>
    </DashboardHeader>
  );
};
