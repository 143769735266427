import { useDispatch } from "react-redux";
import { ResourceLoading } from "../../components/utils/ResourceLoading";
import { useSelector } from "react-redux";
import { logoutUserWithToken } from "../../api/session";
import { useEffect } from "react";
import { logoutUser, logoutUserWithoutToken } from "../../slices/session";
import { Navigate, useNavigate } from "react-router-dom";
import { Logo } from "../../components/utils/logo";
import { Popover } from "@headlessui/react";

export const Logout = () => {
  const session = useSelector((state) => state.session);
  const currentUser = useSelector((state) => state.session.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading } = session;

  const handleLogout = () => {
    if (currentUser?.id && currentUser?.access_token) {
      dispatch(logoutUser(currentUser?.access_token));
    } else {
      dispatch(logoutUserWithoutToken());
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleLogout();
    }, 500);
  }, []);

  if (!currentUser?.id) {
    return <Navigate to={"/"} replace />;
  }

  return (
    <div className="relative overflow-hidden bg-gray-50">
      <div
        className="hidden sm:absolute sm:inset-y-0 sm:block sm:h-full sm:w-full"
        aria-hidden="true"
      >
        <div className="relative mx-auto h-full max-w-7xl">
          <svg
            className="absolute right-full translate-y-1/4 translate-x-1/4 transform lg:translate-x-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute left-full -translate-y-3/4 -translate-x-1/4 transform md:-translate-y-1/2 lg:-translate-x-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
            />
          </svg>
        </div>
      </div>

      <div className="relative pt-6 pb-16 sm:pb-24">
        <main className="mx-auto mt-16 max-w-7xl px-4 sm:mt-24 h-screen">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block xl:inline">Signing you out of</span>{" "}
              <span className="block text-indigo-600 xl:inline">Promang</span>
            </h1>

            <div className="mt-20">
              <ResourceLoading />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
