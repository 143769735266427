import { useTranslation } from "react-i18next";
import { TWclassNames } from "../../../utils/Div";
import { status } from "../../../Utils";
import { fetchDeviationTypes } from "../../../../api/quality/checklistType";
import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import { fetchChecklistTypes } from "../../../../api/quality/checklistType";

// export const checklistItemActionOptions = [
//   {
//     id: "MEASURES_TAKEN",
//     value: "MEASURES_TAKEN",
//     label: "Measures taken",
//     title: "Measures taken",
//     class: "bg-yellow-100 text-yellow-800",
//     icon: PlusCircleIcon,
//     responsibleUserOnly: false,
//   },
//   {
//     id: "MEASURES_REQUIRED",
//     value: "MEASURES_REQUIRED",
//     label: "Measures required",
//     title: "Measures required",
//     class: "bg-red-100 text-red-800",
//     icon: ExclamationCircleIcon,
//     responsibleUserOnly: false,
//   },
//   {
//     id: "MEASURES_APPROVED",
//     value: "MEASURES_APPROVED",
//     label: "Measures approved",
//     title: "Measures approved",
//     class: "bg-green-100 text-green-800",
//     icon: CheckBadgeIcon,

//     responsibleUserOnly: true,
//   },
// ];

export const checklistStatusOptions = [
  {
    id: "open",
    value: "open",
    label: "Open",
    title: "Open",
    class: "bg-red-100 text-red-800",
  },
  {
    id: "in_progress",
    value: "in_progress",
    label: "In Progress",
    title: "in Progress",
    class: "bg-yellow-100 text-yellow-800",
  },
  {
    id: "finished",
    value: "finished",
    label: "Finished",
    title: "Finished",
    class: "bg-green-400 text-white",
  },

  {
    id: "approved",
    value: "approved",
    label: "Approved",
    title: "Approved",
    class: "bg-green-100 text-green-800",
  },
  {
    id: "closed",
    value: "closed",
    label: "Closed",
    title: "Closed",
    class: "bg-green-100 text-green-800",
  },
];

// export const checklistConsequenceOptions = [
//   {
//     id: 1,
//     value: 1,
//     label: "Economic",
//     title: "Economic",
//     class: "bg-orange-100 text-orange-800",
//   },
//   {
//     id: 2,
//     value: 2,
//     label: "Progress",
//     title: "Progress",
//     class: "bg-yellow-100 text-yellow-800",
//   },
//   {
//     id: 3,
//     value: 3,
//     label: "Safety",
//     title: "Safety",
//     class: "bg-red-100 text-red-800",
//   },
//   {
//     id: 4,
//     value: 4,
//     label: "Environment",
//     title: "Environment",
//     class: "bg-green-100 text-green-800",
//   },
//   {
//     id: 5,
//     value: 5,
//     label: "Quality",
//     title: "Quality",
//     class: "bg-blue-100 text-blue-800",
//   },
//   {
//     id: 6,
//     value: 6,
//     label: "Authority",
//     title: "Authority",
//     class: "bg-gray-100 text-gray-800",
//   },
//   {
//     id: 7,
//     value: 7,
//     label: "Other",
//     title: "Other",
//     class: "bg-gray-100 text-gray-800",
//   },
// ];

export async function getChecklistTypeOptions() {
  try {
    const response = await fetchChecklistTypes(); // Replace with your API endpoint
    // if (!response.ok) {
    //   throw new Error("Network response was not ok");
    // }
    const data = await response;

    return data?.data?.data.map((checklist, idx) => ({
      value: checklist?.id, // Assuming the API has an 'id' field for each checklist type
      title: checklist?.displayTitle, // Assuming the API has a 'name' field for each checklist type
      order: idx, // Assuming the API has an 'order' field for each checklist type
      object: checklist,
    }));
  } catch (error) {
    console.log(error);
    console.error(
      "There was a problem with the fetch operation:",
      error.message
    );
  }
}

export function ChecklistStatus({ checklist }) {
  const { t } = useTranslation();
  if (!checklist) return null;

  const status = checklist?.status;

  const item = checklistStatusOptions.find((item) => item.id === status);

  return (
    <span
      className={TWclassNames(
        checklist?.status ? item.class : "bg-gray-200",
        "inline-flex rounded-full px-2 text-xs font-semibold leading-5"
      )}
    >
      {checklist?.status ? <span>{t(item.label)}</span> : "None"}
    </span>
  );
}

export function ChecklistApprovedStatus({ checklist }) {
  const { t } = useTranslation();
  if (!checklist) return null;

  if (!checklist?.approved) return null;

  return (
    <span
      className={TWclassNames(
        "bg-green-400 text-white",
        "inline-flex rounded-full px-2 text-xs font-semibold leading-5"
      )}
    >
      {checklist?.status ? (
        <span>Godkjent av {checklist?.approved_by?.displayTitle}</span>
      ) : (
        "None"
      )}
    </span>
  );
}

// export function DeviationItemActionStatus({ item }) {
//   const { t } = useTranslation();
//   if (!item) return null;

//   let status = {};

//   const findItemActionByValue = (value) => {
//     return checklistItemActionOptions.find((item) => item.value === value);
//   };

//   if (item?.consequence) {
//     status = checklistConsequenceOptions[item?.consequence - 1];
//   }

//   if (item?.measures_taken) {
//     status = findItemActionByValue("MEASURES_TAKEN");
//   }

//   if (item?.measures_required) {
//     status = findItemActionByValue("MEASURES_REQUIRED");
//   }

//   if (item?.measures_approved) {
//     status = findItemActionByValue("MEASURES_APPROVED");
//   }

//   if (!status) return null;

//   return (
//     <div
//       className={TWclassNames(
//         status ? status.class : "bg-gray-200",
//         "rounded-full px-4 text-xs py-2 font-semibold leading-5 flex"
//       )}
//     >
//       {status?.icon && <status.icon className="h-5 w-5" />}
//       <span className="ml-2">
//         {status ? <span>{t(status.label)}</span> : "Missing action!"}
//       </span>
//     </div>
//   );
// }
