import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { FormikSaveBar } from "../../../utils/FormikSaveBar";
import { PMField } from "../../../utils/PMField";
import { useDispatch } from "react-redux";
import { fetchRoles } from "../../../../api/users";
import { useEffect, useState } from "react";
import PMSwitch from "../../../utils/PMSwitch";
import UppercaseFirstLetter, { dispatchWithToast } from "../../../Utils";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { updateUserAsync } from "../../../../slices/user";

export const UserRoles = (props) => {
  const { t } = useTranslation();
  const { item, onRefresh } = props;
  const dispatch = useDispatch();

  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchPMRoles = async () => {
    const response = await fetchRoles()
      .then((res) => {
        setMainRoles(res?.data?.data);
      })
      .catch((err) => err);
  };

  // Main Roles
  const setMainRoles = (roles) => {
    const main = roles
      .filter((role) => !role.name.includes("_"))
      .map((role, idx) => {
        return {
          id: role.id,
          name: role.name,
          label: role.label,
          description: role.description,
          checked: findUserRole(role.name) ? true : false || false,
          children: roles.filter(
            (child) =>
              child.name.includes("_") && child.name.includes(role.name)
          ),
        };
      });
    setRoles(main);
    setIsLoading(false);
  };

  // Find role by searching name
  const findUserRole = (name) => {
    return item.roles.find((role) => role.name === name);
  };

  useEffect(() => {
    fetchPMRoles();

    return () => {};
  }, []);

  const handleRoleChange = (e, formik) => {
    // If e.target.name includes "_" then it's a child role
    if (e.target.name.includes("_")) {
      // Find parent role
      const parentRole = roles.find(
        (role) => role.name === e.target.name.split("_")[0]
      );

      // Find child role
      const childRole = parentRole.children.find(
        (child) => child.name === e.target.name
      );

      // Update child role
      const newChildRole = {
        ...childRole,
        checked: e.target.checked,
      };

      // Update parent role
      const newParentRole = {
        ...parentRole,
        checked: true,
      };

      // Update parent role in roles
      const newRoles = roles.map((role) => {
        if (role.name === newParentRole.name) {
          return newParentRole;
        }
        return role;
      });

      // Update child role in parent role
      const newChildren = newParentRole.children.map((child) => {
        if (child.name === newChildRole.name) {
          return newChildRole;
        }
        return child;
      });

      // Update parent role with new children
      newParentRole.children = newChildren;

      // Update roles with new parent role
      setRoles(newRoles);

      const newRole = {
        id: newChildRole.id,
        name: newChildRole.name,
        active: newChildRole.checked,
      };

      const formikExistingRoleExceptNew = formik.values.roles.filter(
        (role) => role.name !== newRole.name
      );

      // Add changes to formik
      formik.setFieldValue("roles", [...formikExistingRoleExceptNew, newRole]);
    } else {
      const newChanges = roles.map((role) => {
        if (role.name === e.target.name) {
          return {
            ...role,
            checked: e.target.checked,
            // If checked === false, set all children to false
            children: role.children.map((child) => {
              return {
                ...child,
                checked: e.target.checked ? child.checked : false,
              };
            }),
          };
        }
        return role;
      });

      // Replace existing role with new one
      setRoles(newChanges);

      // Add changes to formik
      formik.setFieldValue(
        "roles",
        newChanges.map((role) => {
          return {
            id: role.id,
            name: role.name,
            active: role.checked,
          };
        })
      );
    }
  };

  if (isLoading) {
    return <ResourceLoading />;
  }

  return (
    <>
      <Formik
        initialValues={{
          id: item?.id,
          approved: item?.approved,
          submit: null,
          roles: [],
        }}
        onSubmit={(values, helpers) => {
          try {
            dispatchWithToast(dispatch(updateUserAsync(values)), onRefresh);
            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
          } catch (err) {
            toast.error("Nope");
            console.error(err);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
              <div className=" bg-white py-6 px-4 sm:p-6">
                <div>
                  <h3 className="flex text-lg font-medium leading-6 text-gray-900">
                    <PMSwitch name="approved" {...formik} />
                    {t("Has access")}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500"></p>
                </div>
              </div>
            </div>

            {formik.values.approved &&
              roles.map((role, idx) => (
                <>
                  <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
                    <div className=" bg-white py-6 px-4 sm:p-6">
                      <div>
                        <h3 className="flex text-lg font-medium leading-6 text-gray-900">
                          <PMSwitch
                            name={role.name}
                            checked={role.checked}
                            onChange={(e) => handleRoleChange(e, formik)}
                          />
                          <UppercaseFirstLetter text={t(role.name)} />
                        </h3>
                        <p className="mt-1 text-sm text-gray-500"></p>
                      </div>

                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-6 lg:col-span-6 px-5 py-5 w-full flex">
                          {role.checked &&
                            role.children.map((child, idx) => (
                              <div
                                key={idx}
                                className="flex items-center mt-5 ml-5"
                              >
                                <ArrowRightIcon className="h-5 w-5 text-gray-400 mr-5" />
                                <PMSwitch
                                  name={child.name}
                                  checked={child.checked}
                                  onChange={(e) => handleRoleChange(e, formik)}
                                />
                                <UppercaseFirstLetter
                                  text={t(child.name.split("_")[1])}
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            <FormikSaveBar {...formik} />
          </Form>
        )}
      </Formik>
    </>
  );
};
