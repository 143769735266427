import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchVehicles,
  fetchVehicle,
  updateVehicle,
  createVehicle,
  deleteVehicle,
} from "../api/vehicle";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  totalCount: 0,
  currentCount: 0,
  newCount: 0,
};

export const initialController = {
  filters: [],
  page: 0,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const deleteVehicleAsync = createAsyncThunk(
  "vehicles/deleteVehicleAsync",
  async (id) => {
    let response = await deleteVehicle(id);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createVehicleAsync = createAsyncThunk(
  "vehicles/createVehicleAsync",
  async (payload) => {
    let response = await createVehicle(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const updateVehicleAsync = createAsyncThunk(
  "vehicles/updateVehicleAsync",
  async (payload) => {
    let response = await updateVehicle(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);
export const getVehiclesAsync = createAsyncThunk(
  "vehicles/getVehiclesAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchVehicles(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

export const getVehicleAsync = createAsyncThunk(
  "vehicles/getVehicleAsync",
  async (id, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchVehicle(id);

    // If errors, reject and return response
    if (response.error) return rejectWithValue(response);

    // If all good, return response.
    return response;
  }
);

const vehicleSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVehiclesAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVehiclesAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.currentCount = action.payload.currentCount;
        state.newestVehiclesCount = action.payload.newCount;
      })
      .addCase(getVehiclesAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch vehicles....is controller missing?",
        ];
      })
      .addCase(updateVehicleAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateVehicleAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data[index] = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(updateVehicleAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update vehicle"];
      })
      .addCase(createVehicleAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createVehicleAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
        });
      })
      .addCase(createVehicleAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update vehicle"];
      })
      .addCase(deleteVehicleAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteVehicleAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data.splice(index, 1);
          // draftState.data = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(deleteVehicleAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update vehicle"];
      })
      .addCase(getVehicleAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVehicleAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.vehicle = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(getVehicleAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update product."];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = vehicleSlice;

export default vehicleSlice;
