import axios from "./axios";

export async function fetchTemplates(controller) {
  return axios({
    method: "get",
    url: "/templates",
    params: controller,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchTemplate(id) {
  return axios({
    method: "get",
    url: `/templates/${id}`,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function createTemplate(payload) {
  return axios({
    method: "post",
    url: "/templates",
    data: payload,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function updateTemplate(payload) {
  return axios({
    method: "put",
    url: `/templates/${payload?.id}`,
    data: payload,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
}
