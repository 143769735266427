import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import produce from "immer";
import {
  createCaseType,
  fetchCaseType,
  updateCaseType,
} from "../../api/case/type";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  system: {},
  data: [],
  currentCount: 0,
  totalCount: 0,
  newCount: 0,
};

export const initialController = {
  filters: [],
  page: 0,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const updateCaseTypeAsync = createAsyncThunk(
  "cases/updateCaseTypeAsync",
  async (payload) => {
    let response = await updateCaseType(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createCaseTypeAsync = createAsyncThunk(
  "cases/createCaseTypeAsync",
  async (payload) => {
    let response = await createCaseType(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const getCasesTypeAsync = createAsyncThunk(
  "cases/getCasesTypeAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchCaseType(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

const CaseTypeSlice = createSlice({
  name: "cases",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCasesTypeAsync.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getCasesTypeAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.currentCount = action.payload.currentCount;
        state.newCount = action.payload.newCount;
      })

      .addCase(getCasesTypeAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch Cases....is controller missing?",
        ];
      })
      .addCase(updateCaseTypeAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateCaseTypeAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data[index] = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(updateCaseTypeAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update product."];
      })
      .addCase(createCaseTypeAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createCaseTypeAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
        });
      })
      .addCase(createCaseTypeAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update product."];
      });
  },
});

export const { reducer } = CaseTypeSlice;

export default CaseTypeSlice;
