import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchActivities,
  fetchActivity,
  updateActivity,
  createActivity,
  deleteActivity,
} from "../api/activity";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  totalCount: 0,
  currentCount: 0,
  newCount: 0,
};

export const initialController = {
  filters: [],
  page: 0,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const deleteActivityAsync = createAsyncThunk(
  "activities/deleteActivityAsync",
  async (payload) => {
    let response = await deleteActivity(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createActivityAsync = createAsyncThunk(
  "activities/createActivityAsync",
  async (payload) => {
    let response = await createActivity(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const updateActivityAsync = createAsyncThunk(
  "activities/updateActivityAsync",
  async (payload) => {
    let response = await updateActivity(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);
export const getActivitiesAsync = createAsyncThunk(
  "activities/getActivitiesAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchActivities(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

export const getActivityAsync = createAsyncThunk(
  "activities/getActivityAsync",
  async (id, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchActivity(id);

    // If errors, reject and return response
    if (response.error) return rejectWithValue(response);

    // If all good, return response.
    return response;
  }
);

const activitySlice = createSlice({
  name: "activities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActivitiesAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getActivitiesAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.currentCount = action.payload.currentCount;
        state.newestActivitysCount = action.payload.newCount;
      })
      .addCase(getActivitiesAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch activities....is controller missing?",
        ];
      })
      .addCase(updateActivityAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateActivityAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data[index] = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(updateActivityAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update activity"];
      })
      .addCase(createActivityAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createActivityAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
        });
      })
      .addCase(createActivityAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update activity"];
      })
      .addCase(deleteActivityAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteActivityAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(deleteActivityAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update activity"];
      })
      .addCase(getActivityAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getActivityAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.activity = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(getActivityAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update product."];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = activitySlice;

export default activitySlice;
