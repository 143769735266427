import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchServiceAgreements,
  fetchServiceAgreement,
  updateServiceAgreement,
  createServiceAgreement,
  deleteServiceAgreement,
  listUpdateServiceAgreement,
} from "../../api/service/agreement";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  serviceAgreement: {},
  totalCount: 0,
  currentCount: 0,
  newCount: 0,
};

export const initialController = {
  filters: [],
  page: 0,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const deleteServiceAgreementAsync = createAsyncThunk(
  "serviceAgreements/deleteServiceAgreementAsync",
  async (payload) => {
    let response = await deleteServiceAgreement(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createserviceAgreementAsync = createAsyncThunk(
  "serviceAgreements/createserviceAgreementAsync",
  async (payload) => {
    let response = await createServiceAgreement(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const updateServiceAgreementAsync = createAsyncThunk(
  "serviceAgreements/updateServiceAgreementAsync",
  async (payload) => {
    let response = await updateServiceAgreement(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);
export const getServiceAgreementsAsync = createAsyncThunk(
  "serviceAgreements/getServiceAgreementsAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchServiceAgreements(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

export const getServiceAgreementAsync = createAsyncThunk(
  "serviceAgreements/getServiceAgreementAsync",
  async (id, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchServiceAgreement(id);

    // If errors, reject and return response
    if (response.error) return rejectWithValue(response);

    // If all good, return response.
    return response;
  }
);

export const listUpdateServiceAgreementAsync = createAsyncThunk(
  "serviceAgreements/listUpdateServiceAgreementAsync",
  async (payload, { rejectWithValue }) => {
    let response = await listUpdateServiceAgreement(payload);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/)) return rejectWithValue;

    // If all good, return response.
    return response;
  }
);

const serviceAgreementSlice = createSlice({
  name: "serviceAgreements",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getServiceAgreementsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getServiceAgreementsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.currentCount = action.payload.currentCount;
        state.newCount = action.payload.newCount;
      })
      .addCase(getServiceAgreementsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch variable types....is controller missing?",
        ];
      })
      .addCase(updateServiceAgreementAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateServiceAgreementAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data[index] = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(updateServiceAgreementAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update serviceAgreement",
        ];
      })
      .addCase(createserviceAgreementAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createserviceAgreementAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
        });
      })
      .addCase(createserviceAgreementAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update serviceAgreement",
        ];
      })
      .addCase(deleteServiceAgreementAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteServiceAgreementAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(deleteServiceAgreementAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update serviceAgreement",
        ];
      })
      .addCase(getServiceAgreementAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getServiceAgreementAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.serviceAgreement = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(getServiceAgreementAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update product."];
      })
      .addCase(listUpdateServiceAgreementAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(listUpdateServiceAgreementAsync.fulfilled, (state, action) => {
        state.serviceAgreement = action.payload;
        state.isLoading = false;
        // return produce(state, (draftState) => {
        //     // const index = draftState.data.findIndex(type => type.id === action.payload.id);
        //     draftState.product = action.payload
        //     draftState.isLoading = false;
        // })
      })
      .addCase(listUpdateServiceAgreementAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update serviceAgreement.",
        ];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = serviceAgreementSlice;

export default serviceAgreementSlice;
