import { useEffect, useState } from "react";
import { PMDialog } from "../../utils/PMDialog";
import { PMField } from "../../utils/PMField";
import { StringLimit } from "../../Utils";
import { fetchTemplates } from "../../../api/templates";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { debounce } from "lodash";

export const TemplateDialog = (props) => {
  const { onChange } = props;
  const [query, setQuery] = useState();

  const [isLoading, setIsLoading] = useState(true);

  const [templates, setTemplates] = useState([
    { id: 1, title: "Template 1", content: "Content 1" },
    { id: 2, title: "Template 2", content: "Content 2" },
    { id: 3, title: "Template 3", content: "Content 3" },
  ]);

  const handleChange = (template) => {
    if (onChange) {
      onChange(template);
    }
  };

  const getTemplates = async () => {
    await fetchTemplates({ query: query })
      .then((res) => {
        setIsLoading(false);
        setTemplates(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const debouncedAction = debounce(() => {
    setIsLoading(true);
    getTemplates();
  }, 500);

  useEffect(() => {
    if (open) {
      debouncedAction();
    }
    // Clean the debounced function on component unmount.
    return () => {
      debouncedAction.cancel();
    };
  }, [query, open]);

  return (
    <PMDialog {...props} title="Template">
      {isLoading ? (
        <ResourceLoading />
      ) : (
        <>
          <div>
            <PMField
              label="Søk type..."
              onChange={(e) => setQuery(e?.target?.value)}
              value={query}
            />
          </div>

          <div className="py-5">
            {templates &&
              templates?.map((template) => (
                <div
                  onClick={() => handleChange(template)}
                  key={template.id}
                  className="py-2 hover:text-blue-400 hover:bg-blue-50 rounded-md hover:cursor-pointer"
                >
                  <div className="px-2 py-2">
                    <StringLimit text={template.title} limit={30} />
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </PMDialog>
  );
};
