import Proptypes from "prop-types";
import {
  Avatar,
  Box,
  Checkbox,
  Divider,
  Link,
  Skeleton,
  Table,
  TableFooter,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";

import { useTranslation } from "react-i18next";
import { useSettings } from "../../../../contexts/settings-context";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import {
  getCountItemsAsync,
  deleteCountAsync,
} from "../../../../slices/inventory/count_item";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ResourceEmpty } from "../../../utils/ResourceEmpty";
import { ResourceError } from "../../../utils/ResourceError";
import { Scrollbar } from "../../../utils/scrollbar";

const columns = [
  {
    id: "product_id",
    label: "Product",
  },
  {
    id: "quantity",
    label: "Quantity",
  },

  {
    id: "unit",
    label: "Unit",
  },
  {
    id: "purchasePrice",
    label: "Purchase Price",
  },
  {
    id: "total",
    label: "Total",
  },

  {
    id: "Action",
    label: "Action",
  },
];

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 0,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgrey" : null,

  // styles we need to apply on draggables
  ...draggableStyle,
});

export const InventoryCountItemsTable = (props) => {
  const { settings, priceFormatter } = useSettings();
  const { id } = useParams();
  const countId = id;
  const {
    error,
    isLoading,
    onPageChange,
    onSelect,
    onSelectAll,
    onSortChange,
    page,
    itemsCount,
    selectedItems,
    sort,
    sortBy,
    onEdit,
    updatedItems,
  } = props;

  const countItems = (rows) => {
    var array = [];
    if (!rows) return array;
    rows.map((row, i) => {
      const rowParams = {
        ...row,
        order: i,
        draggableId: "id" + row.id,
      };
      array.push(rowParams);
    });
    return array;
  };

  const itemsState = useSelector((store) => {
    return store.inventory_count_items;
  });
  const [items, setItems] = useState(countItems(itemsState.data));
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !items.length);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  // useEffect(async () => {
  //   updatedItems(items)
  // }, [items])

  useEffect(() => {
    // Fetch count items for current Inventory Count.
    dispatch(getCountItemsAsync(countId));
  }, [dispatch]);

  const onItemRowChange = (e, data) => {
    // setItems(prevState => ({ ...prevState, [1][description]: 'test' }))
    e.preventDefault();

    let prevItems = [...items];
    let prevItem = prevItems.find((item) => item.order === data?.order);
    let item = {
      ...prevItems[prevItem.order],
      [e.target.name]: e.target.value,
    };
    prevItems[data?.order] = item;
    setItems(prevItems);
  };

  const calculateRowTotalPurchasePrice = (item) => {
    var total = 0.0;
    if (item && item.purchase_price && item.quantity) {
      total = item.purchase_price * item.quantity;
    }

    return total;
  };

  const calculateTotalPurchasePrice = () => {
    var total = 0.0;
    if (items && items.length > 0) {
      items?.map((item) => {
        total += calculateRowTotalPurchasePrice(item);
      });
    }
    return total;
  };

  const addItemRow = (type) => {
    const newRow = {
      description: "",
      purchase_price: 0,
      quantity: 0,
      order: items ? items.length : 0,
      // Generating a fake ID for draggable, so that we are able to move the row before saving.
      draggableId: "id" + items.length + 1,
    };
    setItems((prevState) => [...prevState, newRow]);
  };

  const deleteItemRow = () => {
    if (selectedRow) {
      // Filter out
      if (selectedRow?.id !== undefined) {
        dispatch(deleteCountAsync(selectedRow.id));
      } else {
        const newItems = items.filter(
          (item) => item.order !== selectedRow.order
        );
        setItems(newItems);
        toast.success("The row was successfully deleted.");
      }
      setSelectedRow(null);
    } else {
      toast.error("Could not find the selected row: " + selectedRow);
    }
    setDeleteDialogOpen(false);
  };

  const handleDeleteItemRow = (item) => {
    setSelectedRow(item);
    setDeleteDialogOpen(true);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newItems);
  };

  const itemRow = (item, provided, snapshot) => {
    return (
      <TableRow
        onClick={() => console.log(item)}
        hover
        key={item.id}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
      >
        <TableCell>
          <DragHandleIcon />
        </TableCell>

        <TableCell align="left">
          <TextField
            label="Velg produkt"
            variant="standard"
            disabled
            fullWidth
          />{" "}
          <br />
          <TextField
            label="Beskrivelse"
            variant="standard"
            sx={{ mt: "10px" }}
            value={item?.description}
            fullWidth
            name="description"
            multiline
            onChange={(e) => onItemRowChange(e, item)}
          />
        </TableCell>

        <TableCell align="left" sx={{ maxWidth: "25px" }}>
          <TextField
            variant="standard"
            value={item?.quantity}
            type="number"
            name="quantity"
            onChange={(e) => onItemRowChange(e, item)}
          />
        </TableCell>

        <TableCell align="left">
          <TextField
            variant="standard"
            value={item?.unitTitle}
            name="unitTitle"
            disabled
          />
        </TableCell>

        <TableCell align="left">
          <TextField
            variant="standard"
            value={item?.purchase_price}
            fullWidth
            name="purchase_price"
            required
            onChange={(e) => onItemRowChange(e, item)}
          />
        </TableCell>

        <TableCell align="left">
          {priceFormatter.format(calculateRowTotalPurchasePrice(item))}
        </TableCell>

        <TableCell align="right">
          <IconButton onClick={() => handleDeleteItemRow(item)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div className=" bg-white shadow rounded-md p-2">
      <Scrollbar>
        <Table sx={{ minWidth: 800 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={
                    items.length > 0 && selectedItems.length === items.length
                  }
                  disabled={isLoading}
                  indeterminate={
                    selectedItems.length > 0 &&
                    selectedItems.length < items.length
                  }
                  onChange={onSelectAll}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : "asc"}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {items?.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.draggableId}
                      index={index}
                    >
                      {(provided, snapshot) =>
                        itemRow(item, provided, snapshot)
                      }
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>

          <TableFooter>
            <TableRow>
              <TableCell align="left" colSpan={columns.length + 1}>
                {displayLoading && (
                  <Box sx={{ p: 2 }}>
                    <Skeleton height={42} />
                    <Skeleton height={42} />
                    <Skeleton height={42} />
                  </Box>
                )}
                {displayError && <ResourceError />}
                {displayUnavailable && <ResourceEmpty />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" colSpan={2}>
                <Button onClick={() => addItemRow()}>{t("Add row")}</Button>
              </TableCell>
              <TableCell colSpan={3}></TableCell>
              <TableCell colSpan={1}>
                {priceFormatter.format(calculateTotalPurchasePrice())}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Scrollbar>

      <Divider sx={{ mt: "auto" }} />
      {/* <ConfirmationDialog
        message={t("Are you sure you want to delete this row? This cannot be undone.")}
        onCancel={() => setDeleteDialogOpen(false)}
        onConfirm={deleteItemRow}
        open={deleteDialogOpen}
        title={t("Delete item row")}
        variant="error"
      /> */}
    </div>
  );
};

InventoryCountItemsTable.defaultProps = {
  page: 1,
  items: [],
  itemsCount: 0,
  selectedItems: [],
  sort: "desc",
  sortBy: "createdAt",
};

InventoryCountItemsTable.propTypes = {
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSelect: Proptypes.func,
  onSelectAll: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  items: Proptypes.array,
  itemsCount: Proptypes.number,
  selectedItems: Proptypes.array,
  sort: Proptypes.oneOf(["asc", "desc"]),
  sortBy: Proptypes.string,
};
