import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createSystem, destroySystem, updateSystem } from '../../api/customer/system'
import produce from "immer";


const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  system: {},
  data: [],
  count: 0
};

export const systemsController = {
  filters: [],
  page: 0,
  query: '',
  sort: 'desc',
  sortBy: 'created_at',
  view: 'all'
}

export const updateSystemAsync = createAsyncThunk(
  'systems/updatesystemAsync',
  async (payload) => {

    let response = await updateSystem(payload);

    // If errors, reject and return response
    if (response.error) return false

    // If all good, return response.
    return response;
  }
)

export const createSystemAsync = createAsyncThunk(
  'systems/createSystemAsync',
  async (payload) => {

    let response = await createSystem(payload);

    // If errors, reject and return response
    if (response.error) return false

    // If all good, return response.
    return response;
  }
)




const systemSlice = createSlice({
  name: 'systems',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateSystemAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateSystemAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.system = action.payload;
          draftState.isLoading = false;
        })
      })
      .addCase(updateSystemAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ['Something went wrong when trying to update product.']
      })
      .addCase(createSystemAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createSystemAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.system = action.payload;
          draftState.isLoading = false;
        })
      })
      .addCase(createSystemAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ['Something went wrong when trying to update product.']
      })

  }
})


export const { reducer } = systemSlice;

export default systemSlice
