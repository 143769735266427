import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Outlet, useParams } from "react-router-dom"
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import PMDropdown from "../../utils/PMDropdown";

import {
  ArchiveBoxIcon,
  ArrowRightCircleIcon,
  ChevronDownIcon,
  DocumentDuplicateIcon,
  HeartIcon,
  PencilSquareIcon,
  TrashIcon,
  UserPlusIcon,
} from '@heroicons/react/20/solid'
import InventoryCountModal from "./counts/Inventory-count-modal";


export const InventoryLayout = (props) => {
  const { children, title, key } = props
  const { id } = useParams()
  const { t } = useTranslation()
  const item = useSelector((state) => state.inventory_counts)
  const [editModalOpen, setEditModalOpen] = useState(false)

  const inventoryCountListDropdownActions = [
    {
      label: 'new',
      children: [
        { label: 'New', path: '', icon: PencilSquareIcon, action: () => setEditModalOpen(true) },
      ]
    },
    {
      label: 'export',
      children: [
        { label: 'Export PDF', path: '/path', icon: ArchiveBoxIcon }
      ]
    },
  ]

  const inventoryCountDropdownActions = [
    {
      label: 'edit',
      children: [
        { label: 'Edit', path: '/products/63', icon: PencilSquareIcon },
        // { label: 'Duplicate', path: '/path', icon: DocumentDuplicateIcon }
      ]
    },
    {
      label: 'archive',
      children: [
        { label: 'Archive', path: '/path', icon: ArchiveBoxIcon }
      ]
    },
    {
      label: 'delete',
      children: [
        { label: 'Delete', path: '/path', icon: TrashIcon }
      ]
    }
  ]


  const label = {
    "": "Inventory",
    "inventory_overview": "Inventory Overview",
    "inventory_counts_list": "Inventory Counts",
    "inventory_count": id ? item?.title ? item?.title : "Inventory Count" : "Inventory Count"
  }

  const currentActions = {
    "": null,
    "inventory_overview": null,
    "inventory_counts_list": inventoryCountListDropdownActions,
    "inventory_count": inventoryCountListDropdownActions,
  }





  // inventory_counts

  return (
    <>
      <div className="py-6">

        {/* Page header */}
        <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <div className="flex items-center space-x-5">
            <div className="flex-shrink-0">
              <div className="relative">
                {item?.main_picture ?
                  <img
                    className="h-16 w-16 rounded-full"
                    src={item?.main_picture}
                    alt=""
                  />
                  : <PrecisionManufacturingOutlinedIcon className="w-20" />}
                <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">{t(label[props.name])}</h1>
              <p className="text-sm font-medium text-gray-500">
                {id ? item?.item_number : ''}
              </p>
            </div>
          </div>

          <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
            <PMDropdown options={!id ? currentActions[props.name] : inventoryCountDropdownActions} />
          </div>

        </div>
        <div className="mx-auto max-w-8xl px-2 sm:px-6 md:px-9 mt-10 bg-gray-100 rounded-md">
          {children ? children : <Outlet />}
        </div>

      </div>

      <InventoryCountModal open={editModalOpen} onClose={() => setEditModalOpen(false)} />
    </>

  )
}