import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { updateServiceAgreementAsync } from "../../../../slices/service/agreements";
import { FormikSaveBar } from "../../../utils/FormikSaveBar";
import { dispatchWithToast } from "../../../Utils";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import OrderItems from "../../orders/order-items";

export const ServiceAgreementOrderlines = (props) => {
  const { t } = useTranslation();
  const { item, onRefresh, isLoading } = props;
  const dispatch = useDispatch();

  if (isLoading) {
    return <ResourceLoading />;
  }
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          id: item?.id,
          orderlines: item?.orderlines,
        }}
        onSubmit={(values, helpers) => {
          try {
            dispatchWithToast(
              dispatch(updateServiceAgreementAsync(values)),
              onRefresh
            );
            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
          } catch (err) {
            toast.error("Something went wrong...");
            console.error(err);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <div className="bg-white shadow rounded-xl">
              <div className="px-5 py-5 grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <OrderItems
                    name="orderlines"
                    group={false}
                    sales_price={true}
                    purchase_price={false}
                    discount={true}
                    surcharge={false}
                    drag={false}
                    quantity_picked={false}
                    quantity={true}
                    {...formik}
                  />
                </div>
              </div>
            </div>

            <FormikSaveBar {...formik} />
          </Form>
        )}
      </Formik>
    </>
  );
};
