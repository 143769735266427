import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import StripeDialog from "./stripe-dialog";

export const StripePaymentStatus = ({ order }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      {order && order?.stripe_session?.id ? (
        <div
          onClick={() => setDialogOpen(true)}
          className="flex rounded-md py-2 text-green-500 cursor-pointer"
        >
          <CheckBadgeIcon className="h-5 w-5" />
          <span className="px-1">Betalt med Stripe</span>
        </div>
      ) : null}

      <StripeDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        selected={order}
      />
    </>
  );
};
