import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Table } from "../../utils/PMTable/Table";
import { TableBody } from "../../utils/PMTable/TableBody";
import { PMPagination } from "../../utils/PMPagination";
import { Link, useNavigate } from "react-router-dom";
import { LoadingRows } from "../../Utils";
import { ResourceEmpty } from "../../utils/ResourceEmpty";

export const ProjectsTable = (props) => {
  const { itemState, onPageChange, itemsCount, page, perPage, currentCount } =
    props;
  const { data, isLoading, errors } = itemState;

  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedItems?.length > 0 && selectedItems?.length < data?.length;
    setChecked(selectedItems?.length === data?.length);
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedItems]);

  function toggleAll() {
    setSelectedItems(checked || indeterminate ? [] : data);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const navigate = useNavigate();

  function renderRows() {
    return data?.map((item, index) => {
      return (
        <tr
          key={item.id}
          // onClick={() => navigate("/dashboard/projects/" + item.id)}
        >
          <td className="relative px-7 sm:w-12 sm:px-6">
            {selectedItems.includes(item) && (
              <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-400" />
            )}
            <input
              type="checkbox"
              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-400 focus:ring-blue-400"
              value={item.id}
              checked={selectedItems.includes(item)}
              onChange={(e) =>
                setSelectedItems(
                  e.target.checked
                    ? [...selectedItems, item]
                    : selectedItems.filter((p) => p !== item)
                )
              }
            />
          </td>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <Link
              to={"/dashboard/projects/" + item?.id}
              className="text-blue-400"
            >
              {item?.project_number}
            </Link>
          </td>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <Link
              to={"/dashboard/projects/" + item?.id}
              className="text-blue-400"
            >
              <div className="">{item?.name}</div>
            </Link>
          </td>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <Link
              to={"/dashboard/customers/" + item?.customer?.id}
              className="text-blue-400"
            >
              <div className="">{item?.customer?.displayTitle}</div>
            </Link>
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <div className="text-gray-900">
              {item?.delivery_address?.displayTitle}{" "}
            </div>
          </td>
          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
            <a href="#" className="text-indigo-600 hover:text-indigo-900">
              Edit<span className="sr-only">, {item.title}</span>
            </a>
          </td>
        </tr>
      );
    });
  }

  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="relative">
            <div className="relative px-5 top-0 flex h-12 items-center space-x-3 bg-blue-400 rounded-xl mb-2 text-white">
              <div className="flex w-full items-center justify-between">
                {selectedItems?.length > 0 ? (
                  <>
                    <div className="">
                      <span>{selectedItems.length} selected</span>
                    </div>
                    <div className="px-2 py-2">
                      <button
                        type="button"
                        className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center rounded bg-white px-2 py-1 ml-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                      >
                        Finish
                      </button>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <table className="min-w-full table-fixed divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                    <input
                      type="checkbox"
                      className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-400 focus:ring-blue-400"
                      ref={checkbox}
                      checked={checked}
                      onChange={toggleAll}
                    />
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Number
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Customer
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Address
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <TableBody>
                {" "}
                {isLoading ? (
                  <>
                    <LoadingRows cols={5} checkboxCell={true} />
                    <LoadingRows cols={5} checkboxCell={true} />
                    <LoadingRows cols={5} checkboxCell={true} />
                    <LoadingRows cols={5} checkboxCell={true} />
                    <LoadingRows cols={5} checkboxCell={true} />
                    <LoadingRows cols={5} checkboxCell={true} />
                    <LoadingRows cols={5} checkboxCell={true} />
                    <LoadingRows cols={5} checkboxCell={true} />
                    <LoadingRows cols={5} checkboxCell={true} />
                    <LoadingRows cols={5} checkboxCell={true} />
                  </>
                ) : data?.length < 1 ? (
                  <tr>
                    <td colSpan={6} className="py-2 px-2">
                      <ResourceEmpty />
                    </td>
                  </tr>
                ) : (
                  renderRows()
                )}
              </TableBody>
            </table>

            <PMPagination
              disabled={isLoading}
              onPageChange={onPageChange}
              page={page}
              perPage={perPage}
              rowsCount={itemsCount}
              totalCount={itemsCount}
              currentCount={currentCount}
              showCount={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectsTable.defaultProps = {
  page: 1,
  itemState: [],
  itemsCount: 0,
  selectedItems: [],
  sort: "desc",
  sortBy: "createdAt",
};
