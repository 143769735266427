import { toast } from "react-hot-toast";
import { dispatchWithToast, mapErrors } from "../../../../Utils";
import PropTypes from "prop-types";

import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  createAgreementTypeAsync,
  updateAgreementTypeAsync,
} from "../../../../../slices/service/agreement-types";
import { Form, Formik } from "formik";
import { PMField } from "../../../../utils/PMField";
import { useEffect, useState } from "react";
import { TWclassNames } from "../../../../utils/Div";

export const ServiceAgreementTypeSimpleForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { item, onClose, onSelect, closeButton } = props;

  const [loading, setLoading] = useState(false);

  // Const change loading state if props.loading changes
  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  const createOrUpdateDispatch = (data) => {
    if (item?.id) {
      const res = dispatch(updateAgreementTypeAsync(data));
      return res;
    } else {
      const res = dispatch(createAgreementTypeAsync(data));
      return res;
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          id: item?.id,
          title: item?.title,
          description: item?.description,
        }}
        validationSchema={Yup.object({
          title: Yup.string().max(255).required(t("title.required")),
          description: Yup.string().max(500),
        })}
        onSubmit={(values, helpers) => {
          try {
            dispatchWithToast(
              createOrUpdateDispatch(values),
              onSelect,
              onClose
            );
            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
          } catch (err) {
            toast.error("Something went wrong...");
            console.error(err);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <div className="grid grid-cols-6 gap-6 mt-10">
              <div className="col-span-6 sm:col-span-6">
                <PMField name="title" type="text" label="Title" {...formik} />
              </div>
              <div className="col-span-6 sm:col-span-6">
                <PMField
                  multiline
                  name="description"
                  type="text"
                  label="Description"
                  {...formik}
                />
              </div>
            </div>

            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              {closeButton && (
                <button
                  type="button"
                  className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                  onClick={onClose}
                >
                  {t("Close")}{" "}
                </button>
              )}

              <button
                type="button"
                className={TWclassNames(
                  closeButton ? "col-span-1" : "col-span-2",
                  "inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                )}
                onClick={formik.submitForm}
              >
                {t("Save")}{" "}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

ServiceAgreementTypeSimpleForm.defaultProps = {
  closeButton: true,
  item: {},
  loading: false,
  onClose: () => {},
  onSelect: () => {},
};

ServiceAgreementTypeSimpleForm.propTypes = {
  closeButton: PropTypes.bool,
  item: PropTypes.object,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
};
