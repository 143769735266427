import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchReportTypes,
  updateReportType,
  createReportType,
  deleteReportType,
  fetchReportType,
} from "../../api/service/settings";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  report_type: {},
};

export const reportTypesController = {
  filters: [],
  page: 0,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const deleteReportTypeAsync = createAsyncThunk(
  "report_types/deleteReportTypeAsync",
  async (payload) => {
    let response = await deleteReportType(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createReportTypeAsync = createAsyncThunk(
  "report_types/createReportTypeAsync",
  async (payload) => {
    let response = await createReportType(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const updateReportTypeAsync = createAsyncThunk(
  "report_types/updateReportTypeAsync",
  async (payload) => {
    let response = await updateReportType(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);
export const getReportTypes = createAsyncThunk(
  "report_types/getReportTypes",
  async (controller = reportTypesController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchReportTypes(controller);

    // If errors, reject and return response
    if (response.error) return rejectWithValue(response);

    // If all good, return response.
    return response;
  }
);

export const getReportTypeAsync = createAsyncThunk(
  "products/getReportTypeAsync",
  async (id, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchReportType(id);

    // If errors, reject and return response
    if (response.error) return rejectWithValue(response);

    // If all good, return response.
    return response;
  }
);

const reportTypeSlice = createSlice({
  name: "report_types",
  initialState,
  reducers: {
    resetReportType(state) {
      state.report_type = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReportTypes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getReportTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(getReportTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong....is controller missing?"];
      })
      .addCase(updateReportTypeAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateReportTypeAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data[index] = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(updateReportTypeAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update report type",
        ];
      })
      .addCase(createReportTypeAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createReportTypeAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
        });
      })
      .addCase(createReportTypeAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update report type",
        ];
      })
      .addCase(deleteReportTypeAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteReportTypeAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(deleteReportTypeAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to update report type",
        ];
      })
      .addCase(getReportTypeAsync.pending, (state, action) => {
        state.isLoading = true;
        state.report_type = {};
      })
      .addCase(getReportTypeAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.report_type = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(getReportTypeAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.report_type = {};
        state.errors = ["Something went wrong when trying to update product."];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = reportTypeSlice;

export const { resetReportType } = reportTypeSlice.actions;

export default reportTypeSlice;

export const selectReportTypeById = (state, reportTypeId) =>
  state.report_types.data.find((type) => type.id == reportTypeId);
