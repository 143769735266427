import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import Select from "react-select";
import { PMField } from "../../../utils/PMField";
import { ProjectSelector } from "../../../utils/moduleSelectors/projectSelector";
import { ActivitySelector } from "../../../utils/moduleSelectors/activitySelector";

const projects = [
  { value: "project1", label: "Project 1" },
  { value: "project2", label: "Project 2" },
];

const activities = [
  { value: "activity1", label: "Activity 1" },
  { value: "activity2", label: "Activity 2" },
];

const TimesheetInput = ({ value, onChange }) => {
  return (
    <input
      type="text"
      value={value}
      onChange={() => onChange}
      className="m-0 p-0 border-0 border-b-2 border-blue-400 bg-transparent w-full text-center"
    />
  );
};

export const HourSheetTable = () => {
  const [data, setData] = useState([
    {
      project: {
        id: 1,
        displayTitle: "Project 1",
      },
      activity: {
        id: 1,
        displayTitle: "Activity 1",
      },
      monday: null,
      tuesday: null,
      wednesday: null,
      thursday: null,
      friday: null,
      saturday: null,
      sunday: null,
    },
    // Add more initial rows if needed
  ]);

  const columns = useMemo(
    () => [
      {
        Header: "Project",
        accessor: "project",
        Cell: ({ row, value }) => (
          <div className="min-w-[200px] text-left">
            {value?.id ? (
              value?.displayTitle
            ) : (
              <ProjectSelector
                label={""}
                options={projects}
                onChange={(value) =>
                  updateRowSelector(row.index, value, "project")
                }
              />
            )}
          </div>
        ),
      },
      {
        Header: "Activity",
        accessor: "activity",
        Cell: ({ row, value }) => (
          <div className="min-w-[200px] text-left">
            {value?.id ? (
              value?.displayTitle
            ) : (
              <ActivitySelector
                options={activities}
                defaultValue={activities.find((a) => a.value === value)}
              />
            )}
          </div>
        ),
      },
      {
        Header: "Monday",
        accessor: "monday",
        Cell: ({ value }) => (
          <TimesheetInput type="text" value={value} name={"sunday"} />
        ),
      },
      {
        Header: "Tuesday",
        accessor: "tuesday",
        Cell: ({ value }) => (
          <TimesheetInput type="text" value={value} name={"sunday"} />
        ),
      },
      {
        Header: "Wednesday",
        accessor: "wednesday",
        Cell: ({ value }) => (
          <TimesheetInput type="text" value={value} name={"sunday"} />
        ),
      },
      {
        Header: "Thursday",
        accessor: "thursday",
        Cell: ({ value }) => (
          <TimesheetInput type="text" value={value} name={"sunday"} />
        ),
      },
      {
        Header: "Friday",
        accessor: "friday",
        Cell: ({ value }) => (
          <TimesheetInput type="text" value={value} name={"sunday"} />
        ),
      },
      {
        Header: "Saturday",
        accessor: "saturday",
        Cell: ({ value }) => (
          <TimesheetInput type="text" value={value} name={"sunday"} />
        ),
      },
      {
        Header: "Sunday",
        accessor: "sunday",
        Cell: ({ value }) => (
          <TimesheetInput type="text" value={value} name={"sunday"} />
        ),
      },
    ],
    [data]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  function addNewRow() {
    // Add new row to data
    const newRow = {
      project: null,
      activity: null,
      monday: null,
      tuesday: null,
      wednesday: null,
      thursday: null,
      friday: null,
      saturday: null,
      sunday: null,
    };

    // Update the table
    setData([...data, newRow]);
  }

  const handleInputChange = (e, index, day) => {
    const newData = [...data];
    newData[index][day] = e.target.value;
    setData(newData);
  };

  const updateRowSelector = (index, value, key) => {
    const newData = [...data];
    newData[index][key] = value;
    setData(newData);
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <div>
      <table {...getTableProps()} className="rounded-xl">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup?.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  key={column?.id}
                  {...column.getHeaderProps()}
                  className="text-left px-2"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                key={row?.id}
                {...row.getRowProps()}
                className="bg-gray-50 hover:bg-gray-200 transition-colors duration-200 ease-in-out"
              >
                {row.cells.map((cell) => (
                  <td
                    key={cell?.id}
                    {...cell.getCellProps()}
                    className="px-4 py-2 w-[5px] text-center"
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="mt-10">
        {/* Add new row button */}
        <button
          onClick={() => addNewRow()}
          className="border-b-2 border-blue-400 text-black px-4 py-2 rounded-md hover:bg-gray-300 transition-colors duration-200 ease-in-out"
        >
          Add new row
        </button>
      </div>
    </div>
  );
};
