import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createserviceAgreementAsync,
  updateServiceAgreementAsync,
} from "../../../../slices/service/agreements";
import { dispatchWithToast, mapErrors } from "../../../Utils";
import { PMField } from "../../../utils/PMField";
import { SystemSelector } from "../../../utils/moduleSelectors/systemSelector";
import { CustomerSelector } from "../../../utils/moduleSelectors/customerSelector";
import { ProjectSelector } from "../../../utils/moduleSelectors/projectSelector";
import { ServiceAgreementTypeSelector } from "../../../utils/moduleSelectors/serviceAgreementTypeSelector";
import { ContactsSelector } from "../../../utils/moduleSelectors/contactSelector";
import PMListbox from "../../../utils/PMListbox";
import { serviceAgreementIntervals } from "./utils";

export const ServiceAgreementSimpleForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { item, onClose, customer, project, contact, fields, onRefresh } =
    props;

  const createOrUpdateDispatch = (data) => {
    if (item?.id) {
      const res = dispatch(updateServiceAgreementAsync(data));
      return res;
    } else {
      const res = dispatch(createserviceAgreementAsync(data));
      // .then((res) => {
      //   toast.dismiss();
      //   onClose;
      //   navigate(`/dashboard/items/${res.payload.id}`)
      // })
      return res;
    }
  };

  const handleRefresh = (data) => {
    if (onRefresh) {
      onRefresh(data);
    }

    if (data?.id) {
      navigate("/dashboard/service/agreements/" + data?.id);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          id: item?.id,
          order_date: item?.order_date || new Date().toISOString().slice(0, 10),
          agreement_number: item?.agreement_number,
          customer_id: item?.customer?.id || customer?.id,
          project_id: item?.project?.id,
          agreement_type_id: item?.agreement_type?.id,
          interval_id: item?.interval?.id,
          agreement_start_date:
            item?.agreement_start_date || new Date().toISOString().slice(0, 10),
          system_id: item?.system?.id,
        }}
        validationSchema={Yup.object({
          order_date: Yup.date().required(t("Order date is required")),
          customer_id: Yup.string().required(t("Order date is required"), () =>
            toast.error("Customer ID is missing")
          ),
          agreement_type_id: Yup.string().required(t("Type is required")),
          interval_id: Yup.string().required(t("Interval is required")),
        })}
        onSubmit={(values, helpers) => {
          try {
            dispatchWithToast(
              createOrUpdateDispatch(values),
              handleRefresh,
              onClose(true)
            );
            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
          } catch (err) {
            toast.error("Something went wrong...");
            console.error(err);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <div className="grid grid-cols-6 gap-6 mt-10">
              <div className="col-span-6 sm:col-span-3">
                <PMField
                  name="order_date"
                  type="date"
                  label="Order Date"
                  {...formik}
                />
              </div>

              {!props.customer?.id && (
                <div className="col-span-6 sm:col-span-3">
                  <CustomerSelector
                    selected={item?.customer?.id ? item?.customer : customer}
                    {...formik}
                  />
                </div>
              )}

              <div className="col-span-6 sm:col-span-3">
                <ProjectSelector
                  selected={item?.project?.id ? item?.project : null}
                  {...formik}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <SystemSelector
                  module="CUSTOMER"
                  module_param={
                    item?.customer?.id
                      ? item?.customer?.id
                      : formik.values.customer_id
                  }
                  fields={fields}
                  selected={item?.system}
                  {...formik}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <ContactsSelector
                  selected={item?.contact?.id ? item?.contact : contact}
                  module_param={item?.customer?.id}
                  name="contact_id"
                  {...formik}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <ServiceAgreementTypeSelector
                  selected={item?.type?.id ? item?.type : null}
                  {...formik}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <PMListbox
                  label={t("Interval")}
                  name="interval_id"
                  options={serviceAgreementIntervals}
                  selected={serviceAgreementIntervals?.interval_id}
                  {...formik}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <PMField
                  name="agreement_start_date"
                  type="date"
                  label="Agreement Start Date"
                  {...formik}
                />
              </div>

              <div className="col-span-6 sm:col-span-6">
                <PMField
                  name="description"
                  type="text"
                  label="Description"
                  multiline
                  {...formik}
                />
              </div>
            </div>

            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={onClose}
              >
                {t("Close")}{" "}
              </button>

              <button
                type="button"
                className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={() => formik.submitForm()}
              >
                {t("Save")}{" "}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

ServiceAgreementSimpleForm.defaultProps = {
  project: {},
  customer: {},
  item: {},
  contact: {},
  onClose: () => {},
};

ServiceAgreementSimpleForm.propTypes = {
  project: PropTypes.object,
  customer: PropTypes.object,
  onClose: PropTypes.func,
  item: PropTypes.object,
  contact: PropTypes.object,
};
