import PropTypes from 'prop-types';
import { AlertTitle, Avatar, Box, ListItem, ListItemText, Typography } from '@mui/material';

export const PropertyListItem = (props) => {
    const { onClick, key, align, image, icon, alt, children, component, value, label, avatar, ...other } = props;
    
    return (
        <div 
            onClick={onClick}
            key={key}
            className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 hover:bg-gray-200"
        >
            <div className="flex-shrink-0">
            <Avatar src={image ? image : ''} sx={{ backgroundColor: '#2196f3'}} alt={alt}>
                    {icon ? <props.icon className="h-5 w-5" /> : ''}
            </Avatar>

            </div>
            <div className="cursor-pointer min-w-0 flex-1">
            <div className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-medium text-gray-900">{label}</p>
                <p className="truncate text-sm text-gray-500">{value}</p>
            </div>
            </div>
        </div>
        );
};

PropertyListItem.propTypes = {
    align: PropTypes.string,
        component: PropTypes.elementType,
    children: PropTypes.node,
    label: PropTypes.string.isRequired,
    value: PropTypes.string
};
