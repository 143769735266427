import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { mapErrors } from "../../../Utils";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { updateReportAsync } from "../../../../slices/service/reports";
import { useDispatch } from "react-redux";
import { PMField } from "../../../utils/PMField";
import { Link } from "react-router-dom";
import { OrdersStacklist } from "../../orders/orders-stack-list";
import { PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  fetchTripletexEvents,
  subscribeTripletexEvent,
  unsubscribeTripletexEvent,
  updateCompany,
} from "../../../../api/company";
import { IntegrationEventStacklist } from "./events-stack-list";
import axios from "axios";
import PMSwitch from "../../../utils/PMSwitch";

export const CompanyIntegrationDialog = (props) => {
  const { onLoading, onClose, open, isLoading, company } = props;

  const { label, description, name, initialFormValues } = props.integration;
  const [readOnly, setReadOnly] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const integrationValues = initialFormValues.reduce((acc, item) => {
    if (item.name) {
      acc[item.name] = item.value;
    }
    return acc;
  }, {});

  const [tripletexEvents, setTripletexEvents] = useState([]);

  useEffect(() => {
    if (name === "tripletex") {
      onLoading(true);
      // Fetch Sub events
      fetchTripletexEvents()
        .then((res) => {
          setTripletexEvents(res.data);
          onLoading(false);
        })
        .catch((err) => {
          toast.error(t("Could not fetch Tripletex subscription events"));
          onLoading(false);
        });
    }
  }, [open]);

  const handleSubscribe = (event) => {
    if (!event.isSubscribed) {
      subscribeTripletexEvent(event.event)
        .then((res) => {
          toast.success("You are now subscribed to this event.");
          // Update state with isSubscribed to true
          setTripletexEvents(
            tripletexEvents.map((item) => {
              if (item.event === event.event) {
                return {
                  ...item,
                  isSubscribed: true,
                };
              }
              return item;
            })
          );
        })
        .catch((err) => {
          toast.error("Could not subscribe to this event.");
        });
    } else {
      unsubscribeTripletexEvent(event.event)
        .then((res) => {
          toast.success("You are now unsubscribed to this event.");
          // Update state with isSubscribed to true
          setTripletexEvents(
            tripletexEvents.map((item) => {
              if (item.event === event.event) {
                return {
                  ...item,
                  isSubscribed: false,
                };
              }
              return item;
            })
          );
        })
        .catch((err) => {
          toast.error("Could not unsubscribe to this event.");
        });
    }
  };

  const initialValues = {
    ...integrationValues,
    submit: null,
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="flex items-center text-lg font-medium leading-6 text-gray-900"
                    >
                      <div className="w-[60%]">{label}</div>
                      <div className="w-[40%] relative right-0 text-right">
                        <button
                          onClick={() => setReadOnly(!readOnly)}
                          type="button"
                          className="inline-flex items-center rounded-md border border-transparent bg-blue-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          {readOnly ? (
                            <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                          ) : (
                            <PencilIcon
                              className="h-4 w-4 color-"
                              aria-hidden="true"
                            />
                          )}
                          <span className="ml-2">
                            {readOnly ? t("edit") : t("read_only")}
                          </span>
                        </button>
                      </div>
                    </Dialog.Title>
                    <p>{description}</p>

                    {isLoading ? (
                      <ResourceLoading />
                    ) : (
                      <>
                        <Formik
                          initialValues={initialValues}
                          // validationSchema={Yup.object({
                          //   status: Yup.string().required(
                          //     t("You need to pick a status option")
                          //   ),
                          // })}
                          onSubmit={(values, helpers) => {
                            try {
                              toast.promise(updateCompany(values), {
                                loading: t("Saving...."),
                                success: (data) => {
                                  if (data?.status !== 200) {
                                    throw new Error(
                                      mapErrors(data?.data?.errors)
                                    );
                                  }
                                  if (data?.status === 500) {
                                    throw new Error("Server error");
                                  }
                                  props.onClose();
                                  props.onRefresh(data?.data?.data);
                                  return t("Data was saved!");
                                },
                                error: (error) => {
                                  return t(
                                    "Something went wrong. Could not save data!"
                                  );
                                },
                              });
                              helpers.setStatus({ success: true });
                              helpers.setSubmitting(false);
                              helpers.resetForm(initialValues);
                            } catch (err) {
                              toast.error("Something went wrong...");
                              console.error(err);
                              helpers.setStatus({ success: false });
                              helpers.setErrors({ submit: err.message });
                              helpers.setSubmitting(false);
                            }
                          }}
                        >
                          {(formik) => (
                            <Form>
                              <div className="grid grid-cols-6 gap-6 mt-10">
                                <div className="col-span-6 sm:col-span-6">
                                  <h3 className="font-semibold">Events</h3>
                                  <IntegrationEventStacklist
                                    items={tripletexEvents}
                                    isLoading={isLoading}
                                    onLoading={onLoading}
                                    onSubscribe={handleSubscribe}
                                  />
                                </div>
                                {initialFormValues?.map((item, idx) => (
                                  <div
                                    key={idx}
                                    className="col-span-6 sm:col-span-6"
                                  >
                                    {item?.type === "switch" ? (
                                      <PMSwitch
                                        name={item?.name}
                                        label={t(item?.label)}
                                        {...formik}
                                      />
                                    ) : (
                                      <PMField
                                        name={item?.name}
                                        type={item?.type ? item?.type : "text"}
                                        label={t(item?.label)}
                                        readOnly={
                                          item?.disabled
                                            ? item?.disabled
                                            : readOnly
                                        }
                                        {...formik}
                                      />
                                    )}
                                  </div>
                                ))}
                              </div>
                              <div className="mt-20 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                <button
                                  type="button"
                                  className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                  onClick={onClose}
                                >
                                  {t("Close")}{" "}
                                </button>

                                <button
                                  type="button"
                                  className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                  onClick={formik.submitForm}
                                >
                                  {t("Update")}
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
