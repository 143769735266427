import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CustomersTable } from "../../../components/dashboard/customers/customers-table";
import { CustomersFilter } from "../../../components/dashboard/customers/customers-filter";
import { getCountsAsync } from "../../../slices/inventory/count";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import { PlusIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import { getProjectsAsync } from "../../../slices/project";
import { ProjectsTable } from "../../../components/dashboard/projects/projects-table";
import ProjectDialog from "../../../components/dashboard/projects/dialog";
import { debounce } from "lodash";
import { useSearchParams } from "react-router-dom";
import { updateUrlWithException } from "../../../components/Utils";
import { ProductsFilter } from "../../../components/dashboard/products/products-filter";

export const ProjectList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const itemState = useSelector((store) => {
    return store.projects;
  });

  const { isLoading } = itemState;
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [controller, setController] = useState({
    filters: [],
    per_page: 10,
    page: searchParams.get("page") ? searchParams.get("page") : 1,
    query: searchParams.get("query") ? searchParams.get("query") : "",
    sort: searchParams.get("sort") ? searchParams.get("sort") : "desc",
    sortBy: searchParams.get("sortBy")
      ? searchParams.get("sortBy")
      : "project_number",
    view: searchParams.get("view") ? searchParams.get("view") : "all",
  });

  // Update all params from controller except filters (dont't touch filters)
  useEffect(() => {
    updateUrlWithException(
      // These will be updated
      {
        page: controller.page,
        query: controller.query,
        sort: controller.sort,
        sortBy: controller.sortBy,
        view: controller.view,
        // filters: controller.filters,
      },
      // This will be preserved
      "filters"
    );
  }, [controller]);

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery,
    });
  };

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const [projectDialogOpen, setProjectDialogOpen] = useState(false);

  const debouncedDispatchAction = debounce((controller) => {
    dispatch(getProjectsAsync(controller));
  }, 250);

  useEffect(() => {
    // Trigger the debounced dispatch action
    debouncedDispatchAction(controller);

    // Clean the debounced function on component unmount.
    return () => {
      debouncedDispatchAction.cancel();
    };
  }, [controller, dispatch]);

  const headerActions = [{}];

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={"Projects"}
        meta={[]}
        mainButton={{
          label: "New",
          action: () => setProjectDialogOpen(true),
          path: null,
          color: "",
          icon: PlusIcon,
        }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            {/* <CustomersStats /> */}
            <ProductsFilter
              className={"mt-10"}
              disabled={isLoading}
              onQueryChange={handleQueryChange}
              query={controller.query}
              selectedItems={selectedItems}
              // sortByOptions={sortByOptions}
              // onPerPageChange={handlePagePageChange}
              // perPageOptions={[10, 25, 50, 100, 250]}
              // pageOptions={pageOptions({
              //   perPage: controller.per_page,
              //   totalCount: productState.count,
              // })}
              fromToDate={false}
              onFromToDateChange={(data) => {
                setController({
                  ...controller,
                  date_from: data?.date_from,
                  date_to: data?.date_to,
                });
              }}
              // initialFilters={initialFilters}
              // onFilterChange={handleFilterChange}
              // defaultSelectedOptions={[
              //   { name: "status", value: "NEW" },
              //   { name: "status", value: "ONGOING" },
              // ]}
            />

            <ProjectsTable
              itemState={itemState}
              itemsCount={itemState?.totalCount}
              currentCount={itemState?.data?.length}
              page={controller.page}
              perPage={controller.per_page}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </DashboardHeader>

      <ProjectDialog
        open={projectDialogOpen}
        onClose={() => setProjectDialogOpen(false)}
        readOnly={false}
        // selected={{ report.customer }}
        // Only for testing
        // selected={item?.customer}
        // onRefresh={() => getCase()}
      />
    </>
  );
};
