import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Table } from "../../utils/PMTable/Table";
import { TableBody } from "../../utils/PMTable/TableBody";
import { TableCell } from "../../utils/PMTable/TableCell";
import { TableHead } from "../../utils/PMTable/TableHead";
import { TableHeadRow } from "../../utils/PMTable/TableHeadRow";
import { TableRow } from "../../utils/PMTable/TableRow";
import { PMPagination } from "../../utils/PMPagination";
import { Navigate, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useSettings } from "../../../contexts/settings-context";
import { ResourceEmpty } from "../../utils/ResourceEmpty";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { OrderStatus } from "./utils";
import { CustomerTitle, LoadingRows, ProjectTitle } from "../../Utils";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const OrdersTable = (props) => {
  const { priceFormatter } = useSettings();

  const { itemState, onPageChange, itemsCount, page, perPage, currentCount } =
    props;

  const { isLoading, errors, data } = itemState;

  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);

  useLayoutEffect(() => {
    // const isIndeterminate = selectedPeople.length > 0 && selectedPeople.length < people.length
    // setChecked(selectedPeople.length === people.length)
    // setIndeterminate(isIndeterminate)
    // checkbox.current.indeterminate = isIndeterminate
  }, [selectedPeople]);

  function toggleAll() {
    // setSelectedPeople(checked || indeterminate ? [] : people)
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const navigate = useNavigate();

  function renderRows() {
    return data?.map((item, index) => {
      return (
        <tr
          key={item.id}
          onClick={() => navigate("/dashboard/orders/" + item.id)}
        >
          <td></td>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <div className="">
              <div className="font-medium text-gray-900">
                {item.order_number}
              </div>
              <div className="text-gray-500">{item.order_date}</div>
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <div className="text-gray-900 text-sm">
              <CustomerTitle customer={item?.customer} />
            </div>
            <div className="text-gray-900 text-xs">
              <ProjectTitle project={item?.project} />
            </div>
            <div className="text-gray-500 text-sm">{item.project?.title}</div>
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <OrderStatus order={item} />
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {priceFormatter.format(item?.total_amount)}
          </td>
        </tr>
      );
    });
  }

  return (
    <>
      <Table>
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            ></th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 "
            >
              <div className="flex flex-col">
                <span>{t("order.number")}</span>
                <span>{t("order.date")}</span>
              </div>
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 flex flex-col"
            >
              <span>{t("customer")}</span>
              <span>{t("project")}</span>
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {t("status")}
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {t("order.total")}
            </th>
          </tr>
        </thead>
        <TableBody>
          {isLoading ? (
            <>
              <LoadingRows cols={4} checkboxCell={true} />
              <LoadingRows cols={4} checkboxCell={true} />
              <LoadingRows cols={4} checkboxCell={true} />
              <LoadingRows cols={4} checkboxCell={true} />
              <LoadingRows cols={4} checkboxCell={true} />
              <LoadingRows cols={4} checkboxCell={true} />
              <LoadingRows cols={4} checkboxCell={true} />
              <LoadingRows cols={4} checkboxCell={true} />
              <LoadingRows cols={4} checkboxCell={true} />
              <LoadingRows cols={4} checkboxCell={true} />
            </>
          ) : !data ? (
            <ResourceEmpty />
          ) : (
            renderRows()
          )}
        </TableBody>
      </Table>
      <PMPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        perPage={perPage}
        rowsCount={itemsCount}
        totalCount={itemsCount}
        currentCount={currentCount}
        showCount={true}
      />
    </>
  );
};

OrdersTable.defaultProps = {
  page: 1,
  itemState: [],
  itemsCount: 0,
  selectedItems: [],
  sort: "desc",
  sortBy: "createdAt",
};
