
import {
    ClipboardDocumentIcon
  } from '@heroicons/react/24/outline'
import { HomePage } from '../pages/HomePage'
import { Logout } from '../pages/authentication/Logout'
import Login from '../pages/authentication/Login'


export const AppRoutes = [
    // { title: 'Logout', path: '/authentication/logout', secured: false, sidebar: false, icon: '', element: Logout },
    { title: 'Login', path: '/authentication/login', secured: false, sidebar: false, icon: '', element: Login },
    { title: 'Register', path: '/authentication/register', secured: false, sidebar: false, icon: '', element: Login },
]
