import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { TWclassNames } from "./Div";
import {
  CheckBadgeIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { Check } from "@mui/icons-material";

export const PMNotice = (props) => {
  const { title, description, severity } = props;

  const severityClasses = {
    info: "bg-blue-50 text-blue-700",
    success: "bg-green-50 text-green-700",
    warning: "bg-yellow-50 text-yellow-700",
    error: "bg-red-50 text-red-700",
    critical: "bg-red-50 text-red-700",
  };

  const severityIcons = {
    info: QuestionMarkCircleIcon,
    success: CheckBadgeIcon,
    warning: QuestionMarkCircleIcon,
    error: QuestionMarkCircleIcon,
    critical: QuestionMarkCircleIcon,
  };

  const SeverityIcon = ({ severity }) => {
    const Icon = severityIcons[severity];
    return <Icon className="h-7 w-7" aria-hidden="true" />;
  };

  return (
    <div
      className={TWclassNames("rounded-xl p-4 my-5", severityClasses[severity])}
    >
      <div className="flex">
        <div className="flex-shrink-0 py-3">
          <SeverityIcon severity={severity} />
        </div>

        <div className="ml-3 flex-1 md:flex md:justify-between">
          <div className="flex flex-col">
            <h3
              className={TWclassNames("font-semibold", !description && "mt-3")}
            >
              {title}
            </h3>
            {description && <p className="text-sm">{description}</p>}
          </div>

          {/* <p className="mt-3 text-sm md:mt-0 md:ml-6">
            <Link
              to="/kontakt"
              className="flex whitespace-nowrap font-medium text-white hover:text-blue-600 px-3 py-3 rounded-xl bg-blue-400 "
            >
              <PhoneIcon className="h-5 w-5 mr-2" />
              Kontakt oss
            </Link>
          </p> */}
        </div>
      </div>
    </div>
  );
};

PMNotice.propTypes = {
  severity: PropTypes.oneOf([
    "info",
    "success",
    "warning",
    "error",
    "critical",
  ]),
};

PMNotice.defaultProps = {
  title: "",
  description: "",
  severity: "info",
};
