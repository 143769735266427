import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { mapErrors } from "../../../Utils";
import { createNote } from "../../../../api/notes";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import PMCombobox from "../../../utils/PMCombobox";
import { reportStatusesOptions } from "./utils";
import { updateReportAsync } from "../../../../slices/service/reports";
import { useDispatch } from "react-redux";
import PMSwitch from "../../../utils/PMSwitch";
import PMListboxFormik from "../../../utils/PMListboxFormik";
import { getPdfReport, printReport } from "../../../../api/service/report";
import * as FileSaver from "file-saver";
export const ReportPrintDialog = (props) => {
  const { onLoading, onClose, open, report } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    id: report?.id,
    printReport: true,
    print_report_type: "",
    submit: null,
  };

  const handlePrint = async (payload) => {
    return await printReport(payload);
  };

  const [downloadData, setDownloadData] = useState();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [reportType, setReportType] = useState(null);

  useEffect(() => {
    if (downloadData) {
      FileSaver.saveAs(
        downloadData,
        `rapport-${report?.id}-${report?.report_type}.pdf`
      );
    }
  }, [downloadData]);

  const fetchPdfReport = async () => {
    setIsLoading(true);
    if (!report?.id) return false;

    const payload = {
      id: report?.id,
      params: {
        printReportType: reportType,
      },
    };
    await getPdfReport(payload)
      .then((response) => {
        setPdfUrl(response.data.pdf_url);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (reportType) {
      fetchPdfReport();
    }
  }, [reportType]);

  const handleReportTypeChange = (data) => {
    setReportType(data.value);
  };

  const handleDownloadPDF = () => {
    // Open pdfUrl in another window
    window.open(pdfUrl, "_blank");
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 "
                    >
                      {t("Print Report")}
                    </Dialog.Title>

                    {isLoading ? (
                      <ResourceLoading />
                    ) : !report?.id ? (
                      "Missing ID"
                    ) : (
                      <Formik
                        initialValues={initialValues}
                        onSubmit={(values, helpers) => {
                          try {
                            toast.promise(handlePrint(values), {
                              loading: t("Saving...."),
                              success: (data) => {
                                if (data?.status === 500) {
                                  throw new Error("Server error");
                                }
                                if (!data?.status === 200) {
                                  throw new Error(
                                    mapErrors(data?.data?.errors)
                                  );
                                }
                                props.onClose();
                                props.onRefresh(data?.payload);
                                return t("Data was saved!");
                              },
                              error: (error) => {
                                console.log(error);
                                return t(
                                  "Something went wrong. Could not save data!"
                                );
                              },
                            });
                            helpers.setStatus({ success: true });
                            helpers.setSubmitting(false);
                            helpers.resetForm(initialValues);
                          } catch (err) {
                            toast.error("Something went wrong...");
                            console.error(err);
                            helpers.setStatus({ success: false });
                            helpers.setErrors({ submit: err.message });
                            helpers.setSubmitting(false);
                          }
                        }}
                      >
                        {(formik) => (
                          <Form>
                            <div className="grid grid-cols-6 gap-6 mt-10">
                              <div className="col-span-6 sm:col-span-6">
                                <PMListboxFormik
                                  label={t("Report Type")}
                                  name="print_report_type"
                                  onChange={handleReportTypeChange}
                                  options={[
                                    {
                                      name: "print_report_type",
                                      value: "",
                                      label: t("Not Chosen"),
                                    },
                                    {
                                      name: "print_report_type",
                                      value: "report",
                                      label: t("Report"),
                                    },
                                    {
                                      name: "print_report_type",
                                      value: "customer_report",
                                      label: t("Customer Report"),
                                    },
                                    {
                                      name: "print_report_type",
                                      value: "note",
                                      label: t("Note"),
                                    },
                                  ]}
                                  {...formik}
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-6 mb-20">
                                {pdfUrl && (
                                  <button
                                    type="button"
                                    className="w-full justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                    onClick={handleDownloadPDF}
                                  >
                                    {t("Download")}
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className="mt-20 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                              <button
                                type="button"
                                className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                onClick={onClose}
                              >
                                {t("Close")}{" "}
                              </button>

                              {/* <button
                                type="button"
                                className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                onClick={formik.submitForm}
                              >
                                {t("update")}
                              </button> */}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
