import React, { useEffect } from "react";
import { Modal, Box } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function SimpleModal(props) {
  const [open, setOpen] = React.useState(false);

  const body = (
    <Box id="modal-div" sx={style}>
      {props.body()}
    </Box>
  );

  return (
    <div>
      <Modal
        {...props}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
