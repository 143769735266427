import { Dialog, Transition } from "@headlessui/react";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import { LoadingButton } from "@mui/lab";
import { Form, Formik } from "formik";
import { Fragment, useState } from "react";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { toast } from "react-hot-toast";
import PMListboxFormik from "../../utils/PMListboxFormik";
import { caseStatusOptions } from "../cases/utils";
import { useTranslation } from "react-i18next";
import { textGeneratorApproachOptions } from "./utils";
import UppercaseFirstLetter, { axiosWithToast } from "../../Utils";
import { generateText } from "../../../api/ai";
import { on } from "events";

export const TextGeneratorDialog = (props) => {
  const {
    open,
    onClose,
    setOpen,
    text,
    setText,
    onGenerate,
    module,
    module_param,
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="flex items-center text-lg font-medium leading-6 text-gray-900"
                  ></Dialog.Title>

                  {isLoading ? (
                    <ResourceLoading />
                  ) : (
                    <Formik
                      initialValues={{
                        module: module,
                        module_param: module_param,
                        text: text,
                        function: props.function,
                        // length: null, // length of text to be generated
                        // language: "nb-NO", // language of text to be generated
                        // headings: null, // headings of text to be generated
                        // format: null, // format of text to be generated
                        // font: null, // font of text to be generated
                        submit: null,
                      }}
                      //   validationSchema={Yup.object({
                      //     project_number: Yup.string(),
                      //   })}
                      onSubmit={(values, helpers) => {
                        try {
                          axiosWithToast(
                            generateText(values),
                            () => null,
                            null,
                            onGenerate
                          );
                          // helpers.setStatus({ success: true });
                        } catch (err) {
                          toast.error("Something went wrong...");
                          helpers.setStatus({ success: false });
                          helpers.setErrors({ submit: err.message });
                          // helpers.setSubmitting(false);
                        }
                      }}
                    >
                      {(formik) => (
                        <Form>
                          <div className="grid grid-cols-6 gap-6 mt-10 mb-10">
                            <div className="col-span-6 sm:col-span-6">
                              <PMListboxFormik
                                label={t("Approach")}
                                name="approach"
                                options={textGeneratorApproachOptions}
                                {...formik}
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-6">
                              <button
                                onClick={() => formik.submitForm}
                                className="w-full px-5 py-2 shadow rounded-xl bg-blue-400 font-semibold text-white hover:bg-blue-600"
                              >
                                <UppercaseFirstLetter
                                  text={t(props.function)}
                                />
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
