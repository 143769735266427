import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { ResourceEmpty } from "../../../utils/ResourceEmpty";
import { ResourceError } from "../../../utils/ResourceError";
import { useNavigate } from "react-router-dom";
import { getProjectFoldersAsync } from "../../../../slices/project/folder";

const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  // More people...
];

export const FoldersStackList = (props) => {
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);

  const filters = props.filters ? props.filters : [];
  const { project } = props;
  useLayoutEffect(() => {
    const isIndeterminate =
      selectedPeople.length > 0 && selectedPeople.length < people.length;
    setChecked(selectedPeople.length === people.length);
    setIndeterminate(isIndeterminate);
    // checkbox.current.indeterminate = isIndeterminate
  }, [selectedPeople]);

  function toggleAll() {
    setSelectedPeople(checked || indeterminate ? [] : people);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const itemState = useSelector((store) => {
    return store.projectFolders;
  });

  const { isLoading, data, error, errors } = itemState;

  const [controller, setController] = useState({
    filters: [...filters],
    page: 1,
    query: "",
    sort: "desc",
    sortBy: "updated_at",
    view: "all",
  });

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery,
    });
  };

  useEffect(() => {
    const payload = {
      project_id: project?.id,
      filters: controller.filters,
      page: controller.page,
      query: controller.query,
      sort: controller.sort,
      sortBy: controller.sortBy,
      view: controller.view,
    };
    dispatch(getProjectFoldersAsync(payload));
  }, [dispatch, controller]);

  const [selectedCase, setSelectedCase] = useState(null);

  function handleOpenCase(id) {
    setSelectedCase(id);
  }

  const navigate = useNavigate();

  if (isLoading)
    return (
      <div className="mt-10">
        <ResourceLoading />
      </div>
    );
  if (error) return <ResourceError errors={errors} />;
  if (!data) return <ResourceEmpty />;

  return (
    <>
      {/* <PMPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        perPage={perPage}
        rowsCount={itemsCount}
        totalCount={itemsCount}
        currentCount={currentCount}
        showCount={false}
      /> */}
      {data && (
        <div className="overflow-hidden bg-white shadow sm:rounded-md">
          <ul role="list" className="divide-y divide-gray-200">
            {data?.map((item) => (
              <li
                key={item?.id}
                onClick={() =>
                  navigate(
                    "/dashboard/projects/" +
                      project?.id +
                      "/folders/" +
                      item?.id
                  )
                }
              >
                <a href="#" className="block hover:bg-gray-50">
                  <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <div className="grid">
                        <p className="truncate text-sm font-medium text-indigo-600">
                          {item?.displayTitle || "Missing??"}
                        </p>
                      </div>
                      <p className="truncate text-sm font-medium text-indigo-600"></p>

                      <div className="ml-2 flex flex-shrink-0"></div>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between"></div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

FoldersStackList.defaultProps = {
  page: 1,
  itemState: [],
  itemsCount: 0,
  selectedItems: [],
  sort: "desc",
  sortBy: "createdAt",
};
