import { useState } from "react";
import { useUserHasAccess } from "../../../../routes/roleOnly";
import { PMButton } from "../../../utils/PMButton";
import { ReportRectifyDialog } from "./report-rectify-dialog";
import CaseDialog from "../../cases/case-dialog";
import { Link } from "react-router-dom";

export const ReportRectifyDisplay = (props) => {
  const { report } = props;

  const hasAdminAccess = useUserHasAccess({
    role: "service",
    action: "process",
  });

  const [rectifyOpen, setRectifyOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [caseOpen, setCaseOpen] = useState(false);

  if (hasAdminAccess) {
    return (
      <>
        <div className="col-span-1 lg:col-span-5 row-span-2 bg-white">
          <div className="flex justify-between border shadow-xl rounded-md px-4 py-4">
            <div className="flex flex-col">
              <div className="text-md font-bold">
                {report?.case || report?.sequelReport
                  ? "Rapporten er under behandling"
                  : "Ønsker du å behandle rapporten videre?"}
              </div>
              <span className="text-sm">
                {report?.case ? "Gå til" : "Opprett"} sak for å behandle
                rapporten videre eller{" "}
                {report?.sequelReport ? "gå til" : "opprett en"} oppfølger til
                rapporten.
              </span>
            </div>
            <div id="rectify-buttons" className="grid grid-cols-2 gap-4">
              <PMButton
                className="col-span-1"
                onClick={() => setCaseOpen(true)}
              >
                {report?.case ? "Vis sak" : "Opprett sak"}
              </PMButton>
              {report?.sequelReport ? (
                <Link to={`/service/reports/${report?.sequelReport?.id}`}>
                  Gå til oppfølger
                </Link>
              ) : (
                <PMButton
                  className="col-span-1"
                  onClick={() => setRectifyOpen(true)}
                >
                  Opprett oppfølger
                </PMButton>
              )}
            </div>
          </div>
        </div>

        {/* CASE Dialog START */}
        <CaseDialog
          open={caseOpen}
          selected={report?.case}
          onClose={() => setCaseOpen(false)}
          report={report}
          customer={report.customer}
          contact={report.contact}
          title={"Oppfølging av rapport | " + report?.displayTitle}
        />
        {/* CASE Dialog END */}

        {/* Rectify Dialog START */}
        <ReportRectifyDialog
          report={report}
          open={rectifyOpen}
          isLoading={isLoading}
          // onLoading={(state) => setIsLoading(state)}
          onClose={() => setRectifyOpen(!open)}
          onRefresh={() => props.onRefresh()}
          {...props}
        />
        {/* Rectify Dialog END */}
      </>
    );
  }
};
