import axios from '../../../api/axios';

class UploadService {
    upload(files, module, moduleParam, onUploadProgress) {
        let formData = new FormData();

        // files.forEach(file => {
        //     formData.append('files', file)
        // })

        formData.append("file", files);

        formData.append("module", module);
        formData.append("module_param", moduleParam);

        return axios.post("/documents", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress,
        });
    }


    getFiles(props) {
        const params = {
            module: props.module,
            module_param: props.moduleParam
        }

        return axios.get("/documents", { params })
    }


    getDocument(id) {
        return axios.get(`/documents/${id}`);
    }




}

export default new UploadService();