import { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { validateYupSchema } from "formik";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// USED WITH FORMIK

export default function PMSwitch(props) {
  const { label, name, values, onChange, setFieldValue, ...other } = props;

  const checked = values && values[name] ? values[name] : props.checked;

  const handleChange = (checked) => {
    if (onChange) {
      onChange({
        target: {
          name: name,
          checked: checked,
        },
      });
    }

    if (setFieldValue) {
      setFieldValue(name, !values[name]);
    }
  };

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        disabled={props.disabled}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={handleChange}
        className={classNames(
          props.disabled ? "border-red-700" : "",
          checked ? "bg-blue-400" : "bg-gray-200",
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            checked ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3">
        <span className="text-sm font-medium text-gray-900">{label}</span>
        {/* <span className="text-sm text-gray-500">(Save 10%)</span> */}
      </Switch.Label>
    </Switch.Group>
  );
}
