import React, { useState, useEffect } from "react";
import { TableCell, TableRow, Checkbox, Grid, Box } from "@mui/material";
import { TreeView, TreeItem } from "@mui/lab";
import {
  ChevronRightIcon,
  ArrowsPointingOutIcon,
  ArrowUpOnSquareIcon,
  ArrowRightIcon,
  ArrowDownIcon,
} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import { BuildingOfficeIcon, UserIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { TWclassNames } from "../../../../utils/Div";
import { PMDialog } from "../../../../utils/PMDialog";
import { PMPagination } from "../../../../utils/PMPagination";
import { useDispatch } from "react-redux";
import { dispatchWithToast } from "../../../../Utils";
import { updateProductAsync } from "../../../../../slices/products";
import axios from "../../../../../api/axios";

export default function ProductCategorySelector(props) {
  const { open, module, module_param, onSelect, multiple, onClose } = props;

  const [state, setState] = useState({
    loading: false,
    error: false,
  });

  const testCategories = [
    {
      id: 1,
      name: "Parent 1",
      children: [
        {
          id: 2,
          name: "Child 1.1",
          children: [
            {
              id: 3,
              name: "Grandchild 1.1.1",
            },
            {
              id: 4,
              name: "Grandchild 1.1.2",
            },
          ],
        },
        {
          id: 5,
          name: "Child 1.2",
        },
      ],
    },
    {
      id: 6,
      name: "Parent 2",
    },
  ];

  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState([]);
  const [currentCount, setCurrentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchString, setSearchString] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectorAttributes, setSelectorAttributes] = useState({
    title: "",
    categorySelector: false,
    updateLink: null,
    getLink: null,
    getParam: null,
    getItemsLink: null,
    updateParams: [],
  });
  const [currentNavSelect, setCurrentNavSelect] = useState(1);
  const [currentView, setCurrentView] = useState("all");
  const [params, setParams] = useState({
    filters: [],
    page: 1,
    query: searchString,
    sort: "desc",
    sortBy: "updated_at",
    view: "all",
    ids: null,
    per_page: 10,
  });
  // Close modal
  const handleClose = () => {
    props.selectorCloseCallback();
  };

  const dispatch = useDispatch();

  // Get the items (example: products) to fill the selector with.

  const getItems = () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));

    const params = {
      filters: [],
      page: 1,
      query: searchString,
      sort: "desc",
      sortBy: "updated_at",
      view: "all",
      ids: null,
      per_page: 10,
    };

    axios
      .get(`/products/categories/list?parents=true`, {
        params: params,
      })
      .then((response) => {
        setCategories(response.data.data);
        setCurrentCount(response.data.currentCount);
        setTotalCount(response.data.totalCount);
        setState((prev) => ({
          ...prev,
          loading: false,
        }));
      })
      .catch((error) => {
        setState((prev) => ({
          ...prev,
          loading: false,
          error: true,
        }));
        toast.error("Could not get items");
      });
  };

  // When we select a product, we add it to selectedItems state.
  // If we uncheck a product, it will be removed from current state.
  const handleSelectItem = (e, checked, id) => {
    e.stopPropagation();
    e.preventDefault();

    const idToString = id.toString();

    if (multiple) {
      if (checked && !selectedItems.includes(id)) {
        setSelectedItems((prev) => [...prev, idToString]);
      } else if (selectedItems.includes(idToString)) {
        const filteredSelectedProducts = selectedItems.filter((value) => {
          return value !== idToString;
        });
        setSelectedItems(filteredSelectedProducts);
      }
    } else {
      setSelectedItems([idToString]);
    }
  };

  // Checking if a product is selected in the selector.
  const checkIfChecked = (id) => {
    const idToString = id.toString();
    if (selectedItems.length > 0) {
      if (selectedItems.includes(idToString)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  // Handle searching for products/ items
  // Fetches products depending on the searchString.
  const handleSearch = (e) => {
    e.preventDefault();
    getItems();
  };

  useEffect(() => {
    setSelectedItems(props.selectedItems);
  }, [props.selectedItems]);

  useEffect(() => {
    if (open) {
      getItems();
    }
  }, [open, searchString, params]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const tabs = [
    { id: 1, name: "All", icon: UserIcon, action: "all" },
    { id: 2, name: "Selected", icon: BuildingOfficeIcon, action: "selected" },
  ];

  const handleNavSelect = (id) => {
    const tab = tabs.find((tab) => tab.id === id);
    setCurrentNavSelect(id);
    setCurrentView(tab.action);
  };

  // Update params as currentView changes.

  const handleQueryChange = (query) => {
    setParams((prevState) => ({
      ...prevState,
      query: query,
    }));
  };

  const handlePageChange = (page) => {
    setParams((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const handleSave = () => {
    // if (!module === "product") {
    //   toast.error("Cannot set categories for this module");
    //   return;
    // }

    // if (!props.module_param) {
    //   toast.error("ID is not set? Cannot save");
    //   return;
    // }

    if (module === "product" && module_param) {
      const payload = {
        id: props.module_param,
        category_ids: selectedItems,
      };

      dispatchWithToast(
        dispatch(updateProductAsync(payload)),
        null,
        handleClose
      );
    } else if (onSelect) {
      // Get the data from selectedItems
      const ids = selectedItems.map((id) => parseInt(id));

      onSelect(ids);
      handleClose();
      return;
    } else {
      toast.error("Cannot save");
    }
  };

  const Category = ({
    category,
    handleSelectItem,
    isVisible,
    toggleVisibility,
  }) => {
    // const [isVisible, setIsVisible] = useState(false);

    // const toggleVisibility = (e) => {
    //   e.stopPropagation();
    //   e.preventDefault(); // prevent default behavior
    //   setIsVisible((prev) => !prev);
    // };

    return (
      <div className="ml-4 ">
        <div className="flex items-center rounded px-5 py-3 hover:bg-blue-200">
          <input
            type="checkbox"
            className="mr-2 rounded h-5 w-5"
            onChange={(e) => handleSelectItem(e, e.target.checked, category.id)}
            checked={checkIfChecked(category?.id)}
          />
          {category.children && category.children.length > 0 ? (
            <button
              onClick={(e) => {
                toggleVisibility(category?.id);
                e.stopPropagation();
              }}
              className="flex"
            >
              {/* {isVisible ? "Hide" : "Show"} */}
              <span className="py-1 px-2">
                {!isVisible ? (
                  <ArrowRightIcon className="w-5 h-5" />
                ) : (
                  <ArrowDownIcon className="h-5 w-5" />
                )}
              </span>
              <span>{category.title}</span>
            </button>
          ) : (
            <span>{category.title}</span>
          )}
        </div>
        {isVisible &&
          category.children &&
          category.children.map((child) => (
            <Category
              key={child.id}
              category={child}
              handleSelectItem={handleSelectItem}
              isVisible={visibleCategories.includes(child.id)}
              toggleVisibility={(id) => toggleVisibility(id)}
            />
          ))}
      </div>
    );
  };

  // All the categories that are selected

  // Turn selectedItems into integers instead of string

  const [visibleCategories, setVisibleCategories] = useState([]);

  const toggleVisibility = (categoryId) => {
    setVisibleCategories((prev) => {
      if (prev.includes(categoryId)) {
        return prev.filter((id) => id !== categoryId);
      } else {
        return [...prev, categoryId];
      }
    });
  };

  // const setVisibleFromChecked = () => {
  //   // Set all parent categories to visible if a child are checked
  //   selectedItems.forEach((item) => {
  //     console.log("item", parseInt(item));
  //     const category = categories.find(
  //       (category) => category.id === parseInt(item)
  //     );
  //     console.log("category", category);
  //     if (category) {
  //       if (category.parent_category) {
  //         setVisibleCategories((prev) => [...prev, category.parent_category]);
  //       }
  //     }
  //   });
  // };

  // useEffect(() => {
  //   setVisibleFromChecked();
  // }, []);

  // console.log(categories);

  return (
    <PMDialog open={open} onClose={handleClose} title={"Categories"}>
      <form onSubmit={handleSearch} className="mt-10">
        <div className="relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
          <label
            htmlFor="name"
            className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
          >
            Search
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="product-search"
              id="product-search"
              onChange={(e) => handleQueryChange(e.target.value)}
              className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
              placeholder="Search...."
            />
          </div>
        </div>
      </form>
      <div className="py-5">
        {categories.map((category) => (
          <Category
            key={category.id}
            category={category}
            handleSelectItem={handleSelectItem}
            isVisible={visibleCategories.includes(category.id)}
            toggleVisibility={(id) => toggleVisibility(id)}
          />
        ))}
      </div>

      <div className="py-5">
        <PMPagination
          // disabled={isLoading}
          onPageChange={handlePageChange}
          page={params.page}
          perPage={params.per_page}
          rowsCount={currentCount}
          totalCount={totalCount}
          currentCount={currentCount}
          showCount={false}
        />
      </div>

      <hr className="py-5" />

      <div className="flex justify-between right align-right">
        <button
          onClick={handleClose}
          className="rounded bg-gray-400 px-5 py-2 hover:bg-gray-600"
        >
          Close
        </button>
        <button
          onClick={handleSave}
          className="rounded bg-blue-400 px-5 py-2 hover:bg-blue-600"
        >
          Save
        </button>
      </div>
    </PMDialog>
  );
}

ProductCategorySelector.defaultProps = {
  multiple: true,
  selectedItems: [],
};
