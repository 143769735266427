import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCustomers } from "../../../slices/customer";
import { CustomersStats } from "../../../components/dashboard/customers/customers-stats";
import { CustomersTable } from "../../../components/dashboard/customers/customers-table";
import { CustomersFilter } from "../../../components/dashboard/customers/customers-filter";
import {
  ArrowPathRoundedSquareIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import { toast } from "react-hot-toast";
import { mapErrors, updateUrlWithException } from "../../../components/Utils";
import { syncModule } from "../../../api/company";
import SimplePrompt from "../../../components/utils/simplePrompt";
import CustomerDialog from "../../../components/dashboard/customers/dialog";
import { useSearchParams } from "react-router-dom";
import { debounce } from "lodash";
import { getContactsAsync } from "../../../slices/customer/contact";
import ContactDialog from "../../../components/dashboard/contacts/dialog";
import { ContactsTable } from "../../../components/dashboard/contacts/contacts-table";

export const ContactList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const itemState = useSelector((store) => {
    return store.contacts;
  });

  const { isLoading } = itemState;
  const [selectedItems, setSelectedItems] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [controller, setController] = useState({
    filters: [],
    page: searchParams.get("page") ? searchParams.get("page") : 1,
    per_page: searchParams.get("per_page") ? searchParams.get("per_page") : 50,
    query: searchParams.get("query") ? searchParams.get("query") : "",
    sort: searchParams.get("sort") ? searchParams.get("sort") : "asc",
    sortBy: searchParams.get("sortBy") ? searchParams.get("sortBy") : "id",
    view: searchParams.get("view") ? searchParams.get("view") : "all",
    fields: searchParams.get("fields")
      ? searchParams.get("fields")
      : "id,name,email,mobile,displayTitle,syncStatus",
  });

  // Update all params from controller except filters (dont't touch filters)
  useEffect(() => {
    updateUrlWithException(
      // These will be updated
      {
        page: controller.page,
        per_page: controller.per_page,
        query: controller.query,
        sort: controller.sort,
        sortBy: controller.sortBy,
        view: controller.view,
        // filters: controller.filters,
      },
      // This will be preserved
      "filters"
    );
  }, [controller]);

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery,
    });
  };

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const debouncedDispatchAction = debounce((controller) => {
    dispatch(getContactsAsync(controller));
  }, 200);

  useEffect(() => {
    // Trigger the debounced dispatch action
    debouncedDispatchAction(controller);

    // Clean the debounced function on component unmount.
    return () => {
      debouncedDispatchAction.cancel();
    };
  }, [controller, dispatch]);

  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [syncPromptState, setSyncPromptState] = useState(false);

  const headerActions = [
    {
      // component: null,
      // label: "Sync API",
      // description: "",
      // path: null,
      // action: () => setSyncPromptState(true),
      // icon: ArrowPathRoundedSquareIcon,
      // textColor: "text-teal-700",
      // bgColor: "bg-white",
      // hidden: false,
      // menu: true,
    },
  ];

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={"Kontakter"}
        meta={[]}
        mainButton={{
          label: "New",
          action: () => setContactDialogOpen(true),
          path: null,
          color: "",
          icon: PlusIcon,
        }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            <CustomersFilter
              className={"mt-10"}
              disabled={isLoading}
              onQueryChange={handleQueryChange}
              query={controller.query}
              selectedItems={selectedItems}
            />

            <ContactsTable
              itemState={itemState}
              itemsCount={itemState?.count}
              currentCount={itemState?.data?.length}
              page={controller.page}
              onPageChange={handlePageChange}
              perPage={controller.per_page}
            />
          </div>
        </div>
      </DashboardHeader>

      <ContactDialog
        open={contactDialogOpen}
        onClose={() => setContactDialogOpen(false)}
        readOnly={false}
        redirect={true}
      />
    </>
  );
};
