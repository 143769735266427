import { PMDialog } from "../../utils/PMDialog";
import { useState } from "react";
import { UploaderV2 } from "./Uploaderv2";
import { documentSrc } from "../documents/utils";
import PMCombobox from "../../utils/PMCombobox";
import { ProjectFolderSelector } from "../../utils/moduleSelectors/projectFolderSelector";
import { PMField } from "../../utils/PMField";
import { Form, Formik } from "formik";
import { toast } from "react-hot-toast";
import { dispatchWithToast } from "../../Utils";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  createDocumentAsync,
  updateDocumentAsync,
} from "../../../slices/document";
import { ResourceLoading } from "../../utils/ResourceLoading";

export const DocumentUploadSection = (props) => {
  const { module, moduleParam } = props;
  const [open, setOpen] = useState(false);

  const [document, setDocument] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const handleUploadStart = () => {
    setIsLoading(true);
    setOpen(true);
  };

  // Handle document
  const handleOnUpload = (data) => {
    setDocument(data);
    setIsLoading(false);
    props.onUpload(data);
  };

  const dispatch = useDispatch();

  return (
    <>
      <UploaderV2
        onLoading={() => {
          handleUploadStart();
        }}
        module={module}
        module_param={moduleParam}
        size=""
        version={2}
        parentCallback={(data) => handleOnUpload(data)}
      />
      <PMDialog
        open={open}
        onClose={() => setOpen(false)}
        title={
          document?.title
            ? document?.title
            : isLoading
            ? "Laster..."
            : "Dokumentet ble lastet opp"
        }
      >
        {isLoading ? (
          <ResourceLoading />
        ) : (
          <div className="mt-10">
            <Formik
              enableReinitialize
              initialValues={{
                id: document?.id || null,
                title: document?.title || null,
                submit: null,
              }}
              onSubmit={(values, helpers) => {
                try {
                  dispatchWithToast(
                    dispatch(updateDocumentAsync(values)),
                    props.onRefresh,
                    () => setOpen(false)
                  );

                  helpers.setStatus({ success: true });
                  helpers.setSubmitting(false);
                } catch (err) {
                  console.log(err);
                  toast.error("Something went wrong...");
                  console.error(err);
                  helpers.setStatus({ success: false });
                  helpers.setErrors({ submit: err.message });
                  helpers.setSubmitting(false);
                }
              }}
            >
              {(formik) => (
                <Form>
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-3">
                      <img
                        className="border-2 border-blue-200 rounded-md shadow-md w-full"
                        src={documentSrc(document, "thumb")}
                        title={document ? document.title : "Image placeholder"}
                      />
                    </div>
                    <div className="col-span-3 sm:col-span-3 flex flex-col">
                      <div className="">
                        <PMField
                          name="title"
                          type="text"
                          label="Tittel"
                          {...formik}
                        />
                      </div>
                      <div className="mt-5">
                        {module === "project" && moduleParam && (
                          <ProjectFolderSelector
                            projectId={
                              module === "project" ? moduleParam : null
                            }
                            selected={
                              document?.folder?.id ? document?.folder : null
                            }
                            {...formik}
                          />
                        )}
                      </div>
                    </div>

                    {/* {!props?.customer?.id && (
              <div className="col-span-6 sm:col-span-3">
                <CustomerSelector
                  selected={
                    deviation?.customer?.id ? deviation?.customer : customer
                  }
                  {...formik}
                />
              </div>
            )} */}

                    {/* {!props?.project?.id && (
              <div className="col-span-6 sm:col-span-3">
                <ProjectSelector
                  selected={
                    deviation?.project?.id ? deviation?.project : project
                  }
                  {...formik}
                />
              </div>
            )} */}
                  </div>

                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      Steng
                    </button>

                    <button
                      type="button"
                      className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                      onClick={formik.submitForm}
                    >
                      {formik?.values?.id ? "Lagre" : "Opprett"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </PMDialog>
    </>
  );
};
