import React, { component, useEffect, useState } from "react";
import { ProductQR } from "../../../components/dashboard/products/product-qr";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ResourceLoading } from "../../../components/utils/ResourceLoading";
import { useDispatch, useSelector } from "react-redux";
import { ResourceError } from "../../../components/utils/ResourceError";
import { Grid } from "@mui/material";

import { ProjectInfo } from "../../../components/dashboard/projects/project-info";
import PMLinkCard from "../../../components/utils/PMLinkCard";
import {
  CameraIcon,
  ArrowPathRoundedSquareIcon,
  ExclamationCircleIcon,
  FolderIcon,
  PencilIcon,
  WrenchScrewdriverIcon,
  ArrowLeftIcon,
  CheckBadgeIcon,
} from "@heroicons/react/24/outline";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import { ItemStatus, mapErrors } from "../../../components/Utils";
import { fetchProject } from "../../../api/project";
import { Notes } from "../../../components/dashboard/notes/notes";
import { HistoriesModalButton } from "../../../components/dashboard/histories/modal-button";
import { ReportsStackList } from "../../../components/dashboard/service/reports/reports-stack-list";
import { TWclassNames } from "../../../components/utils/Div";
import { DocumentUploadSection } from "../../../components/dashboard/Media/DocumentUploadSection";
import { FolderInfo } from "../../../components/dashboard/projects/folders/folder-info";
import { fetchProjectFolder } from "../../../api/project/folder";
import { DocumentsSection } from "../../../components/dashboard/projects/folders/documentsSection";

export const Folder = (props) => {
  const { t } = useTranslation();
  const { folder_id, project_id } = useParams();
  const dispatch = useDispatch();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    project: {},
    folder: {},
  });

  const [deviationDialogOpen, setDeviationDialogOpen] = useState(false);

  const rightSideActions = [
    {
      label: "Nytt Avvik",
      description: "Opprett et nytt avvik for dette prosjektet",
      path: "",
      action: () => setDeviationDialogOpen(true),
      icon: ExclamationCircleIcon,
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
    },
  ];

  const getFolder = async () => {
    const payload = {
      id: folder_id,
      project_id: project_id,
    };
    return fetchProjectFolder(payload)
      .then((res) => {
        if (res.data.id) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            folder: res.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err.data.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          folder: {},
          error: true,
          errors: errors,
        }));
      });
  };

  const getProject = async () => {
    return fetchProject(project_id)
      .then((res) => {
        if (res.data.id) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            project: res.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err.data.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          project: {},
          error: true,
          errors: errors,
        }));
      });
  };

  useEffect(() => {
    getProject();
    getFolder();
  }, []);

  const handleOnRefreshFolder = (data) => {
    if (data?.id) {
      setItemState((prevState) => ({
        ...prevState,
        folder: data,
      }));
    } else {
      getFolder();
    }
  };

  const handleOnRefresh = (data) => {
    if (data?.id) {
      setItemState((prevState) => ({
        ...prevState,
        project: data,
      }));
    } else {
      getProject();
    }
  };

  const { isLoading, error, errors, project, folder } = itemState;

  const [refresh, setRefresh] = useState(false);

  const item = project;

  const headerMeta = [
    {
      component: <ItemStatus item={project} />,
      label: "Status",
      description: "",
      path: null,
      action: null,
      icon: null,
      textColor: "text-teal-700",
      hidden: false,
    },
  ];

  const headerActions = [
    {
      component: (
        <HistoriesModalButton module={"folder"} module_param={project?.id} />
      ),
      label: "History",
      description: "",
      icon: ArrowPathRoundedSquareIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: false,
    },
    {
      component: (
        <div className="px-2 mt-2">
          <Notes module={"folder"} module_param={project.id} />
        </div>
      ),
      label: "Status",
      description: "",
      path: null,
      action: null,
      icon: CameraIcon,
      textColor: "text-teal-700",
      bgColor: "bg-red-200",
      hidden: false,
      menu: false,
    },
  ];

  const tabs = [
    {
      name: "Prosjekt",
      path: "/dashboard/projects/" + project?.id,
      component: null,
      icon: ArrowLeftIcon,
    },

    {
      name: "Detaljer",
      component: <FolderInfo item={folder} />,
      icon: PencilIcon,
    },
    {
      name: "Documents",
      icon: FolderIcon,
      component: (
        <DocumentsSection
          project={item}
          filters={[
            {
              name: "folder",
              value: folder.id,
            },
          ]}
        />
      ),
    },
    {
      name: "Reports",
      icon: WrenchScrewdriverIcon,
      component: (
        <ReportsStackList
          module_param={folder.id}
          module="folder"
          filters={[
            {
              name: "folder",
              value: folder.id,
            },
          ]}
        />
      ),
    },

    {
      name: "Checklists",
      icon: CheckBadgeIcon,
      component: (
        <DocumentsSection
          project={item}
          filters={[
            {
              name: "folder",
              value: folder.id,
            },
          ]}
        />
      ),
    },
    {
      name: "Deviations",
      icon: ExclamationCircleIcon,
      component: (
        <DocumentsSection
          project={item}
          onRefresh={refresh}
          filters={[
            {
              name: "folder",
              value: folder.id,
            },
          ]}
        />
      ),
    },
  ];

  const [selectedTab, setSelectedTab] = useState(1);

  const navigate = useNavigate();

  function projectTabs() {
    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            onChange={(e) => setSelectedTab(e.target.value)}
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={tabs[selectedTab].name}
          >
            {tabs.map((tab, tabIdx) => (
              <option key={tab.name} value={tabIdx}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav
            className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
            aria-label="Tabs"
          >
            {tabs.map((tab, tabIdx) => (
              <a
                key={tab.name}
                onClick={
                  tab?.path
                    ? () => navigate(tab?.path)
                    : () => setSelectedTab(tabIdx)
                }
                className={TWclassNames(
                  tabIdx === selectedTab
                    ? "text-gray-900"
                    : "text-gray-500 hover:text-gray-700",
                  tabIdx === 0 ? "rounded-l-lg" : "",
                  tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                  "cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                )}
                aria-current={tabIdx === selectedTab ? "page" : undefined}
              >
                <div className="flex">
                  {tab?.icon && <tab.icon className="h-5 w-5 mr-2" />}
                  <span className="">{tab.name}</span>
                </div>
                <span
                  aria-hidden="true"
                  className={TWclassNames(
                    tabIdx === selectedTab ? "bg-indigo-500" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </a>
            ))}
          </nav>
        </div>
      </div>
    );
  }

  const renderContent = () => {
    if (isLoading) {
      return <ResourceLoading />;
    }
    if (error && errors) {
      return <ResourceError errors={errors} />;
    }

    return (
      <>
        <DashboardHeader
          subtitle=""
          actions={headerActions}
          title={project?.displayTitle + " / " + folder?.displayTitle}
          meta={headerMeta}
        >
          <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
              <Grid container spacing={3}>
                <Grid
                  container
                  item
                  lg={8}
                  spacing={3}
                  sx={{ height: "fit-content", order: { md: 2, xs: 1 } }}
                >
                  <Grid item xs={12}>
                    {projectTabs()}
                    <div className="py-5">{tabs[selectedTab].component}</div>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  lg={4}
                  spacing={3}
                  sx={{ height: "fit-content", order: { md: 2, xs: 1 } }}
                >
                  <Grid item xs={12}>
                    <DocumentUploadSection
                      module="folder"
                      moduleParam={folder?.id}
                      onUpload={() => setRefresh(!refresh)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PMLinkCard actions={rightSideActions} />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </DashboardHeader>
      </>
    );
  };

  return <>{renderContent()}</>;
};
