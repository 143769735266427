import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchReports,
  fetchReport,
  updateReport,
  createReport,
  deleteReport,
  listUpdateReport,
} from "../../api/service/report";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  report: {},
  count: 0,
  currentCount: 0,
  newestReportsCount: 0,
};

export const initialController = {
  filters: [],
  page: 0,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const deleteReportAsync = createAsyncThunk(
  "reports/deleteReportAsync",
  async (payload) => {
    let response = await deleteReport(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createReportAsync = createAsyncThunk(
  "reports/createReportAsync",
  async (payload) => {
    let response = await createReport(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const updateReportAsync = createAsyncThunk(
  "reports/updateReportAsync",
  async (payload) => {
    let response = await updateReport(payload);

    // If errors, reject and return response
    // if (response.error) return false;
    console.log("res", response);
    // If all good, return response.
    return response;
  }
);
export const getReportsAsync = createAsyncThunk(
  "reports/getReportsAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchReports(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

export const getReportAsync = createAsyncThunk(
  "reports/getReportAsync",
  async (id, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchReport(id);

    // If errors, reject and return response
    if (response.error) return rejectWithValue(response);

    // If all good, return response.
    return response;
  }
);

export const listUpdateReportAsync = createAsyncThunk(
  "reports/listUpdateReportAsync",
  async (payload, { rejectWithValue }) => {
    let response = await listUpdateReport(payload);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/)) return rejectWithValue;

    // If all good, return response.
    return response;
  }
);

const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReportsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getReportsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.count = action.payload.count;
        state.currentCount = action.payload.currentCount;
        state.newestReportsCount = action.payload.newestReportsCount;
      })
      .addCase(getReportsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch variable types....is controller missing?",
        ];
      })
      .addCase(updateReportAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateReportAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data[index] = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(updateReportAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update report"];
      })
      .addCase(createReportAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createReportAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
        });
      })
      .addCase(createReportAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update report"];
      })
      .addCase(deleteReportAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteReportAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(deleteReportAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update report"];
      })
      .addCase(getReportAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getReportAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.report = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(getReportAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update product."];
      })
      .addCase(listUpdateReportAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(listUpdateReportAsync.fulfilled, (state, action) => {
        state.report = action.payload;
        state.isLoading = false;
        // return produce(state, (draftState) => {
        //     // const index = draftState.data.findIndex(type => type.id === action.payload.id);
        //     draftState.product = action.payload
        //     draftState.isLoading = false;
        // })
      })
      .addCase(listUpdateReportAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update report."];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = reportSlice;

export default reportSlice;
