import { PlusIcon, UserIcon } from "@heroicons/react/24/outline";
import { CheckIcon, HandThumbUpIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { fetchHistories } from "../../../api/histories";
import { TWclassNames } from "../../utils/Div";
import { ResourceEmpty } from "../../utils/ResourceEmpty";
import { ResourceLoading } from "../../utils/ResourceLoading";
import {
  historyActions,
  historyActionTypes,
  historyControllers,
} from "./components";
import { productPath } from "../../Utils";

export const HistoryModuleView = (props) => {
  const { t } = useTranslation();

  const [histories, setHistories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [fetchController, setFetchController] = useState({
    filters: [
      {
        name: props.module,
        value: props.module_param,
      },
    ],
    page: 1,
    per_page: 7,
    query: "",
    sort: "desc",
    sortBy: "updated_at",
    view: "all",
  });

  const { per_page } = fetchController;

  useEffect(() => {
    // Update filters in fetchController
    setFetchController({
      ...fetchController,
      filters: [
        {
          name: props.module,
          value: props.module_param,
        },
      ],
    });
  }, [props.module_param]);

  const getHistories = async () => {
    const response = await fetchHistories(fetchController)
      .then((res) => {
        var array = [];
        res?.data?.data?.map((row, i) => {
          const rowParams = {
            ...row,
            type: historyActionTypes[row.action],
          };
          array.push(rowParams);
        });
        setHistories(array);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  // const controller = (item) => {
  //   switch (item.controller) {
  //     case "user":
  //       return item?.created_by?.name;
  //     case "report":
  //       return (
  //         <Link
  //           className="cursor-pointer"
  //           to={"/dashboard/service/reports/" + item.report?.id}
  //         >
  //           {t("Report") + " " + item?.report?.report_number}
  //         </Link>
  //       );
  //     case "customer_system":
  //       return "Customer System";
  //     default:
  //       return item.controller;
  //   }
  // };

  const controller = {
    order: {
      label: "order",
      path:
        props.module === "order"
          ? ""
          : "/dashboard/orders/" + props.module_param,
    },
    report: {
      label: "report",
      path:
        props.module === "report"
          ? ""
          : "/dashboard/service/reports/" + props.module_param,
    },
  };

  function labelAndLink(item) {
    switch (item.controller) {
      case "order":
        return (
          <>
            {item?.order?.id ? (
              <Link
                className="cursor-pointer"
                to={
                  item.order?.id ? "/dashboard/orders/" + item.order?.id : null
                }
              >
                {t("Order") +
                  " " +
                  (item?.order?.order_number
                    ? item?.order?.order_number
                    : "(Slettet)")}
              </Link>
            ) : (
              <span>
                {t("Order") +
                  " " +
                  (item?.order?.order_number
                    ? item?.order?.order_number
                    : "(Slettet)")}
              </span>
            )}
          </>
        );
      case "report":
        return (
          <Link
            className="cursor-pointer"
            to={"/dashboard/service/reports/" + item.report?.id}
          >
            {t("Report") + " " + item?.report?.report_number}
          </Link>
        );
      case "customer_system":
        return "Customer System";
      case "text_message":
        if (item?.action === "new_message") {
          return "til " + item?.param_two;
        } else {
          return null;
        }
      case "product":
        return (
          <Link
            className="cursor-pointer"
            to={productPath({ product: item?.product })}
          >
            {t("Product")}
          </Link>
        );
      default:
        return item.controller;
    }
  }

  const historyContent = (item) => {
    switch (item.controller) {
      case "order":
        return null;
      case "report":
        return null;
      case "customer_system":
        return null;
      case "text_message":
        return item?.param_one;

      default:
        return null;
    }
  };

  useEffect(() => {
    getHistories();
  }, []);

  console.log(histories);

  return (
    <section aria-labelledby="timeline-title" className="max-sm:hidden">
      <div className="bg-white px-4 py-5 sm:rounded-lg sm:px-6">
        <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
          {t("History")}
        </h2>

        {/* Activity Feed */}
        {isLoading ? (
          <ResourceLoading />
        ) : histories.length < 1 ? (
          <ResourceEmpty />
        ) : (
          <div className="mt-6 flow-root">
            <ul role="list" className="-mb-8">
              {histories?.map((item, itemIdx) => {
                const contr = controller[item.controller];
                return (
                  <li key={item.id}>
                    <div className="relative pb-8">
                      {itemIdx !== histories?.length - 1 ? (
                        <span
                          className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                          aria-hidden="true"
                        />
                      ) : null}
                      <div className="relative flex space-x-3">
                        <div>
                          <span
                            className={TWclassNames(
                              item?.type?.bgColorClass,
                              "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                            )}
                          >
                            {item?.type?.icon ? (
                              <item.type.icon
                                className="h-5 w-5 text-white"
                                aria-hidden="true"
                              />
                            ) : null}
                          </span>
                        </div>
                        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                          <div>
                            <p className="text-sm text-gray-500">
                              {(item.created_by?.name
                                ? item.created_by?.name
                                : "Systemet") + " has "}
                              {historyActions[item.action]}
                              <span className="font-medium text-gray-900">
                                {" "}
                                {labelAndLink(item)}
                              </span>
                            </p>
                            <p className="text-xs">{historyContent(item)}</p>
                          </div>
                          <div className="whitespace-nowrap text-right text-sm text-gray-500">
                            <time dateTime={item.created_at}>
                              {item.created_at.substring(0, 10)}
                            </time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div className="justify-stretch mt-6 flex flex-col">
          {histories?.length > per_page && (
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-2 border-blue-400 px-4 py-2 text-sm font-medium text-black hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              {t("See More")}
            </button>
          )}
        </div>
      </div>
    </section>
  );
};
