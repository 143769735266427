import { WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import { useState, useEffect } from "react";
import { TWclassNames } from "../../../utils/Div";
import { ReportRectifyDialog } from "./report-rectify-dialog";

export const ReportRectifyButton = (props) => {
  const { report, isLoading } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        onClick={() => setOpen(!open)}
        type="button"
        className={TWclassNames(
          "border-red-400",
          "group -m-2 flex items-center p-2 border-2 rounded-md"
        )}
      >
        <WrenchScrewdriverIcon
          className="h-4 w-4 flex-shrink-0 text-black group-hover:text-gray-500 "
          aria-hidden="true"
        />
        <span className="sr-only">Report Rectify Button</span>
      </button>

      {/* Dialog START */}
      <ReportRectifyDialog
        report={report}
        open={open}
        isLoading={isLoading}
        // onLoading={(state) => setIsLoading(state)}
        onClose={() => setOpen(!open)}
        onRefresh={() => props.onRefresh()}
        {...props}
      />
      {/* Dialog END */}
    </>
  );
};

ReportRectifyButton.defaultProps = {};
