import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUnits } from "../api/unit";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  totalCount: 0,
  currentCount: 0,
  newCount: 0,
};

export const initialController = {
  filters: [],
  page: 0,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const getUnitsAsync = createAsyncThunk(
  "units/getUnitsAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchUnits(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

const unitSlice = createSlice({
  name: "units",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUnitsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUnitsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.currentCount = action.payload.currentCount;
        state.newestUnitsCount = action.payload.newCount;
      })
      .addCase(getUnitsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch variable types....is controller missing?",
        ];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = unitSlice;

export default unitSlice;
