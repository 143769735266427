import axios from "../axios";

export async function fetchProjectFolders(controller) {
  return axios({
    method: "get",
    url: `/projects/${controller?.project_id}/folders/`,
    params: controller,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchProjectFolder(payload) {
  return axios.get(`/projects/${payload?.project_id}/folders/` + payload?.id);
}

export async function createProjectFolder(payload) {
  return axios
    .post(`/projects/${payload?.project_id}/folders`, { folder: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function updateProjectFolder(payload) {
  return axios
    .put(`/projects/${payload.id}/folders`, { folder: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function deleteProjectFolder(payload) {
  const project = payload.project;
  return axios
    .delete(`/projects/${project.project_id}/folders/${payload?.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
