import { useState, useEffect } from "react";

export const StripePaymentMethod = ({ payment_method }) => {
  const [lastFourDigits, setLastFourDigits] = useState();
  const [cardBrand, setCardBrand] = useState();
  const [imageSource, setImageSource] = useState();

  useEffect(() => {
    setLastFourDigits(payment_method?.card?.last4);

    setCardBrand(payment_method?.card?.brand);

    setImageSource(
      process.env.PUBLIC_URL +
        "/payment-method/" +
        (cardBrand === "card" ? cardBrand : payment_method?.type) +
        ".png"
    );
  }, [payment_method]);

  return (
    <>
      <div className="rounded-md py-2">
        {payment_method?.type === "card" ? (
          <div className="flex items-center">
            <img src={imageSource} alt={cardBrand} className="h-5" />
            <span className="ml-2 card-details">•••• {lastFourDigits}</span>
          </div>
        ) : payment_method?.type === "klarna" ? (
          <div className="flex items-center">
            <img src={imageSource} alt={cardBrand} className="h-5" />
          </div>
        ) : (
          // Other payment methods
          <div className="flex items-center">
            <span className="ml-2 card-details">{payment_method?.type}</span>
          </div>
        )}
      </div>
    </>
  );
};
