import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCompany, updateCompany } from "../api/company";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  company: null,
};

export const updateCompanyAsync = createAsyncThunk(
  "company/updateCompanyAsync",
  async (payload, params) => {
    let response = await updateCompany(payload, params);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const getCompanyAsync = createAsyncThunk(
  "company/getCompanyAsync",
  async () => {
    let response = await fetchCompany();

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCompanyAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.company = action.payload;
      })
      .addCase(getCompanyAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong...."];
      })
      .addCase(updateCompanyAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateCompanyAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.company = action.payload;
      })
      .addCase(updateCompanyAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Could not update...."];
      });
  },
});

export const { reducer } = companySlice;

export default companySlice;
