import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { PMField } from "../../../utils/PMField";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dispatchWithToast, mapErrors } from "../../../Utils";
import { updateDeviationAsync } from "../../../../slices/quality/deviation";
import { fetchUsers } from "../../../../api/users";
import {
  getDeviationTypeOptions,
  priorityOptions,
  severityOptions,
} from "./utils";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { fetchDeviation } from "../../../../api/quality/deviation";
import {
  fetchDrivingDriver,
  updateDrivingDriver,
} from "../../../../api/driving/driver";
import { createDrivingDriverAsync } from "../../../../slices/driving/driver";
import PMCombobox from "../../../utils/PMCombobox";
import { ResourceError } from "../../../utils/ResourceError";

export default function DrivingDriverDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    deviation: {},
  });

  const { open, onClose, selected, fields, project, customer } = props;

  const { isLoading, deviation, error, errors } = itemState;

  const [deviationTypeOptions, setDeviationTypeOptions] = useState([]);

  const [userOptions, setUserOptions] = useState({
    isLoading: true,
    error: false,
    errors: [],
    data: [],
  });

  async function fetchAndMapUsers() {
    setUserOptions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const controller = {
      filters: [{ name: "driver", value: null }],
      page: 1,
      query: "",
      sort: "desc",
      sortBy: "id",
      view: "all",
    };

    await fetchUsers(controller)
      .then((res) => {
        if (res.data) {
          var array = [];
          if (!res.data) return array;
          res?.data?.map((row, i) => {
            const rowParams = {
              value: row.id,
              title: row.name,
              order: i,
            };
            array.push(rowParams);
          });
          setUserOptions((prevState) => ({
            ...prevState,
            isLoading: false,
            data: array,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        setUserOptions((prevState) => ({
          ...prevState,
          isLoading: false,
          error: true,
          errors: errors,
        }));
      });

    // Map into an array with ID and title?

    // Return the array
  }

  const getDeviation = async () => {
    await fetchDrivingDriver(selected.id)
      .then((res) => {
        if (res?.data?.id) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            deviation: res?.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err?.data?.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          deviation: {},
          error: true,
          errors: errors,
        }));
      });
  };

  useEffect(() => {
    if (open) {
      setItemState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      if (selected?.id) {
        getDeviation(selected?.id);
      } else {
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
      setTimeout(() => {
        fetchAndMapUsers();
      });
    } else {
      setItemState((prevState) => ({
        ...prevState,
        deviation: {},
      }));
    }
  }, [open]);

  const createOrUpdateDispatch = (data) => {
    if (deviation?.id) {
      const res = dispatch(updateDrivingDriver(data));
      return res;
    } else {
      const res = dispatch(createDrivingDriverAsync(data));
      return res;
    }
  };

  const MissingEmployee = () => (
    <ResourceError
      errors={
        "Sorry! Det er ingen tilgjengelige ansatte som kan knyttes til en ny sjåfør."
      }
    />
  );

  const NewDriverForm = () => (
    <Formik
      initialValues={{
        id: null,
        user_id: null,
        submit: null,
      }}
      validationSchema={Yup.object({
        user_id: Yup.string().required("Employee is required"),
      })}
      onSubmit={(values, helpers) => {
        try {
          dispatchWithToast(
            createOrUpdateDispatch(values),
            props.onRefresh,
            onClose(true)
          );

          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
        } catch (err) {
          console.log(err);
          toast.error("Something went wrong...");
          console.error(err);
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <Form>
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-6">
              <PMCombobox
                name="user_id"
                label={t("Employee")}
                options={userOptions.data}
                {...formik}
              />
            </div>
          </div>

          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
              type="button"
              className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
              onClick={onClose}
            >
              {t("Close")}{" "}
            </button>

            <button
              type="button"
              className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
              onClick={formik.submitForm}
            >
              {t("Create")}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    <div className="flex items-center py-5 w-full">
                      <div className="block">
                        <h2
                          id="applicant-information-title"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          {t("Create Driver")}
                        </h2>
                      </div>
                    </div>
                  </Dialog.Title>
                  {isLoading ? (
                    <ResourceLoading />
                  ) : userOptions?.data?.length > 0 ? (
                    <NewDriverForm />
                  ) : (
                    <MissingEmployee />
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
