import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { TextField, TableFooter, Button } from "@mui/material";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { PMField } from "../../utils/PMField";
import { toast } from "react-hot-toast";
import { TrashIcon } from "@heroicons/react/24/outline";
import ProConfirmation from "../../utils/ProConfirmation";
import { ActivitySelector } from "../../utils/moduleSelectors/activitySelector";
import { PMNotice } from "../../utils/notifications";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 0,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgrey" : null,

  // styles we need to apply on draggables
  ...draggableStyle,
});

export default function ActivitiesTable(props) {
  const { setFieldValue, values, isLoading, drag, project, title, module } =
    props;

  const name = props.name ? props.name : "activities";

  const rows = values[name];

  const columns = [
    { id: "drag", label: "", minWidth: 10, maxWidth: 5, active: drag },
    { id: "activity", label: "Activity", minWidth: 300, active: true },
    {
      id: "number",
      label: "Number",
      minWidth: 100,
      active: props.number ? true : false,
    },
    {
      id: "description",
      label: "Description",
      minWidth: 200,
      active: props.module === "settings" ? true : false,
    },
    {
      id: "budget_hours",
      label: "Hours",
      minWidth: 50,
      align: "left",
      active: props.budget_hours && props.module !== "settings" ? true : false,
    },
    {
      id: "budget_fee_rate",
      label: "Rate",
      minWidth: 100,
      align: "left",
      active:
        props.budget_fee_rate && props.module !== "settings" ? true : false,
    },
    {
      id: "budget_fee",
      label: "Fee",
      minWidth: 100,
      align: "left",
      active: props.budget_fee && props.module !== "settings" ? true : false,
    },
    {
      id: "invoiceable",
      label: "Invoiceable",
      minWidth: 20,
      align: "left",
      active: props.invoiceable ? true : false,
    },
    {
      id: "inactive",
      label: "Inactive",
      minWidth: 20,
      align: "left",
      active: props.inactive ? true : false,
    },
    {
      id: "actions",
      label: "",
      minWidth: 50,
      align: "left",
      active: props.actions ? true : false,
    },
  ];

  const orderItems = (rows) => {
    var array = [];

    if (!rows) return;

    rows.map((row, i) => {
      const rowParams = {
        ...row,
        row_order: i,
        draggableId: "id" + row.id,
      };
      array.push(rowParams);
    });
    return array;
  };

  // Title column is only used when group_row is true
  const defaultRows = [
    {
      id: null,
      activity_id: null,
      row_order: null,
      title: "",
    },
  ];

  const [items, setItems] = useState(
    orderItems(rows?.length > 0 ? rows : defaultRows)
  );

  useEffect(() => {
    setFieldValue(name, items);
  }, [items]);

  const addItemRow = (type) => {
    const newRow = {
      // Generate a random ID for the new row until one is made in the API
      id: null,
      title: "",
      row_order: items.length,
      // Generating a fake ID for draggable, so that we are able to move the row before saving.
      draggableId: "id" + items.length + 1,
    };
    setItems((prevState) => [...prevState, newRow]);
  };

  const onItemRowChange = (e, data) => {
    if (typeof e === "function") {
      e.preventDefault();
    }

    if (e?.target?.name === "activity" && e?.target?.value !== null) {
      if (e?.target?.object !== undefined) {
        const activity = e.target.object;

        const item = {
          ...data,
          activity: activity,
          title: null,
          description: null,
          number: activity?.number,
          invoiceable: activity?.invoiceable,
          is_inactive: activity?.is_inactive,
        };

        let prevItems = [...items];
        prevItems[data?.row_order] = item;
        setItems(prevItems);

        return;
      } else {
        toast.error("Missing activity object, cannot set activity correctly");
      }
    }

    let prevItems = [...items];
    let item = {
      ...prevItems[data?.row_order],
      [e.target.name]: e.target.value,
    };
    prevItems[data?.row_order] = item;
    setItems(prevItems);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(orderItems(newItems));
  };

  const [selectedItemRow, setSelectedItemRow] = useState();

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const handleDeleteRow = () => {
    setDeleteConfirmationOpen(false);
    // Delete selectedItem row from array
    if (selectedItemRow && selectedItemRow?.id !== null) {
      const newArray = items.filter((item) => item.id !== selectedItemRow?.id);
      setItems(orderItems(newArray));
    } else {
      const newArray = items.filter(
        (item) => item.draggableId !== selectedItemRow.draggableId
      );
      setItems(orderItems(newArray));
    }
  };

  const deleteRow = (item) => {
    setSelectedItemRow(item);
    setDeleteConfirmationOpen(true);
  };

  const canSelectActivity = (item) => {
    if (item?.activity?.id) {
      if (item?.activity?.is_project_activity) {
        if (module === "project") {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const canEditTitle = (item) => {
    if (item?.activity?.id) {
      if (item?.activity?.is_project_activity) {
        if (module === "project") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const itemRow = (item, provided, snapshot) => {
    return (
      <TableRow
        hover
        key={item.id}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
      >
        {drag ? (
          <TableCell>
            <DragHandleIcon />
          </TableCell>
        ) : null}

        <TableCell align="left">
          {props.module !== "settings" && (
            <>
              {canSelectActivity(item) && (
                <div className="">
                  <ActivitySelector
                    disabled={item?.id !== null ? true : false}
                    selected={item?.activity}
                    name="activity"
                    label=""
                    onChange={(e) => onItemRowChange(e, item)}
                    project={project}
                    report={props.report}
                    module={props.module}
                  />
                </div>
              )}
            </>
          )}
          {canEditTitle(item) && (
            <TextField
              // label="Tittel"
              variant="standard"
              value={item?.title}
              fullWidth
              name="title"
              multiline
              onChange={(e) => onItemRowChange(e, item)}
            />
          )}
        </TableCell>

        {props.number && (
          <TableCell align="left">
            <TextField
              // label="Number"
              variant="standard"
              value={item?.number}
              fullWidth
              name="number"
              multiline
              onChange={(e) => onItemRowChange(e, item)}
            />
          </TableCell>
        )}

        {props.module === "settings" && (
          <TableCell align="left">
            <TextField
              // label="Description"
              variant="standard"
              value={item?.description}
              fullWidth
              name="description"
              multiline
              onChange={(e) => onItemRowChange(e, item)}
            />
          </TableCell>
        )}
        {props.budget_hours && props.module !== "settings" && (
          <TableCell align="left">
            <TextField
              variant="standard"
              fullWidth
              name="budget_hours"
              value={item.budget_hours}
              onChange={(e) => onItemRowChange(e, item)}
            />
          </TableCell>
        )}

        {props.budget_fee_rate && props.module !== "settings" && (
          <TableCell align="left">
            <TextField
              variant="standard"
              fullWidth
              name="budget_fee_rate"
              value={item.budget_fee_rate}
              onChange={(e) => onItemRowChange(e, item)}
            />
          </TableCell>
        )}
        {props.budget_fee && props.module !== "settings" && (
          <TableCell align="left">
            <TextField
              variant="standard"
              fullWidth
              name="budget_fee"
              value={item.budget_fee}
              onChange={(e) => onItemRowChange(e, item)}
            />
          </TableCell>
        )}

        {props.invoiceable && (
          <TableCell align="left">
            <div className="px-5 pb-2">
              <PMField
                disabled={
                  item?.activity && !item?.activity?.is_project_activity
                    ? true
                    : false
                }
                type="checkbox"
                fullWidth
                name="invoiceable"
                checked={item.invoiceable}
                onChange={(e) => onItemRowChange(e, item)}
              />
            </div>
          </TableCell>
        )}

        {props.inactive && (
          <TableCell align="left">
            <div className="px-5 pb-2">
              <PMField
                // disabled={item?.activity ? true : false}
                type="checkbox"
                fullWidth
                name="is_inactive"
                checked={item?.is_inactive}
                onChange={(e) => onItemRowChange(e, item)}
              />
            </div>
          </TableCell>
        )}

        {props.actions && (
          <TableCell align="right">
            <TrashIcon
              className="cursor-pointer h-5 h-5 text-red-400"
              onClick={() => deleteRow(item)}
            />
          </TableCell>
        )}
      </TableRow>
    );
  };

  if (isLoading) {
    return <ResourceLoading />;
  }

  if (props.module === null) {
    return (
      <PMNotice title="Module is not set" description="Please set the module" />
    );
  }

  return (
    <React.Fragment>
      <Table sx={{ width: "100%" }}>
        <TableHead>
          {/* <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center" colSpan={2}>Product</TableCell>
                        { props.purchase_price ? 
                            <TableCell align="center" colSpan={3}>Innkjøpspris</TableCell>
                        : null }

                        { props.sales_price ? 
                            <TableCell align="center" colSpan={4}>Salgspris</TableCell>
                        : null }
                    </TableRow> */}
          <TableRow>
            {columns.map((column) =>
              column.active ? (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    top: 57,
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                    width: column.width,
                  }}
                >
                  {column.label}
                </TableCell>
              ) : null
            )}
          </TableRow>
        </TableHead>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                {items?.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.draggableId}
                    index={index}
                  >
                    {(provided, snapshot) => itemRow(item, provided, snapshot)}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>

        <TableFooter>
          <TableRow>
            <TableCell align="left" colSpan={2}>
              <Button onClick={() => addItemRow()}>Ny rad</Button>
            </TableCell>

            <TableCell laft="left" colSpan={3}></TableCell>
          </TableRow>
        </TableFooter>
      </Table>

      <ProConfirmation
        severity="confirmation"
        open={deleteConfirmationOpen}
        message={"Are you sure you want to delete this row?"}
        action="delete"
        module="order_item"
        // item_id={confirmation.item_id}
        yesCallback={handleDeleteRow}
        cancelCallback={() => setDeleteConfirmationOpen(false)}
      />
    </React.Fragment>
  );
}

ActivitiesTable.defaultProps = {
  budget_hours: false,
  budget_fee_rate: false,
  budget_fee: false,
  number: false,
  invoiceable: false,
  inactive: false,
  actions: true,
  name: "activities",
  drag: true,
  title: true,
  module: null,
};
