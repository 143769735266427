import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getCasesStatsAsync } from "../../../slices/case";
import MonthlyTotalBar from "../../utils/monthlyTotalBar";
import ActivityMonthlyTotalBar from "../../utils/activityMonthlyTotalBar";

export default function CaseStats() {
  const caseState = useSelector((store) => {
    return store.cases;
  });

  const dispatch = useDispatch();

  const {
    activity_monthly,
    monthly_total,
    new_today,
    new_this_week,
    new_this_month,
  } = caseState;

  const stats = [
    { name: "New Today", value: new_today },
    { name: "New This Week", value: new_this_week },
    { name: "New This Month", value: new_this_month },
  ];

  console.log(caseState);

  useEffect(() => {
    dispatch(getCasesStatsAsync());
  }, []);

  return (
    <>
      {activity_monthly && (
        <ActivityMonthlyTotalBar monthlyData={activity_monthly} />
      )}
      {monthly_total && <MonthlyTotalBar monthlyData={monthly_total} />}
      <div className="bg-gray-900">
        <div className="mx-auto max-w-7xl">
          <div className="grid grid-cols-1 gap-px bg-white/5 sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
              <div
                key={stat.name}
                className="bg-gray-900 px-4 py-6 sm:px-6 lg:px-8"
              >
                <p className="text-sm font-medium leading-6 text-gray-400">
                  {stat.name}
                </p>
                <p className="mt-2 flex items-baseline gap-x-2">
                  <span className="text-4xl font-semibold tracking-tight text-white">
                    {stat.value}
                  </span>
                  {stat.unit ? (
                    <span className="text-sm text-gray-400">{stat.unit}</span>
                  ) : null}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
