/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, ClockIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  LinkIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import { toast } from "react-hot-toast";
import { getProductsAsync } from "../../../slices/products";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { formatter } from "../../Utils";
import imgPlaceholder from "../../../utils/img-placeholder.png";
import { useNavigate } from "react-router-dom";

export default function ProductSlideover(props) {
  const { open, onClose, productId, view } = props;
  const dispatch = useDispatch();
  const [controller, setController] = useState({
    filters: [{ name: "spareparts", value: productId }],
    page: 1,
    per_page: 40,
    query: "",
    sort: "asc",
    sortBy: "created_at",
    view: props.view,
    product_id: productId,
  });

  const productState = useSelector((store) => {
    return store.products;
  });

  useEffect(() => {
    setController({
      ...controller,
      filters: [{ name: "spareparts", value: productId }],
    });
  }, [productId]);

  useEffect(() => {
    if (open) {
      dispatch(getProductsAsync(controller));
    }
  }, [open]);

  const { data, isLoading } = productState;

  const navigate = useNavigate();

  const products = () => (
    <ul
      role="list"
      className="divide-y divide-gray-200 border-t border-b border-gray-200"
    >
      {data?.map((product) => (
        <li key={product.id} className="flex py-6">
          <div className="flex-shrink-0">
            <img
              src={
                product?.main_image?.src
                  ? product?.main_image?.src
                  : imgPlaceholder
              }
              alt={product?.main_image?.title}
              className="h-24 w-24 rounded-md object-cover object-center sm:h-32 sm:w-32"
            />
          </div>

          <div className="ml-4 flex flex-1 flex-col sm:ml-6">
            <div>
              <div className="flex justify-between">
                <h4 className="text-sm">
                  <a
                    href={product.href}
                    className="font-medium text-gray-700 hover:text-gray-800"
                  >
                    {product.title}
                  </a>
                </h4>
                <p className="ml-4 text-sm font-medium text-gray-900">
                  {formatter.format(product.sales_price)}
                </p>
              </div>
              <p className="mt-1 text-sm text-gray-500">
                {product.item_number}
              </p>
              <p className="mt-1 text-sm text-gray-500">
                {product.description}
              </p>
            </div>

            <div className="mt-4 flex flex-1 items-end justify-between">
              <p className="flex items-center space-x-2 text-sm text-gray-700">
                {product.stock > 0 ? (
                  <CheckIcon
                    className="h-5 w-5 flex-shrink-0 text-green-500"
                    aria-hidden="true"
                  />
                ) : (
                  <div className="flex items-center">
                    Ikke på lager
                    <ClockIcon
                      className="ml-2 h-5 w-5 flex-shrink-0 text-gray-300"
                      aria-hidden="true"
                    />
                  </div>
                )}

                {/* <span>{product.inStock ? 'In stock' : `Will ship in ${product.leadTime}`}</span> */}
              </p>
              <div className="ml-4">
                <button
                  onClick={() => navigate("/dashboard/products/" + product?.id)}
                  type="button"
                  className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                >
                  <span>Se produkt</span>
                </button>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            Spareparts
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={onClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Viser reservedeler for valgt produkt.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            {products()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={onClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

ProductSlideover.defaultProps = {
  open: false,
  onClose: () => toast.error("Function is missing"),
};
