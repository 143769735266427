import React, { component } from "react";

import { useTranslation } from "react-i18next";
import { InputField } from "../../mui/InputField";
import { Form, Formik, useFormik, useFormikContext } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
// import { PromptIfDirty } from "../../utils/PromptIfDirty";
import { AutocompleteField } from "../../utils/autocomplete-field";
import { Grid } from "@mui/material";
import { FormikSaveBar } from "../../utils/FormikSaveBar";
import { Field } from "formik";
import { PMField } from "../../utils/PMField";
import { getProductAsync, updateProductAsync } from "../../../slices/products";
import { useDispatch } from "react-redux";
import PMSwitch from "../../utils/PMSwitch";
import { mapErrors } from "../../Utils";
import { QuillEditor } from "../../utils/quill-editor";
import { TextGeneratorButton } from "../ai/text-generator-button";
export const ProductInfo = (props) => {
  const { t } = useTranslation();
  const { product } = props;
  // Will be fetched form API in the future
  const tagOptions = ["Aggregat", "Reservedel", "Test3"];
  const dispatch = useDispatch();

  return (
    <>
      <Formik
        initialValues={{
          id: product?.id,
          item_number: product?.item_number,
          title: product?.title,
          short_description: product?.short_description || "",
          description: product?.description || "",
          sales_price: product?.sales_price,
          purchase_price: product?.purchase_price,
          sku: product?.sku || "",
          submit: null,
          tags: product?.tags || [],
          active: product?.active || false,
          isSparePart: product?.isSparePart || false,
          warehouse: product?.warehouse || false,
          online_store: product?.online_store || false,
        }}
        validationSchema={Yup.object({
          item_number: Yup.string()
            .max(50)
            .required(t("Item number is required")),
          title: Yup.string().max(255),
          short_description: Yup.string()
            .max(500)
            .required("Short description is required"),
          description: Yup.string(),
          sales_price: Yup.string(),
          purchase_price: Yup.string(),
          tags: Yup.array(),
          sku: Yup.string(),
        })}
        onSubmit={(values, helpers) => {
          try {
            toast.promise(dispatch(updateProductAsync(values)), {
              loading: t("Saving...."),
              success: (data) => {
                if (data.payload.response?.status === 500)
                  throw new Error("Server error");
                if (data.payload.response?.status === 400)
                  throw new Error("Server error");
                if (data.payload.response?.data.errors)
                  throw new Error(mapErrors(data.payload.response.data.errors));
                return t("Data was saved!");
              },
              error: t("Something went wrong. Could not save data!"),
            });
            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
          } catch (err) {
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <Grid item xs={12}>
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                  <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      {t("Product information")}
                    </h3>
                    <p className="mt-1 text-sm text-gray-500"></p>
                  </div>

                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <PMField
                        name="item_number"
                        type="text"
                        label={t("Item Number")}
                        {...formik}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <PMField
                        name="title"
                        type="text"
                        label={t("Title")}
                        {...formik}
                      />
                    </div>

                    <div className="col-span-6">
                      <PMField
                        name="short_description"
                        type="text"
                        label={t("Short Description")}
                        multiline
                        rows={2}
                        {...formik}
                      />
                    </div>

                    {/* <div className="col-span-6">
                      <AutocompleteField
                        error={Boolean(formik.touched.tags && formik.errors.tags)}
                        filterSelectedOptions
                        helperText={formik.touched.tags && formik.errors.tags}
                        label="Tags"
                        name="tags"
                        multiple
                        onChange={(event, value) => { formik.setFieldValue('tags', value); }}
                        options={tagOptions}
                        placeholder="Tag"
                        value={formik.values.tags}
                      />
                    </div> */}

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <PMField
                        name="purchase_price"
                        type="number"
                        label={t("Purchase Price")}
                        {...formik}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <PMField
                        name="sales_price"
                        type="number"
                        label={t("Sales Price")}
                        {...formik}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      {formik?.values?.sales_price &&
                        product?.sales_price_incl_vat && (
                          <span className="">
                            <PMField
                              readOnly={true}
                              disabled
                              name="sales_price_incl_vat"
                              label={t("Sales Price incl. vat")}
                              value={product?.sales_price_incl_vat}
                            />
                          </span>
                        )}
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2"></div>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                  {/* <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">{ t("Product Description") }</h3>
                    <p className="mt-1 text-sm text-gray-500"></p>
                  </div> */}
                  <div className="grid sm:grid-cols-8 gap-6">
                    <div className="sm:col-span-2">
                      <PMSwitch label={t("Active")} name="active" {...formik} />
                    </div>
                    {/* <div className="sm:col-span-2">
                      <PMSwitch
                        label={t("Spare Part")}
                        name="isSparePart"
                        {...formik}
                      />
                    </div> */}

                    <div className="sm:col-span-2">
                      <PMSwitch
                        label={t("Warehouse")}
                        name="warehouse"
                        {...formik}
                      />
                    </div>

                    <div className="sm:col-span-2">
                      <PMSwitch
                        label={t("Online Store")}
                        name="online_store"
                        {...formik}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                  <div className="flex justify-between">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      {t("Product Description")}
                    </h3>

                    <p className="mt-1 text-sm text-gray-500"></p>
                  </div>
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6">
                      <QuillEditor name="description" rows={4} {...formik} />
                    </div>
                    <div className="col-span-6 mt-2 mb-2 r-0">
                      <div className="flex">
                        <div>
                          <TextGeneratorButton
                            module={"product"}
                            module_param={formik?.values?.id}
                            text={formik?.values?.title}
                            onGenerate={(data) => {
                              formik.setFieldValue("description", data?.text);
                            }}
                          />
                        </div>
                        <div className="ml-2">
                          <TextGeneratorButton
                            module={"product"}
                            module_param={formik?.values?.id}
                            text={formik?.values?.description}
                            function="reformat"
                            onGenerate={(data) => {
                              formik.setFieldValue("description", data?.text);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>

            {/* <PromptIfDirty {...formik}/> */}
            <FormikSaveBar {...formik} />
          </Form>
        )}
      </Formik>
    </>
  );
};
