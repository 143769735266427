import { useTranslation } from "react-i18next";
import { PMSimpleSelectorField } from "../PMSimpleSelector/PMSimpleSelectorField";
import { PMSimpleSelectorFieldV2 } from "../PMSimpleSelector/PMSimpleSelectorFieldV2";
import axios from "../../../api/axios";
import { useState, useEffect } from "react";
import { mapErrors } from "../../Utils";
import { debounce } from "lodash";

export const ProjectSelector = (props) => {
  const {
    setFieldValue,
    values,
    errors,
    onChange,
    module,
    module_param,
    fields,
    showField,
  } = props;

  const name = props.name ? props.name : "project_id";
  const label = props?.label ? props.label : "Project";
  const FETCH_URL = `/projects/`;
  const CREATE_URL = `/projects/`;
  const filters = null;

  const { t } = useTranslation();

  const [query, setQuery] = useState();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({
    id: 0,
    field1: "Ingen valgt",
    field2: null,
  });
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);

  const labelAction = {
    path: "/dashboard/projects/" + selected?.id,
    // action: () => toast("test"),
  };

  useEffect(() => {
    if (props.open) {
      setOpen(true);
    }
  }, [props.open]);

  const handleChange = (data) => {
    getItem(data.selected.item.id);
    setOpen(false);
    if (setFieldValue) setFieldValue(name, data.selected.item.id);

    if (onChange) {
      onChange({
        target: {
          name: name,
          value: data.selected.item.id,
          object: data?.selected?.item?.object,
          data: data?.selected?.item,
        },
      });
    }
  };

  useEffect(() => {
    if (props?.selected?.id) {
      setSelected({
        id: props.selected?.id,
        field1: props.selected?.displayTitle,
      });
    }
  }, [props.selected]);

  const getItem = (id) => {
    return axios({ method: "get", url: FETCH_URL + id })
      .then((res) => {
        if (res.data.id) {
          setLoading(false);
          setSelected({
            id: res.data?.id,
            field1: res.data?.displayTitle,
            field2: res.data?.customer?.displayTitle,
            item: res?.data,
          });
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err.data.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setLoading(false);
      });
  };

  const getItems = () => {
    setLoading(true);
    const params = {
      query: query ? query : "",
      page: 1,
      sortBy: "created_at",
      sort: "desc",
      filters: filters,
      view: null,
      fields: "",
    };

    return axios({ method: "get", url: FETCH_URL, params: params })
      .then((response) => {
        if (response.data.data) {
          var array = [
            { id: null, value: null, primary: "Ingen valgt", secondary: null },
          ];
          if (!response.data.data) return array;
          response.data.data.map((row, i) => {
            const rowParams = {
              value: row.id,
              id: row.id,
              primary: row?.displayTitle,
              secondary: row?.customer?.displayTitle,
              object: row,
            };
            array.push(rowParams);
          });
          setItems(array);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        return error.response.data;
      });
  };

  const debouncedAction = debounce((controller) => {
    getItems();
  }, 500);

  useEffect(() => {
    if (open) {
      debouncedAction();
    }

    // Clean the debounced function on component unmount.
    return () => {
      debouncedAction.cancel();
    };
  }, [query, open]);

  return (
    <>
      <PMSimpleSelectorFieldV2
        showField={showField}
        loading={loading}
        errors={errors}
        name={name}
        label={t(label)}
        labelAction={labelAction}
        options={items}
        selected={selected}
        onChange={handleChange}
        onQueryChange={(query) => setQuery(query)}
        createView={null}
        onClose={() => setOpen(!open)}
        open={open}
      />
    </>
  );
};
