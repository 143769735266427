import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { PMField } from "../../utils/PMField";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { dispatchWithToast, mapErrors } from "../../Utils";
import {
  createCustomerAsync,
  updateCustomerAsync,
} from "../../../slices/customer";
import { fetchCustomer } from "../../../api/customer";
import {
  ArrowRightIcon,
  CheckBadgeIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { LoadingButton } from "@mui/lab";
import { ProjectsStackList } from "../projects/projects-stack-list";
import { ServiceAgreementsStackList } from "../service/agreements/agreements-stack-list";
import { ReportsStackList } from "../service/reports/reports-stack-list";
import { OrdersStacklist } from "../orders/orders-stack-list";
import { CasesStackList } from "../cases/cases-stack-list";
import { CustomerInfo } from "./customer-info";
import { TWclassNames } from "../../utils/Div";
import { PMDialog } from "../../utils/PMDialog";
export default function CustomerDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    customer: {},
  });

  // Deconstructing props
  const { open, onClose, selected, onRefresh, redirect } = props;

  // Deconstruct itemState (Store)
  const { customer, isLoading, error, errors } = itemState;

  const [readOnly, setReadOnly] = useState(props.readOnly);

  const getCustomer = async () => {
    return fetchCustomer(selected.id)
      .then((res) => {
        if (res?.data?.id) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            customer: res?.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err?.data?.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          customer: {},
          error: true,
          errors: errors,
        }));
      });
  };

  useEffect(() => {
    if (open) {
      if (selected?.id) {
        getCustomer();
      } else {
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          customer: {},
          error: false,
          errors: [],
        }));

        setReadOnly(false);
      }
    }
  }, [dispatch, open, selected]);

  const createOrUpdateDispatch = (data) => {
    if (customer?.id) {
      const res = dispatch(updateCustomerAsync(data));
      return res;
    } else {
      const res = dispatch(createCustomerAsync(data));
      return res;
    }
  };

  const redirectToCustomer = (data) => {
    if (data?.id) {
      navigate(`/dashboard/customers/` + data?.id);
    }
  };

  const CustomerForm = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          id: customer?.id,
          customer_number: customer?.customer_number,
          name: customer?.name,
          email: customer?.email,
          mobile: customer?.mobile,
          address: customer?.address,
          address_two: customer?.address_two,
          place: customer?.place,
          postal: customer?.postal,
          nextCustomerNumber: customer?.nextCustomerNumber || false,
          active: customer?.active || true,
          postal_address: customer?.postal_address?.id
            ? customer?.postal_address
            : {
                address: customer?.address,
                address2: customer?.address_two,
                postal: customer?.postal,
                place: customer?.place,
              },
          physical_address: customer?.physical_address?.id
            ? customer?.physical_address
            : "",
          submit: null,
        }}
        validationSchema={Yup.object({
          email: Yup.string().email(),
          // .required(t("Email is required")),
        })}
        onSubmit={(values, helpers) => {
          try {
            dispatchWithToast(
              createOrUpdateDispatch(values),
              redirect ? redirectToCustomer : onRefresh,
              onClose
            );
          } catch (err) {
            toast.error("Something went wrong...");
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            // helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <div className="grid grid-cols-6 gap-6 mt-10">
              <div className="col-span-6 sm:col-span-3">
                <PMField
                  disabled
                  name="customer_number"
                  type="text"
                  label={t("customer.number")}
                  readOnly={readOnly}
                  {...formik}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <PMField
                  name="name"
                  type="text"
                  label={t("customer.name")}
                  readOnly={readOnly}
                  {...formik}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <PMField
                  name="mobile"
                  type="text"
                  label={t("customer.mobile")}
                  readOnly={readOnly}
                  {...formik}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <PMField
                  name="email"
                  type="text"
                  label={t("customer.email")}
                  readOnly={readOnly}
                  {...formik}
                />
              </div>
            </div>
            <div className="border-top border-2 border-blue-400 mt-10" />
            <div className="mt-10 sm:overflow-hidden">
              <div className="grid sm:grid-cols-2">
                <div className="grid grid-cols-3 sm:grid-cols-3 bg-white gap-2">
                  <div className="col-span-3">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      {t("Postal Address")}
                    </h3>
                    <p className="mt-1 text-sm text-gray-500"></p>
                  </div>
                  <div className="col-span-3 sm:col-span-3">
                    <PMField
                      name="postal_address.address"
                      type="text"
                      label="Address"
                      readOnly={readOnly}
                      {...formik}
                    />
                  </div>
                  <div className="col-span-3 sm:col-span-3">
                    <PMField
                      name="postal_address.address2"
                      type="text"
                      label="Address 2"
                      readOnly={readOnly}
                      {...formik}
                    />
                  </div>
                  <div className="col-span-1 sm:col-span-1">
                    <PMField
                      name="postal_address.postal"
                      type="text"
                      label="Postal"
                      placeName="postal_address.place"
                      readOnly={readOnly}
                      {...formik}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-2">
                    <PMField
                      name="postal_address.place"
                      type="text"
                      label="Place"
                      readOnly={readOnly}
                      {...formik}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 sm:grid-cols-3 bg-white gap-2 pl-5">
                  <div className="col-span-3">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      {t("Physical Address")}
                    </h3>
                    <p className="mt-1 text-sm text-gray-500"></p>
                  </div>
                  <div className="col-span-3 sm:col-span-3">
                    <PMField
                      name="physical_address.address"
                      type="text"
                      label="Address"
                      readOnly={readOnly}
                      {...formik}
                    />
                  </div>
                  <div className="col-span-3 sm:col-span-3">
                    <PMField
                      name="physical_address.address2"
                      type="text"
                      label="Address 2"
                      readOnly={readOnly}
                      {...formik}
                    />
                  </div>
                  <div className="col-span-1 sm:col-span-1">
                    <PMField
                      name="physical_address.postal"
                      type="text"
                      label="Postal"
                      readOnly={readOnly}
                      {...formik}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-2">
                    <PMField
                      name="physical_address.place"
                      type="text"
                      label="Place"
                      readOnly={readOnly}
                      {...formik}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={onClose}
              >
                {t("Close")}{" "}
              </button>

              {readOnly ? null : (
                <LoadingButton
                  loading={formik.isSubmitting}
                  disabled={isLoading}
                  loadingPosition="start"
                  startIcon={<CheckBadgeIcon />}
                  type="button"
                  variant="contained"
                  color={"success"}
                  onClick={formik.submitForm}
                >
                  {customer.id ? t("Update") : t("Create")}
                </LoadingButton>
              )}
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  const [selectedTab, setSelectedTab] = useState(0);

  const handleRefresh = (data) => {
    setItemState((prevState) => ({
      ...prevState,
      customer: data,
      isLoading: false,
    }));
  };

  const handleLoading = () => {
    setItemState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
  };

  const tabs = [
    {
      name: "Detaljer",
      component: (
        <CustomerForm
          item={customer}
          onRefresh={(data) => handleRefresh(data)}
          onLoading={() => handleLoading()}
        />
      ),
    },
    {
      name: "Cases",
      component: (
        <CasesStackList
          module_param={customer.id}
          module="customer"
          filters={[
            {
              name: "customer",
              value: customer.id,
            },
          ]}
        />
      ),
    },
    {
      name: "Ordrer",
      component: (
        <OrdersStacklist
          module_param={customer.id}
          module="customer"
          filters={[
            {
              name: "customer",
              value: customer.id,
            },
          ]}
        />
      ),
    },
    {
      name: "Reports",
      component: (
        <ReportsStackList
          module_param={customer.id}
          module="customer"
          filters={[
            {
              name: "customer",
              value: customer.id,
            },
          ]}
        />
      ),
    },
    {
      name: "Agreements",
      component: (
        <ServiceAgreementsStackList
          module_param={customer.id}
          module="customer"
          filters={[
            {
              name: "customer",
              value: customer.id,
            },
          ]}
        />
      ),
    },
    {
      name: "Projects",
      href: "#",
      component: (
        <ProjectsStackList
          module_param={customer.id}
          module="customer"
          filters={[
            {
              name: "customer",
              value: customer.id,
            },
          ]}
        />
      ),
    },
  ];

  function customerTabs() {
    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            onChange={(e) => setSelectedTab(e.target.value)}
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={tabs[selectedTab].name}
          >
            {tabs.map((tab, tabIdx) => (
              <option key={tab.name} value={tabIdx}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav
            className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
            aria-label="Tabs"
          >
            {tabs.map((tab, tabIdx) => (
              <a
                key={tab.name}
                onClick={() => setSelectedTab(tabIdx)}
                className={TWclassNames(
                  tabIdx === selectedTab
                    ? "text-gray-900"
                    : "text-gray-500 hover:text-gray-700",
                  tabIdx === 0 ? "rounded-l-lg" : "",
                  tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                  "cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                )}
                aria-current={tabIdx === selectedTab ? "page" : undefined}
              >
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={TWclassNames(
                    tabIdx === selectedTab ? "bg-indigo-500" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </a>
            ))}
          </nav>
        </div>
      </div>
    );
  }

  const modalHeaderActions = [
    {
      label: "Gå til kunde",
      icon: ArrowRightIcon,
      path: `/dashboard/customers/` + customer?.id,
    },
    {
      label: readOnly ? t("edit") : t("read_only"),
      icon: readOnly ? XMarkIcon : PencilIcon,
      action: () => setReadOnly(!readOnly),
    },
  ];

  return (
    <PMDialog
      open={open}
      onClose={onClose}
      headerActions={modalHeaderActions}
      isLoading={isLoading}
      title={
        readOnly
          ? customer?.id
            ? customer?.displayTitle
            : t("customer")
          : customer?.id
          ? customer?.displayTitle
          : t("customers.create")
      }
    >
      <>
        {customerTabs()}
        <div className="py-5">{tabs[selectedTab].component}</div>
      </>
    </PMDialog>
  );
}

CustomerDialog.defaultProps = {
  selected: null,
  // Read only is used when only wanting to display info. Update or creating will not work.
  readOnly: false,
  open: false,
  selection: false,
  onClose: () => toast.error("Missing onClose function. Cannot close dialog"),
  redirect: false,
};
