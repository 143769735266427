import { UsersIcon } from "@heroicons/react/20/solid";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { ResourceEmpty } from "../../../utils/ResourceEmpty";
import { ResourceError } from "../../../utils/ResourceError";
import { BuildingOffice2Icon, WrenchIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { TWclassNames } from "../../../utils/Div";
import { toast } from "react-hot-toast";

export const IntegrationEventStacklist = (props) => {
  const { items, isLoading, error, errors, data, onSubscribe } = props;

  const navigate = useNavigate();

  if (isLoading)
    return (
      <div className="mt-10">
        <ResourceLoading />
      </div>
    );
  if (error) return <ResourceError errors={errors} />;
  if (!items.length > 0) return <ResourceEmpty />;

  return (
    <>
      {items && (
        <div className={`hidden sm:grid grid-cols-4 gap-4 sm:grid-cols-4`}>
          {items?.map((item) => (
            <div
              key={item.id}
              onClick={item?.can_subscribe ? () => onSubscribe(item) : null}
              className={TWclassNames(
                item.isSubscribed ? "border-green-400" : "",
                item?.can_subscribe
                  ? "cursor-pointer bg-white"
                  : "cursor-not-allowed bg-red-400",
                "relative flex items-center grid-span-1 space-x-3 rounded-lg border border-gray-300 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
              )}
            >
              <div className="min-w-0 flex-1">
                <a className="focus:outline-none">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm font-medium text-gray-900">
                    {item.title}
                  </p>
                  <p className="truncate text-sm text-gray-500"></p>
                </a>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

IntegrationEventStacklist.defaultProps = {};
