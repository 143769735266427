import { useTranslation } from "react-i18next";
import { TWclassNames } from "../../utils/Div";
import { TimeSinceInWords } from "../../Utils";
import { useState } from "react";
import { Transition } from "@headlessui/react";

export function ProductWarehouseStatus({ report }) {
  const { t } = useTranslation();

  const { warehouse_status_type_id } = report;

  const index = warehouse_status_type_id - 1;

  if ((!report && !warehouse_status_type_id) || warehouse_status_type_id === 0)
    return null;

  switch (warehouse_status_type_id) {
    case 1:
      return (
        <span
          className={TWclassNames(
            "bg-green-400 text-white",
            "inline-flex rounded-full px-4 py-1 text-xs font-semibold leading-5"
          )}
        >
          På lager
        </span>
      );
    case 2:
      return (
        <span
          className={TWclassNames(
            "bg-orange-200 text-black",
            "inline-flex rounded-full px-4 py-1 text-xs font-semibold leading-5"
          )}
        >
          In stock: {report?.stock ? report?.stock : 0}
        </span>
      );
    case 3:
      return (
        <span
          className={TWclassNames(
            "bg-yellow-400 text-white",
            "inline-flex rounded-full px-4 py-1 text-xs font-semibold leading-5"
          )}
        >
          Bestillingsvare
        </span>
      );
    case 4:
      return (
        <span
          className={TWclassNames(
            "bg-red-400 text-white",
            "inline-flex rounded-full px-4 py-1 text-xs font-semibold leading-5"
          )}
        >
          Utgått
        </span>
      );
    default:
      return null;
  }
}

export const productViewsOptions = [
  { id: "ACTIVE", value: "ACTIVE", label: "Aktive" },
  {
    id: "NOT_CATEGORIZED",
    value: "NOT_CATEGORIZED",
    label: "Ikke kategorisert",
  },
];

export const ProductLastUpdatedStatus = ({ product }) => {
  const { updated_at } = product;

  const now = new Date();
  const updated = new Date(updated_at);
  const diff = now - updated;
  const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));

  return (
    <span
      className={TWclassNames(
        diffDays < 30 && "bg-blue-400 text-white",
        diffDays > 30 && "bg-yellow-400",
        diffDays > 60 && "bg-red-400",
        "inline-flex rounded-full px-4 py-1 text-xs font-semibold leading-5"
      )}
    >
      <div className="flex flex-col text-left">
        <TimeSinceInWords date={updated_at} /> siste oppdatering
      </div>
    </span>
  );
};

export const priceChangePercentage = (price, oldPrice) => {
  const diff = price - oldPrice;
  const percentage = (diff / oldPrice) * 100;
  return percentage.toFixed(2);
};
