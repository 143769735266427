import {
  UsersIcon,
  CheckCircleIcon,
  MapIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { Popup, TWclassNames } from "./utils/Div";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { PMNotice } from "./utils/notifications";
import SimplePrompt from "./utils/simplePrompt";
import CustomerDialog from "./dashboard/customers/dialog";
import parse from "html-react-parser";

export const mapErrors = (errors) => {
  const mappedErrors = [];
  if (!errors) return false;
  for (const [key, value] of Object.entries(errors)) {
    mappedErrors.push(value.message);
  }
  return mappedErrors.join(", ");
};

export const formatter = new Intl.NumberFormat("nb-NO", {
  style: "currency",
  currency: "NOK",
});

export const dateToString = (dateTime) => {
  if (dateTime === null) return null;

  const locale = "nb-NO";

  // Exclude time if time is not included in dateTime

  // Check if dateTime has time
  const hasTime = dateTime?.split("T")[1] ? true : false;

  let options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
  };

  if (hasTime) {
    options = {
      ...options,
      time: true,
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    };
  }

  const date = new Date(dateTime).toLocaleString(locale, options);

  return date;
};

export const STATUSES = [
  { id: 1, title: "New Case", value: "NEW" },
  { id: 2, title: "Being procesed", value: "PROCESING" },
  { id: 3, title: "Finished", value: "FINISHED" },
];

export const status = {
  null: { label: "Draft", class: "bg-gray-200 text-black" },
  "": { label: "Draft", class: "bg-gray-200 text-black" },
  draft: { label: "Draft", class: "bg-gray-200 text-black" },
  new: { label: "New", class: "bg-yellow-200 text-green-800" },
  ongoing: { label: "Ongoing", class: "bg-green-100 text-green-800" },
  accomplished: { label: "Accomplished", class: "bg-green-600 text-white" },
  finished: { label: "Finished", class: "bg-green-600 text-white" },
  processing: { label: "Processing", class: "bg-yellow-600 text-white" },
  processed: { label: "Processed", class: "bg-blue-600 text-white" },
  closed: { label: "Closed", class: "bg-gray-600 text-white" },
  cancelled: { label: "Cancelled", class: "bg-red-600 text-white" },
  ready_invoice: {
    label: "Ready to be invoiced",
    class: "bg-red-600 text-white",
  },
  invoiced: { label: "Invoiced", class: "bg-indigo-600 text-white" },
  paid: { label: "Paid", class: "bg-green-600 text-white" },
  inactive: { label: "Inactive", class: "bg-yellow-400 text-white" },
  active: { label: "Active", class: "bg-green-600 text-white" },
};

// Update the URL parameters
// Updates all params in the url, except the "exception" param
export const updateUrlWithException = (newParams, exception) => {
  // Update the URL parameters
  const searchParams = new URLSearchParams(window.location.search);
  Object.entries(newParams).forEach(([key, value]) => {
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
  });
  // Preserve the existing filters parameter
  const filtersParam = searchParams.get(exception);
  if (filtersParam) {
    searchParams.set(exception, filtersParam);
  }

  // Construct the updated URL with all the search parameters
  const queryParams = Array.from(searchParams)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  const newUrl = `${window.location.pathname}?${queryParams}`;
  window.history.pushState({}, "", newUrl);
};

export const updateUrl = (selectedFilters) => {
  // Construct the filter values string from the selected filters
  const filterValues = selectedFilters
    ?.map((filter) => `${filter.name}:${filter?.values?.join("|")}`)
    .join(",");

  // Update the URL parameters
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set("filters", filterValues);

  // Construct the updated URL with all the search params
  const queryParams = Array.from(searchParams)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  const newUrl = `${window.location.pathname}?${queryParams}`;
  window.history.pushState({}, "", newUrl);
};

export function StringLimit({ text, limit }) {
  if (text) {
    if (text?.length <= limit) {
      return <span>{text}</span>;
    } else {
      return (
        <Popup content={text} textSize="md">
          <span>{text?.slice(0, limit)}...</span>
        </Popup>
      );
    }
  } else {
    return null;
  }
}

// Standard headerActions for DashboardHeader

export const customerHeaderAction = (customer) => {
  return {
    label: customer?.displayTitle,
    description: "",
    path: "/dashboard/customers/" + customer?.id,
    action: null,
    icon: UsersIcon,
    textColor: "text-teal-700",
    hidden: customer?.id ? false : true,
  };
};

export function ItemStatus({ item }) {
  const { t } = useTranslation();

  return (
    <span
      className={TWclassNames(
        item?.status ? status[item?.status].class : "",
        "inline-flex rounded-full px-2 text-xs font-semibold leading-5"
      )}
    >
      {item?.status ? <span> {t(status[item?.status].label)} </span> : "None"}
    </span>
  );
}

export function SyncStatus({ item }) {
  return (
    <span>
      <CheckCircleIcon
        className={TWclassNames(
          item?.syncStatus ? "text-green-400" : "text-red-400",
          "w-5 h-5"
        )}
      />
    </span>
  );
}
export default function UppercaseFirstLetter({ text }) {
  const capitalizedText = text.charAt(0).toUpperCase() + text.slice(1);

  return <div>{capitalizedText}</div>;
}

export const upperFirstLetter = (text) => {
  const capitalizedText = text.charAt(0).toUpperCase() + text.slice(1);
  return capitalizedText;
};

export function pageOptions({ perPage, totalCount }) {
  const itemsPerPage = perPage > 1 ? perPage : 200;

  const totalPages = Math.ceil(totalCount / itemsPerPage);

  const pageOptions = [];

  for (let i = 1; i <= totalPages; i++) {
    pageOptions.push(i);
  }

  return pageOptions;
}

export const dispatchWithToast = (dispatch, onRefresh, onClose) => {
  toast.promise(dispatch, {
    loading: "Saving...",
    success: (data) => {
      if (data?.payload?.errors) {
        data?.payload?.errors?.map((error) => {
          toast.error(error, { duration: 6000 });
        });

        throw new Error("Server error");
      }

      if (data?.payload?.messages) {
        data?.payload?.messages?.map((message) => {
          toast.success(message, { duration: 6000 });
        });
      }

      if (data?.payload?.id || data?.payload?.data?.id) {
        if (onRefresh) {
          onRefresh(data?.payload);
        }
        if (onClose) {
          onClose(true);
        }
        return "Saved successfully";
      }

      if (
        data?.payload?.success ||
        (data?.payload?.messages &&
          !data?.payload?.id &&
          !data?.payload?.data?.id)
      ) {
        if (onRefresh) {
          onRefresh(data?.payload);
        }
        if (onClose) {
          onClose(true);
        }
        return "Saved successfully";
      }

      console.log("data", data);

      if (!data?.payload?.id && !data?.payload?.data?.id) {
        console.log("nope");
        throw new Error("Server error");
      }

      throw new Error("Server error");
    },
    error: (error) => {
      console.log(error);
      return "Something went wrong. Could not save data!";
    },
  });
};

export const axiosWithToast = (
  action,
  onRefresh = () => {},
  onClose = () => {},
  setData = () => {},
  savingText = "Saving..."
) => {
  toast.promise(action, {
    loading: savingText,
    success: (data) => {
      if (data === undefined) {
        throw new Error("Server error");
      }

      console.log(data);

      if (data?.data?.errors) {
        data?.data?.errors?.map((error) => {
          toast.error(error, { duration: 6000 });
        });

        throw new Error("Server error");
      }

      if (data?.data?.messages) {
        data?.data?.messages?.map((message) => {
          toast.success(message, { duration: 6000 });
        });
      }

      if (data?.status === 200 || data?.data?.id || data?.data?.data?.id) {
        setData(data?.data);
        if (onRefresh) {
          onRefresh(data?.data);
        }
        if (onClose) {
          onClose(true);
        }
        return "Saved successfully";
      } else {
        throw new Error("Server error");
      }
    },
    error: (error) => {
      console.log(error);
      return error?.response?.data?.errors[0] || "Task was not successful";
    },
  });
};

export function formattedDate(date) {
  return new Date(date).toLocaleString();
}

export const productPath = ({ product }) => {
  return "/dashboard/products/" + product?.id;
};

export const reportPath = ({ report }) =>
  "/dashboard/service/reports/" + report?.id;

export const orderPath = ({ order }) => {
  return "/dashboard/orders/" + order?.id;
};

export const customerPath = ({ customer }) =>
  "/dashboard/customers/" + customer?.id;

export const projectPath = ({ project }) =>
  "/dashboard/projects/" + project?.id;

export const employeePath = ({ employee }) =>
  "/dashboard/employees/" + employee?.id;

export const serviceAgreementPath = ({ serviceAgreement }) =>
  "/dashboard/service/agreements/" + serviceAgreement?.id;

export const deliveryAddressGooglePath = ({ deliveryAddress }) =>
  "https://www.google.com/maps?q=" +
  deliveryAddress?.address +
  ", " +
  deliveryAddress?.postal +
  " " +
  deliveryAddress?.place;

export const ProjectTitle = ({
  project,
  path = projectPath({ project: project }),
  className,
  folder,
}) => {
  return (
    <Link to={path}>
      <div className={TWclassNames(className ? className : "text-blue-400")}>
        {project?.displayTitle} {folder?.id && " / " + folder?.displayTitle}
      </div>
    </Link>
  );
};

export const EmployeeTitle = ({
  employee,
  // path = employeePath({ employee: employee }),
  path = null,
  className,
  folder,
}) => {
  const Content = () => (
    <div
      className={TWclassNames(
        className ? className : path ? "text-blue-400" : ""
      )}
    >
      {employee?.displayTitle}
    </div>
  );
  if (path) {
    return (
      <Link to={path}>
        <Content />
      </Link>
    );
  } else {
    return (
      <span>
        <Content />
      </span>
    );
  }
};

export const ServiceAgreementTitle = ({
  serviceAgreement,
  target = "_blank",
  path = serviceAgreementPath({
    serviceAgreement: serviceAgreement,
  }),
  className,
}) => {
  return (
    <Link to={path} target={target}>
      <div className={TWclassNames(className ? className : "text-blue-400")}>
        {serviceAgreement?.displayTitle}
      </div>
    </Link>
  );
};

export const ReportTitle = ({
  report,
  path = reportPath({ report: report }),
  className,
}) => {
  return (
    <Link to={path}>
      <div className={TWclassNames(className ? className : "text-blue-400")}>
        {report?.displayTitle}
      </div>
    </Link>
  );
};

export const OrderTitle = ({
  order,
  path = orderPath({ order: order }),
  className,
}) => {
  return (
    <Link to={path}>
      <div className={TWclassNames(className ? className : "text-blue-400")}>
        {order?.displayTitle}
      </div>
    </Link>
  );
};

export const ContactTitle = ({ contact, path = null, className }) => {
  return (
    <Link to={null}>
      <div className={TWclassNames(className ? className : "text-blue-400")}>
        {contact?.displayTitle}
      </div>
    </Link>
  );
};

export const CustomerTitle = ({
  customer,
  path = customerPath({ customer: customer }),
  className,
  useDialog = false,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  if (useDialog) {
    return (
      <>
        <button
          type="button"
          onClick={() => setDialogOpen(true)}
          className={TWclassNames(className ? className : "text-blue-400")}
        >
          {customer?.displayTitle}
        </button>
        <CustomerDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          onClose={() => setDialogOpen(false)}
          selected={customer}
        />
      </>
    );
  } else {
    return (
      <Link to={path}>
        <div className={TWclassNames(className ? className : "text-blue-400")}>
          {customer?.displayTitle}
        </div>
      </Link>
    );
  }
};

export const SystemTitle = ({ system, path = null, className }) => {
  return (
    <Link to={null}>
      <div className={TWclassNames(className ? className : "text-blue-400")}>
        {system?.displayTitle}
      </div>
    </Link>
  );
};

export const DeliveryAddressTitle = ({
  deliveryAddress,
  path = deliveryAddressGooglePath({ deliveryAddress: deliveryAddress }),
  className,
}) => {
  if (!deliveryAddress?.id) return <span>Missing delivery address</span>;
  return (
    <Link to={path}>
      <div className={TWclassNames(className ? className : "text-blue-400")}>
        {deliveryAddress?.displayTitle}
      </div>
    </Link>
  );
};

export const NewLineFormatter = ({ value }) => {
  const isHTML = /<\/?[a-z][\s\S]*>/i.test(value); // Basic regex to check if the string contains HTML tags

  if (isHTML) {
    return (
      <div
        className="htmlContent"
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    );
  } else {
    const text = value?.split("\n").map((str, index) => (
      <Fragment key={index}>
        {str}
        <br />
      </Fragment>
    ));

    return <div>{text}</div>;
  }
};

// Create a counting animation that starts from 0 and stops at the value
// The animation is triggered when the component is mounted
export const CountUp = ({ value, duration = 1 }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const framesPerSecond = 60;
    const totalFrames = framesPerSecond * duration;
    const increment = value / totalFrames;

    const interval = setInterval(() => {
      if (count < value) {
        setCount((count) => Math.min(count + increment, value));
      } else {
        clearInterval(interval);
      }
    }, (duration * 1000) / framesPerSecond);

    return () => clearInterval(interval);
  }, []);

  return <span>{count.toFixed(2)}</span>;
};

export function generateRandomString() {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export const TimeSinceInWords = ({ date }) => {
  const now = new Date();
  const updated = new Date(date);
  const diff = now - updated;
  const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor(diff / (1000 * 60 * 60));
  const diffMinutes = Math.floor(diff / (1000 * 60));
  const diffSeconds = Math.floor(diff / 1000);

  if (diffDays > 0) {
    return `${diffDays} dager siden`;
  }
  if (diffHours > 0) {
    return `${diffHours} timer siden`;
  }
  if (diffMinutes > 0) {
    return `${diffMinutes} minutter siden`;
  }
  if (diffSeconds > 0) {
    return `${diffSeconds} sekunder siden`;
  }
  return "Nå";
};

export const fullDeliveryAddress = (address) => {
  const address2 = address?.address2 ? address?.address2 + ", " : "";

  return (
    address?.address + ", " + address2 + address?.postal + " " + address?.place
  );
};
export function OperationStatusWithNotice({
  operations,
  operationName,
  title,
}) {
  const { t } = useTranslation();

  // filter operations by operation name
  const filteredOperations = operations?.filter(
    (operation) => operation?.message === operationName
  );

  console.log(filteredOperations);

  function setCanBeCompleted() {
    // Check if any filteredOperations has can_be_completed set to false
    const canBeCompleted = filteredOperations?.every(
      (operation) => operation?.can_be_completed === true
    );

    // return false if any operation has can_be_completed set to false
    return canBeCompleted;
  }

  function description() {
    if (filteredOperations.length === 0) return "Alt er bra ut!";

    if (setCanBeCompleted()) return "Rapporten kan ferdigstilles uten";

    return "Rapporten kan ikke ferdigstilles uten";
  }

  function type() {
    if (filteredOperations.length === 0) return "success";

    if (setCanBeCompleted()) return "warning";

    return "error";
  }

  return <PMNotice title={title} description={description()} type={type()} />;
}

export function OperationsStatus({ operations, operationName, title }) {
  const { t } = useTranslation();

  // filter operations by operation name
  const filteredOperations = operations?.filter(
    (operation) => operation?.name === operationName
  );

  // make sure that filteredOperations has unique messages
  const uniqueMessages = [
    ...new Set(filteredOperations.map((operation) => operation.message)),
  ];

  function setCanBeCompleted() {
    // Check if any filteredOperations has can_be_completed set to false
    const canBeCompleted = filteredOperations?.every(
      (operation) => operation?.can_be_completed === true
    );

    // return false if any operation has can_be_completed set to false
    return canBeCompleted;
  }

  if (setCanBeCompleted()) return null;

  function description() {
    if (filteredOperations.length === 0) return "Alt er bra ut!";

    if (setCanBeCompleted()) return "";

    return uniqueMessages.map((message, index) => (
      <li key={index}>{t(message)}</li>
    ));
  }

  function type() {
    if (filteredOperations.length === 0) return "success";

    if (setCanBeCompleted()) return "warning";

    return "error";
  }

  return (
    <PMNotice
      title={"Kan ikke utføres før dette er rettet"}
      description={<ul className="ml-4 list-disc">{description()}</ul>}
      type={type()}
    />
  );
}

export function OperationCanFinish({ operations, operationName }) {
  // filter operations by operation name
  const filteredOperations = operations?.filter(
    (operation) => operation?.name === operationName
  );

  function canBeCompleted() {
    // Check if any filteredOperations has can_be_completed set to false
    const canBeCompleted = filteredOperations?.every(
      (operation) => operation?.can_be_completed === true
    );

    // return false if any operation has can_be_completed set to false
    return canBeCompleted;
  }

  console.log(filteredOperations);

  if (filteredOperations?.length === 0) return true;

  if (canBeCompleted()) return true;

  return false;
}

function LoadingColumns({ colSpan }) {
  return (
    <td colSpan={colSpan} className="px-5 py-4 whitespace-nowrap">
      <div className="flex flex-col justify-center">
        <div className="flex flex-col animate-pulse">
          <div className="w-20 h-4 mb-2 bg-gray-400 rounded"></div>
          <div className="w-20 h-4 bg-gray-400 rounded"></div>
        </div>
      </div>
    </td>
  );
}

export function LoadingRows({ cols = 5, checkboxCell = false }) {
  const cells = [];

  for (let i = 0; i < cols; i++) {
    cells.push(<LoadingColumns colSpan="1" />);
  }

  return (
    <tr>
      {checkboxCell && <td className="px-5 py-4 whitespace-nowrap" />}
      {cells}
    </tr>
  );
}

export const searchFieldsByName = (item, name) => {
  return item?.fields?.find((field) => field?.name === name);
};

// Returns the change (array with 2 values, old and new) of a field in a form
export function ChangedValues({ change, module }) {
  const allowedModules = ["description", "status", "priority", "type"];

  const { t } = useTranslation();

  if (!change) return null;

  const [oldValue, newValue] = change;

  if (oldValue === newValue) return null;

  // check if oldValue and newValue is a string
  if (typeof oldValue === "string" && typeof newValue === "string") {
    return (
      <div className="flex items-center">
        <div className="flex-shrink-0 w-2.5 h-2.5 rounded-full bg-red-500"></div>
        <div className="ml-1 text-sm font-medium text-gray-900">
          {t("Changed from")} {oldValue ? t(oldValue) : t("None")} {t("to")}{" "}
          {t(newValue)}
        </div>
      </div>
    );
  } else if (allowedModules.includes(module)) {
    return (
      <div className="flex items-center">
        <div className="flex-shrink-0 w-2.5 h-2.5 rounded-full bg-red-500"></div>
        <div className="ml-1 text-sm font-medium text-gray-900">
          {t("Changed from")} {oldValue ? t(oldValue) : t("None")} {t("to")}{" "}
          {t(newValue)}
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex items-center">
        <div className="flex-shrink-0 w-2.5 h-2.5 rounded-full bg-red-500"></div>
        <div className="ml-1 text-sm font-medium text-gray-900">
          {t("Changed from")} {oldValue ? t(oldValue) : t("None")} {t("to")}{" "}
          {t(newValue)}
        </div>
      </div>
    );
  }
}

// function that takes a name and returns a tag with initials
export function getInitials(name) {
  if (!name) return null;
  const initials = name.match(/\b\w/g) || [];

  // return first and last
  return initials.shift() + initials.pop();
}

//
export function InitialsTag({ name }) {
  if (!name) return null;

  // Improved hash function
  const hashString = (str) => {
    let hash = 5381; // Using a larger initial prime number to start
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash * 33) ^ char; // Multiply hash by 33 and XOR with the character code
    }
    return Math.abs(hash); // Ensure the hash is non-negative
  };

  const colors = [
    "bg-red-500",
    "bg-yellow-500",
    "bg-green-500",
    "bg-blue-500",
    "bg-indigo-500",
    "bg-pink-500",
    "bg-purple-500",
    "bg-gray-500",
    "bg-orange-500",
  ];

  const colorIndex = hashString(name) % colors.length;
  const color = colors[colorIndex];

  // Text color for contrast
  const textColor = "text-white";

  return (
    <span
      className={
        "inline-flex items-center justify-center h-6 w-6 rounded-full " + color
      }
    >
      <span
        className={TWclassNames(textColor, "text-sm font-medium leading-none")}
      >
        {getInitials(name)}
      </span>
    </span>
  );
}

export const PromptBeforeClose = ({ formik, message }) => {
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (formik.dirty) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [formik.dirty]);

  // useEffect(() => {
  //   window.onbeforeunload = isDirty ? () => true : undefined;
  // }, [isDirty]);

  console.log("prompt", isDirty);

  return <SimplePrompt open={isDirty} message={message} />;
};

// Remove html from text, but format line-breaks as /n
export const removeHtml = (text) => {
  if (!text) return null;

  const regex = /(<([^>]+)>)/gi;
  const result = text.replace(regex, "\n");

  return result;
};

export const GoogleMapsButton = ({ address }) => {
  const FULL_ADDRESS =
    address?.address +
    ", " +
    (address?.address2 ? ", " + address?.address2 : "") +
    address?.postal +
    ", " +
    address?.place;

  console.log(address);
  if (address?.address?.length > 2) {
    return (
      <div className="">
        <a
          aria-disabled={address ? true : false}
          href={"https://www.google.com/maps?q=" + FULL_ADDRESS}
          rel="noreferrer"
          target="_blank"
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-blue-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <MapIcon className="h-4 w-4" aria-hidden="true" />
          <span className="ml-2">Google</span>
        </a>
      </div>
    );
  } else {
    return null;
  }
};

// Utility function to add classes dynamically
const addClassesToTags = (node, classes) => {
  if (node && node.type === "tag" && classes[node.name]) {
    node.attribs = { ...node.attribs, class: classes[node.name] };
  }
  return node;
};

export const HtmlTextFormatter = ({ string, classes }) => {
  if (!string) return null;

  // Example: string = "<p>Some text</p><h1>Title</h1>"
  // Example: classes = { p: 'text-base mb-4', h1: 'text-2xl font-bold' }

  // Parse and apply the classes
  const parsedHtml = parse(string, {
    replace: (domNode) => addClassesToTags(domNode, classes),
  });

  return <div>{parsedHtml}</div>;
};
