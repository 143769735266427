import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Table } from "../../../utils/PMTable/Table";
import { TableBody } from "../../../utils/PMTable/TableBody";
import { PMPagination } from "../../../utils/PMPagination";
import { Navigate, useNavigate } from "react-router-dom";
import imgPlaceholder from "../../../../utils/img-placeholder.png";
import { PMField } from "../../../utils/PMField";
import { toast } from "react-hot-toast";
import { TWclassNames } from "../../../utils/Div";
import {
  FullReportAddress,
  ReportAppointmentApprovedStatus,
  ReportMustBeRectified,
  ReportStatus,
  reportStatuses,
} from "./utils";
import { LoadingRows } from "../../../Utils";
import { ResourceEmpty } from "../../../utils/ResourceEmpty";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const ReportsTable = (props) => {
  const {
    itemState,
    onPageChange,
    itemsCount,
    currentCount,
    page,
    perPage,
    onItemsSelect,
  } = props;
  const { data, isLoading, errors } = itemState;
  const reports = data;
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedReports, setSelectedReports] = useState([]);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedReports.length > 0 && selectedReports.length < reports.length;
    setChecked(selectedReports.length === reports.length);
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
    onItemsSelect(selectedReports);
  }, [selectedReports]);

  function toggleAll() {
    setSelectedReports(checked || indeterminate ? [] : reports);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const navigate = useNavigate();

  const renderRows = () => {
    return reports?.map((report) => (
      <tr
        key={report.id}
        className={TWclassNames(
          selectedReports.includes(report) ? "bg-gray-50" : null,
          "hover:bg-gray-50"
        )}
      >
        <td className="relative w-12 px-6 sm:w-16 sm:px-8">
          {selectedReports.includes(report) && (
            <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
          )}
          <input
            type="checkbox"
            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
            value={data.id}
            checked={selectedReports.includes(report)}
            onChange={(e) =>
              setSelectedReports(
                e.target.checked
                  ? [...selectedReports, report]
                  : selectedReports.filter((p) => p !== report)
              )
            }
          />
        </td>
        <td
          onClick={() => navigate("/dashboard/service/reports/" + report.id)}
          className={classNames(
            "whitespace-nowrap py-4 pr-3 text-sm font-medium",
            selectedReports.includes(report)
              ? "text-indigo-600"
              : "text-gray-900"
          )}
        >
          <div className="flex items-center">
            <div className="ml-4">
              <div className="font-medium text-gray-900">
                {report.report_number}
              </div>
              <div className="text-gray-500">{report.report_type?.title}</div>
            </div>
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <div className="text-gray-900">
            {report?.customer?.customer_number + " " + report?.customer?.name}
          </div>
          <div className="text-gray-500">
            {report?.project?.id ? report?.project?.displayTitle : ""}
          </div>
        </td>

        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {FullReportAddress(report)}
        </td>

        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <div className="flex flex-col">
            <div className="mt-2">
              <ReportStatus report={report} />
            </div>
            <div className="mt-2">
              <ReportAppointmentApprovedStatus report={report} type="short" />
            </div>

            <div className="mt-2">
              <ReportMustBeRectified rectify={report?.must_be_rectified} />
            </div>
          </div>
        </td>
      </tr>
    ));
  };

  return (
    <div>
      <PMPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        perPage={perPage}
        rowsCount={itemsCount}
        totalCount={itemsCount}
        currentCount={currentCount}
        showCount={false}
        showPages={false}
      />
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="relative w-12 px-6 sm:w-16 sm:px-8"
                    >
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th
                      scope="col"
                      className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Report Number / Type
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Customer / Project
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Address
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {isLoading ? (
                    <>
                      <LoadingRows cols={4} checkboxCell={true} />
                      <LoadingRows cols={4} checkboxCell={true} />
                      <LoadingRows cols={4} checkboxCell={true} />
                      <LoadingRows cols={4} checkboxCell={true} />
                      <LoadingRows cols={4} checkboxCell={true} />
                      <LoadingRows cols={4} checkboxCell={true} />
                      <LoadingRows cols={4} checkboxCell={true} />
                      <LoadingRows cols={4} checkboxCell={true} />
                      <LoadingRows cols={4} checkboxCell={true} />
                      <LoadingRows cols={4} checkboxCell={true} />
                    </>
                  ) : data.length < 1 ? (
                    <tr>
                      <td colSpan={5} className="py-2 px-2">
                        <ResourceEmpty />
                      </td>
                    </tr>
                  ) : (
                    renderRows()
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <PMPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        perPage={perPage}
        rowsCount={itemsCount}
        totalCount={itemsCount}
        currentCount={currentCount}
        showCount={true}
      />
    </div>
  );
};

ReportsTable.defaultProps = {
  page: 1,
  itemState: [],
  itemsCount: 0,
  selectedItems: [],
  sort: "desc",
  sortBy: "createdAt",
};
