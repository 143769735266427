import produce from "immer";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchStorage, fetchStorages, updateStorage, createStorage, deleteStorage } from '../../api/inventory/storage'

const initialState = {
    isLoading: false,
    error: false,
    errors: null,
    data: [{}],
    storage: {}
};


export const deleteStorageAsync = createAsyncThunk(
    'inventory_storages/deleteStorageAsync', 
    async (payload) => {


        let response = await deleteStorage(payload);
        
        // If errors, reject and return response
        if (response.error) return false

        // If all good, return response.
        return response;
    }
)

export const createStorageAsync = createAsyncThunk(
    'inventory_storages/createStorageAsync', 
    async (payload) => {
        
        ("Running: storages/createStorageAsync")
        let response = await createStorage(payload);
        
        // If errors, reject and return response
        if (response.error) return false

        // If all good, return response.
        return response;
    }
)

export const updateStorageAsync = createAsyncThunk(
    'inventory_storages/updateStorageAsync', 
    async (payload) => {
        
        let response = await updateStorage(payload);
        
        // If errors, reject and return response
        if (response.error) return false

        // If all good, return response.
        return response;
    }
)
export const getStorages = createAsyncThunk(
    'inventory_storages/getStorages', 
    async (_, { rejectWithValue }) => {


        // if (! controller) return false;
        let response = await fetchStorages();
        
        // If errors, reject and return response

        if (response.error) return rejectWithValue(response.error.status);

        // If all good, return response.
        return response;
})

export const getStorageAsync = createAsyncThunk(
    'inventory_storages/getStorageAsync',
    async (id, { rejectWithValue }) => {

        // if (! controller) return false;
        let response = await fetchStorage(id);

        // If errors, reject and return response
        if (response.error) return rejectWithValue(response);
        // If all good, return response.
        return response;
    })



const InventoryStorageSlice = createSlice({
    name: 'inventory_storages',
    initialState,
    reducers: {
    },
    extraReducers : (builder) => {
        builder.addCase(getStorages.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getStorages.fulfilled, (state, action ) => {
            state.isLoading = false;
            state.data = action.payload;
        })
        .addCase(getStorages.rejected, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errors = ['Something went wrong....could not fetch storages?']
        })
        .addCase(updateStorageAsync.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(updateStorageAsync.fulfilled, (state, action ) => {
            return produce(state, (draftState) => {
                const index = draftState.data.findIndex(item => item.id === action.payload.id);
                draftState.data[index] = action.payload;
                draftState.isLoading = false;
            })
        })
        .addCase(updateStorageAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errors = ['Something went wrong when trying to update report type']
        })
        .addCase(createStorageAsync.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(createStorageAsync.fulfilled, (state, action ) => {
            return produce(state, (draftState) => {
                draftState.data.push(action.payload)
                draftState.isLoading = false;
            })
        })
        .addCase(createStorageAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errors = ['Something went wrong when trying to update report type']
        })
        .addCase(deleteStorageAsync.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(deleteStorageAsync.fulfilled, (state, action ) => {
            return produce(state, (draftState) => {
                draftState.data = action.payload;
                draftState.isLoading = false;
            })
        })
        .addCase(deleteStorageAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errors = ['Something went wrong when trying to update storage']
        })
            .addCase(getStorageAsync.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getStorageAsync.fulfilled, (state, action) => {
                return produce(state, (draftState) => {
                    draftState.storage = action.payload;
                    draftState.isLoading = false;
                })
            })
            .addCase(getStorageAsync.rejected, (state, action) => {
                state.isLoading = false;
                state.error = true;
                state.errors = ['Something went wrong when trying to update fetch storage.']
        })
    }
})


// export const {} = customerSlice.actions;

export const { reducer } = InventoryStorageSlice;

export default InventoryStorageSlice

export const selectInventoryStorageById = (state, inventoryStorageId) => state.inventory_storages.data.find(storage => storage.id == inventoryStorageId)
