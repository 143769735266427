import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  caseStatusOptions,
  casesViewsOptions,
} from "../../../components/dashboard/cases/utils";
import { ProductsFilter } from "../../../components/dashboard/products/products-filter";
import { getCasesAsync } from "../../../slices/case";
import { useSearchParams } from "react-router-dom";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import { PlusIcon } from "@heroicons/react/24/outline";
import CaseDialog from "../../../components/dashboard/cases/case-dialog";
import { updateUrlWithException } from "../../../components/Utils";
import { debounce } from "lodash";
import { priorityOptions } from "../../../components/dashboard/quality/deviations/utils";
import { CasesTableV2 } from "../../../components/dashboard/cases/cases-table-v2";

export const CasesList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const caseState = useSelector((store) => {
    return store.cases;
  });

  const { isLoading } = caseState;
  const [selectedItems, setSelectedItems] = useState([]);
  const [controller, setController] = useState({
    filters: [
      {
        name: "status",
        value: JSON.stringify(["NEW", "PROCESSING", "FINISHED"]),
      },
      {
        name: "view",
        value: JSON.stringify(["ONLY_OVERDUE"]),
      },
    ],
    page: searchParams.get("page") ? searchParams.get("page") : 1,
    per_page: searchParams.get("per_page") ? searchParams.get("per_page") : 25,
    query: searchParams.get("query") ? searchParams.get("query") : "",
    sort: searchParams.get("sort") ? searchParams.get("sort") : "asc",
    sortBy: searchParams.get("sortBy")
      ? searchParams.get("sortBy")
      : "created_at",
    view: searchParams.get("view") ? searchParams.get("view") : "all",
    fields:
      "id,case_number,case_type,priority,status,created_at,updated_at,customer,user_assigned,last_item,fields",
  });

  const updateSearchParams = (controller) => {
    setSearchParams(controller);
  };

  // Update all params from controller except filters (dont't touch filters)
  useEffect(() => {
    updateUrlWithException(
      // These will be updated
      {
        page: controller.page,
        per_page: controller.per_page,
        query: controller.query,
        sort: controller.sort,
        sortBy: controller.sortBy,
        view: controller.view,
      },
      // This will be preserved
      "filters"
    );
  }, [controller]);

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery,
    });
  };

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const debouncedDispatchAction = debounce((controller) => {
    dispatch(getCasesAsync(controller));
  }, 200);

  useEffect(() => {
    // Trigger the debounced dispatch action
    debouncedDispatchAction(controller);

    // Clean the debounced function on component unmount.
    return () => {
      debouncedDispatchAction.cancel();
    };
  }, [controller, dispatch]);

  const initialFilters = [
    {
      id: "user_assigned_id",
      name: "Assigned User",
      optionsUrl: "/users/",
      optionsParams: {
        filters: [{ name: "role", value: "case" }],
        page: 1,
        query: "",
        sort: "desc",
        sortBy: "id",
        view: "all",
      },
      options: null,
    },
    {
      id: "user_responsible_id",
      name: "Responsible User",
      optionsUrl: "/users/",
      optionsParams: {
        filters: [{ name: "role", value: "case" }],
        page: 1,
        query: "",
        sort: "desc",
        sortBy: "id",
        view: "all",
      },
      options: null,
    },

    {
      id: "case_type",
      name: "Case Type",
      optionsUrl: "/cases/types",
      optionsParams: {
        filters: [],
        page: 1,
        query: "",
        sort: "desc",
        sortBy: "id",
        view: "all",
      },
      options: null,
    },

    {
      id: "priority",
      name: "Priority",
      options: priorityOptions,
    },

    {
      id: "view",
      name: "View",
      options: casesViewsOptions,
    },

    {
      id: "status",
      name: "Status",
      optionsUrl: null,
      optionsParams: null,
      options: caseStatusOptions,
    },
  ];

  const handleFilterChange = (selectedFilters) => {
    setController({
      ...controller,
      filters: selectedFilters?.map((filter) => {
        return {
          name: filter.id,
          value: JSON.stringify(filter.values),
        };
      }),
    });
  };

  const handleItemsRefresh = () => {
    // dispatch(getCasesAsync(controller));
  };

  const [caseDialogOpen, setCaseDialogOpen] = useState(false);

  // useEffect(() => {
  //   handleControllerFilterChange();
  // }, [filters, selectedOptions]);

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={[]}
        title={"Cases"}
        meta={[]}
        mainButton={{
          label: "New",
          action: () => setCaseDialogOpen(true),
          path: null,
          color: "",
          icon: PlusIcon,
        }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            <ProductsFilter
              className={"mt-10"}
              disabled={isLoading}
              onQueryChange={handleQueryChange}
              query={controller.query}
              selectedItems={selectedItems}
              initialFilters={initialFilters}
              onFilterChange={handleFilterChange}
              defaultSelectedOptions={[
                { name: "status", value: "NEW" },
                { name: "status", value: "PROCESSING" },
                { name: "status", value: "FINISHED" },
                { name: "view", value: "ONLY_OVERDUE" },
              ]}
            />
          </div>

          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            <CasesTableV2
              itemState={caseState}
              itemsCount={caseState.count}
              totalCount={caseState.totalCount}
              currentCount={caseState.currentCount}
              page={controller.page}
              onPageChange={handlePageChange}
              perPage={controller.per_page}
              onRefresh={() => handleItemsRefresh()}
            />
          </div>
        </div>
      </DashboardHeader>
      <CaseDialog
        // module_param={item.id}
        // module="customer"
        open={caseDialogOpen}
        onClose={() => setCaseDialogOpen(false)}
        selected={null}
        onRefresh={() => handleItemsRefresh()}
        // onEditing={(boolean) => setIsEditing(boolean)}
      />
    </>
  );
};
