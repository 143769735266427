import { useTranslation } from "react-i18next";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  axiosWithToast,
  dateToString,
  orderPath,
  reportPath,
} from "../../../Utils";
import { Popup, TWclassNames } from "../../../utils/Div";
import { serviceAgreementDaysUntilNextService } from "./utils";
import { Link, useNavigate } from "react-router-dom";
import {
  createOrderServiceAgreement,
  createReportServiceAgreement,
} from "../../../../api/service/agreement";
import SimplePrompt from "../../../utils/simplePrompt";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

export const ServiceAgreementNewReportSection = (props) => {
  const { item } = props;

  const navigate = useNavigate();

  const navigateToReport = (data) => {
    navigate(reportPath({ report: data }));
  };

  const navigateToOrder = (data) => {
    navigate(orderPath({ order: data?.data }));
  };

  const createReport = async () => {
    const payload = {
      id: item?.id,
    };

    axiosWithToast(createReportServiceAgreement(payload), navigateToReport);
  };

  const createOrder = async () => {
    const payload = {
      id: item?.id,
    };

    axiosWithToast(createOrderServiceAgreement(payload), navigateToOrder);
  };

  const [promptOpen, setPromptOpen] = useState(false);

  const handleCreateReport = () => {
    if (!item?.id) {
      // Halt, there is no agreement
      toast.error("Kan ikke opprette rapport uten serviceavtale");
      return;
    }

    // Check if there is an active report
    if (item?.active_report?.id) {
      // Halt, there is an active report
      toast.error("Kan ikke opprette rapport når det er en aktiv rapport");
      return;
    }

    // Check for customer
    if (!item?.customer?.id) {
      // Halt, there is no customer
      toast.error("Kan ikke opprette rapport uten kunde");
      return;
    }

    setPromptOpen(true);
  };

  const [newOrderPromptOpen, setNewOrderPromptOpen] = useState(false);

  const handleCreateOrder = () => {
    if (!item?.id) {
      // Halt, there is no agreement
      toast.error("Kan ikke opprette ordre uten serviceavtale");
      return;
    }

    // Check if there is an active report
    if (item?.active_report?.id) {
      // Halt, there is an active report
      toast.error("Kan ikke opprette ordre når det er en aktiv rapport");
      return;
    }

    // Check for customer
    if (!item?.customer?.id) {
      // Halt, there is no customer
      toast.error("Kan ikke opprette ordre uten kunde");
      return;
    }

    setNewOrderPromptOpen(true);
  };

  return (
    <>
      <section className="" aria-labelledby="applicant-information-title">
        <div className="bg-white shadow sm:rounded-lg ">
          <div
            className={TWclassNames(
              "flex items-center px-4 py-5 sm:px-6 w-full",
              serviceAgreementDaysUntilNextService({ agreement: item }).bgClass
            )}
          >
            <div className="block w-[60%] ">
              <h2
                id="applicant-information-title"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Neste oppdrag
              </h2>
            </div>
          </div>
          <div className="flex flex-col border-t border-gray-200 px-4 py-5 sm:px-6">
            {!item?.active_report?.id ? (
              <>
                <span>
                  Neste oppdrag skal utføres{" "}
                  {dateToString(item?.next_service_date)}
                </span>
                <span className="mt-5">
                  Det er {item?.days_until_next_service}
                  {" dager til neste oppdrag."}
                </span>
              </>
            ) : (
              <span>Oppdrag er under utførelse</span>
            )}
          </div>
          <div>
            {item?.type && item?.type?.mission !== undefined ? (
              <>
                {item?.type?.mission ? (
                  <>
                    {!item?.active_report?.id ? (
                      <div
                        type="button"
                        onClick={() => handleCreateReport()}
                        className="block bg-blue-400 px-4 py-4 text-center text-sm font-medium text-white hover:text-gray-700 sm:rounded-b-lg cursor-pointer"
                      >
                        <span className="flex justify-between">
                          <span>Opprett ny rapport</span>
                          <Popup content="Ny rapport blir opprettet. Når oppdraget er ferdig, blir neste service dato satt ut ifra ferdigstillelsedato">
                            <ExclamationCircleIcon className="w-5 h-5" />
                          </Popup>
                        </span>
                      </div>
                    ) : (
                      <Link
                        to={reportPath({ report: item?.active_report })}
                        className="block bg-blue-400 px-4 py-4 text-center text-sm font-medium text-white hover:text-gray-700 sm:rounded-b-lg cursor-pointer"
                      >
                        Gå til rapport
                      </Link>
                    )}
                  </>
                ) : (
                  <div
                    type="button"
                    onClick={() => handleCreateOrder()}
                    className="block bg-blue-400 px-4 py-4 text-center text-sm font-medium text-white hover:text-gray-700 sm:rounded-b-lg cursor-pointer"
                  >
                    <span className="flex justify-between">
                      <span>Opprett ordre</span>
                      <Popup content="Ny ordre opprettes, og neste service dato blir satt automatisk av systemet.">
                        <ExclamationCircleIcon className="w-5 h-5" />
                      </Popup>
                    </span>
                  </div>
                )}
              </>
            ) : (
              <div
                type="button"
                className="block bg-blue-400 px-4 py-4 text-center text-sm font-medium text-white hover:text-gray-700 sm:rounded-b-lg cursor-pointer"
              >
                Mangler type, avtale kan ikke utføres
              </div>
            )}
          </div>
        </div>
      </section>

      <SimplePrompt
        open={promptOpen}
        onClose={() => setPromptOpen(false)}
        onAccept={() => createReport()}
        title="Opprett ny rapport"
        description="Er du sikker på at du vil opprette en ny rapport for denne serviceavtalen?"
      />

      <SimplePrompt
        open={newOrderPromptOpen}
        onClose={() => setNewOrderPromptOpen(false)}
        onAccept={() => createOrder()}
        title="Opprett ny ordre"
        description="Er du sikker på at du vil opprette en ny ordre for denne serviceavtalen?"
      />
    </>
  );
};
