import axios from "../axios";

export async function fetchDrivingDrivers(controller) {
  return axios({
    method: "get",
    url: "/driving/drivers",
    params: controller,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchDrivingDriver(id) {
  return axios.get(`/driving/drivers/` + id);
}

export async function fetchDrivingDriverByUser(payload) {
  return axios.get(`/driving/drivers/user/` + payload.user_id, {
    params: payload,
  });
}

export async function drivingDriverAction(payload) {
  return axios.post(`/driving/drivers/user/${payload.user_id}/action`, {
    payload,
  });
}

export async function createDrivingDriver(payload) {
  return axios
    .post(`/driving/drivers`, { driver: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
export async function updateDrivingDriver(payload) {
  return axios
    .put(`/driving/drivers/${payload.id}`, { driver: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function deleteDrivingDriver(id) {
  return axios
    .delete(`/driving/drivers/${id}`)

    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
