import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Link } from "react-router-dom";
import ItemTree from "../../../utils/itemTree";

class CategoriesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return this.props.categories.length > 0 ? (
      <React.Fragment>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Bilde</TableCell>
                <TableCell>Tittel.</TableCell>
                <TableCell>Beskrivelse</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <ItemTree
                options={{
                  delete: false,
                }}
                columns={{
                  id: true,
                  title: true,
                  actions: false,
                  description: true,
                }}
                items={this.props.categories}
                onClick={(category) => this.props.categorySelect(category)}
                handleDelete={(category) => this.props.handleDelete(category)}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    ) : (
      <div>
        No Categories could be loaded.
        {/* { this.props.state.isError ? ' Error when trying to load categories.' : '' } */}
      </div>
    );
  }
}

export default CategoriesList;
