import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { ResourceError } from "../../../utils/ResourceError";
import { ResourceEmpty } from "../../../utils/ResourceEmpty";
import { useNavigate } from "react-router-dom";
import { fetchShippingTypes } from "../../../../api/customer/settings";
import ShippingTypeDialog from "./shipping-type-dialog";

export default function ShippingTypeStacklist(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [errors, setErrors] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleItemClick = (item) => {
    if (!item) {
      toast.error("Missing Item ID");
      return;
    }
    setSelectedItem(item);
    setModalOpen(true);
  };

  const handleOnClose = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };

  const [shippingTypes, setShippingTypes] = useState([]);

  useEffect(() => {
    async function getShippingTypes() {
      const fetchedOptions = await fetchShippingTypes().then((res) => {
        setShippingTypes(res?.data);
        setIsLoading(false);
      });
    }

    getShippingTypes();
  }, []);

  const handleCallback = (data) => {
    // add data to shippingTypes
    const newShippingTypes = [...shippingTypes];

    const index = newShippingTypes.findIndex((item) => item?.id === data?.id);

    if (index !== -1) {
      // update
      console.log("found index", index);
      newShippingTypes[index] = data;
    } else {
      // create
      console.log("not found index");
      // add first of array
      newShippingTypes.unshift(data);
    }

    setShippingTypes(newShippingTypes);

    // close modal

    setModalOpen(false);
  };

  const items = shippingTypes;

  const renderItems = () => {
    if (shippingTypes)
      return shippingTypes?.map((item) => (
        <li key={item?.id} onClick={() => handleItemClick(item)}>
          <a className="block hover:bg-gray-50">
            <div className="flex items-center px-4 py-4 sm:px-6">
              <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                <div className="truncate">
                  <div className="flex text-sm">
                    {/* <p className="ml-1 flex-shrink-0 font-normal text-gray-500">{item.storage_number}</p> */}
                    <p className="truncate font-medium text-indigo-600">
                      {item?.displayTitle}
                    </p>
                  </div>
                  <div className="flex">
                    <div className="flex items-center text-sm text-gray-500">
                      {/* <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
                      <p>{item?.description}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5"></div>
              </div>
              <div className="ml-5 flex-shrink-0">
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </a>
        </li>
      ));
  };

  return (
    <>
      <div className="mt-5">
        <div className="p-4 bg-white drop-shadow rounded-lg mb-2 items-center sm:flex sm:justify-between">
          <h3 className="p-1 text-lg font-medium leading-6 text-gray-900">
            {t("shipping_types")}
          </h3>
          <button
            className="ml-5 flex-shrink-0"
            onClick={() => setModalOpen(true)}
          >
            <PlusCircleIcon
              className="h-5 w-5 text-gray-400 hover:text-sky-700"
              aria-hidden="true"
            />
          </button>
        </div>
        <div className="mt-1 overflow-auto bg-white shadow sm:rounded-md h-[400px] max-h-[400px]">
          <ul role="list" className="divide-y divide-gray-200">
            {isLoading ? (
              <div className="p-5">
                <ResourceLoading />
              </div>
            ) : error && errors ? (
              <div className="p-5">
                <ResourceError errors={errors} />
              </div>
            ) : items && items.length > 0 ? (
              renderItems()
            ) : (
              <div className="p-5">
                <ResourceEmpty />
              </div>
            )}
          </ul>
        </div>
      </div>

      <ShippingTypeDialog
        onClose={() => handleOnClose()}
        open={modalOpen}
        item={selectedItem}
        callback={(data) => handleCallback(data)}
      />
    </>
  );
}
