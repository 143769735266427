import { Fragment, useEffect, useState, useRef } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  ArchiveBoxIcon,
  ArrowRightCircleIcon,
  ChevronDownIcon,
  DocumentDuplicateIcon,
  HeartIcon,
  PencilSquareIcon,
  TrashIcon,
  UserPlusIcon,
} from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PMDropdown(props) {
  const { options, label } = props;

  const { t } = useTranslation();

  const [openUpwards, setOpenUpwards] = useState(false);
  const dropdownRef = useRef(null);

  const checkSpaceAndSetDirection = () => {
    if (dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const spaceAbove = dropdownRect.top;
      const spaceBelow = window.innerHeight - dropdownRect.bottom;
      setOpenUpwards(spaceAbove > spaceBelow);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", checkSpaceAndSetDirection);

    // Initial check
    checkSpaceAndSetDirection();

    return () => {
      window.removeEventListener("resize", checkSpaceAndSetDirection);
    };
  }, []);

  if (!options) return false;

  return (
    <Menu
      as="div"
      className="relative inline-block text-left"
      ref={dropdownRef}
    >
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          {t(label)}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute z-20 mt-2 w-56 rounded-md bg-white shadow-lg focus:outline-none ${
            openUpwards ? "bottom-full" : "top-full"
          }`}
        >
          {options?.map((group, index) => (
            <div key={group?.label + index} className="py-1">
              {group?.children.map((option, index) => (
                <Menu.Item key={option?.label + index}>
                  {option?.action ? (
                    <a
                      onClick={option?.action}
                      className="cursor-pointer group flex items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    >
                      {option?.icon && (
                        <option.icon
                          className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      )}
                      {option?.label}
                    </a>
                  ) : (
                    <Link
                      to={option?.path}
                      reloadDocument
                      className={classNames(
                        "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm"
                      )}
                    >
                      <option.icon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      {option.label}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
