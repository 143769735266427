import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Resource Hub": "Resource Hub",
      "product.add": "Add a product",
      "product.update": "Update product",
      "title.required": "Tittel er krevd",
      "storage.edit": "Edit storage",
      "storage.create": "Create a storage",
      "service.report_types": "Service Report Types",
      "service.report_types.edit": "Edit Report Type",
      "service.report_types.create": "Create Report Type",
      "service.agreement_types": "Service Agreement Types",
      "service.variable_types": "Service Variable Types",
      "order.number": "Order number",
      "order.total": "Order sum total",
      "order.info": "Order Information",
      "status": "Status",
      "customer": "Customer",
      "customer.name": "Customer Name",
      "customer.number": "Customer Number",
      "customer.email": "Customer Email",
      "customer.mobile": "Customer Mobile",
      "customer.required": "Customer is required",
      "customer.address": "Customer address",
      "reference": "Reference",
      "customer.contact": "Contact Person",
      "our_contact": "Our Contact",
      "order.lines": "Order Lines",
      "report.create": "Create report",
      "report.update": "Update report",
      "report.type": "Report type",
      "report.date": "Report date",
      "report.description": "Report Description",
      "report.agreement.update": "Update Report Agreement",
      "report.agreement.new": "New Report Agreement",
      "report.variables.update": "Update Variables",
      "project": "Project",
      "order.date": "Order Date",
      "use_customer_address": "Use Customer Address",
      "report": "Report",
      "send-sms.customer": "Send SMS to Customer",
      "cancel.reason": "Reason for canceling",
      "cancel.reason.required": "Reason for canceling is required",
      "cancel.reason.employee_sickness": "Employee is sick",
      "cancel.reason.customer_sickness": "Customer is sick",
      "cancel.reason.not_confirmed": "Ikke bekreftet av kunde",
      "cancel.reason.not_suited": "Date/ time is not suited for customer",
      "cancel.reason.not_chosen": "Not chosen",
      "not_chosen": "Not chosen",
      "product.brands": "Brands",
      "product.brands.create": "Create a New Brand",
      "product.brand": "Product Brand",
      "image.change": "Change Image",
      "delete": "delete",
      "confirmation.sure": "Are you sure you want to",
      "library": "Library",
      "order_date": "Order Date",
      "order_number": "Order Number",
    },
  },
  no: {
    translation: {
      "case": "Sak",
      "is mentioned here": "er nevnt her",
      "is mentioned elsewhere": "er nevnt andre steder",
      "sent_sms_to_customer": "Sendt SMS til kunde",
      "Resource Hub": "Ressurssenter",
      "Order Lines": "Orderlinjer",
      "Not chosen": "Ikke valgt",
      "Address": "Adresse",
      "None": "Mangler",
      "New": "Ny",
      "Order Confirmation Sent": "Ordrebekreftelse sendt",
      "Ready to be picked": "Klar til å plukkes",
      "Picked": "Plukket",
      "Packed": "Pakket",
      "Ready to be shipped": "Klar til å sendes",
      "Ready to be invoiced": "Klar til å faktureres",
      "Invoiced": "Fakturert",
      "Cancelled": "Kansellert",
      "Accomplished": "Ferdigstilt",
      "Agreement confirmed": "Avtale bekreftet",
      "Agreement not confirmed": "Avtale ikke bekreftet",
      "Ongoing": "Pågående",
      "from": "fra",
      "to": "til",
      "updated": "Oppdatert",
      "product.add": "Legg til produkt",
      "product.update": "Oppdater produkt",

      "Welcome to Promang": "Welcome to React and react-i18next",
      "Sign in to your account": "Logg in i din konto",
      "product.brands": "Merkevarer",
      "product.brand": "Produkt Merke",
      "Complaint": "Reklamasjon",
      "Must be Rectified": "Må utbedres",
      "Internal Report": "Intern Rapport",
      "Report": "Rapport",
      "missing_system": "Mangler system",
      "missing_report": "Mangler rapport",
      "missing_customer": "Mangler kunde",
      "missing_order": "Mangler ordre",
      "missing_order_lines": "Mangler orderlinjer",
      "missing_hours": "Mangler timer",
    },
  },
  se: {
    translation: {
      "case": "Ärende",
      "Resource Hub": "Resurscenter",
      "Close": "Stäng",
      "Notes": "Noteringar",
      "Stripe Session": "Stripe Session",
      "Payment Status": "Betalningsstatus",
      "Payment Method": "Betalningsmetod",
      "Order Lines": "Orderlinjer",
      "Comment": "Kommentar",
      "Our Employee Contact": "Vår Anställd Kontakt",
      "Department": "Avdelning",
      "Active": "Aktiv",
      "Employee": "Anställd",
      "Customer Contact": "Kund Kontakt",
      "Contact": "Kontakt",
      "Not chosen": "Inte vald",
      "Address": "Address",
      "None": "Saknas",
      "New": "Ny",
      "Order Confirmation Sent": "Orderbekräftelse skickad",
      "Ready to be picked": "Klar att plockas",
      "Picked": "Plockad",
      "Packed": "Packad",
      "Ready to be shipped": "Klar att skickas",
      "Ready to be invoiced": "Klar att faktureras",
      "Invoiced": "Fakturerad",
      "Cancelled": "Avbruten",
      "Accomplished": "Ferdigstilt",
      "Ongoing": "Pågående",
      "from": "fra",
      "to": "til",
      "updated": "Uppdaterat",
      "item_number": "Artikkelnummer",
      "title": "Tittel",
      "short_description": "Kort beskrivning",
      "purchase_price": "Innkjöpspris",
      "sales_price": "Försäljningspris",
      "product.add": "Lägg till produkt",
      "product.update": "Updatera produkt",
      "image.change": "Byt bild",
      "Welcome to Promang": "Welcome to React and react-i18next",
      "Sign in to your account": "Logga inn",
      "product.brands": "Märke",
      "product.brand": "Produkt Merke",
      "Spare parts": "Reservdelar",
      "Categories": "Kategorier",
      "Accessories": "Tilbehör",
      "Unit": "Enhet",
      "Warehouse Status Type": "Lagerstatus Typ",
      "Account Code": "MVA Kod",
      "Product Type": "Produkt Typ",
      "Status": "Status",
      "Product Settings": "Produkt Innställningar",
      "Product Main Image": "Produktens Huvudbild",
      "Product Description": "Produkt Beskrivning",
      "Shipping": "Frakt",
      "active": "Aktiv",
      "Spare Part": "Reservdel",
      "Warehouse": "På lager",
      "Online Store": "Nätbutik",
      "Sales Price": "Försäljningspris",
      "Purchase Price": "Innkjöpspris",
      "Short Description": "Kort Beskrivning",
      "Title": "Tittel",
      "Item Number": "Artikelnummer",
      "Product Information": "Produktinformation",
      "order_date": "Order Datum",
      "order_number": "Order Nummer",
      "Customer": "Kund",
      "Project": "Projekt",
      "Our Contact": "Vår kontakt",
      "Description": "Beskrivning",
      "Select a": "Välj en",
      "Reference": "Referens",
      "Delivery Information": "Leverans information",
      "Delivery Date": "Leverans Datum",
      "Delivery Address": "Leverans Adress",
      "missing_system": "System fattas",
      "missing_report": "Rapport fattas",
      "missing_customer": "Kund fattas",
      "missing_order": "Order fattas",
      "missing_order_lines": "Orderlinjer fattas",
      "missing_hours": "Timmar fattas",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
