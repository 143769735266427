import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { mapErrors } from "../../../Utils";
import { createNote } from "../../../../api/notes";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import PMCombobox from "../../../utils/PMCombobox";
import { reportStatusesOptions } from "./utils";
import { updateReportAsync } from "../../../../slices/service/reports";
import { useDispatch } from "react-redux";
import PMSwitch from "../../../utils/PMSwitch";
import PMListboxFormik from "../../../utils/PMListboxFormik";
import {
  excelReports,
  getPdfReport,
  printReport,
} from "../../../../api/service/report";
import * as FileSaver from "file-saver";
import { PMDialog } from "../../../utils/PMDialog";
export const ReportExportDialog = (props) => {
  const { onLoading, onClose, open, report, controller } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    id: report?.id,
    printReport: true,
    print_report_type: "",
    submit: null,
  };

  const handlePrint = async (payload) => {
    return await printReport(payload);
  };

  const [downloadData, setDownloadData] = useState();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [reportType, setReportType] = useState(null);

  useEffect(() => {
    if (downloadData) {
      FileSaver.saveAs(
        downloadData,
        `rapport-${report?.id}-${report?.report_type}.pdf`
      );
    }
  }, [downloadData]);

  const fetchPdfReport = async () => {
    setIsLoading(true);
    await excelReports(controller)
      .then((response) => {
        setPdfUrl(response.data.excel_url);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (reportType) {
      fetchPdfReport();
    }
  }, [reportType]);

  const handleReportTypeChange = (data) => {
    setReportType(data.value);
  };

  const handleDownloadPDF = () => {
    // Open pdfUrl in another window
    window.open(pdfUrl, "_blank");
  };

  return (
    <PMDialog open={open} onClose={onClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, helpers) => {
          try {
            toast.promise(handlePrint(values), {
              loading: t("Saving...."),
              success: (data) => {
                if (data?.status === 500) {
                  throw new Error("Server error");
                }
                if (!data?.status === 200) {
                  throw new Error(mapErrors(data?.data?.errors));
                }
                props.onClose();
                props.onRefresh(data?.payload);
                return t("Data was saved!");
              },
              error: (error) => {
                console.log(error);
                return t("Something went wrong. Could not save data!");
              },
            });
            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
            helpers.resetForm(initialValues);
          } catch (err) {
            toast.error("Something went wrong...");
            console.error(err);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <div className="grid grid-cols-6 gap-6 mt-10">
              <div className="col-span-6 sm:col-span-6">
                <button
                  type="button"
                  onClick={() => fetchPdfReport()}
                  className="w-full justify-center rounded-md border border-transparent bg-blue-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                >
                  {t("Export Excel")}
                </button>
              </div>
              <div className="col-span-6 sm:col-span-6 mb-20">
                {isLoading ? (
                  <ResourceLoading />
                ) : (
                  pdfUrl && (
                    <button
                      type="button"
                      className="w-full justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                      onClick={handleDownloadPDF}
                    >
                      {t("Download")}
                    </button>
                  )
                )}
              </div>
            </div>
            <div className="mt-20 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={onClose}
              >
                {t("Close")}{" "}
              </button>

              {/* <button
                                type="button"
                                className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                onClick={formik.submitForm}
                              >
                                {t("update")}
                              </button> */}
            </div>
          </Form>
        )}
      </Formik>
    </PMDialog>
  );
};
