import axios from "../../api/axios";
import { useState } from "react";

const InternetConnectionStatus = () => {
  // Check if app is connected to the internet, else show modal
  const [isConnected, setIsConnected] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const handleOk = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  // axios.interceptors.response.use(
  //   (response) =>
  //     new Promise((resolve, reject) => {
  //       resolve(response);
  //     }),
  //   (error) => {
  //     if (!error.response) {
  //       return new Promise((resolve, reject) => {
  //         reject(error);
  //       });
  //     }
  //     console.log(error);
  //     if (error.response.status === 401) {
  //       setShowModal(true);
  //     }
  //     return new Promise((resolve, reject) => {
  //       reject(error);
  //     });
  //   }
  // );

  window.addEventListener("online", () => {
    setIsConnected(true);
  });

  window.addEventListener("offline", () => {
    setIsConnected(false);
  });

  if (!isConnected) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="relative w-auto max-w-3xl mx-auto my-6">
          {/*content*/}

          <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
            {/*header*/}

            <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
              <h3 className="text-3xl font-semibold">
                Internet Connection Status
              </h3>

              <button
                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={handleCancel}
              >
                <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>

            {/*body*/}

            <div className="relative flex-auto p-6">
              <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                You are currently offline. Please check your internet connection
                and try again.
              </p>
            </div>

            {/*footer*/}

            <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                type="button"
                style={{ transition: "all .15s ease" }}
                onClick={handleCancel}
              >
                Cancel
              </button>

              <button
                className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"
                style={{ transition: "all .15s ease" }}
                onClick={handleOk}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default InternetConnectionStatus;
