import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { CalendarIcon, MapPinIcon, UsersIcon } from "@heroicons/react/20/solid";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { ResourceEmpty } from "../../../utils/ResourceEmpty";
import { ResourceError } from "../../../utils/ResourceError";
import {
  BuildingOffice2Icon,
  FolderIcon,
  UserIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { ChecklistStatus } from "./utils";
import { getChecklistsAsync } from "../../../../slices/quality/checklist";
import { dateToString } from "../../../Utils";

export const ChecklistStacklist = (props) => {
  const [checked, setChecked] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const itemState = useSelector((store) => {
    return store.checklists;
  });

  const { isLoading, data, error, errors } = itemState;

  const [controller, setController] = useState({
    filters: [...props.filters],
    page: 1,
    query: "",
    sort: "desc",
    sortBy: "updated_at",
    view: "all",
    fields:
      "id,title,date,displayTitle,project.id,project.displayTitle,folder.id,folder.displayTitle,checklist_type.id,checklist_type.displayTitle,responsible_executive.id,responsible_executive.displayTitle,status,created_at,updated_at,number",
  });

  useEffect(() => {
    const handleFilterChange = () => {
      setController({
        ...controller,
        page: 1,
        filters: [...props.filters],
      });
    };

    if (props.filters) {
      handleFilterChange();
    }
  }, [props.filters]);

  useEffect(() => {
    dispatch(getChecklistsAsync(controller));
  }, [dispatch, controller]);

  const navigate = useNavigate();

  if (isLoading)
    return (
      <div className="mt-10">
        <ResourceLoading />
      </div>
    );
  if (error) return <ResourceError errors={errors} />;
  if (!data) return <ResourceEmpty />;

  return (
    <>
      {data && (
        <div className=" bg-white shadow sm:rounded-md mt-5">
          <ul role="list" className="divide-y divide-gray-200">
            {data?.map((item) => (
              <li
                key={item?.id}
                onClick={() =>
                  navigate("/dashboard/quality/checklists/" + item?.id)
                }
              >
                <a href="#" className="block hover:bg-gray-50">
                  <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <div className="grid">
                        <p className="truncate text-sm font-medium text-indigo-600">
                          {item?.number || "Missing??"}
                        </p>
                        <p className="text-md">{item?.title}</p>

                        <p className="text-xs font-italic">
                          {item?.checklist_type?.displayTitle}
                        </p>
                      </div>
                      <p className="truncate text-sm font-medium text-indigo-600"></p>

                      <div className="ml-2 flex-col flex-shrink-0">
                        {/* <ReportStatus report={item} /> */}

                        <div className="ml-2">
                          <ChecklistStatus checklist={item} />
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 flex-col sm:flex sm:justify-between">
                      <div className="sm:flex">
                        {/* {item?.assigned_user?.id && (
                          <p className="flex items-center text-sm text-gray-500">
                            <UsersIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            {item?.assigned_user?.id
                              ? item?.assigned_user?.displayTitle
                              : "None"}
                          </p>
                        )} */}

                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                          <BuildingOffice2Icon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {item?.project?.displayTitle}
                        </p>
                        {item?.folder?.id && (
                          <p className="flex items-center text-sm text-gray-500 mt-2 sm:mt-0 md:ml-2">
                            <FolderIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            {item?.folder?.displayTitle}
                          </p>
                        )}
                        {item?.date && (
                          <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 ml-0 sm:ml-2">
                            <CalendarIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <p>
                              <time dateTime={item.agreement_date}>
                                {dateToString(item?.date) || ""}
                              </time>
                            </p>
                          </div>
                        )}

                        {item?.responsible_executive && (
                          <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 ml-0 sm:ml-2">
                            <UserIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <p>{item?.responsible_executive?.displayTitle}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

ChecklistStacklist.defaultProps = {
  page: 1,
  itemState: [],
  itemsCount: 0,
  selectedItems: [],
  sort: "desc",
  sortBy: "created_at",
};
