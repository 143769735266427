import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchDeviations,
  fetchDeviation,
  updateDeviation,
  createDeviation,
  deleteDeviation,
} from "../../api/quality/deviation";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  totalCount: 0,
  currentCount: 0,
  newCount: 0,
};

export const initialController = {
  filters: [],
  page: 0,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const deleteDeviationAsync = createAsyncThunk(
  "deviations/deleteDeviationAsync",
  async (payload) => {
    let response = await deleteDeviation(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createDeviationAsync = createAsyncThunk(
  "deviations/createDeviationAsync",
  async (payload) => {
    let response = await createDeviation(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const updateDeviationAsync = createAsyncThunk(
  "deviations/updateDeviationAsync",
  async (payload) => {
    let response = await updateDeviation(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);
export const getDeviationsAsync = createAsyncThunk(
  "deviations/getDeviationsAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchDeviations(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

export const getDeviationAsync = createAsyncThunk(
  "deviations/getDeviationAsync",
  async (id, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchDeviation(id);

    // If errors, reject and return response
    if (response.error) return rejectWithValue(response);

    // If all good, return response.
    return response;
  }
);

const deviationSlice = createSlice({
  name: "deviations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDeviationsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDeviationsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.currentCount = action.payload.currentCount;
        state.newestDeviationsCount = action.payload.newCount;
      })
      .addCase(getDeviationsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch variable types....is controller missing?",
        ];
      })
      .addCase(updateDeviationAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateDeviationAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data[index] = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(updateDeviationAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update deviation"];
      })
      .addCase(createDeviationAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createDeviationAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
        });
      })
      .addCase(createDeviationAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update deviation"];
      })
      .addCase(deleteDeviationAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteDeviationAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(deleteDeviationAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update deviation"];
      })
      .addCase(getDeviationAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDeviationAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.deviation = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(getDeviationAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update product."];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = deviationSlice;

export default deviationSlice;
