import React, { useState } from "react";
import SimplePopup from "./SimplePopup";
import { formattedDate } from "../Utils";

function ActivityMonthlyTotalBar({ monthlyData = [] }) {
  const [hoverIndex, setHoverIndex] = useState(null); // Track which bar is being hovered

  // Extracting totals from the monthly data
  const totals = monthlyData?.map((data) => data.count);

  const maxTotal = Math.max(...totals);

  console.log("MonthlyTotalBar -> monthlyData", monthlyData);

  const firstDate = monthlyData[0]?.date;
  const lastDate = monthlyData[monthlyData.length - 1]?.date;

  return (
    <div className="bg-gray-300 px-4 py-4 rounded-xl">
      <div className="px-4 sm:px-0 mb-2">
        <h3 className="text-base font-semibold leading-7 text-gray-900">
          Månedens aktivitet
        </h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
          {formattedDate(firstDate)} - {formattedDate(lastDate)}
        </p>
      </div>
      <div
        className="border-blue-400 rounded-2xl border-2"
        style={{
          position: "relative",
          display: "flex",
          alignItems: "flex-end",
          height: "200px",
          padding: "10px",
          gap: "5px",
        }}
      >
        {monthlyData?.map(({ count, date }, index) => {
          const previousTotal = index === 0 ? 0 : totals[index - 1];
          const heightPercentage =
            maxTotal === 0 ? 0 : (count / maxTotal) * 100;
          const barStyle = {
            minHeight: "2px",
            height: `${heightPercentage}%`,
            backgroundColor: count > previousTotal ? "#3ADA17" : "#b70000",
            minWidth: "calc(100% / 34)",
            transition: "all 0.5s ease",
            cursor: "pointer",
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
          };

          const hoverText = () => {
            return (
              <div>
                <div>Date: {formattedDate(date)}</div>
                <div>Total: {count}</div>
                <div>
                  Increase: {calculatePercentageIncrease(count, previousTotal)}
                </div>
              </div>
            );
          };

          return (
            <div
              key={index}
              style={barStyle}
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              {hoverIndex === index && <SimplePopup content={hoverText()} />}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function calculatePercentageIncrease(current, previous) {
  if (previous === 0) return "N/A";
  return (((current - previous) / previous) * 100).toFixed(2) + "%";
}

export default ActivityMonthlyTotalBar;
