import { toast } from "react-hot-toast";
import { useState } from "react";
import { Transition } from "@headlessui/react";
import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
export function TWclassNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const CopyToClipboard = (props) => {
  const { children, value, type, copy, content, path } = props;
  const [isCopied, setIsCopied] = useState(false);

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleCopy = (value) => {
    if (!value) {
      toast.error("Cannot handleCopy an empty field");
      setIsCopied(false);
    } else {
      navigator.clipboard.writeText(value);
      setIsCopied(true);
    }
  };

  const { t } = useTranslation();

  if (type == "button") {
    return (
      <>
        {isHovering && (
          <div
            className={TWclassNames(
              isCopied ? "text-green-700" : "",
              "relative cursor-pointer flex items-center"
            )}
          >
            {children}

            <ClipboardDocumentCheckIcon
              onClick={() => handleCopy(value ? value : children)}
              className={TWclassNames(
                isCopied ? "text-green-500" : "text-gray-400",
                "ml-0 h-5 w-5 mt-1"
              )}
              aria-hidden="true"
            />
          </div>
        )}
      </>
    );
  } else if (type == "popup") {
    return (
      <>
        <div
          className=""
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Link to={path}>{content}</Link>
          {isHovering && (
            <div
              className={TWclassNames(
                isCopied ? "text-green-700" : "",
                "z-10 absolute cursor-pointer flex items-center shadow-md bg-white rounded-md p-2"
              )}
            >
              <div
                className="flex"
                onClick={() => handleCopy(value ? value : content)}
              >
                <ClipboardDocumentCheckIcon
                  className={TWclassNames(
                    isCopied ? "text-green-500" : "text-gray-400",
                    "ml-0 h-5 w-5 mt-1"
                  )}
                  aria-hidden="true"
                />{" "}
                <span className="py-1 px-1">{t("Copy")}</span>
              </div>
            </div>
          )}
        </div>
      </>
    );
  } else {
    return (
      <div
        className={TWclassNames(
          isCopied ? "text-green-700" : "",
          "relative cursor-pointer mt-2"
        )}
        onClick={() => handleCopy(value ? value : children)}
      >
        {children}
        <Transition
          show={isCopied}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            <ClipboardDocumentCheckIcon
              className="text-green-500 h-5 w-5 "
              aria-hidden="true"
            />
          </div>
        </Transition>
      </div>
    );
  }
};

export const Popup = (props) => {
  const { t } = useTranslation();
  const { children, content, textSize } = props;
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  if (!content) {
    return <>{children}</>;
  }
  return (
    <>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <span>{children}</span>
        <Transition
          show={isHovering}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={TWclassNames(
              "z-10 absolute break-all overflow-hidden position-auto cursor-pointer flex items-center shadow-md bg-white rounded-md p-2"
            )}
          >
            <div className="flex max-w-xl">
              <span
                className={TWclassNames(
                  textSize ? "text-" + textSize : "text-xs",
                  "font-normal "
                )}
              >
                {content}
              </span>
            </div>
          </div>
        </Transition>
      </div>
    </>
  );
};
