import React, { useEffect, useRef, useState } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { useFormikContext } from "formik";

const PMTextEditor = ({ name, onChange }) => {
  const editorRef = useRef(null);
  const quillRef = useRef(null);
  const [showToolbar, setShowToolbar] = useState(false);

  // Access Formik context for values and setFieldValue
  const { values, setFieldValue } = useFormikContext();

  // Initialize Quill editor once
  useEffect(() => {
    if (!quillRef.current) {
      // Define a minimal toolbar
      const toolbarOptions = [
        [{ "list": "ordered" }, { "list": "bullet" }],
        ["bold", "italic", "underline"],
      ];

      // Initialize Quill with minimal options
      quillRef.current = new Quill(editorRef.current, {
        theme: "snow",
        modules: {
          toolbar: toolbarOptions,
        },
        placeholder: "Start typing here...",
      });

      // Set initial content based on `values[name]`
      if (values[name]) {
        quillRef.current.clipboard.dangerouslyPasteHTML(values[name]);
      }

      // Handle content change to update Formik state
      quillRef.current.on("text-change", () => {
        const html = quillRef.current.root.innerHTML;
        setFieldValue(name, html); // Update Formik's field value
        if (onChange) onChange(html); // Call onChange if provided
      });
    }

    // Check if the editor is selected to show toolbar
    const handleSelection = () => {
      const selection = quillRef.current.getSelection();
      if (selection && selection.length > 0) {
        setShowToolbar(true);
      } else {
        setShowToolbar(false);
      }
    };

    // Add event listeners directly to editorRef
    const editorContainer = editorRef.current;
    editorContainer.addEventListener("mouseup", handleSelection);
    editorContainer.addEventListener("mouseleave", () => setShowToolbar(false));

    // Cleanup listeners on component unmount
    return () => {
      editorContainer.removeEventListener("mouseup", handleSelection);
      editorContainer.removeEventListener("mouseleave", () =>
        setShowToolbar(false)
      );
    };
  }, [setFieldValue, onChange, name, values]);

  // Update editor content if `values[name]` changes externally
  useEffect(() => {
    if (quillRef.current && values[name] !== quillRef.current.root.innerHTML) {
      quillRef.current.clipboard.dangerouslyPasteHTML(values[name]);
    }
  }, [values, name]);

  return (
    <div
      className="relative w-full mx-auto rounded-md"
      onMouseEnter={() => setShowToolbar(true)}
      onMouseLeave={() => setShowToolbar(false)}
    >
      <div
        ref={editorRef}
        className="ql-container h-[250px] border-none focus:outline-none"
      >
        <div
          className={`ql-toolbar ql-snow transition-opacity duration-200 ${
            showToolbar ? "opacity-100" : "opacity-0"
          }`}
          style={{
            backgroundColor: "white",
            border: "none",
          }}
        />
        <div
          className="ql-editor focus:outline-none"
          style={{ minHeight: "400px" }}
        />
      </div>
    </div>
  );
};

export default PMTextEditor;
