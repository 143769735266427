import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { axiosWithToast, dispatchWithToast, reportPath } from "../../../Utils";
import { TWclassNames } from "../../../utils/Div";
import { serviceAgreementIntervals } from "./utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createReportServiceAgreement } from "../../../../api/service/agreement";
import SimplePrompt from "../../../utils/simplePrompt";
import { PMDialog } from "../../../utils/PMDialog";
import { PMField } from "../../../utils/PMField";
import { updateServiceAgreementAsync } from "../../../../slices/service/agreements";
import PMListbox from "../../../utils/PMListbox";
import { ResourceLoading } from "../../../utils/ResourceLoading";

const ServiceAgreementEditDateDialog = (props) => {
  const { open, onClose, agreement, onRefresh, type } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [open]);

  let initialValues = {
    id: agreement?.id,
    submit: null,
  };

  if (type === "date") {
    initialValues = {
      ...initialValues,
      next_service_date: agreement?.next_service_date,
    };
  } else if (type === "interval") {
    initialValues = {
      ...initialValues,
      interval_id: agreement?.interval?.id,
    };
  }

  return (
    <PMDialog
      onClose={onClose}
      open={open}
      title={
        "Rediger " +
        (type === "date" ? "dato" : "intervall") +
        " for neste service"
      }
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object({})}
        onSubmit={(values, helpers) => {
          try {
            dispatchWithToast(
              dispatch(updateServiceAgreementAsync(values)),
              onRefresh,
              onClose
            );

            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
          } catch (err) {
            toast.error("Something went wrong...");
            console.error(err);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => {
          if (isLoading) {
            return <ResourceLoading />;
          }
          return (
            <Form>
              <div className="grid grid-cols-6 gap-6 mt-10 ">
                {type === "date" && (
                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="next_service_date"
                      type="date"
                      label="Next service date"
                      {...formik}
                    />
                  </div>
                )}

                {type === "interval" && (
                  <div className="col-span-6 sm:col-span-6">
                    <PMListbox
                      label={t("Interval")}
                      name="interval_id"
                      options={serviceAgreementIntervals}
                      selected={agreement.interval?.id}
                      {...formik}
                    />
                  </div>
                )}

                <div className="col-span-6 sm:col-span-3 lg:col-span-2"></div>
              </div>

              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button
                  type="button"
                  className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                  onClick={onClose}
                >
                  {t("Close")}{" "}
                </button>

                <button
                  type="button"
                  className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                  onClick={formik.submitForm}
                >
                  {formik.values?.id ? t("Save") : t("Create")}{" "}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </PMDialog>
  );
};

export const ServiceAgreementChangeDateSection = (props) => {
  const { t } = useTranslation();
  const { item, onRefresh } = props;
  const navigate = useNavigate();

  const redirectToReport = (data) => {
    if (data?.id) {
      navigate(reportPath({ report: data }));
    }
  };

  const createReport = async () => {
    const payload = {};

    axiosWithToast(createReportServiceAgreement(payload), (data) =>
      redirectToReport(data)
    );
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [promptOpen, setPromptOpen] = useState(false);

  const handleCreateReport = () => {
    if (!item?.id) {
      // Halt, there is no agreement
      toast.error("Kan ikke opprette rapport uten serviceavtale");
      return;
    }

    // Check if there is an active report
    if (item?.active_report?.id) {
      // Halt, there is an active report
      toast.error("Kan ikke opprette rapport når det er en aktiv rapport");
      return;
    }

    // Check for customer
    if (!item?.customer?.id) {
      // Halt, there is no customer
      toast.error("Kan ikke opprette rapport uten kunde");
      return;
    }

    setPromptOpen(true);
  };

  const [selectedChangeType, setSelectedChangeType] = useState();

  const handleSelectChangeType = (type) => {
    setSelectedChangeType(type);
    setModalOpen(true);
  };

  return (
    <>
      <section className="" aria-labelledby="applicant-information-title">
        <div className="bg-white shadow sm:rounded-lg ">
          <div
            className={TWclassNames(
              "flex items-center px-4 py-5 sm:px-6 w-full"
            )}
          >
            <div className="block w-[60%] ">
              <h2
                id="applicant-information-title"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Rediger dato for neste service
              </h2>
            </div>
          </div>
          <div className="flex flex-col border-t border-gray-200 px-4 py-5 sm:px-6">
            <>
              <span>
                Flytt datoen for neste service til frem eller tilbake i tid
              </span>
              <span> eller endre intervall for avtalen</span>
            </>
          </div>
          <div className="flex justify-between">
            <div
              type="button"
              onClick={() => handleSelectChangeType("date")}
              className="block bg-blue-400 px-4 py-4 w-full text-center text-sm font-medium text-white hover:bg-blue-300 sm:rounded-bl-lg cursor-pointer"
            >
              Dato
            </div>
            <div
              type="button"
              onClick={() => handleSelectChangeType("interval")}
              className="block bg-blue-400 px-4 py-4 w-full text-center text-sm font-medium text-white hover:bg-blue-300   sm:rounded-br-lg cursor-pointer"
            >
              Interval
            </div>
          </div>
        </div>
      </section>

      <SimplePrompt
        open={promptOpen}
        onClose={() => setPromptOpen(false)}
        onAccept={() => createReport()}
        title="Opprett ny rapport"
        description="Er du sikker på at du vil opprette en ny rapport for denne serviceavtalen?"
      />

      <ServiceAgreementEditDateDialog
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setSelectedChangeType(null);
        }}
        type={selectedChangeType}
        agreement={item}
        onRefresh={onRefresh}
      />
    </>
  );
};
