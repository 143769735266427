import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { PMField } from "../../../utils/PMField";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { axiosWithToast, dispatchWithToast, mapErrors } from "../../../Utils";
import PMSwitch from "../../../utils/PMSwitch";

import { ResourceLoading } from "../../../utils/ResourceLoading";
import {
  createShippingType,
  fetchShippingType,
  updateShippingType,
} from "../../../../api/customer/settings";
import { PMDialog } from "../../../utils/PMDialog";

export default function ShippingTypeDialog(props) {
  const { t } = useTranslation();
  const { open, onClose, item } = props;

  const [itemState, setItemState] = useState({
    shipping_type: {},
    isLoading: true,
  });

  const { shipping_type, isLoading } = itemState;

  useEffect(() => {
    const fetchChecklistType = async () => {
      const res = await fetchShippingType({
        id: item?.id,
      });
      if (res.status === 200) {
        setItemState((prevState) => ({
          ...prevState,
          shipping_type: res?.data,
          isLoading: false,
        }));
      }
    };

    if (item?.id) {
      fetchChecklistType();
    } else {
      setItemState((prevState) => ({
        ...prevState,
        shipping_type: {},
        isLoading: false,
      }));
    }
  }, [open]);

  const createOrUpdate = (data) => {
    if (shipping_type?.id) {
      const res = updateShippingType(data);
      return res;
    } else {
      const res = createShippingType(data);
      return res;
    }
  };

  return (
    <PMDialog open={open} onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={{
          id: shipping_type?.id,
          title: shipping_type?.title,
          description: shipping_type?.description,
          active: shipping_type?.active || true,
          public: shipping_type?.public || false,
          free: shipping_type?.free || false,
          flat: shipping_type?.flat || false,
          price: shipping_type?.price || 0.0,
          free_over_ordertotal: shipping_type?.free_over_ordertotal || 0.0,
          tripletex_api: shipping_type?.tripletex_api || false,
          bring_id: shipping_type?.bring_id || "",
          submit: null,
        }}
        validationSchema={Yup.object({
          title: Yup.string().max(255).required(t("title.required")),
          description: Yup.string().max(500),
        })}
        onSubmit={(values, helpers) => {
          try {
            axiosWithToast(
              createOrUpdate(values),
              (payload) => {
                setItemState((prevState) => ({
                  ...prevState,
                  isLoading: false,
                  shipping_type: payload,
                })),
                  props.callback(payload);
              },
              onClose(true)
            );

            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
          } catch (err) {
            toast.error("Something went wrong...");
            console.error(err);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <div className="grid grid-cols-9 gap-6 mt-10">
              <div className="col-span-9 sm:col-span-9">
                <PMField name="title" type="text" label="Title" {...formik} />
              </div>

              <div className="col-span-9 sm:col-span-9">
                <PMField
                  name="description"
                  type="text"
                  label="Description"
                  multiline
                  rows={2}
                  {...formik}
                />
              </div>

              <div className="col-span-9 sm:col-span-9">
                <PMField
                  disabled
                  name="bring_id"
                  type="text"
                  label="Bring ID"
                  {...formik}
                />
              </div>

              <div className="col-span-9 sm:col-span-9">
                <PMField
                  disabled
                  name="tripletex_id"
                  type="text"
                  label="Tripletex ID"
                  {...formik}
                />
              </div>

              {!formik.values.free && (
                <>
                  <div className="col-span-9 sm:col-span-9">
                    <PMField
                      name="price"
                      type="text"
                      label="Pris"
                      {...formik}
                    />
                  </div>
                  <div className="col-span-9 sm:col-span-9">
                    <PMField
                      name="free_over_ordertotal"
                      type="text"
                      label="Free Over Ordertotal"
                      {...formik}
                    />
                  </div>
                </>
              )}

              <div className="col-span-6 sm:col-span-3 sm:mt-8">
                <PMSwitch name="active" label="Active" {...formik} />
              </div>
              <div className="col-span-6 sm:col-span-3 sm:mt-8">
                <PMSwitch name="public" label="Public" {...formik} />
              </div>
              <div className="col-span-6 sm:col-span-3 sm:mt-8">
                <PMSwitch name="flat" label="Flat" {...formik} />
              </div>
              <div className="col-span-6 sm:col-span-3 sm:mt-8">
                <PMSwitch name="free" label="Free" {...formik} />
              </div>
              <div className="col-span-6 sm:col-span-3 sm:mt-8">
                <PMSwitch
                  name="calculate"
                  label="Calculate price"
                  {...formik}
                />
              </div>
            </div>

            <div className="mt-10 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={onClose}
              >
                {t("Close")}{" "}
              </button>

              <button
                type="button"
                className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={formik.submitForm}
              >
                {shipping_type?.id ? t("Save") : t("Create")}{" "}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </PMDialog>
  );
}
