import { QuestionAnswer } from "@mui/icons-material";
import DashboardHeader from "../../../../components/dashboard/dashboardHeader";
import {
  TWTW,
  TWclassNames,
  TWclassNamesclassNames,
} from "../../../../components/utils/Div";
import {
  AcademicCapIcon,
  ArrowPathRoundedSquareIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  ClockIcon,
  PencilIcon,
  PlusIcon,
  ReceiptRefundIcon,
  TrashIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate, useParams } from "react-router-dom";
import UppercaseFirstLetter, {
  dateToString,
  dispatchWithToast,
  upperFirstLetter,
} from "../../../../components/Utils";
import toast from "react-hot-toast";
import { FaqCategoryDialog } from "../../../../components/dashboard/faq/CategoryDialog";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getResourceHubFaqCategoriesAsync } from "../../../../slices/resource-hub/faq/category";
import { use } from "i18next";
import { PMNotice } from "../../../../components/utils/notifications";
import { ResourceLoading } from "../../../../components/utils/ResourceLoading";
import { destroyResourceHubFaqPost } from "../../../../api/resource-hub/faq/posts";
import {
  deleteResourceHubFaqPostAsync,
  getResourceHubFaqPostsAsync,
} from "../../../../slices/resource-hub/faq/post";
import { Prompt } from "../../../../components/utils/Prompt";
import SimplePrompt from "../../../../components/utils/simplePrompt";
import { Query } from "../../../../components/utils/query";

export default function ParentCategoryTabs({
  mainCategories,
  mainCategory,
  userType,
}) {
  const navigate = useNavigate();

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select main category
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={mainCategory}
        >
          {mainCategories?.map((tab) => (
            <option
              key={tab?.title}
              onClick={() =>
                navigate(
                  "/dashboard/resource-hub/faq/" + userType + "/" + tab?.id
                )
              }
            >
              {tab?.title}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {mainCategories?.map((tab) => (
              <Link
                key={tab?.title}
                to={"/dashboard/resource-hub/faq/" + userType + "/" + tab?.id}
                className={TWclassNames(
                  tab?.id === mainCategory?.id
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                  "flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                )}
                aria-current={tab?.current ? "page" : undefined}
              >
                {tab?.title}
                <span
                  className={TWclassNames(
                    tab?.id === mainCategory?.id
                      ? "bg-indigo-100 text-indigo-600"
                      : "bg-gray-100 text-gray-900",
                    "ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block"
                  )}
                >
                  {tab?.children?.length}
                </span>
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

function ChildrenCategories({ categories, mainCategory, userType }) {
  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
      {categories?.map((category, actionIdx) => (
        <Link
          to={
            "/dashboard/resource-hub/faq/" +
            userType +
            "/" +
            mainCategory?.id +
            "/" +
            category?.id
          }
          key={category.title}
        >
          <div
            key={category.title}
            className={TWclassNames(
              actionIdx === 0
                ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                : "",
              actionIdx === 1 ? "sm:rounded-tr-lg" : "",
              actionIdx === actions.length - 2 ? "sm:rounded-bl-lg" : "",
              actionIdx === actions.length - 1
                ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
                : "",
              "group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
            )}
          >
            <div>
              <span
                className={TWclassNames(
                  category.iconBackground,
                  category.iconForeground,
                  "inline-flex rounded-lg p-3 ring-4 ring-white"
                )}
              >
                dw
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                <a href={category.href} className="focus:outline-none">
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  {category.title}
                </a>
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                Doloribus dolores nostrum quia qui natus officia quod et
                dolorem. Sit repellendus qui ut at blanditiis et quo et
                molestiae.
              </p>
            </div>
            <span
              className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        </Link>
      ))}
    </div>
  );
}

function FaqPosts(props) {
  const [posts, setPosts] = useState(props.posts);

  const { userType } = props;

  const dispatch = useDispatch();

  function deletePost(post) {
    dispatchWithToast(dispatch(deleteResourceHubFaqPostAsync(post)), (data) => {
      setPosts(posts.filter((p) => p.id !== post.id));
    });
  }

  const [deletePostPromptOpen, setDeletePostPromptOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  function deletePostAction(post) {
    setSelectedPost(post);
    setDeletePostPromptOpen(true);
  }

  return (
    <>
      <ul className="w-full">
        {posts?.map((post) => (
          <li className="w-full bg-gray-50 shadow-md px-4 py-4" key={post.id}>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className={`w-8 h-8 rounded-full ${post.iconBackground}`}>
                  {/* <post.icon className={`w-5 h-5 ${post.iconForeground}`} /> */}
                </div>
                <div className="ml-2">
                  <h3 className="text-lg font-medium">{post.title}</h3>
                  <p className="text-sm text-gray-500">
                    <div dangerouslySetInnerHTML={{ __html: post?.content }} />
                  </p>
                  <p className="mt-10 text-xs text-gray-400">
                    Created: {dateToString(post?.created_at)}, Updated:{" "}
                    {dateToString(post?.updated_at)}
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-5">
                <Link
                  to={
                    "/dashboard/resource-hub/faq/editPost/" +
                    post?.id +
                    "?userType=" +
                    userType
                  }
                  className="text-blue-500"
                >
                  <PencilIcon className="w-5 h-5" />
                </Link>
                <button
                  type="button"
                  className="text-red-500"
                  onClick={() => deletePostAction(post)}
                >
                  <TrashIcon className="w-5 h-5" />
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <SimplePrompt
        title="Vill du slette inlegget?"
        description="Dette kan ikke reverseres"
        onAccept={() => deletePost(selectedPost)}
        open={deletePostPromptOpen}
        onClose={() => setDeletePostPromptOpen(false)}
      />
    </>
  );
}

const actions = [
  {
    id: 1,
    title: "Request time off",
    href: "#",
    icon: ClockIcon,
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
  },
  {
    id: 2,
    title: "Benefits",
    href: "#",
    icon: CheckBadgeIcon,
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
  },
  {
    id: 3,
    title: "Schedule a one-on-one",
    href: "#",
    icon: UsersIcon,
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
  },
  {
    id: 4,
    title: "Payroll",
    href: "#",
    icon: BanknotesIcon,
    iconForeground: "text-yellow-700",
    iconBackground: "bg-yellow-50",
  },
  {
    id: 5,
    title: "Submit an expense",
    href: "#",
    icon: ReceiptRefundIcon,
    iconForeground: "text-rose-700",
    iconBackground: "bg-rose-50",
  },
  {
    id: 6,
    title: "Training",
    href: "#",
    icon: AcademicCapIcon,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
];

export const ResourceHubFaqPage = () => {
  const { userType } = useParams();

  const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);

  const [mainCategorySelected, setMainCategorySelected] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const categoryState = useSelector((store) => {
    return store.resource_hub_faq_categories;
  });

  const postsState = useSelector((store) => {
    return store.resource_hub_faq_posts;
  });

  const mainCategories = categoryState?.data;

  const [isLoading, setIsLoading] = useState(false);

  const { mainCategoryId, childCategoryId } = useParams();

  function setMainCategoryFromId(id) {
    const newMainCategory = mainCategories.find(
      (category) => category.id === parseInt(id)
    );

    setMainCategory(newMainCategory);
  }

  // update isLoading depending on categoryState
  useEffect(() => {
    setIsLoading(categoryState.isLoading);
  }, [categoryState.isLoading]);

  const [controller, setController] = useState({
    parent: true,
    userType: userType,
  });

  const [query, setQuery] = useState();

  useEffect(() => {
    dispatch(getResourceHubFaqCategoriesAsync(controller));
  }, []);

  const [mainCategory, setMainCategory] = useState();

  useEffect(() => {
    if (mainCategoryId) {
      setMainCategoryFromId(mainCategoryId);
    }
  }, [categoryState, mainCategoryId]);

  const handleCategoryAction = (type) => {
    if (type === "main") {
      setMainCategorySelected(true);
      setCategoryDialogOpen(true);
    } else {
      setMainCategorySelected(false);
      setCategoryDialogOpen(true);
    }
  };

  const headerActions = [
    {
      component: null,
      label: "Ny hovedkategori",
      description: "",
      path: null,
      action: () => handleCategoryAction("main"),
      icon: PlusIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: true,
    },
    {
      component: null,
      label: "Ny underkategori",
      description: "",
      path: null,
      action: () => handleCategoryAction(),
      icon: PlusIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: true,
    },
    {
      component: null,
      label: "Ny innlegg",
      description: "",
      path: null,
      action: () =>
        navigate("/dashboard/resource-hub/faq/newPost?userType=" + userType),
      icon: PlusIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: true,
    },
  ];

  function currentPosts() {
    if (query) {
      return postsState.data;
    } else {
      if (childCategoryId) {
        return mainCategory?.children.find(
          (category) => category.id === parseInt(childCategoryId)
        )?.posts;
      } else {
        return mainCategory?.posts;
      }
    }
  }

  useEffect(() => {
    if (query) {
      dispatch(getResourceHubFaqPostsAsync({ query: query }));
      dispatch(
        getResourceHubFaqCategoriesAsync({ ...controller, query: query })
      );
    }
  }, [query]);

  // on any category change, remove query
  useEffect(() => {
    setQuery("");
  }, [mainCategoryId, childCategoryId]);

  function Content() {
    return (
      <>
        <Query
          disabled={isLoading ? true : false}
          onChange={setQuery}
          value={query}
        />

        <ParentCategoryTabs
          mainCategories={mainCategories}
          mainCategory={mainCategory}
          userType={userType}
        />

        {mainCategoryId &&
          mainCategory?.children.length > 0 &&
          !childCategoryId && (
            <ChildrenCategories
              categories={mainCategory?.children}
              mainCategory={mainCategory}
              userType={userType}
            />
          )}

        <FaqPosts posts={currentPosts()} userType={userType} />
      </>
    );
  }

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={"FAQ for " + upperFirstLetter(userType)}
        meta={[{ label: "test", icon: QuestionAnswer }]}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            {isLoading ? <ResourceLoading /> : <Content />}
          </div>
        </div>
      </DashboardHeader>

      <FaqCategoryDialog
        open={categoryDialogOpen}
        onClose={() => setCategoryDialogOpen(false)}
        mainCategory={mainCategorySelected}
        userType={userType}
      />
    </>
  );
};
