import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { CalendarIcon, MapPinIcon, UsersIcon } from "@heroicons/react/20/solid";
import { getCasesAsync } from "../../../../slices/case";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { ResourceEmpty } from "../../../utils/ResourceEmpty";
import { ResourceError } from "../../../utils/ResourceError";
import { getReportsAsync } from "../../../../slices/service/reports";
import { WrenchIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { ServiceAgreementStatus } from "./utils";
import { getServiceAgreementsAsync } from "../../../../slices/service/agreements";
import { fullDeliveryAddress } from "../../../Utils";

export const ServiceAgreementsStackList = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const itemState = useSelector((store) => {
    return store.service_agreements;
  });

  const { isLoading, data, error, errors } = itemState;

  const [controller, setController] = useState({
    filters: [...props.filters],
    page: 1,
    query: "",
    sort: "desc",
    sortBy: "updated_at",
    view: "all",
  });

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery,
    });
  };

  useEffect(() => {
    const payload = {
      filters: controller.filters,
      page: controller.page,
      query: controller.query,
      sort: controller.sort,
      sortBy: controller.sortBy,
      view: controller.view,
    };
    dispatch(getServiceAgreementsAsync(payload));
  }, [dispatch, controller]);

  const [selectedCase, setSelectedCase] = useState(null);

  function handleOpenCase(id) {
    setSelectedCase(id);
  }

  const navigate = useNavigate();

  if (isLoading)
    return (
      <div className="mt-10">
        <ResourceLoading />
      </div>
    );
  if (error) return <ResourceError errors={errors} />;
  if (!data) return <ResourceEmpty />;

  return (
    <>
      {/* <PMPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        perPage={perPage}
        rowsCount={itemsCount}
        totalCount={itemsCount}
        currentCount={currentCount}
        showCount={false}
      /> */}
      {data && (
        <div className="overflow-hidden bg-white shadow sm:rounded-md">
          <ul role="list" className="divide-y divide-gray-200">
            {data?.map((item) => (
              <li
                key={item?.id}
                onClick={() =>
                  navigate("/dashboard/service/agreements/" + item?.id)
                }
              >
                <a href="#" className="block hover:bg-gray-50">
                  <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <div className="grid">
                        <p className="truncate text-sm font-medium text-indigo-600">
                          {item?.displayTitle || "Missing??"}
                        </p>
                        <p className="text-xs">{item?.type?.displayTitle}</p>
                      </div>
                      <p className="truncate text-sm font-medium text-indigo-600"></p>

                      <div className="ml-2 flex flex-shrink-0">
                        <ServiceAgreementStatus serviceAgreement={item} />
                      </div>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="sm:flex">
                        <p className="flex items-center text-sm text-gray-500">
                          <UsersIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {item?.customer
                            ? item?.customer?.displayTitle
                            : "None"}
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                          <MapPinIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {item?.delivery_address
                            ? fullDeliveryAddress(item?.delivery_address)
                            : "None"}
                        </p>
                      </div>
                      <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                        <CalendarIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <p>
                          Neste sevice om:{" "}
                          <span>{item.days_until_next_service}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

ServiceAgreementsStackList.defaultProps = {
  page: 1,
  itemState: [],
  itemsCount: 0,
  selectedItems: [],
  sort: "desc",
  sortBy: "createdAt",
};
