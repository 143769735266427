import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ProductsFilter } from "../../../../components/dashboard/products/products-filter";
import {
  reportStatusesOptions,
  reportViewsOptions,
} from "../../../../components/dashboard/service/reports/utils";
import { useSearchParams } from "react-router-dom";
import DashboardHeader from "../../../../components/dashboard/dashboardHeader";
import {
  ArrowUpCircleIcon,
  CheckBadgeIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { updateUrlWithException } from "../../../../components/Utils";
import { getChecklistsAsync } from "../../../../slices/quality/checklist";
import ChecklistDialog from "../../../../components/dashboard/quality/checklists/checklistDialog";
import { ChecklistsTable } from "../../../../components/dashboard/quality/checklists/checklistsTable";
import { checklistStatusOptions } from "../../../../components/dashboard/quality/checklists/utils";
import { debounce } from "lodash";

export const ChecklistsList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [controller, setController] = useState({
    filters: [{ name: "status", value: JSON.stringify(["NEW", "ONGOING"]) }],
    page: searchParams.get("page") ? searchParams.get("page") : 1,
    per_page: searchParams.get("per_page") ? searchParams.get("per_page") : 50,
    query: searchParams.get("query") ? searchParams.get("query") : "",
    sort: searchParams.get("sort") ? searchParams.get("sort") : "asc",
    sortBy: searchParams.get("sortBy")
      ? searchParams.get("sortBy")
      : "created_at",
    view: searchParams.get("view") ? searchParams.get("view") : "all",
    fields:
      "id,number,displayTitle,title,description,project,customer,checklist_type,folder,status",
  });

  const checklistState = useSelector((store) => {
    return store.checklists;
  });

  const updateSearchParams = (controller) => {
    setSearchParams(controller);
  };

  // Update all params from controller except filters (dont't touch filters)
  useEffect(() => {
    updateUrlWithException(
      // These will be updated
      {
        page: controller.page,
        per_page: controller.per_page,
        query: controller.query,
        sort: controller.sort,
        sortBy: controller.sortBy,
        view: controller.view,
      },
      // This will be preserved
      "filters"
    );
  }, [controller]);

  const { isLoading, totalCount, newCount } = checklistState;
  const [selectedItems, setSelectedItems] = useState([]);

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery,
    });
  };

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleFilterChange = (selectedFilters) => {
    setController({
      ...controller,
      page: 1,
      filters: selectedFilters?.map((filter) => {
        return {
          name: filter.id,
          value: JSON.stringify(filter.values),
        };
      }),
    });
  };

  const debouncedDispatchAction = debounce((controller) => {
    dispatch(getChecklistsAsync(controller));
  }, 250);

  useEffect(() => {
    // Trigger the debounced dispatch action
    debouncedDispatchAction(controller);

    // Clean the debounced function on component unmount.
    return () => {
      debouncedDispatchAction.cancel();
    };
  }, [controller, dispatch]);

  const sortByOptions = [
    // { name: "Seneste (Rapportnr.)", sort: "asc", sortBy: "report_number" },
    // { name: "Eldste (Rapportnr.)", sort: "desc", sortBy: "report_number" },
  ];

  const initialFilters = [
    // {
    //   id: "report_type",
    //   name: "report_type",
    //   optionsUrl: "/service/settings/report_types",
    //   optionsParams: {},
    // },
    // {
    //   id: "view",
    //   name: "view",
    //   options: reportViewsOptions,
    // },
    {
      id: "status",
      name: "Status",
      options: checklistStatusOptions,
    },
  ];

  const [checklistDialogOpen, setChecklistDialogOpen] = useState(false);

  const headerMeta = [
    {
      label: "Total: " + totalCount,
      description: "",
      path: null,
      action: null,
      icon: CheckBadgeIcon,
      textColor: "text-gray-400",
      iconColor: "text-gray-400",
      hidden: false,
    },

    {
      label: "New: " + newCount,
      description: "",
      path: null,
      action: null,
      icon: ArrowUpCircleIcon,
      textColor: "text-gray-400",
      iconColor: "text-green-500",
      hidden: false,
    },
  ];

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={[]}
        title={"Checklists"}
        meta={headerMeta}
        mainButton={{
          label: "New",
          action: () => setChecklistDialogOpen(true),
          path: null,
          color: "",
          icon: PlusIcon,
        }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            <ProductsFilter
              className={"mt-10"}
              disabled={isLoading}
              onQueryChange={handleQueryChange}
              query={controller.query}
              selectedItems={selectedItems}
              sortByOptions={sortByOptions}
              initialFilters={initialFilters}
              onFilterChange={handleFilterChange}
              defaultSelectedOptions={[
                { name: "status", value: "open" },
                { name: "status", value: "in_progress" },
              ]}
            />

            <ChecklistsTable
              itemState={checklistState}
              itemsCount={checklistState.totalCount}
              currentCount={checklistState.currentCount}
              page={controller.page}
              onPageChange={handlePageChange}
              perPage={controller.per_page}
            />
          </div>
        </div>
      </DashboardHeader>

      <ChecklistDialog
        open={checklistDialogOpen}
        onClose={() => setChecklistDialogOpen(false)}
        selected={null}
        // onRefresh={() => getReport()}
      />
    </>
  );
};
