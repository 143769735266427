import { ChevronRightIcon } from "@heroicons/react/20/solid";
import {
  PhoneArrowUpRightIcon,
  QuestionMarkCircleIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline";
import { BookOpenIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

const links = [
  {
    name: "Kontakt Oss",
    href: "/kontakt",
    description: "Learn how to integrate our tools with your app.",
    icon: PhoneArrowUpRightIcon,
  },
  {
    name: "Produkter",
    href: "/produkter",
    description: "A complete API reference for our libraries.",
    icon: ShoppingCartIcon,
  },
  {
    name: "Om Oss",
    href: "/om-oss",
    description: "Installation guides that cover popular setups.",
    icon: BookOpenIcon,
  },
  {
    name: "Hjelp",
    href: "/hjelp",
    description: "Read our latest news and articles.",
    icon: QuestionMarkCircleIcon,
  },
];

export const DashboardPageNotFound = () => {
  return (
    <div className="mx-auto w-full max-w-7xl px-6 pb-16 sm:pb-24 lg:px-8">
      <div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
        <p className="text-base font-semibold leading-8 text-indigo-600">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Denne siden finnes ikke...
        </h1>
        <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
          Beklager, vi kunne ikke finne det du søkte etter
        </p>
      </div>
      <div className="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
        <h2 className="sr-only">Popular pages</h2>
        <ul
          role="list"
          className="-mt-6 divide-y divide-gray-900/5 border-b border-gray-900/5"
        >
          {links.map((link, linkIdx) => (
            <li key={linkIdx} className="relative flex gap-x-6 py-6">
              <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10">
                <link.icon
                  className="h-6 w-6 text-indigo-600"
                  aria-hidden="true"
                />
              </div>
              <div className="flex-auto">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  <a href={link.href}>
                    <span className="absolute inset-0" aria-hidden="true" />
                    {link.name}
                  </a>
                </h3>
                <p className="mt-2 text-sm leading-6 text-gray-600">
                  {link.description}
                </p>
              </div>
              <div className="flex-none self-center">
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </li>
          ))}
        </ul>
        <div className="mt-10 flex justify-center">
          <Link
            to="/"
            className="text-sm font-semibold leading-6 text-indigo-600"
          >
            <span aria-hidden="true">&larr;</span>
            Tilbake til forsiden
          </Link>
        </div>
      </div>
    </div>
  );
};
