import { Fragment, useEffect, useState } from "react";
import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  CheckBadgeIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { LoadingButton } from "@mui/lab";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { syncEventSource, syncModule } from "../../../../api/company";
import PMSwitch from "../../../utils/PMSwitch";
import { TWclassNames } from "../../../utils/Div";

export default function SyncDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Deconstructing props
  const { open, onClose, selected, onRefresh } = props;

  const [confirmPrompt, setConfirmPrompt] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [syncState, setSyncState] = useState({
    orders: {
      updated: 0,
      created: 0,
      found: 0,
      isLoading: false,
      error: false,
      errors: [],
    },
    products: {
      updated: 0,
      created: 0,
      found: 0,
      isLoading: false,
      error: false,
      errors: [],
    },
    contacts: {
      updated: 0,
      created: 0,
      found: 0,
      isLoading: false,
      error: false,
      errors: [],
    },
    users: {
      updated: 0,
      created: 0,
      found: 0,
      isLoading: false,
      error: false,
      errors: [],
    },
    customers: {
      updated: 0,
      created: 0,
      found: 0,
      isLoading: false,
      error: false,
      errors: [],
    },
    deliveryAddresses: {
      updated: 0,
      created: 0,
      found: 0,
      isLoading: false,
      error: false,
      errors: [],
    },
    departments: {
      updated: 0,
      created: 0,
      found: 0,
      isLoading: false,
      error: false,
      errors: [],
    },
    projects: {
      updated: 0,
      created: 0,
      found: 0,
      isLoading: false,
      error: false,
      errors: [],
    },
  });

  // const handleModuleChange = (e) => {
  //   // Change syncModules state to new state without change the other states
  //   setSyncModules((prevState) => ({
  //     ...prevState,
  //     [e.event.name]: !syncModules[e.event.name],
  //   }));
  // };

  const modules = [
    {
      name: "orders",
      label: "Orders",
      description: "Sync orders from Tripletex to Project Manager",
      active: false,
    },
    {
      name: "users",
      label: "Users",
      description: "Sync users from Tripletex to Project Manager",
      active: true,
    },
    {
      name: "customers",
      label: "Customers",
      description: "Sync customers from Tripletex to Project Manager",
      active: true,
    },
    {
      name: "deliveryAddresses",
      label: "Delivery Addresses",
      description: "Sync delivery addresses from Tripletex to Project Manager",
      active: true,
    },
    {
      name: "departments",
      label: "Departments",
      description: "Sync departments from Tripletex to Project Manager",
      active: true,
    },
    {
      name: "contacts",
      label: "Contacts",
      description: "Sync contacts from Tripletex to Project Manager",
      active: true,
    },
    {
      name: "projects",
      label: "Projects",
      description: "Sync projects from Tripletex to Project Manager",
      active: true,
    },
    {
      name: "products",
      label: "Products",
      description: "Sync products from Tripletex to Project Manager",
      active: true,
    },
  ];

  const [selectedModules, setSelectedModules] = useState({
    orders: false,
    users: false,
    customers: false,
    deliveryAddresses: false,
    departments: false,
    contacts: false,
    projects: false,
    products: false,
    force: false,
  });

  useEffect(() => {
    // Cancel the confirmation prompt if the dialog closes
    setTimeout(() => {
      if (!open) {
        setConfirmPrompt(false);
      }
    }, 250);
  });

  const runModuleSync = (values) => {
    return syncModule(values);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="flex items-center text-lg font-medium leading-6 text-gray-900"
                  >
                    Sync with Tripletex
                  </Dialog.Title>

                  {isLoading ? (
                    <ResourceLoading />
                  ) : (
                    <Formik
                      initialValues={{
                        orders: false,
                        users: false,
                        customers: false,
                        deliveryAddresses: false,
                        departments: false,
                        contacts: false,
                        projects: false,
                        products: false,
                        force: false,
                      }}
                      onSubmit={(values, helpers) => {
                        setConfirmPrompt(false);
                        try {
                          // change syncState to loading for selected modules
                          const runSyncModule = async (values) => {
                            const syncState = {};
                            for (const [key, value] of Object.entries(values)) {
                              if (value) {
                                syncState[key] = {
                                  isLoading: true,
                                  error: false,
                                  errors: [],
                                };
                              }
                            }
                            setSyncState((prevState) => ({
                              ...prevState,
                              ...syncState,
                            }));
                            const res = await runModuleSync(values);

                            // check if module is updated an update syncState accordingly
                            for (const [key, value] of Object.entries(values)) {
                              if (value) {
                                if (res?.data?.[key]?.updated) {
                                  syncState[key] = {
                                    updated: res?.data?.[key]?.updated,
                                    created: res?.data?.[key]?.created,
                                    found: res?.data?.[key]?.found,
                                    isLoading: false,
                                    error: false,
                                    errors: [],
                                  };
                                } else {
                                  syncState[key] = {
                                    isLoading: false,
                                    error: true,
                                    errors: res?.data?.[key]?.errors,
                                  };
                                }
                              }
                            }
                            setSyncState((prevState) => ({
                              ...prevState,
                              ...syncState,
                            }));

                            return res;
                          };

                          toast.promise(runSyncModule(values), {
                            loading: t("Saving...."),
                            success: (data) => {
                              if (data?.status === 200) {
                                return t("Data was synched!");
                              } else {
                                throw new Error("Server error");
                              }
                            },
                            error: (error) => {
                              console.log(error);
                              return t(
                                "Something went wrong. Could not save data!"
                              );
                            },
                          });
                          helpers.setStatus({ success: true });
                        } catch (err) {
                          toast.error("Something went wrong...");
                          helpers.setStatus({ success: false });
                          helpers.setErrors({ submit: err.message });
                          // helpers.setSubmitting(false);
                        }
                      }}
                    >
                      {(formik) => (
                        <Form>
                          {!confirmPrompt ? (
                            <>
                              <div className="grid grid-cols-6 gap-6 mt-10 mb-10">
                                {modules
                                  .filter((item) => item.active === true)
                                  .map((module) => (
                                    <div
                                      key={module.name}
                                      className={TWclassNames(
                                        syncState[module.name]?.isLoading
                                          ? ""
                                          : "justify-between",
                                        "flex right items-right col-span-6 text-right text-xs"
                                      )}
                                    >
                                      <PMSwitch
                                        label={t(module.label)}
                                        name={module.name}
                                        {...formik}
                                      />
                                      <div className="right">
                                        {syncState[module.name]?.isLoading && (
                                          <ResourceLoading
                                            size="small"
                                            loaderClass="h-5 w-5"
                                            baseClass="px-5 h-5 w-5"
                                          />
                                        )}
                                      </div>
                                      {/* <div>
                                      {syncState[module.name]?.error && (
                                        <div className="flex items-center">
                                          <ExclamationCircleIcon
                                            className="h-5 w-5 text-red-600"
                                            aria-hidden="true"
                                          />
                                          <span className="text-red-600">
                                            {t("Error")}
                                          </span>
                                        </div>
                                      )}
                                    </div> */}

                                      <div>
                                        {syncState[module.name]?.errors?.map(
                                          (error) => (
                                            <div
                                              key={error}
                                              className="flex items-center"
                                            >
                                              <ExclamationCircleIcon
                                                className="h-5 w-5 text-red-600"
                                                aria-hidden="true"
                                              />
                                              <span className="text-red-600">
                                                {error}
                                              </span>
                                            </div>
                                          )
                                        )}
                                      </div>

                                      {syncState[module.name]?.found > 0 && (
                                        <>
                                          <div>
                                            <div className="flex items-center right">
                                              <CheckCircleIcon
                                                className="h-5 w-5 text-green-600 mr-1"
                                                aria-hidden="true"
                                              />
                                              <span className="text-green-600">
                                                {t("Found")}
                                                {": "}
                                                {syncState[module.name]?.found}
                                              </span>
                                            </div>
                                          </div>

                                          <div>
                                            <div className="flex items-center text-right">
                                              <CheckCircleIcon
                                                className="h-5 w-5 text-green-600 mr-1"
                                                aria-hidden="true"
                                              />
                                              <span className="text-green-600">
                                                {t("Updated")}
                                                {": "}
                                                {
                                                  syncState[module.name]
                                                    ?.updated
                                                }
                                              </span>
                                            </div>
                                          </div>

                                          <div>
                                            <div className="flex items-center right">
                                              <CheckCircleIcon
                                                className="h-5 w-5 text-green-600 mr-1"
                                                aria-hidden="true"
                                              />
                                              <span className="text-green-600">
                                                {t("Created")}
                                                {": "}
                                                {
                                                  syncState[module.name]
                                                    ?.created
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  ))}

                                <div className="col-span-6 border-2 border-red-400 px-5 py-5">
                                  <PMSwitch
                                    label={t("Force Sync")}
                                    name="force"
                                    {...formik}
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="px-10 py-10">
                              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                                <ExclamationCircleIcon
                                  className="h-6 w-6 text-red-600"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="mt-3 text-center sm:mt-5">
                                <Dialog.Title
                                  as="h3"
                                  className="text-base font-semibold leading-6 text-gray-900"
                                >
                                  Er du sikker på at du vil synke?
                                </Dialog.Title>
                                <div className="mt-2"></div>
                              </div>
                            </div>
                          )}

                          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                            <button
                              type="button"
                              className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                              onClick={onClose}
                            >
                              {t("Close")}{" "}
                            </button>

                            {!confirmPrompt ? (
                              <button
                                type="button"
                                className="bg-blue-400 rounded shadow text-white"
                                onClick={() => setConfirmPrompt(true)}
                              >
                                {t("Update")}
                              </button>
                            ) : (
                              <LoadingButton
                                loading={isLoading}
                                disabled={isLoading}
                                loadingPosition="start"
                                startIcon={<CheckBadgeIcon />}
                                type="button"
                                variant="contained"
                                color={"success"}
                                onClick={formik.handleSubmit}
                              >
                                {t("Confirm")}
                              </LoadingButton>
                            )}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

SyncDialog.defaultProps = {
  selected: null,
  // Read only is used when only wanting to display info. Update or creating will not work.
  readOnly: false,
  open: false,
  onRefresh: () => null,
  onClose: () => toast.error("Missing onClose function. Cannot close dialog"),
};
