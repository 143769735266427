import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchCustomers,
  fetchCustomer,
  createCustomer,
  destroyCustomer,
  updateCustomer,
} from "../api/customer";
import produce from "immer";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  customer: {},
  data: [],
  count: 0,
  currentCount: 0,
};

export const customersController = {
  filters: [],
  page: 0,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const updateCustomerAsync = createAsyncThunk(
  "customers/updateCustomerAsync",
  async (payload) => {
    let response = await updateCustomer(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createCustomerAsync = createAsyncThunk(
  "customers/createCustomerAsync",
  async (payload) => {
    let response = await createCustomer(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const getCustomers = createAsyncThunk(
  "customers/getCustomers",
  async (controller = customersController, { rejectWithValue }) => {
    let response = await fetchCustomers(controller);

    // If errors, reject and return response
    if (response.error) return rejectWithValue(response);

    // If all good, return response.
    return response;
  }
);

export const getCustomerAsync = createAsyncThunk(
  "customers/getCustomerAsync",
  async (id, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchCustomer(id);

    // If errors, reject and return response
    if (response.error) return rejectWithValue(response);

    // If all good, return response.
    return response;
  }
);

const customerSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomers.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.count = action.payload.count;
        state.currentCount = action.payload.currentCount;
      })
      .addCase(getCustomers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong....is controller missing?"];
      })
      .addCase(getCustomerAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCustomerAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.customer = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(getCustomerAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update product."];
      })
      .addCase(updateCustomerAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateCustomerAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.customer = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(updateCustomerAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update product."];
      });
  },
});

export const { reducer } = customerSlice;

export default customerSlice;

export const selectCustomerById = (state, customerId) =>
  state.customers.customers.find((customer) => customer.id == customerId);
