import { CameraIcon } from "@heroicons/react/20/solid";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ResourceLoading } from "../../../../components/utils/ResourceLoading";
import { ResourceError } from "../../../../components/utils/ResourceError";
import { ResourceEmpty } from "../../../../components/utils/ResourceEmpty";
import { Notes } from "../../../../components/dashboard/notes/notes";
import DashboardHeader from "../../../../components/dashboard/dashboardHeader";
import {
  ArrowLeftIcon,
  ArrowPathRoundedSquareIcon,
  AtSymbolIcon,
  BuildingOfficeIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  deleteDeviationItem,
  fetchDeviation,
} from "../../../../api/quality/deviation";
import { CustomerTag } from "../../../../components/utils/moduleTags/customerTag";
import { ProjectTag } from "../../../../components/utils/moduleTags/projectTag";
import { EmployeeTag } from "../../../../components/utils/moduleTags/employeeTag";
import { DeviationTypeTag } from "../../../../components/utils/moduleTags/deviationTypeTag";
import { DeviationItemForm } from "../../../../components/dashboard/quality/deviations/deviationItemForm";
import {
  DeviationItemActionStatus,
  DeviationPriority,
  DeviationSeverity,
  DeviationStatus,
} from "../../../../components/dashboard/quality/deviations/utils";
import {
  customerHeaderAction,
  dateToString,
} from "../../../../components/Utils";
import { HistoriesModalButton } from "../../../../components/dashboard/histories/modal-button";
import { DocumentViewer } from "../../../../components/dashboard/Media/documentViewer";
import DeviationNewDialog from "../../../../components/dashboard/quality/deviations/deviationNewDialog";
import { DeviationActions } from "../../../../components/dashboard/quality/deviations/deviationActions";
import { Popup } from "../../../../components/utils/Div";
import RoleOnly from "../../../../routes/roleOnly";
import SimplePrompt from "../../../../components/utils/simplePrompt";
import { ProjectFolderTag } from "../../../../components/utils/moduleTags/projectFolderTag";
import { DeviationPrintDialog } from "../../../../components/dashboard/quality/deviations/deviationPrint";

export const DeviationView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    deviation: {},
  });

  const getDeviation = () => {
    setItemState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      fetchDeviation(id).then((data) => {
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          deviation: data?.data,
        }));
      });
    } catch {
      toast.error("Something went wrong when trying to load the deviation");
      setItemState((prevState) => ({
        ...prevState,
        isLoading: false,
        error: true,
      }));
    }
  };

  useEffect(() => {
    getDeviation();
  }, [dispatch, id, open]);

  const handleOnRefresh = (data) => {
    if (data?.id) {
      setItemState((prevState) => ({
        ...prevState,
        deviation: data,
      }));
    } else {
      getDeviation();
    }
  };

  function handleNewDeviationItems(data) {
    const newDeviationItem = {
      ...deviation,
      items: [...deviation.items, data],
    };

    setItemState((prevState) => ({
      ...prevState,
      deviation: newDeviationItem,
    }));
  }

  const [deviationItemDeletePromptOpen, setDeviationItemDeletePromptOpen] =
    useState(false);
  const [selectedDeviationItem, setSelectedDeviationItem] = useState(null);

  const promptDeleteDeviationItem = (item) => {
    setSelectedDeviationItem(item);
    setDeviationItemDeletePromptOpen(true);
  };

  const handleDeleteDeviationItem = async () => {
    const payload = {
      deviation_id: deviation?.id,
      id: selectedDeviationItem?.id,
    };
    const response = await deleteDeviationItem(payload);

    if (response?.status === 200) {
      setDeviationItemDeletePromptOpen(false);
      toast.success("Innlegget ble slettet");

      const newDeviationItems = deviation?.items.filter(
        (deviationItem) => deviationItem.id !== selectedDeviationItem?.id
      );

      const newDeviation = {
        ...deviation,
        items: newDeviationItems,
      };

      setItemState((prevState) => ({
        ...prevState,
        deviation: newDeviation,
      }));

      setDeviationItemDeletePromptOpen(false);
      setSelectedDeviationItem(null);
    }
  };

  const { deviation, isLoading, error, errors } = itemState;

  const [deviationDialogOpen, setDeviationDialogOpen] = useState(false);
  const [deviationSendDialogOpen, setReportSendDialogOpen] = useState(false);
  const [printDialogOpen, setPrintDialogOpen] = useState(false);

  const headerMeta = [
    {
      component: <DeviationSeverity severity={deviation?.severity} />,
      label: "Severity",
      // description: "",
      // path: null,
      // action: null,
      // icon: null,
      // textColor: "text-teal-700",
      hidden: deviation?.severity ? false : true,
    },
    {
      component: <DeviationPriority priority={deviation?.priority} />,
      label: "Priority",
      // description: "",
      // path: null,
      // action: null,
      // icon: null,
      // textColor: "text-teal-700",
      hidden: deviation?.priority ? false : true,
    },
    {
      component: <DeviationStatus deviation={deviation} />,
      label: "Status",
      description: "",
      path: null,
      action: null,
      icon: null,
      textColor: "text-teal-700",
      hidden: false,
    },
    customerHeaderAction(deviation?.customer),
    {
      label: deviation?.project?.displayTitle,
      description: "",
      path: "/dashboard/projects/" + deviation?.project?.id,
      action: null,
      icon: BuildingOfficeIcon,
      textColor: "text-teal-700",
      hidden: deviation?.project?.id ? false : true,
    },
  ];

  const headerActions = [
    {
      component: (
        <HistoriesModalButton
          module={"deviation"}
          module_param={deviation?.id}
        />
      ),
      label: "History",
      description: "",
      icon: ArrowPathRoundedSquareIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: false,
    },
    {
      component: (
        <div className="px-2 mt-2">
          <Notes module={"deviation"} module_param={deviation?.id} />
        </div>
      ),
      label: "Status",
      description: "",
      path: null,
      action: null,
      icon: CameraIcon,
      textColor: "text-teal-700",
      bgColor: "bg-red-200",
      hidden: false,
      menu: false,
    },
    // {
    //   component: null,
    //   label: "Copy",
    //   description: "",
    //   path: null,
    //   action: null,
    //   icon: ClipboardDocumentCheckIcon,
    //   textColor: "text-teal-700",
    //   bgColor: "bg-white",
    //   hidden: false,
    //   menu: true,
    // },
    {
      component: null,
      label: "Print",
      description: "",
      path: null,
      action: () => setPrintDialogOpen(true),
      icon: AtSymbolIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: true,
    },
  ];

  const [selectedChecklistRow, setSelectedChecklistRow] = useState({});

  const handleDeviationCreate = (deviation) => {
    selectedChecklistRow(deviation);
  };

  const DeviationItem = ({ item }) => {
    return (
      <>
        <section
          className="lg:max-w[7xl]"
          aria-labelledby="applicant-information-title"
        >
          <div className="bg-white shadow sm:rounded-lg">
            <div className="flex items-center px-4 py-5 sm:px-6 w-full">
              <div className="block w-full">
                <h2
                  id="applicant-information-title"
                  className="flex-col text-md font-medium leading-6 text-gray-900 "
                >
                  <div className="flex justify-between">
                    <span className="px-2">
                      Lagt til {dateToString(item?.created_at)} av{" "}
                      {item?.created_by?.displayTitle}
                    </span>
                    <RoleOnly role="deviations" action="destroy">
                      <div className="right-0 cursor-pointer">
                        <Popup content="Slett linje">
                          <button
                            type="button"
                            onClick={() => promptDeleteDeviationItem(item)}
                          >
                            <TrashIcon className="h-5 w-5 text-red-600" />
                          </button>
                        </Popup>
                      </div>
                    </RoleOnly>
                  </div>
                  <div className="mt-2">
                    <DeviationItemActionStatus item={item} />
                  </div>
                </h2>
              </div>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  {item?.description}
                </p>
              </dl>
            </div>

            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <DocumentViewer
                pathType="quickview"
                documents={item?.documents}
              />
            </div>
          </div>
        </section>
      </>
    );
  };

  const Content = () => {
    if (isLoading) {
      return <ResourceLoading size="base" />;
    }

    if (error) return <ResourceError errors={errors} />;

    if (!deviation?.id) {
      return <ResourceEmpty />;
    }

    return (
      <>
        <div className="space-y-6 lg:col-span-2 lg:col-start-1 sm:col-span-2 max-sm:mt-5 max">
          {deviation?.checklist_item?.checklist?.id && (
            <div>
              <Link
                to={
                  "/dashboard/quality/checklists/" +
                  deviation?.checklist_item?.checklist?.id
                }
                className="bg-blue-400 flex px-5 py-4 rounded mx-auto text-white hover:bg-blue-500"
              >
                <ArrowLeftIcon className="h-5 w-5 mt-0.5" />
                <span className="ml-2">Back to Checklist</span>
              </Link>
            </div>
          )}
          <section
            className="lg:max-w[7xl]"
            aria-labelledby="applicant-information-title"
          >
            <div className="bg-white shadow sm:rounded-lg">
              <div className="flex items-center px-4 py-5 sm:px-6 w-full">
                <div className="block w-[60%]">
                  <h2
                    id="applicant-information-title"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Detaljer
                  </h2>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Informasjon om avvik
                  </p>
                </div>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
                  <ProjectTag
                    project={deviation?.project}
                    onRefresh={handleOnRefresh}
                  />

                  <CustomerTag
                    customer={deviation?.customer}
                    onRefresh={handleOnRefresh}
                  />
                  <ProjectFolderTag
                    label="Folder"
                    folder={deviation?.folder}
                    onRefresh={handleOnRefresh}
                  />

                  <EmployeeTag
                    label="Ansvarlig"
                    employee={deviation?.responsible_user}
                    onRefresh={handleOnRefresh}
                  />

                  <EmployeeTag
                    label="Utførende"
                    employee={deviation?.assigned_user}
                    onRefresh={handleOnRefresh}
                  />

                  <EmployeeTag
                    label="Opprettet av"
                    employee={deviation?.created_by}
                    onRefresh={handleOnRefresh}
                  />

                  <DeviationTypeTag
                    deviation_type={deviation?.deviation_type}
                    onRefresh={handleOnRefresh}
                  />

                  {/* <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Beskrivelse av avvik
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: deviation?.description,
                        }}
                      />
                    </dd>
                  </div> */}
                </dl>
              </div>
            </div>
          </section>
        </div>
        <div className="space-y-6 lg:col-span-2 lg:col-start-1 sm:col-span-2 max-sm:mt-5 max">
          <DeviationActions
            deviation={deviation}
            onItemChange={(data) => handleNewDeviationItems(data)}
          />
        </div>

        {deviation?.items?.length > 0 &&
          deviation?.items?.map((item, idx) => (
            <div
              key={idx}
              className="space-y-6 lg:col-span-2 lg:col-start-1 sm:col-span-2 max-sm:mt-5 max"
            >
              <DeviationItem item={item} />
            </div>
          ))}

        <div className="space-y-6 lg:col-span-2 lg:col-start-1 sm:col-span-2 max-sm:mt-5 max">
          <DeviationItemForm
            deviation={deviation}
            onChange={(data) => handleNewDeviationItems(data)}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={deviation?.displayTitle || "Mangler title"}
        meta={headerMeta}
        mainButton={{
          label: "Edit",
          action: () => setDeviationDialogOpen(true),
          path: null,
          color: "",
          icon: PencilIcon,
        }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-1 mb-20">
          <Content />
        </div>
      </DashboardHeader>

      <DeviationNewDialog
        open={deviationDialogOpen}
        onClose={() => setDeviationDialogOpen(false)}
        selected={deviation}
        onRefresh={handleOnRefresh}
      />

      <SimplePrompt
        title="Slett inlegg"
        description="Er du sikker på at du ønsker å slette inlegget?"
        onAccept={() => handleDeleteDeviationItem()}
        open={deviationItemDeletePromptOpen}
        onClose={() => setDeviationItemDeletePromptOpen(false)}
      />

      <DeviationPrintDialog
        deviation={deviation}
        open={printDialogOpen}
        onClose={() => setPrintDialogOpen(false)}
      />
    </>
  );
};
