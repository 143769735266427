import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import QRCode from "react-qr-code";
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    maxWidth: "300 !important",
    maxHeight: "120  !important",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

export const ProductQrPdf = (props) => {
  const { product } = props;

  return (
    <Document>
      <Page orientation="landscape" style={styles.page}>
        <View style={styles.section}>
          <Text style={{ fontSize: 15 }}>{product.item_number}</Text>
          <Text style={{ fontSize: 25 }}>{product.title}</Text>
          <Text style={{ fontSize: 25 }}>
            <QRCode
              value={
                "https://promang-frontend.vercel.app/dashboard/products/" +
                product?.id
              }
              size={100}
            />
          </Text>
        </View>
      </Page>
    </Document>
  );
};
