import { DocumentCenter } from "../../../components/dashboard/documents/document-center";

export const ProductDocuments = (props) => {
  const { product } = props;

  return (
    <>
      <DocumentCenter
        subtitle="Kun PDF filer er tillatt"
        module="product_documents"
        allowedFileTypes="application/pdf"
        moduleParam={product?.id}
      />
    </>
  );
};
