import { WrenchIcon } from "@heroicons/react/24/outline";
import { PMDialog } from "../../utils/PMDialog";
import { useState } from "react";
import { documentSrc } from "../documents/utils";

export const ProductStacklistViewer = ({ products }) => {
  function handleRedirect(product) {
    window.location.href = `/dashboard/products/${product.id}`;
  }

  return (
    <>
      {products && (
        <div className="overflow-hidden bg-white shadow sm:rounded-md ">
          <ul role="list" className="divide-y divide-gray-200">
            {products?.map((product) => (
              <li key={product.id} onClick={() => handleRedirect(product)}>
                <a href="#" className="block hover:bg-gray-50">
                  <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center">
                      <div>
                        <img
                          className="h-10 w-10 rounded-full"
                          src={documentSrc(product.main_picture, "thumb")}
                          // alt={
                          //   product?.main_picture
                          //     ? item.main_picture.title
                          //     : "Image placeholder"
                          // }
                        />
                      </div>
                      <p className="ml-5 truncate text-sm font-medium text-blue-400">
                        {product.displayTitle}
                      </p>
                      <div className="ml-2 flex flex-shrink-0">
                        <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          {product.status}
                        </p>
                      </div>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                      {/* <div className="sm:flex">
                        <p className="flex items-center text-sm text-gray-500">
                          <UsersIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {product?.user_assigned
                            ? product?.user_assigned?.name
                            : "None"}
                          {product.department}
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                          <MapPinIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {product.case_type?.title}
                        </p>
                      </div>
                      <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                        <CalendarIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <p>
                          Date created{" "}
                          <time dateTime={product.created_at}>
                            {product.created_at}
                          </time>
                        </p>
                      </div> */}
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export const ProductStacklistViewerDialog = ({
  products,
  open,
  onClose,
  title,
}) => {
  return (
    <PMDialog open={open} onClose={onClose} title={title}>
      <ProductStacklistViewer products={products} />
    </PMDialog>
  );
};

export const ProductsForButton = ({ label, products }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        onClick={() => setOpen(true)}
        className="w-full flex bg-blue-300 shadow px-5 py-5 rounded-xl cursor-pointer hover:bg-blue-400"
      >
        <WrenchIcon className="h-10 w-10" />
        <div className="ml-2">
          <div>{label + " for"}</div>
          <div className="font-bold">
            {products?.length} Produkt {products?.length > 1 ? "er" : ""}
          </div>
        </div>
      </div>

      <ProductStacklistViewerDialog
        title={label}
        open={open}
        onClose={() => setOpen(false)}
        products={products}
      />
    </>
  );
};
