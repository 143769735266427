import { toast } from "react-hot-toast";
import { axiosWithToast } from "../../../../components/Utils";
import { drivingDriverAction } from "../../../../api/driving/driver";

export const driverVehicleAction = async ({
  userId,
  vehicleId,
  action,
  onDriverUpdate,
}) => {
  // Error if action is missing
  if (!action) {
    toast.error("Error: Action is missing");
    return;
  }

  const payload = {
    user_id: userId,
    vehicle_id: vehicleId,
    action: action,
  };

  axiosWithToast(drivingDriverAction(payload), null, null, onDriverUpdate);
};
