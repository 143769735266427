import { Outlet } from "react-router-dom";

export const StandardLayout = (props: any) => {
  const { children, title } = props;
  return (
    <>
      <div className="py-6">
        <div className="mx-auto max-w-8xl px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900 ml-5">
            {" "}
            {title}{" "}
          </h1>
        </div>

        <div className="mx-auto max-w-8xl px-2 sm:px-6 md:px-9 mt-10 bg-gray-100 rounded-md">
          <Outlet />
        </div>
      </div>
    </>
  );
};
