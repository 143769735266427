import { useState } from "react";
import { toast } from "react-hot-toast";
import { ModuleTag } from "./moduleTag";

export const ProjectTag = ({ project, folder, onRefresh }) => {
  const [dialogOpen, setDialogOpen] = useState();

  const labelAction = {
    path: project?.id ? "/dashboard/projects/" + project?.id : null,
    // action: () => toast("test"),
  };

  const handleOpenDialog = () => {
    if (project?.id) {
      setDialogOpen(true);
    } else {
      toast.error("Du må velge prosjekt først");
    }
  };

  const content = () => {
    if (project?.id && folder?.id) {
      return project?.displayTitle + " / " + folder?.displayTitle;
    }
    if (project?.id) {
      return project?.displayTitle;
    } else {
      return "Not selected";
    }
  };
  return (
    <>
      <ModuleTag
        label={"Project" + (folder?.id ? " / " + "Folder" : "")}
        labelAction={labelAction}
      >
        {content()}
      </ModuleTag>
    </>
  );
};
