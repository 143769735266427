import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import toast from "react-hot-toast";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { getDeviationPdf } from "../../../../api/quality/deviation";
import { ResourceError } from "../../../utils/ResourceError";

export const DeviationPrintDialog = (props) => {
  const { onLoading, onClose, open, deviation } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const initialValues = {
    id: deviation?.id,
    submit: null,
  };

  const [pdfUrl, setPdfUrl] = useState(null);

  const fetchDeviationPdf = async () => {
    setIsLoading(true);
    if (!deviation?.id) return false;

    const payload = {
      id: deviation?.id,
    };
    await getDeviationPdf(payload)
      .then((response) => {
        setPdfUrl(response.data.pdf_url);
        setIsLoading(false);
        setError(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    if (open) {
      fetchDeviationPdf();
    }
  }, [open]);

  const handleDownloadPDF = () => {
    // Open pdfUrl in another window
    window.open(pdfUrl, "_blank");
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {t("Print Report")}
                    </Dialog.Title>

                    {isLoading ? (
                      <ResourceLoading />
                    ) : !deviation?.id ? (
                      "Missing ID"
                    ) : (
                      <Formik
                        initialValues={initialValues}
                        onSubmit={(values, helpers) => {
                          try {
                            helpers.setStatus({ success: true });
                            helpers.setSubmitting(false);
                            helpers.resetForm(initialValues);
                          } catch (err) {
                            toast.error("Something went wrong...");
                            console.error(err);
                            helpers.setStatus({ success: false });
                            helpers.setErrors({ submit: err.message });
                            helpers.setSubmitting(false);
                          }
                        }}
                      >
                        {(formik) => (
                          <Form>
                            <div className="grid grid-cols-6 gap-6 mt-10">
                              <div className="col-span-6 sm:col-span-6 mb-20">
                                {error && <ResourceError />}
                              </div>

                              <div className="col-span-6 sm:col-span-6 mb-20">
                                {pdfUrl && (
                                  <button
                                    type="button"
                                    className="w-full justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                    onClick={handleDownloadPDF}
                                  >
                                    {t("Download")}
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className="mt-20 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                              <button
                                type="button"
                                className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                onClick={onClose}
                              >
                                {t("Close")}{" "}
                              </button>

                              {/* <button
                                type="button"
                                className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                onClick={formik.submitForm}
                              >
                                {t("update")}
                              </button> */}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
