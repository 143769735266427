import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchCases,
  updateCase,
  createCase,
  deleteCase,
  fetchCasesStats,
} from "../api/case";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  case: {},
  totalCount: 0,
  currentCount: 0,
  newestCasesCount: 0,

  // weekly_total: 0,
  activity_monthly: [],
  monthly_total: [],
  new_today: 0,
  new_this_week: 0,
  new_this_month: 0,
};

export const initialController = {
  filters: [],
  page: 1,
  per_page: 200,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const deleteCaseAsync = createAsyncThunk(
  "cases/deleteCaseAsync",
  async (payload) => {
    let response = await deleteCase(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // include the ID in the response
    response.id = payload.id;
    // If all good, return response.
    return response;
  }
);

export const createCaseAsync = createAsyncThunk(
  "cases/createCaseAsync",
  async (payload) => {
    let response = await createCase(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const updateCaseAsync = createAsyncThunk(
  "cases/updateCaseAsync",
  async (payload, { rejectWithValue }) => {
    let response = await updateCase(payload);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/)) return rejectWithValue;

    // If all good, return response.
    return response;
  }
);
export const getCasesAsync = createAsyncThunk(
  "cases/getCasesAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchCases(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

export const getCasesStatsAsync = createAsyncThunk(
  "cases/getCasesStatsAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchCasesStats();
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

export const loadMoreCasesAsync = createAsyncThunk(
  "documents/loadMoreCasesAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchCases(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);
    // If all good, return response.
    return response;
  }
);

const caseSlice = createSlice({
  name: "cases",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCasesAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCasesAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.currentCount = action.payload.currentCount;
        state.newestCasesCount = action.payload.newestCasesCount;
      })
      .addCase(getCasesAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch Cases....is controller missing?",
        ];
      })
      .addCase(updateCaseAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateCaseAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data = draftState.data.map((item) => {
            if (item.id === action.payload.id) {
              return action.payload;
            } else {
              return item;
            }
          });

          // draftState.case = action.payload;
          draftState.isLoading = false;
          draftState.error = false;
          draftState.errors = null;
        });
      })
      .addCase(updateCaseAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update Case."];
      })
      .addCase(createCaseAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createCaseAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
          draftState.error = false;
          draftState.errors = null;
        });
      })
      .addCase(createCaseAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to create Case."];
      })
      .addCase(deleteCaseAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteCaseAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data = draftState.data.filter(
            (item) => item.id !== action.payload.id
          );
          draftState.isLoading = false;
          draftState.error = false;
          draftState.errors = null;
        });
      })
      .addCase(deleteCaseAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to delete Case."];
      })
      .addCase(loadMoreCasesAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(loadMoreCasesAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data = [...draftState.data, ...action.payload.data];
          draftState.totalCount = action.payload.totalCount;
          draftState.currentCount =
            draftState.data.length + action.payload.currentCount;
          draftState.isLoading = false;
          draftState.error = false;
          draftState.errors = null;
        });
      })
      .addCase(loadMoreCasesAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to fetch cases"];
      })
      .addCase(getCasesStatsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCasesStatsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.weekly_total = action.payload.data.weekly_total;
        state.monthly_total = action.payload.data.monthly_total;
        state.new_today = action.payload.data.new_today;
        state.new_this_week = action.payload.data.new_this_week;
        state.new_this_month = action.payload.data.new_this_month;
        state.activity_monthly = action.payload.data.activity_monthly;
      })
      .addCase(getCasesStatsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to fetch stats"];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = caseSlice;

export default caseSlice;
