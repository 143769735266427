import React from "react";
import { useSelector } from "react-redux";
import ReportsCalendarSimpleView from "./service/reports/calendar/simpleView";
import DashboardGreeting from "./front/greeting";
import CurrentVehicleSection from "./driving/currentVehicleSection";
import RoleOnly from "../../routes/roleOnly";
import { DeviationReportView } from "../../components/dashboard/quality/deviations/deviationReportView";
import { DashboardStatsSection } from "../../components/utils/dashboardStatsSection";

export const DashboardReport = () => {
  // Get current user from session slice using useSelector

  return (
    <>
      <head>
        <title>Dashboard Report | Promang</title>
      </head>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-7">
        <div className="col-span-1 sm:col-span-3">
          <DashboardGreeting />
        </div>
        <div className="col-span-1 sm:col-span-3">
          <DashboardStatsSection />
        </div>
        <div className="col-span-1 sm:col-span-1">
          <CurrentVehicleSection />
        </div>
        <RoleOnly role="deviations">
          <div className="col-span-1 sm:col-span-2 gap-x-6">
            <DeviationReportView />
          </div>
        </RoleOnly>
        <RoleOnly role="service">
          <div className="col-span-1 sm:col-span-3">
            <ReportsCalendarSimpleView />
          </div>
        </RoleOnly>
      </div>
    </>
  );
};
